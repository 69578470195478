import { createSelector } from 'reselect'

const mmCstListContent = state => state.get('mmCst')

const selectLoading = () => createSelector(mmCstListContent, cst => cst.toJS()['isLoading'])
const mmCstContent = () => createSelector(mmCstListContent, cst => cst.toJS()['mmCstContent'])
const isListUpdated = () => createSelector(mmCstListContent, cst => cst.toJS()['isListUpdated'])
const singleRecord = () => createSelector(mmCstListContent, cst => cst.toJS()['singleRecord'])

export { selectLoading, mmCstContent, mmCstListContent, isListUpdated, singleRecord }
