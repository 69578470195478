import { createSelector } from 'reselect'

const selectLopContent = state => state.get('lopContent')

const selectLoading = () => createSelector(selectLopContent, search => search.toJS()['isLoading'])

const selectStatus = () => createSelector(selectLopContent, search => search.toJS()['status'])

const selectMyLopList = () => createSelector(selectLopContent, search => search.toJS()['lopListContent'])

const selectLopDetails = () => createSelector(selectLopContent, search => search.toJS()['lopDetails'])

const selectLopAlbum = () => createSelector(selectLopContent, search => search.toJS()['album'])
const partnerDetails = () => createSelector(selectLopContent, search => search.toJS()['partnerDetails'])
const isListUpdated = () => createSelector(selectLopContent, search => search.toJS()['isListUpdated'])
const sortedArray = () => createSelector(selectLopContent, search => search.toJS()['sortedArray'])
const removedContent = () => createSelector(selectLopContent, search => search.toJS()['removedContent'])
const currentSlideContent = () => createSelector(selectLopContent, search => search.toJS()['updateCurrentSlide'])

const downloadDocs = () => createSelector(selectLopContent, search => search.toJS()['downloadDocs'])
const collaboratorList = () => createSelector(selectLopContent, search => search.toJS()['collaboratorList'])

const searchcollaboratorList = () => createSelector(selectLopContent, search => search.toJS()['searchcollaboratorList'])

const isLopRemoved = () => createSelector(selectLopContent, search => search.toJS()['lopRemoved'])

const contactWinRoomFlag = () => createSelector(selectLopContent, search => search.toJS()['contactWinRoomFlag'])

const lopLockFlag = () => createSelector(selectLopContent, search => search.toJS()['lopLockFlag'])

const lopError = () => createSelector(selectLopContent, search => search.toJS()['error'])
const publishLink = () => createSelector(selectLopContent, search => search.toJS()['publishLink'])
const mailSentStatus = () => createSelector(selectLopContent, search => search.toJS()['mailSentStatus'])
const retainState = () => createSelector(selectLopContent, search => search.toJS()['retainState'])
const docUrl = () => createSelector(selectLopContent, search => search.toJS()['docUrl'])
const reqData = () => createSelector(selectLopContent, search => search.toJS()['reqData'])
const additionalArticles = () => createSelector(selectLopContent, search => search.toJS()['additionalArticles'])
const getElementIdToScroll = () => createSelector(selectLopContent, search => search.toJS()['elementIdToScroll'])
const restoreLopStatus = () => createSelector(selectLopContent, search => search.toJS()['restoreLopStatus'])
const welcomeVideo = () => createSelector(selectLopContent, search => search.toJS()['welcomeVideo'])
const isWelcomeLoading = () => createSelector(selectLopContent, search => search.toJS()['isWelcomeLoading'])

export {
    selectLoading,
    selectMyLopList,
    selectLopDetails,
    selectLopAlbum,
    partnerDetails,
    selectStatus,
    isListUpdated,
    sortedArray,
    removedContent,
    currentSlideContent,
    downloadDocs,
    collaboratorList,
    searchcollaboratorList,
    isLopRemoved,
    contactWinRoomFlag,
    lopLockFlag,
    lopError,
    docUrl,
    publishLink,
    mailSentStatus,
    retainState,
    reqData,
    additionalArticles,
    getElementIdToScroll,
    restoreLopStatus,
    welcomeVideo,
    isWelcomeLoading,
}
