import React from 'react'
import ReactDOM from 'react-dom'

import { Provider } from 'react-redux'
import 'antd/dist/antd.css'
import './fonts/McKinseySans-Regular.woff'
import './fonts/McKinseySans-Medium.woff'
import './fonts/McKinseySans-Italic.woff'
import './fonts/McKinseySans-Medium.woff'
import './fonts/McKinseySans-MediumItalic.woff'
import './fonts/McKinseySans-Light.woff'
import './fonts/McKinseySans-LightItalic.woff'
import './fonts/Bower-Bold.woff'
import './Styles/theme.css'
import './Styles/main.css'
import App from './Containers/App/index.js'
import store from './Store'
import doInject from './Injectors'
import { Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import { Mid } from '@mid/sdk'
import { ENV_VARS } from './Utils/constants'

doInject(store)

const midConfig = ({ fm, flow }) => ({
    redirectUrl: `${window.location.origin}/auth/callback`,
    logoutRedirectUrl: `${window.location.origin}/`,
    loginInfo: {
        appId: ENV_VARS.REACT_APP_MCK_ID,
        oidcConfigUrl: ENV_VARS.REACT_APP_OIDC_URL,
        authDriver: 'mid',
        fm,
        flow,
    },
    landingFn: async () => {
        window.location.hash = ''
    },
})

const options = {
    position: 'top center',
    timeout: 5000,
    offset: '30px',
    transition: 'scale',
    zIndex: 1005,
}

ReactDOM.render(
    <Provider store={store}>
        <AlertProvider template={AlertTemplate} {...options}>
            <App />
        </AlertProvider>
    </Provider>,
    document.getElementById('root')
)
