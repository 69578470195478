import React, { Component } from 'react'
import Modal from 'react-responsive-modal'
import { getPrefixedUrl } from '../../../../../Utils/apis'
import './index.scss'

export default class DetailsModal extends Component {
    state = {
        anchors: null,
    }

    componentDidMount() {
        const anchors = document.querySelectorAll('a')
        this.setState({ anchors })
        if (anchors && anchors.length) {
            for (let i = 0; i < anchors.length; i++) {
                anchors[i].addEventListener('click', this.eventObj)
                anchors[i].addEventListener('touchstart', this.eventObj)
            }
        }
    }
    componentWillUnmount() {
        const { anchors } = this.state
        if (anchors && anchors.length) {
            for (let i = 0; i < anchors.length; i++) {
                anchors[i].removeEventListener('click', this.eventObj)
                anchors[i].removeEventListener('touchstart', this.eventObj)
            }
        }
    }
    eventObj = e => {
        const locationToSend = e.target.href
        if (locationToSend && locationToSend.includes('noredirect')) {
            e.preventDefault()
            const { sections, updateCurrentSlide, setElementToScroll } = this.props
            const locationArray = locationToSend.split('/')
            const sectionId = locationArray[locationArray.length - 2]
            const elementId = locationArray[locationArray.length - 1]
            const sectionToMove = sections.findIndex(x => x.sectionId === sectionId)
            if (sectionToMove) {
                updateCurrentSlide(sectionToMove)
                setElementToScroll(elementId)
            }
        }
    }

    resetModal = () => {
        this.props.onCloseModal()
    }
    render() {
        const styleClasses = {
            modal: 'detailsModal',
            overlay: 'overlayDetails',
            closeButton: 'close-btn-modal',
        }
        const { name, designation, description, image } = this.props.teamData
        return (
            <Modal open onClose={() => this.resetModal()} center classNames={styleClasses}>
                <div className="mainContent">
                    <div className="profile-image">
                        <img alt="profile" src={getPrefixedUrl(image)} height="300px" />
                    </div>
                    <div className="profile-data">
                        <div className="name">{name}</div>
                        <div className="designation">{designation}</div>
                        <div
                            className="description"
                            dangerouslySetInnerHTML={{
                                __html: description,
                            }}
                        />
                    </div>
                </div>
                <div className="mobileData" dangerouslySetInnerHTML={{ __html: description }} />
            </Modal>
        )
    }
}
