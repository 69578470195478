import React, { Component } from 'react'
import Modal from 'react-responsive-modal'
import { AddIcon, EditMainIcon, ExitEditModeIcon, PlusIconNew } from '../../Utils/svg'
import Inputbox from '../Inputbox'
import { ConfirmAlertBox } from '../../Containers/Library/Alerts'
import { isValidPattern } from '../../Utils/functions'
import { NAME_PATTERN, SITE_TXT, ENTER_KEY_CODE } from '../../Utils/constants'

class PhaseModal extends Component {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this)
        this.addPhase = this.addPhase.bind(this)
        this.state = {
            open: this.props.open ? true : false,
            phase: this.props.phase,
            phaseToTrash: {},
            categoryName: this.props.categoryName,
            label: this.props.label,
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { open, categoryName, label, phase } = nextProps

        this.setState({
            open,
            categoryName,
            label,
            phase,
        })
    }

    onOpenModal = () => {
        this.setState({ open: true, phase: this.props.phase })
    }

    onCloseModal = () => {
        this.setState({ open: false, phase: '' })
    }

    handleChange = (field, value) => {
        this.setState({ [field]: value })
    }

    enterKeyHandler = (e, actionType) => {
        if (e.which === ENTER_KEY_CODE) {
            if (actionType === 'add') {
                this.addPhase()
            } else {
                this.editPhaseHandler()
            }
        }
    }

    addPhase = () => {
        if (!isValidPattern(this.state.phase, NAME_PATTERN)) {
            alert(SITE_TXT.TXT_PHASE_NAME_INVALID_PATTERN)
            return
        }
        let newPhaseInfo = {}
        if (sessionStorage.getItem('topLevel') !== 'Deliver') {
            newPhaseInfo = {
                categoryId: parseInt(this.props.categoryId, 10),
                name: this.state.phase,
            }
        } else {
            newPhaseInfo = {
                deliveryId: this.props.categoryId,
                name: this.state.phase,
            }
        }
        this.props.addPhaseHandler(newPhaseInfo)
        this.onCloseModal()
        sessionStorage.setItem('selTabName', newPhaseInfo.name)
        this.props.afterAdd()
    }

    editPhaseHandler = () => {
        let phaseToEdit = {
            id: this.props.phaseId,
            [sessionStorage.getItem('topLevel') !== 'Deliver' ? 'categoryId' : 'deliveryId']: this.props.categoryId,
            name: this.state.phase,
        }

        this.props.editPhase(phaseToEdit)
    }

    trashPhaseHandler = () => {
        let phaseToTrash = {}
        const isDeliver = sessionStorage.getItem('topLevel') === 'Deliver'
        if (!isDeliver) {
            phaseToTrash = {
                id: this.props.phaseId,
                categoryId: this.props.categoryId,
                name: this.state.phase,
            }
        } else {
            phaseToTrash = {
                id: this.props.phaseId,
                deliveryId: this.props.categoryId,
                name: this.state.phase.name,
            }
        }
        this.setState({ phaseToTrash: phaseToTrash })
        const title = SITE_TXT.TXT_CONFIRM_DELETE
        const message = isDeliver ? SITE_TXT.TXT_PHASE_DELETE_TOPIC : SITE_TXT.TXT_PHASE_DELETE_ALERT
        const onYesTrash = () => this.trashFinally()
        ConfirmAlertBox(title, message, onYesTrash)
        this.onCloseModal()
    }

    trashFinally = () => {
        if (sessionStorage.getItem('topLevel') === 'Deliver') {
            this.props.setSection(this.state.phaseToTrash)
        }
        this.props.trashPhase(this.state.phaseToTrash)
        this.props.afterTrash()
    }

    setSectionHandler = () => {
        this.props.setSection(null)
        this.setState({ open: true })
    }

    render() {
        const { open, phase, categoryName, label } = this.state

        const isDeliver = sessionStorage.getItem('topLevel') === 'Deliver' ? true : false
        const { TXT_EDIT, TXT_DELETE, TXT_STEP_NAME, TXT_SECTION_NAME, TXT_ADD_NEW_SECTION, TXT_DELIVER } = SITE_TXT
        const inBoxLabel = isDeliver ? TXT_SECTION_NAME : TXT_STEP_NAME
        return (
            <div>
                {!isDeliver && (
                    <span className="addEditIcon" onClick={() => this.onOpenModal()}>
                        {this.props.editPhase ? open ? <ExitEditModeIcon /> : <EditMainIcon /> : <AddIcon />}
                        {this.props.label}
                    </span>
                )}
                {isDeliver && (
                    <span className="actionIcon plush-sm" onClick={() => this.setSectionHandler()}>
                        <PlusIconNew /> {TXT_ADD_NEW_SECTION}
                    </span>
                )}
                <Modal
                    open={open}
                    onClose={this.onCloseModal}
                    center
                    classNames={{
                        modal: 'comp-modal phase-modal',
                    }}
                >
                    <label>{label}</label>
                    {!isDeliver && (
                        <span className="currentPath">
                            {sessionStorage.getItem('topLevel') +
                                ' >  \n ' +
                                sessionStorage.getItem('currentCategoryTab')}
                        </span>
                    )}
                    {isDeliver && <span className="currentPath">{TXT_DELIVER + ' >  \n ' + categoryName}</span>}
                    <br />
                    {!this.props.phase ? (
                        <div>
                            <Inputbox
                                label={inBoxLabel}
                                placeholder={this.props.placeholder}
                                type="text"
                                fieldFor="phase"
                                value={phase}
                                onChange={this.handleChange}
                                autoFocus={true}
                                onKeyUp={e => this.enterKeyHandler(e, 'add')}
                            />
                            <div className="btn btn-container">
                                <button disabled={!phase} className="btn btn-primary" onClick={this.addPhase}>
                                    {label}
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <Inputbox
                                label={inBoxLabel}
                                placeholder={this.props.placeholder}
                                type="text"
                                fieldFor="phase"
                                value={isDeliver ? phase.name : phase}
                                onChange={this.handleChange}
                                autoFocus={true}
                                onKeyUp={e => this.enterKeyHandler(e, 'edit')}
                            />
                            <div className="btn btn-container">
                                <button className="btn btn-primary" onClick={() => this.editPhaseHandler()}>
                                    {TXT_EDIT}
                                </button>
                                <button className="btn btn-danger" onClick={() => this.trashPhaseHandler()}>
                                    {TXT_DELETE}
                                </button>
                            </div>
                        </div>
                    )}
                </Modal>
            </div>
        )
    }
}

PhaseModal.defaultProps = {
    phase: '',
    open: false,
    placeholder: SITE_TXT.TXT_PHASE_NAME_PLACEHOLDER,
}
export default PhaseModal
