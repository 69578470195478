import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectStatus, selectLoading, selectError } from './selector'
import { industryAction } from './actions'
import { Industry } from '../../Components'

const mapStateToProps = createStructuredSelector({
    isLoading: selectLoading(),
    status: selectStatus(),
    error: selectError(),
})
const mapDispatchToProps = {
    industry: industryAction,
}
export default connect(mapStateToProps, mapDispatchToProps)(Industry)
