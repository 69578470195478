import { createSelector } from 'reselect'

const selectLearn = state => state.get('learn')

const selectLoading = () => createSelector(selectLearn, learn => learn.toJS()['isLoading'])

const selectStatus = () => createSelector(selectLearn, learn => learn.toJS()['status'])

const selectError = () => createSelector(selectLearn, learn => learn.toJS()['error'])

export { selectStatus, selectLoading, selectError }
