import { fromJS } from 'immutable'
import {
    ADMIN_ADD_USER_BACKEND,
    ADMIN_BLOCK_UNBLOCK_USER,
    ADMIN_CREATE_TICKET,
    ADMIN_CREATE_USER,
    ADMIN_DELETE_DOC_DETAILS,
    ADMIN_DOCUMENT_LOAD_MORE,
    ADMIN_EDIT_USER_DATA,
    ADMIN_EXPIRED_SEARCH,
    ADMIN_EXTEND_DOCUMENTS,
    ADMIN_FETCH_DESIGNATION_TAGS,
    ADMIN_FETCH_DOC_DETAILS,
    ADMIN_FETCH_EXPERTS_TAGS,
    ADMIN_FETCH_EXT_DOCUMENTS,
    ADMIN_FETCH_TAGS,
    ADMIN_FETCH_USER_DATA,
    ADMIN_FETCH_USER_TYPE,
    ADMIN_GET_DOWNLOAD_LIMIT,
    ADMIN_GET_USER_PROFILE,
    ADMIN_REPLACE_DOC_DETAILS,
    ADMIN_SESSION_EMAIL_COMPLETED,
    ADMIN_SESSION_TABLE,
    ADMIN_SESSION_TABLE_LOAD_MORE,
    ADMIN_SET_DOWNLOAD_LIMIT,
    ADMIN_SET_USER_PROFILE,
    ADMIN_UPLOAD_ADMIN,
    ADMIN_USER_COUNT_DETAILS,
    ADMIN_VERIFICATION_EMAIL,
    DELETE_USER,
    DELETE_VIDEO_DATA,
    FETCH_ACTIVE_CST,
    FETCH_CST_GROUP_DETAIL,
    FETCH_EXPIRED_CST,
    FETCH_NEW_CST,
    GET_USER_COUNT,
    GET_VIDEO_DATA,
    LOAD_MORE_ACTIVE_CST,
    LOAD_MORE_EXPIRED_CST,
    LOAD_MORE_NEW_CST,
    RESET_VIDEO_DATA,
    UPDATE_CST_GROUP_DETAIL,
    VIDEO_UPLOAD,
    GET_ACTIVE_USERS,
    GET_LOP_STATS,
    BLK_UNBLK_CST_USER,
    GET_DOCS_STATS,
    GET_SHARED_DOCS,
    GET_RECIPIENT_LIST,
    DELETE_SHARED_DOC,
} from './constant'
import { STATUS_FAILED, STATUS_FAILURE } from '../../Utils/constants'
import { RESET_STATUS } from '../MMCst/constant'

const INITIAL_STATE = fromJS({
    status: null,
    isLoading: false,
    error: null,
    userTypeData: null,
    data: null,
    extDocData: null,
    designationData: null,
    toBeExpData: null,
    extendDocState: null,
    createUserData: null,
    ticket: null,
    backendUserData: null,
    docdetails: null,
    newdocdetails: null,
    deleteData: null,
    uploadData: null,
    userCount: null,
    adminSessionData: {},
    blockUnblockData: null,
    isAdminDataLoading: null,
    userData: null,
    isEditUserData: null,
    downloadLimitData: null,
    downloadLimitStatus: null,
    getUserProfileData: null,
    setUserProfileSuccess: null,
    userEmails: [],
    closeVideoUpload: false,
    videoData: fromJS({
        totalCount: 0,
        data: [],
    }),
    reloadVideoContent: false,
    cstGroupDetail: {},
    isListUpdated: false,
    adminUser: {},
    activeUsers: {},
    lopStats: {},
    isCstVisible: false,
    sharedDocsList: [],
    recipientsList: [],
    isDocDeleted: false,
})

function adminReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case `${ADMIN_UPLOAD_ADMIN}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)
                .set('uploadData', null)
        case `${ADMIN_UPLOAD_ADMIN}_SUCCESS`:
            return state
                .set('status', action.data.responseData)
                .set('isLoading', false)
                .set('uploadData', action.data.responseData)
        case `${ADMIN_UPLOAD_ADMIN}_FAILED`:
            return state
                .set('error', action.data.responseData)
                .set('isLoading', false)
                .set('uploadData', action.data.responseData)
        case `${ADMIN_FETCH_TAGS}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', false)
        case `${ADMIN_FETCH_TAGS}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('data', action.data.responseData.result)
        case `${ADMIN_FETCH_TAGS}_FAILED`:
            return state
                .set('isLoading', false)
                .set('status', null)
                .set('data', null)
        case `${ADMIN_FETCH_EXPERTS_TAGS}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', false)
        case `${ADMIN_FETCH_EXPERTS_TAGS}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('data', action.data.responseData.result)
        case `${ADMIN_FETCH_EXPERTS_TAGS}_FAILED`:
            return state
                .set('isLoading', false)
                .set('status', null)
                .set('data', null)
        case `${ADMIN_FETCH_DESIGNATION_TAGS}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', false)
        case `${ADMIN_FETCH_DESIGNATION_TAGS}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('designationData', action.data.responseData.result)

        case `${ADMIN_FETCH_DESIGNATION_TAGS}_FAILED`:
            return state
                .set('isLoading', false)
                .set('status', null)
                .set('designationData', null)

        case `${ADMIN_FETCH_USER_TYPE}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', false)
                .set('userTypeData', null)
        case `${ADMIN_FETCH_USER_TYPE}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('userTypeData', action.data.responseData.result.data)
                .set('error', null)
        case `${ADMIN_FETCH_USER_TYPE}_FAILED`:
            return state
                .set('isLoading', false)
                .set('status', null)
                .set('userTypeData', null)
        case `${ADMIN_FETCH_EXT_DOCUMENTS}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)
                .set('extDocData', null)
        case `${ADMIN_FETCH_EXT_DOCUMENTS}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('extDocData', action.data.responseData.result)
                .set('error', null)
        case `${ADMIN_FETCH_EXT_DOCUMENTS}_FAILED`:
            return state
                .set('isLoading', false)
                .set('status', null)
                .set('extDocData', null)
        case `${ADMIN_CREATE_USER}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)
        case `${ADMIN_CREATE_USER}_SUCCESS`:
            return state
                .set('status', 'successfulCreation')
                .set('isLoading', false)
                .set('createUserData', action.data.responseData)
        case `${ADMIN_CREATE_USER}_FAILED`:
            return state
                .set('error', action.data.responseData)
                .set('isLoading', false)
                .set('status', STATUS_FAILURE)
        case `${ADMIN_DOCUMENT_LOAD_MORE}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)
        case `${ADMIN_DOCUMENT_LOAD_MORE}_SUCCESS`: {
            const data = state.get('extDocData')
            data.list.push(...action.data.responseData.result.list)
            return state
                .set('status', action.data.status)
                .set('extDocData', data)
                .set('isLoading', false)
        }
        case `${ADMIN_DOCUMENT_LOAD_MORE}_FAILED`:
            return state.set('error', STATUS_FAILED).set('isLoading', false)

        case `${ADMIN_EXPIRED_SEARCH}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)
                .set('extDocData', null)
        case `${ADMIN_EXPIRED_SEARCH}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('extDocData', action.data.responseData.result)
                .set('error', null)
        case `${ADMIN_EXPIRED_SEARCH}_FAILED`:
            return state
                .set('isLoading', false)
                .set('status', null)
                .set('extDocData', null)

        case `${ADMIN_EXTEND_DOCUMENTS}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)
                .set('extendDocState', null)
        case `${ADMIN_EXTEND_DOCUMENTS}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('extendDocState', action.data.responseData.result)
                .set('error', null)
        case `${ADMIN_EXTEND_DOCUMENTS}_FAILED`:
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('extendDocState', action.data.responseData.result)

        case `${ADMIN_CREATE_TICKET}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)
        case `${ADMIN_CREATE_TICKET}_SUCCESS`:
            return state
                .set('status', action.data.responseData)
                .set('isLoading', false)
                .set('ticket', action.data.responseData)
        case `${ADMIN_CREATE_TICKET}_FAILED`:
            return state.set('error', action.data.responseData).set('isLoading', false)

        case `${ADMIN_VERIFICATION_EMAIL}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)
        case `${ADMIN_VERIFICATION_EMAIL}_SUCCESS`:
            return state.set('status', action.data.responseData).set('isLoading', false)

        case `${ADMIN_VERIFICATION_EMAIL}_FAILED`:
            return state.set('error', action.data.responseData).set('isLoading', false)
        case `${ADMIN_ADD_USER_BACKEND}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)

        case `${ADMIN_ADD_USER_BACKEND}_SUCCESS`:
            return state
                .set('status', action.data.responseData.status)
                .set('isLoading', false)
                .set('backendUserData', action.data.responseData)

        case `${ADMIN_ADD_USER_BACKEND}_FAILED`:
            return state.set('error', action.data.responseData.status).set('isLoading', false)

        case `${ADMIN_FETCH_DOC_DETAILS}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)
                .set('docdetails', null)

        case `${ADMIN_FETCH_DOC_DETAILS}_SUCCESS`:
            return state
                .set('status', action.data.responseData.status)
                .set('isLoading', false)
                .set('docdetails', action.data.responseData.result)

        case `${ADMIN_FETCH_DOC_DETAILS}_FAILED`:
            return state
                .set('error', action.data.responseData.status)
                .set('isLoading', false)
                .set('docdetails', action.data.responseData.result)

        case `${ADMIN_REPLACE_DOC_DETAILS}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)
                .set('newdocdetails', null)

        case `${ADMIN_REPLACE_DOC_DETAILS}_SUCCESS`:
            return state
                .set('status', action.data.responseData.status)
                .set('isLoading', false)
                .set('newdocdetails', action.data.responseData.status)

        case `${ADMIN_REPLACE_DOC_DETAILS}_FAILED`:
            return state
                .set('error', action.data.responseData.status)
                .set('isLoading', false)
                .set('newdocdetails', action.data.responseData)

        case `${ADMIN_DELETE_DOC_DETAILS}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)
                .set('deleteData', null)

        case `${ADMIN_DELETE_DOC_DETAILS}_SUCCESS`:
            return state
                .set('status', action.data.responseData.status)
                .set('isLoading', false)
                .set('deleteData', action.data.responseData.status)

        case `${ADMIN_DELETE_DOC_DETAILS}_FAILED`:
            return state
                .set('error', action.data.responseData.status)
                .set('isLoading', false)
                .set('deleteData', action.data.responseData.status)

        case `${ADMIN_USER_COUNT_DETAILS}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)
                .set('userCount', null)

        case `${ADMIN_USER_COUNT_DETAILS}_SUCCESS`:
            return state
                .set('status', action.data.responseData.status)
                .set('isLoading', false)
                .set('userCount', action.data.responseData.result)

        case `${ADMIN_USER_COUNT_DETAILS}_FAILED`:
            return state
                .set('error', action.data.responseData.status)
                .set('isLoading', false)
                .set('userCount', action.data.responseData.status)

        case `${ADMIN_SESSION_TABLE}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)
                .set('isAdminDataLoading', true)
                .set('adminSessionData', null)

        case `${ADMIN_SESSION_TABLE}_SUCCESS`:
            return state
                .set('status', action.data.responseData.status)
                .set('isLoading', false)
                .set('isAdminDataLoading', false)
                .set('adminSessionData', action.data.responseData.result)

        case `${ADMIN_SESSION_TABLE}_FAILED`:
            return state
                .set('error', action.data.responseData.status)
                .set('isLoading', false)
                .set('isAdminDataLoading', false)
                .set('adminSessionData', action.data.responseData.status)

        case `${ADMIN_SESSION_TABLE_LOAD_MORE}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)
                .set('isAdminDataLoading', true)

        case `${ADMIN_SESSION_TABLE_LOAD_MORE}_SUCCESS`: {
            const data = state.get('adminSessionData')
            data.data.push(...action.data.responseData.result.data)
            return state
                .set('status', action.data.status)
                .set('adminSessionData', data)
                .set('isLoading', false)
                .set('isAdminDataLoading', true)
        }
        case `${ADMIN_SESSION_TABLE_LOAD_MORE}_FAILED`:
            return state
                .set('error', STATUS_FAILED)
                .set('isLoading', false)
                .set('isAdminDataLoading', true)

        case `${ADMIN_BLOCK_UNBLOCK_USER}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)
                .set('blockUnblockData', null)

        case `${ADMIN_BLOCK_UNBLOCK_USER}_SUCCESS`:
            return state
                .set('status', action.data.responseData.status)
                .set('isLoading', false)
                .set('blockUnblockData', action.data.responseData)

        case `${ADMIN_BLOCK_UNBLOCK_USER}_FAILED`:
            return state
                .set('error', action.data.responseData.status)
                .set('isLoading', false)
                .set('blockUnblockData', action.data.responseData.status)

        case `${ADMIN_FETCH_USER_DATA}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)
                .set('userData', null)

        case `${ADMIN_FETCH_USER_DATA}_SUCCESS`:
            return state
                .set('status', action.data.responseData.status)
                .set('isLoading', false)
                .set('userData', action.data.responseData.result)

        case `${ADMIN_FETCH_USER_DATA}_FAILED`:
            return state
                .set('error', action.data.responseData.status)
                .set('isLoading', false)
                .set('userData', action.data.responseData.result)

        case `${ADMIN_EDIT_USER_DATA}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)
                .set('isEditUserData', null)

        case `${ADMIN_EDIT_USER_DATA}_SUCCESS`:
            return state
                .set('status', action.data.responseData.status)
                .set('isLoading', false)
                .set('isEditUserData', action.data.responseData)

        case `${ADMIN_EDIT_USER_DATA}_FAILED`:
            return state
                .set('error', action.data.responseData.status)
                .set('isLoading', false)
                .set('isEditUserData', action.data.responseData)

        case `${ADMIN_GET_DOWNLOAD_LIMIT}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)
                .set('downloadLimitData', null)

        case `${ADMIN_GET_DOWNLOAD_LIMIT}_SUCCESS`:
            return state
                .set('status', action.data.responseData.status)
                .set('isLoading', false)
                .set('downloadLimitData', action.data.responseData.result)

        case `${ADMIN_GET_DOWNLOAD_LIMIT}_FAILED`:
            return state
                .set('error', action.data.responseData.status)
                .set('isLoading', false)
                .set('downloadLimitData', action.data.responseData.result)

        case `${ADMIN_SET_DOWNLOAD_LIMIT}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)
                .set('downloadLimitStatus', null)

        case `${ADMIN_SET_DOWNLOAD_LIMIT}_SUCCESS`:
            return state
                .set('status', action.data.responseData.status)
                .set('isLoading', false)
                .set('downloadLimitStatus', action.data.responseData.status)

        case `${ADMIN_SET_DOWNLOAD_LIMIT}_FAILED`:
            return state
                .set('error', action.data.responseData.status)
                .set('isLoading', false)
                .set('downloadLimitStatus', action.data.responseData.result)

        case `${ADMIN_GET_USER_PROFILE}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)
                .set('userData', null)

        case `${ADMIN_GET_USER_PROFILE}_SUCCESS`:
            return state
                .set('status', action.data.responseData.status)
                .set('isLoading', false)
                .set('userData', action.data.responseData.result)

        case `${ADMIN_GET_USER_PROFILE}_FAILED`:
            return state.set('isLoading', false).set('userData', action.data.responseData.result)
        case `${ADMIN_SET_USER_PROFILE}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)
                .set('setUserProfileSuccess', null)
        case `${ADMIN_SET_USER_PROFILE}_SUCCESS`:
            return state
                .set('status', action.data.responseData.status)
                .set('isLoading', false)
                .set('setUserProfileSuccess', action.data.responseData)
        case `${ADMIN_SET_USER_PROFILE}_FAILED`:
            return state.set('setUserProfileSuccess', action.data.responseData).set('isLoading', false)
        case ADMIN_SESSION_EMAIL_COMPLETED:
            return state.set('userEmails', action.data)
        case `${VIDEO_UPLOAD}_SUCCESS`:
            return state.set('closeVideoUpload', true)
        case `${GET_VIDEO_DATA}_PENDING`:
            return state
                .set('closeVideoUpload', false)
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)
        case `${GET_VIDEO_DATA}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .updateIn(['videoData', 'data'], oldData => {
                    const newData = action.data.responseData.result.data
                    if (action.data.resetContent) {
                        return newData
                    } else {
                        return [...oldData, ...newData]
                    }
                })
                .updateIn(['videoData', 'totalCount'], oldCount => action.data.responseData.result.totalCount)
        case `${GET_VIDEO_DATA}_FAILED`:
            return state
                .set('isLoading', false)
                .set('status', null)
                .set('data', null)
        case `${DELETE_VIDEO_DATA}_SUCCESS`:
            return state.set('reloadVideoContent', true)
        case RESET_VIDEO_DATA:
            return state.set('reloadVideoContent', false)

        case `${FETCH_ACTIVE_CST}_PENDING`:
            return state.set('status', null)
        case `${FETCH_ACTIVE_CST}_SUCCESS`:
            return state
                .set('status', action.data.responseData.status)
                .setIn(['cstGroupDetail', 'activeCST'], action.data.responseData.result)
        case `${FETCH_ACTIVE_CST}_FAILED`:
            return state.set('status', action.data.responseData.result).setIn(['cstGroupDetail'], {})
        case `${FETCH_NEW_CST}_PENDING`:
            return state.set('status', null)
        case `${FETCH_NEW_CST}_SUCCESS`:
            return state
                .set('status', action.data.responseData.status)
                .setIn(['cstGroupDetail', 'newCST'], action.data.responseData.result)
        case `${FETCH_NEW_CST}_FAILED`:
            return state.set('status', action.data.responseData.result).setIn(['cstGroupDetail'], {})
        case `${FETCH_EXPIRED_CST}_PENDING`:
            return state.set('status', null)
        case `${FETCH_EXPIRED_CST}_SUCCESS`:
            return state
                .set('status', action.data.responseData.status)
                .setIn(['cstGroupDetail', 'expiredCST'], action.data.responseData.result)
        case `${FETCH_EXPIRED_CST}_FAILED`:
            return state.set('status', action.data.responseData.result).setIn(['cstGroupDetail'], {})
        case `${FETCH_CST_GROUP_DETAIL}_PENDING`:
            return state.set('status', null)
        case `${FETCH_CST_GROUP_DETAIL}_SUCCESS`:
            const newData = action.data.responseData.result.data.map(item => {
                const { cstUsers } = item
                cstUsers.map(cstItem => {
                    cstItem.checked = false
                    return cstItem
                })
                return item
            })
            return state.set('status', action.data.responseData.status).setIn(['cstGroupDetail', 'cstById'], newData)
        case `${FETCH_CST_GROUP_DETAIL}_FAILED`:
            return state.set('status', action.data.responseData.result).setIn(['cstGroupDetail'], {})
        case `${UPDATE_CST_GROUP_DETAIL}_PENDING`:
            return state.set('isListUpdated', false)
        case `${UPDATE_CST_GROUP_DETAIL}_SUCCESS`:
            return state.set('isListUpdated', true)
        case `${UPDATE_CST_GROUP_DETAIL}_FAILED`:
            return state.set('isListUpdated', false)
        case `${LOAD_MORE_ACTIVE_CST}_PENDING`:
            return state.set('status', null)
        case `${LOAD_MORE_ACTIVE_CST}_SUCCESS`:
            console.log('calling success LOAD_MORE_ACTIVE_CST')
            return state.updateIn(['cstGroupDetail', 'activeCST'], arr => {
                const { data, totalRecords } = arr
                const newArray = [...data, ...action.data.responseData.result.data]
                return { totalRecords, data: newArray }
            })
        case `${LOAD_MORE_ACTIVE_CST}_FAILED`:
            return state.set('status', action.data.responseData.result)
        case `${LOAD_MORE_NEW_CST}_PENDING`:
            return state.set('status', null)
        case `${LOAD_MORE_NEW_CST}_SUCCESS`:
            return state.set('status', action.data.responseData.status).updateIn(['cstGroupDetail', 'newCST'], arr => {
                const { data, totalRecords } = arr
                const newArray = [...data, ...action.data.responseData.result.data]
                return { totalRecords, data: newArray }
            })
        case `${LOAD_MORE_NEW_CST}_FAILED`:
            return state.set('status', action.data.responseData.result).setIn(['cstGroupDetail'], {})
        case `${LOAD_MORE_EXPIRED_CST}_PENDING`:
            return state.set('status', null)
        case `${LOAD_MORE_EXPIRED_CST}_SUCCESS`:
            return state
                .set('status', action.data.responseData.status)
                .updateIn(['cstGroupDetail', 'expiredCST'], arr => {
                    const { data, totalRecords } = arr
                    const newArray = [...data, ...action.data.responseData.result.data]
                    return { totalRecords, data: newArray }
                })
        case `${LOAD_MORE_EXPIRED_CST}_FAILED`:
            return state.set('status', action.data.responseData.result).setIn(['cstGroupDetail'], {})
        case `${RESET_STATUS}`:
            return state.set('isListUpdated', false)

        case `${GET_USER_COUNT}_PENDING`:
            return state.set('status', null).setIn(['adminUser'], {})
        case `${GET_USER_COUNT}_SUCCESS`:
            return state
                .set('status', action.data.responseData.status)
                .setIn(['adminUser'], action.data.responseData.result.data)
        case `${GET_USER_COUNT}_FAILED`:
            return state.set('status', action.data.responseData.result).setIn(['adminUser'], {})
        case `${DELETE_USER}_PENDING`:
            return state.set('status', null).set('isListUpdated', false)
        case `${DELETE_USER}_SUCCESS`:
            return state.set('status', action.data.responseData.status).set('isListUpdated', true)
        case `${DELETE_USER}_FAILED`:
            return state.set('status', action.data.responseData.result).set('isListUpdated', false)

        case `${GET_ACTIVE_USERS}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)
                .set('activeUsers', null)
        case `${GET_ACTIVE_USERS}_SUCCESS`:
            return state
                .set('status', action.data.responseData)
                .set('isLoading', false)
                .set('activeUsers', action.data.responseData.result)
        case `${GET_ACTIVE_USERS}_FAILED`:
            return state
                .set('error', action.data.responseData)
                .set('isLoading', false)
                .set('activeUsers', action.data.responseData.result)

        case `${GET_LOP_STATS}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)
                .set('lopStats', null)
        case `${GET_LOP_STATS}_SUCCESS`:
            return state
                .set('status', action.data.responseData)
                .set('isLoading', false)
                .set('lopStats', action.data.responseData.result)
        case `${GET_LOP_STATS}_FAILED`:
            return state
                .set('error', action.data.responseData)
                .set('isLoading', false)
                .set('lopStats', action.data.responseData.result)

        case `${BLK_UNBLK_CST_USER}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)
        case `${BLK_UNBLK_CST_USER}_SUCCESS`:
            const { emails, is_blocked } = action.data && action.data.requestData

            return state
                .set('status', action.data.responseData)
                .set('isLoading', false)
                .updateIn(['cstGroupDetail', 'cstById'], arr => {
                    return arr.map(item => {
                        const { cstUsers } = item
                        cstUsers.map(cstItem => {
                            if (emails.includes(cstItem.email)) {
                                if (is_blocked) {
                                    cstItem.Status = 'Expired'
                                    cstItem.isApproved = false
                                } else {
                                    cstItem.Status = 'Approved'
                                    cstItem.isApproved = true
                                }
                            }
                            return cstItem
                        })
                        return item
                    })
                })

        case `${BLK_UNBLK_CST_USER}_FAILED`:
            return state.set('error', action.data.responseData).set('isLoading', false)

        case `${GET_DOCS_STATS}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)
                .set('docsStats', null)

        case `${GET_DOCS_STATS}_SUCCESS`:
            return state
                .set('status', action.data.responseData.data)
                .set('isLoading', false)
                .set('docsStats', action.data.responseData.data)

        case `${GET_DOCS_STATS}_FAILED`:
            return state
                .set('error', action.data.responseData.data)
                .set('isLoading', false)
                .set('docsStats', action.data.responseData.data)

        case `${GET_SHARED_DOCS}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)
                .set('sharedDocsList', [])

        case `${GET_SHARED_DOCS}_SUCCESS`:
            return state
                .set('status', action.data.responseData.data)
                .set('isLoading', false)
                .set('sharedDocsList', action.data.responseData.data)

        case `${GET_SHARED_DOCS}_FAILED`:
            return state
                .set('error', action.data.responseData.data)
                .set('isLoading', false)
                .set('sharedDocsList', [])

        case `${GET_RECIPIENT_LIST}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)
                .set('recipientsList', [])

        case `${GET_RECIPIENT_LIST}_SUCCESS`:
            return state
                .set('status', action.data.responseData.data)
                .set('isLoading', false)
                .set('recipientsList', action.data.responseData.data)

        case `${GET_RECIPIENT_LIST}_FAILED`:
            return state
                .set('error', action.data.responseData.data)
                .set('isLoading', false)
                .set('recipientsList', [])

        case `${DELETE_SHARED_DOC}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)
                .set('isDocDeleted', false)

        case `${DELETE_SHARED_DOC}_SUCCESS`:
            return state
                .set('status', action.data.responseData.data)
                .set('isLoading', false)
                .set('isDocDeleted', true)

        case `${DELETE_SHARED_DOC}_FAILED`:
            return state
                .set('error', action.data.responseData.data)
                .set('isLoading', false)
                .set('isDocDeleted', false)

        default:
            return state
    }
}

export default adminReducer
