import axios from 'axios'
import { isEmpty } from 'lodash'
function queryParams(params) {
    return Object.keys(params)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&')
}

export function makeAPICall(conf) {
    return axios(conf)
        .then(response => {
            return response
        })
        .catch(error => {
            return error.response
        })
}

export default function callApi(url, options = {}, idToken) {
    const query = options.query || {}
    const method = options.method || 'get'
    const data = options.data || null
    const headers = options.headers || {}
    const ACCESS_TOKEN = JSON.parse(localStorage.getItem('okta-token-storage'))?.accessToken?.accessToken
    if (ACCESS_TOKEN) {
        headers.Authorization = `Bearer ${ACCESS_TOKEN}`
    }
    const originalConfig = {
        method,
        query,
        url,
    }
    if (data) {
        originalConfig.data = data
    }

    if (headers) {
        originalConfig.headers = headers
    }
    if (!isEmpty(originalConfig.query)) {
        let urlWithQueryParams = url
        urlWithQueryParams += (urlWithQueryParams.indexOf('?') === -1 ? '?' : '&') + queryParams(originalConfig.query)
        delete originalConfig.query
        originalConfig.url = urlWithQueryParams
    }

    return makeAPICall(originalConfig)
}

function callAuth0(url, options = {}, idToken) {
    const query = options.query || {}
    const method = options.method || 'get'
    const data = options.data || null
    const headers = options.headers || {}
    const originalConfig = {
        method,
        query,
        url,
    }
    if (data) {
        originalConfig.data = data
    }

    if (headers) {
        originalConfig.headers = headers
    }

    if (!isEmpty(originalConfig.query)) {
        let urlWithQueryParams = url
        urlWithQueryParams += (urlWithQueryParams.indexOf('?') === -1 ? '?' : '&') + queryParams(originalConfig.query)
        delete originalConfig.query
        originalConfig.url = urlWithQueryParams
    }
    return makeAPICall(originalConfig)
}

export { callAuth0 }
