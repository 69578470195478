import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { isEmpty } from 'lodash'
import c from 'classnames'
import { withAlert } from 'react-alert'
import Slider from 'react-slick'
import { Input } from 'reactstrap'
import SliderSections from './SliderSections'
import ImageGallery from './ImageGallery'
import SectionContent from './SectionContent'
import CopySectionModal from './CopySectionModal'
import { SITE_TXT, LOP_SECTIONS_SLIDER_SETTING, isMobile, isIphoneX } from '../../Utils/constants'
import { removeLopData, getSlidesToShow } from '../../Utils/functions'
import { CloseEditLop, DeletedContent1, DeletedContent2, DeletedContent3, CopySectionIcon } from '../../Utils/svg'
import Collaborator from './Collaborator'
import DeletedSections from './DeletedSections'
import ContactWinRoom from '../../Components/ContactWinRoom'
import { ConfirmAlertBox } from '../../Containers/Library/Alerts'
import Modal from 'react-responsive-modal'

import './index.scss'
import PublishLink from '../PublisLink'

class LopContent extends Component {
    constructor(props) {
        super(props)
        const lopInfo = localStorage.getItem('myLopData') ? JSON.parse(localStorage.getItem('myLopData')) : null
        const editMode = localStorage.getItem('editMode') === 'true'
        const currentSlide =
            localStorage.getItem('currentSlide') === null ? 0 : parseInt(localStorage.getItem('currentSlide'))
        const sectionDetails = localStorage.getItem('sectionDetails')
            ? JSON.parse(localStorage.getItem('sectionDetails'))
            : {}
        this.state = {
            lopInfo,
            currentSlide,
            editMode,
            sectionDetails,
            updateSectionSlider: false,
            openCollaborator: false,
            viewDeletedSection: false,
            lopLockFlag: this.props.lopLockFlag || false,
            timer: 830000,
            timeoutModal: false,
            copySectionModal: false,
            interval: 0,
            testFlag: 0,
            redirectUrl: '/lop',
            minHeight: 100,
        }
    }

    componentDidMount() {
        const { lopInfo, sectionDetails } = this.state
        const { setLopHeader, fetchLopDeatils } = this.props
        const { id } = lopInfo
        setLopHeader(true, true, true)
        fetchLopDeatils(id, !isEmpty(sectionDetails))
        window.addEventListener('resize', this.updateLopHeight)
        this.updateLopHeight()
    }

    componentDidUpdate() {
        this.updateLopHeight()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateLopHeight)
    }

    updateLopHeight = () => {
        const { minHeight: oldHeight } = this.state
        const minHeight = window.innerHeight - 112
        if (minHeight !== oldHeight) {
            this.setState({ minHeight })
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.lopLockFlag && this.state.testFlag === 0) {
            this.setState(
                {
                    lopLockFlag: nextProps.lopLockFlag,
                    testFlag: this.state.testFlag + 1,
                },
                () => {
                    if (this.state.lopLockFlag) {
                        this.toggleEditMode(true)
                        this.showTimer()
                    }
                }
            )
        }

        if (nextProps.updateCurrentSlideNumber) {
            const { lopDetails = {} } = nextProps
            const { sectionDetail = [], sections = [] } = lopDetails
            const { email = '' } = sectionDetail[0]
            this.props.toggleSectionUpdate(email)
            this.goToLastSlide(sections.length - 1)
        }
        if (nextProps.lopRemoved || nextProps.lopError === SITE_TXT.LOP_IS_DELETED) {
            setTimeout(() => {
                removeLopData()
                this.setState({ lopInfo: null })
            }, 1000)
        }
        if (nextProps.isListUpdated) {
            this.props.publishLinkForClient()
        }

        if (nextProps.retainState) {
            this.props.resetStateToOriginal()
            this.loadOldState()
        }
    }

    loadOldState = () => {
        const { sectionDetails } = this.state
        const { fetchCurrentTemplate } = this.props
        fetchCurrentTemplate(sectionDetails)
        this.setState({ updateSectionSlider: true, sectionDetails: {} }, () => {
            setTimeout(() => {
                this.setState({ updateSectionSlider: false })
            }, 1)
        })
    }
    showTimer = () => {
        this.delayTimer = setTimeout(() => {
            this.setState({ timeoutModal: true })
            if (this.state.timeoutModal) {
                this.interval = setTimeout(() => {
                    if (this.state.timeoutModal) {
                        this.handleNo()
                    }
                }, 60000)
            }
        }, 830000)
    }

    loadCurrentSectionDetails = (currentSlide, loadCurrentTemplate = true, changeSlider = false) => {
        const { lopDetails, fetchCurrentTemplate } = this.props
        const { sections } = lopDetails
        loadCurrentTemplate && fetchCurrentTemplate(sections[currentSlide])
        localStorage.setItem('currentSlide', currentSlide)
        localStorage.setItem('sectionDetails', JSON.stringify(sections[currentSlide]))
        this.setState({ currentSlide })
        if (changeSlider) {
            this.setState({ updateSectionSlider: true }, () => {
                setTimeout(() => {
                    this.setState({ updateSectionSlider: false })
                }, 1)
            })
        }
    }

    lockMyLop = () => {
        const { lockMyLop } = this.props
        const { lopInfo } = this.state
        const { id } = lopInfo
        lockMyLop({ id, requestType: 1 })
    }

    toggleEditMode = (editMode = false) => {
        localStorage.setItem('editMode', editMode)
        this.setState({ editMode })
    }

    isFormValid = () => {
        const { lopDetails } = this.props
        const { sectionDetail, sections } = lopDetails
        const { sectionContent } = sectionDetail[0]
        const { currentSlide } = this.state
        let currentSlideIndex = currentSlide
        if (currentSlide === null || currentSlide === undefined || currentSlide === NaN || isNaN(currentSlide)) {
            currentSlideIndex = 0
        }
        const { templateId } = sections[currentSlideIndex]
        let shallProceed = true

        if (parseInt(templateId) === 4) {
            sectionContent
                .filter(x => parseInt(x.contentTypeId) === 2 || parseInt(x.contentTypeId) === 3)
                .every(item => {
                    const { images } = item
                    const { alert } = this.props
                    const { error } = alert
                    if (!images.length || images.filter(x => isEmpty(x)).length) {
                        const msg =
                            item.contentTypeId === 2 ? SITE_TXT.TXT_IMAGES_MANDATORY : SITE_TXT.TXT_DOC_MANDATORY
                        error(msg)
                        shallProceed = false
                        return false
                    }
                    return true
                })
        }
        return shallProceed
    }

    updateCurrentSlide = slideNumbder => {
        const { currentSlide, editMode } = this.state
        if (slideNumbder !== currentSlide && this.isFormValid()) {
            editMode && this.saveLopSections(editMode)
            this.loadCurrentSectionDetails(slideNumbder)
        }
    }

    goToLastSlide = currentSlide => {
        const { editMode } = this.state
        if (!editMode) {
            this.setState({ currentSlide, updateSectionSlider: true }, () => {
                setTimeout(() => {
                    this.loadCurrentSectionDetails(currentSlide, true)
                    this.setState({ updateSectionSlider: false })
                }, 1)
            })
        } else if (editMode && this.isFormValid()) {
            this.saveLopSections(editMode)
            this.setState({ currentSlide, updateSectionSlider: true }, () => {
                setTimeout(() => {
                    this.loadCurrentSectionDetails(currentSlide, true)
                    this.setState({ updateSectionSlider: false })
                }, 1)
            })
        }
    }

    saveLopSections = (editMode = true, saveButtonPressed = false) => {
        if (this.isFormValid()) {
            const { saveLopDetails, lopDetails, removedContent } = this.props
            const { sectionDetail, sections } = lopDetails
            const { currentSlide } = this.state
            const { heading, subHeading, sectionContent, email: updatedEmail } = sectionDetail[0]
            const { sectionId, sectionName, templateId, sectionHeader } = sections[currentSlide]

            let dataToSend = {
                sectionId,
                sectionName,
                sectionHeader,
                templateId,
                email: updatedEmail || SITE_TXT.CONTACT_MAIL,
                isUnLock: saveButtonPressed,
            }

            switch (parseInt(templateId)) {
                case 2:
                case 4:
                case 5:
                    const dataArray = removedContent.filter(x => x.id)
                    dataToSend = {
                        ...dataToSend,
                        sectionContent: [...sectionContent, ...dataArray].map(x => {
                            const { images, ...dataActual } = x
                            return dataActual
                        }),
                    }
                    break
                case 3:
                    dataToSend = {
                        sectionId,
                        sectionName,
                        sectionHeader,
                        templateId,
                        sectionContent,
                        isUnLock: saveButtonPressed,
                    }
                    break
                default:
                    dataToSend = {
                        ...dataToSend,
                        heading,
                        subHeading,
                        reorderItemIds: sectionContent.map(item => item.id).join(','),
                    }
            }
            saveLopDetails({ dataToSend, saveButtonPressed })

            this.toggleEditMode(editMode)
            if (saveButtonPressed) {
                this.setState({ testFlag: 0 })
                clearTimeout(this.delayTimer)
                clearTimeout(this.interval)
            }
        }
    }

    toggleCollaborator = () => {
        const { editMode, openCollaborator } = this.state
        let changeState = false
        if (!openCollaborator) {
            if (editMode) {
                if (this.isFormValid()) {
                    this.saveLopSections(editMode)
                    changeState = true
                }
            } else {
                changeState = true
            }
        }
        this.setState({
            openCollaborator: changeState,
        })
    }

    refetchLopDetails = () => {
        const { lopInfo, sectionDetails } = this.state
        const { setLopHeader, fetchLopDeatils } = this.props
        const { id } = lopInfo
        setLopHeader(true, true, true)
        fetchLopDeatils(id, !isEmpty(sectionDetails))
    }

    toggleDeletedSections = () => {
        const { editMode, viewDeletedSection } = this.state
        let changeState = false
        if (!viewDeletedSection) {
            if (editMode) {
                if (this.isFormValid()) {
                    this.saveLopSections(editMode)
                    changeState = true
                    this.setState({ currentSlide: 0 })
                    localStorage.setItem('currentSlide', 0)
                }
                this.refetchLopDetails()
            } else {
                changeState = true
                this.refetchLopDetails()
            }
        }

        this.setState({
            viewDeletedSection: changeState,
        })
    }

    removeLop = () => {
        const { lopInfo } = this.state
        const { id } = lopInfo
        const { deteleLop } = this.props
        deteleLop(id)
    }

    checkToDeleteSection = () => {
        const { TXT_CONFIRM_DELETE, TXT_SECTION_DELETE_MESSAGE } = SITE_TXT
        ConfirmAlertBox(TXT_CONFIRM_DELETE, TXT_SECTION_DELETE_MESSAGE, () => this.removeSelectedSection())
    }

    removeSelectedSection = () => {
        const { currentSlide } = this.state
        const { lopDetails, deleteSection } = this.props
        const { sections = [] } = lopDetails
        deleteSection(currentSlide, sections[currentSlide])
        let newSlide = currentSlide === sections.length - 1 ? currentSlide - 1 : currentSlide
        setTimeout(() => {
            this.loadCurrentSectionDetails(newSlide, true)
        }, 1)
    }
    handleCloseModal = () => {
        this.props.contactWinRoomState(false)
    }

    toggleCopySection = copySectionModal => {
        if (copySectionModal) {
            this.props.getMyLops()
        }
        this.setState({ copySectionModal })
    }

    checkToOpenToggleSection = () => {
        if (this.isFormValid()) {
            this.saveLopSections()
            this.toggleCopySection(true)
        }
    }

    closeTimeoutModal = () => {
        this.setState({
            timeoutModal: false,
            testFlag: true,
        })
    }

    handleYes = () => {
        const { lockMyLop } = this.props
        const { lopInfo } = this.state
        const { id } = lopInfo
        lockMyLop({ id, requestType: 3 })
        this.setState({ testFlag: true })
        clearTimeout(this.delayTimer)
        clearTimeout(this.interval)
        this.showTimer()
        this.closeTimeoutModal()
    }

    handleNo = () => {
        const { lockMyLop } = this.props
        const { lopInfo } = this.state
        const { id } = lopInfo
        this.closeTimeoutModal()
        this.toggleEditMode(false)
        this.setState({ testFlag: 0 })
        clearTimeout(this.delayTimer)
        clearTimeout(this.interval)
        lockMyLop({ id, requestType: 2 })
    }

    duplicateLop = () => {
        const { lopInfo } = this.state
        const { id } = lopInfo
        removeLopData(id)
        this.setState({ lopInfo: null, redirectUrl: '/createLop' })
    }

    render() {
        const {
            lopInfo,
            currentSlide,
            editMode,
            updateSectionSlider,
            openCollaborator,
            timeoutModal,
            redirectUrl,
            minHeight,
            copySectionModal,
            viewDeletedSection,
        } = this.state

        const styleClasses = {
            modal: 'timeoutModal',
            overlay: 'overlayAlbumModal',
            closeButton: 'close-btn-modal',
        }

        const {
            appWidth = 1024,
            lopDetails,
            isListUpdated,
            removedContent,
            publishLink,
            contactWinRoomFlag,
            restoreInTemplate4,
            additionalArticles,
            updateLopSectionDetails,
            restoreLopStatus,
            restoreSection,
        } = this.props
        const disableReorder = appWidth <= 1024
        const { sectionDetail = [], sections = [], deleted_sections = [] } = lopDetails
        const permissionId = lopInfo ? parseInt(lopInfo.permissionId) || 0 : 0
        const content = sections[currentSlide] || {}
        const { templateId = null } = content
        const showDeletedContent =
            editMode &&
            ((removedContent.length && parseInt(templateId) === 4) ||
                (additionalArticles.length && parseInt(templateId) === 5))
        const heightAdjusted = showDeletedContent ? minHeight - 100 : minHeight
        const { email = '' } = sectionDetail[0] || {}
        const slidesToShow = getSlidesToShow(appWidth)
        const variantwidth = isMobile || isIphoneX ? false : true
        const sliderSettings = {
            ...LOP_SECTIONS_SLIDER_SETTING,
            slidesToShow,
            variableWidth: variantwidth,
        }
        return (
            <Fragment>
                <main
                    className={c('lopSectionsContainer', {
                        paddingTop: editMode,
                    })}
                    style={{ minHeight: `${heightAdjusted}px` }}
                >
                    {!lopInfo && <Redirect to={redirectUrl} />}
                    {!isEmpty(lopDetails) && lopInfo && !openCollaborator && !viewDeletedSection ? (
                        <Fragment>
                            {editMode && (
                                <div className="editContent">
                                    {SITE_TXT.TXT_EDITING_LOP}
                                    <span onClick={() => this.saveLopSections(false, true)}>
                                        <CloseEditLop />
                                    </span>
                                </div>
                            )}
                            <SliderSections
                                currentSlide={currentSlide}
                                editMode={editMode}
                                updateSectionSlider={updateSectionSlider}
                                sections={sections}
                                reloadContent={this.loadCurrentSectionDetails}
                                updateCurrentSlide={this.updateCurrentSlide}
                                deleteSection={this.removeSelectedSection}
                                deleteLop={this.removeLop}
                                lockMyLop={this.lockMyLop}
                                duplicateLop={this.duplicateLop}
                                appWidth={appWidth}
                                content={content}
                                sliderSettings={sliderSettings}
                                isExternal={!!lopInfo.isExternal}
                                handleCollaborator={this.toggleCollaborator}
                                toggleDeletedSections={this.toggleDeletedSections}
                                collaboratorCount={parseInt(sectionDetail[0].collaboratorsCount)}
                            />
                            <ImageGallery currentSlide={currentSlide} content={content} editMode={editMode} />
                            <SectionContent
                                content={sectionDetail[0]}
                                sectionDetails={content}
                                editMode={editMode}
                                currentSlide={currentSlide}
                                isListUpdated={isListUpdated}
                                disableReorder={disableReorder}
                                appWidth={appWidth}
                                sliderSettings={sliderSettings}
                                sections={sections}
                                updateCurrentSlide={this.updateCurrentSlide}
                            />
                            {showDeletedContent && removedContent.length && parseInt(templateId) === 4 ? (
                                <div className="removedContent">
                                    <h3>
                                        <span>{SITE_TXT.TXT_DELETED_CONTENT}</span>
                                    </h3>
                                    <Slider {...sliderSettings}>
                                        {removedContent.map((item, i) => {
                                            const { heading, subHeading, contentTypeId, id } = item
                                            const subSvg =
                                                contentTypeId === 1 ? <DeletedContent1 /> : <DeletedContent2 />
                                            const mainSvg = contentTypeId === 3 ? <DeletedContent3 /> : subSvg
                                            return (
                                                <div key={id} className="deletedItemMain">
                                                    {mainSvg}
                                                    <h2 className="ellipsisPro" title={heading}>
                                                        {heading}
                                                    </h2>
                                                    <h3 className="ellipsisPro" title={subHeading}>
                                                        {subHeading}
                                                    </h3>
                                                    <hr />
                                                    <span
                                                        onClick={() =>
                                                            restoreInTemplate4(i, {
                                                                ...item,
                                                                isDelete: 'false',
                                                            })
                                                        }
                                                    >
                                                        Restore
                                                    </span>
                                                </div>
                                            )
                                        })}
                                    </Slider>
                                </div>
                            ) : null}
                            <div
                                className={c('buttonContent', {
                                    showDeletedContent,
                                })}
                            >
                                {editMode ? (
                                    <Fragment>
                                        <div className="copySection" onClick={this.checkToOpenToggleSection}>
                                            <CopySectionIcon />
                                            {SITE_TXT.COPY_PAGE}
                                        </div>
                                        <div className="rightSide">
                                            {permissionId === 4 && sections.length > 1 && (
                                                <span
                                                    className="btn btn-danger btn-lg"
                                                    onClick={this.checkToDeleteSection}
                                                >
                                                    {SITE_TXT.TXT_DELETE}
                                                </span>
                                            )}
                                            <span
                                                className="btn btn-primary btn-lg"
                                                onClick={() => this.saveLopSections(false, true)}
                                            >
                                                {SITE_TXT.TXT_DONE}
                                            </span>
                                        </div>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        {currentSlide !== sections.length - 1 && (
                                            <span
                                                className="btn btn-primary btn-lg"
                                                onClick={() => this.updateCurrentSlide(currentSlide + 1)}
                                            >
                                                {SITE_TXT.TXT_NEXT}
                                            </span>
                                        )}
                                    </Fragment>
                                )}
                            </div>
                        </Fragment>
                    ) : openCollaborator && !viewDeletedSection && lopInfo ? (
                        <Collaborator
                            currentSlide={currentSlide}
                            toggleCollaborator={this.toggleCollaborator}
                            id={lopInfo.id}
                            permissionId={parseInt(lopInfo.permissionId) || 0}
                            reloadContent={this.loadCurrentSectionDetails}
                            appWidth={appWidth}
                        />
                    ) : (
                        viewDeletedSection && (
                            <DeletedSections
                                sections={deleted_sections}
                                restoreSection={restoreSection}
                                restoreLopStatus={restoreLopStatus}
                                toggleDeletedSections={this.toggleDeletedSections}
                                lopId={lopInfo.id}
                                refetchLopDetails={this.refetchLopDetails}
                            />
                        )
                    )}

                    {contactWinRoomFlag && (
                        <ContactWinRoom onCloseModal={this.handleCloseModal} contactWinRoomFlag={contactWinRoomFlag} />
                    )}

                    {timeoutModal && (
                        <Modal
                            open
                            onClose={() => this.closeTimeoutModal()}
                            center
                            classNames={styleClasses}
                            closeOnEsc={false}
                            closeOnOverlayClick={false}
                        >
                            <div className="header-text">{SITE_TXT.TXT_LOP_EXTEND_HEADER}</div>
                            <div className="alert-msg">{SITE_TXT.TXT_LOP_EXTEND_MSG}</div>
                            <div className="button-set">
                                <button className="btn-yes" onClick={this.handleYes}>
                                    Yes
                                </button>
                                <button className="btn-no" onClick={this.handleNo}>
                                    No
                                </button>
                            </div>
                        </Modal>
                    )}

                    {copySectionModal && (
                        <CopySectionModal
                            currentLop={lopInfo.id}
                            sectionContent={content}
                            onClose={this.toggleCopySection}
                        />
                    )}

                    {publishLink && (
                        <PublishLink
                            onClose={this.props.publishLinkForClient}
                            id={lopInfo.id}
                            addExternalUser={this.props.addExternalUser}
                        />
                    )}
                </main>
                <div
                    className={c('contactContent', {
                        showDeletedContent,
                    })}
                >
                    {SITE_TXT.GET_IN_TOUCH}
                    {editMode ? (
                        <Input
                            value={email}
                            className="emailInput"
                            maxLength="70"
                            onChange={e => updateLopSectionDetails('email', e.target.value)}
                        />
                    ) : (
                        <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
                            {email}
                        </a>
                    )}
                </div>
            </Fragment>
        )
    }
}

LopContent.propTypes = {
    additionalArticles: PropTypes.instanceOf(Array).isRequired,
    removedContent: PropTypes.instanceOf(Array).isRequired,
    deleteSection: PropTypes.func.isRequired,
    setLopHeader: PropTypes.func.isRequired,
    getMyLops: PropTypes.func.isRequired,
    fetchLopDeatils: PropTypes.func.isRequired,
    deteleLop: PropTypes.func.isRequired,
    fetchCurrentTemplate: PropTypes.func.isRequired,
    toggleSectionUpdate: PropTypes.func.isRequired,
    lockMyLop: PropTypes.func.isRequired,
    publishLinkForClient: PropTypes.func.isRequired,
    contactWinRoomState: PropTypes.func.isRequired,
    addExternalUser: PropTypes.func.isRequired,
    resetStateToOriginal: PropTypes.func.isRequired,
    restoreInTemplate4: PropTypes.func.isRequired,
    updateLopSectionDetails: PropTypes.func.isRequired,
    lopDetails: PropTypes.instanceOf(Object).isRequired,
    lopRemoved: PropTypes.bool.isRequired,
    retainState: PropTypes.bool.isRequired,
    updateCurrentSlideNumber: PropTypes.bool.isRequired,
    isListUpdated: PropTypes.bool.isRequired,
    contactWinRoomFlag: PropTypes.bool.isRequired,
    publishLink: PropTypes.bool.isRequired,
    lopError: PropTypes.string.isRequired,
    appWidth: PropTypes.number,
}

LopContent.defaultProps = {
    appWidth: 1024,
}

export default withAlert(LopContent)
