import React, { Component, Fragment } from 'react'
import Modal from 'react-responsive-modal'
import PropTypes from 'prop-types'
import { withAlert } from 'react-alert'
import Inputbox from '../../../../Inputbox'
import {
    SITE_TXT,
    IMAGE_CROP_TEAM_PROFILE,
    IMAGE_CROP_SIGNATURE,
    MAX_NAME_LENGTH_100,
} from '../../../../../Utils/constants'
import ThumbnailEditorModal from '../../../../ThumbnailEditorModal'
import { getPrefixedUrl } from '../../../../../Utils/apis'
import { UploadIcon, AddPartnerProfile } from '../../../../../Utils/svg'

import './index.scss'

class PartnerModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isEditMode: false,
            open: this.props.open,
            isModalOpen: false,
            base64path: null,
            imageSrc: null,
            deliverImg1Src: null,
            deliverImg2Src: null,
            imageId: '',
            formData: {
                sectionId: '',
                name: '',
                designation: '',
                image1: '',
                image2: '',
            },
            sectionId: this.props.sectionDetails.sectionId,
            templateId: this.props.sectionDetails.templateId,
            addEdit: false,
            id: this.props.partnerData.id || null,
            cropProportion: IMAGE_CROP_TEAM_PROFILE,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.isEditMode !== prevState.isEditMode && prevState.addEdit === false) {
            return {
                isEditMode: nextProps.isEditMode,
                addEdit: true,
                formData: {
                    image1: nextProps.partnerData.image,
                    image2: nextProps.partnerData.signature,
                    name: nextProps.partnerData.name,
                    designation: nextProps.partnerData.designation,
                },
            }
        } else return null
    }

    openUploadModal = field => {
        this.setState(
            {
                imageId: field,
            },
            () => {
                if (this.state.imageId === 'image1') {
                    this.setState(
                        {
                            cropProportion: IMAGE_CROP_TEAM_PROFILE,
                        },
                        () => {
                            this.setState({
                                isModalOpen: true,
                            })
                        }
                    )
                } else if (this.state.imageId === 'image2') {
                    this.setState(
                        {
                            cropProportion: IMAGE_CROP_SIGNATURE,
                        },
                        () => {
                            this.setState({
                                isModalOpen: true,
                            })
                        }
                    )
                }
            }
        )
    }

    closeUploadModal = () => {
        this.setState({
            isModalOpen: false,
        })
    }

    getSrcFunction = reader => {
        if (this.state.imageId === 'image1') {
            this.setState({
                deliverImg1Src: reader.result,
                imageSrc: reader.result,
                base64path: reader.result,
            })
        } else if (this.state.imageId === 'image2') {
            this.setState({
                deliverImg2Src: reader.result,
                imageSrc: reader.result,
                base64path: reader.result,
            })
        }
    }

    getBase64(file) {
        var reader = new FileReader()
        reader.addEventListener(
            'load',
            () => {
                this.getSrcFunction(reader)
            },
            false
        )
        if (file) {
            reader.readAsDataURL(file)
        }
    }

    handleSave = (thumbnailId, formTosave, imageTosave) => {
        if (this.state.imageId === 'image1') {
            this.setState({
                formData: { ...this.state.formData, image1: imageTosave },
            })
            this.getBase64(imageTosave)
        } else if (this.state.imageId === 'image2') {
            this.setState({
                formData: { ...this.state.formData, image2: imageTosave },
            })
            this.getBase64(imageTosave)
        }
        this.closeUploadModal()
    }

    handleChange = (field, value) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: value,
            },
        })
    }

    isFormValid = () => {
        const { alert } = this.props
        const { error } = alert
        const { name } = this.state.formData
        if (name.trim().length > MAX_NAME_LENGTH_100) {
            error(SITE_TXT.TXT_NAME_MAX_LENGTH)
            return false
        }
        return true
    }

    createFormData = () => {
        const { name = '', designation = '', image1 = '', image2 = '' } = this.state.formData
        const { id = '', sectionId = '', templateId = '' } = this.state

        let data = new FormData()
        data.append('sectionId', sectionId)
        data.append('name', name)
        data.append('designation', designation)
        data.append('image', image1)
        data.append('signature', image2)
        data.append('templateId', templateId)

        if (this.state.isEditMode) {
            data.append('id', id)
        }

        return data
    }
    handleSavePartner = e => {
        e.preventDefault()
        if (!this.isFormValid()) {
            return
        }
        const { addPartner, editPartner, onCloseModal } = this.props
        this.setState({ addEdit: true }, () => {
            const validData = this.createFormData()
            if (this.state.isEditMode) {
                editPartner(validData)
            } else {
                addPartner(validData)
            }
            onCloseModal()
        })
    }

    render() {
        const { TXT_ADD_PARTNER_PLACEHOLDER_1, TXT_ADD_PARTNER_PLACEHOLDER_2 } = SITE_TXT
        const { base64path, isModalOpen, formData, imageId, deliverImg1Src, deliverImg2Src, isEditMode } = this.state

        const { name, designation } = formData

        const { onCloseModal } = this.props

        const thumbImages = {
            deliverImg1Src,
            deliverImg2Src,
        }
        const partnerImg = formData[`${this.state.imageId}`]

        const styleClasses = {
            modal: 'partnerModal',
            overlay: 'overlayAlbumModal',
            closeButton: 'close-btn-modal',
        }
        return (
            <Modal open onClose={onCloseModal} center classNames={styleClasses}>
                <p className="title">{!isEditMode ? SITE_TXT.TXT_ADD_PARTNER : SITE_TXT.TXT_EDIT_PARTNER}</p>
                <form>
                    <div className="profile-img">
                        <label htmlFor="profile">{SITE_TXT.TXT_IMAGE}</label>
                        <div className="uploadProfile">
                            {formData.image1 ? (
                                <img
                                    alt="img"
                                    src={deliverImg1Src || getPrefixedUrl(`${formData.image1}`)}
                                    className="profile-image"
                                />
                            ) : (
                                <div className="dummyContent" />
                            )}
                            <div className="browse-icon" onClick={() => this.openUploadModal('image1')}>
                                <AddPartnerProfile />
                            </div>
                        </div>
                    </div>

                    <div className="signature-image">
                        <label htmlFor="signature">{SITE_TXT.TXT_SIGNATURE}</label>
                        <div className="uploadSignature">
                            {formData.image2 ? (
                                <img
                                    className="signature-image"
                                    alt="img"
                                    src={deliverImg2Src || getPrefixedUrl(`${formData.image2}`)}
                                />
                            ) : (
                                <div onClick={() => this.openUploadModal('image2')}>
                                    <UploadIcon width="22" height="20" />
                                    <br />
                                    {SITE_TXT.TXT_PARTNER_UPLOAD + ' '}
                                    <span className="browse-txt">{SITE_TXT.TXT_PARTNER_UPLOAD_BROWSE}</span>
                                </div>
                            )}
                            {formData.image2 && (
                                <div className="browse-icon" onClick={() => this.openUploadModal('image2')}>
                                    <AddPartnerProfile />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="details">
                        <label htmlFor="details">{SITE_TXT.TXT_PARTNER_DETAILS}</label>
                        <Inputbox
                            placeholder={TXT_ADD_PARTNER_PLACEHOLDER_1}
                            type="text"
                            fieldFor="name"
                            onChange={this.handleChange}
                            value={TXT_ADD_PARTNER_PLACEHOLDER_1 !== name ? name : ''}
                            max={40}
                        />
                        <Inputbox
                            placeholder={TXT_ADD_PARTNER_PLACEHOLDER_2}
                            type="text"
                            fieldFor="designation"
                            onChange={this.handleChange}
                            value={TXT_ADD_PARTNER_PLACEHOLDER_2 !== designation ? designation : ''}
                            max={40}
                        />
                    </div>
                    <div className={'buttonContainer'}>
                        <button className="btn btn-outline-secondary btn-lg" onClick={onCloseModal}>
                            {SITE_TXT.TXT_CANCEL}
                        </button>
                        <button
                            disabled={name.trim() === ''}
                            className="btn btn-primary btn-lg"
                            onClick={e => this.handleSavePartner(e)}
                        >
                            {SITE_TXT.TXT_DONE}
                        </button>
                    </div>
                </form>
                <Fragment>
                    {isModalOpen && (
                        <ThumbnailEditorModal
                            isAdminPage
                            onlyImage
                            isModalOpen
                            imageId={imageId}
                            thumbnailImg={partnerImg || null}
                            thumbImages={thumbImages}
                            deliverImgageSrc={base64path}
                            editCancel={() => this.closeUploadModal()}
                            handleSave={(id, form, test) => this.handleSave(id, form, test)}
                            imgCropObj={this.state.cropProportion}
                            overlayClass="uploadGalleryImageOverlay"
                            modalClass="partnerImageModal"
                        />
                    )}
                </Fragment>
            </Modal>
        )
    }
}

PartnerModal.propTypes = {
    addPartner: PropTypes.func.isRequired,
    editPartner: PropTypes.func.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    sectionDetails: PropTypes.instanceOf(Object).isRequired,
    partnerData: PropTypes.instanceOf(Object).isRequired,
    isListUpdated: PropTypes.bool,
}

export default withAlert(PartnerModal)
