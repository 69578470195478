import React, { Component } from 'react'
import '../../Components/Admin/admin.scss'
import VideoData from '../../Components/Admin/VideoData'

class CstWithLopVideoComp extends Component {
    render() {
        return (
            <main className="admin-container">
                <VideoData
                    appWidth={this.props.appWidth}
                    isLoading={this.props.isLoading}
                    reloadVideoContent={this.props.reloadVideoContent}
                    closeVideoUpload={this.props.closeVideoUpload}
                    userVideos={this.props.userVideos}
                    uploadVideo={this.props.uploadVideo}
                    getAdminVideo={this.props.getAdminVideo}
                    deleteAdminVideo={this.props.deleteAdminVideo}
                    resetAdminVideo={this.props.resetAdminVideo}
                />
            </main>
        )
    }
}

export default CstWithLopVideoComp
