import React from 'react'
import PropTypes from 'prop-types'
import { SortableContainer } from 'react-sortable-hoc'

import Item from './Item'
import './index.scss'

const SortableList = SortableContainer(props => {
    const {
        data,
        editMode,
        appWidth,
        currentSlide,
        deleteInTemplate,
        updateTemplate,
        toggleModal,
        toggleNotesModal,
        sectionDetails,
        disableReorder,
        createSharableLInk,
        updateMultipleVideos,
    } = props
    return (
        <div className="customSectionContainer">
            {data.map((item, index) => {
                return (
                    <Item
                        key={index}
                        index={index}
                        indexItem={index}
                        item={item}
                        appWidth={appWidth}
                        disabled={!editMode || data.length < 2 || disableReorder}
                        editMode={editMode}
                        currentSlide={currentSlide}
                        sectionDetails={sectionDetails}
                        toggleModal={toggleModal}
                        deleteInTemplate={deleteInTemplate}
                        updateTemplate={updateTemplate}
                        toggleNotesModal={toggleNotesModal}
                        createSharableLInk={createSharableLInk}
                        updateMultipleVideos={updateMultipleVideos}
                    />
                )
            })}
        </div>
    )
})

SortableList.propTypes = {
    sectionDetails: PropTypes.instanceOf(Object).isRequired,
    data: PropTypes.instanceOf(Array).isRequired,
    appWidth: PropTypes.number.isRequired,
    currentSlide: PropTypes.number.isRequired,
    editMode: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    updateTemplate: PropTypes.func.isRequired,
    deleteInTemplate: PropTypes.func.isRequired,
    toggleNotesModal: PropTypes.func.isRequired,
    createSharableLInk: PropTypes.func.isRequired,
}

export default SortableList
