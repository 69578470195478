export default class PDFJs {
    init = (source, element) => {
        const iframe = document.createElement('iframe')
        iframe.src = `/pdfjs/web/viewer.html?file=${encodeURIComponent(source)}#page=1&zoom=page-fit`
        iframe.width = '100%'
        iframe.height = '100%'

        element.appendChild(iframe)
    }
}
