import React, { useEffect, useCallback, useState } from 'react'
import { connect } from 'react-redux'
import XLSX from 'xlsx'
import { createStructuredSelector } from 'reselect'
import { lopStats } from '../../Containers/Admin/selector'
import moment from 'moment'
import { getLopStats } from '../../Containers/Admin/actions'
import { Table, Input } from 'antd'
import './LopDataTable.scss'
import 'antd/dist/antd.css'
import { lopDataColumns } from '../../Containers/Admin/constant'
import { DownloadIcon } from '../../Utils/svg'
const { Search } = Input

const LOPDataTable = ({ getLopStats, lopStats }) => {
    const [lopData, setLopData] = useState({
        table: [],
        cst_user: 0,
        lop_created: 0,
        external_views: 0,
    })
    const [search, setSearch] = useState('')

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getLopStatsCall = useCallback(() => getLopStats(), [])
    useEffect(() => {
        getLopStatsCall()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getLopStatsCall])

    useEffect(() => {
        if (lopStats) {
            const { data } = lopStats
            if (data) {
                const { table_data, total_cst_users_with_lop } = data
                const today = moment()
                const lastMonthDate = moment().subtract(1, 'months')
                const lastOneMonthRecord = []

                table_data.map((item, index) => {
                    item.key = index
                    item.created_date = moment(item.created_date).format('L')
                    const recordDate = moment(item.created_date, 'DD/MM/YYYY')
                    if (recordDate.isBetween(lastMonthDate, today, 'days', '[]')) {
                        lastOneMonthRecord.push(item)
                    }
                    return item
                })
                let externalViews = 0
                lastOneMonthRecord.forEach(item => {
                    externalViews += item.total_external_viewer
                })
                setLopData({
                    ...lopData,
                    table: table_data,
                    cst_user: total_cst_users_with_lop,
                    lop_created: lastOneMonthRecord.length,
                    external_views: externalViews,
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lopStats])

    const dataCard = (title, text, value) => {
        return (
            <div className="card">
                <div className="card-title"> {title}</div>
                <div className="card-template">
                    <div className="card-value">{value}</div>
                    <div className="card-text">{text}</div>
                </div>
            </div>
        )
    }

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra)
    }

    const handleInputChange = value => {
        setSearch(value)
    }

    useEffect(() => {
        if (search) {
            const tableData = lopData.table
            const newTable = tableData.filter(
                item =>
                    item.name.toLowerCase().includes(search) ||
                    item.created_by_email.toLowerCase().includes(search) ||
                    item.created_by_name.toLowerCase().includes(search)
            )

            setLopData({ ...lopData, table: newTable })
        } else {
            const { data } = lopStats
            if (data) {
                const { table_data } = data
                setLopData({ ...lopData, table: table_data })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])

    useEffect(() => {
        const dom = document.querySelector('.ant-pagination-options')
        if (dom) {
            const node = document.createElement('DIV')
            const textNode = document.createTextNode('Rows per page')
            node.appendChild(textNode)
            dom.append(node)
        }
    })
    const handleDataDownload = () => {
        console.log('lopData.table', lopData.table)
        if (lopStats) {
            const { data } = lopStats
            if (data) {
                const { table_data } = data
                const tempTableData = table_data.map(item => {
                    delete item.key
                    return item
                })
                const animalWS = XLSX.utils.json_to_sheet(tempTableData)
                const wb = XLSX.utils.book_new() // make Workbook of Excel
                XLSX.utils.book_append_sheet(wb, animalWS, 'Lop Data') // sheetAName is name of Worksheet
                XLSX.writeFile(wb, 'Lop_Data.xlsx') // name of the file is 'book.xlsx'
            }
        }
    }
    return (
        <div className="lop-data-table">
            <div>
                <div className="card-container">
                    {dataCard('Total CST users with LOP', 'This month', lopData.cst_user)}
                    {dataCard('Total Number of LOPs created', 'This month', lopData.lop_created)}
                    {dataCard('Total External views', 'This month', lopData.external_views)}
                </div>
                <div className="search-input">
                    <Search size="large" placeholder="Search" allowClear onSearch={handleInputChange} />
                </div>
                <div className="downloadExcel" onClick={handleDataDownload}>
                    <DownloadIcon fill="white" /> Download excel
                </div>
                <Table
                    columns={lopDataColumns}
                    dataSource={lopData.table}
                    pagination={{
                        defaultPageSize: 5,
                        defaultCurrent: 1,
                        showSizeChanger: true,
                        pageSizeOptions: ['5', '10', '15', '20'],
                        locale: { items_per_page: '' },
                    }}
                    onChange={onChange}
                />
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    lopStats: lopStats(),
})
const mapDispatchToProps = {
    getLopStats,
}

export default connect(mapStateToProps, mapDispatchToProps)(LOPDataTable)
