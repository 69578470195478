import React, { Component } from 'react'
import Modal from 'react-responsive-modal'
import { createMarkup } from '../../../Utils/functions'

export default class NotesModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openNotes: this.props.openNotes || false,
        }
    }
    render() {
        const { openNotes } = this.state
        const { onNotesCloseModal, notes } = this.props
        return (
            openNotes && (
                <Modal
                    open={openNotes}
                    center
                    classNames={{
                        modal: 'comp-modal notes-modal',
                        overlay: 'lop-modal-overlay',
                        closeButton: 'close-btn-modal',
                    }}
                    onClose={() => onNotesCloseModal()}
                >
                    {/* <div className="notes-header">{''}</div> */}
                    <div className="notes-body">
                        <div dangerouslySetInnerHTML={createMarkup(notes)} />
                    </div>
                </Modal>
            )
        )
    }
}

NotesModal.defaultProps = {
    heading: 'NOTES',
}
