import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import {
    selectLoading,
    selectStatus,
    selectCategoryData,
    selectDocumentsData,
    downloadDocumentsData,
    emailDocumentsData,
    selectEditStatus,
    isListUpdated,
    isCopyDoc,
    reGenLink,
    shareDoc,
    multiShareDoc,
    selectClosePopUpBox,
} from './selector'
import LibraryCategory from '../../Components/LibraryCategory'
import {
    libraryCategory,
    libraryDocuments,
    libraryDocumentsMore,
    addPhase,
    editPhase,
    trashPhase,
    downloadDocuments,
    saveDocuments,
    emailDocuments,
    postShareDocuments,
    copyShareDocuments,
    postCopyShareDocuments,
    multipleShareDocs,
} from './actions'

const mapStateToProps = createStructuredSelector({
    isLoading: selectLoading(),
    status: selectStatus(),
    editStatus: selectEditStatus(),
    categoryData: selectCategoryData(),
    documentsData: selectDocumentsData(),
    downloadDocumentsData: downloadDocumentsData(),
    emailDocumentsData: emailDocumentsData(),
    isListUpdated: isListUpdated(),
    isCopyDoc: isCopyDoc(),
    reGenLink: reGenLink(),
    shareDoc: shareDoc(),
    multiShareDoc: multiShareDoc(),
    closePopUpBox: selectClosePopUpBox(),
})

const mapDispatchToProps = {
    libraryCategory,
    libraryDocuments,
    libraryDocumentsMore,
    addPhase,
    editPhase,
    trashPhase,
    downloadDocuments,
    saveDocuments,
    emailDocuments,
    postShareDocuments,
    copyShareDocuments,
    postCopyShareDocuments,
    multipleShareDocs,
}
export default connect(mapStateToProps, mapDispatchToProps)(LibraryCategory)
