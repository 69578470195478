import React, { Component } from 'react'
import {
    PencilIcon,
    DownloadIcon,
    MailtoIcon,
    // VideoLink,
    DeleteBinIcon,
    FoldereIcon,
    FileIcon,
} from '../../Utils/svg'

import {
    getFileExt,
    isEmpty,
    isValidPattern,
    // createMarkup,
} from '../../Utils/functions'
import {
    SITE_TXT,
    isMobile,
    isAdmin,
    isIphoneX,
    DOT_SLIDER_4_ROWS,
    EMAIL_PATTERN_DOMAIN,
    // ENTER_KEY_CODE,
} from '../../Utils/constants'
import Checkbox from '../Checkbox'
import InfiniteScroll from 'react-infinite-scroller'
import Inputbox from '../Inputbox'
import RowExpansion from './RowExpansion'
import Modal from 'react-responsive-modal'
import FolderModal from './FolderModal'
import FileModal from './FileModal'
// import VideoModal from './VideoModal'
import { ConfirmAlertBox } from '../../Containers/Library/Alerts'
import { getPrefixedUrl } from '../../Utils/apis'
import Slider from 'react-slick'

import TabComp from '../TabComp'
// import { combinator } from 'postcss-selector-parser'
class GoldStandard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            expandedRows: false,
            rowId: null,
            selected: {},
            videoSelected: {},
            selectAll: 0,
            documentsData: [],
            pageNumber: 1,
            pageSize: 20,
            sortColumn: 'contentName',
            sortType: 'ASC',
            isLoading: false,
            search: '',
            isGoldLoading: this.props.isGoldLoading,
            lopGoldItemsMore: this.props.lopGoldItemsMore,
            requestType: localStorage.getItem('requestType'),
            foldersList: this.props.foldersList,
            isListUpdated: this.props.isListUpdated,
            isEditMode: false,
            folderId: null,
            docId: null,
            open: false,
            openFolder: false,
            ondeleteSuccessStatus: false,
            stringContainsWord: '',
            downloadDoc: false,
            submitted: false,
            email: '',
            openForEmail: false,
            // openVideo: false,
            // videoUrl: this.props.videoUrl || '',
            // openPlayModal: false,
        }
    }

    handleChange = (field, value) => {
        this.setState({ email: { ...this.state.email, [field]: value } })
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { documentsData, lopGoldItemsMore, isLoading, foldersList, isListUpdated } = nextProps
        this.setState(
            {
                documentsData: documentsData,
                lopGoldItemsMore: lopGoldItemsMore,
                isLoading: isLoading,
                foldersList: foldersList,
                isListUpdated: isListUpdated,
                ondeleteSuccessStatus: false,
            },
            () => {
                if (this.state.isListUpdated) {
                    this.setState(
                        {
                            pageNumber: 1,
                        },
                        () => {
                            let data = {
                                search: this.state.search,
                                pageSize: isIphoneX || window.innerWidth <= 767 ? 100000 : this.state.pageSize,
                                sortType: this.state.sortType,
                                sortColumn: this.state.sortColumn,
                                pageNumber: this.state.pageNumber,
                                requestType: this.state.requestType,
                            }
                            this.props.lopGoldItems(data)
                            this.props.lopFetchFolderList({
                                requestType: this.state.requestType,
                                id: '',
                            })
                            this.setState({ selectAll: 0, selected: {} })
                        }
                    )
                }

                if (this.state.downloadDoc === true && nextProps.downloadDocumentsData !== null) {
                    const { downloadDocumentsData } = nextProps
                    if (downloadDocumentsData.result && downloadDocumentsData.result.fileURL) {
                        this.setState({
                            downloadDoc: false,
                            downloadId: '',
                        })
                        this.props.saveDocuments(getPrefixedUrl(downloadDocumentsData.result.fileURL))
                    }
                }
            }
        )
    }
    componentDidUpdate() {
        if (this.state.selectAll !== 0 && Object.keys(this.state.selected).length === 0) {
            this.setState({ selectAll: 0 })
        }
    }

    openModal = (item, folderId) => {
        /**
        |--------------------------------------------------
        | Commnented fo Videw Preivew
        |--------------------------------------------------
        */
        // if (item.videoUrl) {
        //     this.openVideoModal(item, folderId)
        // } else
        if (item.isFolder && item.isFolder === '1') {
            this.openFolderModal(item.id, item.contentName)
        } else {
            this.openFileModal(item, folderId)
        }
    }

    downloadItems = () => {
        // const selMediaVals = Object.keys(this.state.videoSelected)
        // if (selMediaVals.length > 0) {
        //     alert(SITE_TXT.TXT_VIDEO_DOWNLOAD)
        //     return
        // }
        const selVals = Object.keys(this.state.selected)
        const ids = selVals.join(',')
        const data = {
            requestType: 'lop',
            ids,
        }
        this.setState({ downloadDoc: true }, () => this.props.lopGoldDownloadItems(data))
    }
    openForEmail() {
        const selMediaVals = Object.keys(this.state.videoSelected)
        if (selMediaVals.length > 0) {
            alert(SITE_TXT.TXT_VIDEO_EMAIL)
            return
        }
        this.setState({ openForEmail: true })
    }
    openFolderModal = (folderId, folderName) => {
        if (folderId) {
            this.setState({
                isEditMode: true,
                folderId: folderId,
                name: folderName,
                openFolder: true,
            })
        } else {
            this.setState({ openFolder: true })
        }
    }
    // openVideoModal = (item, folderId) => {
    //     if ((item && item.videoUrl) || item !== undefined) {
    //         this.setState(
    //             {
    //                 isEditMode: true,
    //                 folderId: folderId,
    //                 name: item.contentName,
    //                 videoUrl: item.videoUrl,
    //                 openVideo: true,
    //                 docId: item.id ? item.id : '',
    //             },
    //             () => {}
    //         )
    //     } else {
    //         this.setState({ openVideo: true })
    //     }
    // }

    openFileModal = (file, folderId) => {
        if (file) {
            this.setState({
                isEditMode: true,
                folderId: folderId,
                name: file.contentName ? file.contentName : file,
                open: true,
                docId: file.id ? file.id : '',
            })
        } else {
            this.setState({ open: true })
        }
    }

    emailDocs() {
        const selVals = Object.keys(this.state.selected)
        const ids = selVals.join(',')
        this.setState({ submitted: true }, () => {
            const { email } = this.state
            if (email && isValidPattern(email['email'], EMAIL_PATTERN_DOMAIN)) {
                const data = {
                    requestType: 'lop',
                    emailId: email['email'].toLowerCase(),
                    ids: ids,
                }

                this.props.lopGoldEmailItems(data)
                this.setState({ emailSuccess: true, openForEmail: false })
            }
        })
    }
    componentDidMount() {
        let data = {
            search: this.state.search,
            pageNumber: 1,
            pageSize: isIphoneX || window.innerWidth <= 767 ? 100000 : this.state.pageSize,
            sortColumn: this.state.sortColumn,
            sortType: this.state.sortType,
            requestType: this.state.requestType,
        }
        this.props.lopGoldItems(data)
        this.props.lopFetchFolderList({
            requestType: this.state.requestType,
            id: '',
        })
    }

    handleRowClick(rowId) {
        if (this.state.rowId === null) {
            this.setState({
                expandedRows: !this.state.expandedRows,
                rowId: rowId,
            })
        } else if (rowId === this.state.rowId) {
            this.setState({
                expandedRows: !this.state.expandedRows,
            })
        } else {
            this.setState({
                rowId: rowId,
                expandedRows: true,
            })
        }
    }

    toggleRow = item => {
        const newSelected = Object.assign({}, this.state.selected)
        const newVideoSelected = Object.assign({}, this.state.videoSelected)

        newSelected[item.id] = !this.state.selected[item.id]

        if (newSelected[item.id] === false) {
            delete newSelected[item.id]
        }

        this.state.documentsData.data.forEach(x => {
            const selVals = Object.keys(newSelected)
            const newids = selVals.join(',')

            if (newids === x.id) {
                this.setState({
                    selectContentName: x.contentName,
                    selectedContentExt: x.contentExtension,
                })
            }
        })

        if (item.contentFormatIcon.split('.')[0] === 'video') {
            newVideoSelected[item.id] = !this.state.videoSelected[item.id]
            if (newVideoSelected[item.id] === false) {
                delete newVideoSelected[item.id]
            }
        }

        this.setState({
            selected: newSelected,
            videoSelected: newVideoSelected,
            selectAll: 2,
        })
    }

    toggleSelectAll() {
        let newSelected = {}
        let newVideoSelected = {}
        if (this.state.selectAll === 0) {
            this.state.documentsData.data.forEach(x => {
                if (parseInt(x.isFolder) === 0) {
                    newSelected[x.id] = true
                    x.files && x.files.length > 0 && x.files.map(i => (newSelected[i.id] = true))
                }
                if (x.contentFormatIcon && x.contentFormatIcon.split('.')[0] === 'video') {
                    newVideoSelected[x.id] = true
                }
            })
        }

        this.setState({
            selected: newSelected,
            videoSelected: newVideoSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0,
        })
    }
    loadMoreDocuments = () => {
        if (!this.state.isLoading) {
            this.setState(
                {
                    pageNumber: this.state.pageNumber + 1,
                    isLoading: true,
                },
                () => {
                    const data = {
                        search: this.state.search,
                        pageSize: isIphoneX || window.innerWidth <= 767 ? 100000 : this.state.pageSize,
                        sortType: this.state.sortType,
                        sortColumn: this.state.sortColumn,
                        requestType: this.state.requestType,
                        pageNumber: this.state.pageNumber,
                    }
                    this.state.lopGoldItemsMore(data)
                }
            )
        }
    }
    togglecheckboxComp = item => {
        return (
            <div className="checkbox-wrapper" onClick={() => this.toggleRow(item)}>
                <Checkbox
                    itemId={item.id}
                    checked={this.state.selected[item.id] === true}
                    //checked={this.state.selected[id] === true}
                />
            </div>
        )
    }

    // onOpenVideoModal = item => {
    //     this.setState({
    //         openPlayModal: true,
    //         videoUrl: item.videoUrl,
    //         name: item.contentName,
    //     })
    // }
    // enterKeyHandler = (e, actionType) => {
    //     if (e.which === ENTER_KEY_CODE) {
    //         if (actionType === 'downloadBrochure') {
    //             this.downloadBrochureHandler()
    //         } else {
    //             this.onOpenVideoModal()
    //         }
    //     }
    // }

    renderListItem(item, folderId, isFolder) {
        const clickCallback = () => this.handleRowClick(item.id)
        let wordInString = ''

        const itemRows = [
            <tr
                key={'row-data-' + item.id}
                className={`${
                    this.state.expandedRows && item.files && this.state.rowId === item.id ? 'selected-row' : ''
                }`}
            >
                <td>
                    {' '}
                    {!item.files && this.togglecheckboxComp(item)}{' '}
                    {item.files &&
                        item.files.forEach(file => {
                            if (
                                file.contentName.toLowerCase().includes(this.state.search.toLowerCase().trim()) &&
                                this.state.search !== ''
                            ) {
                                wordInString = <div title={SITE_TXT.TXT_SEARCHED_FOLDER} className="search-dot" />
                            }
                        })}
                    {wordInString}
                </td>
                <td>{!item.files ? <FileIcon /> : <FoldereIcon />}</td>
                <td>
                    {item.contentName}
                    {/* {item.videoUrl ? (
                        <span
                            onClick={() => this.onOpenVideoModal(item)}
                            onKeyUp={e => this.enterKeyHandler(e, 'openVideo')}
                            tabIndex="0"
                        >
                            <VideoLink width="14" height="14" />
                            <span className="lower-div-text action-icon">
                                {' '}
                                {item.contentName}
                            </span>
                        </span>
                    ) : (
                        item.contentName
                    )} */}
                </td>
                <td>{item.downloadCount}</td>
                <td>
                    {' '}
                    <span className={item.contentFormatIcon && 'extBox ' + getFileExt(item.contentFormatIcon)}>
                        {getFileExt(item.contentFormatIcon ? item.contentFormatIcon : '').toUpperCase()}
                    </span>
                </td>
                <td>
                    {' '}
                    <span>
                        {!isMobile && !isIphoneX && isAdmin && (
                            <span onClick={() => this.openModal(item, folderId)} className="addEditIcon">
                                <PencilIcon />
                            </span>
                        )}
                    </span>
                </td>
                <td>
                    {' '}
                    <span>
                        {item.files && (
                            <RowExpansion
                                clickCallback={clickCallback}
                                item={item}
                                expandedRows={this.state.expandedRows}
                                rowId={this.state.rowId}
                            />
                        )}
                    </span>
                </td>
            </tr>,
        ]
        if (item.files && this.state.expandedRows && this.state.rowId === item.id) {
            item.files.forEach(file => {
                let _this = this
                itemRows.push(
                    <tr key={'row-expanded-' + file.id} className="expanded-rows">
                        <td>{_this.togglecheckboxComp(file)}</td>
                        <td>
                            <FileIcon />
                        </td>
                        <td>
                            {file.contentName}
                            {/* {file.videoUrl ? (
                                <span
                                    onClick={() => this.onOpenVideoModal(file)}
                                    onKeyUp={e =>
                                        this.enterKeyHandler(e, 'openVideo')
                                    }
                                    tabIndex="0"
                                >
                                    <VideoLink width="14" height="14" />
                                    <span className="lower-div-text action-icon">
                                        {' '}
                                        {file.contentName}
                                    </span>
                                </span>
                            ) : (
                                file.contentName
                            )} */}
                        </td>
                        <td>{file.downloadCount}</td>
                        <td>
                            <span className={file.contentFormatIcon && 'extBox ' + getFileExt(file.contentFormatIcon)}>
                                {getFileExt(file.contentFormatIcon ? file.contentFormatIcon : '').toUpperCase()}
                            </span>
                        </td>
                        <td>
                            <span>
                                {!isMobile && !isIphoneX && isAdmin && (
                                    <span
                                        onClick={() => {
                                            this.openFileModal(file, item.id)
                                        }}
                                        className="addEditIcon"
                                    >
                                        <PencilIcon />
                                    </span>
                                )}
                            </span>
                        </td>
                        <td />
                    </tr>
                )
            })
        }

        return itemRows
    }
    showNestedRows = () => {
        this.setState({ showRows: true })
    }

    sortDocuments = col => {
        this.setState(
            {
                pageNumber: 1,
                sortType: this.state.sortType === 'ASC' ? 'DESC' : 'ASC',
                sortColumn: col,
                isLoading: true,
            },
            () => {
                let data = {
                    search: this.state.search,
                    pageSize: isIphoneX || window.innerWidth <= 767 ? 100000 : this.state.pageSize,
                    sortType: this.state.sortType,
                    sortColumn: this.state.sortColumn,
                    pageNumber: this.state.pageNumber,
                    requestType: this.state.requestType,
                }
                this.props.lopGoldItems(data)
            }
        )
    }

    showSortIcon(colid) {
        return this.state.sortColumn === colid ? this.state.sortType.toLowerCase() : ''
    }

    searchDocuments = (field, value) => {
        clearTimeout(this.delayTimer)
        this.delayTimer = setTimeout(() => {
            this.setState({ search: value, pageNumber: 1 }, () => {
                let data = {
                    search: this.state.search,
                    pageSize: isIphoneX || window.innerWidth <= 767 ? 100000 : this.state.pageSize,
                    sortType: this.state.sortType,
                    sortColumn: this.state.sortColumn,
                    pageNumber: this.state.pageNumber,
                    requestType: this.state.requestType,
                }
                this.props.lopGoldItems(data)
            })
        }, 1000)
    }

    onCloseModal = () => {
        this.setState({
            open: false,
            openFolder: false,
            isEditMode: false,
            // openVideo: false,
            name: '',
            videoUrl: '',
            folderId: null,
            // openPlayModal: false,
        })
    }

    handleDeleteFolder = id => {
        this.setState({ ondeleteSuccessStatus: false })
        const title = SITE_TXT.TXT_CONFIRM_DELETE
        const message = SITE_TXT.TXT_SURE_DELETE_FOLDER_DOCUMENT
        const onYesTrash = () => this.trashFolder(id)
        ConfirmAlertBox(title, message, onYesTrash)
    }
    trashFolder = id => {
        this.setState({ ondeleteSuccessStatus: true, isLoading: true })
        const data = []

        if (!isNaN(id)) {
            data.push(id)
        }

        const obj = { items: data.join(), requestType: this.state.requestType }
        this.setState({ deleteDocStatus: true }, () => {
            this.props.lopGoldDeleteFolder(obj)
        })
    }
    deleteItems = id => {
        this.setState({ ondeleteSuccessStatus: false })
        const title = SITE_TXT.TXT_CONFIRM_DELETE
        const message = SITE_TXT.TXT_SURE_DELETE_DOCUMENT
        const onYesTrash = () => this.trashFile(id)
        ConfirmAlertBox(title, message, onYesTrash)
    }
    trashFile = id => {
        this.setState({ ondeleteSuccessStatus: true })
        const data = []
        if (!isNaN(id)) {
            data.push(id)
        } else if (this.state.selected) {
            const { selected } = this.state
            for (const key in selected) {
                data.push(key)
            }
        }

        const obj = { items: data.join(), requestType: this.state.requestType }
        this.setState({ deleteDocStatus: true }, () => {
            this.props.lopGoldDeleteItems(obj)
        })
    }
    closeForEmail = () => {
        this.setState({ openForEmail: false })
    }
    render() {
        const {
            documentsData,
            isEditMode,
            open,
            openFolder,
            foldersList,
            name,
            requestType,
            docId,
            folderId,
            submitted,
            email,
            // videoUrl,
            // openVideo,
            // openPlayModal,
        } = this.state

        const {
            TXT_FILENAME,
            TXT_FILE_FORMAT,
            TXT_EMAIL,
            TXT_DOWNLOAD,
            TXT_DELETE_NEW,
            TXT_ADD_NEW_FOLDER,
            TXT_LOADING,
            TXT_SEARCHBY_DOCNAME,
            TXT_NO_DOCUMENT_AVAILABLE,
            TXT_SHOWING,
            TXT_DOCUMENTS,
            TXT_ADD_NEW_FILE,
            TXT_DOWNLOAD_COUNT,
            TXT_CANCEL,
            TXT_SUBMIT,
            TXT_EMAIL_DOCUMENTS,
            TXT_EMAIL_REQUIRED,
            TXT_EMAIL_ADDRESS_HERE,
            TXT_EMAILADDRESS,
            TXT_EMAIL_INVALID,
            // TXT_ADD_VIDEO,
        } = SITE_TXT
        let allItemRows = [],
            lengthTable = 0

        if (!isEmpty(this.state.documentsData) && !isEmpty(this.state.documentsData.data)) {
            this.state.documentsData.data.forEach(item => {
                const perItemRows = this.renderListItem(
                    item,
                    this.state.documentsData.data.id,
                    this.state.documentsData.data.isFolder
                )
                allItemRows = allItemRows.concat(perItemRows)
            })
        }

        if (!isEmpty(this.state.documentsData) && !isEmpty(this.state.documentsData.data)) {
            lengthTable = this.state.documentsData.data.length
        } else {
            lengthTable = 0
        }

        let totalRecords = !isEmpty(this.state.documentsData) ? this.state.documentsData.totalRecords : 0

        const selectAllCheckboxComp = label => (
            <div className="checkbox-wrapper selectall-chk" onClick={() => this.toggleSelectAll()}>
                <Checkbox
                    checked={this.state.selectAll === 1}
                    isIndeterminate={this.state.selectAll === 2}
                    label={label}
                />
            </div>
        )

        // const iframe = `<iframe class="video-iframe" src=${
        //     videoUrl ? videoUrl : ''
        // } allowfullscreen allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture frameborder="0"></iframe>`

        return (
            <div className="gold-container">
                <div className="above-table-section">
                    <div className="search-list-section">
                        <Inputbox
                            placeholder={TXT_SEARCHBY_DOCNAME}
                            type="text"
                            fieldFor="search"
                            onChange={this.searchDocuments}
                        />
                        {documentsData && documentsData.data && (
                            <div className="showing-records">
                                {TXT_SHOWING}
                                <span className="total-rec">
                                    {' '}
                                    {documentsData.data.length}/{documentsData.totalRecords} {TXT_DOCUMENTS}
                                </span>
                            </div>
                        )}
                    </div>

                    {!isMobile && !isIphoneX && isAdmin && (
                        <div>
                            <span className="txt-upper txt-blue action-icon" onClick={() => this.openFolderModal()}>
                                + {TXT_ADD_NEW_FOLDER}
                            </span>
                            <span className="txt-upper txt-blue action-icon" onClick={() => this.openFileModal()}>
                                + {TXT_ADD_NEW_FILE}
                            </span>
                            {/* <span
                                className="txt-upper txt-blue action-icon"
                                onClick={() => this.openVideoModal()}
                            >
                                + {TXT_ADD_VIDEO}
                            </span> */}
                        </div>
                    )}
                </div>
                {totalRecords > 0 && !isEmpty(this.state.selected) ? (
                    <div className="actionBtns">
                        <button className="btn btn-primary" onClick={() => this.openForEmail()}>
                            <MailtoIcon width="16" height="16" />
                            {TXT_EMAIL}
                        </button>
                        <button className="btn btn-primary" onClick={() => this.downloadItems()}>
                            <span>
                                <DownloadIcon width="16" height="16" />
                                {TXT_DOWNLOAD}
                            </span>
                        </button>
                        {!isMobile && !isIphoneX && isAdmin && (
                            <button className="btn btn-primary" onClick={() => this.deleteItems()}>
                                <span>
                                    <DeleteBinIcon width="16" height="16" />
                                    {TXT_DELETE_NEW}
                                </span>
                            </button>
                        )}
                        <Modal
                            open={this.state.openForEmail}
                            onClose={() => this.closeForEmail()}
                            center
                            classNames={{
                                modal: 'comp-modal library-modal',
                            }}
                        >
                            <h2 className="heading-class">{TXT_EMAIL_DOCUMENTS}</h2>
                            <form>
                                <div className={'form-group' + (submitted && !email ? ' has-error' : '')}>
                                    <label htmlFor="email" className="inputlabels">
                                        {TXT_EMAILADDRESS}
                                    </label>
                                    <Inputbox
                                        type="email"
                                        fieldFor="email"
                                        placeholder={TXT_EMAIL_ADDRESS_HERE}
                                        onChange={(field, value) => this.handleChange(field, value)}
                                        customClass="email"
                                    />
                                    {submitted && !email && <div className="help-block">{TXT_EMAIL_REQUIRED}</div>}
                                    {submitted && email && !isValidPattern(email['email'], EMAIL_PATTERN_DOMAIN) && (
                                        <div className="help-block">{TXT_EMAIL_INVALID}</div>
                                    )}
                                </div>

                                <div className="butClass ">
                                    <button
                                        type="button"
                                        className="btn btn-default cancel-Btn"
                                        onClick={this.closeForEmail}
                                    >
                                        {TXT_CANCEL}
                                    </button>
                                    <button onClick={() => this.emailDocs()} type="button" className="btn btn-primary">
                                        {TXT_SUBMIT}
                                    </button>
                                </div>
                            </form>
                        </Modal>
                    </div>
                ) : null}
                {totalRecords > 0 ? (
                    !isMobile && !isIphoneX ? (
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={() => this.loadMoreDocuments()}
                            loader={
                                <div className="loader" key={0}>
                                    {TXT_LOADING}
                                </div>
                            }
                            hasMore={lengthTable < totalRecords}
                            useWindow={true}
                        >
                            <table className="table custom-table goldtbl">
                                <thead>
                                    <tr>
                                        <th id="doc_check" className="checkboxtd">
                                            {selectAllCheckboxComp()}
                                        </th>
                                        <th id="doc_fileIcon">&nbsp;</th>
                                        <th
                                            id="contentName"
                                            className={this.showSortIcon('contentName')}
                                            onClick={() => this.sortDocuments('contentName')}
                                        >
                                            {TXT_FILENAME}
                                        </th>
                                        <th
                                            id="downloadCount"
                                            className={this.showSortIcon('downloadCount')}
                                            onClick={() => this.sortDocuments('downloadCount')}
                                        >
                                            {TXT_DOWNLOAD_COUNT}
                                        </th>
                                        <th
                                            id="contentFormat"
                                            className={this.showSortIcon('contentFormat')}
                                            onClick={() => this.sortDocuments('contentFormat')}
                                        >
                                            {TXT_FILE_FORMAT}
                                        </th>
                                        <th />
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>{allItemRows}</tbody>
                            </table>
                        </InfiniteScroll>
                    ) : (
                        <div className="tab-list">
                            {selectAllCheckboxComp(SITE_TXT.TXT_SELECTALL)}
                            <Slider {...DOT_SLIDER_4_ROWS}>
                                {documentsData ? (
                                    documentsData.data.map(item => (
                                        <div key={item.id}>
                                            <TabComp
                                                key={item.id}
                                                checked={this.checked}
                                                toggleRow={() => this.toggleRow(item)}
                                                tab={item}
                                                selected={this.state.selected[item.id] === true}
                                                selectedNested={this.state.selected}
                                                search={this.state.search}
                                                toggleNestedRow={this.toggleRow}
                                                enterKeyHandler={this.enterKeyHandler}
                                                fileIcon={true}
                                            />
                                        </div>
                                    ))
                                ) : (
                                    <div>TXT_NO_DOCUMENT_AVAILABLE</div>
                                )}
                            </Slider>

                            {/* <ReactSlider
                                listData={documentsData && documentsData.data}
                                settings={DOT_SLIDER_4_ROWS}
                                noImage={true}
                                isPhaseList={false}
                                toggleRow={id => this.toggleRow(id)}
                                selected={this.state.selected}
                                search={this.state.search}
                                toggleNestedRow={this.toggleRow}
                                selectedNested={this.state.selected}
                            /> */}
                        </div>
                    )
                ) : (
                    <div className="no-result">{TXT_NO_DOCUMENT_AVAILABLE}</div>
                )}
                {openFolder && (
                    <FolderModal
                        open={openFolder}
                        isEditMode={isEditMode}
                        name={name}
                        requestType={requestType}
                        folderId={folderId}
                        lopAddFolder={this.props.lopAddFolder}
                        lopEditFolder={this.props.lopEditFolder}
                        onGoldDeleteFolder={this.handleDeleteFolder}
                        onGoldDeleteSuccess={this.state.ondeleteSuccessStatus}
                        onCloseModal={this.onCloseModal}
                        addEditStatus={this.props.addEditStatus}
                    />
                )}
                {open && (
                    <FileModal
                        open={open}
                        foldersList={foldersList}
                        isEditMode={isEditMode}
                        name={name}
                        folderId={folderId}
                        onCloseModal={this.onCloseModal}
                        lopAddItem={this.props.lopAddItem}
                        requestType={requestType}
                        docId={docId}
                        lopEditItem={this.props.lopEditItem}
                        addEditStatus={this.props.addEditStatus}
                        onGoldDelete={this.deleteItems}
                        onLopDeleteSuccess={this.state.ondeleteSuccessStatus}
                        acceptedFileType={SITE_TXT.TXT_FILE_TYPE_ACCEPTED_GOLD}
                    />
                )}
            </div>
        )
    }
}

export default GoldStandard
