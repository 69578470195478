import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormGroup, Label, Input, Button } from 'reactstrap'
import { withAlert } from 'react-alert'
import { BackIcon } from '../../Utils/svg'
import {
    CHARGECODE_PATTERN,
    DOCUMENT_NAME_PATTERN,
    MAX_NAME_LENGTH_100,
    MonthListCode,
    EMAIL_PATTERN_DOMAIN,
    SITE_TXT,
} from '../../Utils/constants'
import { futureYearList, isValidPattern } from '../../Utils/functions'
import { ConfirmAlertBox } from '../../Containers/Library/Alerts'
import './index.scss'
import Dropdown from '../../Components/Dropdown'

class CreateCst extends Component {
    state = {
        cst: '',
        chargeCode: '',
        contact: JSON.parse(localStorage.getItem('userInfo')).nickname,
        month: 'Select',
        year: 'Select',
        email: '',
        emailIds: [],
        dropdownOpen: false,
        dropdownYearOpen: false,
        checked: false,
        status: false,
        id: null,
        addEdit: false,
        isChargeCodeValid: false,
        validChargeCode: null,
    }

    static getDerivedStateFromProps(props, state) {
        if (props.singleRecord && props.singleRecord.length > 0 && !state.addEdit) {
            const { id, name, memberContact, chargeCode, loginValidity, cstUsers } = props.singleRecord[0]
            return {
                id,
                cst: name,
                contact: memberContact,
                chargeCode,
                checked: true,
                month: parseInt(loginValidity.split('/')[0]),
                year: parseInt(loginValidity.split('/')[2]),
                emailIds: cstUsers,
                addEdit: true,
            }
        }
        return null
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.status === true) {
            this.setState(
                {
                    status: true,
                },
                () => {
                    this.props.resetStatus()
                    this.props.toggleCreate({ editMode: false })
                }
            )
        }
    }

    handleInputChange = ({ target }) => {
        const { name, value } = target
        this.setState({ [name]: value })
    }

    handleChange = (field, value) => {
        this.setState({
            [field]: value,
        })
    }

    addEmail = () => {
        const { email, emailIds } = this.state
        const { editMode } = this.props
        if (!editMode) {
            this.setState({ emailIds: [...emailIds, email], email: '' })
        } else {
            const emailData = {
                id: 0,
                email,
                isApproved: false,
                isDelete: false,
            }
            this.setState({
                emailIds: [...emailIds, emailData],
                email: '',
            })
        }
    }

    checkRemoveEmail = index => {
        const { TXT_CONFIRM_DELETE, TXT_CST_MAIL_MESSAGE } = SITE_TXT
        ConfirmAlertBox(TXT_CONFIRM_DELETE, TXT_CST_MAIL_MESSAGE, () => this.removeEmail(index))
    }

    consvertToDateString = (month, year) => {
        let dateString
        dateString = month + '/01/' + year
        return dateString
    }
    removeEmail = index => {
        if (!this.props.editMode) {
            const { emailIds } = this.state
            const updateEmails = [...emailIds.slice(0, index), ...emailIds.slice(index + 1)]
            this.setState({ emailIds: updateEmails })
        } else {
            const { emailIds } = this.state
            if (emailIds[index].id === 0) {
                const updateEmails = [...emailIds.slice(0, index), ...emailIds.slice(index + 1)]
                this.setState({ emailIds: updateEmails })
            } else {
                emailIds[index].isDelete = true
                this.setState({ emailIds })
            }
        }
    }

    toggleDropdDown = () => {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen,
        }))
    }

    toggleDropdDownYear = () => {
        this.setState(prevState => ({
            dropdownYearOpen: !prevState.dropdownYearOpen,
        }))
    }

    toggleAcknowledgement = () => {
        this.setState(prevState => ({
            checked: !prevState.checked,
        }))
    }

    changeMonth = index => {
        const month = ('0' + (index + 1)).slice(-2)
        this.setState({ month })
    }

    changeYear = year => this.setState({ year })

    resetForm = () => {
        this.setState(
            {
                cst: '',
                month: 'Select',
                year: 'Select',
                chargeCode: '',
                checked: false,
                emailIds: [],
            },
            () => {
                this.props.resetStatus()
            }
        )
    }

    isFormValid = () => {
        const { cst, emailIds, month, year, chargeCode, checked } = this.state
        const { alert } = this.props
        const { error } = alert
        if (!isValidPattern(cst, DOCUMENT_NAME_PATTERN)) {
            error(SITE_TXT.TXT_CST_NAME_VALID_PATTERN)
            return false
        }
        if (cst.trim().length > MAX_NAME_LENGTH_100) {
            error(SITE_TXT.TXT_NAME_MAX_LENGTH)
            return false
        }
        if (!isValidPattern(chargeCode, CHARGECODE_PATTERN)) {
            error(SITE_TXT.TXT_CHARGECODE_VALID_PATTERN)
            return false
        }
        if (cst.trim().length > MAX_NAME_LENGTH_100) {
            error(SITE_TXT.TXT_NAME_MAX_LENGTH)
            return false
        }
        if (emailIds.length <= 0) {
            error(SITE_TXT.TXT_EMAIL_LIST)
            return false
        }
        if (month === 'Select') {
            error(SITE_TXT.TXT_MONTH_SELECT)
            return false
        }
        if (year === 'Select') {
            error(SITE_TXT.TXT_YEAR_SELECT)
            return false
        }
        if (!checked) {
            error(SITE_TXT.TXT_ACKNOWLEDGE)
            return false
        }

        return true
    }

    testDuplicate = email => {
        const { emailIds } = this.state
        const { editMode } = this.props
        if (emailIds.indexOf(email) > -1 && !editMode) {
            return true
        }
        if (editMode) {
            const flagVal =
                emailIds &&
                emailIds.map(item => {
                    return item.email === email
                })
            return flagVal.indexOf(true) > -1
        }
        return false
    }

    handleKeyPressed = e => {
        if (e.which === 44 || e.which === 13) {
            e.preventDefault()
            const { email, emailIds } = this.state
            if (!this.testDuplicate(email) && !this.isInList(email) && this.isEmail(email)) {
                const { editMode } = this.props
                if (!editMode) {
                    this.setState({ emailIds: [...emailIds, email], email: '' })
                } else {
                    const emailData = {
                        id: 0,
                        email,
                        isApproved: false,
                        isDelete: false,
                    }
                    this.setState({
                        emailIds: [...emailIds, emailData],
                        email: '',
                    })
                }
            }
            return false
        } else {
            return true
        }
    }

    isInList = email => {
        const { emailIds } = this.state
        return emailIds.includes(email)
    }

    isEmail = email => EMAIL_PATTERN_DOMAIN.test(email)

    handlePaste = e => {
        e.preventDefault()
        const { editMode } = this.props
        const { emailIds } = this.state
        let paste = e.clipboardData.getData('text')
        //eslint-disable-next-line
        let emails = paste.match(/[\w\d\.-]+@(external.)?mckinsey\.com/g)
        if (emails) {
            if (!editMode) {
                let toBeAdded = emails.filter(email => !this.isInList(email))
                this.setState({
                    emailIds: [...emailIds, ...toBeAdded],
                })
            } else {
                let checkDuplicate = emails.filter(email => !this.testDuplicate(email))

                const emailData =
                    checkDuplicate.length &&
                    checkDuplicate.map(email => ({
                        id: 0,
                        email,
                        isApproved: false,
                        isDelete: false,
                    }))
                if (emailData.length) {
                    this.setState({
                        emailIds: [...emailIds, ...emailData],
                    })
                }
            }
        }
    }

    saveData = e => {
        e.preventDefault()
        if (!this.isFormValid()) {
            return null
        }

        const { editMode, addCstGroup, editCstGroup } = this.props
        const {
            cst: name,
            id,
            emailIds: cstUsers,
            contact: memberContact,
            month,
            year,
            chargeCode,
            checked: isAcknowledge,
        } = this.state

        const data = {
            name,
            cstUsers,
            isAcknowledge,
            memberContact,
            loginValidity: this.consvertToDateString(month, year),
            chargeCode,
        }
        let editData
        if (editMode) {
            editData = {
                ...data,
                id,
            }
        }
        if (editMode) {
            editCstGroup(editData)
        } else {
            addCstGroup(data)
        }
    }

    // validateChargeCode = () => {
    //     const { fetchToken } = this.props
    //     fetchToken()
    // }

    render() {
        const { checked, cst, chargeCode, contact, email, emailIds, month, year } = this.state
        const { toggleCreate, editMode } = this.props
        const YearsData = futureYearList()
        const {
            ADD_CST,
            EDIT_CST,
            CST_NAME,
            CST_NAME_PLACEHOLDER,
            CHARGE_CODE,
            CHARGE_CODE_PLACEHOLDER,
            CST_ACKNOWLEGMENT,
            CST_DURATION,
            CST_CONTACT,
            CST_CONTACT_PLACEHOLDER,
            CST_ACCESS,
            TXT_EMAIL_ADDRESS_HERE,
            CST_ADD_MAIL,
            TXT_SAVE,
        } = SITE_TXT
        return (
            <div className="createCst">
                <h3>
                    <span onClick={toggleCreate}>
                        <BackIcon />
                    </span>
                    {!editMode ? ADD_CST : EDIT_CST}
                </h3>
                <section className="cstWrapper">
                    <div className="leftSection">
                        <FormGroup>
                            <Label>{CST_NAME}</Label>
                            <Input
                                placeholder={CST_NAME_PLACEHOLDER}
                                name="cst"
                                value={cst}
                                onChange={e => this.handleInputChange(e)}
                            />
                        </FormGroup>
                        <FormGroup className={'chargeCode'}>
                            <Label>{CHARGE_CODE}</Label>
                            <div className={'chargeCode-container'}>
                                <Input
                                    placeholder={CHARGE_CODE_PLACEHOLDER}
                                    name="chargeCode"
                                    value={chargeCode}
                                    onChange={e => this.handleInputChange(e)}
                                    className={'chargecode1'}
                                />
                                {/*{!isChargeCodeValid && chargeCode !== validChargeCode ? (*/}
                                {/*    <Button disabled={false} onClick={e => this.validateChargeCode(e)}>*/}
                                {/*        {'Validate'}*/}
                                {/*    </Button>*/}
                                {/*) : (*/}
                                {/*    <span className={'validChargeCode'}>*/}
                                {/*        <ValidateTick />*/}
                                {/*    </span>*/}
                                {/*)}*/}
                            </div>
                        </FormGroup>
                        <FormGroup className="checkBoxAck">
                            <input
                                type="checkbox"
                                id="acknowledge"
                                checked={checked}
                                onChange={this.toggleAcknowledgement}
                            />
                            <label htmlFor="acknowledge">{CST_ACKNOWLEGMENT}</label>
                        </FormGroup>
                        <FormGroup className="dropOuter">
                            <Label>{CST_DURATION}</Label>
                            <div className={'dropdown'}>
                                <Dropdown
                                    value={month}
                                    options={MonthListCode}
                                    fieldFor={'month'}
                                    onChange={this.handleChange}
                                />
                                <Dropdown
                                    value={year}
                                    options={YearsData}
                                    fieldFor={'year'}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </FormGroup>
                    </div>
                    <div className="rightSection">
                        <FormGroup>
                            <Label>{CST_CONTACT}</Label>
                            <Input
                                placeholder={CST_CONTACT_PLACEHOLDER}
                                name="contact"
                                value={contact}
                                onChange={e => this.handleInputChange(e)}
                            />
                        </FormGroup>
                        <FormGroup className="emailOuter">
                            <Label>{CST_ACCESS}</Label>
                            <Input
                                placeholder={TXT_EMAIL_ADDRESS_HERE}
                                name="email"
                                value={email}
                                onChange={e => this.handleInputChange(e)}
                                onKeyPress={e => this.handleKeyPressed(e)}
                                onPaste={this.handlePaste}
                            />
                            <Button
                                // disabled={!EMAIL_PATTERN_DOMAIN.test(email) || this.testDuplicate()}
                                disabled={!EMAIL_PATTERN_DOMAIN.test(email) || this.testDuplicate(email)}
                                onClick={this.addEmail}
                            >
                                + {CST_ADD_MAIL}
                            </Button>
                            <div className={'indicates'}>
                                <div className={'new'}>New</div>
                                <div className={'pending'}>Pending</div>
                                <div className={'approved'}>Approved</div>
                                <div className={'denied'}>Denied</div>
                            </div>
                            {emailIds.length && !emailIds[0].hasOwnProperty('id') ? (
                                <ul className="emailGroup">
                                    {emailIds.map((item, i) => (
                                        <li key={i}>
                                            {item}
                                            <span onClick={() => this.checkRemoveEmail(i)}>x</span>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <div>
                                    <ul className="emailGroup">
                                        {emailIds.map(
                                            (item, i) =>
                                                !item.isDelete && (
                                                    <li key={i} className={item.Status && item.Status.toLowerCase()}>
                                                        {item.email}
                                                        <span onClick={() => this.checkRemoveEmail(i)}>x</span>
                                                    </li>
                                                )
                                        )}
                                    </ul>
                                </div>
                            )}
                        </FormGroup>
                    </div>
                    <div className="buttonContent">
                        <Button
                            disabled={
                                !cst ||
                                !chargeCode ||
                                !contact ||
                                emailIds.length === 0 ||
                                !checked ||
                                month === 'Select' ||
                                year === 'Select'
                            }
                            onClick={e => this.saveData(e)}
                        >
                            {TXT_SAVE}
                        </Button>
                    </div>
                </section>
            </div>
        )
    }
}

CreateCst.propTypes = {
    toggleCreate: PropTypes.func.isRequired,
    cstData: PropTypes.object,
    addCstGroup: PropTypes.func.isRequired,
    editCstGroup: PropTypes.func.isRequired,
    editMode: PropTypes.bool.isRequired,
    status: PropTypes.bool,
    resetStatus: PropTypes.func.isRequired,
}

export default withAlert(CreateCst)
