export const DELIVER_GET = 'DELIVER_GET'
export const DELIVER_ADD_SECTION = 'DELIVER_ADD_SECTION'
export const DELIVER_EDIT_SECTION = 'DELIVER_EDIT_SECTION'
export const DELIVER_TRASH_SECTION = 'DELIVER_TRASH_SECTION'
export const DELIVER_ADD_SUB_SECTION = 'DELIVER_ADD_SUB_SECTION'
export const DELIVER_EDIT_SUB_SECTION = 'DELIVER_EDIT_SUB_SECTION'
export const DELIVER_FETCH_ITEM = 'DELIVER_FETCH_ITEM'
export const DELIVER_DOWNLOAD_BROCHURE = 'DELIVER_DOWNLOAD_BROCHURE'
export const DELIVER_DELETE_SUB_SECTION = 'DELIVER_DELETE_SUB_SECTION'
export const DELIVER_ITEM_REORDER = 'DELIVER_ITEM_REORDER'

export const DELIVER_INITAL_STATE = {
    toolName: '',
    toolIcon: '',
    toolLink: '',
    toolDescription: '',
    videoLink: '',
    brochure: '',
    toolId: '',
    image1: '',
    image2: '',
    image3: '',
    deliveryTopicId: '',
}
