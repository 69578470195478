import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'

import {
    Search,
    Deliver,
    GetRole,
    Library,
    Lop,
    LibraryCategory,
    Profile,
    DeliverForm,
    DeliverDetail,
    Learn,
    MMPractice,
    Admin,
    CreateLop,
    LopContent,
    SharedLop,
    MMCst,
} from './Containers'
import { ForgotPass, Login } from './Components'

import { Callback, MckCallback } from './Auth'
import { Redirect } from 'react-router'
import { isCST, isExternalClinet, isMM_Core, isCSTLOP, isAdmin } from './Utils/constants'
import PageNotFound from './Components/PageNotFound'
import CstWithLopVideo from './Containers/Admin/cstWithLopVideo'
import history from './history'
import { LoginCallback } from '@okta/okta-react'

const AuthRoute = props => {
    const redirectRoute = isExternalClinet ? 'sharedLop' : ''
    return props.authO && !isExternalClinet ? <Route {...props} /> : <Redirect to={redirectRoute} />
}

const RedirectRoute = props => {
    const redirectRoute = isExternalClinet ? 'sharedLop' : ''
    return props.authO ? <Route {...props} /> : <Redirect to={redirectRoute} />
}

const NonCSTRoute = props => {
    return props.authO && !isCST && !isExternalClinet ? <Route {...props} /> : <Redirect to="/" />
}

const LopSectionRouter = props => {
    return props.authO && !isCST ? <Route {...props} /> : <Redirect to="/" />
}

const MMCoreRoute = props => {
    return props.authO && (isMM_Core || isAdmin) ? <Route {...props} /> : <Redirect to="/" />
}

const CSTWithLOPRoute = props => {
    return props.authO && isCSTLOP ? <Route {...props} /> : <Redirect to="/" />
}

const ExternalRoute = props => {
    return props.authO && isExternalClinet ? <Route {...props} /> : <Redirect to="/" />
}

class Routes extends Component {
    authO = this.props.authO
    // mid = this.props.mid

    handleAuthentication = ({ location }) => {
        if (/access_token|id_token|error/.test(location.hash)) {
            this.authO.handleAuthentication()
        } else if (/code/.test(location.search)) {
            this.authO.handleSSOAuthentication()
        } else {
            console.error('Authentication error routes.js', location)
            alert(`Your account has been deactivated, please contact your administrator.`)
            history.replace('/')
        }
    }

    render() {
        const isValidUser = localStorage.getItem('isAuthenticated')

        if (isValidUser) {
            this.props.validateUser()
        }

        return (
            <Switch>
                <Route path="/" exact render={props => <Login authO={this.authO || isValidUser} {...props} />} />
                {/* <Route
                    path="/authO/callback"
                    exact
                    render={props => {
                        // this.handleAuthentication(props)
                        return <MckCallback />
                    }}
                /> */}
                {/* <Route
                    path="/callback"
                    exact
                    render={props => {
                        this.handleAuthentication(props)
                        return <Callback {...props} authO={this.authO || isValidUser} />
                    }}
                /> */}
                <Route path="/callback" component={LoginCallback} />
                <Route
                    path="/forgot-pass"
                    render={props => <ForgotPass authO={this.authO || isValidUser} {...props} />}
                />
                <AuthRoute
                    exact
                    path="/getRole"
                    authO={this.authO || isValidUser}
                    render={props => <GetRole {...props} authO={this.authO || isValidUser} />}
                />
                <AuthRoute
                    exact
                    path="/library"
                    authO={this.authO || isValidUser}
                    render={props => <Library {...props} authO={this.authO || isValidUser} />}
                />
                <AuthRoute
                    path="/library/:type/:id"
                    authO={this.authO || isValidUser}
                    render={props => <LibraryCategory {...props} authO={this.authO || isValidUser} />}
                />
                <AuthRoute
                    exact
                    path="/deliver"
                    authO={this.authO || isValidUser}
                    render={props => <Deliver {...props} authO={this.authO || isValidUser} />}
                />
                <AuthRoute
                    path="/deliver/deliverdetails/:type/:id"
                    authO={this.authO || isValidUser}
                    render={props => <DeliverDetail {...props} authO={this.authO || isValidUser} />}
                />
                <AuthRoute
                    path="/deliver/deliverform/:type/:id"
                    authO={this.authO || isValidUser}
                    render={props => <DeliverForm {...props} authO={this.authO || isValidUser} />}
                />
                <AuthRoute
                    path="/deliver/deliverform/add"
                    authO={this.authO || isValidUser}
                    render={props => <DeliverForm {...props} authO={this.authO || isValidUser} />}
                />
                <AuthRoute
                    path="/learn"
                    authO={this.authO || isValidUser}
                    render={props => <Learn {...props} authO={this.authO || isValidUser} />}
                />
                <NonCSTRoute
                    path="/mmpractice"
                    authO={this.authO || isValidUser}
                    render={props => <MMPractice {...props} authO={this.authO || isValidUser} />}
                />
                <NonCSTRoute
                    path="/lop"
                    authO={this.authO || isValidUser}
                    render={props => <Lop {...props} authO={this.authO || isValidUser} />}
                />
                {/*<NonCSTRoute*/}
                {/*    path="/industry"*/}
                {/*    authO={this.authO || isValidUser}*/}
                {/*    render={props => <Industry {...props} authO={this.authO || isValidUser} />}*/}
                {/*/>*/}
                <AuthRoute
                    authO={this.authO || isValidUser}
                    path="/admin"
                    render={props => <Admin {...props} authO={this.authO || isValidUser} />}
                />
                <AuthRoute
                    authO={this.authO || isValidUser}
                    path="/profile"
                    render={props => <Profile {...props} authO={this.authO || isValidUser} />}
                />
                <AuthRoute
                    path="/search/:type"
                    authO={this.authO || isValidUser}
                    render={props => <Search {...props} authO={this.authO || isValidUser} />}
                />
                <AuthRoute
                    path="/createLop"
                    authO={this.authO || isValidUser}
                    render={props => <CreateLop {...props} authO={this.authO || isValidUser} />}
                />
                <LopSectionRouter
                    path="/lopSections"
                    authO={this.authO || isValidUser}
                    render={props => <LopContent {...props} authO={this.authO || isValidUser} />}
                />
                <ExternalRoute
                    path="/sharedLop"
                    authO={this.authO || isValidUser}
                    render={props => <SharedLop {...props} authO={this.authO || isValidUser} />}
                />
                <MMCoreRoute
                    path="/cstGroups"
                    authO={this.authO || isValidUser}
                    render={props => <MMCst {...props} authO={this.authO || isValidUser} />}
                />

                <CSTWithLOPRoute
                    path="/cstWithLopVideo"
                    authO={this.authO || isValidUser}
                    render={props => <CstWithLopVideo {...props} authO={this.authO || isValidUser} />}
                />
                <RedirectRoute
                    path="*"
                    authO={this.authO || isValidUser}
                    render={props => <PageNotFound {...props} authO={this.authO || isValidUser} />}
                />
            </Switch>
        )
    }
}

export default Routes
