import React, { Component, Fragment } from 'react'
import { Input } from 'reactstrap'
import PropTypes from 'prop-types'
import c from 'classnames'
import { connect } from 'react-redux'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import arrayMove from 'array-move'

import RichTextTool from '../RichTextTool'
import {
    sortTemplate2,
    deleteInTemplate2,
    addTemplate2Details,
    updateTemplate2Details,
    setElementToScroll,
} from '../../../../Containers/LopContent/actions'
import { AddPointerIcon, DeleteGreyIcon } from '../../../../Utils/svg'

import './index.scss'

const SortableItem = SortableElement(props => {
    const { item, editMode, updateTemplate2Details, indexItem, deleteInTemplate2, dataLength } = props
    const graybgArr = [2, 3, 6, 7, 10, 11, 14, 15, 18, 19, 22, 23, 26, 27]
    return (
        <div
            className={c(`contentBoxMckinsey ${graybgArr.indexOf(props.indexItem + 1) >= 0 ? ' graybox' : ''}`, {
                moveIcon: props.editMode,
            })}
        >
            {editMode && dataLength > 1 && (
                <span
                    className="deletePointer trash-black"
                    onClick={() =>
                        deleteInTemplate2(indexItem, {
                            ...item,
                            isDelete: 'true',
                        })
                    }
                >
                    <DeleteGreyIcon />
                </span>
            )}
            <div className="contentHeader mckinseyHeader">
                <span>{('0' + (indexItem + 1)).slice(-2)}</span>
                {editMode ? (
                    <Fragment>
                        <Input
                            value={item.heading}
                            className="contentTitleText"
                            placeholder="Heading"
                            maxLength="100"
                            onChange={e => updateTemplate2Details(indexItem, 'heading', e.target.value)}
                        />
                        <Input
                            value={item.subHeading}
                            className="contentSubHeaderText"
                            placeholder="Sub Line to explain the heading"
                            maxLength="100"
                            onChange={e => updateTemplate2Details(indexItem, 'subHeading', e.target.value)}
                        />
                    </Fragment>
                ) : (
                    <Fragment>
                        <h2>{item.heading}</h2>
                        <h3 className="ellipsisPro" title={item.subHeading}>
                            {item.subHeading}
                        </h3>
                    </Fragment>
                )}
                <div className="border" />
            </div>

            {editMode ? (
                <RichTextTool
                    richTextClassName="pointerTextTool"
                    richTextContent={item.description}
                    onToolTextChange={content => updateTemplate2Details(indexItem, 'description', content)}
                    placeholder="Add text"
                />
            ) : (
                <div
                    className="desc"
                    dangerouslySetInnerHTML={{
                        __html: item.description,
                    }}
                />
            )}
        </div>
    )
})

const SortableList = SortableContainer(props => {
    const { data, editMode, disableReorder } = props
    return (
        <div className="mckinseyContent template2">
            {props.data.map((item, index) => (
                <SortableItem
                    key={index}
                    index={index}
                    indexItem={index}
                    item={item}
                    dataLength={data.length}
                    disabled={!editMode || data.length < 2 || disableReorder}
                    editMode={editMode}
                    deleteInTemplate2={props.deleteInTemplate2}
                    updateTemplate2Details={props.updateTemplate2Details}
                />
            ))}
        </div>
    )
})

class Template2 extends Component {
    state = {
        anchors: null,
    }

    componentDidMount() {
        const anchors = document.querySelectorAll('a')
        this.setState({ anchors })
        if (anchors && anchors.length) {
            for (let i = 0; i < anchors.length; i++) {
                anchors[i].addEventListener('click', this.eventObj)
                anchors[i].addEventListener('touchstart', this.eventObj)
            }
        }
    }
    componentWillUnmount() {
        const { anchors } = this.state
        if (anchors && anchors.length) {
            for (let i = 0; i < anchors.length; i++) {
                anchors[i].removeEventListener('click', this.eventObj)
                anchors[i].removeEventListener('touchstart', this.eventObj)
            }
        }
    }

    eventObj = e => {
        const locationToSend = e.target.href
        if (locationToSend && locationToSend.includes('noredirect')) {
            e.preventDefault()
            const { sections, updateCurrentSlide, setElementToScroll } = this.props
            const locationArray = locationToSend.split('/')
            const sectionId = locationArray[locationArray.length - 2]
            const elementId = locationArray[locationArray.length - 1]
            const sectionToMove = sections.findIndex(x => x.sectionId === sectionId)
            if (sectionToMove) {
                updateCurrentSlide(sectionToMove)
                setElementToScroll(elementId)
            }
        }
    }

    render() {
        const {
            content,
            editMode,
            sortTemplate2,
            deleteInTemplate2,
            addTemplate2Details,
            updateTemplate2Details,
            disableReorder,
        } = this.props
        const { sectionContent = [] } = content
        return (
            <Fragment>
                <SortableList
                    data={sectionContent}
                    onSortEnd={({ oldIndex, newIndex }) => sortTemplate2(arrayMove(sectionContent, oldIndex, newIndex))}
                    axis="xy"
                    distance={1}
                    lockToContainerEdges={true}
                    editMode={editMode}
                    disableReorder={disableReorder}
                    deleteInTemplate2={deleteInTemplate2}
                    updateTemplate2Details={updateTemplate2Details}
                    helperClass="movingIcon"
                />
                {editMode && (
                    <div className="addContentOuter" onClick={addTemplate2Details}>
                        <span>
                            <AddPointerIcon />
                        </span>
                    </div>
                )}
            </Fragment>
        )
    }
}

Template2.propTypes = {
    content: PropTypes.instanceOf(Object).isRequired,
    sections: PropTypes.instanceOf(Array).isRequired,
    editMode: PropTypes.bool.isRequired,
    disableReorder: PropTypes.bool.isRequired,
    sortTemplate2: PropTypes.func.isRequired,
    deleteInTemplate2: PropTypes.func.isRequired,
    addTemplate2Details: PropTypes.func.isRequired,
    updateTemplate2Details: PropTypes.func.isRequired,
    updateCurrentSlide: PropTypes.func.isRequired,
    setElementToScroll: PropTypes.func.isRequired,
}

const dispatchProps = {
    sortTemplate2,
    deleteInTemplate2,
    addTemplate2Details,
    updateTemplate2Details,
    setElementToScroll,
}
export default connect(null, dispatchProps)(Template2)
