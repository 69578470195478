import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import ConfirmModal from './ConfirmModal'
import { SITE_TXT } from '../../../Utils/constants'
import { Restore, TrashNew } from '../../../Utils/svg'
import './index.scss'

const DeletedSections = ({
    toggleDeletedSections,
    sections,
    restoreSection,
    restoreLopStatus,
    lopId,
    refetchLopDetails,
}) => {
    const [openModal, setOpen] = useState(false)
    const [isDelete, setIsDelete] = useState(true)
    const [sectionId, setSectionId] = useState('')

    useEffect(() => {
        if (restoreLopStatus) {
            refetchLopDetails()
        }
    }, [restoreLopStatus, refetchLopDetails])

    const toggleConfirmModal = (currentSecId, openModal, isDelete = false) => {
        setIsDelete(isDelete)
        setOpen(openModal)
        setSectionId(currentSecId)
    }

    const takeAction = () => {
        setIsDelete(isDelete)
        setOpen(false)

        const payload = {
            sectionId: sectionId,
            type: isDelete ? 2 : 1,
            myLopId: lopId,
        }
        restoreSection(payload)
    }
    return (
        <div className="deletedSection">
            {sections.length > 0 ? (
                <div className="upperSection">
                    <div className="header">{SITE_TXT.VIEW_DELETED}</div>
                    <p className="subSection">{SITE_TXT.VIEW_DELETED_SUB_TEXT}</p>
                </div>
            ) : (
                <div className="upperSection">
                    <div className="header centeredText">{SITE_TXT.DELETED_MESSAGE}</div>
                </div>
            )}
            <div className="mainContent">
                {sections.length > 0 && (
                    <div className="sectionContent">
                        {sections.map((item, i) => (
                            <div key={item.sectionId} className="mainSlide">
                                <h3 className="ellipsisPro">{item.sectionName}</h3>
                                <p>{item.sectionHeader}</p>
                                <ul>
                                    <li onClick={() => toggleConfirmModal(item.sectionId, true)}>
                                        <Restore /> {SITE_TXT.TXT_RESTORE}
                                    </li>
                                    <li onClick={() => toggleConfirmModal(item.sectionId, true, true)}>
                                        <TrashNew />
                                        {SITE_TXT.TXT_DELETE_NEW}
                                    </li>
                                </ul>
                            </div>
                        ))}
                    </div>
                )}

                <div className="buttonSet">
                    <button className="backBtn" onClick={toggleDeletedSections}>
                        Back
                    </button>
                    <button className="nextBtn" onClick={toggleDeletedSections}>
                        Done
                    </button>
                </div>
            </div>
            {openModal && <ConfirmModal onClose={toggleConfirmModal} takeAction={takeAction} isDelete={isDelete} />}
        </div>
    )
}

DeletedSections.propTypes = {
    sections: PropTypes.array.isRequired,
    toggleDeletedSections: PropTypes.func.isRequired,
}

export default DeletedSections

// const DeletedSections = () =>  {
//     state = {
//         openModal: false,
//         isDelete: true,
//     }
//
//     toggleConfirmModal = (openModal, isDelete = false) => {
//         this.setState({ openModal, isDelete })
//     }
//
//     takeAction = () => {
//         const { isDelete } = this.state
//         this.setState({ openModal: false, isDelete })
//     }
//
//     render() {
//         const { openModal, isDelete } = this.state
//         const { toggleDeletedSections, sections } = this.props
//         return (
//             <div className="deletedSection">
//                 <div className="upperSection">
//                     <div className="header">{SITE_TXT.VIEW_DELETED}</div>
//                     <p className="subSection">{SITE_TXT.VIEW_DELETED_SUB_TEXT}</p>
//                 </div>
//
//                 <div className="mainContent">
//                     <div className="sectionContent">
//                         {sections.map((item, i) => (
//                             <div key={item.sectionId} className="mainSlide">
//                                 <h3 className="ellipsisPro">{item.sectionName}</h3>
//                                 <p>{item.sectionHeader}</p>
//                                 <ul>
//                                     <li onClick={() => this.toggleConfirmModal(true)}>
//                                         <Restore /> {SITE_TXT.TXT_RESTORE}
//                                     </li>
//                                     <li onClick={() => this.toggleConfirmModal(true, true)}>
//                                         <TrashNew />
//                                         {SITE_TXT.TXT_DELETE_NEW}
//                                     </li>
//                                 </ul>
//                             </div>
//                         ))}
//                     </div>
//                     <div className="buttonSet">
//                         <button className="backBtn" onClick={toggleDeletedSections}>
//                             Back
//                         </button>
//                         <button className="nextBtn" onClick={toggleDeletedSections}>
//                             Done
//                         </button>
//                     </div>
//                 </div>
//                 {openModal && (
//                     <ConfirmModal onClose={this.toggleConfirmModal} takeAction={this.takeAction} isDelete={isDelete} />
//                 )}
//             </div>
//         )
//     }
// }

// DeletedSections.propTypes = {
//     sections: PropTypes.array.isRequired,
//     toggleDeletedSections: PropTypes.func.isRequired,
// }
//
// export default DeletedSections
