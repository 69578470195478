import React, { Component } from 'react'
import './index.css'
import { CheckboxTickIcon } from '../../Utils/svg'

class Checkbox extends Component {
    constructor(props) {
        super(props)
        this.state = {
            checked: this.props.checked || false,
            label: this.props.label,
            isIndeterminate: this.props.isIndeterminate,
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { checked, isIndeterminate, label } = nextProps
        this.setState({
            checked,
            isIndeterminate,
            label,
        })
    }

    checkToggle = e => {
        this.setState({ checked: !this.state.checked })
    }

    render() {
        const { checked, label, isIndeterminate } = this.state

        return (
            <div className="checkbox-comp">
                <input
                    type="checkbox"
                    checked={checked}
                    className={`${checked ? 'checked' : ''}`}
                    onChange={() => this.checkToggle()}
                />
                <label>{isIndeterminate ? <span className="minus-icon" /> : <CheckboxTickIcon />}</label>
                {label && <span>{label}</span>}
            </div>
        )
    }
}
Checkbox.defaultProps = {
    checked: false,
    label: '',
    isIndeterminate: false,
}
export default Checkbox
