import React, { Component } from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { debounce } from 'lodash'

import Loader from '../Loader'
import { contactWinRoomState, publishLinkForClient } from '../../Containers/LopContent/actions'
import { contactWinRoomFlag } from '../../Containers/LopContent/selector'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { isMobile } from '../../Utils/constants'
import { SearchIcon, LOPIcon, LopHeaderNounContact, LopHeaderLink } from '../../Utils/svg'
import { removeLopData } from '../../Utils/functions'
import './Header.css'

class LopHeader extends Component {
    constructor(props) {
        super(props)
        this.debounceMyLops = debounce(props.getMyLops, 500)
        const lopInfo = localStorage.getItem('myLopData') ? JSON.parse(localStorage.getItem('myLopData')) : {}

        this.state = {
            lopInfo,
            search: '',
            pageNumber: 0,
            pageSize: 10000,
            isLoading: false,
            dropdownOpen: false,
        }
    }

    static getDerivedStateFromProps(nextProps, state) {
        const { isLopListLoading } = nextProps
        const { isLoading } = state
        const changeLoadingState = !isLopListLoading ? isLopListLoading : isLoading
        return { ...state, isLoading: changeLoadingState }
    }

    loadLops = pageNumber => {
        const { search, pageSize } = this.state
        const { getMyLops } = this.props
        const data = {
            search,
            pageSize,
            pageNumber,
        }
        this.setState(
            {
                pageNumber,
                isLoading: true,
            },
            () => {
                getMyLops(data)
            }
        )
    }

    handleLopSearch = search => {
        const { pageSize } = this.state
        const pageNumber = 1
        const data = {
            search,
            pageSize,
            pageNumber,
        }
        this.setState(
            {
                search,
                pageNumber,
            },
            () => {
                this.debounceMyLops(data)
            }
        )
    }

    setCurrenLopInfo = myLopData => {
        localStorage.setItem('myLopData', JSON.stringify(myLopData))
    }

    removeCurrenLopInfo = () => {
        removeLopData()
    }

    handleContactWinRoom = () => {
        this.props.contactWinRoomState(true)
    }

    toggleLopList = () => {
        const { dropdownOpen } = this.state
        const { isLopListLoading } = this.props
        if (!dropdownOpen && !isLopListLoading) {
            this.loadLops(1)
        }
        this.setState({ dropdownOpen: !dropdownOpen })
    }

    handlePublishLink = () => {
        const { publishLinkForClient } = this.props
        publishLinkForClient(true)
    }

    render() {
        const { search, lopInfo, dropdownOpen } = this.state
        const { isExternal = false, name = '' } = lopInfo
        const permissionId = parseInt(lopInfo.permissionId)
        const { myLopData, isLopListLoading, hideSideBar } = this.props
        const { data } = myLopData
        if (isExternal) return null
        return (
            <ul className={`lopNavBar ${isMobile ? ' lop-mobile-nav' : ''}`}>
                {name && (
                    <li className="ctawinroom" onClick={this.handleContactWinRoom}>
                        <LopHeaderNounContact /> <span>Contact M&A Win Room</span>
                    </li>
                )}
                {hideSideBar && permissionId > 2 && (
                    <li className="publishLink" onClick={this.handlePublishLink}>
                        <LopHeaderLink /> <span>Publish external link for client</span>
                    </li>
                )}
                <li className="lopDropDown">
                    <Dropdown isOpen={dropdownOpen} toggle={this.toggleLopList}>
                        <DropdownToggle>
                            <LOPIcon colorFill="#000000" style={{ marginRight: 2 }} /> My LOPs
                        </DropdownToggle>
                        <DropdownMenu left={'true'}>
                            <div className="searchLopContainer">
                                <div className="searchContentOuter">
                                    <SearchIcon />
                                    <input
                                        type="text"
                                        value={search}
                                        onChange={e => this.handleLopSearch(e.target.value)}
                                    />
                                </div>
                            </div>
                            {isLopListLoading ? (
                                <div className="loaderLopList">
                                    <Loader />
                                </div>
                            ) : (
                                <div className="mainLopList">
                                    {data.map((sectionInfo, i) => (
                                        <DropdownItem
                                            key={sectionInfo.id}
                                            onClick={() => this.setCurrenLopInfo(data[i])}
                                        >
                                            <Link to="/createLop">{sectionInfo.name}</Link>
                                        </DropdownItem>
                                    ))}
                                </div>
                            )}
                        </DropdownMenu>
                        <div className="dropIcon" />
                    </Dropdown>
                </li>
                <li onClick={this.removeCurrenLopInfo} className="createLopLink">
                    <Link to="/createLop">
                        <span className="addIcon">+</span>
                        <span className="btnCreateLop">Create New LOP</span>
                    </Link>
                </li>
            </ul>
        )
    }
}

LopHeader.propTypes = {
    getMyLops: PropTypes.func.isRequired,
    publishLinkForClient: PropTypes.func.isRequired,
    myLopData: PropTypes.instanceOf(Object).isRequired,
    isLopListLoading: PropTypes.bool.isRequired,
    hideSideBar: PropTypes.bool.isRequired,
}

const stateToProps = createStructuredSelector({
    contactWinRoomFlag: contactWinRoomFlag(),
})

const dispatchProps = {
    contactWinRoomState,
    publishLinkForClient,
}

export default connect(stateToProps, dispatchProps)(LopHeader)
