import {
    PptLop,
    PptLopActive,
    PptLopText,
    PptLopTextActive,
    VideoLop,
    VideoLopActive,
    VideoTextLop,
    VideoTextLopActive,
    ImageCarousel,
    ImageCarouselActive,
    ImageCarouselText,
    ImageCarouselTextActive,
    ImageCollage4,
    ImageCollage4Active,
    ImageCollage5,
    ImageCollage5Active,
    ImageCollage6,
    ImageCollage6Active,
    ImageCollageText,
    ImageCollageTextActive,
    SingleImage,
    SingleImageActive,
    SingleImageText,
    SingleImageTextActive,
    HeadingWithOutText,
    HeadingWithText,
    HeadingWithTextActive,
    HeadingWithOutTextActive,
    InactiveMultiVidWithoutText,
    ActiveMultiVidWithoutText,
    InactiveMultiVidWithText,
    ActiveMultiVidWithText,
} from '../Utils/svg'

//eslint-disable-next-line
export const ALPHA_NUMERIC_PATTERN = /[^a-z^A-Z^0-9\^_]+/
export const DOCUMENT_NAME_PATTERN = /^[a-z A-Z0-9_&\-']+$/
//eslint-disable-next-line
export const PERSON_NAME_PATTERN = /^["a-z A-Z0-9_&\-'"]+$/
export const NAME_PATTERN = /^[a-zA-Z0-9 _,.&-]+$/
export const CHARGECODE_PATTERN = /^[a-zA-Z0-9]+$/
export const EMAIL_PATTERN = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$/
//eslint-disable-next-line
export const NEW_EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const EMAIL_PATTERN_DOMAIN = /[a-z0-9._%+-]+@(external.)?mckinsey\.com$/
export const LINK_PATTERN = /^((http|https)?:\/\/|\/\/)/
export const NUMBER_PATTERN = /^(0|[1-9][0-9]*)$/
export const MAX_TAGS_ALLOWED_EXPERT = 20
export const MAX_TAGS_ALLOWED = 10
export const MAX_TAGS_ALLOWED_DESTINATION = 1
export const MAX_TAGS_ALLOWED_LOP_INDUSTRY = 1
export const MAX_TAGS_ALLOWED_LOP_KEYWORDS = 20
export const MAX_TAGS_ALLOWED_LOP_INDUSTRY_TOPIC = 20
export const MAX_TAGS_ALLOWED_LOP_ROLE = 1
export const MAX_TAGS_ALLOWED_LOP_INDUSTRY_ROLE_CLIENT_REFERENCE = 1
export const MAX_TAGS_ALLOWED_OFFICE = 1
export const MAX_LENGTH_TOOL_DESCRIPTION = 70
export const TOOL_NAME_LENGTH_ALLOWED = 30
export const MAX_NAME_LENGTH = 250
export const MAX_NAME_LENGTH_15 = 15
export const MAX_NAME_LENGTH_100 = 100
export const ENTER_KEY_CODE = 13
export const COMMA_KEY_CODE = 188
export const TAB_KEY_CODE = 9
export const MAX_LENGTH_NOTES = 1000
export const ORG_WIN_ROOM_EMAIL = 'MA_Win_Room@mckinsey.com'
export const ORG_WIN_ROOM_EMAIL_NEW = 'Hello_MA@mckinsey'
export const PROPOSE_TO_WIN_LINK = 'https://home.intranet.mckinsey.com/proposetowin/'
export const isMobile = window.innerWidth <= 767
export const isUptoTabletPortrait = window.innerWidth <= 768
export const IsIpadPortrait = window.innerWidth === 768
export const isMobileTablet = window.innerWidth <= 1024
export const isTablet = 768 <= window.innerWidth && window.innerWidth <= 1024
export const isIphoneX = 769 <= window.innerWidth && window.innerWidth <= 812 && window.devicePixelRatio === 3
export const isLaptop = window.innerWidth > 1024

export const AWS_CONFIG = {
    region: 'us-east-1',
    credentials: {
        accessKeyId: '',
        secretAccessKey: '',
    },
}
export const BUCKET_DIR_PARAMS = { params: { Bucket: 'ohi-npn-upload-image' } }

export const DELIVER_HEADING =
    'One-stop shop for access to all solutions used to accelerate mergers, design the NewCo and increase value capture'

export const LIBRARY_HEADING = 'Home to all M&A Integration playbook materials'

export const LEARN_HEADING = 'Coming soon: Section for learning modules'

export const MMPRACTICE_HEADING =
    'Coming soon: Section where we will host all our practice management content (e.g., Integration council meetings, Recruiting, Client pipeline management, ED capacity tracker, Conference Board, Global Knowledge Summit, etc…'
export const LOP_HEADING = 'One-stop shop section for all your LOP content needs'

export const INDUSTRY_HEADING = 'Coming soon: Section to host industry specific materials'
export const ENV_VARS = process.env
export const SSO_SIGN_IN_LINK = `https://${ENV_VARS.REACT_APP_AUTH_0_DOMAIN}/authorize?response_type=code&client_id=${ENV_VARS.REACT_APP_AUTH_0_CLIENT_ID}&connection=MCKINSEYSSO&redirect_uri=${ENV_VARS.REACT_APP_AUTH_0_REDIRECT_URI}&state=12111&scope=openid profile email offline_access`
export const userInfo = JSON.parse(localStorage.getItem('userInfo'))
export const userRoleNew = [userInfo && userInfo.newRole]
export const userAuthorization = userInfo && { user_metadata: { loginValidity: userInfo.loginValidity } }
// userInfo && userInfo[`${ENV_VARS.REACT_APP_AUTH_0_NAMESPACE}_user_authorization`]

export const userRoles = userRoleNew
    ? userRoleNew
    : userAuthorization && userAuthorization['app_metadata'] && userAuthorization['app_metadata']['Roles']

export const isAdmin = (userRoles && userRoles.includes('Admin')) || false
export const isCST = (userRoles && userRoles.includes('CST')) || false
export const isCSTLOP = (userRoles && userRoles.includes('CST_LOP')) || false
export const isMM_Core = (userRoles && userRoles.includes('MM_Core')) || false
export const isExternalClinet = (userRoles && userRoles.includes('External_Client')) || false

export const IMAGE_CROP_OBJ_SQUARE = {
    x: 20,
    y: 10,
    width: 50,
    aspect: 1,
}
export const IMAGE_CROP_USER_PROFILE = {
    x: 0,
    y: 0,
    height: 90,
    aspect: 1,
}
export const IMAGE_CROP_TEAM_PROFILE = {
    x: 0,
    y: 0,
    height: 90,
    aspect: 0.94,
}
export const IMAGE_CROP_SIGNATURE = {
    unit: '%',
    x: 5,
    y: 10,
    width: 90,
    height: 70,
}
export const IMAGE_CROP_OBJ_RECT = {
    x: 20,
    y: 10,
    height: 40,
    aspect: 16 / 9,
}
export const IMAGE_CROP_NO_WIDTH = {
    x: 20,
    y: 10,
    aspect: 16 / 9,
}

export const IMAGE_CROP_LOP = {
    x: 10,
    y: 10,
    height: 30,
    aspect: 3,
}

export const IMAGE_CROP_LOP_SECTION_HEADER = {
    x: 10,
    y: 10,
    aspect: 6,
}

export const IMAGE_CROP_LOP_LOGO = {
    x: 20,
    y: 10,
    height: 20,
    aspect: 16 / 9,
}

export const LOP_SECTIONS_SLIDER_SETTING = {
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    draggable: false,
    infinite: false,
    dots: false,
    arrows: true,
    adaptiveHeight: false,
}

export const COLLAGE_SLIDER = {
    slidesToShow: 1,
    rows: 1,
    slidesToScroll: 1,
    speed: 500,
    draggable: true,
    infinite: false,
    arrows: true,
    adaptiveHeight: true,
}

export const TEXT_EDITOR_CONFIG = {
    display: ['LINK_BUTTONS', 'INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
        { label: 'Bold', style: 'BOLD' },
        { label: 'Italic', style: 'ITALIC' },
        { label: 'Underline', style: 'UNDERLINE' },
    ],
    BLOCK_TYPE_BUTTONS: [
        { label: 'UL', style: 'unordered-list-item' },
        { label: 'OL', style: 'ordered-list-item' },
    ],
}

export const WARNING_TIME = 25
export const SESSION_TIMEOUT_TIME = 30

export const STATUS_SUCCESS = 'success'
export const STATUS_FAILURE = 'failure'
export const STATUS_ERROR = 'error'
export const STATUS_FAILED = 'failed'

export const DOT_SLIDER_4_ROWS = {
    dots: true,
    arrows: false,
    slidesToShow: 1,
    rows: 4,
    slidesToScroll: 1,
    infinite: false,
    speed: 500,
    className: 'custom-slider',
}
export const MonthList = [
    { key: '1', value: 'Jan' },
    { key: '2', value: 'Feb' },
    { key: '3', value: 'Mar' },
    { key: '4', value: 'Apr' },
    { key: '5', value: 'May' },
    { key: '6', value: 'Jun' },
    { key: '7', value: 'Jul' },
    { key: '8', value: 'Aug' },
    { key: '9', value: 'Sep' },
    { key: '10', value: 'Oct' },
    { key: '11', value: 'Nov' },
    { key: '12', value: 'Dec' },
]

export const MonthListCode = [
    { key: '1', value: '01' },
    { key: '2', value: '02' },
    { key: '3', value: '03' },
    { key: '4', value: '04' },
    { key: '5', value: '05' },
    { key: '6', value: '06' },
    { key: '7', value: '07' },
    { key: '8', value: '08' },
    { key: '9', value: '09' },
    { key: '10', value: '10' },
    { key: '11', value: '11' },
    { key: '12', value: '12' },
]

export const MonthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export const MonthsOptions = [
    { key: '1', value: 'Jan' },
    { key: '2', value: 'Feb' },
    { key: '3', value: 'Mar' },
    { key: '4', value: 'Apr' },
    { key: '5', value: 'May' },
    { key: '6', value: 'Jun' },
    { key: '7', value: 'Jul' },
    { key: '8', value: 'Aug' },
    { key: '9', value: 'Sep' },
    { key: '10', value: 'Oct' },
    { key: '11', value: 'Nov' },
    { key: '12', value: 'Dec' },
]

// export const DealSize = [
//     { key: '1', value: '<1Bn' },
//     { key: '2', value: '1Bn-5Bn' },
//     { key: '3', value: '5Bn-10Bn' },
//     { key: '4', value: '10Bn-50Bn' },
//     { key: '5', value: '>50Bn' },
// ]

export const DealSizeLowOptions = [
    { key: '-1', value: '< 1 BN' },
    { key: '1', value: '1 BN' },
    { key: '5', value: '5 BN' },
    { key: '10', value: '10 BN' },
    { key: '50', value: '50 BN' },
]

export const DealSizeHighOptions = [
    { key: '1', value: '1 BN' },
    { key: '5', value: '5 BN' },
    { key: '10', value: '10 BN' },
    { key: '50', value: '50 BN' },
    { key: '55', value: '> 50 BN' },
]

export const formData = {
    id: '',
    name: '',
    designation: '',
    image1: '',
    image2: '',
}

export const DealType = [
    {
        key: '1',
        value: 'Industry consolidation (large & similar business)',
    },
    {
        key: '2',
        value: 'Corporate transformation (large & different business)',
    },
    { key: '3', value: 'New Business model (medium & different business)' },
    { key: '4', value: 'Small tuck-in' },
]

export const TEMPLATE_1 = {
    Video: [
        {
            isText: 'False',
            isHeader: false,
            isTextArea: false,
            content: 'Video',
            Icon: VideoLopActive,
            DefaultIcon: VideoLop,
            contentTypeId: 1,
        },
    ],
}

export const LOP_CUSTOM_SECTIONS = {
    'Image(s)': [
        {
            isText: 'False',
            isHeader: false,
            isTextArea: false,
            content: 'Image Carousel',
            Icon: ImageCarouselActive,
            DefaultIcon: ImageCarousel,
            layoutId: 1,
            contentTypeId: 2,
        },
        {
            isText: 'True',
            isHeader: false,
            isTextArea: false,
            content: 'Carousel with Text',
            Icon: ImageCarouselTextActive,
            DefaultIcon: ImageCarouselText,
            layoutId: 2,
            contentTypeId: 2,
        },
        {
            isText: 'False',
            isHeader: false,
            isTextArea: false,
            content: 'Image Collage (04)',
            Icon: ImageCollage4Active,
            DefaultIcon: ImageCollage4,
            layoutId: 3,
            contentTypeId: 2,
        },
        {
            isText: 'False',
            isHeader: false,
            isTextArea: false,
            content: 'Image Collage (05)',
            Icon: ImageCollage5Active,
            DefaultIcon: ImageCollage5,
            layoutId: 4,
            contentTypeId: 2,
        },
        {
            isText: 'False',
            isHeader: false,
            isTextArea: false,
            content: 'Image Collage (06)',
            Icon: ImageCollage6Active,
            DefaultIcon: ImageCollage6,
            layoutId: 5,
            contentTypeId: 2,
        },
        {
            isText: 'True',
            isHeader: false,
            isTextArea: false,
            content: 'Image collage (03) with text',
            Icon: ImageCollageTextActive,
            DefaultIcon: ImageCollageText,
            layoutId: 6,
            contentTypeId: 2,
        },
        {
            isText: 'False',
            isHeader: false,
            isTextArea: false,
            content: 'Single Image',
            Icon: SingleImageActive,
            DefaultIcon: SingleImage,
            layoutId: 7,
            contentTypeId: 2,
        },
        {
            isText: 'True',
            isHeader: false,
            isTextArea: false,
            content: 'Image with Text',
            Icon: SingleImageTextActive,
            DefaultIcon: SingleImageText,
            layoutId: 8,
            contentTypeId: 2,
        },
    ],
    Document: [
        {
            isText: 'False',
            isHeader: false,
            isTextArea: false,
            content: 'Doc without text',
            Icon: PptLopActive,
            DefaultIcon: PptLop,
            contentTypeId: 3,
        },
        {
            isText: 'True',
            isHeader: false,
            isTextArea: false,
            content: 'Doc with text',
            Icon: PptLopTextActive,
            DefaultIcon: PptLopText,
            contentTypeId: 3,
        },
    ],
    Video: [
        {
            isText: 'False',
            isHeader: false,
            isTextArea: false,
            content: 'Video without text',
            Icon: VideoLopActive,
            DefaultIcon: VideoLop,
            contentTypeId: 1,
            isMulti: false,
        },
        {
            isText: 'True',
            isHeader: false,
            isTextArea: false,
            content: 'Video with text',
            Icon: VideoTextLopActive,
            DefaultIcon: VideoTextLop,
            contentTypeId: 1,
            isMulti: false,
        },
        {
            isText: 'False',
            isHeader: false,
            isTextArea: false,
            content: 'Multiple videos without text',
            Icon: ActiveMultiVidWithoutText,
            DefaultIcon: InactiveMultiVidWithoutText,
            contentTypeId: 1,
            isMulti: true,
        },
        {
            isText: 'True',
            isHeader: false,
            isTextArea: false,
            content: 'Multiple videos with text',
            Icon: ActiveMultiVidWithText,
            DefaultIcon: InactiveMultiVidWithText,
            contentTypeId: 1,
            isMulti: true,
        },
    ],
    Text: [
        {
            isText: 'False',
            content: 'Heading',
            isHeader: true,
            isTextArea: false,
            Icon: HeadingWithOutTextActive,
            DefaultIcon: HeadingWithOutText,
            contentTypeId: 4,
        },
        {
            isText: 'False',
            isHeader: false,
            isTextArea: true,
            content: 'Textarea',
            Icon: HeadingWithTextActive,
            DefaultIcon: HeadingWithText,
            contentTypeId: 5,
        },
    ],
}

export const SITE_TXT = {
    TXT_MERGER_MANAGANEMENT_DIGITAL_HUB: 'Merger Management Digital Home',
    TXT_MCK_MM: ' McKinsey Merger Management',
    TXT_MCK_MM_HUB: 'M&A Integration Digital Home',
    TXT_MCK_LOGO: 'McKinsey&Company',
    TXT_HOME: 'Home',
    TXT_DELIVER: 'Deliver',
    TXT_LIBRARY: 'Library',
    TXT_MM_CORE_CST: 'My CSTs',
    TXT_ADMIN_CORE_CST: 'CSTs',
    TXT_LEARN: 'Learn',
    TXT_MM_PRACTICE: 'MM Practice',
    TXT_LOP: 'LOP',
    TXT_LOP_CREATE: 'LOP Name',
    TXT_LOP_PLACEHOLDER: 'LOP Name here',
    TXT_INDUSTRY: 'Industry',
    TXT_ADMIN: 'Admin',
    TXT_USER: 'User',
    TXT_DOCUMENTS: 'Documents',
    TXT_VIDEOS: 'Videos',
    TXT_ADD_NEW_VIDEO: 'Add New Video',
    TXT_VIDEO_NAME: 'Name of the Video',
    TXT_VIDEO_DESC: 'Description',
    TXT_VIDEO_UPLOAD: 'Upload Video',
    TXT_SIGN_IN: 'Sign In with Username & Password',
    TXT_SIGN_IN_SSO: 'Sign In with SSO',
    TXT_WELCOME_TO: 'Welcome to',
    TXT_WELCOME: 'Welcome',
    TXT_EMAIL_OR_USERNAME_REQUIRED: 'Email or Username is required',
    TXT_EMAILADDRESS: 'Email Address',
    TXT_LOGOUT: 'Log out',
    TXT_PAGE_NOT_EXIST: 'Oops! Page does not exist',
    TXT_NAME: 'NAME',
    TXT_REGION_BASE: 'Region Base',
    TXT_NAME_PLACEHOLDER: 'Name here...',
    TXT_DOCNAME_MANDATORY: 'Document Name is mandatory',
    TXT_DOCNAME_VALID_PATTERN:
        'Document Name has invalid pattern!\nIt should not contain (/*?"<>|+$)@#%^,.[]{}:~` characters',
    TXT_PERSON_NAME_VALID_PATTERN:
        'Person Name has invalid pattern!\nIt should not contain (/*?<>|+$)@#%^,.[]{}:~` characters',
    TXT_NAME_VALID_PATTERN:
        'Client Name has invalid pattern!\nIt should not contain (/:*?"<>|+$)@#%^&\',.[]{} ~` characters',
    TXT_PARTNER_VALID_PATTERN:
        'Partner Name has invalid pattern!\nIt should not contain (/:*?"<>|+$)@#%^&\',.[]{} ~` characters',
    TXT_DESIGNATION_VALID_PATTERN:
        'Designation has invalid pattern!\nIt should not contain (/:*?"<>|+$)@#%^&\',.[]{} ~` characters',
    TXT_FOLDERNAME_VALID_PATTERN:
        'Folder Name has invalid pattern!\nIt should not contain (/:*?"<>|+$)@#%^&\',.[]{} ~` characters',
    TXT_NAME_MAX_LENGTH: "File Name shouldn't exceed " + MAX_NAME_LENGTH + ' characters',
    TXT_CLIENT_MAX_LENGTH: "Client Name shouldn't exceed " + MAX_NAME_LENGTH_15 + ' characters',
    TXT_FOLDER_NAME_MAX_LENGTH: "Folder Name shouldn't exceed " + MAX_NAME_LENGTH + ' characters',
    TXT_WILL_COME_SOON: 'Work in progress...',
    TXT_COPYRIGHT: '@copyright since ...',
    TXT_ADD: 'ADD',
    TXT_EDIT: 'EDIT',
    TXT_DELETE: 'DELETE',
    TXT_RESTORE: 'Restore',
    TXT_DELETE_NEW: 'Delete',
    TXT_PATH: 'PATH',
    TXT_CANCEL: 'CANCEL',
    TXT_NEXT: 'NEXT',
    TXT_DONE: 'Done',
    TXT_USER_TYPE: 'USER TYPE',
    TXT_FORGOT_PASSWORD: 'FORGOT PASSWORD',
    TXT_FORGOT_PASSWORD_REDIRECT: 'Forgot Password ?',
    TXT_EMAIL: 'Email',
    TXT_EMAIL_INVALID: 'Email pattern is invalid',
    TXT_NUMBER_INVALID: 'Phone Number should have 10 digits',
    TXT_SEND: 'Send',
    TXT_EMAIL_REQUIRED: 'Email is required',
    TXT_ENTER_EMAILID_SIGNEDUP: 'Enter the email ID used when you signed up.',
    TXT_EMAIL_WILLBE_SENT_MESSAGE: 'An email will be sent with a link to reset your password.',
    TXT_SELECT_USER_TYPE: 'Select User Type',
    TXT_EMAILID_PLACEHOLDER: 'Email id here...',
    TXT_ADD_PHASE: 'Add Phase',
    TXT_EDIT_DELETE_PHASE: 'Edit/Delete Phase',
    TXT_ADD_NEW_SECTION: 'Add new section',
    TXT_REORDER_SECTION: 'Reorder Section',
    TXT_TOPIC_NAME: 'Topic Name',
    TXT_SECTION_NAME: 'Section Name',
    TXT_EDIT_MODE: 'Edit Mode',
    TXT_EDIT_EXIT_MODE: 'Exit Edit Mode',
    TXT_EDIT_DELETE: 'EDIT/DELETE',
    TXT_EDIT_DELETE_EXIT: 'Exit EDIT/DELETE',
    TXT_SAVE_ORDER: 'Save order',
    TXT_REORDER: 'Reorder',
    TXT_DOCUMENT_TYPE: 'DOCUMENT TYPE',
    TXT_SELECT_DOCUMENT_TYPE: 'Select Document Type',
    TXT_SELECT_DOCUMENT_TAG: 'Select Document Tag',
    TXT_SURE_DELETE_DOCUMENT: 'Are you sure, you want to delete this document?',
    TXT_SURE_DELETE_CASE_STUDY: 'Are you sure, you want to delete this case study?',
    TXT_SURE_DELETE_EXPERT_CV: 'Are you sure, you want to delete this cv?',
    TXT_SURE_DELETE_FOLDER_DOCUMENT:
        'All documents will get deleted automatically under this folder. Are you sure you want to delete it?',
    TXT_SURE_DELETE_TOOL: 'Are you sure, you want to delete this tool?',
    TXT_ALLOWED_FILES_TEXT: 'Allowed file extensions are:',
    TXT_BROWSE_FILE: 'Browse File',
    TXT_DOCUMENT_PATH: 'Document Path',
    TXT_UPLOAD_DOCUMENT: 'Upload Document',
    TXT_REPLACE_DOCUMENT: 'Replace Document',
    TXT_UPLOAD_BTN: 'Upload',
    TXT_REPLACE_BTN: 'Replace',
    TXT_ADMIN_SESSION: 'Admin Session',
    TXT_EXTEND: 'extend',
    TXT_MONTHS: 'Months',
    TXT_CUSTOM: 'Custom',
    TXT_UPLOAD: 'UPLOAD',
    TXT_ADD_TAGS_PLACEHOLDER: 'Add Tags here',
    TXT_RESET: 'RESET',
    TXT_NO_DATA: 'Opps! No Data Available',
    TXT_PASSWORD: 'Password',
    TXT_PHASE_PATH: 'PHASE PATH',
    TXT_ADD_TOPIC: 'ADD TOPIC',
    TXT_TOPIC: 'Topic',
    TXT_ADD_SECTION: 'ADD SECTION',
    TXT_EDIT_DELETE_TOPIC: 'EDIT/DELETE TOPIC',
    TXT_STEP_NAME: 'Step Name',
    TXT_PHASE_NAME_PLACEHOLDER: 'Phase name here...',
    TXT_SECTION_NAME_PLACEHOLDER: 'Section name here...',
    TXT_INVALID_FILE_EXT: 'Invalid File Type! Please upload valid File Type',
    TXT_PHASE_NAME_INVALID_PATTERN:
        'Invalid characters in the phase name\nIt should not contain any of (/:*?"<>|+$)@#%^&\',.[]{} ~`',
    TXT_PHASE_DELETE_ALERT:
        'All documents will get deleted automatically under this phase.\nAre you sure you want to delete it?',
    TXT_PHASE_DELETE_TOPIC:
        'All items will get deleted automatically under this section.\nAre you sure you want to delete it?',
    TXT_USER_IMAGE: 'User Image',
    TXT_REF_EMAIL: 'REFRENCE EMAIL',
    TXT_NO_PHASE_AVAILABLE: 'NO PHASE AVAILABLE',
    TXT_NO_DOCUMENT_AVAILABLE: 'NO DOCUMENT AVAILABLE',
    TXT_SHOWING: 'Showing :',
    TXT_NO_RECORDS_FOUND: 'No records found!',
    TXT_SEARCH_RESULT: 'Search Result',
    TXT_ALL: 'All',
    TXT_RESET_SEARCH: 'Reset',
    TXT_RESET_FILTER: 'Reset Filter',
    TXT_SEARCH_BTN: 'Search',
    TXT_KEYWORDS: 'Keywords',
    TXT_TYPE: 'Type',
    TXT_FUNCTION_OR_TOPICS: 'Function or Topic',
    TXT_PASSWORD_REQUIRED: 'Password is required',
    TXT_LOP_CENTRAL: 'LOP Central',
    TXT_STOPSHOP_LOP: 'One stop shop for all your LOP needs',
    TXT_DELETED_CONTENT: 'Recently deleted elements – will be permanently removed once you log out of the microsite',
    TXT_EDITING_LOP:
        'You are now editing this LOP. Other collaborators will not be able to edit the LOP until you 1) close this message, 2) click "done" at the bottom of this page, or 3) exit the LOP.',
    TXT_ADD_HERE: 'ADD HERE',
    TXT_NEW_PASSWORD_PLACEHOLDER: 'Enter New Password',
    TXT_RE_ENTER_NEW_PASSWORD_PLACEHOLDER: 'Re-Enter New Password',
    TXT_PASSWORD_SAME: 'Passwords should be same!',
    TXT_SUBMIT: 'Submit',
    TXT_RESET_PASSWORD: 'RESET PASSWORD',
    TXT_PASSWORD_REQUEST: 'You have requested to reset the password for:',
    TXT_TEST_EMAILID: 'testemail@mckinsey.com',
    TXT_EXPERTISE: 'EXPERTISE',
    TXT_ROLE: 'ROLE',
    TXT_EMAILID: 'EMAIL ID',
    TXT_CREATE_USER_ADMIN: 'Create User/Admin',
    TXT_UPDATE_USER_ADMIN: 'Update User/Admin',
    TXT_UPDATE_USER_PROFILE: 'Update Profile',
    TXT_SR_NO: 'SR. NO.',
    TXT_CONTENT: 'CONTENT',
    TXT_SEARCH_PLACEHOLDER: 'Search here...',
    TXT_SEARCHBY_DOCNAME: 'Search by CONTENT...',
    TXT_VALID_SUBSECTION: 'Please enter a valid subsection text',
    TXT_INVALID_NAME_PATTERN:
        'Invalid characters in the name\nIt should not contain any of (/:*?"<>|+$)@#%^&\',.[]{} ~`',
    TXT_FILE_FORMAT: 'FILE FORMAT',
    TXT_CONFIRM: 'Confirm',
    TXT_CONFIRM_DELETE: 'Confirm Delete',
    TXT_DELETE_LIB_CATEGORY_MESSAGE:
        'All documents will get deleted automatically under this sub-section.\nAre you sure you want to delete it?',
    TXT_FORMAT: 'FILE FORMAT',
    TXT_YES: 'Yes',
    TXT_EXPIRATION_DATE: 'EXPIRATION DATE',
    TXT_NO: 'No',
    TXT_ACTIONS: 'ACTIONS',
    TXT_PLACEHOLDER: 'Type here...',
    TXT_CLICK_TOADD_IMAGE: 'Click to add Image',
    TXT_CLICK_TOCHANGE_IMAGE: 'Click to change image',
    TXT_CLICK_TOCHANGE_LOPIMAGE: 'Click to change the section of the image',
    TXT_DOCUMENT_TAGS: 'Document Tags',
    TXT_FILE_TYPE: 'FILE TYPE',
    TXT_LOADING: 'Loading ...',
    TXT_DOCNAME: 'Document Name',
    TXT_DOCNAME_LOP: 'Document Name',
    TXT_VIDNAME_LOP: 'Video Name',
    TXT_VID_DESC: 'Video Description',
    TXT_VID_DATE: 'Video Upload Date',
    TXT_VID_LINK_COPY: 'Use this URL to embed the video in a proposal microsite',
    TXT_VID_LINK_META: 'COPY LINK',
    TXT_FOLDERNAME: 'Folder Name',
    TXT_DOCNAME_PLACEHOLDER: 'Document name here...',
    TXT_VIDNAME_PLACEHOLDER: 'Video name here...',
    TXT_FOLDERNAME_PLACEHOLDER: 'Folder name here...',
    TXT_NO_IMAGE: 'NO image',
    TXT_CREATE_ADMIN_AND_USERS: 'Create Admin & Users',
    TXT_DOCUMENTTYPE_MANDATORY: 'Document Type is mandatory',
    TXT_DRAG_DROP_CHOOSE_FILE_MANDATORY: 'Document File is mandatory. Please browse/drag-drop required file',
    TXT_USERNAME_MANDATORY: 'User Name is mandatory',
    TXT_LOP_MANDATORY: 'LOP Name is mandatory',
    TXT_USERTYPE_MANDATORY: 'User Type is mandatory',
    TXT_EMAILID_MANDATORY: 'Email Id is mandatory',
    TXT_EMAILID_INVALID_PATTERN: "Email Id's invalid pattern!",
    TXT_EXPERTISETAG_MANDATORY: 'Expertise Tag is mandatory',
    TXT_DESIGNATIONTAG_MANDATORY: 'Designation Tag is mandatory',
    TXT_DOCUMENTTAG_MANDATORY: 'Document Tag is mandatory',
    TXT_EXPIRED: 'Expired',
    TXT_TO_EXPIRE: 'About to expire',
    TXT_EXTENSION_OF_DPOCUMENTS: 'Extension Of Documents',
    TXT_DATE_PATTERN: 'MM/DD/YYYY',
    TXT_ENTER_TIME_DURATION: 'Please select a Time Duration!',
    TXT_USERNAME_INVALID_PATTERN:
        "User Name's invalid pattern!\nIt should not contain (/:*?\"<>|+$)@#%^&',.[]{} ~` characters",
    TXT_LOP_INVALID_PATTERN:
        "LOP Name's invalid pattern!\nIt should not contain (/:*?\"<>|+$)@#%^&',.[]{} ~` characters",
    TXT_CST_NAME_VALID_PATTERN:
        'CST Name contains invalid pattern!\nIt should not contain any special characters like (/:*?"<>|+$)@#%^&\',.[]{} ~`',
    TXT_CHARGECODE_VALID_PATTERN:
        'Charge code contains invalid pattern!\nIt should not contain any special characters like (/:*?"<>|+$)@#%^&\',.[]{} ~`',
    LOP_LOGOS_MANDATORY: 'Company Logos are mandatory!\n Please upload at least 2 logos',
    CST_USER_MESSAGE:
        'This person is a CST User, their User Type will have to be modified for them to access the LOP section',
    TXT_FILE_TYPE_ACCEPTED: '.doc, .docx, .ppt, .pptx, .pdf, .xls, .xlsx, .csv, .txt',
    PDF_FILE_TYPE_ACCEPTED: '.pdf',
    TXT_FILE_TYPE_ACCEPTED_GOLD:
        '.doc, .docx, .ppt, .pptx, .pdf, .xls, .xlsx, .csv, .txt, .avi, .flv, .wmv, .mp4, .mov',
    TXT_VIDEO_TYPE_ACCEPTED: '.webm, .mkv, .flv, .mov, .mp4, .mpg, .3gp, .avi, .wmv, .m4v',
    TXT_DROPZONE: 'Drag file to upload or',
    TXT_DROPZONE_TEXT: 'Drag/drop document here OR click to browse document to upload',
    TXT_DROPZONE_TEXT_NEW: 'Drag file to upload or BROWSE',
    TXT_CREATE_USER_FILES_ACCEPTED: '.png, .jpeg, .jpg, .bmp',
    TXT_MAX_TAGS_ALLOWED: `Maximum Number of Document Tags allowed should not be greater than ${MAX_TAGS_ALLOWED}`,
    TXT_MAX_TAGS_ALLOWED_DESTINATION: `Maximum Number of Designation Tags allowed should not be greater than ${MAX_TAGS_ALLOWED_DESTINATION}`,
    TXT_MAX_TAGS_ALLOWED_EXPERT: `Maximum Number of Expertise Tags allowed should not be greater than ${MAX_TAGS_ALLOWED_EXPERT}`,
    TXT_TODAYS_ACTIVITY: "Today's activity",
    TXT_TOTAL_NO_OF_USERS: 'Total number of Users',
    TXT_TOTAL_CLIENTREF: 'Total Client References',
    TXT_NUMBER_OF_ADMIN_USERS: 'Number of admin Users',
    TXT_NEW_USERS: 'New Users',
    TXT_THIS_MONTH: 'This month',
    TXT_THIS_YEAR: 'This year',
    TXT_USER_HISTORY: 'User History',
    TXT_DOWNLOAD_HISTORY: 'WEEKLY DOWNLOADS',
    TXT_TAKE_ACTION: 'ACTION',
    TXT_EDIT_DOCUMENT: 'Edit document',
    TXT_RECENTLY_SEARCHED: 'Your Recently Searched',
    TXT_SETUP_NEW: 'Setup New',
    TXT_GO_TO_DOCUMENT: 'Go to document',
    TXT_EMAIL_DOCUMENTS: 'Email Documents',
    TXT_DOWNLOAD: 'Download',
    TXT_ADD_TOOL_DETAILS: 'Add tool details',
    TXT_EDIT_TOOL_DETAILS: 'Edit tool details',
    TXT_TOOL_ICON_NAME: 'Tool Icon & Name',
    TXT_TOOL_LINK: 'Tool Link',
    TXT_TOOL_NAME_PLACEHOLDER: 'Tool Name',
    TXT_VIDEO_LINK_PLACEHOLDER: 'https://www.example.com/',
    TXT_TOOL_LINK_PLACEHOLDER: 'https://www.example.com/',
    TXT_DELIVER_DROPZONE_TEXT: 'Drop file to upload',
    TXT_BROWSE: 'BROWSE',
    TXT_DELIVER_FILE_TYPE_ACCEPTED: '.png, .jpeg, .jpg, .bmp',
    TXT_DELIVER_BROCHURE_FILE_TYPE_ACCEPTED: '.pdf, .doc, .docx, .ppt, .pptx',
    TXT_ARTICLE_FILE_TYPE_ACCEPTED: '.pdf, .doc, .docx',
    TXT_TOOL_IMAGES: 'Tool Images',
    TXT_PLACEHOLDER_DELIVER_TOOL_DESCIRPTION: 'Add a description of maximum of 70 words. ',
    TXT_TOOL_DESCIRPTION: 'Tool description',
    TXT_UPLOAD_BROCHURE: 'Upload Brochure',
    TXT_VIDEO_LINK: 'Video Link',
    TXT_TOOL_NAME_REQUIRED: 'Tool name is required.',
    TXT_TOOL_ICON_REQUIRED: 'Tool icon is required',
    TXT_TOOL_DESCRIPTION_REQUIRED: 'Tool description is required.',
    TXT_TOOL_LINK_REQUIRED: 'Tool link is required.',
    TXT_VIDEO_LINK_REQUIRED: 'Video link is required.',
    TXT_VIDEO_LINK_INVALID: 'Invalid Video Link',
    TXT_TOOL_LINK_INVALID: 'Invalid Tool Link',
    TXT_TOOL_IMAGE_REQUIRED: 'Upload Image is required',
    TXT_ADD_TOOL: 'Add Tool',
    TXT_EDIT_TOOL: 'Save Tool',
    TXT_DELETE_TOOL: 'Delete Tool',
    TXT_TOOL_DESCRIPTION_INVALID: 'Maximum 70 words are allowed.',
    TXT_TOOL_NAME_INVALID: 'Maximum 30 characters are allowed.',
    TXT_WATCH_VIDEO: 'WATCH VIDEO',
    TXT_DELIVER_EDIT_DROPZONE_TEXT: 'Re-Upload Brochure file to change Brochure associated with tool',
    TXT_UNDER_VIDEO_LINK_LABEL:
        'By clicking on ‘Upload’ button, you will be taken to a third - party video platform. Paste the link created, in the following text box.',
    TXT_TOP_RIGHT_IMAGE: 'Top Right',
    TXT_LEFT_IMAGE: 'Left',
    TXT_BOTTOM_RIGHT_IMAGE: 'Bottom Right',
    TXT_DOWNLOAD_BROCHURE: 'DOWNLOAD BROCHURE',
    TXT_GO_TO_TOOL: 'GO TO TOOL',
    TXT_UPDATE_ADMIN_AND_USERS: 'Update Admin & Users',
    TXT_BLOCK_USER: 'Block',
    TXT_UNBLOCK_USER: 'Unblock',
    TXT_EDIT_CHECK: 'edit',
    TXT_UPDATE_PROFILE_CHECK: 'profile',
    TXT_ENTER: 'Enter',
    TXT_TOTAL_DOWNLOADS: 'TOTAL DOWNLOADS',
    TXT_SET_DOWNLOAD_LIMIT: 'Set download limit',
    TXT_SET_WEEKLY_DOWNLOAD_LIMIT: 'Set Weekly Download Limit',
    TXT_CST: 'CST User',
    TXT_CST_TYPE: 'CST',
    TXT_EXTERNAL_TYPE: 'External_Client',
    TXT_LOGIN_USER: 'Login User',
    TXT_MM_CORE: 'MM Expert',
    TXT_SAVE: 'SAVE',
    TXT_CANCEL_ADMIN: 'Cancel',
    TXT_DOWNLOAD_COUNT: 'DOWNLOAD COUNT',
    TXT_UPPER_DOWNLOAD_LIMIT: '0-999',
    TXT_REQUIRED_DOWNLOAD_VALUE: "Value(s) can't be blank",
    TXT_SELECTALL: 'Select All',
    TXT_EDIT_PROFILE: 'Edit Profile',
    TXT_YOUR_PROFILE: 'Your Profile',
    TXT_SESSION_TIMEOUT_WARNING: `You will be logged out automatically in ${
        SESSION_TIMEOUT_TIME - WARNING_TIME // TXT_DOCUMENT_DOWNLOAD_SUCCESS: `Document downloaded successfully`,
    } minute(s)`,
    TXT_YEARS: 'Years',
    TXT_YEAR: 'Year',
    TXT_LOP_GETTING_STARTED: 'Getting Started',
    TXT_LOP_GOLD_STANDARD: 'Gold Standard LOP Materials',
    TXT_LOP_CASE_STUDIES: 'Case Studies',
    TXT_LOP_CLIENT_REFERENCES: 'Client References',
    TXT_LOP_CLIENT_REFERENCES_ADD: 'Add Client References',
    TXT_LOP_CLIENT_REFERENCES_EDIT: 'Edit Client References',
    TXT_LOP_EXPERT_CVS: 'Expert CVs',
    TXT_LOP_DELIVERY: 'Delivery',
    TXT_LOP_ARTICLES: 'Articles',
    TXT_ADD_NEW_FILE: 'Add File',
    TXT_EDIT_FILE: 'Edit File',
    TXT_ADD_NEW_FILES: 'Add New File(s)',
    TXT_ADD_NEW_FOLDER: 'Add Folder',
    TXT_ADD_VIDEO: 'Add Video',
    TXT_EDIT_VIDEO: 'Edit Video',
    TXT_VIDEO_INFO:
        '* Only videos which are uploaded on internal McKinsey network should be embedded here e.g. BrightCove, YouTube etc',
    TXT_EDIT_FOLDER: 'Edit Folder',
    TXT_FILENAME: 'FILE NAME',
    TXT_ADD_ANOTHER_FILE: 'Add Another File',
    TXT_ADD_FILES_TO_FOLDER: 'Add File to Folder',
    TXT_SELECT_FOLDER: 'Select Folder',
    TXT_FOLDER_NAME: 'Folder Name',
    TXT_REPLACE_FILE: 'Replace File',
    TXT_REMOVE_FILE: 'Remove File',
    TXT_SEARCHED_FOLDER: 'This folder contains searched documents.',
    TXT_EMAIL_ADDRESS_HERE: 'Enter email address here...',
    TXT_ADD_NEW_CASE_STUDY: 'Add New Case Study',
    TXT_EDIT_CASE_STUDY: 'Edit Case Study',
    TXT_COMPANY_NAME: 'Company Name',
    TXT_GEOGRAPHY: 'Geography',
    TXT_DEAL_TYPE: 'Deal Type',
    TXT_DEAL_SIZE_Bn: 'Deal Size ($Bn)',
    TXT_DEAL_SIZE: 'Deal Size',
    TXT_TOPIC_KEYWORDS: 'Topic & Keywords',
    TXT_CONTACT: 'Contact',
    TXT_CONTACT_PRIMARY: 'Primary Contact',
    TXT_CONTACT_SECONDARY: 'Secondary Contact',
    TXT_TOTAL_CASE_STUDIES: 'Total Case Studies:',
    TXT_NO_CASE_STUDIES_AVAILABLE: 'No Case Studies Available',
    TXT_NO_CLIENT_REFERENCES_AVAILABLE: 'No Client References Available',
    TXT_NO_EXPERT_CVS_AVAILABLE: 'No Expert CVs Available',
    TXT_ADD_INDUSTRY_PLACEHOLDER: 'Add Industry here...',
    TXT_ADD_TOPICKEYWORDS_PLACEHOLDER: 'Add Topic/Keywords here...',
    TXT_ADD_COMPANTNAME_PLACEHOLDER: 'Add Company Name here...',
    TXT_MAX_TAGS_ALLOWED_ROLE_INDUSTRY_CLIENT_REFRENCES: `Maximum Number of Tags allowed should not be greater than ${MAX_TAGS_ALLOWED_LOP_INDUSTRY_ROLE_CLIENT_REFERENCE}`,
    TXT_MAX_TAGS_ALLOWED_OFFICE: `Only one office can be selected here.`,
    TXT_MAX_TAGS_ALLOWED_LOP_INDUSTRY: `Maximum Number of Industry Tags allowed should not be greater than ${MAX_TAGS_ALLOWED_LOP_INDUSTRY}`,
    TXT_MAX_TAGS_ALLOWED_LOP_EXPERT_INDUSTRY: `Maximum Number of Industry Tags allowed should not be greater than ${MAX_TAGS_ALLOWED_LOP_INDUSTRY_TOPIC}`,

    TXT_MAX_TAGS_ALLOWED_LOP_ROLE: `Maximum Number of Role Tags allowed should not be greater than ${MAX_TAGS_ALLOWED_LOP_ROLE}`,

    TXT_MAX_TAGS_ALLOWED_LOP_TOPICS: `Maximum Number of Topics Tags allowed should not be greater than ${MAX_TAGS_ALLOWED_LOP_INDUSTRY_TOPIC}`,

    TXT_MAX_TAGS_ALLOWED_LOP_KEYWORDS: `Maximum Number of Topic & Keywords Tags allowed should not be greater than ${MAX_TAGS_ALLOWED_LOP_KEYWORDS}`,
    TXT_SELECT_GEOGRAPHY: 'Select Geography',
    TXT_SELECT_YEAR: 'Select Year',
    TXT_SELECT_DEALTYPE: 'Select Deal Type',
    TXT_SELECT_DEALSIZE: 'Select Deal Size',
    TXT_SELECT_REGION: 'Select Region',
    TXT_SELECT_IMAGE_LIB: 'Select Image from Library',
    TXT_DATE_MESSAGE: '*Future dates are not allowed.',
    TXT_TOTAL_CVS: 'Total CVs',
    TXT_MM: 'MM',
    TXT_YYYY: 'YYYY',
    TXT_MIN: 'MIN',
    TXT_MAX: 'MAX',
    TXT_ADD_NEW_CV: 'Add New CV',
    TXT_EDIT_CV: 'Edit CV',
    TXT_EXPERT_NAME: 'Name of the Expert',
    TXT_ADD_ROLE_PLACEHOLDER: 'Add Role here...',
    TXT_ADD_TOPIC_PLACEHOLDER: 'Add Topic here...',
    TXT_CST_CONTACT: 'CST Contact',
    TXT_MM_CONTACT: 'MM Contact',
    TXT_NOTE: 'Notes',
    TXT_SHOW_MORE: 'Show More',
    TXT_SHOW_LESS: 'Show Less',
    TXT_READ_MORE: 'Read More',
    TXT_COPIED: 'Copied to clipboard',
    TXT_EXPERT_ROLE: 'Role',
    TXT_YEAR_RANGE: 'From - To',
    TXT_FROM: 'From:',
    TXT_TO: 'To:',
    TXT_DEALSIZE_RANGE: 'Low - High',
    TXT_LOWER_VALUE: 'Lower Value',
    TXT_HIGHER_VALUE: 'Higher Value',
    TXT_INVALID_INPUT: 'Invalid input!',
    TXT_CST_CONTACT_PLACEHOLDER: 'Add CST Contact Name',
    TXT_MM_CONTACT_PLACEHOLDER: 'Add MM Contact Name',
    TXT_ADD_NEW_CLIENT_REFERENCE: 'Add New Client Reference',
    TXT_NOTES: 'Notes',
    TXT_PHONE_NUMBER: 'Phone Number',
    TXT_CONTACT_NAME: 'Contact Name',
    TXT_CLIENT_REFERENCES: 'Client References',
    TXT_NOTES_DESCRIPTION_INVALID: 'Maximum 1000 words are allowed.',
    TXT_SESSION_MESSAGE: 'Your session has expired. Please login again.',
    TXT_VIDEO_DOWNLOAD: 'Video cannot be Downloaded, Please Un-check the video file in order to proceed',
    TXT_VIDEO_EMAIL: 'Video cannot be Emailed, Please Un-check the video file in order to proceed',
    TXT_COMPANY_LOGO: ['Company A Logo', 'Company B Logo', 'Company C Logo'],
    TXT_ADD_LOGO: 'ADD ANOTHER LOGO',
    TXT_ADD_PARTNER: 'Add Person',
    TXT_EDIT_PARTNER: 'Edit Person',
    TXT_ADD_PARTNER_PLACEHOLDER_1: 'Partner Name',
    TXT_ADD_PARTNER_PLACEHOLDER_2: 'Title/Designation',
    TXT_IMAGE: 'Image',
    TXT_SIGNATURE: 'Signature',
    TXT_PARTNER_UPLOAD: 'Click to upload or',
    TXT_PARTNER_UPLOAD_BROWSE: 'Browse',
    TXT_PARTNER_DETAILS: 'Details',
    TXT_PARTNER_DELETE_MESSAGE: 'Selected person would get deleted. Are you sure you want to delete it?',
    TXT_BLOCK_DELETE_MESSAGE: 'This block will be deleted. Are you sure you want to delete it?',
    TXT_ARTICLE_DELETE_MESSAGE: 'Selected Article will be deleted. Are you sure you want to delete it?',
    TXT_CUSTOM_CONTENT_MESSAGE: 'Selected Element would get deleted. Are you sure you want to delete it?',
    TXT_SECTION_DELETE_MESSAGE: 'Selected Section would get deleted. Are you sure you want to delete it?',
    TXT_VIDEO_DELETE_MESSAGE: 'Selected Video would get deleted. Are you sure you want to delete it?',
    TXT_LOP_DELETE_MESSAGE: 'The current LOP would get deleted. Are you sure you want to delete it?',
    TXT_CST_MAIL_MESSAGE: 'Selected emailId would get deleted. Are you sure you want to delete it?',
    TXT_SHARED_DOC_DELETE_MESSAGE: 'Are you sure you want to delete the selected document?',
    TXT_OUR_TEAM: 'Our Team',
    TXT_ADD_TEAM: 'Add Team Member',
    TXT_ADD_HEADING: 'Add New Heading',
    TXT_EDIT_TEAM: 'Edit Team Member',
    TXT_DESCRIPTION: 'Description',
    TXT_ADD_ELE: 'Add Element',
    TXT_LAYOUT: 'Choose Layout',
    TXT_ENTER_LINK: 'Enter the Video Link',
    TXT_HEADER_PLACEHOLDER: 'Heading',
    TXT_SUB_PLACEHOLDER: 'Sub Line to explain the heading',
    TXT_UPLOAD_IMAGES: 'Upload Images',
    TXT_IMAGES_MANDATORY: 'Please select images for all the slots in the images sections',
    TXT_DOC_MANDATORY:
        'Please upload an Image for Doc Element (We suggest uploading a screen shot of the cover page of your Doc)',
    TXT_IMAGES_MIN_MANDATORY: 'Please select at least 2 images',
    TXT_LOP_ARTICLES_ADD: 'Add Additional Material',
    TXT_LOP_ARTICLES_EDIT: 'Edit Additional Material',
    TXT_LOP_EXTEND_HEADER: 'Confirm LOP Extension',
    TXT_CST_DELETE_HEADER: 'Confirm CST action',
    TXT_VID_DELETE_HEADER: 'Confirm video delete',
    TXT_LOP_EXTEND_MSG: 'Do you wish to extend locking on this LOP?',
    TXT_CST_BLOCK_MSG: 'Do you wish to block this/these selected CST user(s)?',
    TXT_VID_DEL_MSG: 'Do you wish to delete this video ?',
    TXT_CST_UNBLOCK_MSG: 'Do you wish to unblock this/these selected CST user(s)?',
    LOP_IS_LOCKED: 'LOP_IS_LOCKED',
    LOP_IS_DELETED: 'Current LOP has been deleted',
    LOP_OWNER: 'Playbook Owners',
    LOP_EXPERT: 'Other Experts/ Knowledge Team',
    LOP_ADD_PLAYBOOK_OWNER: 'Add Playbook Owner',
    LOP_EDIT_PLAYBOOK_OWNER: 'Edit Playbook Owner',
    LOP_ADD_PLAYBOOK_EXPERT: 'Add Playbook Expert',
    LOP_EDIT_PLAYBOOK_EXPERT: 'Edit Playbook Expert',
    EXTERNAL_USER_EXPIRED: 'You can not access this LOP, as your validity period has been expired.',
    CALENDAR_IMG:
        'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDMyIDMyIiBoZWlnaHQ9IjMycHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjMycHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxnIGlkPSJjYWxlbmRhcl8xXyI+PHBhdGggZD0iTTI5LjMzNCwzSDI1VjFjMC0wLjU1My0wLjQ0Ny0xLTEtMXMtMSwwLjQ0Ny0xLDF2MmgtNlYxYzAtMC41NTMtMC40NDgtMS0xLTFzLTEsMC40NDctMSwxdjJIOVYxICAgYzAtMC41NTMtMC40NDgtMS0xLTFTNywwLjQ0Nyw3LDF2MkgyLjY2N0MxLjE5NCwzLDAsNC4xOTMsMCw1LjY2NnYyMy42NjdDMCwzMC44MDYsMS4xOTQsMzIsMi42NjcsMzJoMjYuNjY3ICAgQzMwLjgwNywzMiwzMiwzMC44MDYsMzIsMjkuMzMzVjUuNjY2QzMyLDQuMTkzLDMwLjgwNywzLDI5LjMzNCwzeiBNMzAsMjkuMzMzQzMwLDI5LjcwMSwyOS43MDEsMzAsMjkuMzM0LDMwSDIuNjY3ICAgQzIuMjk5LDMwLDIsMjkuNzAxLDIsMjkuMzMzVjUuNjY2QzIsNS4yOTksMi4yOTksNSwyLjY2Nyw1SDd2MmMwLDAuNTUzLDAuNDQ4LDEsMSwxczEtMC40NDcsMS0xVjVoNnYyYzAsMC41NTMsMC40NDgsMSwxLDEgICBzMS0wLjQ0NywxLTFWNWg2djJjMCwwLjU1MywwLjQ0NywxLDEsMXMxLTAuNDQ3LDEtMVY1aDQuMzM0QzI5LjcwMSw1LDMwLDUuMjk5LDMwLDUuNjY2VjI5LjMzM3oiIGZpbGw9IiMzMzMzMzIiLz48cmVjdCBmaWxsPSIjMzMzMzMyIiBoZWlnaHQ9IjMiIHdpZHRoPSI0IiB4PSI3IiB5PSIxMiIvPjxyZWN0IGZpbGw9IiMzMzMzMzIiIGhlaWdodD0iMyIgd2lkdGg9IjQiIHg9IjciIHk9IjE3Ii8+PHJlY3QgZmlsbD0iIzMzMzMzMiIgaGVpZ2h0PSIzIiB3aWR0aD0iNCIgeD0iNyIgeT0iMjIiLz48cmVjdCBmaWxsPSIjMzMzMzMyIiBoZWlnaHQ9IjMiIHdpZHRoPSI0IiB4PSIxNCIgeT0iMjIiLz48cmVjdCBmaWxsPSIjMzMzMzMyIiBoZWlnaHQ9IjMiIHdpZHRoPSI0IiB4PSIxNCIgeT0iMTciLz48cmVjdCBmaWxsPSIjMzMzMzMyIiBoZWlnaHQ9IjMiIHdpZHRoPSI0IiB4PSIxNCIgeT0iMTIiLz48cmVjdCBmaWxsPSIjMzMzMzMyIiBoZWlnaHQ9IjMiIHdpZHRoPSI0IiB4PSIyMSIgeT0iMjIiLz48cmVjdCBmaWxsPSIjMzMzMzMyIiBoZWlnaHQ9IjMiIHdpZHRoPSI0IiB4PSIyMSIgeT0iMTciLz48cmVjdCBmaWxsPSIjMzMzMzMyIiBoZWlnaHQ9IjMiIHdpZHRoPSI0IiB4PSIyMSIgeT0iMTIiLz48L2c+PC9zdmc+',
    CST_TEXT: 'My CSTs',
    ADD_CST: 'Add New CST',
    EDIT_CST: 'EDIT CST',
    CST_NAME: 'Name of the CST',
    CST_NAME_PLACEHOLDER: 'Enter name of CST',
    CHARGE_CODE: 'Charge Code',
    CHARGE_CODE_PLACEHOLDER: 'Enter Charge Code',
    CST_ACKNOWLEGMENT: 'I acknowledge the CST will be charged a fee for the access to the MM Digital Hub',
    CST_DURATION: 'Duration of Access',
    CST_CONTACT: 'MM Core Member Contact',
    CST_CONTACT_PLACEHOLDER: 'Enter MM Core Member Contact',
    CST_ACCESS: 'CST users of access',
    CST_ADD_MAIL: 'Add email id',
    GET_IN_TOUCH: 'Get in touch: Please contact us at',
    CONTACT_MAIL: 'lorem_ipsum@mckinsey.com',
    MMCST_NAME: 'Name of the CST',
    MMCST_STATUS: 'STATUS',
    MMCST_CONTACT: 'MM Core Contact',
    MMCST_CHARGE_CODE: 'Charge Code',
    MMCST_DURATION: 'Duration of Access',
    TXT_PLAYBOOK_ADD: 'ADD/EDIT EXPERT',
    TXT_PLAYBOOK_DONE: 'DONE',
    TXT_PLAYBOOK_OWNER1: 'Add Owner',
    TXT_PLAYBOOK_OWNER2: 'Add another Owner',
    TXT_PLAYBOOK_OTHER1: 'Add Expert',
    TXT_PLAYBOOK_OTHER2: 'Add Another Expert',
    TXT_ACTIVE_CST: 'Active CST Accounts',
    TXT_NEW_CST: 'New CST access requests',
    TXT_EXPIRED_CST: 'Expired CSTs',
    TXT_MM_ADMIN_CONTACT: 'MM CONTACT',
    TXT_CHARGE_CODE: 'CHARGE CODE',
    TXT_VIEW_MORE: 'View More',
    TXT_VIEW_LESS: 'View less',
    TXT_ACTIVE_CST_ADMIN: 'My Active CSTs',
    TXT_EXPIRED_CST_ADMIN: 'Expired CSTs',
    TXT_NEW_CST_ADMIN: 'New CST access requests',
    TXT_SAVE_ADMIN: 'Save',
    TXT_DENY_BTN: 'Deny Selected',
    TXT_CREATE_USER_BTN: 'Create Selected Users',
    TXT_MM_CONTECT_ADMIN: 'MM Core member contact ',
    TXT_CUSTOM_LINK_TILE: 'Link Title',
    TXT_CUSTOM_CONTENT: 'Content',
    TXT_COPY_TOOLTIP:
        'Generate hyperlink (enables you to link to this section of the microsite from elsewhere in the microsite)',
    TXT_EMAIL_LIST: 'Please enter at least one email address',
    TXT_MONTH_SELECT: 'Please select month.',
    TXT_YEAR_SELECT: 'Please select year.',
    TXT_ACKNOWLEDGE: 'Please acknowledge the statement before proceeding forward.',
    TXT_ADD_COLLABORATOR: 'Add collaborators for this LoP',
    TXT_ACCESS_COLLABORATOR: 'Manage access',
    TXT_COLLABORATOR_OWNER: 'Owner',
    TXT_EXTERNAL_CLIENT: 'External viewer',
    TXT_NUMBER_OF_ACTIVE_USERS: 'Active Users per week',
    COPY_PAGE: 'Copy Page',
    COPY_PAGE_TEXT: 'Copy this section and place it in an other LOP',
    SELECT_LOP: 'Select LOP',
    VIEW_DELETED: 'View Deleted',
    DELETED_MESSAGE: ' There are no deleted sections',
    VIEW_DELETED_SUB_TEXT: 'Please select the sections which you would like to restore or delete permanently',
    DELETE_PERMANENT: 'Delete Permanently',
    RESTORE_PERMANENT: 'Restore Section',
    TEXT_STATISTICS: 'STATISTICS',
    TXT_VIDEO_ADD_INST: 'Insert a video URL below to upload, You can add upto 30 videos.',
    SHARE_DOCS: 'SHARE',
    ACTION_DOCS_LIBRARY: 'ACTION',
    SHARE_CANCEL: 'Cancel',
}
