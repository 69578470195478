import React, { Component } from 'react'
import Modal from 'react-responsive-modal'
import ReactDropzone from 'react-dropzone'
import {
    SITE_TXT,
    DOCUMENT_NAME_PATTERN,
    STATUS_SUCCESS,
    MAX_NAME_LENGTH,
    IMAGE_CROP_OBJ_RECT,
} from '../../Utils/constants'
import Inputbox from '../Inputbox'
import { UploadIcon } from '../../Utils/svg'
import { getPrefixedUrl } from '../../Utils/apis'
import { AddPartnerProfile } from '../../Utils/svg'
import { isValidPattern, getFileExtType, isValidFile } from '../../Utils/functions'
import Dropdown from '../Dropdown'
import Checkbox from '../Checkbox'
import ThumbnailEditorModal from '../ThumbnailEditorModal'
import { LOP_GOLD_STANDARD, LOP_GETTING_STARTED, LOP_DELIVERY, LOP_ARTICLES } from '../../Containers/Lop/constant'

class FileModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: this.props.open,
            docFile: this.props.docFile,
            fileName: this.props.fileName,
            name: this.props.name || '',
            imageUrl: this.props.imageUrl || '',
            base64path: null,
            imageSrc: null,
            fileBrowsed: false,
            isEditMode: this.props.isEditMode,
            requestType: this.props.requestType,
            docId: this.props.docId,
            addEditStatus: this.props.addEditStatus,
            addEdit: false,
            isModalOpen: false,
            onLopDeleteSuccess: false,
            checked: false,
            folderId: this.props.folderId,
            foldersList: this.props.foldersList,
            acceptedFileType:
                this.props.requestType === 'articles'
                    ? SITE_TXT.PDF_FILE_TYPE_ACCEPTED
                    : this.props.acceptedFileType || SITE_TXT.TXT_FILE_TYPE_ACCEPTED,
        }
    }

    UNSAFE_componentWillReceiveProps = nextProps => {
        const { open, isEditMode, docId, addEditStatus, foldersList, onLopDeleteSuccess } = nextProps
        this.setState({
            open,
            isEditMode,
            docId,
            addEditStatus,
            foldersList,
            onLopDeleteSuccess,
        })
        if (this.state.onLopDeleteSuccess) {
            this.setState({ onLopDeleteSuccess: false }, () => {
                this.resetModal()
            })
        }

        if (addEditStatus && addEditStatus.status === STATUS_SUCCESS && this.state.addEdit === true) {
            this.setState(
                {
                    addEdit: false,
                },
                () => {
                    this.resetModal()
                }
            )
        }

        if (this.state.name === '') {
            this.setState({ name: nextProps.name })
        }
    }

    handleChange = (field, value) => {
        this.setState({ [field]: value })
    }

    handleDrop = files => {
        if (this.state.fileBrowsed) {
            if (!isValidFile(files[0].name, this.state.acceptedFileType)) {
                alert(SITE_TXT.TXT_INVALID_FILE_EXT)
                return
            }
            this.setState({ docFile: files[0], fileName: files[0].name })
        } else {
            if (files.length === 0) {
                alert(SITE_TXT.TXT_INVALID_FILE_EXT)
                return
            }
            this.setState({
                docFile: files[0],
                fileName: files[0].name,
                fileBrowsed: true,
            })
        }
    }

    isFormValid = () => {
        const { name } = this.state
        if (!isValidPattern(name, DOCUMENT_NAME_PATTERN)) {
            alert(SITE_TXT.TXT_DOCNAME_VALID_PATTERN)
            return false
        }
        if (name.trim().length > MAX_NAME_LENGTH) {
            alert(SITE_TXT.TXT_NAME_MAX_LENGTH)
            return false
        }

        return true
    }

    createFormData = () => {
        let { name, docFile, docId, folderId, checked, imageSrc, requestType, isEditMode } = this.state
        let data = new FormData()
        data.append('name', name)
        data.append('docFile', docFile)
        data.append('requestType', requestType)
        if (requestType === LOP_GOLD_STANDARD && !isEditMode) {
            data.append('parentId', checked && folderId ? folderId : '0')
        } else {
            data.append('folderId', '-1')
        }
        if (isEditMode) {
            data.append('id', docId)
        }
        if (imageSrc) {
            data.append('image', imageSrc)
        }
        return data
    }

    handleSave = e => {
        e.preventDefault()
        if (!this.isFormValid()) {
            return
        }
        this.setState({ isLoading: true, addEdit: true }, () => {
            const validData = this.createFormData()
            if (this.state.isEditMode) {
                this.props.lopEditItem(validData, this.state)
            } else {
                this.props.lopAddItem(validData, this.state)
            }
        })
    }

    resetModal = () => {
        this.setState(
            {
                docFile: null,
                name: '',
                fileBrowsed: false,
                isEditMode: false,
            },
            () => {
                this.props.onCloseModal()
            }
        )
    }

    replaceFile = () => {
        this.browseInp.click()
    }

    handleDelete = () => {
        if (
            this.state.requestType === LOP_GETTING_STARTED ||
            this.state.requestType === LOP_DELIVERY ||
            this.state.requestType === LOP_ARTICLES
        ) {
            this.props.onLopDelete(this.state.docId)
        } else if (this.state.requestType === LOP_GOLD_STANDARD) {
            this.props.onGoldDelete(this.state.docId)
        }
    }

    toggleCheckbox = () => {
        this.setState({
            checked: !this.state.checked,
        })
    }
    getSrcFunction = ({ result }, imageSrc) => {
        this.setState({
            imageSrc,
            base64path: result,
        })
    }

    getBase64 = file => {
        let reader = new FileReader()
        reader.addEventListener(
            'load',
            () => {
                this.getSrcFunction(reader, file)
            },
            false
        )
        if (file) {
            reader.readAsDataURL(file)
        }
        this.closeUploadModal()
    }

    openUploadModal = () => this.setState({ isModalOpen: true })

    closeUploadModal = () => this.setState({ isModalOpen: false })

    render = () => {
        const {
            open,
            name,
            docFile,
            fileBrowsed,
            isEditMode,
            folderId,
            foldersList,
            requestType,
            imageUrl,
            base64path,
            isModalOpen,
            fileName,
        } = this.state
        const {
            TXT_ADD_NEW_FILE,
            TXT_SAVE,
            TXT_DELETE,
            TXT_EDIT_FILE,
            TXT_ADD_FILES_TO_FOLDER,
            TXT_SELECT_FOLDER,
        } = SITE_TXT

        const { showDelete, imageMandatory } = this.props
        const imageToResize = base64path ? base64path : imageUrl ? getPrefixedUrl(imageUrl) : null

        return (
            open && (
                <Modal
                    open={open}
                    center
                    closeOnOverlayClick={false}
                    classNames={{
                        modal: 'comp-modal lop-modal',
                        overlay: 'lop-modal-overlay overlaySmall',
                        closeButton: 'close-btn-modal',
                    }}
                    onClose={() => this.resetModal()}
                >
                    <form id="addFileLop" className="form-horizontal" encType="multipart/form-data" method="post">
                        {isEditMode ? (
                            <span className="modal-heading">{TXT_EDIT_FILE}</span>
                        ) : (
                            <span className="modal-heading">{TXT_ADD_NEW_FILE}</span>
                        )}
                        <div className="file-detail">
                            {showDelete && (
                                <Inputbox
                                    label={SITE_TXT.TXT_DOCNAME_LOP}
                                    placeholder={SITE_TXT.TXT_DOCNAME_PLACEHOLDER}
                                    type="text"
                                    fieldFor={'name'}
                                    onChange={this.handleChange}
                                    autoFocus={true}
                                    value={name}
                                />
                            )}
                            {fileBrowsed || fileName ? (
                                <div className="file-info-container">
                                    <input
                                        type="file"
                                        accept={this.state.acceptedFileType}
                                        ref={input => (this.browseInp = input)}
                                        onChange={e => this.handleDrop(e.target.files)}
                                        className="browse-lop-inp"
                                    />
                                    <div className="file-info">
                                        <span className={'txt-upper extBox ' + getFileExtType(fileName)}>
                                            {getFileExtType(fileName)}
                                        </span>
                                        <span className="file-name">{fileName}</span>
                                    </div>
                                    <div className="action-icon">
                                        <span className="txt-blue" onClick={() => this.replaceFile()}>
                                            {SITE_TXT.TXT_REPLACE_FILE}
                                        </span>
                                    </div>
                                </div>
                            ) : (
                                <ReactDropzone
                                    onDrop={this.handleDrop}
                                    className="dropzone"
                                    accept={this.state.acceptedFileType}
                                    ref={node => {
                                        this.dropzoneRef = node
                                    }}
                                >
                                    {docFile ? (
                                        <span>{docFile.name}</span>
                                    ) : (
                                        <span>
                                            <UploadIcon width="20" height="20" />
                                            {SITE_TXT.TXT_DROPZONE + ' '}
                                            <span className="txt-blue action-icon">{SITE_TXT.TXT_BROWSE}</span>
                                        </span>
                                    )}
                                </ReactDropzone>
                            )}

                            <hr />
                        </div>
                        <span className="allowedExt">
                            {SITE_TXT.TXT_ALLOWED_FILES_TEXT}
                            <br />
                            <b>{this.state.acceptedFileType}</b>
                        </span>
                        {requestType === LOP_ARTICLES && (
                            <div className="profile-img">
                                <label htmlFor="profile">{SITE_TXT.TXT_IMAGE}</label>
                                <div
                                    className={!imageUrl && !base64path ? 'uploadProfile' : 'uploadProfile with-image'}
                                >
                                    {(base64path || imageUrl) && (
                                        <img
                                            alt="img"
                                            src={base64path || getPrefixedUrl(imageUrl)}
                                            className="profile-image"
                                        />
                                    )}
                                    <div className="browse-icon" onClick={this.openUploadModal}>
                                        <AddPartnerProfile />
                                    </div>
                                </div>
                            </div>
                        )}
                        {!isEditMode && this.state.requestType === LOP_GOLD_STANDARD && (
                            <div>
                                <div className="checkbox-wrapper">
                                    <span onClick={() => this.toggleCheckbox()}>
                                        <Checkbox checked={this.state.checked} />
                                    </span>
                                    <label>{TXT_ADD_FILES_TO_FOLDER}</label>
                                </div>

                                <Dropdown
                                    disabled={!this.state.checked}
                                    defaultVal={TXT_SELECT_FOLDER}
                                    fieldFor="folderId"
                                    value={folderId ? folderId : 0}
                                    onChange={this.handleChange}
                                    selectedMenu={folderId}
                                    options={foldersList}
                                    customClass="select-validation"
                                />
                            </div>
                        )}
                        <div className="btn-section text-center">
                            {isEditMode && showDelete && (
                                <span
                                    className="btn btn-danger btn-lg"
                                    disabled={name === '' || docFile === null}
                                    onClick={() => this.handleDelete()}
                                >
                                    {TXT_DELETE}
                                </span>
                            )}
                            <button
                                disabled={
                                    this.state.isEditMode
                                        ? name.trim() === '' || (!showDelete && !docFile && !base64path)
                                        : name.trim() === '' || !docFile || (imageMandatory && !imageToResize)
                                }
                                className="btn btn-primary btn-lg"
                                onClick={e => this.handleSave(e)}
                            >
                                {TXT_SAVE}
                            </button>
                        </div>
                    </form>

                    {isModalOpen && (
                        <ThumbnailEditorModal
                            isAdminPage
                            isModalOpen
                            onlyImage
                            thumbnailImg={imageToResize}
                            userImg1Src={imageToResize}
                            editCancel={this.closeUploadModal}
                            handleSave={(a, b, img) => this.getBase64(img)}
                            imgCropObj={IMAGE_CROP_OBJ_RECT}
                        />
                    )}
                </Modal>
            )
        )
    }
}

FileModal.defaultProps = {
    docId: null,
    addEditStatus: false,
    onLopDeleteSuccess: false,
    showDelete: true,
    onLopDelete: () => {},
}

export default FileModal
