import React, { Component } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import { contactWinRoom } from '../../Containers/LopContent/actions'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { mailSentStatus } from '../../Containers/LopContent/selector'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import PropTypes from 'prop-types'
import c from 'classnames'
import './index.scss'
import Inputbox from '../Inputbox'
import { CloseEditLop } from '../../Utils/svg'
import { EMAIL_PATTERN } from '../../Utils/constants'

class ContactWinRoom extends Component {
    constructor(props) {
        super(props)
        const lopName = JSON.parse(localStorage.getItem('myLopData')).name || ''
        const userName = JSON.parse(localStorage.getItem('userInfo')).name || ''
        const html = `<p><b>LOP Name</b>: ${lopName}\nClient: \nDeadline:\n<b>User Name</b>: ${userName} \n\n[Contact the M&A Win Room for additional assistance with your competitive M&A LoP. The Win Room 1) connect CSTs with experts, content, and tools in an integrated and simple way, and 2) provides hands-on support for discrete sections of priority competitive proposals. When you get in touch, please indicate client name, CST leadership, M&A experts involved, deadline, and competitors if known.]</p>`
        const contentBlock = htmlToDraft(html)
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
        const editorState = EditorState.createWithContent(contentState)
        this.state = {
            editorState,
            itemsFrom: ['MM_Helpdesk@mckinsey.com'],
            itemsTo: ['MA_Win_Room@mckinsey.com'],
            valueFrom: '',
            valueTo: '',
            subject: 'Request for assistance with M&A proposal (via MM Digital Home – LOP microsite)',
            body: '',
            error: null,
            animate: false,
            contentState: {},
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.mailSentStatus) {
            this.props.onCloseModal()
        }
    }

    onEditorStateChange = editorState => {
        this.setState({
            editorState,
        })
    }

    onKeyPress = (e, value) => {
        if (['Enter', 'Tab', ',', 'Spacebar'].includes(e.key)) {
            e.preventDefault()
            const valueNew = value.trim()
            if (valueNew && this.isValid(valueNew)) {
                this.setState({
                    itemsTo: [...this.state.itemsTo, valueNew],
                    valueTo: '',
                })
            }
        }
    }

    handleChange = e => {
        this.setState({
            valueTo: e.target.value,
        })
    }
    handleSubjectChange = (field, value) => {
        this.setState({
            [field]: value,
        })
    }

    handleDelete = item => {
        this.setState({
            itemsTo: this.state.itemsTo.filter(i => i !== item),
        })
    }

    handlePaste = e => {
        e.preventDefault()
        var paste = e.clipboardData.getData('text')
        var emails = paste.match(EMAIL_PATTERN)
        if (emails) {
            var toBeAdded = emails.filter(email => !this.isInList(email))
            this.setState({
                itemsTo: [...this.state.itemsTo, ...toBeAdded],
            })
        }
    }

    isValid(email) {
        let error = null
        if (this.isInList(email)) {
            error = `${email} has already been added.`
        }
        if (!this.isEmail(email)) {
            error = `${email} is not a valid email address.`
        }
        if (error) {
            this.setState({ error })
            return false
        }
        return true
    }

    isInList(email) {
        return this.state.itemsTo.includes(email)
    }

    isEmail(email) {
        return EMAIL_PATTERN.test(email)
    }

    isAnimate = () => {
        const { contactWinRoomFlag } = this.props
        clearTimeout(this.delayTimer)
        if (contactWinRoomFlag) {
            this.delayTimer = setTimeout(() => {
                this.setState({
                    animate: true,
                })
            }, 100)
        }
        return null
    }

    onCloseWinRoom = () => {
        this.setState(
            {
                animate: false,
            },
            () => {
                clearTimeout(this.delayTimer)
                this.delayTimer = setTimeout(() => {
                    this.props.onCloseModal()
                }, 200)
            }
        )
    }

    handleSave = e => {
        e.preventDefault()
        const { contactWinRoom } = this.props
        const data = {
            recipient: this.state.itemsTo.length > 0 ? this.state.itemsTo[0] : this.state.valueTo,
            subject: this.state.subject,
            body: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())),
        }
        setTimeout(() => {
            contactWinRoom(data)
        }, 500)
    }

    render() {
        const { editorState, animate, subject, valueTo } = this.state
        this.isAnimate()
        return (
            <div
                className={c('win-room-container win_room_container_animatable', {
                    win_room_container_visible: animate,
                })}
            >
                <div className="cancel-btn" onClick={this.onCloseWinRoom}>
                    <CloseEditLop />
                </div>
                <div className="email-input">
                    <div className="form-group">
                        <label>From </label>
                        <div className="input-box">
                            {this.state.itemsFrom.map(item => (
                                <div className="tag-item" key={item}>
                                    {item}
                                    <button type="button" className="button">
                                        &times;
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="form-group">
                        <label>To</label>
                        <div className="input-box">
                            {this.state.itemsTo.map(item => (
                                <div className="tag-item" key={item}>
                                    {item}
                                    <button type="button" className="button" onClick={() => this.handleDelete(item)}>
                                        &times;
                                    </button>
                                </div>
                            ))}
                            {this.state.itemsTo.length === 0 && (
                                <input
                                    className="form-control"
                                    type="text"
                                    onKeyPress={e => this.onKeyPress(e, valueTo)}
                                    onChange={this.handleChange}
                                    onPaste={this.handlePaste}
                                    value={valueTo}
                                    label="to"
                                />
                            )}
                        </div>
                    </div>

                    <Inputbox
                        label="Subject"
                        value={subject}
                        fieldFor={'subject'}
                        onChange={this.handleSubjectChange}
                    />
                </div>

                <Editor
                    toolbar={{
                        options: ['inline', 'textAlign', 'history', 'fontSize'],
                        inline: {
                            options: ['bold', 'italic', 'underline'],
                        },
                        textAlign: {
                            options: ['left', 'center', 'right'],
                        },
                    }}
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={this.onEditorStateChange}
                />

                <div className="text-center">
                    <button className="btn btn-primary width136px" onClick={this.handleSave}>
                        Send
                    </button>
                </div>
            </div>
        )
    }
}

ContactWinRoom.propTypes = {
    onCloseModal: PropTypes.func.isRequired,
}

const stateToProps = createStructuredSelector({
    mailSentStatus: mailSentStatus(),
})

const dispatchProps = {
    contactWinRoom,
}

export default connect(stateToProps, dispatchProps)(ContactWinRoom)
