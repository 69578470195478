import React, { Component } from 'react'
import ModuleHeader from '../ModuleHeader'
import './index.css'
import Error from '../Error'
import DeliverSection from './DeliverSection'
import Inputbox from '../Inputbox'
import { SITE_TXT, STATUS_SUCCESS, DELIVER_HEADING } from '../../Utils/constants'

class Deliver extends Component {
    static getDerivedStateFromProps(nextProps, state) {
        const { data } = nextProps
        return { ...state, deliverData: data || {} }
    }

    constructor(props) {
        super(props)
        sessionStorage.setItem('topLevel', 'Deliver')
        this.state = {
            deliverData: {},
            searchInp: '',
            editmodeSection: null,
            isEditMode: false,
            categoryName: '',
        }
    }

    componentDidMount() {
        this.props.deliver()
    }

    searchDeliver = (field, value) => {
        this.setState({ [field]: value })
    }
    handleFade = (isEditMode, editSection, catName) => {
        //console.log('isEditMode', isEditMode + 'editSection', editSection)
        this.setState({ isEditMode: isEditMode, editmodeSection: editSection, categoryName: catName })
    }

    render() {
        const { deliverData, searchInp } = this.state
        const { TXT_SEARCH_PLACEHOLDER } = SITE_TXT
        return (
            <main className="deliver-container">
                {deliverData.status === STATUS_SUCCESS && (
                    <div>
                        <div className="deliver-search-inp">
                            {deliverData.result.header && <ModuleHeader heading={DELIVER_HEADING} />}
                            <Inputbox
                                placeholder={TXT_SEARCH_PLACEHOLDER}
                                type="text"
                                fieldFor="searchInp"
                                onChange={this.searchDeliver}
                                autoFocus={true}
                            />
                        </div>
                        {this.state.isEditMode ? (
                            <div className="editingmode">
                                You are now Editing <strong>{this.state.categoryName}</strong> on this page{' '}
                                <span onClick={() => this.handleFade(false, null, '')}>X</span>
                            </div>
                        ) : null}
                        {deliverData.result.data &&
                            deliverData.result.data.map((list, index) => (
                                <div
                                    key={index}
                                    className={
                                        this.state.isEditMode && this.state.editmodeSection !== list.id
                                            ? 'fadesection'
                                            : null
                                    }
                                >
                                    <DeliverSection
                                        categoryId={list.id}
                                        handleFade={this.handleFade}
                                        isEditMode={this.state.isEditMode}
                                        key={index}
                                        categoryName={list.name}
                                        data={list.list}
                                        addSection={this.props.deliverAddSection}
                                        editSection={this.props.deliverEditSection}
                                        trashSection={this.props.deliverTrashSection}
                                        addSubSection={this.props.deliverAddSubSection}
                                        editSubSection={this.props.deliverEditSubSection}
                                        fetchDeliverItem={this.props.fetchDeliverItem}
                                        selectfetchData={this.props.selectfetchData}
                                        searchInp={searchInp.trim()}
                                        onReorder={this.props.reorder}
                                    />
                                </div>
                            ))}
                    </div>
                )}
                {deliverData.error && <Error message={deliverData.error} />}
            </main>
        )
    }
}

export default Deliver
