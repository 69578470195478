import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { PencilIcon, TrashIcon, Arrow } from '../../../../Utils/svg'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import {
    addPartner,
    editPartner,
    deletePartner,
    setSortedArray,
    setOrderOfItem,
    fetchCurrentTemplate,
    updateBlock,
    addBlock,
    deleteBlock,
    updateBlockHeader,
    resetListUpdate,
    setElementToScroll,
} from '../../../../Containers/LopContent/actions'
import c from 'classnames'
import { isListUpdated } from '../../../../Containers/LopContent/selector'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import TeamMemberModal from './TeamMemberModal'
import arrayMove from 'array-move'
import { getPrefixedUrl } from '../../../../Utils/apis'
import './antd.css'
import './index.scss'
import { SITE_TXT } from '../../../../Utils/constants'
import { ConfirmAlertBox } from '../../../../Containers/Library/Alerts'
import HeadingModal from './HeadingModal'
import DetailsModal from './DetailsModal'
import { Collapse, Dropdown, Icon, Menu } from 'antd'

const SortableItem = SortableElement(props => {
    const graybgArr = [2, 3, 6, 7, 10, 11, 14, 15, 18, 19, 22, 23]
    return (
        <div className={`team-box ${graybgArr.indexOf(props.dumIndex + 1) >= 0 ? 'gray-teambox' : null}`}>
            <div className="profileImage">
                <img alt="" src={getPrefixedUrl(props.value.image)} className="partnerImage" />
            </div>
            <div className="profile-details">
                {props.editMode && (
                    <div className="button-group">
                        <span>
                            <Dropdown
                                overlay={props.menuItem}
                                onVisibleChange={() => props.getItemId(props.value, props.itemIndex, props.dumIndex)}
                            >
                                <span className="ant-dropdown-link">
                                    Move To <Icon type="down" />
                                </span>
                            </Dropdown>
                        </span>
                        <span onClick={() => props.handleEdit(props.value, props.itemIndex, `${props.dumIndex}`)}>
                            <PencilIcon width="18px" height="18px" />
                        </span>
                        <span
                            onClick={() =>
                                props.handleDelete(props.value.id, props.blockId, props.itemIndex, props.dumIndex)
                            }
                            className="black-trace-icon"
                        >
                            <TrashIcon width="13px" height="15px" />
                        </span>
                    </div>
                )}
                <div className="TeamMemberName">
                    <h3>{props.value.name}</h3>
                    <div className="designation">{props.value.designation}</div>
                </div>
                <div
                    className="description"
                    dangerouslySetInnerHTML={{
                        __html: props.value.description,
                    }}
                />
                {props.value.description && props.value.description.length > 225 ? (
                    <button className="details" onClick={() => props.openDetailsModal(props.value)}>
                        Read More
                    </button>
                ) : (
                    <div>&nbsp;</div>
                )}
            </div>
        </div>
    )
})

const SortableList = SortableContainer(props => {
    return (
        <div
            className={c('team-container', {
                editmode: props.editMode,
            })}
        >
            {props.data &&
                props.data.length > 0 &&
                props.data.map((value, index) => {
                    if (value.isDelete === 'false') {
                        return (
                            <SortableItem
                                key={`item-${index}`}
                                index={index}
                                dumIndex={index}
                                value={value}
                                disabled={!props.editMode || props.disableReorder}
                                handleDelete={props.handleDelete}
                                handleEdit={props.handleEdit}
                                editMode={props.editMode}
                                openDetailsModal={props.openDetailsModal}
                                menuItem={props.menuItem}
                                getItemId={props.getItemId}
                                itemIndex={props.itemIndex}
                                blockId={props.block_id}
                            />
                        )
                    }
                    return null
                })}
            {props.editMode && (
                <Fragment>
                    <div onClick={() => props.addTeamMember(props.block_id)} className="add-team">
                        <span className="btn-add-team">Add Person</span>
                    </div>
                </Fragment>
            )}
        </div>
    )
})

class Template3 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            detailsModal: false,
            isEditMode: false,
            sectionId: props.sectionId,
            isListUpdated: false,
            teamData: {},
            currentVisibleItem: null,
            openHeadingModal: false,
            header: '',
            curItemIndex: null,
            dumIndex: null,
            oldIndex: null,
            curBlockId: null,
            curBlockIndex: null,
            anchors: null,
        }
        this.myRef = React.createRef()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.isListUpdated) {
            this.setState(
                {
                    isListUpdated: true,
                },
                () => {
                    if (this.state.isListUpdated) {
                        this.handleCloseModal()
                        this.props.resetListUpdate()
                        this.setState({
                            isListUpdated: false,
                        })
                    }
                }
            )
        }
    }

    componentDidMount() {
        const anchors = document.querySelectorAll('a')
        this.setState({ anchors })
        if (anchors && anchors.length) {
            for (let i = 0; i < anchors.length; i++) {
                anchors[i].addEventListener('click', this.eventObj)
                anchors[i].addEventListener('touchstart', this.eventObj)
            }
        }
    }
    componentWillUnmount() {
        const { anchors } = this.state
        if (anchors && anchors.length) {
            for (let i = 0; i < anchors.length; i++) {
                anchors[i].removeEventListener('click', this.eventObj)
                anchors[i].removeEventListener('touchstart', this.eventObj)
            }
        }
    }
    eventObj = e => {
        const locationToSend = e.target.href
        if (locationToSend && locationToSend.includes('noredirect')) {
            e.preventDefault()
            const { sections, updateCurrentSlide, setElementToScroll } = this.props
            const locationArray = locationToSend.split('/')
            const sectionId = locationArray[locationArray.length - 2]
            const elementId = locationArray[locationArray.length - 1]
            const sectionToMove = sections.findIndex(x => x.sectionId === sectionId)
            if (sectionToMove) {
                updateCurrentSlide(sectionToMove)
                setElementToScroll(elementId)
            }
        }
    }

    handleAddTeamMember = (curBlockId, curBlockIndex) => {
        this.setState({
            open: true,
            curBlockId,
            curBlockIndex,
        })
    }

    handleCloseModal = () => {
        this.setState({
            open: false,
            isEditMode: false,
            teamData: {},
            curBlockId: null,
            curBlockIndex: null,
        })
    }
    handleHeadingCloseModal = () => {
        this.setState({
            openHeadingModal: false,
            header: '',
        })
    }

    handleDetailsCloseModal = () => {
        this.setState({
            detailsModal: false,
        })
    }

    handleHeadingModal = () => {
        this.setState({
            openHeadingModal: true,
        })
    }

    handleEdit = (teamData, curBlockIndex, curBlockId) => {
        this.setState({
            open: true,
            teamData,
            isEditMode: true,
            curBlockId,
            curBlockIndex,
        })
    }

    handleDelete = (id, block_id, blockIndex, itemIndex) => {
        this.setState({ ondeleteSuccessStatus: false })
        const title = SITE_TXT.TXT_CONFIRM_DELETE
        const message = SITE_TXT.TXT_PARTNER_DELETE_MESSAGE
        ConfirmAlertBox(title, message, () => this.trashFinally(id, block_id, blockIndex, itemIndex))
    }

    trashFinally = (id, block_id, blockIndex, itemIndex) => {
        const { deletePartner, sectionDetails } = this.props
        const { sectionId, templateId } = sectionDetails
        const data = {
            sectionId,
            templateId,
            id,
            block_id,
            blockIndex,
            itemIndex,
        }
        deletePartner(data)
        this.setState({ ondeleteSuccessStatus: true })
    }

    openDetailsModal = item => {
        this.setState({
            detailsModal: true,
            teamData: item,
        })
    }

    onSortEnd = ({ oldIndex, newIndex }, array, index) => {
        const { content } = this.props
        const { sectionContent = [] } = content
        sectionContent[index].partners = arrayMove(array, oldIndex, newIndex)
        this.props.setSortedArray(sectionContent)
    }

    moveTo = (item, index) => {
        const { content, updateBlock } = this.props
        const { currentVisibleItem, dumIndex, oldIndex: removeIndex } = this.state
        const { sectionContent = [] } = content
        const { block_id } = sectionContent[index]
        const data = {
            dataToMove: { ...currentVisibleItem, block_id },
            index,
            removeIndex,
            dumIndex,
        }
        updateBlock(data)
    }
    handleHeaderEdit = (e, item, index) => {
        e.stopPropagation()
        this.setState({
            openHeadingModal: true,
            header: item.block_name,
            curItemIndex: index,
        })
    }
    handleHeaderDelete = (e, item, index) => {
        e.stopPropagation()
        const { deleteBlock } = this.props
        this.setState({ ondeleteSuccessStatus: false })
        const title = SITE_TXT.TXT_CONFIRM_DELETE
        const message = SITE_TXT.TXT_BLOCK_DELETE_MESSAGE
        ConfirmAlertBox(title, message, () => deleteBlock({ ...item, isDelete: 'true' }, index))
    }

    handleHeaderShift = (e, moveType, item, index) => {
        e.stopPropagation()
        const { content } = this.props
        const { sectionContent = [] } = content

        if (moveType === 'up') {
            if (index !== 0) {
                const newIndex = index - 1
                this.props.setSortedArray(arrayMove(sectionContent, index, newIndex))
            }
        } else {
            if (index < sectionContent.length) {
                const newIndex = index + 1
                this.props.setSortedArray(arrayMove(sectionContent, index, newIndex))
            }
        }
    }

    getItemId = (item, index, x) => {
        this.setState({
            currentVisibleItem: item,
            oldIndex: index,
            dumIndex: x,
        })
    }

    render() {
        const {
            open,
            isEditMode,
            teamData,
            detailsModal,
            openHeadingModal,
            header,
            curItemIndex,
            curBlockId,
            curBlockIndex,
        } = this.state
        const {
            content,
            editMode,
            disableReorder,
            addPartner,
            editPartner,
            sectionDetails,
            addBlock,
            updateBlockHeader,
        } = this.props
        const { sections, updateCurrentSlide, setElementToScroll } = this.props
        const { templateId, sectionId } = sectionDetails
        const { sectionContent = [] } = content
        const menuItem = sectionContent.map((item, index) => {
            return (
                <Menu.Item key={index} onClick={() => this.moveTo(item, index)}>
                    {item.block_name}
                </Menu.Item>
            )
        })

        const menu = <Menu>{menuItem}</Menu>

        const genExtra = (item, index, editMode) => {
            if (editMode) {
                return (
                    <div>
                        <Icon
                            component={Arrow}
                            onClick={e => this.handleHeaderShift(e, 'up', item, index)}
                            style={{ marginRight: '10px' }}
                            className={'upArrow'}
                        />
                        <Icon
                            component={Arrow}
                            onClick={e => this.handleHeaderShift(e, 'down', item, index)}
                            style={{ marginRight: '50px' }}
                            className={'downArrow'}
                        />
                        <Icon
                            component={PencilIcon}
                            onClick={e => this.handleHeaderEdit(e, item, index)}
                            style={{ marginRight: '40px' }}
                        />
                        {sectionContent.filter(item => item.isDelete === 'false').length > 1 ? (
                            <Icon
                                className="delete-icon"
                                component={TrashIcon}
                                onClick={e => this.handleHeaderDelete(e, item, index)}
                                style={{ marginRight: '60px' }}
                            />
                        ) : null}
                    </div>
                )
            } else {
                return null
            }
        }
        const { Panel } = Collapse

        return (
            <div className={`team-ctrl ${editMode ? 'ineditmode' : ''}`}>
                {editMode && (
                    <div className="add-panel">
                        <div onClick={this.handleHeadingModal}>
                            <div className="plus">
                                <div className="cross">&nbsp;</div>
                            </div>
                            {SITE_TXT.TXT_ADD_HEADING}
                        </div>
                    </div>
                )}

                <Collapse
                    defaultActiveKey={sectionContent[0].block_id}
                    expandIconPosition="right"
                    accordion
                    bordered={false}
                >
                    {sectionContent.map((item, index) => {
                        if (item.isDelete === 'false') {
                            return (
                                <Panel
                                    header={item && item.block_name}
                                    key={item.block_id}
                                    ref={this.myRef}
                                    extra={genExtra(item, index, editMode)}
                                    forceRender={true}
                                    className="padding-left-45"
                                >
                                    <SortableList
                                        data={item.partners}
                                        onSortEnd={props => this.onSortEnd(props, item.partners, index)}
                                        axis="xy"
                                        block_id={item.block_id}
                                        distance={1}
                                        lockToContainerEdges={true}
                                        editMode={editMode}
                                        disableReorder={disableReorder}
                                        handleDelete={this.handleDelete}
                                        handleEdit={this.handleEdit}
                                        handleClick={this.handleClick}
                                        openDetailsModal={this.openDetailsModal}
                                        addTeamMember={item => this.handleAddTeamMember(item, index)}
                                        menuItem={menu}
                                        getItemId={this.getItemId}
                                        itemIndex={index}
                                    />
                                </Panel>
                            )
                        }
                        return null
                    })}
                </Collapse>
                {open && (
                    <TeamMemberModal
                        open={open}
                        onCloseModal={this.handleCloseModal}
                        teamData={teamData}
                        isEditMode={isEditMode}
                        sectionId={sectionId}
                        addPartner={addPartner}
                        editPartner={editPartner}
                        sectionDetails={sectionDetails}
                        content={content}
                        PeopleCategory={sectionContent.map(item => ({
                            key: item.block_id,
                            value: item.block_name,
                        }))}
                        curBlockId={curBlockId}
                        curBlockIndex={curBlockIndex}
                    />
                )}
                {detailsModal && (
                    <DetailsModal
                        onCloseModal={this.handleDetailsCloseModal}
                        teamData={teamData}
                        sections={sections}
                        updateCurrentSlide={updateCurrentSlide}
                        setElementToScroll={setElementToScroll}
                    />
                )}

                {openHeadingModal && (
                    <HeadingModal
                        editMode={editMode}
                        sectionId={sectionId}
                        templateId={templateId}
                        header={header}
                        curItemIndex={curItemIndex}
                        addBlock={addBlock}
                        onCloseModal={this.handleHeadingCloseModal}
                        updateBlockHeader={updateBlockHeader}
                    />
                )}
            </div>
        )
    }
}

Template3.propTypes = {
    editMode: PropTypes.bool.isRequired,
    disableReorder: PropTypes.bool.isRequired,
    isListUpdated: PropTypes.bool.isRequired,
    content: PropTypes.instanceOf(Object).isRequired,
    sectionDetails: PropTypes.instanceOf(Object).isRequired,
    deletePartner: PropTypes.func.isRequired,
    addPartner: PropTypes.func.isRequired,
    editPartner: PropTypes.func.isRequired,
    fetchCurrentTemplate: PropTypes.func.isRequired,
    setOrderOfItem: PropTypes.func.isRequired,
    setSortedArray: PropTypes.func.isRequired,
    updateBlock: PropTypes.func.isRequired,
    addBlock: PropTypes.func.isRequired,
    deleteBlock: PropTypes.func.isRequired,
    updateBlockHeader: PropTypes.func.isRequired,
    resetListUpdate: PropTypes.func.isRequired,
}

const stateToProps = createStructuredSelector({
    isListUpdated: isListUpdated(),
})

const dispatchProps = {
    addPartner,
    editPartner,
    deletePartner,
    setSortedArray,
    setOrderOfItem,
    fetchCurrentTemplate,
    updateBlock,
    addBlock,
    deleteBlock,
    updateBlockHeader,
    resetListUpdate,
    setElementToScroll,
}

export default connect(stateToProps, dispatchProps)(Template3)
