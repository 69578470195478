import React, { Component } from 'react'
import { HeadingIcon, EditIconWithCircleNew, ReorderIcon } from '../../Utils/svg'
import ThumbnailList from '../ThumbnailList'
import { isAdmin, isMobileTablet } from '../../Utils/constants'

class DeliverSubSection extends Component {
    state = {
        isReorderMode: false,
    }
    toggleReorderMode() {
        this.setState({ isReorderMode: !this.state.isReorderMode }, () => {
            this.props.toggleShowEditMode()
        })
    }

    setReorderMode(mode) {
        this.setState({ isReorderMode: mode }, () => mode || this.props.toggleShowEditMode())
    }

    render() {
        const {
            data,
            sectionId,
            addSubSection,
            editSubSection,
            fetchDeliverItem,
            selectfetchData,
            searchInp,
            onReorder,
        } = this.props
        const { isReorderMode } = this.state
        const { isEditMode, editSectionHandler } = this.props
        const showActions = isAdmin && !isMobileTablet
        return (
            <div className="deliver-thumbnail-list">
                <div className="list-header">
                    <HeadingIcon height="16" width="16" />
                    <label>{data.name}</label>
                    {isEditMode && (
                        <span className="actionIcon" onClick={() => editSectionHandler(data)}>
                            <EditIconWithCircleNew />
                        </span>
                    )}
                    {!isReorderMode && !isEditMode && showActions && (
                        <span className="actionIcon" onClick={() => this.toggleReorderMode()}>
                            <ReorderIcon />
                        </span>
                    )}
                </div>
                <ThumbnailList
                    topCategoryId={data.id}
                    topCategoryName={data.name}
                    listData={data.list}
                    showSlider={false}
                    isEditMode={isEditMode}
                    addSubSection={addSubSection}
                    editSubSection={editSubSection}
                    fetchDeliverItem={fetchDeliverItem}
                    selectfetchData={selectfetchData}
                    searchInp={searchInp}
                    isReorderMode={isReorderMode}
                    setReorderMode={mode => this.setReorderMode(mode)}
                    onReorder={onReorder}
                    sectionId={sectionId}
                />
            </div>
        )
    }
}

export default DeliverSubSection
