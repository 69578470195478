import { fromJS } from 'immutable'
import { INDUSTRY_GET } from './constant'

const INITIAL_STATE = fromJS({
    status: null,
    isLoading: true,
    error: null,
})

function industryReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case `${INDUSTRY_GET}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)
        case `${INDUSTRY_GET}_SUCCESS`:
            return state.set('status', action.data.responseData).set('isLoading', false)
        case `${INDUSTRY_GET}_FAILED`:
            return state.set('error', action.data.responseData).set('isLoading', false)
        default:
            return state
    }
}

export default industryReducer
