import { call, put, all, takeLatest } from 'redux-saga/effects'
import {
    getDeliverItems,
    addDeliverTopic,
    editDeliverTopic,
    addDeliverProduct,
    editDeliverProduct,
    getDeliverProduct,
    deleteDeliverTopic,
    deleteDeliverProduct,
    setDeliverItemOrder,
    downloadDeliverProductBrochure,
} from '../../Utils/apis'
import {
    DELIVER_GET,
    DELIVER_ADD_SECTION,
    DELIVER_EDIT_SECTION,
    DELIVER_TRASH_SECTION,
    DELIVER_EDIT_SUB_SECTION,
    DELIVER_ADD_SUB_SECTION,
    DELIVER_FETCH_ITEM,
    DELIVER_DELETE_SUB_SECTION,
    DELIVER_ITEM_REORDER,
    DELIVER_DOWNLOAD_BROCHURE,
} from './constant'
import {
    deliverSuccess,
    deliverFailed,
    deliverAddSectionSuccess,
    deliverAddSectionFailed,
    deliverEditSectionSuccess,
    deliverEditSectionFailed,
    deliverTrashSectionSuccess,
    deliverTrashSectionFailed,
    deliverAddSubSectionSuccess,
    deliverAddSubSectionFailed,
    deliverEditSubSectionSuccess,
    deliverEditSubSectionFailed,
    fetchDeliverItemSuccess,
    fetchDeliverItemFailed,
    deliverDeleteSubSectionSuccess,
    deliverDeleteSubSectionFailed,
    reorderSuccess,
    reorderFailure,
    downloadDeliverBrochureFailed,
    downloadDeliverBrochureSuccess,
} from './actions'
import { STATUS_SUCCESS } from '../../Utils/constants'

export function* deliver() {
    const response = yield call(getDeliverItems)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(deliverSuccess({ responseData }))
    } else {
        yield put(deliverFailed({ responseData }))
    }
}

export function* deliverAddSection({ data }) {
    const response = yield call(addDeliverTopic, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(
            deliverAddSectionSuccess({
                responseData,
                reqData: data,
            })
        )
    } else {
        yield put(deliverAddSectionFailed({ responseData }))
    }
}

export function* deliverEditSection({ data }) {
    const response = yield call(editDeliverTopic, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(
            deliverEditSectionSuccess({
                responseData,
                reqData: data,
            })
        )
    } else {
        yield put(deliverEditSectionFailed({ responseData }))
    }
}

export function* deliverTrashSection({ data }) {
    const response = yield call(deleteDeliverTopic, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(
            deliverTrashSectionSuccess({
                responseData,
                reqData: data,
            })
        )
    } else {
        yield put(deliverTrashSectionFailed({ responseData }))
    }
}

export function* deliverAddSubSection({ data }) {
    const response = yield call(addDeliverProduct, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(deliverAddSubSectionSuccess({ responseData }))
    } else {
        yield put(deliverAddSubSectionFailed({ responseData }))
    }
}

export function* deliverFetchItem({ data }) {
    const response = yield call(getDeliverProduct, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(fetchDeliverItemSuccess({ responseData }))
    } else {
        yield put(fetchDeliverItemFailed({ responseData }))
    }
}

export function* deliverEditSubSection({ data }) {
    const response = yield call(editDeliverProduct, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(deliverEditSubSectionSuccess({ responseData }))
    } else {
        yield put(deliverEditSubSectionFailed({ responseData }))
    }
}

export function* deliverDeleteSubSection({ data }) {
    const response = yield call(deleteDeliverProduct, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(deliverDeleteSubSectionSuccess({ responseData }))
    } else {
        yield put(deliverDeleteSubSectionFailed({ responseData }))
    }
}

export function* reorderDeliverItems({ data }) {
    const response = yield call(setDeliverItemOrder, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(reorderSuccess({ responseData, reqData: data }))
    } else {
        yield put(reorderFailure({ responseData }))
    }
}

export function* downloadBrochure({ data }) {
    const response = yield call(downloadDeliverProductBrochure, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(downloadDeliverBrochureSuccess({ responseData }))
    } else {
        yield put(downloadDeliverBrochureFailed({ responseData }))
    }
}

export function* deliverSaga() {
    yield all([
        takeLatest(`${DELIVER_ADD_SUB_SECTION}_PENDING`, deliverAddSubSection),
        takeLatest(`${DELIVER_EDIT_SUB_SECTION}_PENDING`, deliverEditSubSection),
        takeLatest(`${DELIVER_FETCH_ITEM}_PENDING`, deliverFetchItem),
        takeLatest(`${DELIVER_GET}_PENDING`, deliver),
        takeLatest(`${DELIVER_ADD_SECTION}_PENDING`, deliverAddSection),
        takeLatest(`${DELIVER_EDIT_SECTION}_PENDING`, deliverEditSection),
        takeLatest(`${DELIVER_TRASH_SECTION}_PENDING`, deliverTrashSection),
        takeLatest(`${DELIVER_DELETE_SUB_SECTION}_PENDING`, deliverDeleteSubSection),
        takeLatest(`${DELIVER_ITEM_REORDER}_PENDING`, reorderDeliverItems),
        takeLatest(`${DELIVER_DOWNLOAD_BROCHURE}_PENDING`, downloadBrochure),
    ])
}

export default [deliverSaga]
