import { put, takeEvery, all, call } from 'redux-saga/effects'
import {
    LIBRARY_ITEMS_GET,
    LIBRARY_ITEM,
    LIBRARY_DOCUMENTS,
    LIBRARY_DOCUMENTS_MORE,
    LIBRARY_ITEM_ADD,
    LIBRARY_ITEM_EDIT,
    LIBRARY_ITEM_TRASH,
    LIBRARY_PHASE_ADD,
    LIBRARY_PHASE_EDIT,
    LIBRARY_PHASE_TRASH,
    LIBRARY_ITEM_REORDER,
    LIBRARY_DOWNLOAD_DOCUMENTS,
    SAVE_LIBRARY_DOWNLOAD_DOCUMENTS,
    LIBRARY_EMAIL_DOCUMENTS,
    LIBRARY_OWNER_GET,
    OWNER_LOCATION_GET,
    LIBRARY_OWNER_ADD,
    REMOVE_OWNER,
    UPDATE_OWNER,
    SHARE_DOCUMENTS_POST,
    SHARE_DOCUMENTS_GET,
    SHARE_DOCUMENT_POST,
    MULTIPLE_SHARE_DOCS,
} from './constant'
import { STATUS_SUCCESS } from '../../Utils/constants'
import {
    librarySuccessAction,
    libraryFailedAction,
    addSuccess,
    addFailure,
    reorderSuccess,
    reorderFailure,
    editSuccess,
    editFailure,
    trashSuccess,
    trashFailure,
    libraryCategorySuccess,
    libraryCategoryFailure,
    addPhaseSuccess,
    addPhaseFailure,
    editPhaseSuccess,
    editPhaseFailure,
    trashPhaseSuccess,
    trashPhaseFailure,
    libraryDocumentsSuccess,
    libraryDocumentsFailure,
    libraryDocumentsMoreSuccess,
    libraryDocumentsMoreFailure,
    downloadDocumentsSuccess,
    downloadDocumentsFailure,
    saveDocumentsSuccess,
    saveDocumentsFailure,
    emailDocumentsSuccess,
    emailDocumentsFailure,
    getLibraryOwnerSuccess,
    getLibraryOwnerFailure,
    getOwnerLocationsSuccess,
    getOwnerLocationsFailure,
    addLibraryOwnerSuccess,
    addLibraryOwnerFailure,
    removeOwnerFailure,
    removeOwnerSuccess,
    updateOwnerFailure,
    updateOwnerSuccess,
    postShareDocumentsSuccess,
    shareDocumentsFailure,
    copyShareDocumentsSuccess,
    copyDocumentsFailure,
    postCopyShareDocumentsSuccess,
    postCopyDocumentsFailure,
    multipleShareDocsSuccess,
    multipleShareDocsFailure,
} from './actions'
import {
    getLibaryItems,
    getLibraryItem,
    getLibraryItemDocuments,
    addLibraryItem,
    setLibraryItemOrder,
    editLibraryItem,
    deleteLibraryItem,
    addLibraryPhase,
    callEditPhase,
    callTrashPhase,
    downloadLibraryDocument,
    saveLibraryDocument,
    emailLibraryDocument,
    getLibraryOwner,
    getOwnerLocations,
    addLibraryOwner,
    setLibraryOwner,
    removeLibraryOwner,
    shareDocument,
    getShareCopyDocument,
    postShareCopyDocument,
    multipleShareDocs,
} from '../../Utils/apis'

export function* library() {
    const response = yield call(getLibaryItems)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(librarySuccessAction({ responseData }))
    } else {
        yield put(libraryFailedAction({ responseData }))
    }
}

export function* libraryCategory() {
    const response = yield call(getLibraryItem)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(libraryCategorySuccess({ responseData }))
    } else {
        yield put(
            libraryCategoryFailure({
                responseData,
            })
        )
    }
}

export function* libraryDocuments({ data }) {
    const response = yield call(getLibraryItemDocuments, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(libraryDocumentsSuccess({ responseData }))
    } else {
        yield put(
            libraryDocumentsFailure({
                responseData,
            })
        )
    }
}

export function* shareDocuments({ data }) {
    const response = yield call(shareDocument, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(postShareDocumentsSuccess({ responseData }))
    } else {
        yield put(shareDocumentsFailure({ responseData }))
    }
}

export function* shareCopyDocuments({ data }) {
    const response = yield call(getShareCopyDocument, data)
    const responseData = response.data
    if (responseData !== undefined) {
        if (!responseData.length) {
            const responsePost = yield call(postShareCopyDocument, data.doc)
            const responsePostData = responsePost.data
            if (responsePostData.status === STATUS_SUCCESS) {
                yield put(postCopyShareDocumentsSuccess({ responseData: responsePostData }))
            } else {
                yield put(postCopyDocumentsFailure({ responseData: responsePostData }))
            }
        } else {
            yield put(copyShareDocumentsSuccess({ responseData }))
        }
    } else {
        yield put(copyDocumentsFailure({ responseData }))
    }
}

export function* postShareCopyDocuments({ data }) {
    const response = yield call(postShareCopyDocument, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(postCopyShareDocumentsSuccess({ responseData }))
    } else {
        yield put(postCopyDocumentsFailure({ responseData }))
    }
}

export function* multipleShareDocsSaga({ data }) {
    const response = yield call(multipleShareDocs, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(multipleShareDocsSuccess({ responseData }))
    } else {
        yield put(multipleShareDocsFailure({ responseData }))
    }
}

export function* libraryDocumentsMore({ data }) {
    const response = yield call(getLibraryItemDocuments, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(libraryDocumentsMoreSuccess({ responseData }))
    } else {
        yield put(
            libraryDocumentsMoreFailure({
                responseData,
            })
        )
    }
}

export function* addLibrary({ data }) {
    const response = yield call(addLibraryItem, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(addSuccess({ responseData, reqData: data }))
    } else {
        yield put(addFailure({ responseData }))
    }
}

export function* editLibrary({ data }) {
    const response = yield call(editLibraryItem, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(editSuccess({ responseData, reqData: data }))
    } else {
        yield put(editFailure({ responseData }))
    }
}

export function* trashLibrary({ data }) {
    const response = yield call(deleteLibraryItem, data.id)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(trashSuccess({ responseData, reqData: data }))
    } else {
        yield put(trashFailure({ responseData }))
    }
}

export function* addLibraryPhaseItem({ data }) {
    const response = yield call(addLibraryPhase, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(addPhaseSuccess({ responseData, reqData: data }))
        sessionStorage.setItem('selTabId', responseData.result.id)
        sessionStorage.setItem('selTabName', data.name)
        document.querySelector('.react-tabs__tab--selected').click()
    } else {
        yield put(addPhaseFailure({ responseData }))
    }
}

export function* editLibraryPhase({ data }) {
    const response = yield call(callEditPhase, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(editPhaseSuccess({ responseData, reqData: data }))
    } else {
        yield put(editPhaseFailure({ responseData }))
    }
}

export function* trashLibraryPhase({ data }) {
    const response = yield call(callTrashPhase, data.id)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(trashPhaseSuccess({ responseData, reqData: data }))
    } else {
        yield put(trashPhaseFailure({ responseData }))
    }
}

export function* reorderLibrary({ data }) {
    const response = yield call(setLibraryItemOrder, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(reorderSuccess({ responseData, reqData: data }))
    } else {
        yield put(reorderFailure({ responseData }))
    }
}

export function* downloadLibraryDocuments({ data }) {
    const response = yield call(downloadLibraryDocument, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(downloadDocumentsSuccess({ responseData }))
    } else {
        yield put(downloadDocumentsFailure({ responseData }))
    }
}

export function* saveLibraryDocuments({ url }) {
    const fileLength = url.split('/')
    const fileName = fileLength[fileLength.length - 1]
    const response = yield call(saveLibraryDocument, url)
    const urlNew = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = urlNew
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    if (response.status === 200) {
        yield put(saveDocumentsSuccess({ response }))
    } else {
        yield put(saveDocumentsFailure({ response }))
    }
}

export function* emailLibraryDocuments({ data }) {
    const response = yield call(emailLibraryDocument, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(emailDocumentsSuccess({ responseData }))
    } else {
        yield put(emailDocumentsFailure({ responseData }))
    }
}

export function* getLibraryOwnerSaga({ data }) {
    const response = yield call(getLibraryOwner, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(getLibraryOwnerSuccess({ responseData }))
    } else {
        yield put(getLibraryOwnerFailure({ responseData }))
    }
}

export function* getOwnerLocationsSaga() {
    const response = yield call(getOwnerLocations)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(getOwnerLocationsSuccess({ responseData }))
    } else {
        yield put(getOwnerLocationsFailure({ responseData }))
    }
}

export function* addLibraryOwnerSaga({ data }) {
    const response = yield call(addLibraryOwner, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(addLibraryOwnerSuccess({ responseData }))
    } else {
        yield put(addLibraryOwnerFailure({ responseData }))
    }
}
export function* updateOwnerSaga({ data }) {
    const response = yield call(setLibraryOwner, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(updateOwnerSuccess({ responseData }))
    } else {
        yield put(updateOwnerFailure({ responseData }))
    }
}
export function* removeOwnerSaga({ data }) {
    const response = yield call(removeLibraryOwner, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(removeOwnerSuccess({ responseData }))
    } else {
        yield put(removeOwnerFailure({ responseData }))
    }
}
export function* librarySaga() {
    yield all([
        takeEvery(`${LIBRARY_ITEMS_GET}_PENDING`, library),
        takeEvery(`${LIBRARY_ITEM}_PENDING`, libraryCategory),
        takeEvery(`${LIBRARY_DOCUMENTS}_PENDING`, libraryDocuments),
        takeEvery(`${LIBRARY_DOCUMENTS_MORE}_PENDING`, libraryDocumentsMore),
        takeEvery(`${LIBRARY_ITEM_ADD}_PENDING`, addLibrary),
        takeEvery(`${LIBRARY_ITEM_REORDER}_PENDING`, reorderLibrary),
        takeEvery(`${LIBRARY_ITEM_EDIT}_PENDING`, editLibrary),
        takeEvery(`${LIBRARY_ITEM_TRASH}_PENDING`, trashLibrary),
        takeEvery(`${LIBRARY_PHASE_ADD}_PENDING`, addLibraryPhaseItem),
        takeEvery(`${LIBRARY_PHASE_EDIT}_PENDING`, editLibraryPhase),
        takeEvery(`${LIBRARY_PHASE_TRASH}_PENDING`, trashLibraryPhase),
        takeEvery(`${LIBRARY_DOWNLOAD_DOCUMENTS}_PENDING`, downloadLibraryDocuments),
        takeEvery(`${SAVE_LIBRARY_DOWNLOAD_DOCUMENTS}_PENDING`, saveLibraryDocuments),
        takeEvery(`${LIBRARY_EMAIL_DOCUMENTS}_PENDING`, emailLibraryDocuments),

        takeEvery(`${LIBRARY_OWNER_GET}_PENDING`, getLibraryOwnerSaga),
        takeEvery(`${OWNER_LOCATION_GET}_PENDING`, getOwnerLocationsSaga),
        takeEvery(`${LIBRARY_OWNER_ADD}_PENDING`, addLibraryOwnerSaga),
        takeEvery(`${UPDATE_OWNER}_PENDING`, updateOwnerSaga),
        takeEvery(`${REMOVE_OWNER}_PENDING`, removeOwnerSaga),
        takeEvery(`${SHARE_DOCUMENTS_POST}_PENDING`, shareDocuments),
        takeEvery(`${SHARE_DOCUMENTS_GET}_PENDING`, shareCopyDocuments),
        takeEvery(`${SHARE_DOCUMENT_POST}_PENDING`, postShareCopyDocuments),
        takeEvery(`${MULTIPLE_SHARE_DOCS}_PENDING`, multipleShareDocsSaga),
    ])
}

export default [librarySaga]
