import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-responsive-modal'
import 'react-image-crop/dist/ReactCrop.css'

import { IMAGE_CROP_OBJ_RECT } from '../../../../Utils/constants'
import { TickIconNew, DeleteIcon } from '../../../../Utils/svg'
import ImageResizer from '../../../ImageResizer'
import './index.scss'

class ImageModal extends Component {
    state = {
        croppedImg: null,
    }

    clickTick = () => {
        const { croppedImg } = this.state
        this.props.handleSave(croppedImg)
    }

    getResizedImg = croppedImg => this.setState({ croppedImg })

    render() {
        const { dummyImg, closeModal } = this.props
        const styleClasses = {
            modal: 'modal-custom create-user-modal albumUploadImage galleryImageModal createLopModal',
            overlay: 'overlayAlbumModal',
            closeButton: 'closeBtn-custom',
        }

        return (
            <Modal open center onClose={closeModal} classNames={styleClasses} closeOnOverlayClick={false}>
                <div className="editable-item-modal full-image">
                    <ImageResizer
                        crop={IMAGE_CROP_OBJ_RECT}
                        quality={0.5} //this could be made more adjustable if required
                        imgDataUrl={dummyImg}
                        getResizedImg={x => this.getResizedImg(x)}
                    />
                    <div className="overlay-icons">
                        <span className="border-right" onClick={this.clickTick}>
                            <TickIconNew fillColor="#2251ff" />
                        </span>
                        <span onClick={closeModal} className="closeWrapper">
                            <DeleteIcon />
                        </span>
                    </div>
                </div>
            </Modal>
        )
    }
}

ImageModal.propTypes = {
    dummyImg: PropTypes.string.isRequired,
    handleSave: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
}

export default ImageModal
