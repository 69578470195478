import React, { Component } from 'react'

class Inputbox extends Component {
    handleChange(e) {
        this.props.onChange(this.props.fieldFor, e.target.value)
    }

    render() {
        const {
            label,
            placeholder,
            type,
            fieldFor,
            value,
            customClass,
            autoFocus,
            readonly,
            max,
            id,
            required,
            refValue,
            disabled,
        } = this.props
        return (
            <div className="form-group">
                {label ? <label>{label} </label> : ''}
                <input
                    className={`form-control  ${customClass}`}
                    placeholder={placeholder}
                    type={type}
                    name={fieldFor}
                    value={value}
                    onChange={this.handleChange.bind(this)}
                    onKeyPress={this.props.onKeyPress}
                    autoFocus={autoFocus}
                    readOnly={readonly}
                    maxLength={max}
                    onKeyUp={this.props.onKeyUp}
                    id={id}
                    required={required}
                    ref={refValue}
                    onBlur={this.props.onBlur}
                    disabled={disabled}
                />
            </div>
        )
    }
}
Inputbox.defaultProps = {
    autoFocus: false,
    disabled: false,
    type: 'text',
}

export default Inputbox
