import { USER_ROLE_GET, SET_LOP_HEADER } from './constant'

export const role = data => ({
    type: `${USER_ROLE_GET}_PENDING`,
    data,
})

export const roleSuccessAction = data => ({
    type: `${USER_ROLE_GET}_SUCCESS`,
    data,
})

export const roleFailedAction = data => ({
    type: `${USER_ROLE_GET}_FAILED`,
    data,
})

export const setLopHeader = (showLopHeader = false, disableSideBar = false, hideSideBar = true) => ({
    type: SET_LOP_HEADER,
    showLopHeader,
    disableSideBar,
    hideSideBar,
})
