import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { setLopHeader } from '../App/actions'
import { uploadLop } from '../Lop/actions'
import { uploadLopStatus } from '../Lop/selector'
import { selectWidth } from '../App/selector'
import CreateLop from './CreateLop'

const mapStateToProps = createStructuredSelector({
    uploadLopStatus: uploadLopStatus(),
    appWidth: selectWidth(),
})

const dispatchToProps = {
    uploadLop,
    setLopHeader,
}

export default connect(mapStateToProps, dispatchToProps)(CreateLop)
