import { NAME_PATTERN, MonthNames } from './constants'
import history from '../history'
export const expirationLogout = () => {
    // localStorage.removeItem('access_token')
    // localStorage.removeItem('id_token')
    // localStorage.removeItem('expires_at')
    // localStorage.removeItem('role')
    // localStorage.removeItem('userInfo')
    localStorage.clear()
    history.replace('/')
}

export const isEmpty = value =>
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)

export const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
}

export const getYearList = () => {
    const year = []
    for (let i = new Date().getFullYear(); i >= 1900; i--) {
        let yearObject = {
            key: i,
            value: i,
        }
        year.push(yearObject)
    }
    return year
}

export const isValidPattern = (value, pattern) => {
    let re = pattern || NAME_PATTERN
    return re.test(value)
}

export const isValidFile = (fileName, acceptTypes) => {
    let fileExt = fileName.slice(fileName.lastIndexOf('.') + 1).toLowerCase()
    return acceptTypes.indexOf(fileExt) !== -1
}

export const getFileExt = format => {
    return format.slice(0, format.indexOf('.'))
}

export const getFormattedDate = dateVal => {
    const d = new Date(dateVal)
    return MonthNames[d.getMonth()] + ' - ' + d.getFullYear()
}

export const getMonthNum = dateVal => {
    const d = new Date(dateVal)
    return d.getMonth() + 1
}

export const getYearNum = dateVal => {
    const d = new Date(dateVal)
    return d.getFullYear()
}

export const yearListData = () => {
    const years = []
    for (let i = new Date().getFullYear(); i >= 1900; i--) {
        let yearObj = {
            key: i,
            value: i,
        }
        years.push(yearObj)
    }
    return years
}

export const futureYearList = () => {
    const years = []
    for (let i = new Date().getFullYear(); i <= 2040; i++) {
        years.push({ key: i, value: i })
    }
    return years
}

export const getFileExtType = name => {
    let fileExt = name.slice(name.lastIndexOf('.') + 1).toLowerCase()
    if (fileExt === 'pptx' || fileExt === 'ppt') {
        return 'ppt'
    } else if (fileExt === 'txt') {
        return 'txt'
    } else if (fileExt === 'png') {
        return 'png'
    } else if (fileExt === 'xls' || fileExt === 'csv' || fileExt === 'excel' || fileExt === 'xlsx') {
        return 'xls'
    } else if (fileExt === 'doc' || fileExt === 'docx') {
        return 'doc'
    } else if (fileExt === 'pdf') {
        return 'pdf'
    } else if (fileExt === 'mov' || fileExt === 'mp4' || fileExt === 'flv' || fileExt === 'wmv') {
        return 'video'
    } else {
        return ''
    }
}

export const createMarkup = __html => ({ __html })

export const getStripTagsText = htmlTxt => {
    return htmlTxt.replace(/(<([^>]+)>)/gi, '')
}

export const hasAnyOfWords = (words, textToSearch) => {
    const arr = words.split(' ')
    let flag = false
    const arrL = arr.length
    for (let i = 0; i < arrL; i++) {
        if (textToSearch.indexOf(arr[i]) >= 0) {
            flag = true
            break
        }
    }
    return flag
}

export const getUniqueElements = elements => {
    const newElements = elements.map(x => (typeof x === 'string' ? x.toLowerCase() : x))
    return Array.from(new Set(newElements))
}

export const truncatedString = (str, mobile) => {
    let wordList = ''
    const wordCount = mobile && mobile === 'mobile' ? 15 : 50
    str.split(' ').forEach((word, index) => {
        if (index < wordCount) wordList += word + ' '
    })
    return wordList
}

export const truncatedString250 = (str, mobile) => {
    let wordList = ''
    const wordCount = mobile && mobile === 'mobile' ? 5 : 12
    str.split(' ').forEach((word, index) => {
        if (index < wordCount) wordList += word + ' '
    })
    return wordList + '...'
}

function getId(url) {
    //eslint-disable-next-line
    let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
    let match = url.match(regExp)

    if (match && match[2].length === 11) {
        return match[2]
    } else {
        return 'error'
    }
}

export const convertToEmbedLinkYouTube = regularLink => {
    const getHttp = regularLink.split(':')[0]
    let regX = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/
    let myId = ''
    let embedLink = ''
    if (regularLink.match(regX)) {
        myId = getId(regularLink)
        embedLink = getHttp + '://www.youtube.com/embed/' + myId
    }

    if (embedLink !== undefined && embedLink !== null && embedLink !== '') {
        return embedLink
    } else {
        return regularLink
    }
}

export const getRandomColor = () => {
    let color = 'hsl(' + Math.random() * 360 + ', 100%, 75%)'
    return color
}

export const random = number => {
    return Math.floor(Math.random() * number) + 1
}

export const getRandonColor = () => {
    return `hsl(${random(360)}, 100%, 75%)`
}

export const getNamePrefix = name => {
    const namePrefix = name.split(' ')
    let item = ''
    namePrefix.forEach(arr => {
        item += arr.split('')[0]
    })
    return item
}

export const sortFileArray = originalFile => {
    return [...originalFile].sort((a, b) => {
        const filenameA = a.imageFile.name.toLowerCase()
        const filenameB = b.imageFile.name.toLowerCase()
        if (filenameA < filenameB) {
            return -1
        }
        if (filenameA > filenameB) {
            return 1
        }
        return 0
    })
}

export const removeDuplicates = (a, b) => {
    for (let i = 0, len = a.length; i < len; i++) {
        for (let j = 0, len2 = b.length; j < len2; j++) {
            b[j].background = getRandonColor()
            if (a[i].userId === b[j].userId) {
                b.splice(j, 1)
                len2 = b.length
            }
        }
    }
}

export const removeLopData = (lop_id = null) => {
    localStorage.removeItem('myLopData')
    localStorage.setItem('editMode', false)
    localStorage.setItem('currentSlide', 0)
    localStorage.setItem('sectionDetails', '')
    localStorage.setItem('lop_id', lop_id)
}

export const getSlidesToShow = (appWidth = 1024) => {
    const biggerSlides = appWidth <= 1270 ? 5 : 7
    const mediumSlides = appWidth <= 900 ? 3 : biggerSlides
    return appWidth <= 440 ? 2 : mediumSlides
}
