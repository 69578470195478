import { INDUSTRY_GET } from './constant'

export const industryAction = data => ({
    type: `${INDUSTRY_GET}_PENDING`,
    data,
})

export const industrySuccessAction = data => ({
    type: `${INDUSTRY_GET}_SUCCESS`,
    data,
})

export const industryFailedAction = data => ({
    type: `${INDUSTRY_GET}_FAILED`,
    data,
})
