import React, { Component } from 'react'
import CreateUser from '../Admin/createUser'
import './profile.css'
class Profile extends Component {
    render() {
        const {
            fetchExpertTags,
            data,
            fetchUserType,
            userTypeData,
            fetchDesignationTags,
            designationData,
            status,
            userData,
            getUserProfile,
            setUserProfile,
            setUserProfileSuccess,
        } = this.props
        return (
            <main className="admin-container">
                <CreateUser
                    fetchExpertTags={fetchExpertTags}
                    data={data}
                    fetchUserType={fetchUserType}
                    userTypeData={userTypeData}
                    fetchDesignationTags={fetchDesignationTags}
                    designationData={designationData}
                    status={status}
                    userData={userData}
                    getUserProfile={getUserProfile}
                    setUserProfile={setUserProfile}
                    setUserProfileSuccess={setUserProfileSuccess}
                />
            </main>
        )
    }
}

export default Profile
