import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Modal from 'react-responsive-modal'
import { Button, Input } from 'reactstrap'
import { isEmpty } from 'lodash'

import { addTemplate4Details, updateTemplate4Details } from '../../../../Containers/LopContent/actions'
import { SITE_TXT, LINK_PATTERN } from '../../../../Utils/constants'
import { isValidPattern, convertToEmbedLinkYouTube } from '../../../../Utils/functions'
import './index.scss'

class VideoModal extends Component {
    constructor(props) {
        super(props)
        const { videoEditData } = props
        const { videoUrl = '' } = videoEditData
        this.state = {
            videoUrl,
        }
    }

    updateText = videoUrl => this.setState({ videoUrl })

    sendData = () => {
        const { videoUrl } = this.state
        const {
            addTemplate4Details,
            updateTemplate4Details,
            onClose,
            isText,
            contentTypeId,
            videoEditData,
        } = this.props
        const videoLink = convertToEmbedLinkYouTube(videoUrl)
        if (isEmpty(videoEditData)) {
            addTemplate4Details({ videoUrl: videoLink, isText, contentTypeId })
        } else {
            updateTemplate4Details(videoEditData.indexItem, 'videoUrl', videoLink)
        }
        onClose()
    }

    render() {
        const { videoUrl } = this.state
        const { onClose, videoEditData } = this.props

        return (
            <Modal open onClose={onClose} center classNames={{ modal: 'videoLinkModal' }} closeOnOverlayClick={false}>
                <h2>{isEmpty(videoEditData) ? SITE_TXT.TXT_ADD_VIDEO : SITE_TXT.TXT_EDIT_VIDEO}</h2>
                <div className="videoContent clearfix">
                    <h3>{SITE_TXT.TXT_ENTER_LINK}</h3>
                    <Input
                        value={videoUrl}
                        placeholder={SITE_TXT.TXT_ENTER_LINK}
                        onChange={e => this.updateText(e.target.value)}
                    />
                    <div className="info">
                        <a
                            href="http://ec2-3-8-149-75.eu-west-2.compute.amazonaws.com/images/Instructions.pptx"
                            download
                        >
                            Video Upload Instructions
                        </a>
                    </div>
                </div>

                <div className="btn-section">
                    <div className="btn btn-outline-secondary">
                        <span onClick={onClose}>{SITE_TXT.TXT_CANCEL}</span>
                    </div>
                    <Button disabled={!isValidPattern(videoUrl, LINK_PATTERN)} onClick={this.sendData} color="primary">
                        {SITE_TXT.TXT_DONE}
                    </Button>
                </div>
            </Modal>
        )
    }
}

VideoModal.propTypes = {
    contentTypeId: PropTypes.number,
    videoEditData: PropTypes.instanceOf(Object).isRequired,
    isText: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    addTemplate4Details: PropTypes.func.isRequired,
    updateTemplate4Details: PropTypes.func.isRequired,
}

VideoModal.defaultProps = {
    contentTypeId: 1,
}

const dispatchProps = {
    addTemplate4Details,
    updateTemplate4Details,
}
export default connect(null, dispatchProps)(VideoModal)
