import React, { Component } from 'react'
import UploadDocument from './uploadDocument'
import CreateUser from './createUser'
import Documents from './documents'
import './admin.css'
import AdminData from './adminData'
import VideoData from './VideoData'
import { Redirect } from 'react-router'
import { isAdmin } from '../../Utils/constants'
import Statistic from './Statistic'
class Admin extends Component {
    uploadDocPath() {
        const uploadPath = this.props.location.pathname.split('/')
        uploadPath.pop()
        return uploadPath.join('/')
    }
    render() {
        const { location } = this.props
        const finalPath = this.uploadDocPath()
        return (
            <main className="admin-container">
                {!isAdmin ? (
                    <Redirect to="/library" />
                ) : location.pathname === '/admin/createUser' || finalPath === '/admin/createUser/edit' ? (
                    <CreateUser
                        fetchExpertTags={this.props.fetchExpertTags}
                        data={this.props.data}
                        fetchUserType={this.props.fetchUserType}
                        userTypeData={this.props.userTypeData}
                        fetchDesignationTags={this.props.fetchDesignationTags}
                        designationData={this.props.designationData}
                        createUserAdmin={this.props.createUserAdmin}
                        authO={this.props.authO}
                        createUserData={this.props.createUserData}
                        createUserTicket={this.props.createUserTicket}
                        verificationEmail={this.props.verificationEmail}
                        status={this.props.status}
                        addUserBackend={this.props.addUserBackend}
                        backendUserData={this.props.backendUserData}
                        fetchUserData={this.props.fetchUserData}
                        editUserData={this.props.editUserData}
                        userData={this.props.userData}
                        isEditUserData={this.props.isEditUserData}
                        getUserEmail={this.props.getUserEmail}
                        userEmails={this.props.userEmails}
                    />
                ) : finalPath === '/admin/edit' ? (
                    <UploadDocument
                        upload={this.props.upload}
                        isLoading={this.props.isLoading}
                        fetchTags={this.props.fetchTags}
                        data={this.props.data}
                        fetchDocDetails={this.props.fetchDocDetails}
                        docdetails={this.props.docdetails}
                        deleteDoc={this.props.deleteDoc}
                        replaceDocDetails={this.props.replaceDocDetails}
                        newdocdetails={this.props.newdocdetails}
                        deleteData={this.props.deleteData}
                    />
                ) : location.pathname === '/admin/documents' ? (
                    <Documents
                        fetchExtDocuments={this.props.fetchExtDocuments}
                        extDocData={this.props.extDocData}
                        adminDocumentLoadMore={this.props.adminDocumentLoadMore}
                        isLoading={this.props.isLoading}
                        toBeExpData={this.props.toBeExpData}
                        expSearch={this.props.expSearch}
                        extendDoc={this.props.extendDoc}
                        extendDocState={this.props.extendDocState}
                        status={this.props.status}
                    />
                ) : location.pathname === '/admin/statistic' ? (
                    <Statistic
                        activeUsers={this.props.activeUsers}
                        userTypeData={this.props.userTypeData}
                        fetchUserType={this.props.fetchUserType}
                        getActiveUsers={this.props.getActiveUsers}
                    />
                ) : location.pathname === '/admin/adminData' ? (
                    <AdminData
                        getUserCount={this.props.getUserCount}
                        userCount={this.props.userCount}
                        adminSessionData={this.props.adminSessionData}
                        getAdminTableData={this.props.getAdminTableData}
                        blockUnblockUser={this.props.blockUnblockUser}
                        blockUnblockData={this.props.blockUnblockData}
                        getAdminTableDataLoadMore={this.props.getAdminTableDataLoadMore}
                        isLoading={this.props.isLoading}
                        isAdminDataLoading={this.props.isAdminDataLoading}
                        getDownloadLimit={this.props.getDownloadLimit}
                        setDownloadLimit={this.props.setDownloadLimit}
                        downloadLimitData={this.props.downloadLimitData}
                        downloadLimitStatus={this.props.downloadLimitStatus}
                        getAdminUserCount={this.props.getAdminUserCount}
                        adminUser={this.props.adminUser}
                        deleteUser={this.props.deleteUser}
                        isListUpdated={this.props.isListUpdated}
                        resetStatus={this.props.resetStatus}
                        getActiveUsers={this.props.getActiveUsers}
                        activeUsers={this.props.activeUsers}
                        fetchUserType={this.props.fetchUserType}
                        userTypeData={this.props.userTypeData}
                        isCstVisible={this.props.isCstVisible}
                        showCst={this.props.showCst}
                        hideCst={this.props.hideCst}
                    />
                ) : location.pathname === '/admin/videos' ? (
                    <VideoData
                        appWidth={this.props.appWidth}
                        isLoading={this.props.isLoading}
                        reloadVideoContent={this.props.reloadVideoContent}
                        closeVideoUpload={this.props.closeVideoUpload}
                        userVideos={this.props.userVideos}
                        uploadVideo={this.props.uploadVideo}
                        getAdminVideo={this.props.getAdminVideo}
                        deleteAdminVideo={this.props.deleteAdminVideo}
                        resetAdminVideo={this.props.resetAdminVideo}
                    />
                ) : (
                    <UploadDocument
                        upload={this.props.upload}
                        isLoading={this.props.isLoading}
                        fetchTags={this.props.fetchTags}
                        data={this.props.data}
                        uploadData={this.props.uploadData}
                    />
                )}
            </main>
        )
    }
}

export default Admin
