import React, { Component } from 'react'
import { SITE_TXT, EMAIL_PATTERN_DOMAIN, isMobile, isIphoneX, isAdmin } from '../../Utils/constants'
import { getFileExt, isEmpty, isValidPattern } from '../../Utils/functions'
import { MailtoIcon, PencilIcon, DownloadIcon, DeleteBinIcon } from '../../Utils/svg'
import InfiniteScroll from 'react-infinite-scroller'
import Modal from 'react-responsive-modal'
import Inputbox from '../Inputbox'
import ReactSlider from '../ReactSlider'
import FileModal from './FileModal'
import { ConfirmAlertBox } from '../../Containers/Library/Alerts'
import { getPrefixedUrl } from '../../Utils/apis'

import Checkbox from '../Checkbox'

class GetStarted extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {},
            documentsData: {},
            selected: {},
            selectAll: 0,
            open: false,
            openForDeletion: false,
            openForEmail: false,
            email: '',
            submitted: false,
            emaiInProgress: false,
            emailSuccess: false,
            selectContentName: '',
            selectedContentExt: '',
            downloadDoc: false,
            downloadId: '',
            search: '',
            pageNumber: 1,
            pageSize: 20,
            sortColumn: 'contentName',
            sortType: 'ASC',
            isLoading: false,
            isEditMode: false,
            name: '',
            imageUrl: '',
            requestType: localStorage.getItem('requestType'),
            isListUpdated: this.props.isListUpdated,
            docId: '',
            ondeleteSuccessStatus: false,
        }
    }
    closeForEmail = () => {
        this.setState({ openForEmail: false })
    }
    componentDidMount() {
        let data = {
            search: this.state.search,
            pageSize: isIphoneX || window.innerWidth <= 767 ? 100000 : this.state.pageSize,
            sortType: this.state.sortType,
            sortColumn: this.state.sortColumn,
            pageNumber: 1,
            requestType: this.state.requestType,
        }
        this.props.lopGetStartedItems(data)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { data } = nextProps
        this.setState(
            (state, props) => {
                return {
                    documentsData: data, //nextProps.data,
                    isLoading: nextProps.isLoading,
                    isListUpdated: nextProps.isListUpdated,
                    ondeleteSuccessStatus: false,
                }
            },
            () => {
                if (this.state.isListUpdated) {
                    this.setState(
                        {
                            pageNumber: 1,
                        },
                        () => {
                            let data = {
                                search: this.state.search,
                                pageSize: isIphoneX || window.innerWidth <= 767 ? 100000 : this.state.pageSize,
                                sortType: this.state.sortType,
                                sortColumn: this.state.sortColumn,
                                pageNumber: this.state.pageNumber,
                                requestType: this.state.requestType,
                            }

                            this.props.lopGetStartedItems(data)
                            this.setState({
                                selectAll: 0,
                                selected: {},
                            })
                        }
                    )
                }
            }
        )

        if (this.state.downloadDoc === true) {
            if (nextProps.downloadDocumentsData !== null) {
                const { downloadDocumentsData } = nextProps
                if (downloadDocumentsData.result && downloadDocumentsData.result.fileURL) {
                    this.setState({ downloadDoc: false, downloadId: '' })
                    this.props.saveDocuments(getPrefixedUrl(downloadDocumentsData.result.fileURL))
                }
            }
        }
    }

    componentDidUpdate() {
        if (this.state.selectAll !== 0 && Object.keys(this.state.selected).length === 0) {
            this.setState({ selectAll: 0 })
        }
    }

    toggleRow(id) {
        const newSelected = Object.assign({}, this.state.selected)
        newSelected[id] = !this.state.selected[id]
        if (newSelected[id] === false) {
            delete newSelected[id]
        }

        this.state.documentsData.data.forEach(x => {
            const selVals = Object.keys(newSelected)
            const newids = selVals.join(',')

            if (newids === x.id) {
                this.setState({
                    selectContentName: x.contentName,
                    selectedContentExt: x.contentExtension,
                })
            }
        })
        this.setState({
            selected: newSelected,
            selectAll: 2,
        })
    }

    toggleSelectAll() {
        let newSelected = {}
        if (this.state.selectAll === 0) {
            this.state.documentsData.data.forEach(x => {
                newSelected[x.id] = true
            })
        }

        this.setState({
            selected: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0,
        })
    }

    handleChange = (field, value) => {
        this.setState({ email: { ...this.state.email, [field]: value } }, () => {})
    }

    onCloseModal = () => {
        this.setState({ open: false, isEditMode: false, name: '' })
    }
    onCloseDeletionModal = () => {
        this.setState({ openForDeletion: false })
    }

    onOpenDeletionModal = () => {
        this.setState({ openForDeletion: true })
    }

    openForEmail() {
        this.setState({ openForEmail: true })
    }
    onOpenModal = file => {
        this.setState(
            {
                isEditMode: file && file.contentFormatIcon ? true : false,
                name: file && file.contentName ? file.contentName : '',
                docId: file && file.id ? file.id : '',
                imageUrl: file && file.imageUrl ? file.imageUrl : '',
            },
            () => {
                this.setState({ open: true })
            }
        )
    }

    showSortIcon(colid) {
        return this.state.sortColumn === colid ? this.state.sortType.toLowerCase() : ''
    }

    sortDocuments = col => {
        this.setState(
            {
                pageNumber: 1,
                sortType: this.state.sortType === 'ASC' ? 'DESC' : 'ASC',
                sortColumn: col,
                isLoading: true,
            },
            () => {
                let data = {
                    search: this.state.search,
                    pageSize: isIphoneX || window.innerWidth <= 767 ? 100000 : this.state.pageSize,
                    sortType: this.state.sortType,
                    sortColumn: this.state.sortColumn,
                    pageNumber: this.state.pageNumber,
                    requestType: this.state.requestType,
                }
                this.props.lopGetStartedItems(data)
            }
        )
    }

    loadMoreDocuments() {
        if (!this.state.isLoading) {
            this.setState(
                {
                    pageNumber: this.state.pageNumber + 1,
                    isLoading: true,
                },
                () => {
                    const data = {
                        pageNumber: this.state.pageNumber,
                        search: this.state.search,
                        pageSize: this.state.pageSize,
                        sortType: this.state.sortType,
                        sortColumn: this.state.sortColumn,
                        requestType: this.state.requestType,
                    }
                    this.props.lopGetStartedItemsMore(data)
                }
            )
        }
    }

    searchDocuments = (field, value) => {
        clearTimeout(this.delayTimer)
        this.delayTimer = setTimeout(() => {
            this.setState({ search: value, pageNumber: 1 }, () => {
                let data = {
                    search: this.state.search,
                    pageSize: isIphoneX || window.innerWidth <= 767 ? 100000 : this.state.pageSize,
                    sortType: this.state.sortType,
                    sortColumn: this.state.sortColumn,
                    pageNumber: this.state.pageNumber,
                    requestType: this.state.requestType,
                }
                this.props.lopGetStartedItems(data)
            })
        }, 1000)
    }

    deleteDoc = id => {
        this.setState({ ondeleteSuccessStatus: false })
        const title = SITE_TXT.TXT_CONFIRM_DELETE
        const message = SITE_TXT.TXT_SURE_DELETE_DOCUMENT
        const onYesTrash = () => this.trashFinally(id)
        ConfirmAlertBox(title, message, onYesTrash)
    }

    trashFinally = id => {
        this.setState({ ondeleteSuccessStatus: true })
        //this.onCloseModal()
        const data = []
        if (!isNaN(id)) {
            data.push(id)
        } else if (this.state.selected) {
            const { selected } = this.state
            for (const key in selected) {
                data.push(key)
            }
        }

        const obj = { items: data.join(), requestType: this.state.requestType }
        this.setState({ deleteDocStatus: true }, () => {
            this.props.lopGetStartedItemsDelete(obj)
        })
    }

    downloadDocs() {
        /**Enable this for implementing the download document */
        const selVals = Object.keys(this.state.selected)
        const ids = selVals.join(',')
        const data = {
            requestType: 'lop',
            ids,
        }
        this.setState({ downloadDoc: true }, () => this.props.lopGoldDownloadItems(data))
    }

    emailDocs() {
        const selVals = Object.keys(this.state.selected)
        const ids = selVals.join(',')
        this.setState({ submitted: true }, () => {
            const { email } = this.state
            if (email && isValidPattern(email['email'], EMAIL_PATTERN_DOMAIN)) {
                const data = {
                    requestType: 'lop',
                    emailId: email['email'].toLowerCase(),
                    ids: ids,
                }

                this.props.lopGoldEmailItems(data)
                this.setState({ emailSuccess: true, openForEmail: false })
            }
        })
    }

    render() {
        const { documentsData, isEditMode, submitted, email, name, imageUrl, open } = this.state

        const {
            TXT_LOADING,
            TXT_FILE_FORMAT,
            TXT_CANCEL,
            TXT_SUBMIT,
            TXT_EMAIL_DOCUMENTS,
            TXT_EMAIL,
            TXT_EMAIL_REQUIRED,
            TXT_DOWNLOAD,
            TXT_EMAILADDRESS,
            TXT_EMAIL_INVALID,
            TXT_FILENAME,
            TXT_DELETE_NEW,
            TXT_DOWNLOAD_COUNT,
            TXT_NO_DOCUMENT_AVAILABLE,
            TXT_SHOWING,
            TXT_DOCUMENTS,
            TXT_SEARCHBY_DOCNAME,
            TXT_ADD_NEW_FILE,
        } = SITE_TXT

        const selectAllCheckboxComp = label => (
            <div className="checkbox-wrapper selectall-chk" onClick={() => this.toggleSelectAll()}>
                <Checkbox
                    checked={this.state.selectAll === 1}
                    isIndeterminate={this.state.selectAll === 2}
                    label={label}
                />
            </div>
        )

        const rows = documents =>
            documents.map(item => (
                <tr key={item.id}>
                    <td className="checkboxtd">
                        <div className="checkbox-wrapper" onClick={() => this.toggleRow(item.id)}>
                            <Checkbox itemId={item.id} checked={this.state.selected[item.id] === true} />
                        </div>
                    </td>
                    <td>{item.contentName}</td>
                    <td>{item.downloadCount}</td>
                    <td>
                        <span className={'extBox ' + getFileExt(item.contentFormatIcon)}>
                            {getFileExt(item.contentFormatIcon).toUpperCase()}
                        </span>
                    </td>
                    {isAdmin && (
                        <td>
                            <span onClick={() => this.onOpenModal(item)} className="addEditIcon">
                                <PencilIcon />
                            </span>
                        </td>
                    )}
                </tr>
            ))

        const dotSlider = {
            dots: true,
            arrows: false,
            slidesToShow: 1,
            rows: 4,
            slidesToScroll: 1,
            infinite: false,
            speed: 500,
            className: 'custom-slider',
        }

        let lengthTable =
            this.state.documentsData && this.state.documentsData.data ? this.state.documentsData.data.length : 0
        let totalRecords =
            this.state.documentsData && this.state.documentsData.data ? this.state.documentsData.totalRecords : 0

        return (
            <div>
                <div className="above-table-section">
                    <div className="search-list-section">
                        <Inputbox
                            placeholder={TXT_SEARCHBY_DOCNAME}
                            type="text"
                            fieldFor="search"
                            onChange={this.searchDocuments}
                        />
                        {documentsData && documentsData.data && (
                            <div className="showing-records">
                                {TXT_SHOWING}
                                <span className="total-rec">
                                    {' '}
                                    {documentsData.data.length}/{documentsData.totalRecords} {TXT_DOCUMENTS}
                                </span>
                            </div>
                        )}
                    </div>

                    {!isMobile && !isIphoneX && isAdmin && (
                        <span className="txt-upper txt-blue action-icon" onClick={() => this.onOpenModal()}>
                            + {TXT_ADD_NEW_FILE}
                        </span>
                    )}
                </div>

                {totalRecords > 0 && !isEmpty(this.state.selected) ? (
                    <div className="actionBtns">
                        <button className="btn btn-primary" onClick={() => this.openForEmail()}>
                            <span>
                                <MailtoIcon width="16" height="16" />
                                {TXT_EMAIL}
                            </span>
                        </button>
                        <button className="btn btn-primary" onClick={() => this.downloadDocs()}>
                            <span>
                                <DownloadIcon width="16" height="16" />
                                {TXT_DOWNLOAD}
                            </span>
                        </button>
                        {!isMobile && !isIphoneX && isAdmin && (
                            <button className="btn btn-primary dwn" onClick={this.deleteDoc.bind(this)}>
                                <span>
                                    <DeleteBinIcon width="16" height="16" />
                                    {TXT_DELETE_NEW}
                                </span>
                            </button>
                        )}
                        <Modal
                            open={this.state.openForEmail}
                            onClose={() => this.closeForEmail()}
                            center
                            classNames={{
                                modal: 'comp-modal library-modal',
                            }}
                        >
                            <h2 className="heading-class">{TXT_EMAIL_DOCUMENTS}</h2>
                            <form>
                                <div className={'form-group' + (submitted && !email ? ' has-error' : '')}>
                                    <label htmlFor="email" className="inputlabels">
                                        {TXT_EMAILADDRESS}
                                    </label>
                                    <Inputbox
                                        type="email"
                                        fieldFor="email"
                                        placeholder="Enter email address here..."
                                        onChange={(field, value) => this.handleChange(field, value)}
                                        customClass="email"
                                    />
                                    {submitted && !email && <div className="help-block">{TXT_EMAIL_REQUIRED}</div>}
                                    {submitted && email && !isValidPattern(email['email'], EMAIL_PATTERN_DOMAIN) && (
                                        <div
                                            className="help-block "
                                            style={{
                                                color: '#d84315',
                                            }}
                                        >
                                            {TXT_EMAIL_INVALID}
                                        </div>
                                    )}
                                </div>

                                <div className="butClass ">
                                    <button
                                        type="button"
                                        className="btn btn-default cancel-Btn"
                                        onClick={this.closeForEmail}
                                    >
                                        {TXT_CANCEL}
                                    </button>
                                    <button onClick={() => this.emailDocs()} type="button" className="btn btn-primary">
                                        {TXT_SUBMIT}
                                    </button>
                                </div>
                            </form>
                        </Modal>
                    </div>
                ) : null}
                {this.state.documentsData && this.state.documentsData.totalRecords > 0 ? (
                    !isMobile && !isIphoneX ? (
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={() => this.loadMoreDocuments()}
                            hasMore={lengthTable < totalRecords}
                            loader={
                                <div className="loader" key={0}>
                                    {TXT_LOADING}
                                </div>
                            }
                            useWindow={true}
                        >
                            <table className="table custom-table">
                                <thead>
                                    <tr>
                                        <th id="doc_check" className="checkboxtd">
                                            {selectAllCheckboxComp()}
                                        </th>

                                        <th
                                            id="contentName"
                                            className={this.showSortIcon('contentName')}
                                            onClick={() => this.sortDocuments('contentName')}
                                        >
                                            {TXT_FILENAME}
                                        </th>
                                        <th
                                            id="downloadCount"
                                            className={this.showSortIcon('downloadCount')}
                                            onClick={() => this.sortDocuments('downloadCount')}
                                        >
                                            {TXT_DOWNLOAD_COUNT}
                                        </th>
                                        <th
                                            id="contentFormat"
                                            className={this.showSortIcon('contentFormat')}
                                            onClick={() => this.sortDocuments('contentFormat')}
                                        >
                                            {TXT_FILE_FORMAT}
                                        </th>
                                        {isAdmin && <th>&nbsp;</th>}
                                    </tr>
                                </thead>
                                <tbody>{rows(documentsData.data)}</tbody>
                            </table>
                        </InfiniteScroll>
                    ) : (
                        // Mobile View of the table
                        <div className="tab-list">
                            {selectAllCheckboxComp(SITE_TXT.TXT_SELECTALL)}

                            <ReactSlider
                                listId={1}
                                topCategoryName={'test'}
                                listData={documentsData.data}
                                settings={dotSlider}
                                noImage={true}
                                isPhaseList={false}
                                toggleRow={id => this.toggleRow(id)}
                                selected={this.state.selected}
                            />
                        </div>
                    )
                ) : (
                    <div className="no-result">{TXT_NO_DOCUMENT_AVAILABLE}</div>
                )}
                {open && (
                    <FileModal
                        isEditMode={isEditMode}
                        name={name}
                        imageUrl={imageUrl}
                        open={open}
                        onCloseModal={this.onCloseModal}
                        lopAddItem={this.props.lopAddItem}
                        requestType={this.state.requestType}
                        docId={this.state.docId}
                        lopEditItem={this.props.lopEditItem}
                        addEditStatus={this.props.addEditStatus}
                        onLopDelete={this.deleteDoc}
                        onLopDeleteSuccess={this.state.ondeleteSuccessStatus}
                    />
                )}
            </div>
        )
    }
}

export default GetStarted
