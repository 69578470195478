import { createSelector } from 'reselect'

const selectLop = state => state.get('lop')

const selectData = () => createSelector(selectLop, lop => lop.toJS()['data'])

const selectLoading = () => createSelector(selectLop, lop => lop.toJS()['isLoading'])

const selectStatus = () => createSelector(selectLop, lop => lop.toJS()['status'])

const documentsData = () => createSelector(selectLop, lop => lop.toJS()['documentsData'])

const isGoldLoading = () => createSelector(selectLop, lop => lop.toJS()['isGoldLoading'])
const addEditStatus = () => createSelector(selectLop, lop => lop.toJS()['addEditStatus'])

const isListUpdated = () => createSelector(selectLop, lop => lop.toJS()['isListUpdated'])

const foldersList = () => createSelector(selectLop, lop => lop.toJS()['foldersList'])

const downloadDocumentsData = () => createSelector(selectLop, lop => lop.toJS()['downloadDocumentsData'])
const emailDocumentsData = () => createSelector(selectLop, lop => lop.toJS()['emailDocumentsData'])
const selectIndustryTags = () => createSelector(selectLop, lop => lop.toJS()['industryTags'])

const selectTopicsKeywordsTags = () => createSelector(selectLop, lop => lop.toJS()['topicsKeywordsTags'])

const selectLopGeography = () => createSelector(selectLop, lop => lop.toJS()['geographyData'])
const selectLopDealType = () => createSelector(selectLop, lop => lop.toJS()['dealTypeData'])
const selectLopDealSize = () => createSelector(selectLop, lop => lop.toJS()['dealSizeData'])

const editItemData = () => createSelector(selectLop, lop => lop.toJS()['editItemData'])
const isListUpdatedForAdd = () => createSelector(selectLop, lop => lop.toJS()['isListUpdatedForAdd'])

const expertData = () => createSelector(selectLop, lop => lop.toJS()['expertData'])

const lopTags = () => createSelector(selectLop, lop => lop.toJS()['lopTags'])

const fetchedCv = () => createSelector(selectLop, lop => lop.toJS()['fetchedCv'])
const selectLopRegionBase = () => createSelector(selectLop, lop => lop.toJS()['selectLopRegionBase'])

const selectExpIndustryTags = () => createSelector(selectLop, lop => lop.toJS()['selectExpIndustryTags'])
const selectExpRoleTags = () => createSelector(selectLop, lop => lop.toJS()['selectExpRoleTags'])
const selectExpTopicsTags = () => createSelector(selectLop, lop => lop.toJS()['selectExpTopicsTags'])

const isLoadingFetch = () => createSelector(selectLop, lop => lop.toJS()['isLoadingFetch'])
const countryCode = () => createSelector(selectLop, lop => lop.toJS()['countryCode'])
const uploadLopStatus = () => createSelector(selectLop, lop => lop.toJS()['uploadLopStatus'])

export {
    selectStatus,
    selectLoading,
    selectData,
    documentsData,
    isGoldLoading,
    addEditStatus,
    isListUpdated,
    isListUpdatedForAdd,
    foldersList,
    emailDocumentsData,
    downloadDocumentsData,
    expertData,
    lopTags,
    selectIndustryTags,
    selectTopicsKeywordsTags,
    selectLopGeography,
    selectLopDealType,
    selectLopDealSize,
    editItemData,
    fetchedCv,
    selectLopRegionBase,
    selectExpIndustryTags,
    selectExpRoleTags,
    selectExpTopicsTags,
    isLoadingFetch,
    countryCode,
    uploadLopStatus,
}
