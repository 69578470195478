import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import './index.css'
import { dataURLtoFile } from '../../Utils/functions'

class ImageResizer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            crop: props.crop,
            croppedImageUrl: '',
        }
    }

    componentDidUpdate(nextProps) {
        const { imgDataUrl } = this.props
        if (nextProps.imgDataUrl !== imgDataUrl) {
            this.onCropComplete(this.state.crop)
        }
    }

    onCropChange = crop => this.setState({ crop })

    onCropComplete = crop => this.makeClientCrop(crop)

    async makeClientCrop(crop) {
        const { imgDataUrl } = this.props
        if (imgDataUrl && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(imgDataUrl, crop, 'newFile.jpeg')

            this.setState({ croppedImageUrl })
        }
    }

    getCroppedImg(imgDataUrl, pixelCrop, fileName) {
        const { crossOrigin = 'anonymous', getResizedImg, quality } = this.props
        let image = new Image()

        image.src = imgDataUrl
        image.crossOrigin = crossOrigin
        const targetX = (image.width * pixelCrop.x) / 100
        const targetY = (image.height * pixelCrop.y) / 100
        const targetWidth = (image.width * pixelCrop.width) / 100
        const targetHeight = (image.height * pixelCrop.height) / 100

        const canvas = document.createElement('canvas')
        canvas.width = targetWidth
        canvas.height = targetHeight
        const ctx = canvas.getContext('2d')
        ctx.drawImage(image, targetX, targetY, targetWidth, targetHeight, 0, 0, targetWidth, targetHeight)

        let croppedImgUrl = canvas.toDataURL('image/jpeg', quality)
        getResizedImg(dataURLtoFile(croppedImgUrl, fileName))
    }

    render() {
        const { imgDataUrl } = this.props
        const { crop } = this.state
        return (
            <ReactCrop
                keepSelection
                minWidth={30}
                src={imgDataUrl}
                crop={crop}
                onChange={this.onCropChange}
                onComplete={this.onCropComplete}
                className="reactCropcustomStyle"
            />
        )
    }
}

ImageResizer.defaultProps = {
    crossOrigin: '',
    quality: 1,
}

ImageResizer.propTypes = {
    quality: PropTypes.number,
    crossOrigin: PropTypes.string,
    imgDataUrl: PropTypes.string.isRequired,
    crop: PropTypes.instanceOf(Object).isRequired,
    getResizedImg: PropTypes.func.isRequired,
}

export default ImageResizer
