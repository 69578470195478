import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import {
    selectStatus,
    selectLoading,
    selectError,
    selectData,
    userTypeData,
    extDocData,
    designationData,
    toBeExpData,
    extendDocState,
    createUserData,
    ticket,
    backendUserData,
    docdetails,
    newdocdetails,
    deleteData,
    uploadData,
    userCount,
    adminSessionData,
    blockUnblockData,
    isEditUserData,
    userData,
    downloadLimitData,
    downloadLimitStatus,
    getUserEmails,
    getUserVideos,
    getUserVideoModalStatus,
    getUserVideoStatus,
    adminUser,
    isListUpdated,
    activeUsers,
    lopStats,
} from './selector'
import { selectWidth } from '../App/selector'
import { Admin } from '../../Components'

import {
    uploadAdmin,
    fetchTags,
    fetchExpertTags,
    fetchUserType,
    fetchExtDocuments,
    fetchDesignationTags,
    createUserAdmin,
    adminDocumentLoadMore,
    expSearch,
    extendDoc,
    createUserTicket,
    verificationEmail,
    addUserBackend,
    fetchDocDetails,
    replaceDocDetails,
    deleteDoc,
    getUserCount,
    getAdminTableData,
    blockUnblockUser,
    getAdminTableDataLoadMore,
    fetchUserData,
    editUserData,
    getDownloadLimit,
    setDownloadLimit,
    getUserEmail,
    uploadVideo,
    getAdminVideo,
    deleteAdminVideo,
    resetAdminVideo,
    getAdminUserCount,
    deleteUser,
    resetStatus,
    getActiveUsers,
    getLopStats,
} from './actions'

const mapStateToProps = createStructuredSelector({
    isLoading: selectLoading(),
    status: selectStatus(),
    error: selectError(),
    data: selectData(),
    designationData: designationData(),
    userTypeData: userTypeData(),
    extDocData: extDocData(),
    toBeExpData: toBeExpData(),
    extendDocState: extendDocState(),
    createUserData: createUserData(),
    ticket: ticket(),
    backendUserData: backendUserData(),
    docdetails: docdetails(),
    newdocdetails: newdocdetails(),
    deleteData: deleteData(),
    uploadData: uploadData(),
    userCount: userCount(),
    adminSessionData: adminSessionData(),
    blockUnblockData: blockUnblockData(),
    userData: userData(),
    isEditUserData: isEditUserData(),
    downloadLimitData: downloadLimitData(),
    downloadLimitStatus: downloadLimitStatus(),
    userEmails: getUserEmails(),
    userVideos: getUserVideos(),
    closeVideoUpload: getUserVideoModalStatus(),
    reloadVideoContent: getUserVideoStatus(),
    appWidth: selectWidth(),
    adminUser: adminUser(),
    isListUpdated: isListUpdated(),
    activeUsers: activeUsers(),
    lopStats: lopStats(),
})
const mapDispatchToProps = {
    upload: uploadAdmin,
    fetchTags: fetchTags,
    fetchExpertTags: fetchExpertTags,
    fetchUserType: fetchUserType,
    fetchExtDocuments: fetchExtDocuments,
    fetchDesignationTags: fetchDesignationTags,
    createUserAdmin: createUserAdmin,
    adminDocumentLoadMore: adminDocumentLoadMore,
    expSearch: expSearch,
    extendDoc: extendDoc,
    createUserTicket: createUserTicket,
    verificationEmail: verificationEmail,
    addUserBackend: addUserBackend,
    fetchDocDetails: fetchDocDetails,
    replaceDocDetails: replaceDocDetails,
    deleteDoc: deleteDoc,
    getUserCount: getUserCount,
    getAdminTableData: getAdminTableData,
    blockUnblockUser: blockUnblockUser,
    getAdminTableDataLoadMore: getAdminTableDataLoadMore,
    fetchUserData: fetchUserData,
    editUserData: editUserData,
    getDownloadLimit: getDownloadLimit,
    setDownloadLimit: setDownloadLimit,
    getUserEmail,
    uploadVideo,
    getAdminVideo,
    deleteAdminVideo,
    resetAdminVideo,
    getAdminUserCount,
    deleteUser,
    resetStatus,
    getActiveUsers,
    getLopStats,
}
export default connect(mapStateToProps, mapDispatchToProps)(Admin)
