import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import PropTypes from 'prop-types'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import Modal from 'react-responsive-modal'
import 'react-image-crop/dist/ReactCrop.css'
import { Button } from 'reactstrap'
import c from 'classnames'

import { selectMyLopList, selectLoading } from '../../../Containers/LopContent/selector'
import { copyMoveSection } from '../../../Containers/LopContent/actions'
import Loader from '../../Loader'

import { SITE_TXT } from '../../../Utils/constants'
import './index.scss'

class CopySection extends Component {
    state = {
        selectedLop: null,
        dropDownOpen: false,
    }

    updateSectionImage = () => {
        const { currentLop, sectionContent, onClose, copyMoveSection } = this.props
        const { selectedLop } = this.state
        const { id: myLopId } = selectedLop
        const { sectionId } = sectionContent
        let contentToUse = null
        if (currentLop === myLopId) {
            contentToUse = sectionContent
        }
        copyMoveSection({ sectionId, myLopId }, contentToUse)
        onClose(false)
    }

    toggleLopList = () => {
        const { dropDownOpen } = this.state
        this.setState({ dropDownOpen: !dropDownOpen })
    }

    setCurrenLopInfo = selectedLop => {
        this.setState({ selectedLop })
    }

    render() {
        const { onClose, myLopData, loading } = this.props
        const { dropDownOpen, selectedLop } = this.state
        const { data } = myLopData
        const styleClasses = {
            modal: 'albumModal copySectionModal',
            overlay: 'overlayAlbumModal',
            closeButton: 'closeIcon',
        }
        return (
            <Modal open onClose={() => onClose(false)} center classNames={styleClasses} closeOnOverlayClick={false}>
                <h2>{SITE_TXT.COPY_PAGE}</h2>
                <p>{SITE_TXT.COPY_PAGE_TEXT}</p>
                <h3>{SITE_TXT.SELECT_LOP}</h3>
                <div className={c('dropDownContainer', { selectedLop })}>
                    <Dropdown isOpen={dropDownOpen} toggle={this.toggleLopList}>
                        <DropdownToggle caret>{!selectedLop ? SITE_TXT.SELECT_LOP : selectedLop.name}</DropdownToggle>
                        <DropdownMenu left={'true'}>
                            {loading ? (
                                <div className="loaderLopList">
                                    <Loader />
                                </div>
                            ) : (
                                <div className="mainLopList">
                                    {data.map((sectionInfo, i) => (
                                        <DropdownItem
                                            key={sectionInfo.id}
                                            onClick={() => this.setCurrenLopInfo(data[i])}
                                        >
                                            {sectionInfo.name}
                                        </DropdownItem>
                                    ))}
                                </div>
                            )}
                        </DropdownMenu>
                    </Dropdown>
                </div>

                <div className="buttonContent">
                    <span onClick={() => onClose(false)} className="btn btn-outline-secondary btn-lg">
                        {SITE_TXT.TXT_CANCEL_ADMIN}
                    </span>
                    <Button
                        disabled={!selectedLop}
                        className="btn btn-primary btn-lg"
                        onClick={this.updateSectionImage}
                    >
                        {SITE_TXT.TXT_DONE}
                    </Button>
                </div>
            </Modal>
        )
    }
}

CopySection.propTypes = {
    onClose: PropTypes.func.isRequired,
    copyMoveSection: PropTypes.func.isRequired,
    currentLop: PropTypes.string.isRequired,
    myLopData: PropTypes.instanceOf(Object).isRequired,
    sectionContent: PropTypes.instanceOf(Object).isRequired,
    loading: PropTypes.bool.isRequired,
}

const stateToProps = createStructuredSelector({
    myLopData: selectMyLopList(),
    loading: selectLoading(),
})

const dispatchToProps = { copyMoveSection }

export default connect(stateToProps, dispatchToProps)(CopySection)
