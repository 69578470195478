import React, { Component } from 'react'
import * as Constant from '../../Containers/Admin/constant'
import ReactSelect from 'react-select'

class Tags extends Component {
    constructor(props) {
        super(props)
        this.state = {
            spinnerState: false,
            user: JSON.parse(JSON.stringify(Constant.USER_INITAL_STATE)),
            options: [],
            value: [],
            lastTagTyped: '',
            userType: '',
            placeholder: this.props.placeholder || 'Enter Tags here',
            customNewClass: this.props.customNewClass,
        }
    }

    render() {
        return (
            <ReactSelect
                isDisabled={this.props.isDisabled}
                isMulti
                searchable={true}
                options={this.props.options}
                labelKey="label"
                valuekey="value"
                placeholder={this.state.placeholder}
                onInputChange={this.props.onInputChange}
                onChange={this.props.onChange}
                onKeyDown={this.props.onKeyDown}
                ref={this.props.refValue}
                className={`tags-validation  ${this.state.customNewClass ? this.state.customNewClass : ''}`}
            />
        )
    }
}

export default Tags
