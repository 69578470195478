import React, { Component } from 'react'
import ThumbnailEditorModal from '../ThumbnailEditorModal'
import { isValidPattern, hasAnyOfWords, getStripTagsText } from '../../Utils/functions'
import ReactSlider from '../ReactSlider'
import ThumbnailComp from '../ThumbnailComp'
import { Link } from 'react-router-dom'

import './index.css'
import { IMAGE_CROP_OBJ_SQUARE, IMAGE_CROP_OBJ_RECT, isMobile, isAdmin } from '../../Utils/constants'
import { TickIconNew, DeleteIcon, PlusIconNew } from '../../Utils/svg'
class ThumbnailList extends Component {
    state = {
        listId: this.props.topCategoryId,
        topCategoryName: this.props.topCategoryName,
        listData: this.props.listData,
        isEditMode: this.props.isEditMode,
        isModalOpen: this.props.isModalOpen,
        showSlider: this.props.showSlider,
        dragIndex: null,
        dropIndex: null,
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let isEdit = nextProps.isEditMode
        if (isEdit !== this.state.isEditMode) {
            this.setState({ isEditMode: isEdit })
        }

        let isOpen = nextProps.isModalOpen
        if (isOpen !== this.state.isModalOpen) {
            this.setState({ isModalOpen: isOpen })
        }
        this.setState({ listData: nextProps.listData })
    }

    handleSave = (thumbnailId, formTosave, imageTosave) => {
        const formName = formTosave.titleThumb.value.replace(/\n|\r/g, ' ').trim()
        if (formName.trim() === '') {
            alert('Please enter a valid subsection text')
            return
        }
        if (!isValidPattern(formName)) {
            alert('Invalid characters in the name\nIt should not contain any of (/:*?"<>|+$)@#%^&\',.[]{} ~`')
            return
        }
        const data = new FormData()
        const name = formName.replace(/\n|\r/g, ' ').trim()
        data.append('name', name)
        data.append('image', imageTosave)
        data.append('libraryId', this.state.listId)
        this.props.add(data)
        this.props.closeThumbnailModal()
    }
    setDragIndex(dragIndex) {
        this.setState({ dragIndex })
    }
    setDropIndex(dropIndex) {
        const { listData, dragIndex } = this.state
        const newListData = JSON.parse(JSON.stringify(listData))
        newListData.splice(dropIndex, 0, newListData.splice(dragIndex, 1)[0])
        this.setState({
            listData: newListData,
            dragIndex: null,
            dropIndex: null,
        })
    }

    render() {
        const { listId, topCategoryName, listData, isEditMode, isModalOpen, showSlider } = this.state

        const dotSliderMobile = {
            dots: true,
            arrows: false,
            slidesToShow: 1,
            rows: 3,
            slidesToScroll: 1,
            infinite: false,
            speed: 500,
            className: 'custom-slider',
        }

        const dotSliderTablet = {
            dots: true,
            arrows: false,
            slidesToShow: 2,
            rows: 3,
            slidesToScroll: 2,
            infinite: false,
            speed: 500,
            className: 'custom-slider',
        }

        const arrowSliderMobile = {
            dots: false,
            arrows: true,
            slidesToShow: 2,
            rows: 2,
            slidesToScroll: 2,
            infinite: false,
            speed: 500,
            className: 'custom-slider',
            nextArrow: <div className={'react-slider-next-arrow'} />,
            prevArrow: <div className={'react-slider-prev-arrow'} />,
        }

        const arrowSliderTablet = {
            dots: false,
            arrows: true,
            slidesToShow: 3,
            rows: 2,
            slidesToScroll: 3,
            infinite: false,
            speed: 500,
            className: 'custom-slider',
            nextArrow: <div className={'react-slider-next-arrow'} />,
            prevArrow: <div className={'react-slider-prev-arrow'} />,
        }

        const dotSlider = {
            dots: true,
            arrows: false,
            slidesToShow: 3,
            rows: 3,
            slidesToScroll: 3,
            infinite: false,
            speed: 500,
            className: 'custom-slider',
            centerPadding: '65px',
            responsive: [
                {
                    breakpoint: 1025,
                    settings: dotSliderTablet,
                },
                {
                    breakpoint: 768,
                    settings: dotSliderMobile,
                },
            ],
        }

        const arrowSlider = {
            dots: false,
            arrows: true,
            slidesToShow: 4,
            rows: 2,
            slidesToScroll: 4,
            infinite: false,
            speed: 500,
            className: 'custom-slider',
            nextArrow: <div className={'react-slider-next-arrow'} />,
            prevArrow: <div className={'react-slider-prev-arrow'} />,
            responsive: [
                {
                    breakpoint: 1025,
                    settings: arrowSliderTablet,
                },
                {
                    breakpoint: 768,
                    settings: arrowSliderMobile,
                },
            ],
        }
        const { isReorderMode } = this.props
        const isDeliver = sessionStorage.getItem('topLevel') === 'Deliver'
        const showActions = isAdmin && !isMobile
        const thumbnailComponent = (thumbnail, index) => (
            <ThumbnailComp
                itemIndex={index}
                key={thumbnail.id}
                categoryId={listId}
                topCategoryName={topCategoryName}
                isEditMode={isEditMode}
                thumbnail={thumbnail}
                edit={this.props.edit}
                trash={this.props.trash}
                isReorderMode={isReorderMode}
                setDragIndex={index => this.setDragIndex(index)}
                setDropIndex={index => this.setDropIndex(index)}
                imageAlignment={isDeliver || listId === '1' ? 'img-left' : 'img-top'}
                editSubSection={this.props.editSubSection}
                fetchDeliverItem={this.props.fetchDeliverItem}
                selectfetchData={this.props.selectfetchData}
            />
        )

        const showComp = thumbnail => {
            const { name, detail } = thumbnail
            return hasAnyOfWords(
                this.props.searchInp.toLowerCase(),
                `${name.trim()} ${getStripTagsText(detail.trim())}`.toLowerCase()
            )
        }

        const thumbnails =
            !isDeliver || (isDeliver && this.props.searchInp.length === 0)
                ? listData.map((thumbnail, index) => thumbnailComponent(thumbnail, index))
                : listData.map((thumbnail, index) => showComp(thumbnail) && thumbnailComponent(thumbnail, index))

        return isReorderMode ? (
            <div className="thumbnail-list-reorderable-wrapper">
                <div className="thumbnail-list-reorderable-list-wrapper">
                    <div className="thumbnail-list-reorderable">{thumbnails}</div>
                </div>
                <div className="actionIcon">
                    <span className="actionIcon tick" onClick={() => this.saveReorder()}>
                        <TickIconNew />
                    </span>
                    <span className="actionIcon" onClick={() => this.props.setReorderMode(false)}>
                        <DeleteIcon />
                    </span>
                </div>
            </div>
        ) : (
            <div className="thumbnail-list">
                {sessionStorage.getItem('topLevel') === 'Library' && <div className="blue-bar" />}
                {showSlider ? (
                    <ReactSlider
                        listId={listId}
                        topCategoryName={topCategoryName}
                        listData={listData}
                        isEditMode={isEditMode}
                        edit={this.props.edit}
                        trash={this.props.trash}
                        imageAlignment={listId === '1' ? 'img-left' : 'img-top'}
                        settings={listId === '1' ? dotSlider : arrowSlider}
                        noImage={false}
                        isPhaseList={false}
                    />
                ) : (
                    thumbnails
                )}
                {isDeliver && showActions && isEditMode && (
                    <Link
                        className="actionIcon"
                        to={{
                            pathname: '/deliver/deliverform/add',
                            state: {
                                listId: { listId },
                            },
                        }}
                    >
                        <span className="kkk" onClick={this.onOpenModal}>
                            <PlusIconNew />
                        </span>
                    </Link>
                )}
                {isModalOpen && (
                    <ThumbnailEditorModal
                        onlyImage={false}
                        isModalOpen={isModalOpen}
                        libraryId={this.state.listId}
                        clickToBrowse={this.clickToBrowse}
                        editCancel={() => this.props.closeThumbnailModal()}
                        handleSave={(id, form, test) => this.handleSave(id, form, test)}
                        imgCropObj={this.state.listId === '1' ? IMAGE_CROP_OBJ_SQUARE : IMAGE_CROP_OBJ_RECT}
                        modalClass="library-modal addLidModal"
                    />
                )}
            </div>
        )
    }
    saveReorder() {
        const { topCategoryId, onReorder, setReorderMode, sectionId } = this.props
        const { listData } = this.state
        setReorderMode(false)
        onReorder({
            libraryId: topCategoryId,
            list: listData,
            sectionId,
        })
    }
}

ThumbnailList.defaultProps = {
    showSlider: true,
    isReorderMode: false,
}

export default ThumbnailList
