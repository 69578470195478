import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import c from 'classnames'
import { withAlert } from 'react-alert'
import ReactTooltip from 'react-tooltip'
import Inputbox from '../../Inputbox'
import {
    getCollaborator,
    searchCollaborator,
    editCollaborator,
    addCollaborators,
    updateCollaborators,
    deleteCollaborators,
    clearSearchCollaborators,
} from '../../../Containers/LopContent/actions'
import { collaboratorList, searchcollaboratorList, isListUpdated } from '../../../Containers/LopContent/selector'
import { createStructuredSelector } from 'reselect'
import { getPrefixedUrl } from '../../../Utils/apis'

import './index.scss'
import { DeleteGreyIcon, TickIconTable } from '../../../Utils/svg'
import { getNamePrefix, getRandonColor } from '../../../Utils/functions'
import { SITE_TXT } from '../../../Utils/constants'

class Collaborator extends Component {
    constructor(props) {
        super(props)
        this.state = {
            headerLabel: ['Collaborators', 'View', 'Edit', 'Share', 'All', ''],
            collaborators: [],
            search: '',
            selected: {},
            isListUpdated: false,
            isDirtySave: true,
        }

        this.inputElement = React.createRef()
    }

    UNSAFE_componentWillReceiveProps = nextProps => {
        const { collaboratorList, isListUpdated } = nextProps
        const test = collaboratorList.map(item => {
            const permissionType = parseInt(item.permissionId)
            const isEdit = permissionType === 2 || permissionType > 3
            const isShare = permissionType > 2
            const isAll = permissionType === 4
            const background = item.background || getRandonColor()
            return {
                ...item,
                isView: true,
                isEdit,
                isShare,
                isAll,
                background,
            }
        })
        if (test.length > 0) {
            this.setState({
                collaborators: test,
                isListUpdated,
            })
        }

        if (isListUpdated) {
            const { id, getCollaborator } = this.props
            getCollaborator({ id })
            this.setState({
                isDirtySave: true,
            })
            this.props.reloadContent(this.props.currentSlide)
        }
    }

    componentDidMount = () => {
        const { id, getCollaborator } = this.props
        getCollaborator({ id })
    }

    handleUpdateslide = e => {
        e.preventDefault()
        const { toggleCollaborator, clearSearchCollaborators } = this.props
        clearSearchCollaborators()
        toggleCollaborator()
    }

    handleClick = (action, index) => {
        switch (action) {
            case 'view':
                const viewAction = this.state.collaborators.map((item, itemIndex) => {
                    if (itemIndex === index) {
                        return {
                            ...item,
                            isView: !item.isAll && !item.isEdit && !item.isShare ? !item.isView : true,
                            permissionId: '1',
                        }
                    } else {
                        return item
                    }
                })
                this.setState(
                    {
                        collaborators: viewAction,
                        isDirtySave: false,
                    },
                    () => {
                        this.props.updateCollaborators(this.state.collaborators)
                    }
                )
                break
            case 'edit':
                const editAction = this.state.collaborators.map((item, itemIndex) => {
                    if (itemIndex === index) {
                        return {
                            ...item,
                            isEdit: !item.isEdit,
                            isView: !item.isEdit ? true : item.isView,
                            isAll: item.isEdit ? false : item.isAll,
                            permissionId:
                                !item.isEdit && item.isShare
                                    ? '5'
                                    : item.isEdit && item.isShare
                                    ? '3'
                                    : !item.isEdit && !item.isShare
                                    ? '2'
                                    : '1',
                        }
                    } else {
                        return item
                    }
                })
                this.setState(
                    {
                        collaborators: editAction,
                        isDirtySave: false,
                    },
                    () => {
                        this.props.updateCollaborators(this.state.collaborators)
                    }
                )
                break

            case 'share':
                const shareAction = this.state.collaborators.map((item, itemIndex) => {
                    if (itemIndex === index) {
                        return {
                            ...item,
                            isShare: !item.isShare,
                            isView: !item.isShare ? true : item.isView,
                            isAll: item.isShare ? false : item.isAll,
                            permissionId:
                                !item.isShare && item.isEdit
                                    ? '5'
                                    : !item.isEdit && !item.isShare
                                    ? '3'
                                    : item.isEdit && item.isShare
                                    ? '2'
                                    : '1',
                        }
                    } else {
                        return item
                    }
                })
                this.setState(
                    {
                        collaborators: shareAction,
                        isDirtySave: false,
                    },
                    () => {
                        this.props.updateCollaborators(this.state.collaborators)
                    }
                )
                break

            case 'all':
                const allAction = this.state.collaborators.map((item, itemIndex) => {
                    if (itemIndex === index) {
                        return {
                            ...item,
                            isAll: !item.isAll,
                            isEdit: !item.isAll ? true : item.isEdit,
                            isShare: !item.isAll ? true : item.isShare,
                            isView: !item.isAll ? true : item.isView,
                            permissionId: item.isAll && item.isShare && item.isEdit ? '5' : '4',
                        }
                    } else {
                        return item
                    }
                })
                this.setState(
                    {
                        collaborators: allAction,
                        isDirtySave: false,
                    },
                    () => {
                        this.props.updateCollaborators(this.state.collaborators)
                    }
                )
                break
            default:
                break
        }
    }

    handleChange = (field, value) => {
        clearTimeout(this.delayTimer)
        this.setState({ search: value, selected: true }, () => {
            this.delayTimer = setTimeout(() => {
                let data = {
                    name: this.state.search,
                    existingList: this.state.collaborators,
                }
                if (value.length === 0) {
                    this.props.clearSearchCollaborators()
                }
                this.props.searchCollaborator(data)
            }, 1000)
        })
    }

    handleDelete = (item, index) => {
        const { deleteCollaborators } = this.props
        deleteCollaborators({ ...item, isDelete: true }, index)
        this.setState({
            isDirtySave: false,
            search: '',
            selected: {},
        })
    }

    handleListSelect = item => {
        if (item.userRole === 'CST') {
            const { alert } = this.props
            const { error } = alert
            const { CST_USER_MESSAGE } = SITE_TXT
            error(CST_USER_MESSAGE)
            this.setState({})
            return
        }

        this.inputElement.current.focus()

        const selected = {
            ...item,
            permissionId: '1',
            isView: true,
            isEdit: true,
            isShare: false,
            isAll: false,
            id: '',
            isOwner: false,
            isDelete: false,
        }
        this.setState(
            {
                search: item.userName,
                selected,
            },
            () => {
                this.props.clearSearchCollaborators()
            }
        )
    }

    handleAddCollaborator = e => {
        e.preventDefault()
        const { addCollaborators } = this.props
        addCollaborators(this.state.selected)
        this.setState({
            search: '',
            selected: {},
            isDirtySave: false,
        })
    }

    handleSaveCollaborator = e => {
        e.preventDefault()
        const { editCollaborator, id: myLopId } = this.props
        const { collaborators } = this.state
        editCollaborator({ myLopId, collaborators })
    }

    render() {
        const { headerLabel, collaborators, search, isDirtySave, selected } = this.state

        const disableAdd = isEmpty(selected)
        const { searchcollaboratorList, permissionId, appWidth } = this.props
        const isMobile = appWidth <= 768
        return (
            <div className="container">
                <div className="header">Collaborators</div>
                <div className="collaborator-card">
                    <div
                        className={c(
                            'search-header',
                            { collaborator_card1: permissionId === 4 },
                            { collaborator_card2: permissionId !== 4 }
                        )}
                    >
                        {permissionId === 4 && (
                            <Fragment>
                                <div>
                                    <span className="search-label add-collaborator-label">
                                        {SITE_TXT.TXT_ADD_COLLABORATOR}
                                    </span>
                                </div>
                                <div>
                                    <form>
                                        <Inputbox
                                            type="text"
                                            placeholder="Enter Name"
                                            customClass="collaborator-textbox"
                                            onChange={this.handleChange}
                                            value={search}
                                            refValue={this.inputElement}
                                        />
                                        <button
                                            disabled={disableAdd}
                                            className={c('btn btn-primary', {
                                                disabled: disableAdd,
                                            })}
                                            onClick={this.handleAddCollaborator}
                                        >
                                            ADD
                                        </button>
                                    </form>
                                </div>

                                <div
                                    className={c('collaborator-search-list', {
                                        minHeight_1: searchcollaboratorList.length === 1,
                                        minHeight_2: searchcollaboratorList.length === 2,
                                        minHeight_3: searchcollaboratorList.length === 3,
                                        minHeight_4: searchcollaboratorList.length === 4,
                                        minHeight_5: searchcollaboratorList.length >= 5,
                                    })}
                                >
                                    {searchcollaboratorList.map((item, index) => (
                                        <div
                                            className="user-list"
                                            onClick={() => this.handleListSelect(item)}
                                            key={index}
                                        >
                                            <div className="profile-image">
                                                {item.userImage !== '' ? (
                                                    <img alt="" src={getPrefixedUrl(item.userImage)} />
                                                ) : (
                                                    <div
                                                        className="profile-AB"
                                                        style={{
                                                            backgroundColor: item.background,
                                                        }}
                                                    >
                                                        {getNamePrefix(item.userName)}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="profile-name">{item.userName}</div>
                                        </div>
                                    ))}
                                </div>
                            </Fragment>
                        )}
                    </div>

                    <div className="table-collaborator">
                        <div className="search-label table-label">
                            {SITE_TXT.TXT_ACCESS_COLLABORATOR}
                            <span className="hint" data-tip data-for="info">
                                i
                            </span>
                        </div>
                        <div className="table-collection">
                            <div className="table-header">
                                {headerLabel.map((item, index) => (
                                    <div key={index}>{item}</div>
                                ))}
                            </div>
                            <div
                                className={c('table-body', {
                                    pointerEvent: permissionId !== 4,
                                })}
                            >
                                {collaborators.map(
                                    (item, index) =>
                                        !item.isDelete && (
                                            <div
                                                key={index}
                                                className={c('table-row', {
                                                    'pointerEvent externalClient': item.roleKey === 'External_Client',
                                                })}
                                            >
                                                <div className="user">
                                                    {item.userImage ? (
                                                        <img alt="" src={getPrefixedUrl(item.userImage)} />
                                                    ) : (
                                                        <div
                                                            className="profile-AB"
                                                            style={{
                                                                backgroundColor: item.background,
                                                            }}
                                                        >
                                                            {getNamePrefix(item.userName)}
                                                        </div>
                                                    )}
                                                    <div className="profile">
                                                        <div className="name">{item.userName}</div>
                                                        {item.isOwner && (
                                                            <div className="owner">
                                                                {SITE_TXT.TXT_COLLABORATOR_OWNER}
                                                            </div>
                                                        )}
                                                        {item.roleKey === 'External_Client' && (
                                                            <div className="owner">{SITE_TXT.TXT_EXTERNAL_CLIENT}</div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="view">
                                                    {isMobile && <div>{headerLabel[1]}</div>}
                                                    <input
                                                        type="checkbox"
                                                        onChange={() => {}}
                                                        checked={this.state.collaborators[index].isView}
                                                    />
                                                    <TickIconTable />
                                                </div>
                                                <div className="edit">
                                                    {isMobile && <div>{headerLabel[2]}</div>}
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.collaborators[index].isEdit}
                                                        onChange={() => this.handleClick('edit', index)}
                                                    />
                                                    <TickIconTable />
                                                </div>
                                                <div className="share">
                                                    {isMobile && <div>{headerLabel[3]}</div>}
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.collaborators[index].isShare}
                                                        onChange={() => this.handleClick('share', index)}
                                                    />
                                                    <TickIconTable />
                                                </div>
                                                <div className="all">
                                                    {isMobile && <div>{headerLabel[4]}</div>}
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.collaborators[index].isAll}
                                                        onChange={() => this.handleClick('all', index)}
                                                    />
                                                    <TickIconTable />
                                                </div>
                                                {!item.isOwner && permissionId === 4 && (
                                                    <div
                                                        className="delete"
                                                        onClick={() => this.handleDelete(item, index)}
                                                    >
                                                        <DeleteGreyIcon />
                                                    </div>
                                                )}
                                            </div>
                                        )
                                )}
                            </div>
                        </div>
                        <div className="button-set">
                            <button className="btn btn-outline-secondary btn-lg" onClick={this.handleUpdateslide}>
                                Back
                            </button>
                            {permissionId === 4 && (
                                <button
                                    disabled={isDirtySave}
                                    className="btn btn-primary btn-lg"
                                    onClick={this.handleSaveCollaborator}
                                >
                                    Save
                                </button>
                            )}
                        </div>
                    </div>
                </div>

                <ReactTooltip
                    place={isMobile ? 'bottom' : 'right'}
                    type="dark"
                    effect="float"
                    id="info"
                    className="extraClass"
                    style={{ cursor: 'pointer' }}
                    globalEventOff={appWidth <= 1024 ? 'touchstart' : undefined}
                >
                    <div className="show-info">
                        <p>Note: All permissions are specific to a given LoP</p>
                        <p>
                            <b>View</b>: These users can view the current LoP
                        </p>
                        <p>
                            <b>Edit</b>: These users can modify as well as view the current LoP. Note: only one LoP user
                            can be in “edit mode” at any given time. Other users who try to enter “edit mode” will
                            receive a notification that they can only view the LoP.
                        </p>
                        <p>
                            <b>Share</b>: In addition to modifying and viewing the current LoP, these users can access
                            the “Publish external link” functionality
                        </p>
                        <p>
                            <b>All access</b>: In addition to the above, these users can delete the LoP.
                        </p>
                    </div>
                </ReactTooltip>
            </div>
        )
    }
}

Collaborator.propTypes = {
    toggleCollaborator: PropTypes.func.isRequired,
    getCollaborator: PropTypes.func.isRequired,
    searchCollaborator: PropTypes.func.isRequired,
    editCollaborator: PropTypes.func.isRequired,
    addCollaborators: PropTypes.func.isRequired,
    updateCollaborators: PropTypes.func.isRequired,
    deleteCollaborators: PropTypes.func.isRequired,
    clearSearchCollaborators: PropTypes.func.isRequired,
    isListUpdated: PropTypes.bool,
    permissionId: PropTypes.number.isRequired,
    appWidth: PropTypes.number.isRequired,
}

const stateToProps = createStructuredSelector({
    collaboratorList: collaboratorList(),
    searchcollaboratorList: searchcollaboratorList(),
    isListUpdated: isListUpdated(),
})

const dispatchProps = {
    getCollaborator,
    searchCollaborator,
    editCollaborator,
    addCollaborators,
    deleteCollaborators,
    clearSearchCollaborators,
    updateCollaborators,
}

export default withAlert(connect(stateToProps, dispatchProps)(Collaborator))
