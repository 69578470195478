import React, { Component } from 'react'
import { PlusIcon, MinusIcon } from '../../Utils/svg'

class RowExpansion extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const { item, expandedRows, rowId, clickCallback } = this.props

        return (
            <span className="plus-minus-icon">
                {expandedRows === true && rowId === item.id ? (
                    <span onClick={clickCallback}>
                        {' '}
                        <MinusIcon width="12px" height="12px" />
                    </span>
                ) : (
                    <span onClick={clickCallback}>
                        {' '}
                        <PlusIcon width="12px" height="12px" />{' '}
                    </span>
                )}
            </span>
        )
    }
}

export default RowExpansion
