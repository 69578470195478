import React, { Fragment, useRef } from 'react'
import PropTypes from 'prop-types'
import { SortableElement } from 'react-sortable-hoc'
import c from 'classnames'
import { Input } from 'reactstrap'

import RichTextTool from '../RichTextTool'
import { SITE_TXT } from '../../../../Utils/constants'
import { TrashIcon, CopyGreyIcon } from '../../../../Utils/svg'
import { createMarkup } from '../../../../Utils/functions'
import VideoItem from './VideoItem'
import ImageItem from './ImageItem'
import DocImage from './ImageItem/DocImage'
import MultipleVideoItem from './MultiVideoItem'

const CustomItem = props => {
    const { item } = props
    const { contentTypeId } = item
    switch (parseInt(contentTypeId)) {
        case 1:
            return <VideoItem {...props} />
        case 2:
            return <ImageItem {...props} />
        case 3:
            return <DocImage {...props} />
        default:
            return null
    }
}

const SortableItem = SortableElement(props => {
    const itemRef = useRef(null)
    const {
        item = {},
        editMode,
        updateTemplate,
        indexItem,
        deleteInTemplate,
        toggleNotesModal,
        appWidth,
        createSharableLInk,
    } = props
    const { isText, heading, subHeading, description, contentTypeId, id, url, isMulti, videos = [] } = item

    // console.log("isTextisTextisTextisText", isText)
    return (
        <div
            className={c('contentBoxMckinsey customSectionContent', {
                moveIcon: editMode,
                docContent: parseInt(contentTypeId) === 3,
                textContent: parseInt(contentTypeId) === 5,
                headingContent: parseInt(contentTypeId) === 4,
            })}
        >
            <div className="dummyHeightContent" id={id} />
            {editMode && (
                <div className="controls">
                    <span
                        className="deletePointer"
                        onClick={() =>
                            deleteInTemplate(indexItem, {
                                ...item,
                                isDelete: 'true',
                            })
                        }
                        data-tip="Delete"
                    >
                        <TrashIcon />
                    </span>
                    <span
                        className="copyPointer"
                        onClick={() => createSharableLInk(url)}
                        data-tip={SITE_TXT.TXT_COPY_TOOLTIP}
                    >
                        <CopyGreyIcon />
                    </span>
                </div>
            )}
            {videos.length === 0 && isText === 'True' && appWidth > 740 ? <CustomItem {...props} /> : null}
            {contentTypeId !== 4 && contentTypeId !== 5 && videos.length === 0 ? (
                <div
                    className={c('textContent', {
                        fullTextContent: isText === 'False',
                    })}
                >
                    <div className="contentHeader">
                        {editMode ? (
                            <Fragment>
                                <Input
                                    value={heading}
                                    className="contentTitleText"
                                    placeholder={SITE_TXT.TXT_HEADER_PLACEHOLDER}
                                    maxLength="100"
                                    onChange={e => updateTemplate(indexItem, 'heading', e.target.value)}
                                />
                                <Input
                                    value={subHeading}
                                    className="contentSubHeaderText"
                                    placeholder={SITE_TXT.TXT_SUB_PLACEHOLDER}
                                    maxLength="100"
                                    onChange={e => updateTemplate(indexItem, 'subHeading', e.target.value)}
                                />
                            </Fragment>
                        ) : (
                            <Fragment>
                                <h2>{heading}</h2>
                                <h3>{subHeading}</h3>
                            </Fragment>
                        )}
                    </div>
                    {isText === 'True' &&
                        (editMode ? (
                            <Fragment>
                                <div className="borderDesc" />
                                <RichTextTool
                                    richTextClassName="pointerTextTool"
                                    richTextContent={description}
                                    onToolTextChange={content => updateTemplate(indexItem, 'description', content)}
                                    placeholder="Add text, maximum 1000 characters"
                                />
                            </Fragment>
                        ) : (
                            <Fragment>
                                <div className="borderDesc" />
                                <div
                                    className="desc"
                                    dangerouslySetInnerHTML={createMarkup(description)}
                                    ref={itemRef}
                                />
                                {/*{description.length > 760 || (appWidth < 812 && description.length > 250) ? (*/}
                                {itemRef.current && itemRef.current.offsetHeight < itemRef.current.scrollHeight && (
                                    <div className="readMore" onClick={() => toggleNotesModal(true, description)}>
                                        {SITE_TXT.TXT_READ_MORE}
                                    </div>
                                )}
                            </Fragment>
                        ))}
                </div>
            ) : contentTypeId === 5 && editMode && videos.length === 0 ? (
                <div className="text-area">
                    <RichTextTool
                        richTextClassName="pointerTextTool"
                        richTextContent={description}
                        onToolTextChange={content => updateTemplate(indexItem, 'description', content)}
                        placeholder="Add text, maximum 1000 characters"
                    />
                </div>
            ) : contentTypeId === 4 && editMode && videos.length === 0 ? (
                <div className="heading-text">
                    <Input
                        value={heading}
                        className="contentTitleText"
                        placeholder={SITE_TXT.TXT_HEADER_PLACEHOLDER}
                        maxLength="100"
                        onChange={e => updateTemplate(indexItem, 'heading', e.target.value)}
                    />
                </div>
            ) : contentTypeId === 5 && !editMode && videos.length === 0 ? (
                <div className="desc" dangerouslySetInnerHTML={createMarkup(description)} ref={itemRef} />
            ) : (
                videos.length === 0 && contentTypeId === 4 && !editMode && <div className="heading">{heading}</div>
            )}
            {videos.length === 0 && (isText === 'False' || appWidth <= 740) ? <CustomItem {...props} /> : null}
            {isMulti || (videos.length > 0 && <MultipleVideoItem {...props} />)}
        </div>
    )
})

SortableItem.propTypes = {
    item: PropTypes.instanceOf(Object).isRequired,
    sectionDetails: PropTypes.instanceOf(Object).isRequired,
    currentSlide: PropTypes.number.isRequired,
    appWidth: PropTypes.number.isRequired,
    editMode: PropTypes.bool.isRequired,
    indexItem: PropTypes.number.isRequired,
    toggleModal: PropTypes.func.isRequired,
    updateTemplate: PropTypes.func.isRequired,
    deleteInTemplate: PropTypes.func.isRequired,
    toggleNotesModal: PropTypes.func.isRequired,
}

export default SortableItem
