import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { setLopHeader } from '../App/actions'
import { selectLoading } from '../LopContent/selector'
import MMCst from './MMCst'

const mapStateToProps = createStructuredSelector({
    isLopListLoading: selectLoading(),
})

const dispatchToProps = {
    setLopHeader,
}

export default connect(mapStateToProps, dispatchToProps)(MMCst)
