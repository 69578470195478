import React, { Component, Fragment } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import c from 'classnames'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import loadImage from 'blueimp-load-image'
import { withAlert } from 'react-alert'
import Slider from 'react-slick'

import { selectWidth } from '../../../../../Containers/App/selector'
import Loader from '../../../../Loader'
import ImageCropper from './ImageCropper'
import { sortFileArray } from '../../../../../Utils/functions'
import { getPrefixedUrl } from '../../../../../Utils/apis'
import { SITE_TXT, COLLAGE_SLIDER } from '../../../../../Utils/constants'
import { addCustomContent, editCollageContent } from '../../../../../Containers/LopContent/actions'
import { AddPartnerProfile, EditLopImageIcon } from '../../../../../Utils/svg'
import './index.scss'

class ImageCarousel extends Component {
    state = {
        addedImages: [],
        openImageCropper: false,
        editCarousel: false,
        showLoader: false,
        currentSlide: 1,
    }

    closeCropModal = () => {
        this.setState(
            {
                openImageCropper: false,
                addedImages: [],
                editCarousel: false,
            },
            () => {
                this.handleImageHeight()
            }
        )
    }

    updateSlide = currentSlide => this.setState({ currentSlide })

    componentDidMount() {
        this.handleImageHeight()
    }
    componentDidUpdate() {
        this.handleImageHeight()
    }

    handleImageHeight = () => {
        if (this.slider1 && this.slider1.innerSlider) {
            const imgs = ReactDOM.findDOMNode(this.slider1.innerSlider.list).querySelectorAll('img')
            let loadedImgs = 0
            imgs.forEach(img => {
                const newImg = new Image()
                newImg.src = img.src
                newImg.onload = () => {
                    loadedImgs++
                    if (this.slider1 && (loadedImgs === 1 || loadedImgs === imgs.length)) {
                        this.slider1.innerSlider.adaptHeight()
                    }
                }
            })
        }
    }

    openCropModal = () => {
        const { item } = this.props
        const { images: addedImages } = item
        this.setState({
            openImageCropper: true,
            editCarousel: true,
            addedImages,
        })
    }

    handleFile = e => {
        if (e.target.files && e.target.files.length > 1) {
            const limitImages = e.target.files.length > 30 ? 30 : e.target.files.length
            const addedImages = []
            const { appWidth } = this.props
            if (appWidth <= 768) {
                this.setState({ showLoader: true })
                for (let i = 0; i < limitImages; i++) {
                    const imageFile = e.target.files[i]
                    loadImage(
                        e.target.files[i],
                        canvas => {
                            addedImages.push({
                                dummyImg: canvas.toDataURL('image/png'),
                                imageText: '',
                                imageFile,
                            })
                            if (i === limitImages - 1) {
                                this.setState({
                                    addedImages: sortFileArray(addedImages),
                                    openImageCropper: true,
                                    showLoader: false,
                                })
                            }
                        },
                        { orientation: true }
                    )
                }
            } else {
                for (let i = 0; i < limitImages; i++) {
                    const imageFile = e.target.files[i]
                    const reader = new FileReader()
                    reader.addEventListener(
                        'load',
                        () => {
                            addedImages.push({
                                dummyImg: reader.result,
                                imageText: '',
                                imageFile,
                            })
                        },
                        false
                    )
                    reader.readAsDataURL(e.target.files[i])
                    if (i === limitImages - 1) {
                        setTimeout(() => {
                            this.setState({
                                addedImages: sortFileArray(addedImages),
                                openImageCropper: true,
                            })
                        }, 100)
                    }
                }
            }
        } else {
            const { alert } = this.props
            const { error } = alert
            error(SITE_TXT.TXT_IMAGES_MIN_MANDATORY)
        }
        e.target.value = null
    }

    render() {
        const { openImageCropper, addedImages, editCarousel, showLoader, currentSlide } = this.state
        const { item, indexItem, editMode, appWidth, sectionDetails, addCustomContent, editCollageContent } = this.props
        const { isText, images } = item
        return (
            <div
                className={c('imageContainer ', {
                    fullImage: isText === 'False',
                })}
            >
                <div
                    className={c('imageCollage', {
                        fullImage: isText === 'False',
                    })}
                >
                    {images.length ? (
                        <Fragment>
                            {editMode && (
                                <span className="editImageIcon" onClick={this.openCropModal}>
                                    <EditLopImageIcon />
                                </span>
                            )}
                            <Slider
                                {...COLLAGE_SLIDER}
                                ref={slider => (this.slider1 = slider)}
                                beforeChange={(a, nextSlide) => this.updateSlide(nextSlide + 1)}
                            >
                                {images.map((item, i) => (
                                    <div className="figureWrapper" key={i}>
                                        <figure>
                                            <img
                                                alt=""
                                                src={item.base64path || getPrefixedUrl(item.image)}
                                                key={
                                                    item.base64path
                                                        ? item.base64path.substring(0, 4)
                                                        : getPrefixedUrl(item.image)
                                                }
                                                height="100%"
                                            />
                                        </figure>
                                        <figcaption className="ellipsisPro" title={item.imageText}>
                                            {item.imageText}
                                        </figcaption>
                                    </div>
                                ))}
                            </Slider>
                            <div className="slideContent">
                                <span>{currentSlide}</span>/<span>{images.length}</span>
                            </div>
                        </Fragment>
                    ) : (
                        <div className="uploadContent">
                            <span className="bottomContent">
                                Select minimum of 02 images & maximum of 30 images. It’s easy, you can choose multiple
                                images at the same time.
                            </span>
                            <AddPartnerProfile width="36" height="36" />
                            <input
                                multiple
                                type="file"
                                onChange={e => this.handleFile(e)}
                                accept={SITE_TXT.TXT_CREATE_USER_FILES_ACCEPTED}
                            />
                        </div>
                    )}
                </div>
                {openImageCropper ? (
                    <ImageCropper
                        sectionItem={item}
                        sectionIndex={indexItem}
                        editCarousel={editCarousel}
                        addedImages={addedImages}
                        sectionDetails={sectionDetails}
                        addCustomContent={addCustomContent}
                        editCollageContent={editCollageContent}
                        closeModal={this.closeCropModal}
                        appWidth={appWidth}
                    />
                ) : null}
                {showLoader && <Loader />}
            </div>
        )
    }
}

ImageCarousel.propTypes = {
    indexItem: PropTypes.number.isRequired,
    editMode: PropTypes.bool.isRequired,
    item: PropTypes.instanceOf(Object).isRequired,
    sectionDetails: PropTypes.instanceOf(Object).isRequired,
    addCustomContent: PropTypes.func.isRequired,
    editCollageContent: PropTypes.func.isRequired,
    appWidth: PropTypes.number.isRequired,
}

const stateToProps = createStructuredSelector({
    appWidth: selectWidth(),
})

const dispatchProps = {
    addCustomContent,
    editCollageContent,
}
export default withAlert(connect(stateToProps, dispatchProps)(ImageCarousel))
