import React, { Component } from 'react'
import { NavLink, withRouter, Link } from 'react-router-dom'
import { withOktaAuth } from '@okta/okta-react'

import LopHeader from './LopHeader'
import { SearchIcon, AdminIcon } from '../../Utils/svg'
import { getPrefixedUrl } from '../../Utils/apis'
import logo from '../../Assets/logo_2.svg'
import './Header.css'
import {
    SITE_TXT,
    isMobile,
    isMobileTablet,
    userInfo,
    userAuthorization,
    isExternalClinet,
    STATUS_FAILURE,
} from '../../Utils/constants'
class Header extends Component {
    state = {
        windowWidth: window.innerWidth,
        mobileNavVisible: false,
        showCancel: false,
        userAccessToken: this.props.userAccessToken,
        userDetails: this.props.data,
        showDropdown: false,
        isAuthenticated: this.props.authState?.isAuthenticated,
        // isAuthenticated: this.props.authO.isAuthenticated(),
        lopInfo: localStorage.getItem('myLopData') ? JSON.parse(localStorage.getItem('myLopData')) : {},
    }

    handleResize() {
        this.setState({ windowWidth: window.innerWidth }, () => {
            this.props.showWidth(this.state.windowWidth)
        })
    }

    componentDidMount() {
        this.setState({ windowWidth: window.innerWidth }, () => {
            this.props.showWidth(this.state.windowWidth)
        })

        window.addEventListener('resize', this.handleResize.bind(this))
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize.bind(this))
    }

    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.authState) {
            if (nextProps.authState.isAuthenticated !== this.state.isAuthenticated) {
                this.setState({ isAuthenticated: nextProps.authState.isAuthenticated })
            }
        }
    }

    logoutDropdown = () => {
        this.setState({ showDropdown: !this.state.showDropdown })
    }

    clickLogo = e => {
        this.setState({ showCancel: !this.state.showCancel }, () => {
            this.props.showMenu(this.state.showCancel)
        })
    }

    logout = () => {
        this.logoutDropdown()
        this.props.setLogoutUser()
    }

    toggleSidebar() {
        if (!this.state.isAuthenticated) {
            return
        }
        let sidebarClass = document.getElementsByClassName('sidebar-container')[0].className
        document.getElementsByClassName('sidebar-container')[0].className =
            sidebarClass === 'sidebar-container' ? 'sidebar-container show' : 'sidebar-container'
        this.setState({ showCross: !this.state.showCross })
    }

    render() {
        const {
            location,
            showLopHeader,
            getMyLops,
            myLopData,
            isLopListLoading,
            hideSideBar,
            userRoleStatus,
        } = this.props
        const searchModules = ['library']
        const searchId = location.pathname.split('/')[1]
        const searchAvailable = !!~searchModules.indexOf(searchId)
        const { isAuthenticated, showDropdown } = this.state
        // const { name = '' } = lopInfo
        // const permissionId = parseInt(lopInfo.permissionId)
        const profileName =
            (userAuthorization && userAuthorization['user_metadata'] && userAuthorization['user_metadata']['name']) ||
            (userInfo && userInfo.nickname)
        const { TXT_LOGOUT, TXT_EDIT_PROFILE, TXT_WELCOME } = SITE_TXT

        return (
            <header>
                <div>
                    {isMobileTablet && !hideSideBar ? (
                        <span className="leftLogoCss" onClick={() => this.toggleSidebar()}>
                            <img
                                alt="left"
                                id="leftLogo"
                                src={this.state.showCross ? '/icons/Cross.png' : '/icons/topLeftLogo.png'}
                                height="70"
                                width="80"
                            />
                        </span>
                    ) : (
                        <img alt="left" id="leftLogo" src="/icons/topLeftLogo.png" height="70" width="80" />
                    )}
                </div>

                <div className="logo-container">
                    {userRoleStatus !== STATUS_FAILURE ? (
                        <Link to={'/'} className="mck-logo-icon">
                            <img src={logo} alt="" />
                        </Link>
                    ) : (
                        <div className="mck-logo-icon">
                            <img src={logo} alt="" />
                        </div>
                    )}
                </div>

                {isAuthenticated && !isMobile && !showLopHeader && userRoleStatus !== STATUS_FAILURE && (
                    <div className="welcome-div">
                        {profileName && (
                            <div className="welcomeName">
                                <span className="welcomeText">{TXT_WELCOME}</span> {profileName}
                            </div>
                        )}
                    </div>
                )}

                {isAuthenticated && showLopHeader && (
                    <LopHeader
                        isLopListLoading={isLopListLoading}
                        myLopData={myLopData}
                        getMyLops={getMyLops}
                        hideSideBar={hideSideBar}
                    />
                )}
                {searchAvailable && (
                    <div className="searchIcon">
                        <NavLink to={{ pathname: `/search/${searchId}` }}>
                            <SearchIcon />
                        </NavLink>
                    </div>
                )}
                {isAuthenticated && userRoleStatus !== STATUS_FAILURE && (
                    <div className="dropdown user-logout">
                        {(userAuthorization &&
                            userAuthorization['user_metadata'] &&
                            userAuthorization['user_metadata']['image'] && (
                                <img
                                    className="buttoncss action-icon"
                                    src={getPrefixedUrl(`${userAuthorization['user_metadata']['image']}`)}
                                    alt="userImage"
                                    width="35"
                                    height="35"
                                    onClick={this.logoutDropdown}
                                />
                            )) || (
                            <button className="buttoncss action-icon" onClick={this.logoutDropdown}>
                                <AdminIcon />
                            </button>
                        )}

                        {showDropdown && (
                            <div className="dropdown-content">
                                {!isExternalClinet && (
                                    <NavLink to={'/profile/updateProfile'}>
                                        <p className="action-icon">{TXT_EDIT_PROFILE}</p>
                                    </NavLink>
                                )}
                                <p onClick={this.logout} className="action-icon">
                                    {TXT_LOGOUT}
                                </p>
                            </div>
                        )}
                    </div>
                )}
            </header>
        )
    }
}

export default withOktaAuth(withRouter(Header))
