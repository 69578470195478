import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { learnAction } from './actions'
import { selectLoading, selectError, selectStatus } from './selector'
import { Learn } from '../../Components'

const mapStateToProps = createStructuredSelector({
    isLoading: selectLoading(),
    success: selectStatus(),
    error: selectError(),
})

const mapDispatchToProps = {
    learn: learnAction,
}
export default connect(mapStateToProps, mapDispatchToProps)(Learn)
