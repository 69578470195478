import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Lop } from '../../Components'

import {
    selectLoading,
    selectStatus,
    selectData,
    documentsData,
    addEditStatus,
    isListUpdated,
    foldersList,
    lopTags,
    expertData,
    selectIndustryTags,
    selectTopicsKeywordsTags,
    emailDocumentsData,
    downloadDocumentsData,
    selectLopGeography,
    selectLopDealType,
    selectLopDealSize,
    editItemData,
    isListUpdatedForAdd,
    fetchedCv,
    selectLopRegionBase,
    selectExpIndustryTags,
    selectExpRoleTags,
    selectExpTopicsTags,
    isLoadingFetch,
    countryCode,
} from './selector'
import {
    lopGetStartedItems,
    lopGetStartedItemsMore,
    lopGoldItems,
    lopGoldItemsMore,
    lopGetStartedItemsDelete,
    lopAddItem,
    lopEditItem,
    lopGoldDeleteItems,
    lopGoldDeleteFolder,
    lopAddFolder,
    lopEditFolder,
    lopFetchFolderList,
    lopGoldDownloadItems,
    lopGoldEmailItems,
    lopIndustryTags,
    lopTopicsKeywordsTags,
    lopGeography,
    lopDealType,
    lopDealSize,
    lopCaseStudiesItems,
    lopCaseStudiesItemsMore,
    lopCaseStudiesAddItem,
    caseStudEditItemPut,
    caseStudEditItemGet,
    lopExpertItems,
    lopExpertItemsMore,
    lopClientRefItems,
    lopClientRefItemsMore,
    lopExpertCvAddItem,
    lopExpertCvEditItem,
    lopExpertCvFetchItem,
    lopRegionBase,
    lopExpertIndustryTags,
    lopExpertRoleTags,
    lopExpertTopicsTags,
    lopClearFetchedEditData,
    lopFetchedCountryCode,
    lopAddClient,
    lopEditClientFetch,
    lopEditClientPut,
    lopEmptyClientRef,
} from './actions'

import { setLopHeader } from '../App/actions'

const mapStateToProps = createStructuredSelector({
    isLoading: selectLoading(),
    status: selectStatus(),
    data: selectData(),
    documentsData: documentsData(),
    addEditStatus: addEditStatus(),
    isListUpdated: isListUpdated(),
    foldersList: foldersList(),
    downloadDocumentsData: downloadDocumentsData(),
    emailDocumentsData: emailDocumentsData(),
    expertData: expertData(),
    fetchLopTags: lopTags(),
    industryTags: selectIndustryTags(),
    topicsKeywordsTags: selectTopicsKeywordsTags(),
    geographyData: selectLopGeography(),
    dealTypeData: selectLopDealType(),
    dealSizeData: selectLopDealSize(),
    editItemData: editItemData(),
    isListUpdatedForAdd: isListUpdatedForAdd(),
    fetchedCv: fetchedCv(),
    selectLopRegionBase: selectLopRegionBase(),
    selectExpIndustryTags: selectExpIndustryTags(),
    selectExpRoleTags: selectExpRoleTags(),
    selectExpTopicsTags: selectExpTopicsTags(),
    isLoadingFetch: isLoadingFetch(),
    countryCode: countryCode(),
})

const mapDispatchToProps = {
    lopGetStartedItems,
    lopGetStartedItemsMore,
    lopGetStartedItemsDelete,
    lopAddItem,
    lopEditItem,
    lopGoldItems,
    lopGoldItemsMore,
    lopGoldDeleteItems,
    lopGoldDeleteFolder,
    lopAddFolder,
    lopEditFolder,
    lopFetchFolderList,
    lopGoldEmailItems,
    lopGoldDownloadItems,
    lopIndustryTags,
    lopTopicsKeywordsTags,
    lopGeography,
    lopDealType,
    lopDealSize,
    lopCaseStudiesItems,
    lopCaseStudiesItemsMore,
    lopCaseStudiesAddItem,
    caseStudEditItemPut,
    caseStudEditItemGet,
    lopExpertItems,
    lopExpertItemsMore,
    lopClientRefItems,
    lopClientRefItemsMore,
    lopExpertCvAddItem,
    lopExpertCvEditItem,
    lopExpertCvFetchItem,
    lopRegionBase,
    lopExpertIndustryTags,
    lopExpertRoleTags,
    lopExpertTopicsTags,
    lopClearFetchedEditData,
    lopFetchedCountryCode,
    lopAddClient,
    lopEditClientPut,
    lopEditClientFetch,
    lopEmptyClientRef,
    setLopHeader,
}

export default connect(mapStateToProps, mapDispatchToProps)(Lop)
