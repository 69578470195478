import React, { Component } from 'react'

class Dropdown extends Component {
    handleChange(e) {
        this.props.onChange(this.props.fieldFor, e.target.value)
    }

    render() {
        const { label, fieldFor, options, defaultVal, customClass, value, disabled } = this.props

        return (
            <div className="form-group">
                {label ? <label>{label} </label> : ''}
                <select
                    value={value ? value : undefined}
                    name={fieldFor}
                    className={`form-control  ${customClass}`}
                    onChange={this.handleChange.bind(this)}
                    disabled={disabled ? disabled : false}
                >
                    {defaultVal !== 'edit' ? <option value="0">{defaultVal ? defaultVal : 'Select'}</option> : null}
                    {options &&
                        options.length > 0 &&
                        options.map((option, index) => (
                            <option key={index} value={option.key} selected={option.valueName}>
                                {option.value}
                            </option>
                        ))}
                </select>
            </div>
        )
    }
}

export default Dropdown
