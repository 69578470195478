import { call, takeLatest, put } from 'redux-saga/effects'
import { USER_ROLE_GET } from './constant'
import { STATUS_SUCCESS } from '../../Utils/constants'
import { roleSuccessAction, roleFailedAction, setLopHeader } from './actions'
import { getUserRole } from '../../Utils/apis'

export function* role({ data }) {
    try {
        yield put(setLopHeader())
        const response = yield call(getUserRole, data)
        const responseData = response.data
        if (responseData.status === STATUS_SUCCESS) {
            yield put(roleSuccessAction({ responseData }))
        } else {
            yield put(roleFailedAction({ responseData }))
        }
    } catch (err) {
        yield put(roleFailedAction({ responseData: err }))
    }
}

export function* roleSaga() {
    yield takeLatest(`${USER_ROLE_GET}_PENDING`, role)
}

export default [roleSaga]
