import React, { Component } from 'react'
import ModuleHeader from '../ModuleHeader'
import * as Constant from '../../Containers/Admin/constant'
import Inputbox from '../Inputbox'
import Dropdown from '../Dropdown'
import ReactDropzone from 'react-dropzone'
import ReactSelect from 'react-select'
import { BackIcon, RemoveIcon } from '../../Utils/svg'
import './admin.css'
import { isValidPattern, getUniqueElements } from '../../Utils/functions'
import { ConfirmAlertBox } from '../../Containers/Library/Alerts'

import {
    DOCUMENT_NAME_PATTERN,
    MAX_TAGS_ALLOWED,
    SITE_TXT,
    STATUS_SUCCESS,
    COMMA_KEY_CODE,
    MAX_NAME_LENGTH,
} from '../../Utils/constants'

class UploadDocument extends Component {
    constructor(props) {
        super(props)

        this.state = {
            document: JSON.parse(JSON.stringify(Constant.DOC_INITAL_STATE)),
            options: [],
            value: [],
            lastTagTyped: '',
            secondlastParam: '',
            docdetails: this.props.docdetails,
            tagArray: [],
            tagdata: [],
            setVal: false,
            deleteDocStatus: false,
            newDocs: false,
            documentName: '',
            newDocsUploaded: false,
            setNameVal: false,
            setTypeVal: false,
            documentType: 0,
        }
    }
    componentDidMount = () => {
        let currentLocation = window.location.href
        let docId = currentLocation.slice(currentLocation.lastIndexOf('/') + 1)
        let secondlastParam = currentLocation.split('/').slice(-2)[0]
        let editDocId = currentLocation.slice(currentLocation.lastIndexOf('/') + 1)
        this.setState(
            {
                secondlastParam: secondlastParam,
                document: {
                    ...this.state.document,
                    docId: docId,
                },
            },
            () => {
                if (this.state.secondlastParam === 'edit') {
                    this.props.fetchDocDetails(editDocId)
                }
            }
        )
    }
    callDocType = contentType => {
        if (contentType !== '' && this.state.setTypeVal === false) {
            this.setState(
                {
                    documentType:
                        contentType === 'Discussion document'
                            ? '1'
                            : contentType === 'Article'
                            ? '2'
                            : contentType === 'Toolbox'
                            ? '3'
                            : 0,
                    setTypeVal: true,
                },
                () => {
                    this.setState({
                        document: {
                            ...this.state.document,
                            docType: this.state.documentType,
                        },
                    })
                }
            )
        }
    }
    UNSAFE_componentWillReceiveProps = nextProps => {
        if (this.state.newDocsUploaded === true && nextProps.uploadData === 'Library Document added successfully.') {
            this.setState({ newDocsUploaded: false })
            this.historyBack()
        }
        if (this.state.secondlastParam === 'edit') {
            if (this.state.deleteDocStatus === true && nextProps.deleteData === STATUS_SUCCESS) {
                // alert('Document deleted successfully!')
                this.setState({ deleteDocStatus: false }, () => {
                    this.historyBack()
                })
            }
            if (this.state.newDocs === true && nextProps.newdocdetails === STATUS_SUCCESS) {
                // alert('Document Replaced successfully!')
                this.setState({ newDocs: false })
                this.historyBack()
            }

            this.setState({
                docdetails: nextProps.docdetails,
            })

            this.setState(
                {
                    tagArray: nextProps.docdetails ? nextProps.docdetails.data[0].TagList.split(',') : [],
                },
                () => {
                    if (nextProps.docdetails) {
                        if (nextProps.docdetails.data[0].TagList !== '' && this.state.setVal === false) {
                            this.state.tagArray.map(val =>
                                this.state.tagdata.push({
                                    label: val,
                                    value: val,
                                })
                            )

                            this.reactSelect.select.setValue(this.state.tagdata)
                            this.setState({ setVal: true })
                        }

                        if (nextProps.docdetails.data[0].contentName !== '' && this.state.setNameVal === false) {
                            this.setState(
                                {
                                    documentName: nextProps.docdetails ? nextProps.docdetails.data[0].contentName : '',
                                    setNameVal: true,
                                },
                                () => {
                                    this.setState({
                                        document: {
                                            ...this.state.document,
                                            docName: this.state.documentName,
                                        },
                                    })
                                    this.callDocType(nextProps.docdetails.data[0].contentType)
                                }
                            )
                        }

                        this.setState({
                            document: {
                                ...this.state.document,
                                docTags: this.state.tagdata,
                            },
                        })
                    }
                }
            )
        }
    }

    inputChangeHandler = input => {
        let inp = input.trim()
        if (inp === '') {
            return
        }
        clearTimeout(this.delayTimer)
        this.delayTimer = setTimeout(() => {
            this.setState({ lastTagTyped: inp })
            this.props.fetchTags(inp)
        }, 1000)
    }

    onReactSelectChange = value => {
        if (value.length > MAX_TAGS_ALLOWED) {
            alert(SITE_TXT.TXT_MAX_TAGS_ALLOWED)
            value.pop()
            this.reactSelect.select.setState({ selectValue: value })
            return
        }

        this.setState({
            document: {
                ...this.state.document,
                docTags: value,
                lastTagTyped: '',
            },
        })
    }

    addTypedTag = val => {
        if (!this.reactSelect.select.state.selectValue) this.reactSelect.select.state.selectValue = []
        this.reactSelect.select.setState(
            {
                selectValue: [...this.reactSelect.select.state.selectValue, { label: val, value: val }],
            },
            () => {
                this.setState({ lastTagTyped: '' })
                this.reactSelect.blur()
                this.reactSelect.focus()
                this.reactSelect.onChange(this.reactSelect.select.state.selectValue)
            }
        )
    }

    keyDownHandler = e => {
        if (e.which === COMMA_KEY_CODE) {
            this.addTypedTag(e.target.value)
            e.preventDefault()
        }
    }

    onAddBtnClick = e => {
        e.preventDefault()
        if (this.state.lastTagTyped !== '') this.addTypedTag(this.state.lastTagTyped)
    }

    resetForm = () => {
        this.setState({
            document: JSON.parse(JSON.stringify(Constant.DOC_INITAL_STATE)),
        })
        this.reactSelect.state.value = []
    }

    handleChange = (field, value) => {
        this.setState({ document: { ...this.state.document, [field]: value } }, () => {})
    }

    handleDrop = files => {
        if (files.length === 0) {
            alert(SITE_TXT.TXT_INVALID_FILE_EXT)
            return
        }
        this.setState({
            document: { ...this.state.document, docFile: files[0] },
        })
    }

    validateFormHere = () => {
        let currentDocDetails = this.state.document
        if (currentDocDetails.docName.trim() === '') {
            alert(SITE_TXT.TXT_DOCNAME_MANDATORY)
            return false
        } else if (currentDocDetails.docName.trim().length > MAX_NAME_LENGTH) {
            alert(SITE_TXT.TXT_NAME_MAX_LENGTH)
            return false
        } else if (!isValidPattern(currentDocDetails.docName, DOCUMENT_NAME_PATTERN)) {
            alert(SITE_TXT.TXT_DOCNAME_VALID_PATTERN)
            return false
        } else if (currentDocDetails.docTags.length === 0) {
            alert(SITE_TXT.TXT_DOCUMENTTAG_MANDATORY)
            return false
        } else if (
            currentDocDetails.docType === '' ||
            currentDocDetails.docType === SITE_TXT.TXT_SELECT_DOCUMENT_TYPE
        ) {
            alert(SITE_TXT.TXT_DOCUMENTTYPE_MANDATORY)
            return false
        } else if (currentDocDetails.docFile === '' && this.state.secondlastParam !== 'edit') {
            alert(SITE_TXT.TXT_DRAG_DROP_CHOOSE_FILE_MANDATORY)
            return false
        }
        return true
    }

    createFormData = () => {
        let doc = this.state.document
        let data = new FormData()
        let docTagsStr = ''
        let docL = doc.docTags.length
        for (let index = 0; index < docL; index++) {
            docTagsStr += doc.docTags[index].value + ','
        }
        if (docTagsStr.trim().length > 0) {
            docTagsStr = docTagsStr.substring(0, docTagsStr.length - 1)
        }
        data.append('docName', doc.docName)
        data.append('docTags', getUniqueElements(docTagsStr.split(',')).toString())
        data.append('docType', doc.docType)
        data.append('docFile', doc.docFile)
        if (this.state.secondlastParam === 'edit') {
            data.append('docId', doc.docId)
        } else {
            data.append('docExpiryDate', doc.docExpiryDate)
            data.append('docSubCategoryId', doc.docSubCategoryId)
        }
        return data
    }
    deleteDoc = e => {
        const title = SITE_TXT.TXT_CONFIRM_DELETE
        const message = SITE_TXT.TXT_SURE_DELETE_DOCUMENT
        const onYesTrash = () => this.trashFinally()
        ConfirmAlertBox(title, message, onYesTrash)
    }

    trashFinally = () => {
        this.setState({ deleteDocStatus: true }, () => {
            this.props.deleteDoc(this.state.document.docId)
        })
    }

    handleUpload = e => {
        e.preventDefault()
        let currentLocation = window.location.href

        let tabid = currentLocation.slice(currentLocation.lastIndexOf('/') + 1)
        if (this.state.secondlastParam === 'edit') {
            this.setState(
                {
                    document: {
                        ...this.state.document,
                        docId: tabid,
                    },
                },
                () => {
                    if (this.validateFormHere()) {
                        this.createFormData()
                        this.setState({ newDocs: true }, () => {
                            this.props.replaceDocDetails(this.createFormData())
                        })
                    }
                }
            )
        } else {
            this.setState(
                {
                    document: {
                        ...this.state.document,
                        docSubCategoryId: tabid,
                    },
                },
                () => {
                    if (this.validateFormHere()) {
                        this.setState({ newDocsUploaded: true }, () => {
                            this.props.upload(this.createFormData())
                        })
                    }
                }
            )
        }
    }

    removeFile = () => {
        this.setState({ document: { ...this.state.document, docFile: '' } })
    }

    historyBack = () => {
        window.history.back()
    }

    render() {
        const { document, docdetails } = this.state
        const {
            TXT_UPLOAD_DOCUMENT,
            TXT_DOCNAME,
            TXT_DOCNAME_PLACEHOLDER,
            TXT_ADD_TAGS_PLACEHOLDER,
            TXT_SELECT_DOCUMENT_TYPE,
            TXT_ADD,
            TXT_DOCUMENT_TYPE,
            TXT_FILE_TYPE_ACCEPTED,
            TXT_DOCUMENT_TAGS,
            TXT_DROPZONE_TEXT,
            TXT_ALLOWED_FILES_TEXT,
            TXT_BROWSE_FILE,
            TXT_DOCUMENT_PATH,
            TXT_RESET,
            TXT_REPLACE_DOCUMENT,
            TXT_UPLOAD_BTN,
            TXT_REPLACE_BTN,
        } = SITE_TXT

        return (
            <div className="uploadDoc-container">
                <span onClick={() => this.historyBack()} className="getBack">
                    <BackIcon />
                </span>
                {this.state.secondlastParam === 'edit' ? (
                    <ModuleHeader heading={TXT_REPLACE_DOCUMENT} />
                ) : (
                    <ModuleHeader heading={TXT_UPLOAD_DOCUMENT} />
                )}
                <section className="form-container">
                    <form id="uploadDocForm" encType="multipart/form-data" className="form-horizontal" method="post">
                        <Inputbox
                            id="uploadDocInp"
                            label={TXT_DOCNAME}
                            placeholder={TXT_DOCNAME_PLACEHOLDER}
                            type="text"
                            fieldFor="docName"
                            value={document.docName}
                            onChange={this.handleChange}
                            autoFocus={true}
                        />
                        <label>{TXT_DOCUMENT_TAGS}</label>
                        <div className="addTag">
                            <ReactSelect
                                isMulti
                                searchable={true}
                                className="customtag"
                                labelKey="label"
                                valuekey="value"
                                options={this.props.data ? this.props.data.tags : []}
                                onInputChange={input => this.inputChangeHandler(input)}
                                onChange={this.onReactSelectChange}
                                onKeyDown={e => this.keyDownHandler(e)}
                                ref={input => (this.reactSelect = input)}
                                placeholder={TXT_ADD_TAGS_PLACEHOLDER}
                            />
                            <button onClick={e => this.onAddBtnClick(e)} className="addTagBtnUser">
                                +{TXT_ADD}
                            </button>
                        </div>

                        <Dropdown
                            label={TXT_DOCUMENT_TYPE}
                            defaultVal={this.state.secondlastParam === 'edit' ? 'edit' : TXT_SELECT_DOCUMENT_TYPE}
                            value={docdetails ? document.docType : 0}
                            fieldFor="docType"
                            selectedMenu={document.docType}
                            options={Constant.docType}
                            onChange={this.handleChange}
                        />
                        <div className="btn-container">
                            {this.state.secondlastParam === 'edit' ? (
                                <button
                                    type="reset"
                                    className="btn btn-danger btn-lg size-136"
                                    onClick={this.deleteDoc.bind(this)}
                                >
                                    Delete
                                </button>
                            ) : (
                                <button
                                    type="reset"
                                    className="btn btn-outline-dark btn-lg size-136"
                                    onClick={() => this.resetForm()}
                                >
                                    {TXT_RESET}
                                </button>
                            )}
                            <button
                                type="submit"
                                className="btn btn-primary btn-lg size-136"
                                onClick={e => this.handleUpload(e)}
                            >
                                {this.state.secondlastParam === 'edit' ? TXT_REPLACE_BTN : TXT_UPLOAD_BTN}
                            </button>
                        </div>
                    </form>

                    <div id="uploadFileDiv" className="form-group" form="uploadDocForm">
                        <label>{TXT_DOCUMENT_PATH}</label>
                        <span className="currentPath">
                            {sessionStorage.getItem('topLevel') +
                                ' >  \n ' +
                                sessionStorage.getItem('currentCategoryTab') +
                                ' >   \n' +
                                sessionStorage.getItem('selTabName')}
                        </span>
                        <br />
                        <label>{TXT_BROWSE_FILE}</label>
                        <ReactDropzone onDrop={this.handleDrop} className="dropzone" accept={TXT_FILE_TYPE_ACCEPTED}>
                            <svg width="46px" height="44px" viewBox="0 0 46 44" version="1.1">
                                <g
                                    id="Merger-Management-v11"
                                    stroke="none"
                                    stroke-width="1"
                                    fill="none"
                                    fill-rule="evenodd"
                                >
                                    <g
                                        id="08.3_MM_Admin_List/-Replace-Document"
                                        transform="translate(-950.000000, -462.000000)"
                                        fill="#2251FF"
                                    >
                                        <g
                                            id="Icons-/-Outline-/-32-/-Actions-/-Cloud-Upload-Copy-2"
                                            transform="translate(950.000000, 462.000000)"
                                        >
                                            <path
                                                d="M23,14.5428932 L30.7071068,22.25 L30,22.9571068 L23.499,16.457 L23.5,43.25 L22.5,43.25 L22.499,16.457 L16,22.9571068 L15.2928932,22.25 L23,14.5428932 Z M22,0.75 C30.0155767,0.75 36.656439,6.86129483 37.4116028,14.7597927 C41.9146144,14.9745286 45.5,18.6938509 45.5,23.25 C45.5,27.9441424 41.6941424,31.75 37,31.75 L37,31.75 L30.5,31.75 L30.5,30.75 L37,30.75 C41.1418576,30.75 44.5,27.3918576 44.5,23.25 C44.5,19.1178205 41.1575331,15.765698 37.0290216,15.750055 C37.0161217,15.7515687 36.9992865,15.7524423 36.95,15.755 L36.95,15.755 L36.4818532,15.755 L36.4510813,15.2878656 C35.950656,7.69111783 29.6432202,1.75 22,1.75 C14.2611693,1.75 7.90716878,7.83602516 7.53642278,15.5450186 L7.53642278,15.5450186 L7.51865236,15.9145223 L7.1601103,16.0056069 C3.85225718,16.8459391 1.5,19.8077068 1.5,23.25 C1.5,27.3918576 4.85814237,30.75 9,30.75 L9,30.75 L15.5,30.75 L15.5,31.75 L9,31.75 C4.30585763,31.75 0.5,27.9441424 0.5,23.25 C0.5,19.4703411 2.99952086,16.2027721 6.55919234,15.1346101 C7.13397362,7.0661668 13.8481111,0.75 22,0.75 Z"
                                                id="Combined-Shape"
                                            ></path>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            {document.docFile ? document.docFile.name : TXT_DROPZONE_TEXT}
                        </ReactDropzone>
                        <span className="allowedExt">
                            {TXT_ALLOWED_FILES_TEXT}
                            <br />
                            <b>{TXT_FILE_TYPE_ACCEPTED}</b>{' '}
                        </span>
                        <div className="removeFile">
                            <span onClick={this.removeFile}>
                                <RemoveIcon />
                            </span>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default UploadDocument
