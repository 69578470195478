import { fromJS } from 'immutable'
import {
    LIBRARY_ITEMS_GET,
    LIBRARY_ITEM,
    LIBRARY_DOCUMENTS,
    LIBRARY_DOCUMENTS_MORE,
    LIBRARY_ITEM_ADD,
    LIBRARY_ITEM_EDIT,
    LIBRARY_ITEM_REORDER,
    LIBRARY_ITEM_TRASH,
    LIBRARY_PHASE_ADD,
    LIBRARY_PHASE_EDIT,
    LIBRARY_PHASE_TRASH,
    LIBRARY_EMAIL_DOCUMENTS,
    LIBRARY_DOWNLOAD_DOCUMENTS,
    LIBRARY_OWNER_GET,
    OWNER_LOCATION_GET,
    LIBRARY_OWNER_ADD,
    TOGGLE_LIST_UPDATED_FLAG,
    UPDATE_OWNER,
    REMOVE_OWNER,
    SHARE_DOCUMENTS_POST,
    SHARE_DOCUMENTS_GET,
    SHARE_DOCUMENT_POST,
    MULTIPLE_SHARE_DOCS,
} from './constant'
import { STATUS_FAILED } from '../../Utils/constants'

const INITIAL_STATE = fromJS({
    status: null,
    editStatus: null,
    isLoading: true,
    data: null,
    categoryData: null,
    documentsData: null,
    emailDocumentsData: null,
    downloadDocumentsData: null,
    libraryOwnerList: {},
    ownerLocation: {},
    isListUpdated: false,
    closePopUpBox: false,
})

function libraryReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case `${LIBRARY_ITEMS_GET}_PENDING`:
            return state
                .set('isLoading', true)
                .set('status', null)
                .set('data', null)
        case `${LIBRARY_ITEMS_GET}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('data', action.data.responseData.result)
        case `${LIBRARY_ITEMS_GET}_FAILED`:
            return state
                .set('isLoading', false)
                .set('status', null)
                .set('data', null)
        case `${LIBRARY_ITEM}_PENDING`:
            return state
                .set('isLoading', true)
                .set('status', null)
                .set('categoryData', null)
        case `${LIBRARY_ITEM}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('categoryData', action.data.responseData.result)
        case `${LIBRARY_ITEM}_FAILED`:
            return state
                .set('isLoading', false)
                .set('status', null)
                .set('categoryData', null)
        case `${LIBRARY_DOCUMENTS}_PENDING`:
            return state
                .set('isLoading', true)
                .set('status', null)
                .set('documentsData', null)
        // .set('isListUpdated', false)
        case `${LIBRARY_DOCUMENTS}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('documentsData', action.data.responseData.result)
        // .set('isListUpdated', true)
        case `${LIBRARY_DOCUMENTS}_FAILED`:
            return state.set('isLoading', false).set('error', STATUS_FAILED)
        // .set('isListUpdated', false)
        case `${LIBRARY_DOCUMENTS_MORE}_PENDING`:
            return state.set('isLoading', true).set('status', null)
        case `${LIBRARY_DOCUMENTS_MORE}_SUCCESS`: {
            const docsData = state.get('documentsData')
            docsData.data.push(...action.data.responseData.result.data)
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('documentsData', docsData)
        }
        case `${LIBRARY_DOCUMENTS_MORE}_FAILED`:
            return state.set('isLoading', false).set('error', STATUS_FAILED)
        case `${LIBRARY_ITEM_ADD}_PENDING`:
            return state.set('isLoading', true).set('status', null)
        case `${LIBRARY_ITEM_ADD}_SUCCESS`: {
            const data = state.get('data')
            const reqData = action.data.reqData
            const name = reqData.get('name')
            const libraryId = reqData.get('libraryId')
            const libraryData = data.data.filter(obj => obj.id === libraryId)
            libraryData[0].list.push({
                name,
                libraryId,
                id: action.data.responseData.result.id,
                icon: action.data.responseData.result.image,
            })
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('data', data)
        }
        case `${LIBRARY_ITEM_ADD}_FAILED`:
            return state.set('isLoading', false).set('status', null)
        case `${LIBRARY_ITEM_REORDER}_PENDING`:
            return state.set('isLoading', true).set('status', null)
        case `${LIBRARY_ITEM_REORDER}_SUCCESS`: {
            const data = state.get('data')
            const { reqData } = action.data
            const { libraryId, list } = reqData
            const libraryData = data.data.filter(obj => obj.id === libraryId)
            libraryData[0].list = list
            // window.location.reload()
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('data', data)
        }
        case `${LIBRARY_ITEM_REORDER}_FAILED`:
            return state.set('isLoading', false).set('status', null)
        case `${LIBRARY_ITEM_EDIT}_PENDING`:
            return state.set('isLoading', true).set('status', null)
        case `${LIBRARY_ITEM_EDIT}_SUCCESS`: {
            const data = state.get('data')
            const reqData = action.data.reqData
            const id = reqData.get('id')
            const name = reqData.get('name')
            const libraryId = reqData.get('libraryId')
            const libraryData = data.data.filter(obj => obj.id === libraryId)
            const libraryListItem = libraryData[0].list.filter(obj => obj.id === id)
            libraryListItem[0]['name'] = name
            if (action.data.responseData.result.image) {
                libraryListItem[0]['icon'] = action.data.responseData.result.image
            }
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('data', data)
        }
        case `${LIBRARY_ITEM_EDIT}_FAILED`:
            return state
                .set('isLoading', false)
                .set('status', null)
                .set('error', null)

        case `${LIBRARY_ITEM_TRASH}_PENDING`:
            return state.set('isLoading', true).set('status', null)
        case `${LIBRARY_ITEM_TRASH}_SUCCESS`: {
            const { reqData, responseData } = action.data
            const { status } = responseData
            const data = state.get('data')
            const libraryId = reqData.categoryId
            const libraryData = data.data.filter(obj => obj.id === libraryId)
            const libraryListItemIndex = libraryData[0].list.findIndex(obj => obj.id === reqData.id)
            if (libraryListItemIndex > -1) {
                libraryData[0].list.splice(libraryListItemIndex, 1)
            }
            return state
                .set('isLoading', false)
                .set('status', status)
                .set('data', data)
        }
        case `${LIBRARY_ITEM_TRASH}_FAILED`:
            return state.set('isLoading', false).set('status', action.data.responseData)

        case `${LIBRARY_PHASE_ADD}_PENDING`:
            return state.set('isLoading', true).set('status', null)
        case `${LIBRARY_PHASE_ADD}_SUCCESS`: {
            const { reqData, responseData } = action.data
            const categoryData = state.get('categoryData')
            categoryData.data.push({
                id: responseData.result.id,
                name: reqData.name,
                list: [],
            })
            return state
                .set('isLoading', false)
                .set('status', responseData.status)
                .set('categoryData', categoryData)
        }
        case `${LIBRARY_PHASE_ADD}_FAILED`:
            return state.set('isLoading', false).set('status', action.data.responseData.result.message)
        case `${LIBRARY_PHASE_EDIT}_PENDING`:
            return state.set('isLoading', true).set('editStatus', null)
        case `${LIBRARY_PHASE_EDIT}_SUCCESS`: {
            const { reqData, responseData } = action.data
            const categoryData = state.get('categoryData')
            const phaseToEditInd = categoryData.data.findIndex(phase => phase.id === action.data.reqData.id)
            categoryData.data[phaseToEditInd].name = reqData.name
            return state
                .set('isLoading', false)
                .set('editStatus', responseData.status)
                .set('categoryData', categoryData)
        }
        case `${LIBRARY_PHASE_EDIT}_FAILED`:
            return state.set('isLoading', false).set('editStatus', action.data.responseData.status)

        case `${LIBRARY_PHASE_TRASH}_PENDING`:
            return state.set('isLoading', true).set('status', null)
        case `${LIBRARY_PHASE_TRASH}_SUCCESS`: {
            const { reqData, responseData } = action.data
            const categoryData = state.get('categoryData')
            const phaseToTrashInd = categoryData.data.findIndex(phase => phase.id === reqData.id)
            categoryData.data.splice(phaseToTrashInd, 1)
            return state
                .set('isLoading', false)
                .set('status', responseData.status)
                .set('data', categoryData)
        }
        case `${LIBRARY_PHASE_TRASH}_FAILED`:
            return state.set('isLoading', false).set('status', action.data.responseData.result.message)

        case `${LIBRARY_DOWNLOAD_DOCUMENTS}_PENDING`:
            return state
                .set('isLoading', true)
                .set('status', null)
                .set('downloadDocumentsData', null)
        case `${LIBRARY_DOWNLOAD_DOCUMENTS}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('downloadDocumentsData', action.data.responseData)
        case `${LIBRARY_DOWNLOAD_DOCUMENTS}_FAILED`:
            return state
                .set('isLoading', false)
                .set('status', null)
                .set('downloadDocumentsData', null)

        case `${LIBRARY_EMAIL_DOCUMENTS}_PENDING`:
            return state
                .set('isLoading', true)
                .set('status', null)
                .set('emailDocumentsData', null)
        case `${LIBRARY_EMAIL_DOCUMENTS}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('emailDocumentsData', action.data.responseData.status)
        case `${LIBRARY_EMAIL_DOCUMENTS}_FAILED`:
            return state
                .set('isLoading', false)
                .set('status', null)
                .set('emailDocumentsData', null)

        case `${LIBRARY_OWNER_GET}_PENDING`:
            return state.set('isLoading', true).set('status', null)

        case `${LIBRARY_OWNER_GET}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('libraryOwnerList', action.data.responseData.result.owners)
        case `${LIBRARY_OWNER_GET}_FAILED`:
            return state
                .set('isLoading', false)
                .set('status', null)
                .set('libraryOwnerList', null)

        case `${OWNER_LOCATION_GET}_PENDING`:
            return state
                .set('isLoading', true)
                .set('status', null)
                .set('ownerLocation', null)

        case `${OWNER_LOCATION_GET}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('ownerLocation', action.data.responseData.result.locations)

        case `${OWNER_LOCATION_GET}_FAILED`:
            return state
                .set('isLoading', false)
                .set('status', null)
                .set('ownerLocation', null)

        case `${LIBRARY_OWNER_ADD}_PENDING`:
            return state
                .set('isLoading', true)
                .set('status', null)
                .set('isListUpdated', false)
        case `${LIBRARY_OWNER_ADD}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('isListUpdated', true)
        case `${LIBRARY_OWNER_ADD}_FAILED`:
            return state
                .set('isLoading', false)
                .set('status', null)
                .set('isListUpdated', false)
        case `${UPDATE_OWNER}_PENDING`:
            return state
                .set('isLoading', true)
                .set('status', null)
                .set('isListUpdated', false)
        case `${UPDATE_OWNER}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('isListUpdated', true)
        case `${UPDATE_OWNER}_FAILED`:
            return state
                .set('isLoading', false)
                .set('status', null)
                .set('isListUpdated', false)
        case `${REMOVE_OWNER}_PENDING`:
            return state
                .set('isLoading', true)
                .set('status', null)
                .set('isListUpdated', false)
        case `${REMOVE_OWNER}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('isListUpdated', true)
        case `${REMOVE_OWNER}_FAILED`:
            return state
                .set('isLoading', false)
                .set('status', null)
                .set('isListUpdated', false)
        case `${TOGGLE_LIST_UPDATED_FLAG}`:
            return state.set('isListUpdated', false)

        case `${SHARE_DOCUMENTS_POST}_PENDING`:
            return state
                .set('isLoading', false)
                .set('shareDoc', action.data.responseData)
                .set('isListUpdated', true)

        case `${SHARE_DOCUMENTS_POST}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('shareDoc', action.data.responseData)
                .set('closePopUpBox', true)
                .set('isListUpdated', true)

        case `${SHARE_DOCUMENTS_POST}_FAILED`:
            return state
                .set('isLoading', false)
                .set('shareDoc', null)
                .set('isListUpdated', false)

        case `${SHARE_DOCUMENTS_GET}_PENDING`:
            return state
                .set('isLoading', false)
                .set('isCopyDoc', action.data.responseData)
                .set('isListUpdated', true)
                .set('closePopUpBox', false)

        case `${SHARE_DOCUMENTS_GET}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('isCopyDoc', action.data.responseData[0])
                .set('isListUpdated', true)

        case `${SHARE_DOCUMENTS_GET}_FAILED`:
            return state
                .set('isLoading', false)
                .set('isCopyDoc', null)
                .set('isListUpdated', false)

        case `${SHARE_DOCUMENT_POST}_PENDING`:
            return state
                .set('isLoading', false)
                .set('reGenLink', action.data.responseData)
                .set('isListUpdated', true)
                .set('closePopUpBox', false)

        case `${SHARE_DOCUMENT_POST}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('reGenLink', action.data.responseData)
                .set('isCopyDoc', action.data.responseData.document[0])
                .set('isListUpdated', true)

        case `${SHARE_DOCUMENT_POST}_FAILED`:
            return state
                .set('isLoading', false)
                .set('reGenLink', null)
                .set('isListUpdated', false)

        case `${MULTIPLE_SHARE_DOCS}_PENDING`:
            return state.set('isLoading', false).set('multipleShareDocs', action.data.responseData)

        case `${MULTIPLE_SHARE_DOCS}_SUCCESS`:
            return state.set('isLoading', false).set('multipleShareDocs', action.data.responseData)

        case `${MULTIPLE_SHARE_DOCS}_FAILED`:
            return state.set('isLoading', false).set('multipleShareDocs', action.data.responseData)

        default:
            return state
    }
}

export default libraryReducer
