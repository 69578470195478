import React, { Component } from 'react'
import GetStarted from './GetStarted'
import GoldStandard from './GoldStandard'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { saveDocuments } from '../../Containers/Library/actions'
import { contactWinRoomState } from '../../Containers/LopContent/actions'
import { contactWinRoomFlag } from '../../Containers/LopContent/selector'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'

import Articles from './Articles'
import { NavLink, Route, BrowserRouter as Router } from 'react-router-dom'

import './index.css'
import {
    LOP_SECTIONS,
    LOP_GETTING_STARTED,
    LOP_GOLD_STANDARD,
    LOP_DELIVERY,
    LOP_CASE_STUDIES,
    LOP_EXPERT_CV,
    LOP_CLIENT_REFERENCES,
    LOP_ARTICLES,
} from '../../Containers/Lop/constant'
import {
    isMobile,
    isIphoneX,
    IsIpadPortrait,
    SITE_TXT,
    ORG_WIN_ROOM_EMAIL_NEW,
    isUptoTabletPortrait,
} from '../../Utils/constants'

/*import CaseStudies from './CaseStudies'
import ExpertsCVs from './ExpertsCVs'
import Delivery from './Delivery'
import ClientReferences from './ClientReferences'*/
import ContactWinRoom from '../ContactWinRoom'
import { removeLopData } from '../../Utils/functions'

class Lop extends Component {
    constructor(props) {
        super(props)
        this.state = {
            lopSections: LOP_SECTIONS,
            scrollStep: 0,
        }
        localStorage.setItem('requestType', LOP_GETTING_STARTED)
    }

    setRequestType = id => {
        if (id === 'getting-started') {
            localStorage.setItem('requestType', LOP_GETTING_STARTED)
        } else if (id === 'articles') {
            localStorage.setItem('requestType', LOP_ARTICLES) //LOP_ARTICLES once available
        } else if (id === LOP_DELIVERY) {
            localStorage.setItem('requestType', LOP_DELIVERY)
        } else if (id === 'gold-standard-lop-materials') {
            localStorage.setItem('requestType', LOP_GOLD_STANDARD)
        } else if (id === 'case-studies') {
            localStorage.setItem('requestType', LOP_CASE_STUDIES)
        } else if (id === 'expert-cvs') {
            localStorage.setItem('requestType', LOP_EXPERT_CV)
        } else if (id === 'client-references') {
            localStorage.setItem('requestType', LOP_CLIENT_REFERENCES)
        }
    }

    clickPrev = e => {
        e.preventDefault()
        let ulEle = document.querySelector('.lop-sections ul')
        let scrl = 0
        if (parseInt(this.state.scrollStep) > 0) {
            scrl = parseInt(this.state.scrollStep) - parseInt(ulEle.clientWidth)
            this.setState({ scrollStep: scrl }, () => {
                ulEle.scrollTo(scrl, 0)
            })
        }
    }

    clickNext = e => {
        e.preventDefault()
        let ulEle = document.querySelector('.lop-sections ul')
        let scrl = parseInt(this.state.scrollStep) + parseInt(ulEle.clientWidth)
        if (parseInt(scrl) < parseInt(ulEle.clientWidth) * 3) {
            this.setState({ scrollStep: scrl }, () => {
                ulEle.scrollTo(scrl, 0)
            })
        }
    }

    lopTabs = url => (
        <TabList>
            {this.state.lopSections.map(({ id, name }) => (
                <Tab key={id} onClick={() => this.setRequestType(id)}>
                    <NavLink to={`${url}/${id}`}>{name}</NavLink>
                    {(isUptoTabletPortrait || isIphoneX) && <div className="blue-bar" />}
                </Tab>
            ))}
        </TabList>
    )

    componentDidMount = () => {
        this.reloadSelectedTab()
        this.tabRoutingThroughHistory()
        this.props.setLopHeader(true)
        removeLopData()
    }

    componentWillUnmount() {
        this.props.setLopHeader()
    }

    reloadSelectedTab = () => {
        if (document.querySelector('.lop-sections ul li a.active') === null) {
            document.querySelector('.lop-sections ul li:first-of-type a').click()
        } else {
            document.querySelector('.lop-sections ul li a.active').click()
        }
    }

    tabRoutingThroughHistory = () => {
        this.props.history.listen((location, action) => {
            const sectionsL = this.state.lopSections.length
            for (let i = 0; i < sectionsL; i++) {
                if (location.pathname.includes(this.state.lopSections[i].id)) {
                    document.querySelector('.lop-sections ul li:nth-child(' + (i + 1) + ')').click()
                }
            }
        })
    }

    handleCloseModal = () => {
        this.props.contactWinRoomState(false)
    }

    render() {
        const { match, contactWinRoomFlag } = this.props
        const LopHeader = () => (
            <section className="module-header">
                <span>
                    <p>
                        Working on an M&A proposal?
                        <br />
                        <br />
                        Contact
                        <a href={'mailto:' + ORG_WIN_ROOM_EMAIL_NEW} target="_top" rel="noopener noreferrer">
                            {ORG_WIN_ROOM_EMAIL_NEW}
                        </a>{' '}
                        to get connected to experts, content, and analyses (e.g., SynergyLab) to jumpstart your effort.
                    </p>
                    <b className="section-header">{SITE_TXT.TXT_LOP_CENTRAL}</b> &nbsp;&nbsp;
                    <div className="blue-bar" />
                    {SITE_TXT.TXT_STOPSHOP_LOP}
                    <br />
                    <br />
                </span>
            </section>
        )
        return (
            <Router>
                <main className="lop-container loptb">
                    <LopHeader />
                    <Tabs>
                        <div className="lop-sections">
                            {(isMobile || isIphoneX || IsIpadPortrait) && (
                                <span onClick={e => this.clickPrev(e)}>{'<'}</span>
                            )}
                            {this.lopTabs(match.url)}
                            {(isMobile || isIphoneX || IsIpadPortrait) && (
                                <span onClick={e => this.clickNext(e)}>{'>'}</span>
                            )}
                        </div>
                        <TabPanel>
                            <Route path="/lop/getting-started" render={props => <GetStarted {...this.props} />} />
                        </TabPanel>
                        <TabPanel>
                            <Route path="/lop/articles" render={props => <Articles {...this.props} />} />
                        </TabPanel>
                        <TabPanel>
                            <Route
                                path="/lop/gold-standard-lop-materials"
                                render={props => <GoldStandard {...this.props} />}
                            />
                        </TabPanel>
                        {/* <TabPanel>
                            <Route path="/lop/case-studies" render={props => <CaseStudies {...this.props} />} />
                        </TabPanel>
                        <TabPanel>
                            <Route
                                path="/lop/client-references"
                                render={props => <ClientReferences {...this.props} />}
                            />
                        </TabPanel>
                        <TabPanel>
                            <Route path="/lop/expert-cvs" render={props => <ExpertsCVs {...this.props} />} />
                        </TabPanel>
                        <TabPanel>
                            <Route path="/lop/delivery" render={props => <Delivery {...this.props} />} />
                        </TabPanel>*/}
                    </Tabs>
                    {contactWinRoomFlag && (
                        <ContactWinRoom onCloseModal={this.handleCloseModal} contactWinRoomFlag={contactWinRoomFlag} />
                    )}
                </main>
            </Router>
        )
    }
}

const stateToProps = createStructuredSelector({
    contactWinRoomFlag: contactWinRoomFlag(),
})

const dispatchProps = {
    contactWinRoomState,
    saveDocuments,
}

export default connect(stateToProps, dispatchProps)(Lop)

// export default Lop
