import React, { Component } from 'react'
import ReactHighcharts from 'react-highcharts'

class Graph extends Component {
    constructor(props) {
        super(props)
        this.state = { graph: this.props.graph }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.graph !== this.props.graph) {
            this.setState({
                graph: nextProps.graph,
            })
        }
    }

    render() {
        const { graph } = this.state
        const { yAxis = [] } = graph || {}
        let maxPoint = 0
        yAxis.forEach(({ value = [0] }) => {
            const highMaxY = value.reduce((a, b) => Math.max(a, b))
            maxPoint = maxPoint > highMaxY ? maxPoint : highMaxY
        })
        const yAxisMax = Math.ceil(maxPoint / 10) * 10
        const axisToUse = yAxisMax > 100 ? yAxisMax : 100
        let result = []
        graph &&
            graph.yAxis.forEach((v, k) => {
                result.push({
                    type: 'column',
                    name: v.name,
                    data: v.value,
                })
            })
        const config = {
            title: {
                text: '',
            },
            credits: { enabled: false },
            xAxis: {
                categories: graph && graph.xAxis,
            },
            yAxis: {
                min: 0,
                max: axisToUse,
                title: '',
                tickInterval: 10,
            },
            labels: {
                items: [{}],
            },
            series: result,
        }
        return (
            <div className="highcharts-style">
                <ReactHighcharts config={config}></ReactHighcharts>
            </div>
        )
    }
}

export default Graph
