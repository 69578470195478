import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectStatus, selectLoading, selectError } from './selector'
import { MMPractice } from '../../Components'
import { mmPracticeAction } from './actions'

const mapStateToProps = createStructuredSelector({
    isLoading: selectLoading(),
    status: selectStatus(),
    error: selectError(),
})
const mapDispatchToProps = {
    mmPractice: mmPracticeAction,
}
export default connect(mapStateToProps, mapDispatchToProps)(MMPractice)
