import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import c from 'classnames'
import { createStructuredSelector } from 'reselect'
import RichTextTool from '../RichTextTool'
import {
    addPartner,
    editPartner,
    deletePartner,
    setSortedArray,
    setOrderOfItem,
    fetchCurrentTemplate,
    updateLopSectionDetails,
    setElementToScroll,
    setLopWelcome,
    getLopWelcome,
    updateLopWelcome,
} from '../../../../Containers/LopContent/actions'
import { isListUpdated, welcomeVideo, isWelcomeLoading } from '../../../../Containers/LopContent/selector'

import { SITE_TXT, TEMPLATE_1 } from '../../../../Utils/constants'
import PartnerModal from './PartnerModal'
import { ConfirmAlertBox } from '../../../../Containers/Library/Alerts'
import { PencilIcon, TrashIcon, AddPartner } from '../../../../Utils/svg'
import { getPrefixedUrl } from '../../../../Utils/apis'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import arrayMove from 'array-move'
import './index.scss'
import { createMarkup } from '../../../../Utils/functions'
import Template1VideoModal from './VideoModal'

const SortableItemPartner = SortableElement(props => {
    return (
        <div className="partnerBox">
            <div>
                {props.editMode && (
                    <div className="button-group">
                        <span onClick={() => props.handleEdit(props.value)}>
                            <PencilIcon width="18px" height="18px" />
                        </span>
                        <span className="trash-black" onClick={() => props.handleDelete(props.value.id)}>
                            <TrashIcon width="13px" height="15px" />
                        </span>
                    </div>
                )}
                <img alt="" src={getPrefixedUrl(props.value.image)} className="partnerImage" height="80px" />
                <img alt="" src={getPrefixedUrl(props.value.signature)} className="partnerSign" height="40px" />
                <h5>{props.value.name}</h5>
                <p>{props.value.designation}</p>
            </div>
        </div>
    )
})

const SortablePartnerList = SortableContainer(props => {
    return (
        <div>
            {props.data.map((value, index) => (
                <SortableItemPartner
                    key={`item-${index}`}
                    index={index}
                    value={value}
                    disabled={!props.editMode || props.disableReorder}
                    handleDelete={props.handleDelete}
                    handleEdit={props.handleEdit}
                    editMode={props.editMode}
                />
            ))}
            {props.editMode && (
                <div className={'add-partner'}>
                    <span onClick={props.handleClick}>
                        <span>
                            <AddPartner />
                        </span>
                        <span>{SITE_TXT.TXT_ADD_PARTNER}</span>
                    </span>
                </div>
            )}
        </div>
    )
})

const SortableItem = SortableElement(({ value, editMode }) => <Fragment>{value}</Fragment>)

const SortableContainers = SortableContainer(({ children }) => {
    return <div className="main-items">{children}</div>
})

class Template1 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            type: null,
            open: false,
            isEditMode: false,
            modalOpen: false,
            selectedIndex: null,
            contentTypeId: null,
            isText: 'False',
            partnerData: [],
            partnerOrderList: [],
            ondeleteSuccessStatus: false,
            status: null,
            sectionId: props.sectionId,
            editMode: this.props.editMode || false,
            isListUpdated: false,
            anchors: null,
            videoEditData: {},
            layoutId: null,
            welcomeVideo: props.welcomeVideo || {},
            displayOrder: null,
            mainContent: [],
            updateWelcomeFlag: false,
            editWelcomeFlag: false,
            subHeading: '',
            mainContentItems: [1, 2],
            url: '',
            openVidDeleteModal: false,
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {
            welcomeVideo,
            editMode,
            content: { subHeading },
        } = nextProps
        if (nextProps.isListUpdated && nextProps.isListUpdated === true) {
            this.setState({ isListUpdated: nextProps.isListUpdated })
        }

        const { display_order: displayOrder } = welcomeVideo

        this.setState({
            welcomeVideo,
            displayOrder,
            editMode,
            subHeading,
        })
    }

    componentDidUpdate(prevProps, prevState) {
        const { sectionDetails, fetchCurrentTemplate, welcomeVideo } = this.props
        const { video_url } = welcomeVideo

        const { updateWelcomeFlag, editMode, editWelcomeFlag, subHeading, displayOrder } = this.state
        if (prevProps.isListUpdated !== this.state.isListUpdated) {
            const { sectionId, templateId } = sectionDetails
            this.handleCloseModal()
            fetchCurrentTemplate({ sectionId, templateId })
        }

        const videoUrlFrame = () => {
            if (video_url) {
                const iframe = `<iframe class="videoIframe" src=${video_url} allowfullscreen />`
                if (video_url.includes('mm-digital-hub.com') || video_url.includes('/mm/')) {
                    return (
                        <div className={`video-item ${editMode && 'moveIcon'}`}>
                            {editMode && (
                                <div className="control">
                                    <span onClick={() => this.onVideoEdit(video_url)}>
                                        <PencilIcon width="18px" height="18px" />
                                    </span>
                                    <span className="trash-black" onClick={() => this.onVideoDelete()}>
                                        <TrashIcon width="18px" height="18px" />
                                    </span>
                                </div>
                            )}
                            <video controls key={video_url}>
                                <source src={video_url} />
                            </video>
                        </div>
                    )
                } else {
                    return (
                        <div className={`video-item ${editMode && 'moveIcon'}`}>
                            {editMode && (
                                <div className="control">
                                    <span onClick={() => this.onVideoEdit(video_url)}>
                                        <PencilIcon width="18px" height="18px" />
                                    </span>
                                    <span className="trash-black" onClick={() => this.onVideoDelete()}>
                                        <TrashIcon width="18px" height="18px" />
                                    </span>
                                </div>
                            )}

                            <div dangerouslySetInnerHTML={createMarkup(iframe)} />
                        </div>
                    )
                }
            }
        }

        const editorContent = editMode => {
            if (editMode) {
                return (
                    <div className={`editor-item ${editMode && 'moveIcon'}`}>
                        <RichTextTool
                            richTextClassName="sectionTool"
                            richTextContent={subHeading}
                            onToolTextChange={this.onChange}
                            onChange={this.onChange}
                        />
                    </div>
                )
            } else {
                return (
                    <div className="editor-item">
                        <div dangerouslySetInnerHTML={{ __html: subHeading }} className="subHeadingContent" />
                    </div>
                )
            }
        }
        let mainContentElement = []
        if (Object.keys(welcomeVideo).length > 0) {
            if (displayOrder === 2) {
                mainContentElement.push(editorContent(editMode))
                mainContentElement.push(videoUrlFrame())
            } else {
                mainContentElement.push(videoUrlFrame())
                mainContentElement.push(editorContent(editMode))
            }

            if (updateWelcomeFlag === false) {
                this.setState({ mainContent: mainContentElement, updateWelcomeFlag: true })
            }
            if (editMode === true && editWelcomeFlag === false) {
                this.setState({ mainContent: mainContentElement, editWelcomeFlag: true })
            }

            if (prevState.subHeading !== this.state.subHeading) {
                this.setState({ mainContent: mainContentElement })
            }

            if (prevState.displayOrder !== this.state.displayOrder) {
                this.setState({ mainContent: mainContentElement })
            }
        } else {
            mainContentElement.push(editorContent(editMode))
            if (editMode === true && editWelcomeFlag === false) {
                this.setState({ mainContent: mainContentElement, editWelcomeFlag: true })
            }
            if (updateWelcomeFlag === false) {
                this.setState({ mainContent: mainContentElement, updateWelcomeFlag: true })
            }
            if (prevState.subHeading !== this.state.subHeading) {
                this.setState({ mainContent: mainContentElement })
            }

            if (prevState.displayOrder !== this.state.displayOrder) {
                this.setState({ mainContent: mainContentElement })
            }
        }
    }
    componentDidMount() {
        const anchors = document.querySelectorAll('a')
        this.setState({ anchors })
        if (anchors && anchors.length) {
            for (let i = 0; i < anchors.length; i++) {
                anchors[i].addEventListener('click', this.eventObj)
                anchors[i].addEventListener('touchstart', this.eventObj)
            }
        }

        const {
            getLopWelcome,
            isWelcomeLoading,
            sectionDetails: { sectionId },
        } = this.props
        if (!isWelcomeLoading) {
            getLopWelcome({ section_id: sectionId })
        }
    }
    componentWillUnmount() {
        const { anchors } = this.state
        if (anchors && anchors.length) {
            for (let i = 0; i < anchors.length; i++) {
                anchors[i].removeEventListener('click', this.eventObj)
                anchors[i].removeEventListener('touchstart', this.eventObj)
            }
        }
    }
    eventObj = e => {
        const locationToSend = e.target.href
        if (locationToSend && locationToSend.includes('noredirect')) {
            e.preventDefault()
            const { sections, updateCurrentSlide, setElementToScroll } = this.props
            const locationArray = locationToSend.split('/')
            const sectionId = locationArray[locationArray.length - 2]
            const elementId = locationArray[locationArray.length - 1]
            const sectionToMove = sections.findIndex(x => x.sectionId === sectionId)
            if (sectionToMove) {
                updateCurrentSlide(sectionToMove)
                setElementToScroll(elementId)
            }
        }
    }

    onChange = value => {
        const { updateLopSectionDetails } = this.props
        this.setState({ subHeading: value })
        updateLopSectionDetails('subHeading', value)
    }

    saveTemplateData = () => {
        const { sectionDetails, content, setOrderOfItem } = this.props
        const { sectionContent = [], heading, subHeading } = content
        const { sectionId, templateId } = sectionDetails

        let dataToSend = {
            sectionId,
            templateId,
            heading,
            subHeading,
            reorderItemIds: sectionContent.map(item => item.id).join(','),
        }
        setOrderOfItem(dataToSend)
    }
    handleClick = e => {
        e.preventDefault()
        this.saveTemplateData()
        this.setState({ open: true })
    }

    handleCloseModal = () => {
        this.setState({
            open: false,
            isEditMode: false,
            partnerData: {},
            openVidDeleteModal: false,
        })
    }

    handleEdit = item => {
        this.saveTemplateData()
        this.setState({
            open: true,
            partnerData: item,
            isEditMode: true,
        })
    }

    handleDelete = id => {
        this.saveTemplateData()
        this.setState({ ondeleteSuccessStatus: false })
        const title = SITE_TXT.TXT_CONFIRM_DELETE
        const message = SITE_TXT.TXT_PARTNER_DELETE_MESSAGE
        ConfirmAlertBox(title, message, () => this.trashFinally(id))
    }

    trashFinally = id => {
        const { deletePartner, sectionDetails } = this.props
        const { sectionId, templateId } = sectionDetails
        const data = {
            sectionId,
            templateId,
            id,
        }
        deletePartner(data)
        this.setState({ ondeleteSuccessStatus: true })
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        const { content } = this.props
        const { sectionContent = [] } = content
        this.props.setSortedArray(arrayMove(sectionContent, oldIndex, newIndex))
    }

    updateSelectedIndex = (selectedIndex, isText, layoutId, contentTypeId, isHeader, isTextArea) =>
        this.setState({ selectedIndex, isText, layoutId, contentTypeId })

    addNewContent = type =>
        this.setState({
            type,
            selectedIndex: 0,
            isText: TEMPLATE_1[type][0].isText,
            contentTypeId: TEMPLATE_1[type][0].contentTypeId,
            layoutId: TEMPLATE_1[type][0].layoutId || null,
        })

    toggleModal = () => {
        this.setState({ modalOpen: !this.state.modalOpen })
    }

    onMainSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const { mainContent } = this.state
            const { updateLopWelcome, welcomeVideo } = this.props
            const { id, section_detail_id, video_url, display_order } = welcomeVideo
            const data = {
                section_id: section_detail_id,
                display_order: display_order === 1 ? 2 : 1,
                video_url: video_url,
                section_detail_id: id,
                is_delete: 0,
            }
            this.setState({
                mainContent: arrayMove(mainContent, oldIndex, newIndex),
            })
            updateLopWelcome(data)
        }
    }

    onVideoEdit = url => {
        this.setState({ url, modalOpen: true })
    }

    onVideoDelete = () => {
        const title = SITE_TXT.TXT_VID_DELETE_HEADER
        const message = SITE_TXT.TXT_VID_DEL_MSG
        ConfirmAlertBox(title, message, () => this.handleYes())
    }

    handleYes = () => {
        const { updateLopWelcome, welcomeVideo } = this.props
        const { id, section_detail_id, video_url, display_order } = welcomeVideo

        const data = {
            section_id: section_detail_id,
            display_order: display_order,
            video_url: video_url,
            section_detail_id: id,
            is_delete: 1,
        }

        updateLopWelcome(data)

        this.handleCloseModal()
    }

    handleNo = () => {
        this.handleCloseModal()
    }

    render() {
        const {
            open,
            isEditMode,
            partnerData,
            sectionId,
            modalOpen,
            type,
            selectedIndex,
            mainContent,
            editMode,
            welcomeVideo,
        } = this.state
        const {
            content,
            disableReorder,
            addPartner,
            editPartner,
            isListUpdated,
            sectionDetails,
            setOrderOfItem,
            setLopWelcome,
            updateLopWelcome,
            isWelcomeLoading,
        } = this.props

        const { sectionContent = [] } = content
        const { TXT_ADD_ELE, TXT_LAYOUT, TXT_NEXT } = SITE_TXT

        return (
            <div className="section1Content">
                {mainContent.length > 0 && !isWelcomeLoading && (
                    <SortableContainers
                        onSortEnd={this.onMainSortEnd}
                        axis="xy"
                        distance={1}
                        helperClass="main-container"
                        lockToContainerEdges={true}
                        data={mainContent}
                    >
                        {mainContent.map((value, index) => (
                            <SortableItem
                                key={`item-${index}`}
                                index={index}
                                value={value}
                                disabled={!editMode || disableReorder}
                            />
                        ))}
                    </SortableContainers>
                )}

                {editMode && Object.keys(welcomeVideo).length === 0 && (
                    <div className="addCustomContent">
                        <h3>{TXT_ADD_ELE}</h3>
                        <section className="contentHeader">
                            {Object.keys(TEMPLATE_1).map(item => (
                                <div
                                    className={c({
                                        highlighted: type === item,
                                    })}
                                    onClick={() => this.addNewContent(item)}
                                    key={item}
                                >
                                    {item}
                                </div>
                            ))}
                        </section>

                        {type && (
                            <Fragment>
                                <h3>{TXT_LAYOUT}</h3>
                                <section className="contentItems">
                                    {TEMPLATE_1[type].map((item, i) => {
                                        const {
                                            Icon,
                                            DefaultIcon,
                                            content,
                                            isText,
                                            contentTypeId,
                                            isHeader,
                                            isTextArea,
                                            layoutId = null,
                                        } = item
                                        return (
                                            <div
                                                className={c(`selectorType${contentTypeId}`, {
                                                    highlighted: selectedIndex === i,
                                                })}
                                                onClick={() =>
                                                    this.updateSelectedIndex(
                                                        i,
                                                        isText,
                                                        layoutId,
                                                        contentTypeId,
                                                        isHeader,
                                                        isTextArea
                                                    )
                                                }
                                                key={i}
                                            >
                                                {selectedIndex === i ? <Icon /> : <DefaultIcon />}
                                                {content}
                                            </div>
                                        )
                                    })}
                                </section>
                                <div className="buttonContent">
                                    <span className="btn btn-outline-primary" onClick={() => this.toggleModal(true)}>
                                        {TXT_NEXT}
                                    </span>
                                </div>
                            </Fragment>
                        )}
                    </div>
                )}

                <div
                    className={c('partnerContainer', {
                        editmode: editMode,
                    })}
                >
                    <SortablePartnerList
                        data={sectionContent}
                        onSortEnd={this.onSortEnd}
                        axis="xy"
                        distance={1}
                        lockToContainerEdges={true}
                        editMode={editMode}
                        disableReorder={disableReorder}
                        handleDelete={this.handleDelete}
                        handleEdit={this.handleEdit}
                        handleClick={this.handleClick}
                        helperClass="abcd"
                    />
                </div>
                {open && (
                    <PartnerModal
                        onCloseModal={this.handleCloseModal}
                        isEditMode={isEditMode}
                        partnerData={partnerData}
                        sectionId={sectionId}
                        addPartner={addPartner}
                        editPartner={editPartner}
                        isListUpdated={isListUpdated}
                        sectionDetails={sectionDetails}
                        content={content}
                        setOrderOfItem={setOrderOfItem}
                    />
                )}

                {modalOpen && (
                    <Template1VideoModal
                        videoDetails={welcomeVideo}
                        onClose={this.toggleModal}
                        sectionDetails={sectionDetails}
                        addVideo={setLopWelcome}
                        editMode={editMode}
                        updateVideo={updateLopWelcome}
                    />
                )}
            </div>
        )
    }
}

Template1.propTypes = {
    editMode: PropTypes.bool.isRequired,
    disableReorder: PropTypes.bool.isRequired,
    isListUpdated: PropTypes.bool.isRequired,
    content: PropTypes.instanceOf(Object).isRequired,
    sectionDetails: PropTypes.instanceOf(Object).isRequired,
    deletePartner: PropTypes.func.isRequired,
    addPartner: PropTypes.func.isRequired,
    editPartner: PropTypes.func.isRequired,
    fetchCurrentTemplate: PropTypes.func.isRequired,
    updateLopSectionDetails: PropTypes.func.isRequired,
    setOrderOfItem: PropTypes.func.isRequired,
    setSortedArray: PropTypes.func.isRequired,
}

const stateToProps = createStructuredSelector({
    isListUpdated: isListUpdated(),
    welcomeVideo: welcomeVideo(),
    isWelcomeLoading: isWelcomeLoading(),
})

const dispatchProps = {
    addPartner,
    editPartner,
    deletePartner,
    setSortedArray,
    setOrderOfItem,
    fetchCurrentTemplate,
    updateLopSectionDetails,
    setElementToScroll,
    setLopWelcome,
    getLopWelcome,
    updateLopWelcome,
}

export default connect(stateToProps, dispatchProps)(Template1)
