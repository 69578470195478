import moment from 'moment'

export const ADMIN_UPLOAD_ADMIN = 'ADMIN_UPLOAD_ADMIN'
export const ADMIN_FETCH_TAGS = 'ADMIN_FETCH_TAGS'

export const ADMIN_FETCH_EXPERTS_TAGS = 'ADMIN_FETCH_EXPERTS_TAGS'

export const ADMIN_FETCH_USER_TYPE = 'ADMIN_FETCH_USER_TYPE'

export const ADMIN_FETCH_EXT_DOCUMENTS = 'ADMIN_FETCH_EXT_DOCUMENTS'

export const ADMIN_FETCH_DESIGNATION_TAGS = 'ADMIN_FETCH_DESIGNATION_TAGS'

export const ADMIN_CREATE_USER = 'ADMIN_CREATE_USER'
export const ADMIN_CREATE_TICKET = 'ADMIN_CREATE_TICKET'
export const ADMIN_VERIFICATION_EMAIL = 'ADMIN_VERIFICATION_EMAIL'
export const ADMIN_DOCUMENT_LOAD_MORE = 'ADMIN_DOCUMENT_LOAD_MORE'
export const ADMIN_EXPIRED_SEARCH = 'ADMIN_EXPIRED_SEARCH'
export const ADMIN_EXTEND_DOCUMENTS = 'ADMIN_EXTEND_DOCUMENTS'
export const ADMIN_ADD_USER_BACKEND = 'ADMIN_ADD_USER_BACKEND'
export const ADMIN_FETCH_DOC_DETAILS = 'ADMIN_FETCH_DOC_DETAILS'
export const ADMIN_REPLACE_DOC_DETAILS = 'ADMIN_REPLACE_DOC_DETAILS'
export const ADMIN_DELETE_DOC_DETAILS = 'ADMIN_DELETE_DOC_DETAILS'
export const ADMIN_USER_COUNT_DETAILS = 'ADMIN_USER_COUNT_DETAILS'
export const ADMIN_SESSION_TABLE = 'ADMIN_SESSION_TABLE'
export const ADMIN_BLOCK_UNBLOCK_USER = 'ADMIN_BLOCK_UNBLOCK_USER'
export const ADMIN_SESSION_TABLE_LOAD_MORE = 'ADMIN_SESSION_TABLE_LOAD_MORE'
export const ADMIN_FETCH_USER_DATA = 'ADMIN_FETCH_USER_DATA'
export const ADMIN_EDIT_USER_DATA = 'ADMIN_EDIT_USER_DATA'
export const ADMIN_GET_DOWNLOAD_LIMIT = 'ADMIN_GET_DOWNLOAD_LIMIT'
export const ADMIN_SET_DOWNLOAD_LIMIT = 'ADMIN_SET_DOWNLOAD_LIMIT'
export const ADMIN_GET_USER_PROFILE = 'ADMIN_GET_USER_PROFILE'
export const ADMIN_SET_USER_PROFILE = 'ADMIN_SET_USER_PROFILE'
export const ADMIN_SESSION_EMAIL = 'ADMIN_SESSION_EMAIL'
export const ADMIN_SESSION_EMAIL_COMPLETED = 'ADMIN_SESSION_EMAIL_COMPLETED'
export const VIDEO_UPLOAD = 'VIDEO_UPLOAD'
export const GET_VIDEO_DATA = 'GET_VIDEO_DATA'
export const DELETE_VIDEO_DATA = 'DELETE_VIDEO_DATA'
export const RESET_VIDEO_DATA = 'DELETE_VIDEO_DATA'
export const FETCH_ACTIVE_CST = 'FETCH_ACTIVE_CST'
export const FETCH_NEW_CST = 'FETCH_NEW_CST'
export const FETCH_EXPIRED_CST = 'FETCH_EXPIRED_CST'
export const LOAD_MORE_ACTIVE_CST = 'LOAD_MORE_ACTIVE_CST'
export const LOAD_MORE_NEW_CST = 'LOAD_MORE_NEW_CST'
export const LOAD_MORE_EXPIRED_CST = 'LOAD_MORE_EXPIRED_CST'
export const FETCH_CST_GROUP_DETAIL = 'FETCH_CST_GROUP_DETAIL'
export const UPDATE_CST_GROUP_DETAIL = 'UPDATE_CST_GROUP_DETAIL'
export const RESET_STATUS = 'RESET_STATUS'
export const GET_USER_COUNT = 'GET_USER_COUNT'
export const DELETE_USER = 'DELETE_USER'
export const GET_ACTIVE_USERS = 'GET_ACTIVE_USERS'
export const GET_LOP_STATS = 'GET_LOP_STATS'
export const BLK_UNBLK_CST_USER = 'BLK_UNBLK_CST_USER'
export const GET_DOCS_STATS = 'GET_DOCS_STATS'
export const GET_SHARED_DOCS = 'GET_SHARED_DOCS'
export const GET_RECIPIENT_LIST = 'GET_RECIPIENT_LIST'
export const DELETE_SHARED_DOC = 'DELETE_SHARED_DOC'

export const DOC_INITAL_STATE = {
    docName: '',
    docTags: [],
    docType: '',
    docExpiryDate: '',
    docFile: '',
    docSubCategoryId: '',
    docId: '',
}

export const USER_INITAL_STATE = {
    name: '',
    expertTags: [],
    designationTags: [],
    userType: '',
    userFile: '',
    userId: '',
    email: '',
    userTypeId: '',
}

export const docType = [
    {
        key: '1',
        value: 'Discussion document',
    },
    {
        key: '2',
        value: 'Article',
    },
    {
        key: '3',
        value: 'Toolbox',
    },
]

export const DOWNLOAD_INITAL_STATE = {
    CST: 0,
    Admin: 0,
    MM_Core: 0,
    Login_User: 0,
}
export const optionsRequestType = [
    {
        key: 'library',
        value: 'Library',
    },
    {
        key: 'lop',
        value: 'LOP',
    },
    {
        key: 'mylop',
        value: 'My Lop',
    },
]

export const lopDataColumns = [
    {
        title: 'Name',
        dataIndex: 'name',
        sorter: (a, b) => a.name.length - b.name.length,
        width: '150px',
    },
    {
        title: 'Created Date',
        dataIndex: 'created_date',
        // sorter: (a, b) => new Date(a.created_date) - new Date(b.created_date),
        width: '200px',
        sorter: (a, b) => moment(a.created_date).unix() - moment(b.created_date).unix(),
        align: 'center',
    },
    {
        title: 'Created By Name',
        dataIndex: 'created_by_name',
        sorter: (a, b) => a.created_by_name.length - b.created_by_name.length,
        align: 'center',
    },
    {
        title: 'Created By Email',
        dataIndex: 'created_by_email',
        sorter: (a, b) => a.created_by_email.length - b.created_by_email.length,
    },
    {
        title: 'Publish to external Viewer',
        dataIndex: 'published_to_external_viewer',
        sorter: (a, b) => a.published_to_external_viewer.length - b.published_to_external_viewer.length,
        align: 'center',
    },
    {
        title: 'Total External Viewer',
        dataIndex: 'total_external_viewer',
        sorter: (a, b) => a.total_external_viewer.length - b.total_external_viewer.length,
        align: 'center',
    },
]

export const docsDataColumns = [
    {
        title: 'Document Name',
        dataIndex: 'Name',
        sorter: (a, b) => a.Name.length - b.Name.length,
        width: '200px',
    },
    {
        title: 'Uploaded By',
        dataIndex: 'EmailId',
        width: '250px',
        sorter: (a, b) => (a.EmailId !== null ? a.EmailId.length - b.EmailId.length : ''),
        align: 'center',
    },
    {
        title: 'Date',
        dataIndex: 'dateShow',
        sorter: (a, b) => moment(a.UploadedAt).unix() - moment(b.UploadedAt).unix(),
        align: 'center',
    },
    {
        title: 'Box URL',
        dataIndex: 'boxUrlLink',
        align: 'center',

        // render: data => {
        //     let url = JSON.stringify(data.boxPerviewURL)
        //     let abc = url.slice(1, url.length - 1)
        //     return (
        //         <Anchor>
        //             <Link target="_blank" href={abc} title="Open link" />
        //         </Anchor>
        //     )
        // },
        // sorter: (a, b) => a.boxPerviewURL.length - b.boxPerviewURL.length,
    },
]

export const sharedDocsListColumns = [
    {
        title: 'Document Name',
        dataIndex: 'shared_file_name',
        sorter: (a, b) => {
            if (a.shared_file_name && b.shared_file_name) {
                return a.shared_file_name.length - b.shared_file_name.length
            } else return
        },
        width: '200px',
    },
    {
        title: 'Shared By',
        dataIndex: 'UserName',
        width: '250px',
        sorter: (a, b) => (a.UserName !== null ? a.UserName.length - b.UserName.length : ''),
        align: 'center',
    },
    {
        title: 'Date',
        dataIndex: 'dateShow',
        sorter: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix(),
        align: 'center',
    },
    {
        title: 'Box URL',
        dataIndex: 'boxUrlLink',
        // dataIndex: 'shared_file_id',
        // sorter: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix(),
        //https://mckinsey.box.com/file/
        align: 'center',
        key: 'shared_file_id',
        // render: data => {
        //     let tooltipText = 'Copy Link'
        //     const copyLink = () => {
        //         const el = document.createElement('textarea')
        //         el.value = `https://mckinsey.box.com/file/${data}`
        //         document.body.appendChild(el)
        //         el.select()
        //         document.execCommand('copy')
        //         tooltipText = 'Copied'
        //         document.body.removeChild(el)
        //     }
        //     return (
        //         <span data-tip={tooltipText} onClick={copyLink}>
        //             <LopHeaderLink style={{ cursor: 'pointer' }} />
        //             <ReactTooltip />
        //         </span>
        //     )
        // },
    },
    {
        title: 'Action',
        dataIndex: 'action',
        align: 'center',
    },
]
