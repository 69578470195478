import React, { Component } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import c from 'classnames'

import {
    HomeIcon,
    DeliverIcon,
    LibraryIcon,
    LearnIcon,
    /*MMPracticeIcon,*/
    LOPIcon,
    AdminIcon,
    DocumentIcon,
    VideoIcon,
    CreateUser,
    CSTIcon,
    StatisticIcon,
} from '../../Utils/svg'
import {
    SITE_TXT,
    isMobile,
    isAdmin,
    isCST,
    isIphoneX,
    isCSTLOP,
    isExternalClinet,
    isMM_Core,
} from '../../Utils/constants'
import './index.css'
class Sidebar extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    uploadDocPath() {
        const uploadPath = this.props.location.pathname.split('/')
        uploadPath.pop()
        return uploadPath.join('/')
    }
    render() {
        if (isExternalClinet) return null
        const {
            TXT_HOME,
            TXT_DELIVER,
            TXT_LIBRARY,
            TXT_LEARN,
            /*TXT_MM_PRACTICE,*/
            TXT_LOP,
            TXT_ADMIN,
            TXT_USER,
            TXT_DOCUMENTS,
            TXT_MM_CORE_CST,
            TXT_ADMIN_CORE_CST,
            TXT_VIDEOS,
            TEXT_STATISTICS,
        } = SITE_TXT
        const { location, disableSideBar } = this.props
        const finalPath = this.uploadDocPath()
        const colorFill = location.pathname === '/cstGroups' ? '#348ff1' : '#aaaaaa'
        return (
            <div
                className={c('sidebar-section', {
                    disabledList: disableSideBar,
                })}
            >
                {disableSideBar && <div className="sideBarOverContent" />}
                {location.pathname === '/admin' ||
                location.pathname === '/admin/documents' ||
                location.pathname === '/admin/adminData' ||
                location.pathname === '/admin/createUser' ||
                location.pathname === '/admin/videos' ||
                location.pathname === '/admin/statistic' ||
                finalPath === '/admin/createUser/edit' ? (
                    <aside className="sidebar-container">
                        <NavLink to="/admin/adminData" className="admin-nav">
                            <div className="verticalBar" />
                            <AdminIcon />
                            <span>{TXT_ADMIN}</span>
                        </NavLink>
                        <NavLink to="/admin/createUser" className="adduser-icon admin-nav">
                            <div className="verticalBar" />
                            <CreateUser fill={location.pathname === '/admin/createUser' ? '#348ff1' : '#aaaaaa'} />
                            <span>{TXT_USER}</span>
                        </NavLink>
                        <NavLink to="/admin/documents" className="admin-nav">
                            <div className="verticalBar" />
                            <DocumentIcon fill={location.pathname === '/admin/documents' ? '#348ff1' : '#aaaaaa'} />
                            <span>{TXT_DOCUMENTS}</span>
                        </NavLink>
                        <NavLink to="/admin/videos" className="admin-nav">
                            <div className="verticalBar" />
                            <VideoIcon fill={location.pathname === '/admin/videos' ? '#348ff1' : '#aaaaaa'} />
                            <span>{TXT_VIDEOS}</span>
                        </NavLink>
                        <NavLink to="/admin/statistic" className="admin-nav">
                            <div className="verticalBar" />
                            <StatisticIcon />
                            <span>{TEXT_STATISTICS}</span>
                        </NavLink>
                    </aside>
                ) : (
                    <aside className="sidebar-container">
                        {!isCST && !isCSTLOP && (
                            <NavLink exact to="/">
                                <div className="verticalBar" />
                                <HomeIcon />
                                <span>{TXT_HOME}</span>
                            </NavLink>
                        )}
                        <NavLink to="/deliver">
                            <div className="verticalBar" />
                            <DeliverIcon />
                            <span>{TXT_DELIVER}</span>
                        </NavLink>
                        <NavLink to="/library">
                            <div className="verticalBar" />
                            <LibraryIcon />
                            <span>{TXT_LIBRARY}</span>
                        </NavLink>
                        <NavLink to="/learn">
                            <div className="verticalBar" />
                            <LearnIcon />
                            <span>{TXT_LEARN}</span>
                        </NavLink>
                        {/*{!isCST && !isCSTLOP && (
                            <NavLink to="/mmpractice">
                                <div className="verticalBar" />
                                <MMPracticeIcon />
                                <span>{TXT_MM_PRACTICE}</span>
                            </NavLink>
                        )}*/}
                        {!isCST && (
                            <NavLink to="/lop/getting-started">
                                <div className="verticalBar" />
                                <LOPIcon />
                                <span>{TXT_LOP}</span>
                            </NavLink>
                        )}
                        {/*{!isCST && !isCSTLOP && (*/}
                        {/*    <NavLink to="/industry">*/}
                        {/*        <div className="verticalBar" />*/}
                        {/*        <IndustryIcon />*/}
                        {/*        <span>{TXT_INDUSTRY}</span>*/}
                        {/*    </NavLink>*/}
                        {/*)}*/}
                        {!isMobile && !isIphoneX && isAdmin ? (
                            <NavLink to="/admin/adminData">
                                <div className="verticalBar" />
                                <AdminIcon />

                                <span>{TXT_ADMIN}</span>
                            </NavLink>
                        ) : null}
                        {(isMM_Core || isAdmin) && (
                            <NavLink to="/cstGroups">
                                <div className="verticalBar" />
                                <CSTIcon colorFill={colorFill} />
                                <span>{isAdmin ? TXT_ADMIN_CORE_CST : TXT_MM_CORE_CST}</span>
                            </NavLink>
                        )}

                        {isCSTLOP && (
                            <NavLink to="/cstWithLopVideo" className="admin-nav">
                                <div className="verticalBar" />
                                <VideoIcon fill={location.pathname === '/admin/videos' ? '#348ff1' : '#aaaaaa'} />
                                <span>{TXT_VIDEOS}</span>
                            </NavLink>
                        )}
                    </aside>
                )}
            </div>
        )
    }
}

export default withRouter(Sidebar)
