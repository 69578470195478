import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-responsive-modal'
import { Button } from 'reactstrap'
import { Input } from 'reactstrap'
import c from 'classnames'

import './index.scss'

class DeleteLopModal extends Component {
    state = { value: '' }

    confirmDelete = () => {
        const { onClose, deleteLop } = this.props
        onClose()
        deleteLop()
    }

    handleChange = ({ target }) => {
        const { value } = target
        this.setState({ value })
    }

    render() {
        const { value } = this.state
        const { onClose } = this.props
        const styleClasses = {
            modal: 'albumModal deleteLopModal',
            overlay: 'overlayAlbumModal',
            closeButton: 'closeIcon',
        }
        return (
            <Modal open onClose={onClose} center classNames={styleClasses} closeOnOverlayClick={false}>
                <h2>Confirm LOP Delete</h2>
                <div className="textContent">
                    The current LOP would get deleted. Are you sure you want to delete it?
                    <br />
                    In order to delete the LOP, please type <b>DELETE</b> in the box provided below
                </div>
                <Input value={value} className="imageTitleInput" maxLength="50" onChange={this.handleChange} />
                <div className="btn-section">
                    <Button outline color="secondary" size="lg" onClick={onClose}>
                        No
                    </Button>
                    <div
                        className={c('btn btn-danger btn-lg', { active: value.toLowerCase() === 'delete' })}
                        onClick={this.confirmDelete}
                    >
                        <span className="closeButton">Yes</span>
                    </div>
                </div>
            </Modal>
        )
    }
}

DeleteLopModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    deleteLop: PropTypes.func.isRequired,
}

export default DeleteLopModal
