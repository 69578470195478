import {
    ADMIN_UPLOAD_ADMIN,
    ADMIN_FETCH_TAGS,
    ADMIN_FETCH_EXPERTS_TAGS,
    ADMIN_FETCH_USER_TYPE,
    ADMIN_FETCH_EXT_DOCUMENTS,
    ADMIN_FETCH_DESIGNATION_TAGS,
    ADMIN_CREATE_USER,
    ADMIN_DOCUMENT_LOAD_MORE,
    ADMIN_EXPIRED_SEARCH,
    ADMIN_EXTEND_DOCUMENTS,
    ADMIN_CREATE_TICKET,
    ADMIN_VERIFICATION_EMAIL,
    ADMIN_ADD_USER_BACKEND,
    ADMIN_FETCH_DOC_DETAILS,
    ADMIN_REPLACE_DOC_DETAILS,
    ADMIN_DELETE_DOC_DETAILS,
    ADMIN_USER_COUNT_DETAILS,
    ADMIN_SESSION_TABLE,
    ADMIN_BLOCK_UNBLOCK_USER,
    ADMIN_SESSION_TABLE_LOAD_MORE,
    ADMIN_FETCH_USER_DATA,
    ADMIN_EDIT_USER_DATA,
    ADMIN_GET_DOWNLOAD_LIMIT,
    ADMIN_SET_DOWNLOAD_LIMIT,
    ADMIN_GET_USER_PROFILE,
    ADMIN_SET_USER_PROFILE,
    ADMIN_SESSION_EMAIL,
    ADMIN_SESSION_EMAIL_COMPLETED,
    VIDEO_UPLOAD,
    GET_VIDEO_DATA,
    DELETE_VIDEO_DATA,
    RESET_VIDEO_DATA,
    FETCH_CST_GROUP_DETAIL,
    UPDATE_CST_GROUP_DETAIL,
    FETCH_ACTIVE_CST,
    FETCH_EXPIRED_CST,
    FETCH_NEW_CST,
    LOAD_MORE_EXPIRED_CST,
    LOAD_MORE_NEW_CST,
    LOAD_MORE_ACTIVE_CST,
    RESET_STATUS,
    GET_USER_COUNT,
    DELETE_USER,
    GET_ACTIVE_USERS,
    GET_LOP_STATS,
    BLK_UNBLK_CST_USER,
    GET_DOCS_STATS,
    GET_SHARED_DOCS,
    GET_RECIPIENT_LIST,
    DELETE_SHARED_DOC,
} from './constant'

export const uploadAdmin = data => ({
    type: `${ADMIN_UPLOAD_ADMIN}_PENDING`,
    data,
})

export const uploadAdminSuccess = data => ({
    type: `${ADMIN_UPLOAD_ADMIN}_SUCCESS`,
    data,
})

export const uploadAdminFailed = data => ({
    type: `${ADMIN_UPLOAD_ADMIN}_FAILED`,
    data,
})

export const fetchTags = data => ({
    type: `${ADMIN_FETCH_TAGS}_PENDING`,
    data,
})

export const fetchTagsSuccess = data => ({
    type: `${ADMIN_FETCH_TAGS}_SUCCESS`,
    data,
})

export const fetchTagsFailure = data => ({
    type: `${ADMIN_FETCH_TAGS}_FAILED`,
    data,
})

export const fetchExpertTags = data => ({
    type: `${ADMIN_FETCH_EXPERTS_TAGS}_PENDING`,
    data,
})

export const fetchExpertTagsSuccess = data => ({
    type: `${ADMIN_FETCH_EXPERTS_TAGS}_SUCCESS`,
    data,
})

export const fetchExpertTagsFailure = data => ({
    type: `${ADMIN_FETCH_EXPERTS_TAGS}_FAILED`,
    data,
})

export const fetchUserType = data => ({
    type: `${ADMIN_FETCH_USER_TYPE}_PENDING`,
    data,
})

export const fetchUserTypeSuccess = data => ({
    type: `${ADMIN_FETCH_USER_TYPE}_SUCCESS`,
    data,
})

export const fetchUserTypeFailure = data => ({
    type: `${ADMIN_FETCH_USER_TYPE}_FAILED`,
    data,
})
export const fetchExtDocuments = data => ({
    type: `${ADMIN_FETCH_EXT_DOCUMENTS}_PENDING`,
    data,
})

export const fetchExtDocumentsSuccess = data => ({
    type: `${ADMIN_FETCH_EXT_DOCUMENTS}_SUCCESS`,
    data,
})

export const fetchExtDocumentsFailure = data => ({
    type: `${ADMIN_FETCH_EXT_DOCUMENTS}_FAILED`,
    data,
})

export const fetchDesignationTags = data => ({
    type: `${ADMIN_FETCH_DESIGNATION_TAGS}_PENDING`,
    data,
})

export const fetchDesignationTagsSuccess = data => ({
    type: `${ADMIN_FETCH_DESIGNATION_TAGS}_SUCCESS`,
    data,
})

export const fetchDesignationTagsFailure = data => ({
    type: `${ADMIN_FETCH_DESIGNATION_TAGS}_FAILED`,
    data,
})

export const createUserAdmin = data => ({
    type: `${ADMIN_CREATE_USER}_PENDING`,
    data,
})

export const createUserAdminSuccess = data => ({
    type: `${ADMIN_CREATE_USER}_SUCCESS`,
    data,
})

export const createUserAdminFailed = data => ({
    type: `${ADMIN_CREATE_USER}_FAILED`,
    data,
})

export function adminDocumentLoadMore(data) {
    return {
        type: `${ADMIN_DOCUMENT_LOAD_MORE}_PENDING`,
        data,
    }
}
export function adminDocumentLoadMoreSuccess(data) {
    return {
        type: `${ADMIN_DOCUMENT_LOAD_MORE}_SUCCESS`,
        data,
    }
}

export function adminDocumentLoadMoreFailed(data) {
    return {
        type: `${ADMIN_DOCUMENT_LOAD_MORE}_FAILED`,
        data,
    }
}

export const expSearch = data => ({
    type: `${ADMIN_EXPIRED_SEARCH}_PENDING`,
    data,
})

export const expSearchSuccess = data => ({
    type: `${ADMIN_EXPIRED_SEARCH}_SUCCESS`,
    data,
})

export const expSearchFailure = data => ({
    type: `${ADMIN_EXPIRED_SEARCH}_FAILED`,
    data,
})

export const extendDoc = data => ({
    type: `${ADMIN_EXTEND_DOCUMENTS}_PENDING`,
    data,
})
export const extendDocSuccess = data => ({
    type: `${ADMIN_EXTEND_DOCUMENTS}_SUCCESS`,
    data,
})

export const extendDocFailure = data => ({
    type: `${ADMIN_EXTEND_DOCUMENTS}_FAILED`,
    data,
})

export const createUserTicket = data => ({
    type: `${ADMIN_CREATE_TICKET}_PENDING`,
    data,
})

export const createUserTicketSuccess = data => ({
    type: `${ADMIN_CREATE_TICKET}_SUCCESS`,
    data,
})

export const createUserTicketFailed = data => ({
    type: `${ADMIN_CREATE_TICKET}_FAILED`,
    data,
})
export const verificationEmail = data => ({
    type: `${ADMIN_VERIFICATION_EMAIL}_PENDING`,
    data,
})

export const verificationEmailSuccess = data => ({
    type: `${ADMIN_VERIFICATION_EMAIL}_SUCCESS`,
    data,
})

export const verificationEmailFailed = data => ({
    type: `${ADMIN_VERIFICATION_EMAIL}_FAILED`,
    data,
})
export const addUserBackend = data => ({
    type: `${ADMIN_ADD_USER_BACKEND}_PENDING`,
    data,
})

export const addUserBackendSuccess = data => ({
    type: `${ADMIN_ADD_USER_BACKEND}_SUCCESS`,
    data,
})

export const addUserBackendFailed = data => ({
    type: `${ADMIN_ADD_USER_BACKEND}_FAILED`,
    data,
})

export const fetchDocDetails = data => ({
    type: `${ADMIN_FETCH_DOC_DETAILS}_PENDING`,
    data,
})

export const fetchDocDetailsSuccess = data => ({
    type: `${ADMIN_FETCH_DOC_DETAILS}_SUCCESS`,
    data,
})

export const fetchDocDetailsFailed = data => ({
    type: `${ADMIN_FETCH_DOC_DETAILS}_FAILED`,
    data,
})
export const replaceDocDetails = data => ({
    type: `${ADMIN_REPLACE_DOC_DETAILS}_PENDING`,
    data,
})

export const replaceDocDetailsSuccess = data => ({
    type: `${ADMIN_REPLACE_DOC_DETAILS}_SUCCESS`,
    data,
})

export const replaceDocDetailsFailed = data => ({
    type: `${ADMIN_REPLACE_DOC_DETAILS}_FAILED`,
    data,
})

export const deleteDoc = data => ({
    type: `${ADMIN_DELETE_DOC_DETAILS}_PENDING`,
    data: data,
})

export const deleteDocSuccess = data => ({
    type: `${ADMIN_DELETE_DOC_DETAILS}_SUCCESS`,
    data,
})

export const deleteDocFailure = data => ({
    type: `${ADMIN_DELETE_DOC_DETAILS}_FAILED`,
    data,
})

export const getUserCount = data => ({
    type: `${ADMIN_USER_COUNT_DETAILS}_PENDING`,
    data: data,
})

export const getUserCountSuccess = data => ({
    type: `${ADMIN_USER_COUNT_DETAILS}_SUCCESS`,
    data,
})

export const getUserCountFailure = data => ({
    type: `${ADMIN_USER_COUNT_DETAILS}_FAILED`,
    data,
})

export const getAdminTableData = data => ({
    type: `${ADMIN_SESSION_TABLE}_PENDING`,
    data: data,
})

export const getAdminTableDataSuccess = data => ({
    type: `${ADMIN_SESSION_TABLE}_SUCCESS`,
    data,
})

export const getAdminTableDataFailure = data => ({
    type: `${ADMIN_SESSION_TABLE}_FAILED`,
    data,
})

export const blockUnblockUser = data => ({
    type: `${ADMIN_BLOCK_UNBLOCK_USER}_PENDING`,
    data: data,
})

export const blockUnblockUserSuccess = data => ({
    type: `${ADMIN_BLOCK_UNBLOCK_USER}_SUCCESS`,
    data,
})

export const blockUnblockUserFailure = data => ({
    type: `${ADMIN_BLOCK_UNBLOCK_USER}_FAILED`,
    data,
})
export const getAdminTableDataLoadMore = data => ({
    type: `${ADMIN_SESSION_TABLE_LOAD_MORE}_PENDING`,
    data: data,
})

export const getAdminTableDataLoadMoreSuccess = data => ({
    type: `${ADMIN_SESSION_TABLE_LOAD_MORE}_SUCCESS`,
    data,
})

export const getAdminTableDataLoadMoreFailure = data => ({
    type: `${ADMIN_SESSION_TABLE_LOAD_MORE}_FAILED`,
    data,
})

export const fetchUserData = data => ({
    type: `${ADMIN_FETCH_USER_DATA}_PENDING`,
    data: data,
})

export const fetchUserDataSuccess = data => ({
    type: `${ADMIN_FETCH_USER_DATA}_SUCCESS`,
    data,
})

export const fetchUserDataFailure = data => ({
    type: `${ADMIN_FETCH_USER_DATA}_FAILED`,
    data,
})

export const editUserData = data => ({
    type: `${ADMIN_EDIT_USER_DATA}_PENDING`,
    data: data,
})

export const editUserDataSuccess = data => ({
    type: `${ADMIN_EDIT_USER_DATA}_SUCCESS`,
    data,
})

export const editUserDataFailure = data => ({
    type: `${ADMIN_EDIT_USER_DATA}_FAILED`,
    data,
})

export const getDownloadLimit = data => ({
    type: `${ADMIN_GET_DOWNLOAD_LIMIT}_PENDING`,
    data: data,
})

export const getDownloadLimitSuccess = data => ({
    type: `${ADMIN_GET_DOWNLOAD_LIMIT}_SUCCESS`,
    data,
})

export const getDownloadLimitFailure = data => ({
    type: `${ADMIN_GET_DOWNLOAD_LIMIT}_FAILED`,
    data,
})

export const setDownloadLimit = data => ({
    type: `${ADMIN_SET_DOWNLOAD_LIMIT}_PENDING`,
    data: data,
})

export const setDownloadLimitSuccess = data => ({
    type: `${ADMIN_SET_DOWNLOAD_LIMIT}_SUCCESS`,
    data,
})

export const setDownloadLimitFailure = data => ({
    type: `${ADMIN_SET_DOWNLOAD_LIMIT}_FAILED`,
    data,
})

export const getUserProfile = data => ({
    type: `${ADMIN_GET_USER_PROFILE}_PENDING`,
    data,
})
export const getUserProfileSuccess = data => ({
    type: `${ADMIN_GET_USER_PROFILE}_SUCCESS`,
    data,
})

export const getUserProfileFailure = data => ({
    type: `${ADMIN_GET_USER_PROFILE}_FAILED`,
    data,
})
export const setUserProfile = data => ({
    type: `${ADMIN_SET_USER_PROFILE}_PENDING`,
    data,
})
export const setUserProfileSuccess = data => ({
    type: `${ADMIN_SET_USER_PROFILE}_SUCCESS`,
    data,
})

export const setUserProfileFailure = data => ({
    type: `${ADMIN_SET_USER_PROFILE}_FAILED`,
    data,
})

export const getUserEmail = data => ({
    type: ADMIN_SESSION_EMAIL,
    data,
})

export const getUserEmailComplete = (data = []) => ({
    type: ADMIN_SESSION_EMAIL_COMPLETED,
    data,
})

export const uploadVideo = data => ({
    type: `${VIDEO_UPLOAD}_PENDING`,
    data,
})
export const uploadVideoSuccess = data => ({
    type: `${VIDEO_UPLOAD}_SUCCESS`,
    data,
})

export const uploadVideoFailure = data => ({
    type: `${VIDEO_UPLOAD}_FAILED`,
    data,
})

export const getAdminVideo = data => ({
    type: `${GET_VIDEO_DATA}_PENDING`,
    data,
})

export const getAdminVideoSuccess = data => ({
    type: `${GET_VIDEO_DATA}_SUCCESS`,
    data,
})

export const getAdminVideoFailure = data => ({
    type: `${GET_VIDEO_DATA}_FAILED`,
    data,
})

export const deleteAdminVideo = id => ({
    type: `${DELETE_VIDEO_DATA}_PENDING`,
    id,
})

export const deleteAdminVideoSuccess = data => ({
    type: `${DELETE_VIDEO_DATA}_SUCCESS`,
    data,
})

export const deleteAdminVideoFailure = data => ({
    type: `${DELETE_VIDEO_DATA}_FAILED`,
    data,
})

export const resetAdminVideo = () => ({
    type: RESET_VIDEO_DATA,
})

export const fetchActiveCST = data => ({
    type: `${FETCH_ACTIVE_CST}_PENDING`,
    data,
})

export const fetchActiveCSTSuccess = data => ({
    type: `${FETCH_ACTIVE_CST}_SUCCESS`,
    data,
})

export const fetchActiveCSTFailure = data => ({
    type: `${FETCH_ACTIVE_CST}_FAILED`,
    data,
})
export const fetchNewCST = data => ({
    type: `${FETCH_NEW_CST}_PENDING`,
    data,
})

export const fetchNewCSTSuccess = data => ({
    type: `${FETCH_NEW_CST}_SUCCESS`,
    data,
})

export const fetchNewCSTFailure = data => ({
    type: `${FETCH_NEW_CST}_FAILED`,
    data,
})
export const fetchExpiredCST = data => ({
    type: `${FETCH_EXPIRED_CST}_PENDING`,
    data,
})

export const fetchExpiredCSTSuccess = data => ({
    type: `${FETCH_EXPIRED_CST}_SUCCESS`,
    data,
})

export const fetchExpiredCSTFailure = data => ({
    type: `${FETCH_EXPIRED_CST}_FAILED`,
    data,
})

export const fetchCSTById = data => ({
    type: `${FETCH_CST_GROUP_DETAIL}_PENDING`,
    data,
})

export const fetchCSTByIdSuccess = data => ({
    type: `${FETCH_CST_GROUP_DETAIL}_SUCCESS`,
    data,
})

export const fetchCSTByIdFailure = data => ({
    type: `${FETCH_CST_GROUP_DETAIL}_FAILED`,
    data,
})

export const updateCSTGroupDetail = data => ({
    type: `${UPDATE_CST_GROUP_DETAIL}_PENDING`,
    data,
})

export const updateCSTGroupDetailSuccess = data => ({
    type: `${UPDATE_CST_GROUP_DETAIL}_SUCCESS`,
    data,
})

export const updateCSTGroupDetailFailure = data => ({
    type: `${UPDATE_CST_GROUP_DETAIL}_FAILED`,
    data,
})

export const loadMoreActiveCST = data => ({
    type: `${LOAD_MORE_ACTIVE_CST}_PENDING`,
    data,
})

export const loadMoreActiveCSTSuccess = data => ({
    type: `${LOAD_MORE_ACTIVE_CST}_SUCCESS`,
    data,
})

export const loadMoreActiveCSTFailure = data => ({
    type: `${LOAD_MORE_ACTIVE_CST}_FAILED`,
    data,
})
export const loadMoreNewCST = data => ({
    type: `${LOAD_MORE_NEW_CST}_PENDING`,
    data,
})

export const loadMoreNewCSTSuccess = data => ({
    type: `${LOAD_MORE_NEW_CST}_SUCCESS`,
    data,
})

export const loadMoreNewCSTFailure = data => ({
    type: `${LOAD_MORE_NEW_CST}_FAILED`,
    data,
})
export const loadMoreExpiredCST = data => ({
    type: `${LOAD_MORE_EXPIRED_CST}_PENDING`,
    data,
})

export const loadMoreExpiredCSTSuccess = data => ({
    type: `${LOAD_MORE_EXPIRED_CST}_SUCCESS`,
    data,
})

export const loadMoreExpiredCSTFailure = data => ({
    type: `${LOAD_MORE_EXPIRED_CST}_FAILED`,
    data,
})
export const resetStatus = () => ({
    type: `${RESET_STATUS}`,
})

export const getAdminUserCount = data => ({
    type: `${GET_USER_COUNT}_PENDING`,
    data,
})
export const getAdminUserCountSuccess = data => ({
    type: `${GET_USER_COUNT}_SUCCESS`,
    data,
})
export const getAdminUserCountFailure = data => ({
    type: `${GET_USER_COUNT}_FAILED`,
    data,
})

export const deleteUser = data => ({
    type: `${DELETE_USER}_PENDING`,
    data,
})
export const deleteUserSuccess = data => ({
    type: `${DELETE_USER}_SUCCESS`,
    data,
})
export const deleteUserFailure = data => ({
    type: `${DELETE_USER}_FAILED`,
    data,
})

export const getActiveUsers = data => ({
    type: `${GET_ACTIVE_USERS}_PENDING`,
    data,
})
export const getActiveUsersSuccess = data => ({
    type: `${GET_ACTIVE_USERS}_SUCCESS`,
    data,
})
export const getActiveUsersFailure = data => ({
    type: `${GET_ACTIVE_USERS}_FAILED`,
    data,
})

export const getLopStats = data => ({
    type: `${GET_LOP_STATS}_PENDING`,
    data,
})
export const getLopStatsSuccess = data => ({
    type: `${GET_LOP_STATS}_SUCCESS`,
    data,
})
export const getLopStatsFailure = data => ({
    type: `${GET_LOP_STATS}_FAILED`,
    data,
})

export const blkUnBlkCST = data => ({
    type: `${BLK_UNBLK_CST_USER}_PENDING`,
    data,
})
export const blkUnBlkCSTSuccess = data => ({
    type: `${BLK_UNBLK_CST_USER}_SUCCESS`,
    data,
})
export const blkUnBlkCSTFailure = data => ({
    type: `${BLK_UNBLK_CST_USER}_FAILED`,
    data,
})

export const getDocsStats = data => ({
    type: `${GET_DOCS_STATS}_PENDING`,
    data,
})
export const getDocsStatsSuccess = data => ({
    type: `${GET_DOCS_STATS}_SUCCESS`,
    data,
})
export const getDocsStatsFailure = data => ({
    type: `${GET_DOCS_STATS}_FAILED`,
    data,
})

export const getSharedDocsList = data => ({
    type: `${GET_SHARED_DOCS}_PENDING`,
    data,
})
export const getSharedDocsListSuccess = data => ({
    type: `${GET_SHARED_DOCS}_SUCCESS`,
    data,
})
export const getSharedDocsListFailure = data => ({
    type: `${GET_SHARED_DOCS}_FAILED`,
    data,
})

export const getRecipientsList = data => ({
    type: `${GET_RECIPIENT_LIST}_PENDING`,
    data,
})
export const getRecipientsListSuccess = data => ({
    type: `${GET_RECIPIENT_LIST}_SUCCESS`,
    data,
})
export const getRecipientsListFailure = data => ({
    type: `${GET_RECIPIENT_LIST}_FAILED`,
    data,
})

export const deleteSelectedDocument = data => ({
    type: `${DELETE_SHARED_DOC}_PENDING`,
    data,
})
export const deleteSelectedDocumentSuccess = data => ({
    type: `${DELETE_SHARED_DOC}_SUCCESS`,
    data,
})
export const deleteSelectedDocumentFailure = data => ({
    type: `${DELETE_SHARED_DOC}_FAILED`,
    data,
})
