import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Search } from '../../Components'
import { searchAction, searchFetchAction, searchLoadMoreAction, recentlySearched } from './actions'
import { selectStatus, selectLoading, selectError, selectData, selectMetaData, recentlySearchedData } from './selector'

const mapStateToProps = createStructuredSelector({
    isLoading: selectLoading(),
    status: selectStatus(),
    error: selectError(),
    data: selectData(),
    metaData: selectMetaData(),
    recentlySearchedData: recentlySearchedData(),
})
const mapDispatchToProps = {
    searchFetchOptions: searchFetchAction,
    searchLoadMore: searchLoadMoreAction,
    search: searchAction,
    recentlySearched: recentlySearched,
}
export default connect(mapStateToProps, mapDispatchToProps)(Search)
