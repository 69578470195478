import React, { Component } from 'react'
import Loader from '../Loader'
import { DownloadIcon, GoToLink, VideoLink, NoImageIcon } from '../../Utils/svg'
import { SITE_TXT, isUptoTabletPortrait, ENTER_KEY_CODE } from '../../Utils/constants'
import { Link } from 'react-router-dom'
import { getPrefixedUrl } from '../../Utils/apis'
import './index.css'
import { createMarkup } from '../../Utils/functions'
import Modal from 'react-responsive-modal'
import Slider from 'react-slick'
class DeliverDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            secondlastParam: '',
            fetchedData: {},
            downloadLoader: false,
            downloadFile: false,
            open: false,
        }
    }
    downloadBrochureHandler = () => {
        this.setState({ downloadFile: true }, () => {
            this.props.downloadDeliverBrochure(this.state.fetchedData.id)
        })
    }
    onCloseModal = () => {
        this.setState({ open: false })
    }
    onOpenModal = () => {
        this.setState({ open: true })
    }
    componentDidMount() {
        let currentLocation = window.location.href
        let toolId = currentLocation.slice(currentLocation.lastIndexOf('/') + 1)
        let secondlastParam = currentLocation.split('/').slice(-2)[0]

        this.setState(
            {
                secondlastParam: secondlastParam,
                tool: {
                    ...this.state.tool,
                    toolId: toolId,
                },
            },
            () => {
                this.props.fetchDeliverItem(toolId)
            }
        )
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.selectfetchData) {
            this.setState({ fetchedData: nextProps.selectfetchData })
        }
        if (this.state.downloadFile === true) {
            if (nextProps.downloadBrochureData !== null) {
                if (nextProps.downloadBrochureData.result && nextProps.downloadBrochureData.result.fileURL) {
                    this.setState({ downloadFile: false })
                    this.props.saveDocuments(getPrefixedUrl(nextProps.downloadBrochureData.result.fileURL))
                }
            }
        }
    }

    enterKeyHandler = (e, actionType) => {
        if (e.which === ENTER_KEY_CODE) {
            if (actionType === 'downloadBrochure') {
                this.downloadBrochureHandler()
            } else {
                this.onOpenModal()
            }
        }
    }

    render() {
        const { fetchedData, downloadLoader, open } = this.state
        const fetchedDescription = fetchedData.description || ''
        const { TXT_NO_IMAGE, TXT_DOWNLOAD_BROCHURE, TXT_GO_TO_TOOL, TXT_WATCH_VIDEO } = SITE_TXT
        const iframe = `<iframe class="video-iframe" src=${fetchedData.videoLink} allowfullscreen allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture frameborder="0"></iframe>`

        const detailImg = fetchedDataImg =>
            fetchedDataImg ? (
                <img src={getPrefixedUrl(fetchedDataImg)} alt="img2" width="362" height="232" className="border-css" />
            ) : (
                <div className="img-svg-noImg1">
                    <NoImageIcon />
                    <br />
                    {TXT_NO_IMAGE}
                </div>
            )

        return (
            <span className="loader-span">
                {downloadLoader ? <Loader /> : null}
                <main className="deliver-container">
                    <div className="heading-bookmark">
                        <Link to="/deliver">Deliver</Link> &nbsp;/ &nbsp;&nbsp;
                        {fetchedData.name}
                    </div>
                    <div className="deliver-details">
                        {!isUptoTabletPortrait && (
                            <div className="first-div-deliver-detail">
                                <div className="upper-div-first-section" />
                                <div className="middle-div-first-section">{detailImg(fetchedData.image2)}</div>
                                <div className="lower-div-first-section" />
                            </div>
                        )}
                        {!isUptoTabletPortrait && (
                            <div className="sec-div-deliver-detail">
                                <div className="upper-div-sec-section">{detailImg(fetchedData.image1)}</div>
                                <div className="middle-div-sec-section">{detailImg(fetchedData.image3)}</div>
                                <div className="lower-div-sec-section" />
                            </div>
                        )}
                        {isUptoTabletPortrait && (
                            <Slider>
                                <div className="img1">{detailImg(fetchedData.image1)}</div>
                                <div className="img2">{detailImg(fetchedData.image2)}</div>
                                <div className="img3">{detailImg(fetchedData.image3)}</div>
                            </Slider>
                        )}
                        <div className="third-div-deliver-detail">
                            <div className="upper-div-third-section">
                                <div className="heading-third-section">{fetchedData.name}</div>

                                <div className="blue-bar-deliver" />
                            </div>
                            <div className="middle-div-third-section">
                                <div className="middle-div-text">
                                    <div dangerouslySetInnerHTML={createMarkup(fetchedDescription)} />
                                </div>
                            </div>
                            <div className="lower-div-third-section">
                                <a
                                    className="go-to-text"
                                    href={fetchedData.toolLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <div className="btn btn-lg btn-primary">
                                        <span> {TXT_GO_TO_TOOL} </span>

                                        <span className="go-to-link">
                                            <GoToLink />
                                        </span>
                                    </div>
                                </a>
                            </div>

                            <div className="deliver-detail-action">
                                {fetchedData.brochure ? (
                                    <span
                                        tabIndex="0"
                                        onClick={() => this.downloadBrochureHandler()}
                                        onKeyUp={e => this.enterKeyHandler(e, 'downloadBrochure')}
                                    >
                                        <DownloadIcon width="14" height="14" fill="#0079ff" />
                                        <span className="lower-div-text action-icon"> {TXT_DOWNLOAD_BROCHURE}</span>
                                    </span>
                                ) : null}{' '}
                                <span
                                    onClick={this.onOpenModal}
                                    onKeyUp={e => this.enterKeyHandler(e, 'openVideo')}
                                    tabIndex="0"
                                >
                                    <VideoLink width="14" height="14" />
                                    <span className="lower-div-text action-icon"> {TXT_WATCH_VIDEO}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <Modal
                        open={open}
                        classNames={{
                            modal: 'comp-modal deliver-detail-modal',
                        }}
                        onClose={this.onCloseModal}
                        center
                    >
                        <h2 className="heading-class">{fetchedData.name}</h2>
                        {fetchedData.videoLink && fetchedData.videoLink.includes('ohi-npn-upload-image') ? (
                            <video controls>
                                <source src={fetchedData.videoLink} />
                            </video>
                        ) : (
                            <div dangerouslySetInnerHTML={createMarkup(iframe)} />
                        )}
                    </Modal>
                </main>
            </span>
        )
    }
}

export default DeliverDetail
