import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-responsive-modal'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import loadImage from 'blueimp-load-image'

import ImageResizer from '../ImageResizer'
import { selectWidth } from '../../Containers/App/selector'
import { TickIconNew, DeleteIcon, AddIcon } from '../../Utils/svg'
import { SITE_TXT, ENTER_KEY_CODE } from '../../Utils/constants'
import { getPrefixedUrl } from '../../Utils/apis'
import Loader from '../Loader'
import 'react-image-crop/dist/ReactCrop.css'
import './index.css'

class ThumbnailEditorModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isModalOpen: this.props.isModalOpen,
            thumbnailId: this.props.thumbnailId,
            thumbnailImg: this.props.thumbnailImg,
            thumbnailName: this.props.thumbnailName,
            libraryId: this.props.libraryId,
            thumbnailImgSrc: null,
            crop: this.props.imgCropObj,
            inEdit: false,
            croppedImg: null,
            originalImg: null,
            onlyImage: this.props.onlyImage,
            userImg1Src: this.props.userImg1Src || null,
            deliverImgageSrc: this.props.deliverImgageSrc || null,
            thumbImages: this.props.thumbImages || null,
            imageId: this.props.imageId || null,
            showLoader: false,
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            deliverImgageSrc: nextProps.deliverImgageSrc,
            thumbImages: nextProps.thumbImages,
        })
    }
    onCloseModal = () => {
        this.props.editCancel()
    }

    getBase64FromImageUrl = url => {
        const img = new Image()
        const that = this
        img.setAttribute('crossOrigin', 'anonymous')
        img.onload = function() {
            const canvas = document.createElement('canvas')
            canvas.width = this.width
            canvas.height = this.height
            const ctx = canvas.getContext('2d')
            ctx.drawImage(this, 0, 0)
            const dataURL = canvas.toDataURL('image/png')
            that.setState({
                thumbnailImgSrc: dataURL,
                inEdit: true,
                showLoader: false,
            })
        }
        img.src = url
    }

    clickToBrowse = () => {
        const { useOriginal } = this.props
        if (useOriginal) {
            this.getBase64FromImageUrl(getPrefixedUrl(useOriginal))
            this.setState({
                showLoader: true,
            })
        } else {
            this.browseFile.click()
        }
    }

    handleFile = e => {
        this.setState({ inEdit: true })
        if (e.target.files.length === 0) {
            alert(SITE_TXT.TXT_INVALID_FILE_EXT)
            return
        }
        const { appWidth, isAdminPage } = this.props
        if (e.target.files && e.target.files.length > 0) {
            if (appWidth <= 768) {
                this.setState({ showLoader: true })
                loadImage(
                    e.target.files[0],
                    canvas => {
                        this.setState({
                            thumbnailImgSrc: canvas.toDataURL('image/png'),
                            showLoader: false,
                        })
                    },
                    { orientation: true }
                )
            } else {
                const reader = new FileReader()
                reader.addEventListener(
                    'loadend',
                    () =>
                        this.setState({
                            thumbnailImgSrc: reader.result,
                        }),
                    false
                )
                reader.readAsDataURL(e.target.files[0])
            }
            if (isAdminPage) {
                this.setState({ originalImg: e.target.files[0] })
            }
        }
    }

    clickTick = () => {
        const { thumbnailId, croppedImg } = this.state
        this.props.handleSave(
            thumbnailId,
            this.formElemThumb,
            croppedImg !== null ? croppedImg : this.state.originalImg
        )
    }

    enterKeyHandler = e => {
        if (e.which === ENTER_KEY_CODE) {
            this.clickTick()
        }
    }

    componentDidMount() {
        this.setState({ origImg: this.origImg })
    }

    getResizedImg(fileObj) {
        this.setState({ croppedImg: fileObj })
    }

    getnewImages = () => {
        const { imageId, thumbImages } = this.state
        if (imageId === 'image1' && thumbImages.deliverImg1Src) {
            return thumbImages.deliverImg1Src
        } else if (imageId === 'image2' && thumbImages.deliverImg2Src) {
            return thumbImages.deliverImg2Src
        } else if (imageId === 'image3' && thumbImages.deliverImg3Src) {
            return thumbImages.deliverImg3Src
        } else if (imageId === 'toolIcon' && thumbImages.deliverImgIconSrc) {
            return thumbImages.deliverImgIconSrc
        }
    }

    render() {
        const { isModalOpen, thumbnailImgSrc, thumbnailImg, thumbnailName, crop, inEdit, showLoader } = this.state

        const { modalClass, overlayClass, useOriginal, crossOrigin } = this.props
        const styleClasses = {
            modal: `modal-custom  ${modalClass ? modalClass : ''} ${
                !thumbnailImg && !thumbnailImgSrc ? 'no_image' : ''
            }`,
            overlay: `overlay-custom  ${overlayClass ? overlayClass : ''}`,
            closeButton: 'closeBtn-custom',
        }
        const {
            TXT_PLACEHOLDER,
            TXT_CLICK_TOADD_IMAGE,
            TXT_CLICK_TOCHANGE_IMAGE,
            TXT_CLICK_TOCHANGE_LOPIMAGE,
            TXT_CREATE_USER_FILES_ACCEPTED,
        } = SITE_TXT

        return (
            <Modal
                open={isModalOpen ? isModalOpen : false}
                onClose={this.onCloseModal}
                center
                classNames={styleClasses}
                closeOnOverlayClick={false}
            >
                <div className="editable-item-modal full-image">
                    <form encType="multipart/form-data" ref={elemThumb => (this.formElemThumb = elemThumb)}>
                        <input
                            type="file"
                            ref={ele => (this.browseFile = ele)}
                            onChange={e => this.handleFile(e)}
                            accept={TXT_CREATE_USER_FILES_ACCEPTED}
                        />

                        {!inEdit ? (
                            <span className="image-container">
                                {thumbnailImg ? (
                                    <span className="img-span" onClick={this.clickToBrowse}>
                                        <img
                                            ref={elem => (this.origImg = elem)}
                                            alt="BrowseImageHere"
                                            src={
                                                this.state.userImg1Src ||
                                                this.getnewImages() ||
                                                getPrefixedUrl(thumbnailImg)
                                            }
                                        />
                                        <label>
                                            {useOriginal ? TXT_CLICK_TOCHANGE_LOPIMAGE : TXT_CLICK_TOCHANGE_IMAGE}
                                        </label>
                                    </span>
                                ) : (
                                    <span className="iconSpan" onClick={() => this.clickToBrowse()}>
                                        <span>
                                            <AddIcon fillColor="#ffffff" />
                                        </span>
                                        <label>{TXT_CLICK_TOADD_IMAGE}</label>
                                    </span>
                                )}
                            </span>
                        ) : thumbnailImgSrc ? (
                            <ImageResizer
                                origImg={thumbnailImg}
                                crop={crop}
                                quality={0.5} //this could be made more adjustable if required
                                imgDataUrl={thumbnailImgSrc}
                                getResizedImg={data => this.getResizedImg(data)}
                                crossOrigin={crossOrigin}
                            />
                        ) : (
                            <Loader />
                        )}
                        {!this.props.onlyImage && (
                            <textarea
                                autoFocus
                                defaultValue={thumbnailName}
                                placeholder={TXT_PLACEHOLDER}
                                name="titleThumb"
                                onKeyUp={e => this.enterKeyHandler(e)}
                            />
                        )}
                    </form>

                    {showLoader && <Loader />}

                    <div className="overlay-icons">
                        <span className="border-right" onClick={this.clickTick}>
                            <TickIconNew fillColor="#2251ff" />
                        </span>
                        <span onClick={this.onCloseModal} className="closeWrapper">
                            <DeleteIcon />
                        </span>
                    </div>
                </div>
            </Modal>
        )
    }
}

ThumbnailEditorModal.defaultProps = {
    crossOrigin: '',
    appWidth: PropTypes.number.isRequired,
}

const stateToProps = createStructuredSelector({
    appWidth: selectWidth(),
})

export default connect(stateToProps)(ThumbnailEditorModal)
