import React, { useEffect, useState } from 'react'
import Modal from 'react-responsive-modal'
import { Button, Input } from 'reactstrap'

import { SITE_TXT, LINK_PATTERN } from '../../../../Utils/constants'
import { isValidPattern, convertToEmbedLinkYouTube } from '../../../../Utils/functions'
import './index.scss'

const Template1VideoModal = ({ onClose, addVideo, sectionDetails, videoDetails = {}, updateVideo }) => {
    const [video, setVideo] = useState({
        id: '',
        url: '',
        displayOrder: '',
    })

    useEffect(() => {
        if (Object.keys(videoDetails).length > 0) {
            const { id, video_url, display_order } = videoDetails

            setVideo({ ...video, id, url: video_url, displayOrder: display_order })
        }
        // eslint-disable-next-line
    }, [videoDetails])
    const updateText = videoUrl => setVideo({ ...video, url: videoUrl })
    const sendData = () => {
        const { sectionId } = sectionDetails

        let data = {
            section_id: parseInt(sectionId),
            display_order: '2',
            video_url: convertToEmbedLinkYouTube(video.url),
            is_delete: 0,
        }

        if (video.id) {
            data = {
                ...data,
                section_detail_id: video.id,
                display_order: video.displayOrder,
            }
        }

        console.log('welcomeVideo', videoDetails)
        if (Object.keys(videoDetails).length === 0) {
            addVideo(data)
        } else {
            updateVideo(data)
        }

        onClose()
    }

    return (
        <Modal open onClose={onClose} center classNames={{ modal: 'videoLinkModal' }} closeOnOverlayClick={false}>
            <h2>{video.url === '' ? SITE_TXT.TXT_ADD_VIDEO : SITE_TXT.TXT_EDIT_VIDEO}</h2>
            <div className="videoContent clearfix">
                <h3>{SITE_TXT.TXT_ENTER_LINK}</h3>
                <Input
                    value={video.url}
                    placeholder={SITE_TXT.TXT_ENTER_LINK}
                    onChange={e => updateText(e.target.value)}
                />
                <div className="info">
                    <a href="http://ec2-3-8-149-75.eu-west-2.compute.amazonaws.com/images/Instructions.pptx" download>
                        Video Upload Instructions
                    </a>
                </div>
            </div>

            <div className="btn-section">
                <div className="btn btn-outline-secondary">
                    <span onClick={onClose}>{SITE_TXT.TXT_CANCEL}</span>
                </div>
                <Button disabled={!isValidPattern(video.url, LINK_PATTERN)} onClick={sendData} color="primary">
                    {SITE_TXT.TXT_DONE}
                </Button>
            </div>
        </Modal>
    )
}
export default Template1VideoModal
