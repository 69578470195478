import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Messages } from '../../Components'
import { selectStatus, selectType, selectMessage } from './selector'

const mapStateToProps = createStructuredSelector({
    type: selectType(),
    status: selectStatus(),
    message: selectMessage(),
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(Messages)
