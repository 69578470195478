import React, { useEffect, useCallback, useState } from 'react'

import { connect } from 'react-redux'
import moment from 'moment'

import { createStructuredSelector } from 'reselect'
import { Table, Input } from 'antd'
import { sharedDocsList, recipientsList, isDocDeleted } from '../../Containers/Admin/selector'

import { getSharedDocsList, getRecipientsList, deleteSelectedDocument } from '../../Containers/Admin/actions'

import { sharedDocsListColumns } from '../../Containers/Admin/constant'
import { LopHeaderLink, BinDelete } from '../../Utils/svg'
import Modal from 'react-responsive-modal'
import { ConfirmAlertBox } from '../../Containers/Library/Alerts'
import { SITE_TXT } from '../../Utils/constants'

import './sharedDocuments.scss'

const { Search } = Input

let lastCopiedFile = null

const SharedDouments = ({
    getSharedDocsList,
    getRecipientsList,
    sharedDocsList,
    recipientsList,
    deleteSelectedDocument,
    isDocDeleted,
}) => {
    const [docsData, setDocsData] = useState({
        table: [],
        cst_user: 0,
        doc_created: 0,
        external_views: 0,
    })
    const [search, setSearch] = useState('')

    const [recipientListModalopen, setRecipientListModalopen] = useState(false)

    const [deleteDocList, setDeleteDocList] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([])

    const getSharedDocsListCall = useCallback(() => getSharedDocsList(), [getSharedDocsList])
    useEffect(() => {
        getSharedDocsListCall()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getSharedDocsListCall])

    useEffect(() => {
        if (sharedDocsList) {
            const today = moment()
            const lastMonthDate = moment().subtract(1, 'months')
            const lastOneMonthRecord = []

            sharedDocsList.map((item, index) => {
                item.key = index
                item.dateShow = moment(item.created_at).format('DD/MM/YYYY')
                item.created_at = moment(item.created_at).format('L')
                const recordDate = moment(item.created_at, 'DD/MM/YYYY')
                if (recordDate.isBetween(lastMonthDate, today, 'days', '[]')) {
                    lastOneMonthRecord.push(item)
                }

                item.boxUrlLink = (
                    <span className="tooltip-copy" onClick={() => copyLink(item, index)}>
                        <LopHeaderLink style={{ cursor: 'pointer' }} />
                        <span className="tooltiptext" id={`myTooltip-${index}`}>
                            Copy Link
                        </span>
                    </span>
                )

                item.action = (
                    <div>
                        <a
                            style={{ marginRight: 10 }}
                            href="javascript:void(0)"
                            onClick={() => handleViewMoreBtnClick(item)}
                        >
                            View More
                        </a>
                        <span onClick={() => handleDeleteBtnClick(item)} style={{ cursor: 'pointer' }}>
                            <BinDelete style={{ marginLeft: 10 }} />
                        </span>
                    </div>
                )
                return item
            })
            let externalViews = 0
            lastOneMonthRecord.forEach(item => {
                externalViews += item.total_external_viewer
            })

            setDocsData({
                ...docsData,
                table: sharedDocsList,
                docs_created: lastOneMonthRecord.length,
                external_views: externalViews,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sharedDocsList])

    const onChange = (pagination, filters, sorter, extra) => {
        // console.log('params', pagination, filters, sorter, extra)
    }
    const handleInputChange = value => {
        setSearch(value)
    }
    useEffect(() => {
        if (search) {
            const tableData = docsData.table
            const newTable = tableData.filter(
                item =>
                    (item.shared_file_name && item.shared_file_name.toLowerCase().includes(search)) ||
                    item.created_at.toLowerCase().includes(search)
            )
            setDocsData({ ...docsData, table: newTable })
        } else {
            if (sharedDocsList) {
                setDocsData({ ...docsData, table: sharedDocsList })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])

    useEffect(() => {
        const dom = document.querySelector('.ant-pagination-options')
        if (dom && dom.childNodes.length < 2) {
            const node = document.createElement('DIV')
            const textNode = document.createTextNode('Rows per page')
            node.appendChild(textNode)
            dom.append(node)
        }
    })

    const handleViewMoreBtnClick = data => {
        getRecipientsList(data.id)
        setRecipientListModalopen(true)
    }

    const handleDeleteBtnClick = data => {
        const onYesTrash = () => callDelete(data.shared_file_id ? [data.shared_file_id] : deleteDocList)
        ConfirmAlertBox(SITE_TXT.TXT_CONFIRM_DELETE, SITE_TXT.TXT_SHARED_DOC_DELETE_MESSAGE, onYesTrash)
    }

    const callDelete = data => {
        deleteSelectedDocument({ shared_documents: data })
    }

    const onCloseRecipientsListModal = () => {
        setRecipientListModalopen(false)
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            let idList = selectedRows.map(item => item.shared_file_id)
            setDeleteDocList(idList)
            setSelectedRowKeys(selectedRowKeys)
        },
    }

    useEffect(() => {
        if (isDocDeleted) {
            setDeleteDocList([])
            setSelectedRowKeys([])
            getSharedDocsListCall()
        }
    }, [isDocDeleted])

    const copyLink = (data, index) => {
        if (lastCopiedFile !== null) {
            let tooltipN = document.getElementById(`myTooltip-${lastCopiedFile}`)
            tooltipN.innerHTML = 'Copy Link'
        }
        lastCopiedFile = index
        const el = document.createElement('textarea')
        el.value = `https://mckinsey.box.com/file/${data.shared_file_id}`
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        let tooltip = document.getElementById(`myTooltip-${index}`)
        tooltip.innerHTML = 'Copied'
        document.body.removeChild(el)
    }

    return (
        <>
            <div className="lop-data-table">
                <div>
                    <div className="search-input">
                        <Search size="large" placeholder="Search" allowClear onSearch={handleInputChange} />
                    </div>
                    {deleteDocList.length ? (
                        <div className="downloadExcel" onClick={handleDeleteBtnClick}>
                            <BinDelete fill="#ffffff" /> Delete All
                        </div>
                    ) : null}
                    <Table
                        rowSelection={rowSelection}
                        columns={sharedDocsListColumns}
                        dataSource={docsData.table}
                        pagination={{
                            defaultPageSize: 5,
                            defaultCurrent: 1,
                            showSizeChanger: true,
                            pageSizeOptions: ['5', '10', '15', '20'],
                            locale: { items_per_page: '' },
                        }}
                        onChange={onChange}
                    />

                    <Modal open={recipientListModalopen} onClose={onCloseRecipientsListModal} center>
                        <div style={{ marginTop: 10, textAlign: 'center' }}>Recipients List</div>
                        <hr></hr>
                        <Table
                            columns={[
                                {
                                    title: 'Shared To',
                                    dataIndex: 'recipient',
                                    key: 'recipient',
                                },
                                {
                                    title: 'Shared On',
                                    dataIndex: 'created_at',
                                    key: 'created_at',
                                },
                                {
                                    title: 'Shared By',
                                    dataIndex: 'UserName',
                                    key: 'UserName',
                                },
                            ]}
                            dataSource={recipientsList}
                            pagination={false}
                        />
                    </Modal>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = createStructuredSelector({
    sharedDocsList: sharedDocsList(),
    recipientsList: recipientsList(),
    isDocDeleted: isDocDeleted(),
})
const mapDispatchToProps = {
    getSharedDocsList,
    getRecipientsList,
    deleteSelectedDocument,
}

export default connect(mapStateToProps, mapDispatchToProps)(SharedDouments)
