import React, { Component } from 'react'
import ModuleHeader from '../ModuleHeader'
import { LEARN_HEADING } from '../../Utils/constants'

class Learn extends Component {
    render() {
        return (
            <main className="learn-container">
                <ModuleHeader heading={LEARN_HEADING} />
            </main>
        )
    }
}

export default Learn
