import { createSelector } from 'reselect'

const selectAdmin = state => state.get('admin')

const selectLoading = () => createSelector(selectAdmin, admin => admin.toJS()['isLoading'])

const selectStatus = () => createSelector(selectAdmin, admin => admin.toJS()['status'])

const selectError = () => createSelector(selectAdmin, admin => admin.toJS()['error'])

const selectData = () => createSelector(selectAdmin, admin => admin.toJS()['data'])

const userTypeData = () => createSelector(selectAdmin, admin => admin.toJS()['userTypeData'])

const extDocData = () => createSelector(selectAdmin, admin => admin.toJS()['extDocData'])

const designationData = () => createSelector(selectAdmin, admin => admin.toJS()['designationData'])

const toBeExpData = () => createSelector(selectAdmin, admin => admin.toJS()['toBeExpData'])

const extendDocState = () => createSelector(selectAdmin, admin => admin.toJS()['extendDocState'])

const createUserData = () => createSelector(selectAdmin, admin => admin.toJS()['createUserData'])

const ticket = () => createSelector(selectAdmin, admin => admin.toJS()['ticket'])

const backendUserData = () => createSelector(selectAdmin, admin => admin.toJS()['backendUserData'])

const docdetails = () => createSelector(selectAdmin, admin => admin.toJS()['docdetails'])

const newdocdetails = () => createSelector(selectAdmin, admin => admin.toJS()['newdocdetails'])
const deleteData = () => createSelector(selectAdmin, admin => admin.toJS()['deleteData'])
const uploadData = () => createSelector(selectAdmin, admin => admin.toJS()['uploadData'])
const userCount = () => createSelector(selectAdmin, admin => admin.toJS()['userCount'])
const adminSessionData = () => createSelector(selectAdmin, admin => admin.toJS()['adminSessionData'])
const blockUnblockData = () => createSelector(selectAdmin, admin => admin.toJS()['blockUnblockData'])
const isAdminDataLoading = () => createSelector(selectAdmin, admin => admin.toJS()['isAdminDataLoading'])
const userData = () => createSelector(selectAdmin, admin => admin.toJS()['userData'])
const isEditUserData = () => createSelector(selectAdmin, admin => admin.toJS()['isEditUserData'])
const downloadLimitData = () => createSelector(selectAdmin, admin => admin.toJS()['downloadLimitData'])
const downloadLimitStatus = () => createSelector(selectAdmin, admin => admin.toJS()['downloadLimitStatus'])
const getUserProfileData = () => createSelector(selectAdmin, admin => admin.toJS()['getUserProfileData'])
const setUserProfileSuccess = () => createSelector(selectAdmin, admin => admin.toJS()['setUserProfileSuccess'])
const getUserEmails = () => createSelector(selectAdmin, admin => admin.toJS()['userEmails'])
const getUserVideoModalStatus = () => createSelector(selectAdmin, admin => admin.toJS()['closeVideoUpload'])
const getUserVideos = () => createSelector(selectAdmin, admin => admin.toJS()['videoData'])
const getUserVideoStatus = () => createSelector(selectAdmin, admin => admin.toJS()['reloadVideoContent'])
const cstGroupDetail = () => createSelector(selectAdmin, admin => admin.toJS()['cstGroupDetail'])
const isListUpdated = () => createSelector(selectAdmin, admin => admin.toJS()['isListUpdated'])
const adminUser = () => createSelector(selectAdmin, admin => admin.toJS()['adminUser'])
const activeUsers = () => createSelector(selectAdmin, admin => admin.toJS()['activeUsers'])
const lopStats = () => createSelector(selectAdmin, admin => admin.toJS()['lopStats'])
const docsStats = () => createSelector(selectAdmin, admin => admin.toJS()['docsStats'])
const sharedDocsList = () => createSelector(selectAdmin, admin => admin.toJS()['sharedDocsList'])
const recipientsList = () => createSelector(selectAdmin, admin => admin.toJS()['recipientsList'])
const isDocDeleted = () => createSelector(selectAdmin, admin => admin.toJS()['isDocDeleted'])

export {
    selectStatus,
    selectLoading,
    selectData,
    selectError,
    userTypeData,
    extDocData,
    designationData,
    toBeExpData,
    extendDocState,
    createUserData,
    ticket,
    backendUserData,
    docdetails,
    newdocdetails,
    deleteData,
    uploadData,
    userCount,
    adminSessionData,
    blockUnblockData,
    isAdminDataLoading,
    userData,
    isEditUserData,
    downloadLimitData,
    downloadLimitStatus,
    getUserProfileData,
    setUserProfileSuccess,
    getUserEmails,
    getUserVideos,
    getUserVideoModalStatus,
    getUserVideoStatus,
    cstGroupDetail,
    isListUpdated,
    adminUser,
    activeUsers,
    lopStats,
    docsStats,
    sharedDocsList,
    recipientsList,
    isDocDeleted,
}
