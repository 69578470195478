import deliverSaga from '../Containers/Deliver/sagas'
import deliverReducer from '../Containers/Deliver/reducer'
import libraryReducer from '../Containers/Library/reducer'
import lopReducer from '../Containers/Lop/reducer'
import librarySaga from '../Containers/Library/sagas'
import lopSaga from '../Containers/Lop/sagas'
import learnReducer from '../Containers/Learn/reducer'
import learnSaga from '../Containers/Learn/sagas'
import mmPracticeReducer from '../Containers/MMPractice/reducer'
import mmPracticeSaga from '../Containers/MMPractice/sagas'
import industrySaga from '../Containers/Industry/sagas'
import industryReducer from '../Containers/Industry/reducer'
import adminReducer from '../Containers/Admin/reducer'
import adminSaga from '../Containers/Admin/sagas'
import searchReducer from '../Containers/Search/reducer'
import searchSaga from '../Containers/Search/sagas'
import appReducer from '../Containers/App/reducer'
import appSaga from '../Containers/App/sagas'
import loaderReducer from '../Reducer/loader'
import messageReducer from '../Containers/Messages/reducer'
import lopContentReducer from '../Containers/LopContent/reducer'
import lopContentSaga from '../Containers/LopContent/sagas'
import mmCstReducer from '../Containers/MMCst/reducer'
import mmCstSaga from '../Containers/MMCst/saga'

import roleSaga from '../Containers/GetRole/sagas'
import roleReducer from '../Containers/GetRole/reducer'

import { getAsyncInjectors } from '../Utils/asyncInjectors'

const doInject = store => {
    const { injectReducer, injectSagas } = getAsyncInjectors(store)
    injectReducer('loader', loaderReducer)
    injectReducer('messages', messageReducer)
    injectReducer('deliver', deliverReducer)
    injectSagas(deliverSaga)
    injectReducer('library', libraryReducer)
    injectSagas(librarySaga)
    injectReducer('lop', lopReducer)
    injectSagas(lopSaga)
    injectReducer('learn', learnReducer)
    injectSagas(learnSaga)
    injectReducer('mmPractice', mmPracticeReducer)
    injectSagas(mmPracticeSaga)
    injectReducer('industry', industryReducer)
    injectSagas(industrySaga)
    injectReducer('admin', adminReducer)
    injectSagas(adminSaga)
    injectReducer('search', searchReducer)
    injectSagas(searchSaga)
    injectReducer('app', appReducer)
    injectSagas(appSaga)
    injectReducer('lopContent', lopContentReducer)
    injectSagas(lopContentSaga)
    injectReducer('mmCst', mmCstReducer)
    injectSagas(mmCstSaga)

    injectReducer('userRole', roleReducer)
    injectSagas(roleSaga)
}

export default doInject
