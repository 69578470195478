import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { Redirect } from 'react-router-dom'
import { withAlert } from 'react-alert'

import Loader from '../../Components/Loader'
import { SITE_TXT, userAuthorization } from '../../Utils/constants'

class SharedLop extends Component {
    state = {
        redirectToSections: false,
        isUserValid: true,
    }

    componentDidMount = () => {
        const { setLopHeader, getMyLops, alert, setLogoutUser } = this.props
        setLopHeader(false)
        getMyLops({
            search: '',
            pageNumber: 1,
            pageSize: 10000,
        })

        const { user_metadata = {} } = userAuthorization
        const { loginValidity = '' } = user_metadata
        if (new Date(loginValidity) < new Date().setHours(0, 0, 0, 0)) {
            const { error } = alert
            error(SITE_TXT.EXTERNAL_USER_EXPIRED)
            this.setState({ isUserValid: false })
            setLogoutUser()
        }
    }

    componentDidUpdate() {
        const { myLopData } = this.props
        const { data } = myLopData
        const lopInfo = data[0] || {}
        if (!isEmpty(lopInfo) && this.state.isUserValid) {
            localStorage.setItem('myLopData', JSON.stringify({ ...lopInfo, isExternal: true }))
            this.setState({ redirectToSections: true })
        }
    }

    render() {
        const { redirectToSections } = this.state
        const { myLopData, isLopListLoading } = this.props
        const { data } = myLopData
        const lopInfo = data[0] || {}
        return (
            <main className="sharedLopContainer">
                {redirectToSections && <Redirect to="/lopSections" />}
                {isLopListLoading || isEmpty(lopInfo) ? <Loader /> : null}
            </main>
        )
    }
}

SharedLop.propTypes = {
    getMyLops: PropTypes.func.isRequired,
    setLopHeader: PropTypes.func.isRequired,
    setLogoutUser: PropTypes.func.isRequired,
    isLopListLoading: PropTypes.bool.isRequired,
    myLopData: PropTypes.instanceOf(Object).isRequired,
}

export default withAlert(SharedLop)
