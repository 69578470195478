import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Library } from '../../Components'
import { selectLoading, selectStatus, selectData } from './selector'
import { library, add, edit, trash, reorder } from './actions'

const mapStateToProps = createStructuredSelector({
    isLoading: selectLoading(),
    status: selectStatus(),
    data: selectData(),
})

const mapDispatchToProps = {
    library,
    add,
    edit,
    trash,
    reorder,
}
export default connect(mapStateToProps, mapDispatchToProps)(Library)
