import React, { Component, Fragment } from 'react'

import Modal from 'react-responsive-modal'
import PropTypes from 'prop-types'
import { withAlert } from 'react-alert'
import { SITE_TXT, IMAGE_CROP_TEAM_PROFILE, MAX_NAME_LENGTH_100 } from '../../../../../Utils/constants'
import Inputbox from '../../../../Inputbox'
import ThumbnailEditorModal from '../../../../ThumbnailEditorModal'
import { getPrefixedUrl } from '../../../../../Utils/apis'
import { AddPartnerProfile } from '../../../../../Utils/svg'
import RichTextTool from '../../RichTextTool'

import './index.scss'
import Dropdown from '../../../../Dropdown'

class TeamMemberModal extends Component {
    state = {
        formData: {
            name: '',
            designation: '',
            image1: '',
            description: '',
            block_id: this.props.curBlockId || '',
        },
        deliverImg1Src: '',
        isModalOpen: false,
        base64path: null,
        imageId: '',
        imageSrc: '',
        isEditMode: false,
        id: this.props.teamData.id || null,
        addEdit: false,
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.isEditMode !== prevState.isEditMode && prevState.addEdit === false) {
            return {
                isEditMode: nextProps.isEditMode,
                addEdit: true,
                formData: {
                    name: nextProps.teamData.name,
                    designation: nextProps.teamData.designation,
                    image1: nextProps.teamData.image,
                    description: nextProps.teamData.description,
                    block_id: nextProps.teamData.block_id,
                },
            }
        }

        return null
    }

    openUploadModal = field => {
        this.setState({
            isModalOpen: true,
            imageId: field,
        })
    }
    getSrcFunction = reader => {
        if (this.state.imageId === 'image1') {
            this.setState({
                deliverImg1Src: reader.result,
                imageSrc: reader.result,
                base64path: reader.result,
            })
        }
    }

    getBase64(file) {
        var reader = new FileReader()
        reader.addEventListener(
            'load',
            () => {
                this.getSrcFunction(reader)
            },
            false
        )
        if (file) {
            reader.readAsDataURL(file)
        }
    }
    resetModal = () => {
        this.setState(
            {
                name: '',
                designation: '',
                formData: {
                    sectionId: '',
                    name: '',
                    designation: '',
                    image1: '',
                    description: '',
                    block_id: '',
                },
                isEditMode: false,
                addEdit: false,
            },
            () => {
                this.props.onCloseModal()
            }
        )
    }
    handleRichTextChange = value => {
        this.setState({
            formData: {
                ...this.state.formData,
                description: value,
            },
        })
    }
    handleSaveImage = (thumbnailId, formTosave, imageTosave) => {
        if (this.state.imageId === 'image1') {
            this.setState({
                formData: { ...this.state.formData, image1: imageTosave },
            })
            this.getBase64(imageTosave)
        }
        this.closeUploadModal()
    }
    closeUploadModal = () => {
        this.setState({
            isModalOpen: false,
        })
    }

    handleChange = (field, value) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: value,
            },
        })
    }

    isFormValid = () => {
        const { alert } = this.props
        const { error } = alert
        const { name } = this.state.formData
        if (name.trim().length > MAX_NAME_LENGTH_100) {
            error(SITE_TXT.TXT_NAME_MAX_LENGTH)
            return false
        }

        return true
    }

    createFormData = () => {
        const { name = '', designation = '', image1 = '', description = '', block_id = '' } = this.state.formData
        const { teamData, sectionDetails } = this.props
        const { sectionId = '', templateId = '' } = sectionDetails
        const { id = '' } = this.state
        let data = new FormData()
        let newUpadtedData = {}
        data.append('sectionId', sectionId)
        data.append('name', name)
        data.append('designation', designation)
        data.append('image', image1)
        data.append('description', description)
        data.append('templateId', templateId)
        data.append('block_id', block_id)
        if (this.state.isEditMode) {
            data.append('id', id)
            newUpadtedData = {
                id,
                name,
                block_id,
                isDelete: 'false',
                image: teamData.image,
                designation,
                description,
            }
        }

        return { data, newUpadtedData }
    }

    handleSave = e => {
        e.preventDefault()
        if (!this.isFormValid()) {
            return
        }
        const { addPartner, editPartner, curBlockIndex, curBlockId: elementIndex, PeopleCategory } = this.props
        this.setState({ addEdit: true }, () => {
            const { data, newUpadtedData } = this.createFormData()
            if (this.state.isEditMode) {
                const newBlockIndex = PeopleCategory.findIndex(cat => cat.key === newUpadtedData.block_id)
                editPartner(data, newUpadtedData, curBlockIndex, newBlockIndex, parseInt(elementIndex))
            } else {
                addPartner(data, curBlockIndex)
            }
        })
    }
    render() {
        const styleClasses = {
            modal: 'teamMemberModal',
            overlay: 'overlayTeamMember',
            closeButton: 'close-btn-modal',
        }
        const { deliverImg1Src, isModalOpen, imageId, base64path, id, formData } = this.state
        const { PeopleCategory, curBlockId } = this.props
        const { name, description, designation, image1, block_id } = formData
        const thumbImages = {
            deliverImg1Src,
        }
        return (
            <Modal open onClose={() => this.resetModal()} center classNames={styleClasses}>
                <form>
                    <div className="header-text">{id ? SITE_TXT.TXT_EDIT_TEAM : SITE_TXT.TXT_ADD_TEAM}</div>
                    <div className="form-content">
                        <div className="column1">
                            <div className="profile-poto">
                                <label>Image</label>
                                <div className="profile">
                                    {image1 ? (
                                        <img
                                            alt="img"
                                            src={deliverImg1Src || getPrefixedUrl(`${image1}`)}
                                            className="profile-image"
                                        />
                                    ) : (
                                        <div className="dummyContent" />
                                    )}
                                    <div className="browse-icon" onClick={() => this.openUploadModal('image1')}>
                                        <AddPartnerProfile />
                                    </div>
                                </div>
                            </div>
                            <div className="profile-details">
                                <Inputbox
                                    placeholder="Enter Name..."
                                    type="text"
                                    fieldFor="name"
                                    label="Name"
                                    onChange={this.handleChange}
                                    value={name}
                                    max={40}
                                />

                                <Inputbox
                                    placeholder="Enter Title/Designation..."
                                    type="text"
                                    label="Title/Designation"
                                    fieldFor="designation"
                                    onChange={this.handleChange}
                                    value={designation}
                                    max={40}
                                />

                                <Dropdown
                                    label="Category"
                                    fieldFor="block_id"
                                    options={PeopleCategory}
                                    value={block_id || curBlockId}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className="column2">
                            <label>Bio</label>
                            <RichTextTool
                                richTextContent={description}
                                onToolTextChange={this.handleRichTextChange}
                                placeholder="Add text, maximum 1000 characters"
                                richTextClassName="pointerTextTool"
                            />
                        </div>
                    </div>
                    <div className={'buttonContainer'}>
                        <button className="btn btn-outline-secondary btn-lg" onClick={e => this.resetModal()}>
                            {SITE_TXT.TXT_CANCEL}
                        </button>
                        <button
                            className="btn btn-primary btn-lg"
                            onClick={e => this.handleSave(e)}
                            disabled={!name || block_id <= 0}
                        >
                            {SITE_TXT.TXT_DONE}
                        </button>
                    </div>
                </form>
                <Fragment>
                    {isModalOpen && (
                        <ThumbnailEditorModal
                            isAdminPage
                            onlyImage
                            isModalOpen={isModalOpen}
                            imageId={imageId}
                            thumbnailImg={image1 || null}
                            thumbImages={thumbImages}
                            deliverImgageSrc={base64path}
                            editCancel={() => this.closeUploadModal()}
                            handleSave={(id, form, test) => this.handleSaveImage(id, form, test)}
                            imgCropObj={IMAGE_CROP_TEAM_PROFILE}
                            overlayClass="uploadGalleryImageOverlay"
                            modalClass="partnerImageModal"
                        />
                    )}
                </Fragment>
            </Modal>
        )
    }
}

TeamMemberModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    addPartner: PropTypes.func.isRequired,
    editPartner: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    sectionDetails: PropTypes.instanceOf(Object).isRequired,
    teamData: PropTypes.instanceOf(Object),
    curBlockId: PropTypes.string.isRequired,
    curBlockIndex: PropTypes.number.isRequired,
}

export default withAlert(TeamMemberModal)
