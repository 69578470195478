import React, { Component } from 'react'
import PropTypes from 'prop-types'
import RichTextEditor from 'react-rte'

import { TEXT_EDITOR_CONFIG } from '../../../Utils/constants'

class RichTextTool extends Component {
    constructor(props) {
        super(props)
        this.state = {
            richTextValue: RichTextEditor.createValueFromString(props.richTextContent, 'html'),
            richTextContent: props.richTextContent,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.richTextContent !== prevState.richTextContent) {
            return {
                richTextValue: RichTextEditor.createValueFromString(nextProps.richTextContent, 'html'),
                richTextContent: nextProps.richTextContent,
            }
        } else return null
    }

    onChange = richTextValue => this.setState({ richTextValue })

    updateValueInRedux = () => {
        const { richTextValue, richTextContent: oldValue } = this.state
        const richTextContent = richTextValue.toString('html').replace(/<a/gi, '<a target="_blank"')
        if (richTextContent !== oldValue) {
            const { onToolTextChange } = this.props
            this.setState({
                richTextContent,
            })
            onToolTextChange(richTextContent)
        }
    }

    render() {
        const { richTextClassName, placeholder } = this.props
        const { richTextValue } = this.state
        return (
            <RichTextEditor
                toolbarConfig={TEXT_EDITOR_CONFIG}
                value={richTextValue}
                onChange={this.onChange}
                onBlur={this.updateValueInRedux}
                className={richTextClassName}
                placeholder={placeholder}
            />
        )
    }
}

RichTextTool.propTypes = {
    richTextClassName: PropTypes.string,
    placeholder: PropTypes.string,
    richTextContent: PropTypes.string,
    onToolTextChange: PropTypes.func.isRequired,
}

RichTextTool.defaultProps = {
    richTextClassName: '',
    placeholder: '',
    richTextContent: '',
}

export default RichTextTool
