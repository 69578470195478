import React, { Component } from 'react'
import DocCommonTable from './docCommonTable'
import './index.css'
class DocToBeExpTable extends Component {
    render() {
        return (
            <DocCommonTable
                dataAction="ToBeExpired"
                modalheading="Extend To Be Expired Documents"
                tableData={this.props.tableData}
                adminDocumentLoadMore={this.props.adminDocumentLoadMore}
                isLoading={this.props.isLoading}
                data={this.props.data}
                expSearch={this.props.expSearch}
                extendDoc={this.props.extendDoc}
                extendDocState={this.props.extendDocState}
                status={this.props.status}
                fetchDocuments={this.props.fetchDocuments}
                extendAction="updateToBeExpired"
                pageNumber={this.props.pageNumber}
            />
        )
    }
}

export default DocToBeExpTable
