import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

export default class SafeImage extends Component {
    state = {
        error: false,
    }
    handleImageErrored = () => {
        this.setState({ error: true })
    }

    render() {
        const { componentClass, componentStyle, imageUrl, supportClass } = this.props
        const { error } = this.state
        return (
            <Fragment>
                {error ? (
                    <div className={supportClass} />
                ) : (
                    <img
                        alt=""
                        src={imageUrl}
                        key={imageUrl}
                        className={componentClass}
                        style={componentStyle}
                        onError={this.handleImageErrored}
                    />
                )}
            </Fragment>
        )
    }
}

SafeImage.propTypes = {
    imageUrl: PropTypes.string,
    componentClass: PropTypes.string,
    supportClass: PropTypes.string,
    componentStyle: PropTypes.object,
}

SafeImage.defaultProps = {
    imageUrl: '',
    componentClass: '',
    supportClass: '',
    componentStyle: {},
}
