import { call, put, all, takeLatest } from 'redux-saga/effects'
import {
    addLibraryDocument,
    getDocumentTags,
    getAdminUserRoles,
    getAdminUserExpertises,
    callCreateUser,
    callCreateTicket,
    getAdminLibraryDocuments,
    getAdminUserDesignation,
    extendDocuments,
    callVerification,
    addUser,
    getLibraryDocument,
    editLibraryDocument,
    deleteLibraryDocument,
    callAdminDetails,
    callAdminUserCount,
    callAdminBlockUnblock,
    callFetchUser,
    callEditUser,
    callGetDocumentLimit,
    callEditDocumentLimit,
    callGetUserProfile,
    callEditUserProfile,
    uploadVideoData,
    fetchVideoData,
    removeVideoData,
    fetchCstGroupDetail,
    editCSTGroup,
    fetchCstGroup,
    getUserCount,
    deleteUser,
    callActiveUsers,
    callLOPStats,
    blockUnblock,
    callDocsStats,
    callSharedDocsList,
    callRecipientsList,
    callDeleteSharedDoc,
} from '../../Utils/apis'
import {
    ADMIN_UPLOAD_ADMIN,
    ADMIN_FETCH_TAGS,
    ADMIN_FETCH_EXPERTS_TAGS,
    ADMIN_FETCH_USER_TYPE,
    ADMIN_FETCH_EXT_DOCUMENTS,
    ADMIN_FETCH_DESIGNATION_TAGS,
    ADMIN_CREATE_USER,
    ADMIN_DOCUMENT_LOAD_MORE,
    ADMIN_EXPIRED_SEARCH,
    ADMIN_EXTEND_DOCUMENTS,
    ADMIN_CREATE_TICKET,
    ADMIN_VERIFICATION_EMAIL,
    ADMIN_ADD_USER_BACKEND,
    ADMIN_FETCH_DOC_DETAILS,
    ADMIN_REPLACE_DOC_DETAILS,
    ADMIN_DELETE_DOC_DETAILS,
    ADMIN_USER_COUNT_DETAILS,
    ADMIN_SESSION_TABLE,
    ADMIN_BLOCK_UNBLOCK_USER,
    ADMIN_SESSION_TABLE_LOAD_MORE,
    ADMIN_FETCH_USER_DATA,
    ADMIN_EDIT_USER_DATA,
    ADMIN_GET_DOWNLOAD_LIMIT,
    ADMIN_SET_DOWNLOAD_LIMIT,
    ADMIN_GET_USER_PROFILE,
    ADMIN_SET_USER_PROFILE,
    ADMIN_SESSION_EMAIL,
    VIDEO_UPLOAD,
    GET_VIDEO_DATA,
    DELETE_VIDEO_DATA,
    FETCH_CST_GROUP_DETAIL,
    UPDATE_CST_GROUP_DETAIL,
    FETCH_ACTIVE_CST,
    FETCH_NEW_CST,
    FETCH_EXPIRED_CST,
    LOAD_MORE_ACTIVE_CST,
    LOAD_MORE_NEW_CST,
    LOAD_MORE_EXPIRED_CST,
    GET_USER_COUNT,
    DELETE_USER,
    GET_ACTIVE_USERS,
    GET_LOP_STATS,
    BLK_UNBLK_CST_USER,
    GET_DOCS_STATS,
    GET_SHARED_DOCS,
    GET_RECIPIENT_LIST,
    DELETE_SHARED_DOC,
} from './constant'
import {
    uploadAdminSuccess,
    uploadAdminFailed,
    fetchTagsSuccess,
    fetchTagsFailure,
    fetchExpertTagsSuccess,
    fetchExpertTagsFailure,
    fetchUserTypeSuccess,
    fetchUserTypeFailure,
    fetchExtDocumentsSuccess,
    fetchExtDocumentsFailure,
    fetchDesignationTagsSuccess,
    fetchDesignationTagsFailure,
    createUserAdminSuccess,
    createUserAdminFailed,
    adminDocumentLoadMoreSuccess,
    adminDocumentLoadMoreFailed,
    expSearchFailure,
    expSearchSuccess,
    extendDocSuccess,
    extendDocFailure,
    createUserTicketFailed,
    createUserTicketSuccess,
    verificationEmailFailed,
    verificationEmailSuccess,
    addUserBackendSuccess,
    addUserBackendFailed,
    fetchDocDetailsSuccess,
    fetchDocDetailsFailed,
    replaceDocDetailsSuccess,
    replaceDocDetailsFailed,
    deleteDocSuccess,
    deleteDocFailure,
    getUserCountSuccess,
    getUserCountFailure,
    getAdminTableDataSuccess,
    getAdminTableDataFailure,
    blockUnblockUserSuccess,
    blockUnblockUserFailure,
    getAdminTableDataLoadMoreSuccess,
    getAdminTableDataLoadMoreFailure,
    fetchUserDataSuccess,
    fetchUserDataFailure,
    editUserDataSuccess,
    editUserDataFailure,
    getDownloadLimitSuccess,
    getDownloadLimitFailure,
    setDownloadLimitSuccess,
    setDownloadLimitFailure,
    getUserProfileSuccess,
    getUserProfileFailure,
    setUserProfileSuccess,
    setUserProfileFailure,
    getUserEmailComplete,
    uploadVideoSuccess,
    uploadVideoFailure,
    getAdminVideoSuccess,
    getAdminVideoFailure,
    deleteAdminVideoSuccess,
    deleteAdminVideoFailure,
    updateCSTGroupDetailFailure,
    updateCSTGroupDetailSuccess,
    fetchActiveCSTSuccess,
    fetchActiveCSTFailure,
    fetchNewCSTSuccess,
    fetchNewCSTFailure,
    fetchExpiredCSTSuccess,
    fetchExpiredCSTFailure,
    fetchCSTByIdFailure,
    fetchCSTByIdSuccess,
    loadMoreActiveCSTSuccess,
    loadMoreActiveCSTFailure,
    loadMoreNewCSTSuccess,
    loadMoreNewCSTFailure,
    loadMoreExpiredCSTSuccess,
    loadMoreExpiredCSTFailure,
    getAdminUserCountFailure,
    getAdminUserCountSuccess,
    deleteUserFailure,
    deleteUserSuccess,
    getActiveUsersSuccess,
    getActiveUsersFailure,
    getLopStatsFailure,
    getLopStatsSuccess,
    blkUnBlkCSTFailure,
    blkUnBlkCSTSuccess,
    getDocsStatsSuccess,
    getDocsStatsFailure,
    getSharedDocsListSuccess,
    getSharedDocsListFailure,
    getRecipientsListSuccess,
    getRecipientsListFailure,
    deleteSelectedDocumentSuccess,
    deleteSelectedDocumentFailure,
} from './actions'
import { expirationLogout } from '../../Utils/functions'
import { STATUS_SUCCESS } from '../../Utils/constants'

export function* uploadAdmin({ data }) {
    const response = yield call(addLibraryDocument, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(uploadAdminSuccess({ responseData }))
    } else {
        yield put(uploadAdminFailed({ responseData }))
        // alert(responseData.message)
    }
}

export function* createUserAdmin({ data }) {
    const response = yield call(callCreateUser, data)
    const responseData = response.data
    if (responseData.email) {
        yield put(createUserAdminSuccess({ responseData }))
    } else {
        yield put(createUserAdminFailed({ responseData }))
        if (responseData.message === 'Error: Request failed with status code 409') {
            alert('User already exists')
        } else {
            if (responseData.message === 'Error: Request failed with status code 401') {
                alert('Session has been expired. Please login again!')
                expirationLogout()
            } else {
                alert(responseData.message)
            }
        }
    }
}

export function* createTicket({ data }) {
    const response = yield call(callCreateTicket, data)
    const responseData = response.data
    if (responseData.ticket) {
        yield put(createUserTicketSuccess({ responseData }))
    } else {
        yield put(createUserTicketFailed({ responseData }))
        // alert(responseData)
    }
}

export function* verificationMail({ data }) {
    const response = yield call(callVerification, data)
    const responseData = response.data
    if (responseData.ticket) {
        yield put(verificationEmailSuccess({ responseData }))
        // alert('Verification Link has been sent at your email address!')
    } else {
        yield put(verificationEmailFailed({ responseData }))
        // alert(responseData)
    }
}

export function* fetchExpertTags({ data }) {
    const response = yield call(getAdminUserExpertises, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(fetchExpertTagsSuccess({ responseData }))
    } else {
        yield put(fetchExpertTagsFailure({ responseData }))
    }
}
export function* fetchTags({ data }) {
    const response = yield call(getDocumentTags, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(fetchTagsSuccess({ responseData }))
    } else {
        yield put(fetchTagsFailure({ responseData }))
    }
}
export function* fetchDesignationTags({ data }) {
    const response = yield call(getAdminUserDesignation, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(fetchDesignationTagsSuccess({ responseData }))
    } else {
        yield put(
            fetchDesignationTagsFailure({
                responseData,
            })
        )
    }
}

export function* fetchUserTypes() {
    const response = yield call(getAdminUserRoles)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(fetchUserTypeSuccess({ responseData }))
    } else {
        yield put(fetchUserTypeFailure({ responseData }))
    }
}

export function* fetchExtDocuments({ data }) {
    const response = yield call(getAdminLibraryDocuments, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(fetchExtDocumentsSuccess({ responseData }))
    } else {
        yield put(
            fetchExtDocumentsFailure({
                responseData,
            })
        )
    }
}

export function* adminDocLoadMore({ data }) {
    const response = yield call(getAdminLibraryDocuments, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(adminDocumentLoadMoreSuccess({ responseData }))
    } else {
        yield put(
            adminDocumentLoadMoreFailed({
                responseData,
            })
        )
    }
}

export function* fetchExpSearch({ data }) {
    const response = yield call(getAdminLibraryDocuments, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(expSearchSuccess({ responseData }))
    } else {
        yield put(
            expSearchFailure({
                responseData,
            })
        )
    }
}

export function* extendDocs({ data }) {
    const response = yield call(extendDocuments, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(extendDocSuccess({ responseData }))
    } else {
        yield put(extendDocFailure({ responseData }))
    }
}

export function* addUserBackendApi({ data }) {
    const response = yield call(addUser, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(addUserBackendSuccess({ responseData }))
    } else {
        yield put(addUserBackendFailed({ responseData }))
    }
}

export function* fetchDocumentDetails({ data }) {
    const response = yield call(getLibraryDocument, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(fetchDocDetailsSuccess({ responseData }))
    } else {
        yield put(
            fetchDocDetailsFailed({
                responseData,
            })
        )
    }
}
export function* replaceDocumentDetails({ data }) {
    const response = yield call(editLibraryDocument, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(replaceDocDetailsSuccess({ responseData }))
    } else {
        yield put(
            replaceDocDetailsFailed({
                responseData,
            })
        )
        // if (
        //     responseData.message ===
        //     'Error: Request failed with status code 400'
        // ) {
        //     alert('Library Document already exists!')
        // } else {
        //     alert(responseData.message)
        // }
    }
}

export function* deleteDocumentDetails({ data }) {
    const response = yield call(deleteLibraryDocument, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(deleteDocSuccess({ responseData }))
    } else {
        yield put(
            deleteDocFailure({
                responseData,
            })
        )
    }
}

export function* adminUserCount({ data }) {
    const response = yield call(callAdminUserCount, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(getUserCountSuccess({ responseData }))
    } else {
        yield put(
            getUserCountFailure({
                responseData,
            })
        )
    }
}

export function* adminSessionTable({ data }) {
    const response = yield call(callAdminDetails, data) //adminSessionTableData //yield call(callAdminDetails, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(getAdminTableDataSuccess({ responseData }))
    } else {
        yield put(
            getAdminTableDataFailure({
                responseData,
            })
        )
    }
}

export function* adminSessionEmail({ data }) {
    const response = yield call(callAdminDetails, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(getUserEmailComplete(responseData.result.data.map(({ emailId }) => emailId)))
    } else {
        yield put(getUserEmailComplete())
    }
}

export function* adminSessionTableLoadMore({ data }) {
    const response = yield call(callAdminDetails, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(getAdminTableDataLoadMoreSuccess({ responseData }))
    } else {
        yield put(
            getAdminTableDataLoadMoreFailure({
                responseData,
            })
        )
    }
}
export function* adminBlockUnBlock({ data }) {
    const response = yield call(callAdminBlockUnblock, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(blockUnblockUserSuccess({ responseData }))
    } else {
        yield put(
            blockUnblockUserFailure({
                responseData,
            })
        )
    }
}

export function* fetchUser({ data }) {
    const response = yield call(callFetchUser, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(fetchUserDataSuccess({ responseData }))
    } else {
        yield put(
            fetchUserDataFailure({
                responseData,
            })
        )
    }
}
export function* updateUser({ data }) {
    const response = yield call(callEditUser, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(editUserDataSuccess({ responseData }))
    } else {
        yield put(
            editUserDataFailure({
                responseData,
            })
        )
    }
}
export function* fetchDownloadLimit({ data }) {
    const response = yield call(callGetDocumentLimit, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(getDownloadLimitSuccess({ responseData }))
    } else {
        yield put(
            getDownloadLimitFailure({
                responseData,
            })
        )
    }
}
export function* setDownloadLimit({ data }) {
    const response = yield call(callEditDocumentLimit, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(setDownloadLimitSuccess({ responseData }))
    } else {
        yield put(
            setDownloadLimitFailure({
                responseData,
            })
        )
    }
}

export function* fetchUserProfileDetails() {
    const response = yield call(callGetUserProfile)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(getUserProfileSuccess({ responseData }))
    } else {
        yield put(
            getUserProfileFailure({
                responseData,
            })
        )
    }
}
export function* setUserProfileDetails({ data }) {
    const response = yield call(callEditUserProfile, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(setUserProfileSuccess({ responseData }))
    } else {
        yield put(
            setUserProfileFailure({
                responseData,
            })
        )
    }
}
export function* uploadVideoInfo({ data }) {
    const response = yield call(uploadVideoData, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(uploadVideoSuccess({ responseData }))
    } else {
        yield put(
            uploadVideoFailure({
                responseData,
            })
        )
    }
}

export function* getVideoInfo({ data }) {
    const { resetContent, ...otherData } = data
    const response = yield call(fetchVideoData, otherData)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(getAdminVideoSuccess({ responseData, resetContent }))
    } else {
        yield put(getAdminVideoFailure({ responseData }))
    }
}

export function* deleteVideoInfo({ id }) {
    const response = yield call(removeVideoData, id)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(deleteAdminVideoSuccess({ responseData }))
    } else {
        yield put(deleteAdminVideoFailure({ responseData }))
    }
}

export function* getActiveCst({ data }) {
    const response = yield call(fetchCstGroup, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(fetchActiveCSTSuccess({ responseData }))
    } else {
        yield put(fetchActiveCSTFailure({ responseData }))
    }
}

export function* getNewCst({ data }) {
    const response = yield call(fetchCstGroup, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(fetchNewCSTSuccess({ responseData }))
    } else {
        yield put(fetchNewCSTFailure({ responseData }))
    }
}
export function* getExpiredCst({ data }) {
    const response = yield call(fetchCstGroup, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(fetchExpiredCSTSuccess({ responseData }))
    } else {
        yield put(fetchExpiredCSTFailure({ responseData }))
    }
}

export function* getCstById({ data }) {
    const response = yield call(fetchCstGroupDetail, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(fetchCSTByIdSuccess({ responseData }))
    } else {
        yield put(fetchCSTByIdFailure({ responseData }))
    }
}

export function* updatCSTGroup({ data }) {
    const response = yield call(editCSTGroup, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(updateCSTGroupDetailSuccess({ responseData }))
    } else {
        yield put(updateCSTGroupDetailFailure({ responseData }))
    }
}

export function* loadMoreActiveCstSaga({ data }) {
    const response = yield call(fetchCstGroup, data)
    console.log('      response', response)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(loadMoreActiveCSTSuccess({ responseData }))
    } else {
        yield put(loadMoreActiveCSTFailure({ responseData }))
    }
}

export function* loadMoreNewCstSaga({ data }) {
    const response = yield call(fetchCstGroup, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(loadMoreNewCSTSuccess({ responseData }))
    } else {
        yield put(loadMoreNewCSTFailure({ responseData }))
    }
}
export function* loadMoreExpiredCstSaga({ data }) {
    const response = yield call(fetchCstGroup, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(loadMoreExpiredCSTSuccess({ responseData }))
    } else {
        yield put(loadMoreExpiredCSTFailure({ responseData }))
    }
}

export function* getAdminUserCountSaga({ data }) {
    const response = yield call(getUserCount, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(getAdminUserCountSuccess({ responseData }))
    } else {
        yield put(getAdminUserCountFailure({ responseData }))
    }
}

export function* deleteUserSaga({ data }) {
    const { id } = data
    const response = yield call(deleteUser, id)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(deleteUserSuccess({ responseData, requestData: data }))
    } else {
        yield put(deleteUserFailure({ responseData }))
    }
}

export function* getActiveUsers({ data }) {
    const { userType } = data
    const response = yield call(callActiveUsers, userType)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(getActiveUsersSuccess({ responseData, requestData: data }))
    } else {
        yield put(
            getActiveUsersFailure({
                responseData,
            })
        )
    }
}

export function* getLopStatsSaga({ data }) {
    const response = yield call(callLOPStats)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(getLopStatsSuccess({ responseData }))
    } else {
        yield put(getLopStatsFailure({ responseData }))
    }
}

export function* blkUnblkCSTSaga({ data }) {
    const response = yield call(blockUnblock, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(blkUnBlkCSTSuccess({ responseData, requestData: data }))
    } else {
        yield put(blkUnBlkCSTFailure({ responseData, requestData: data }))
    }
}

export function* getDocsStatsSaga() {
    const response = yield call(callDocsStats)
    const responseData = response.data
    if (responseData.data === STATUS_SUCCESS) {
        yield put(getDocsStatsSuccess({ responseData }))
    } else {
        yield put(getDocsStatsFailure({ responseData }))
    }
}

export function* getSharedDocsListSaga() {
    const response = yield call(callSharedDocsList)
    const responseData = response.data
    if (Array.isArray(responseData.data)) {
        yield put(getSharedDocsListSuccess({ responseData }))
    } else {
        yield put(getSharedDocsListFailure({ responseData }))
    }
}

export function* getRecipientsListSaga({ data }) {
    const response = yield call(callRecipientsList, data)
    const responseData = response.data
    if (Array.isArray(responseData.data)) {
        yield put(getRecipientsListSuccess({ responseData }))
    } else {
        yield put(getRecipientsListFailure({ responseData }))
    }
}

export function* deleteSharedDocSaga({ data }) {
    const response = yield call(callDeleteSharedDoc, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(deleteSelectedDocumentSuccess({ responseData }))
    } else {
        yield put(deleteSelectedDocumentFailure({ responseData }))
    }
}

export function* adminSaga() {
    yield all([
        takeLatest(`${ADMIN_FETCH_TAGS}_PENDING`, fetchTags),
        takeLatest(`${ADMIN_FETCH_EXPERTS_TAGS}_PENDING`, fetchExpertTags),
        takeLatest(`${ADMIN_UPLOAD_ADMIN}_PENDING`, uploadAdmin),
        takeLatest(`${ADMIN_FETCH_USER_TYPE}_PENDING`, fetchUserTypes),
        takeLatest(`${ADMIN_FETCH_EXT_DOCUMENTS}_PENDING`, fetchExtDocuments),
        takeLatest(`${ADMIN_FETCH_DESIGNATION_TAGS}_PENDING`, fetchDesignationTags),
        takeLatest(`${ADMIN_CREATE_USER}_PENDING`, createUserAdmin),
        takeLatest(`${ADMIN_DOCUMENT_LOAD_MORE}_PENDING`, adminDocLoadMore),
        takeLatest(`${ADMIN_USER_COUNT_DETAILS}_PENDING`, adminUserCount),
        takeLatest(`${ADMIN_SESSION_TABLE}_PENDING`, adminSessionTable),
        takeLatest(ADMIN_SESSION_EMAIL, adminSessionEmail),
        takeLatest(`${ADMIN_EXPIRED_SEARCH}_PENDING`, fetchExpSearch),
        takeLatest(`${ADMIN_EXTEND_DOCUMENTS}_PENDING`, extendDocs),
        takeLatest(`${ADMIN_CREATE_TICKET}_PENDING`, createTicket),
        takeLatest(`${ADMIN_VERIFICATION_EMAIL}_PENDING`, verificationMail),
        takeLatest(`${ADMIN_ADD_USER_BACKEND}_PENDING`, addUserBackendApi),
        takeLatest(`${ADMIN_FETCH_DOC_DETAILS}_PENDING`, fetchDocumentDetails),

        takeLatest(`${ADMIN_REPLACE_DOC_DETAILS}_PENDING`, replaceDocumentDetails),
        takeLatest(`${ADMIN_DELETE_DOC_DETAILS}_PENDING`, deleteDocumentDetails),
        takeLatest(`${ADMIN_BLOCK_UNBLOCK_USER}_PENDING`, adminBlockUnBlock),
        takeLatest(`${ADMIN_SESSION_TABLE_LOAD_MORE}_PENDING`, adminSessionTableLoadMore),
        takeLatest(`${ADMIN_FETCH_USER_DATA}_PENDING`, fetchUser),
        takeLatest(`${ADMIN_EDIT_USER_DATA}_PENDING`, updateUser),
        takeLatest(`${ADMIN_GET_DOWNLOAD_LIMIT}_PENDING`, fetchDownloadLimit),
        takeLatest(`${ADMIN_SET_DOWNLOAD_LIMIT}_PENDING`, setDownloadLimit),
        takeLatest(`${ADMIN_GET_USER_PROFILE}_PENDING`, fetchUserProfileDetails),
        takeLatest(`${ADMIN_SET_USER_PROFILE}_PENDING`, setUserProfileDetails),
        takeLatest(`${VIDEO_UPLOAD}_PENDING`, uploadVideoInfo),
        takeLatest(`${GET_VIDEO_DATA}_PENDING`, getVideoInfo),
        takeLatest(`${DELETE_VIDEO_DATA}_PENDING`, deleteVideoInfo),
        takeLatest(`${FETCH_CST_GROUP_DETAIL}_PENDING`, getCstById),
        takeLatest(`${FETCH_ACTIVE_CST}_PENDING`, getActiveCst),
        takeLatest(`${FETCH_NEW_CST}_PENDING`, getNewCst),
        takeLatest(`${FETCH_EXPIRED_CST}_PENDING`, getExpiredCst),
        takeLatest(`${LOAD_MORE_ACTIVE_CST}_PENDING`, loadMoreActiveCstSaga),
        takeLatest(`${LOAD_MORE_NEW_CST}_PENDING`, loadMoreNewCstSaga),
        takeLatest(`${LOAD_MORE_EXPIRED_CST}_PENDING`, loadMoreExpiredCstSaga),
        takeLatest(`${UPDATE_CST_GROUP_DETAIL}_PENDING`, updatCSTGroup),
        takeLatest(`${GET_USER_COUNT}_PENDING`, getAdminUserCountSaga),
        takeLatest(`${DELETE_USER}_PENDING`, deleteUserSaga),
        takeLatest(`${GET_ACTIVE_USERS}_PENDING`, getActiveUsers),
        takeLatest(`${GET_LOP_STATS}_PENDING`, getLopStatsSaga),
        takeLatest(`${BLK_UNBLK_CST_USER}_PENDING`, blkUnblkCSTSaga),
        takeLatest(`${GET_DOCS_STATS}_PENDING`, getDocsStatsSaga),
        takeLatest(`${GET_SHARED_DOCS}_PENDING`, getSharedDocsListSaga),
        takeLatest(`${GET_RECIPIENT_LIST}_PENDING`, getRecipientsListSaga),
        takeLatest(`${DELETE_SHARED_DOC}_PENDING`, deleteSharedDocSaga),
    ])
}

export default [adminSaga]
