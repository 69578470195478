import React, { Component } from 'react'
import { EditMainIcon, AddIcon, ReorderIcon } from '../../Utils/svg'
import './index.css'
import { SITE_TXT, isMobileTablet, isMobile, isAdmin } from '../../Utils/constants'
class ThumbnailListActions extends Component {
    state = {
        isEditMode: this.props.isEditMode,
        isReorderMode: this.props.isReorderMode,
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.isEditMode !== this.state.isEditMode) {
            this.setState({ isEditMode: nextProps.isEditMode })
        }
        if (nextProps.isReorderMode !== this.state.isReorderMode) {
            this.setState({ isReorderMode: nextProps.isReorderMode })
        }
    }

    render() {
        const { isEditMode, isReorderMode } = this.state

        const { TXT_ADD_HERE, TXT_EDIT_DELETE, TXT_REORDER } = SITE_TXT

        const listActions = !isMobile && isAdmin && (
            <div className="addEdit">
                <span onClick={() => this.props.clickAdd()} className="addEditIcon">
                    <AddIcon />
                    <span>{TXT_ADD_HERE}</span>
                </span>

                {isEditMode ? /*  (
                    <span className="addEditIcon" onClick={() => this.props.clickToggleEdit()}>
                        <ExitEditModeIcon />
                        <span>{TXT_EDIT_DELETE_EXIT}</span>
                    </span>
                )  */ null : (
                    <span className="addEditIcon" onClick={() => this.props.clickToggleEdit()}>
                        <EditMainIcon />
                        <span>{TXT_EDIT_DELETE}</span>
                    </span>
                )}
                <span>
                    {!isReorderMode && !isMobileTablet && (
                        <span className="addEditIcon" onClick={() => this.props.toggleReorderMode()}>
                            <ReorderIcon />
                            <span>{TXT_REORDER}</span>
                        </span>
                    )}
                </span>
            </div>
        )
        return isReorderMode ? null : listActions
    }
}

export default ThumbnailListActions
