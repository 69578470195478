import React, { Component } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { EditIcon, TrashIcon, NoImageIcon, EditIconWithCircleNew } from '../../Utils/svg'
import 'react-confirm-alert/src/react-confirm-alert.css'
import ThumbnailEditorModalLibrary from '../ThumbnailEditorModalLibrary'
import { confirmAlert } from 'react-confirm-alert'
import { isValidPattern } from '../../Utils/functions'

import { NAME_PATTERN, SITE_TXT, IMAGE_CROP_OBJ_SQUARE, IMAGE_CROP_OBJ_RECT } from '../../Utils/constants'

import { getPrefixedUrl } from '../../Utils/apis'
import './index.css'

class ThumbnailCompLibrary extends Component {
    constructor(props) {
        super(props)

        if (!sessionStorage.getItem('currentCategoryTab')) {
            sessionStorage.setItem('currentCategoryTab', this.props.topCategoryName)
        }
    }
    state = {
        libraryId: this.props.categoryId,
        topCategoryName: this.props.topCategoryName,
        isEditMode: this.props.isEditMode,
        thumbnail: this.props.thumbnail,
        editing: this.props.editMode,
        showAddBox: this.props.showAddBox,
        name: this.props.thumbnail.name,
        thumbnailImage: '',
        isModalOpen: false,
        isModalOpenDeliver: false,
        imageAlignment: this.props.isReorderMode ? 'img-left' : this.props.imageAlignment,
        fetchDeliverItem: this.props.fetchDeliverItem,
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let isEditMode = nextProps.isEditMode

        if (isEditMode !== this.state.isEditMode) {
            this.setState({ isEditMode })
        }
        this.setState({
            thumbnail: nextProps.thumbnail,
        })
    }

    setNavPath(topCategoryName, thumbnailName) {
        sessionStorage.setItem('currentCategoryTab', topCategoryName + ' > ' + thumbnailName)
    }
    openThumbnailModal = () => {
        this.setState({
            isModalOpen: true,
        })
    }

    closeThumbnailModal = () => {
        this.setState({
            isModalOpen: false,
        })
    }

    handleSave = (thumbnailId, formTosave, imageTosave) => {
        const formName = formTosave.titleThumb.value.replace(/\n|\r/g, ' ').trim()
        if (formName.trim() === '') {
            alert(SITE_TXT.TXT_VALID_SUBSECTION)
            return
        }
        if (!isValidPattern(formName, NAME_PATTERN)) {
            alert(SITE_TXT.TXT_INVALID_NAME_PATTERN)
            return
        }
        const data = new FormData()
        const name = formName.replace(/\n|\r/g, ' ').trim()
        data.append('name', name)
        data.append('image', imageTosave)
        data.append('libraryId', this.state.libraryId)
        data.append('id', thumbnailId)
        this.props.edit(data)
        this.closeThumbnailModal()
    }

    handleTrash = (itemId, libraryId) => {
        confirmAlert({
            title: SITE_TXT.TXT_CONFIRM_DELETE,
            message: SITE_TXT.TXT_DELETE_LIB_CATEGORY_MESSAGE,
            buttons: [
                {
                    label: SITE_TXT.TXT_YES,
                    onClick: () => this.props.trash(itemId, libraryId),
                },
                {
                    label: SITE_TXT.TXT_NO,
                    onClick: () => '',
                },
            ],
        })
    }

    handleDragStart(e) {
        e.dataTransfer.effectAllowed = 'move'
        this.props.setDragIndex(this.props.itemIndex)
    }
    handleDragOver(e) {
        e.preventDefault() // Necessary. Allows us to drop.
        e.dataTransfer.dropEffect = 'move'
        return false
    }

    handleDrop(e) {
        this.props.setDropIndex(this.props.itemIndex)
        e.stopPropagation()
    }
    handleDragEnd(e) {}

    openModal = () => {
        this.setState({ isModalOpenDeliver: true })
    }

    closeModal = () => {
        this.setState({ isModalOpenDeliver: false })
    }

    render() {
        const { libraryId, topCategoryName, isEditMode, thumbnail, isModalOpen, imageAlignment } = this.state
        const { TXT_NO_IMAGE } = SITE_TXT
        const { id, name, icon } = thumbnail

        const { isReorderMode } = this.props
        const classes = ['thumbnail-comp', imageAlignment]
        const isDeliver = sessionStorage.getItem('topLevel') === 'Deliver'
        return (
            <div
                className={classes.join(' ')}
                draggable={isReorderMode}
                ref={elem => (this.thumbComp = elem)}
                onDragStart={e => this.handleDragStart(e)}
                onDragOver={e => this.handleDragOver(e)}
                onDrop={e => this.handleDrop(e)}
                onDragEnd={e => this.handleDragEnd(e)}
                style={{
                    cursor: isReorderMode ? 'move' : 'pointer',
                }}
            >
                {!isEditMode ? (
                    <NavLink
                        style={{
                            cursor: 'inherit',
                            pointerEvents: isReorderMode ? 'none' : 'initial',
                        }}
                        to={
                            sessionStorage.getItem('topLevel') === 'Deliver'
                                ? '/deliver/deliverdetails/' + name + '/' + id
                                : '/library/' + topCategoryName + '/' + id
                        }
                        onClick={() => this.setNavPath(topCategoryName, name)}
                    >
                        <span className="img-span">
                            {icon ? (
                                <img alt="img" src={getPrefixedUrl(icon)} />
                            ) : (
                                <span className="img-svg-span">
                                    <NoImageIcon />
                                    {TXT_NO_IMAGE}
                                </span>
                            )}
                        </span>
                        <div>{name}</div>
                    </NavLink>
                ) : (
                    <div className="in-edit-mode">
                        {icon ? (
                            <span className="img-span">
                                <img alt="img" src={getPrefixedUrl(icon)} />
                            </span>
                        ) : (
                            <span className="img-svg-span">
                                <NoImageIcon />
                                {TXT_NO_IMAGE}
                            </span>
                        )}

                        {!isDeliver && (
                            <div>
                                <div>{name}</div>
                                <div className="overlay-icons">
                                    <div className="xy-center">
                                        <span onClick={() => this.openThumbnailModal()}>
                                            <EditIcon />
                                        </span>
                                        <span onClick={() => this.handleTrash(id, libraryId)}>
                                            <TrashIcon />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )}
                        {isDeliver && (
                            <div>
                                <div>{name}</div>
                                <div className="overlay-icons">
                                    <Link
                                        to={{
                                            pathname: '/deliver/deliverform/edit/' + thumbnail.id,
                                        }}
                                    >
                                        <span>
                                            <EditIconWithCircleNew />
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {isModalOpen && (
                    <ThumbnailEditorModalLibrary
                        onlyImage={false}
                        isModalOpen={isModalOpen}
                        thumbnailImg={icon}
                        thumbnailId={id}
                        thumbnailName={name}
                        libraryId={libraryId}
                        clickToBrowse={this.clickToBrowse}
                        editCancel={() => this.closeThumbnailModal()}
                        handleSave={(id, form, test) => this.handleSave(id, form, test)}
                        imgCropObj={libraryId === '1' ? IMAGE_CROP_OBJ_SQUARE : IMAGE_CROP_OBJ_RECT}
                    />
                )}
            </div>
        )
    }
}

ThumbnailCompLibrary.defaultProps = {
    imageAlignment: 'img-left',
    isReorderMode: false,
    isDragOver: false,
}

export default ThumbnailCompLibrary
