import React, { Component } from 'react'
import ReactTooltip from 'react-tooltip'
import Inputbox from '../Inputbox'
import { SITE_TXT } from '../../Utils/constants'

class CstList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            search: '',
            readMore: false,
        }
    }

    componentDidUpdate() {
        ReactTooltip.rebuild()
    }

    handleSearchInput = (field, value) => {
        clearTimeout(this.delayTimer)
        this.delayTimer = setTimeout(() => {
            this.setState({ [field]: value, pageNumber: 1 }, () => {
                this.props.handleSearch(this.state.search, this.props.requestType)
            })
        }, 1000)
    }

    handleChange = (field, value) => {
        this.setState({
            [field]: value,
        })
    }

    handleClick = item => {
        const { toggleCSTDetails, isNewCst = false, isActiveCst = false, isExpiredCst = false } = this.props
        if (isNewCst) {
            toggleCSTDetails({ id: item.id, isNewCst: true })
        }
        if (isActiveCst) {
            toggleCSTDetails({ id: item.id, isActiveCst: true })
        }
        if (isExpiredCst) {
            toggleCSTDetails({ id: item.id, isExpiredCst: true })
        }
    }

    render() {
        const { search } = this.state
        const { list, currentPanel } = this.props
        return (
            <div className="cst-list">
                {((search && list && list.totalRecords === 0) || (list && list.totalRecords > 0)) && (
                    <Inputbox
                        type={'text'}
                        placeholder={'Search'}
                        fieldFor={'search'}
                        onChange={this.handleSearchInput}
                    />
                )}
                {list && list.totalRecords > 0 ? (
                    <div>
                        <div className={'header'}>
                            <div>{SITE_TXT.TXT_CST_TYPE}</div>
                            <div>{SITE_TXT.TXT_MM_ADMIN_CONTACT}</div>
                            <div>{SITE_TXT.TXT_CHARGE_CODE}</div>
                        </div>
                        <div className={'list'}>
                            {list.data.map(item => {
                                return (
                                    <div className={'list-content'} key={item.id}>
                                        <div data-tip={item.name} onClick={() => this.handleClick(item)}>
                                            {item.name}
                                        </div>
                                        <div>{item.memberContact}</div>
                                        <div>{item.chargeCode}</div>
                                    </div>
                                )
                            })}
                        </div>
                        {list.data.length < list.totalRecords && (
                            <div
                                className={'read-more'}
                                onClick={() => this.props.handleReadMore({ field: currentPanel, value: true })}
                            >
                                {SITE_TXT.TXT_VIEW_MORE}
                            </div>
                        )}
                        {list.data.length === list.totalRecords && list.data.length > 5 && (
                            <div
                                className={'read-more'}
                                onClick={() => this.props.handleReadLess(this.props.requestType)}
                            >
                                {SITE_TXT.TXT_VIEW_LESS}
                            </div>
                        )}
                    </div>
                ) : (
                    <div>
                        {this.props.requestType === 2 && <div className={'no-data'}>No Active CSTs</div>}
                        {this.props.requestType === 1 && <div className={'no-data'}>No CST Request</div>}
                        {this.props.requestType === 4 && <div className={'no-data'}>No Expired CSTs</div>}
                    </div>
                )}
                <ReactTooltip place="bottom" />
            </div>
        )
    }
}

export default CstList
