import { call, put, all, takeLatest } from 'redux-saga/effects'
import { logoutUser } from '../../Utils/apis'

import { ADMIN_LOGOUT_USER } from './constant'
import { setLogoutUserSuccess, setLogoutUserFailure } from './actions'
import { STATUS_SUCCESS } from '../../Utils/constants'

export function* setLogoutUser() {
    const response = yield call(logoutUser)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(setLogoutUserSuccess({ responseData }))
    } else {
        yield put(
            setLogoutUserFailure({
                responseData,
            })
        )
    }
}

export function* appSaga() {
    yield all([takeLatest(`${ADMIN_LOGOUT_USER}_PENDING`, setLogoutUser)])
}

export default [appSaga]
