import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectStatus, selectLoading, selectError, selectfetchData, selectData, downloadBrochureData } from './selector'
import { DeliverDetail } from '../../Components'
import { fetchDeliverItem, downloadDeliverBrochure } from './actions'
import { saveDocuments } from '../Library/actions'

const mapStateToProps = createStructuredSelector({
    isLoading: selectLoading(),
    status: selectStatus(),
    error: selectError(),

    selectfetchData: selectfetchData(),
    data: selectData(),
    downloadBrochureData: downloadBrochureData(),
})
const mapDispatchToProps = {
    fetchDeliverItem: fetchDeliverItem,
    downloadDeliverBrochure: downloadDeliverBrochure,
    saveDocuments,
}
export default connect(mapStateToProps, mapDispatchToProps)(DeliverDetail)
