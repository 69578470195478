import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { setLopHeader, setLogoutUser } from '../App/actions'
import { selectLoading, selectMyLopList } from '../LopContent/selector'
import { getMyLops } from '../LopContent/actions'
import SharedLop from './SharedLop'

const mapStateToProps = createStructuredSelector({
    isLopListLoading: selectLoading(),
    myLopData: selectMyLopList(),
})

const dispatchToProps = {
    setLopHeader,
    getMyLops,
    setLogoutUser,
}

export default connect(mapStateToProps, dispatchToProps)(SharedLop)
