import React, { Component } from 'react'
import { withAlert } from 'react-alert'
import Auth from '../../Auth/Auth'
import { SITE_TXT } from '../../Utils/constants'

const messagesArray = [
    'Authorization header missing',
    'Invalid Authorization header',
    'Invalid header,Token not found',
    'Invalid Authorization header',
    'Invalid User.',
    'Token expired.',
    "You don't have permission to access this page.",
]

const authO = new Auth()
class Messages extends Component {
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { type, status, alert, message } = nextProps
        if (status !== 'pending' && this.props.type !== type && message) {
            const smallCaseErrorList = messagesArray.map(errMsg => errMsg.toLowerCase())
            if (smallCaseErrorList.includes(message.toLowerCase())) {
                alert[type](SITE_TXT.TXT_SESSION_MESSAGE)
                setTimeout(() => {
                    authO.logout()
                }, 2000)
            } else {
                alert[type](message)
            }
        }
    }
    render() {
        const { type, status } = this.props
        return (
            <div style={{ display: 'none' }}>
                <span>This is message component</span>
                <span>Type: {type}</span>
                <span>Status: {status}</span>
            </div>
        )
    }
}

export default withAlert(Messages)
