import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-responsive-modal'
import AWS from 'aws-sdk'
import moment from 'moment'
import { FormGroup, Label, Input, Progress } from 'reactstrap'
import ReactDropzone from 'react-dropzone'

import RichTextTool from '../../LopContent/SectionContent/RichTextTool'

import { SITE_TXT, AWS_CONFIG, BUCKET_DIR_PARAMS } from '../../../Utils/constants'
import { UploadIcon } from '../../../Utils/svg'
import { isValidFile } from '../../../Utils/functions'
import './index.scss'

class VideoModal extends Component {
    constructor(props) {
        super(props)
        const { videoData } = props
        const { id = 0, video_name = '', video_description = '<p><br></p>', video_url = '' } = videoData
        this.state = {
            docFile: null,
            video_name,
            video_description,
            id,
            video_url,
            progress: 0,
            uploadInProgress: false,
        }
    }

    componentDidMount() {
        AWS.config.update(AWS_CONFIG)
    }

    componentDidUpdate() {
        if (this.props.closeVideoUpload) {
            this.props.closeModal({}, false, true)
        }
    }

    handleInputChange = ({ target }) => {
        const { value: video_name } = target
        this.setState({ video_name })
    }

    onChange = video_description => this.setState({ video_description })

    handleFileUpload = () => {
        const { docFile: Body, video_url } = this.state
        if (video_url) {
            this.sendDataToServer(video_url)
        } else {
            const { name } = Body
            const Key = `mm/${moment().format('MMMDDYYYYhmmssa')}_${name.replace(/ /g, '')}`
            const ContentType = Body.name
                .split('.')
                .pop()
                .toLowerCase()
            const params = { Key, ContentType, Body }
            const bucket = new AWS.S3(BUCKET_DIR_PARAMS)
            this.setState({ uploadInProgress: true }, () => {
                bucket
                    .upload(params)
                    .on('httpUploadProgress', evt => {
                        const { loaded = 0, total = 0 } = evt
                        const progress = parseInt((loaded * 100) / total, 10)
                        this.setState({ progress })
                    })
                    .send((err, data) => {
                        this.setState({ uploadInProgress: false })
                        if (err) {
                            alert(err)
                        } else {
                            const { Location } = data
                            this.sendDataToServer(Location, Key)
                        }
                    })
            })
        }
    }

    sendDataToServer = (video_url, key = '') => {
        const { uploadVideo } = this.props
        const { video_name, video_description, id } = this.state
        const dataToSend = {
            video_name,
            video_description,
            video_url,
            key,
        }
        uploadVideo({
            dataToSend,
            id,
        })
    }

    handleDrop = files => {
        const { TXT_VIDEO_TYPE_ACCEPTED, TXT_INVALID_FILE_EXT } = SITE_TXT
        if (!isValidFile(files[0].name, TXT_VIDEO_TYPE_ACCEPTED)) {
            alert(TXT_INVALID_FILE_EXT)
        } else if (files.length) {
            this.setState({
                docFile: files[0],
            })
        }
    }

    render() {
        const { video_name, video_description, docFile, uploadInProgress, progress, video_url } = this.state
        const { closeModal } = this.props
        const {
            TXT_SEARCH_BTN,
            TXT_VIDEO_NAME,
            TXT_VIDEO_DESC,
            TXT_ADD_NEW_VIDEO,
            TXT_BROWSE,
            TXT_DROPZONE,
            TXT_VIDEO_UPLOAD,
            TXT_SAVE,
        } = SITE_TXT
        const styleClasses = {
            modal: 'adminVideoModal',
            overlay: 'overlayVideoUpload',
            closeButton: 'closeBtn-custom',
        }
        const enableSubmit = (docFile || video_url) && video_name
        return (
            <Modal
                open
                center
                onClose={() => closeModal({}, false)}
                classNames={styleClasses}
                closeOnOverlayClick={false}
            >
                <div className="videoContent">
                    <h1>{TXT_ADD_NEW_VIDEO}</h1>
                    <div className="leftSection">
                        <Label>{TXT_VIDEO_UPLOAD}</Label>
                        {video_url ? (
                            <video controls key={video_url}>
                                <source src={video_url} />
                            </video>
                        ) : (
                            <ReactDropzone onDrop={this.handleDrop} className="dropzone">
                                <UploadIcon width="34" height="38" />
                                {TXT_DROPZONE}
                                <span className="browseBtn">{TXT_BROWSE}</span>
                                {docFile && <div className="docFileUploaded">{docFile.name}</div>}
                            </ReactDropzone>
                        )}
                        <FormGroup>
                            <Label>{TXT_VIDEO_NAME}</Label>
                            <Input
                                placeholder={TXT_SEARCH_BTN}
                                value={video_name}
                                onChange={e => this.handleInputChange(e)}
                            />
                        </FormGroup>
                    </div>
                    <div className="rightSection">
                        <FormGroup>
                            <Label>{TXT_VIDEO_DESC}</Label>
                            <RichTextTool
                                richTextClassName="sectionTool"
                                richTextContent={video_description}
                                onToolTextChange={this.onChange}
                            />
                        </FormGroup>
                    </div>
                    <div className="buttonContent">
                        <button
                            className="btn btn-primary btn-lg"
                            disabled={!enableSubmit}
                            onClick={this.handleFileUpload}
                        >
                            {TXT_SAVE}
                        </button>
                    </div>
                </div>
                {uploadInProgress ? (
                    <div className="progressOverlay">
                        <div className="percentUploaded">{progress}%</div>
                        <Progress value={progress} />
                    </div>
                ) : null}
            </Modal>
        )
    }
}

VideoModal.propTypes = {
    videoData: PropTypes.instanceOf(Object).isRequired,
    uploadVideo: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    closeVideoUpload: PropTypes.bool.isRequired,
}

export default VideoModal
