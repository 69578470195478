import React from 'react'
import XLSX from 'xlsx'
import { saveAs } from 'file-saver'

export default class ExportTrendsFull extends React.Component {
    state = {
        users: [],
        pages: [],
        downloads: [],
    }

    componentDidMount() {
        this.prepatedData('users')
        this.prepatedData('pages')
        this.prepatedData('downloads')
    }

    prepatedData(type = 'users') {
        const { activeUsers } = this.props

        if (activeUsers && activeUsers.data && activeUsers.data.trends && activeUsers.data.trends[type]) {
            let trends = activeUsers.data.trends[type],
                header = ['Month'],
                cells = []

            trends.xAxis.forEach((element, uidx) => {
                let row = [element]

                trends.yAxis.forEach(ele => {
                    // push header values once only
                    if (uidx === 0) header.push(ele.name)
                    // push values into row
                    row.push(ele.value[uidx])
                })

                // fill up data
                cells.push(row)
            })

            // merge header with rows
            cells = [header, ...cells]

            // set into specific state
            this.setState({ [type]: cells })
        } else {
            return false
        }
    }

    s2ab = s => {
        var buf = new ArrayBuffer(s.length) //convert s to arrayBuffer
        var view = new Uint8Array(buf) //create uint8array as viewer
        for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff //convert to octet
        return buf
    }

    exportXl = () => {
        // create a workbook
        let wb = XLSX.utils.book_new()

        // set props
        wb.Props = {
            Title: 'Trends Sheet',
        }

        // add some sheets
        wb.SheetNames.push('Users', 'Pages', 'Downloads')

        // get data
        const users_data = XLSX.utils.json_to_sheet(this.state.users, { skipHeader: true }),
            pages_data = XLSX.utils.json_to_sheet(this.state.pages, { skipHeader: true }),
            downloads_data = XLSX.utils.json_to_sheet(this.state.downloads, { skipHeader: true })

        // put data into sheet
        wb.Sheets['Users'] = users_data
        wb.Sheets['Pages'] = pages_data
        wb.Sheets['Downloads'] = downloads_data

        // write into sheet
        const xl = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })

        // download sheet
        saveAs(new Blob([this.s2ab(xl)], { type: 'application/octet-stream' }), 'trends_report.xlsx')
    }

    render() {
        return (
            <React.Fragment>
                <div className="d-flex justify-content-center">
                    <button type="button" className="btn btn-outline-primary" onClick={this.exportXl}>
                        Export Trends Data
                    </button>
                </div>
            </React.Fragment>
        )
    }
}
