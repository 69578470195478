import React, { Component } from 'react'
import './admin.css'
import AdminDashboardCards from './AdminDashboardCards'
import AdminDashboardTable from './AdminDashboardTable'
import { SITE_TXT, isTablet } from '../../Utils/constants'
import CSTPanel from '../CSTPanel'
import CSTDetail from '../CSTDetail'

let dataRequest = {}
class AdminData extends Component {
    constructor(props) {
        super(props)
        this.state = {
            sortColumn: 'remaining',
            sortType: 'ASC',
            userCount: 0,
            adminTableData: [],
            pageNumber: 1,
            pageSize: 20,
            isAdminDataLoading: this.props.isAdminDataLoading,
            isLoading: this.props.isLoading,
            showRightPanel: false,
            showCstDetail: false,
            cstId: null,
            isNewCst: false,
            isExpiredCst: false,
            isActiveCst: false,
            activeUsers: this.props.activeUsers,
        }
    }
    componentDidMount() {
        if (!this.state.showCstDetail) {
            this.props.getUserCount()
            const data = {
                pageSize: this.state.pageSize,
                pageNumber: this.state.pageNumber,
                search: '',
                sortColumn: this.state.sortColumn,
                sortType: this.state.sortType,
            }

            this.props.getAdminTableData(data)

            this.setState({ data: data }, () => {
                dataRequest = data
            })
        }
    }

    UNSAFE_componentWillReceiveProps(nextprops) {
        this.setState({
            userCount: nextprops.userCount ? nextprops.userCount.data[0] : [],
            adminTableData: nextprops.adminSessionData ? nextprops.adminSessionData : [],
            activeUsers: nextprops.activeUsers,
        })
        if (nextprops.isListUpdated) {
            const data = {
                pageSize: this.state.pageSize,
                pageNumber: this.state.pageNumber,
                search: '',
                sortColumn: this.state.sortColumn,
                sortType: this.state.sortType,
            }
            this.props.resetStatus()
            this.props.getAdminTableData(data)
        }
    }

    showHideRightPanel = () => {
        this.setState({ showRightPanel: !this.state.showRightPanel })
    }

    toggleCSTDetails = ({ id = '', isNewCst = false, isExpiredCst = false, isActiveCst = false }) => {
        this.setState({
            showCstDetail: !this.state.showCstDetail,
            cstId: id,
            isNewCst,
            isExpiredCst,
            isActiveCst,
        })
    }

    render() {
        const { TXT_ADMIN_SESSION, TXT_WILL_COME_SOON } = SITE_TXT
        const { showCstDetail, cstId, isNewCst, isExpiredCst, isActiveCst, activeUsers } = this.state

        return (
            <div>
                {!showCstDetail ? (
                    <div className="admin-session-container">
                        <div className="admin-session-left">
                            <div className="top-heading-admin">{TXT_ADMIN_SESSION}</div>
                            {isTablet && (
                                <div className="admin-session-tablet">
                                    <span style={{ padding: '10px' }}>{TXT_WILL_COME_SOON}</span>
                                </div>
                            )}
                            <AdminDashboardCards
                                totalUsers={this.state.userCount ? this.state.userCount.totalUser : 0}
                                newUsers={this.state.userCount ? this.state.userCount.newUser : 0}
                                adminUsers={this.state.userCount ? this.state.userCount.adminUser : 0}
                                getActiveUsers={this.props.getActiveUsers}
                                activeUsers={activeUsers}
                                fetchUserType={this.props.fetchUserType}
                                userTypeData={this.props.userTypeData}
                            />
                            <br />
                            <AdminDashboardTable
                                adminTableData={this.state.adminTableData}
                                blockUnblockUser={this.props.blockUnblockUser}
                                blockUnblockData={this.props.blockUnblockData}
                                getAdminTableData={this.props.getAdminTableData}
                                getAdminTableDataLoadMore={this.props.getAdminTableDataLoadMore}
                                isAdminDataLoading={this.props.isAdminDataLoading}
                                isLoading={this.props.isLoading}
                                dataRequest={dataRequest}
                                getDownloadLimit={this.props.getDownloadLimit}
                                setDownloadLimit={this.props.setDownloadLimit}
                                downloadLimitData={this.props.downloadLimitData}
                                downloadLimitStatus={this.props.downloadLimitStatus}
                                getAdminUserCount={this.props.getAdminUserCount}
                                adminUser={this.props.adminUser}
                                deleteUser={this.props.deleteUser}
                                isListUpdated={this.props.isListUpdated}
                            />
                        </div>
                        {!isTablet && (
                            <div className={`${this.state.showRightPanel && 'open-right-panel'} admin-session-right`}>
                                {/* <span style={{ padding: '10px' }}>
                            {TXT_WILL_COME_SOON}
                        </span> */}
                                <div className="rectangle" onClick={this.showHideRightPanel}>
                                    <span className={`${this.state.showRightPanel ? 'close' : 'chevron right'}`}>
                                        &nbsp;
                                    </span>
                                </div>
                                <CSTPanel toggleCSTDetails={this.toggleCSTDetails} />
                            </div>
                        )}
                    </div>
                ) : (
                    <CSTDetail
                        toggleCSTDetails={this.toggleCSTDetails}
                        cstId={cstId}
                        isNewCst={isNewCst}
                        isActiveCst={isActiveCst}
                        isExpiredCst={isExpiredCst}
                    />
                )}
            </div>
        )
    }
}

export default AdminData
