import { fromJS } from 'immutable'
import { APP_SHOW_MENU, APP_SHOW_WIDTH, ADMIN_LOGOUT_USER, SET_LOP_HEADER, SET_IS_AUTHENTICATED } from './constant'

const INITIAL_STATE = fromJS({
    status: null,
    isLoading: null,
    data: null,
    categoryData: null,
    width: null,
    loginStatus: null,
    showLopHeader: false,
    disableSideBar: false,
    hideSideBar: false,
    isAuthenticated: null,
})

const appReducer = (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case APP_SHOW_MENU:
            return state
                .set('isLoading', false)
                .set('status', null)
                .set('data', action.data)

        case APP_SHOW_WIDTH:
            return state
                .set('isLoading', false)
                .set('status', null)
                .set('width', action.data)

        case `${ADMIN_LOGOUT_USER}_PENDING`:
            return state
                .set('loginStatus', null)
                .set('error', null)
                .set('isLoading', true)

        case `${ADMIN_LOGOUT_USER}_SUCCESS`:
            return state.set('loginStatus', action.data.responseData.status).set('isLoading', false)

        case `${ADMIN_LOGOUT_USER}_FAILED`:
            return state
                .set('loginStatus', null)
                .set('error', action.data.responseData.result)
                .set('isLoading', false)

        case SET_LOP_HEADER:
            return state
                .set('showLopHeader', action.showLopHeader)
                .set('disableSideBar', action.disableSideBar)
                .set('hideSideBar', action.hideSideBar)

        case SET_IS_AUTHENTICATED:
            return state.set('isAuthenticated', action.data)

        default:
            return state
    }
}

export default appReducer
