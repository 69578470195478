import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import FileModal from '../../../../Lop/FileModal'
import { getPrefixedUrl } from '../../../../../Utils/apis'
import {
    addCustomContent,
    updateTemplate4Details,
    editCollageContent,
    downloadLOPDocs,
    previewLOPDocs,
    clearPreviewedDoc,
} from '../../../../../Containers/LopContent/actions'
import { downloadDocs, docUrl, reqData } from '../../../../../Containers/LopContent/selector'
import { saveDocuments } from '../../../../../Containers/Library/actions'
import { LOP_ARTICLES } from '../../../../../Containers/Lop/constant'
import { AddPartnerProfile, PencilIcon, DownloadBlueIcon, FilePreview } from '../../../../../Utils/svg'
import './index.scss'
import PDFComponent from '../../../../PDFComponent'

class DocImage extends Component {
    constructor(props) {
        super(props)
        const { indexItem } = props
        this.state = {
            openFileModal: false,
            editFile: false,
            openForPreview: false,
            docURL: null,
            indexItem,
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { docUrl, reqData } = nextProps
        const { item } = this.props
        const { id } = item
        if (docUrl.length > 0 && id === reqData.ids) {
            this.setState({
                openForPreview: true,
                docURL: docUrl[0],
            })
        }
    }

    componentDidUpdate(nextProps) {
        const { downloadDocs } = nextProps
        if (downloadDocs.length > 0) {
            if (downloadDocs[0].result && downloadDocs[0].result.fileURL) {
                this.props.saveDocuments(getPrefixedUrl(downloadDocs[0].result.fileURL))
            }
        }
    }

    closeFileModal = () => {
        this.setState({
            openFileModal: false,
            editFile: false,
        })
    }
    closePreviewModal = () => {
        const { clearPreviewedDoc } = this.props
        this.setState(
            {
                openForPreview: false,
                docURL: '',
            },
            () => {
                clearPreviewedDoc()
            }
        )
    }

    openFileModal = () => {
        const { item } = this.props
        const { id } = item
        this.setState({
            openFileModal: true,
            editFile: !!id,
        })
    }

    downloadFile = () => {
        const { item, downloadLOPDocs, sectionDetails } = this.props
        const { id } = item
        const { templateId } = sectionDetails

        const data = {
            ids: id,
            templateId,
        }
        downloadLOPDocs(data)
    }
    previewFile = () => {
        const { item, previewLOPDocs, sectionDetails } = this.props
        const { id } = item
        const { templateId } = sectionDetails
        const previewData = {
            ids: id,
            templateId,
            devicetype: 'MOBILE',
        }
        previewLOPDocs(previewData)
    }

    addDoc = (a, dataReceived) => {
        const { item, indexItem, updateTemplate4Details, sectionDetails, addCustomContent } = this.props
        const { isText, contentTypeId } = item
        const { base64path, docFile, imageSrc } = dataReceived
        updateTemplate4Details(indexItem, 'images', [{ base64path }])
        const { sectionId, templateId } = sectionDetails
        const data = new FormData()
        data.append('sectionId', sectionId)
        data.append('templateId', templateId)
        data.append('contentTypeId', contentTypeId)
        data.append('isText', isText)
        data.append('docFile', docFile)
        data.append('images', imageSrc)
        addCustomContent(data, indexItem, true, docFile.name)
        this.closeFileModal()
    }

    editDoc = (a, dataReceived) => {
        const { item, editCollageContent, indexItem, updateTemplate4Details } = this.props
        const { id, images } = item
        const { base64path, docFile, imageSrc } = dataReceived
        const data = new FormData()
        data.append('elementId', id)
        if (imageSrc) {
            data.append('image', imageSrc)
            data.append('id', images[0].id)
            updateTemplate4Details(indexItem, 'images', [{ base64path, id: images[0].id }])
        }
        docFile && data.append('docFile', docFile)
        editCollageContent(data, indexItem, false, false)
        this.closeFileModal()
    }

    render() {
        const { openFileModal, editFile, openForPreview, docURL } = this.state
        const { item, editMode, appWidth } = this.props
        const { isText, images, id, fileName = '' } = item
        const imageUrl = images[0] ? images[0].image : ''
        return (
            <div
                className={c('imageContainer ', {
                    fullImage: isText === 'False',
                })}
            >
                <div
                    className={c('imageCollage docFileImage', {
                        fullImage: isText === 'False',
                    })}
                >
                    {images.length ? (
                        <Fragment>
                            <div className="iconsDoc">
                                {editMode && (
                                    <span className="editImageIcon" onClick={this.openFileModal}>
                                        <PencilIcon />
                                    </span>
                                )}
                            </div>
                            <img
                                alt=""
                                src={images[0].base64path || getPrefixedUrl(imageUrl)}
                                key={
                                    images[0].base64path
                                        ? images[0].base64path.substring(0, 4)
                                        : getPrefixedUrl(imageUrl)
                                }
                            />
                            <div className="docIconContent">
                                {appWidth > 1024 ? (
                                    <span onClick={this.downloadFile}>
                                        Download File
                                        <i>
                                            <DownloadBlueIcon />
                                        </i>
                                    </span>
                                ) : (
                                    <span onClick={this.previewFile} className="previewContent">
                                        View Doc{' '}
                                        <i>
                                            <FilePreview />
                                        </i>
                                    </span>
                                )}
                            </div>
                        </Fragment>
                    ) : (
                        <div className="uploadContent" onClick={this.openFileModal}>
                            <AddPartnerProfile width="36" height="36" />
                        </div>
                    )}
                </div>

                {openFileModal && (
                    <FileModal
                        open
                        imageMandatory
                        name="dummy"
                        fileName={fileName}
                        docId={parseInt(id)}
                        showDelete={false}
                        isEditMode={editFile}
                        imageUrl={imageUrl}
                        requestType={LOP_ARTICLES}
                        onCloseModal={this.closeFileModal}
                        lopAddItem={this.addDoc}
                        lopEditItem={this.editDoc}
                    />
                )}
                {openForPreview && (
                    <PDFComponent
                        open
                        docId={parseInt(id)}
                        pdfUrl={docURL}
                        closePreviewModal={this.closePreviewModal}
                    />
                )}
            </div>
        )
    }
}

DocImage.propTypes = {
    indexItem: PropTypes.number.isRequired,
    item: PropTypes.instanceOf(Object).isRequired,
    editMode: PropTypes.bool.isRequired,
    sectionDetails: PropTypes.instanceOf(Object).isRequired,
    addCustomContent: PropTypes.func.isRequired,
    editCollageContent: PropTypes.func.isRequired,
    updateTemplate4Details: PropTypes.func.isRequired,
    downloadLOPDocs: PropTypes.func.isRequired,
    previewLOPDocs: PropTypes.func.isRequired,
    clearPreviewedDoc: PropTypes.func.isRequired,
    saveDocuments: PropTypes.func.isRequired,
    appWidth: PropTypes.number.isRequired,
}

const stateToProps = createStructuredSelector({
    downloadDocs: downloadDocs(),
    docUrl: docUrl(),
    reqData: reqData(),
})

const dispatchProps = {
    addCustomContent,
    editCollageContent,
    updateTemplate4Details,
    downloadLOPDocs,
    previewLOPDocs,
    clearPreviewedDoc,
    saveDocuments,
}
export default connect(stateToProps, dispatchProps)(DocImage)
