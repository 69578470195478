import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-responsive-modal'
import 'react-image-crop/dist/ReactCrop.css'
import { Button } from 'reactstrap'

import ThumbnailEditorModal from '../../ThumbnailEditorModal'
import { SITE_TXT, IMAGE_CROP_LOP_SECTION_HEADER } from '../../../Utils/constants'
import { UploadIcon, PencilIcon } from '../../../Utils/svg'
import { getPrefixedUrl } from '../../../Utils/apis'
import './index.scss'

class AlbumModal extends Component {
    state = {
        selectedOne: null,
        base64path: null,
        imageSrc: null,
    }

    selectedImage = selectedOne =>
        this.setState({
            selectedOne,
            base64path: null,
            imageSrc: selectedOne.image,
            isModalOpen: true,
        })

    updateSectionImage = () => {
        const { selectedOne, base64path, imageSrc } = this.state
        const { sectionId, currentSlide, updateSectionData } = this.props
        let mainData = null
        if (base64path) {
            mainData = new FormData()
            mainData.append('sectionId', sectionId)
            mainData.append('sectionImage', imageSrc)
        } else {
            const { id: galleryId } = selectedOne
            mainData = {
                sectionId,
                galleryId,
            }
        }
        const data = {
            currentSlide,
            image: selectedOne ? selectedOne.image : '',
            uploadedImage: base64path,
            mainData,
        }
        updateSectionData(data)
    }

    openUploadModal = () => {
        this.setState({
            isModalOpen: true,
        })
    }

    closeUploadModal = () => {
        this.setState({
            isModalOpen: false,
        })
    }

    getSrcFunction = ({ result }, imageSrc) => {
        this.setState({ imageSrc, base64path: result, selectedOne: null })
    }

    getBase64 = file => {
        let reader = new FileReader()
        reader.addEventListener(
            'load',
            () => {
                this.getSrcFunction(reader, file)
            },
            false
        )
        if (file) {
            reader.readAsDataURL(file)
        }
        this.closeUploadModal()
    }

    render() {
        const { onClose, album } = this.props
        const { selectedOne, base64path, imageSrc, isModalOpen } = this.state
        const styleClasses = {
            modal: 'albumModal',
            overlay: 'overlayAlbumModal',
            closeButton: 'closeIcon',
        }
        return (
            <Modal open onClose={onClose} center classNames={styleClasses} closeOnOverlayClick={false}>
                <h2>{SITE_TXT.TXT_SELECT_IMAGE_LIB}</h2>
                <div className="albumContent clearfix">
                    <div className="uploadContent">
                        {base64path ? (
                            <Fragment>
                                <div className="editIcon" onClick={this.openUploadModal}>
                                    <PencilIcon width="15" height="15" />
                                </div>
                                <img alt="img" src={base64path} />
                            </Fragment>
                        ) : (
                            <div onClick={this.openUploadModal} className="uploadLogo">
                                <UploadIcon width="22" height="20" />
                                <br />
                                {SITE_TXT.TXT_CLICK_TOADD_IMAGE}
                            </div>
                        )}
                    </div>
                    {album.map((item, i) => (
                        <figure key={item.id} onClick={() => this.selectedImage(item)}>
                            {selectedOne && item.id === selectedOne.id && <i />}
                            <img alt="" src={getPrefixedUrl(item.image)} />
                        </figure>
                    ))}
                </div>

                <div className="buttonContent">
                    <div className="btn btn-outline-secondary btn-lg">
                        <span onClick={onClose}>{SITE_TXT.TXT_CANCEL}</span>
                    </div>
                    <Button
                        disabled={!selectedOne && !base64path}
                        className="btn btn-primary btn-lg"
                        onClick={this.updateSectionImage}
                    >
                        {SITE_TXT.TXT_DONE}
                    </Button>
                </div>
                <Fragment>
                    {isModalOpen && (
                        <ThumbnailEditorModal
                            isAdminPage
                            onlyImage
                            modalClass="albumUploadImage galleryImageModal"
                            overlayClass="uploadGalleryImageOverlay"
                            isModalOpen={isModalOpen}
                            thumbnailImg={imageSrc || null}
                            userImg1Src={base64path || null}
                            editCancel={() => this.closeUploadModal()}
                            handleSave={(a, b, img) => this.getBase64(img)}
                            imgCropObj={IMAGE_CROP_LOP_SECTION_HEADER}
                            crossOrigin="Anonymous"
                            useOriginal={selectedOne ? selectedOne.image : ''}
                        />
                    )}
                </Fragment>
            </Modal>
        )
    }
}

AlbumModal.propTypes = {
    album: PropTypes.instanceOf(Array).isRequired,
    currentSlide: PropTypes.number.isRequired,
    sectionId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    updateSectionData: PropTypes.func.isRequired,
}

export default AlbumModal
