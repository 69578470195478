import {
    FETCH_MY_LOP,
    FETCH_MY_LOP_DETAILS,
    DELETE_LOP,
    UPDATE_LOP_SECTION,
    UPDATE_LOP_SECTION_DETAILS,
    SAVE_MY_LOP_DETAILS,
    FETCH_LOP_ALBUM,
    EDIT_SECTION_IMAGE,
    ADD_LOP_SECTION,
    TOGGLE_LOP_SECTION,
    ADD_PARTNER,
    EDIT_PARTNER,
    DELETE_PARTNER,
    FETCH_CURRENT_TEMPLATE_DETAILS,
    FETCH_ADDITIONAL_DETAILS,
    SET_SORTED_ARRAY,
    ADD_TEMPLATE2_DETAILS,
    UPDATE_LOP_TEMPLATE2_DETAILS,
    DELETE_SECTION_IN_TEMPLATE2,
    SORT_IN_TEMPLATE2,
    SET_ORDER_ITEM,
    ADD_TEMPLATE4_DETAILS,
    UPDATE_LOP_TEMPLATE4_DETAILS,
    DELETE_SECTION_IN_TEMPLATE4,
    RESTORE_SECTION_IN_TEMPLATE4,
    SORT_IN_TEMPLATE4,
    ADD_CUSTOM_CONTENT,
    EDIT_CUSTOM_CONTENT,
    EDIT_COLLAGE_CONTENT,
    ADD_DUMMY_LOADER,
    ADD_ARTICLE,
    EDIT_ARTICLE,
    EDIT_FILE_CONTENT,
    DELETE_ARTICLE,
    DOWNLOAD_LOP_DOCS,
    PREVIEW_LOP_DOCS,
    GET_COLLABORATOR_LIST,
    SEARCH_COLLABORATOR,
    EDIT_COLLABORATORS,
    ADD_COLLABORATORS,
    DELETE_COLLABORATORS,
    CLEAR_SEARCH_COLLABORATOR,
    DELETE_SECTION,
    SORT_SECTIONS,
    CONTACT_WIN_ROOM,
    LOCK_MY_LOP,
    UPDATE_COLLABORATORS,
    PUBLISH_LINK_FOR_CLIENT,
    CONTACT_WIN_ROOM_MAIL,
    ADD_EXTERNAL_USER,
    RESET_TO_ORIGINAL,
    CLEAR_PREVIEWED_DOC,
    UPDATE_BLOCK,
    ADD_BLOCK,
    DELETE_BLOCK,
    UPDATE_BLOCK_HEADER,
    UPDATE_ARTICLE_IN_SEPERATE_SECTION,
    RESET_LIST_UPDATED,
    ADD_ARTICLE_FROM_LIST,
    SET_ELEMENT_TO_SCROLL,
    COPY_LOP_SECTION,
    MOVE_LOP_SECTION,
    RESTORE_SECTION,
    EDIT_MULTIPLE_VIDEOS,
    ADD_MULTIPLE_VIEO_DATA,
    UPDATE_MULTIPLE_VIDEO,
    LOP_WELCOME,
} from './constant'

export const getMyLops = data => ({
    type: `${FETCH_MY_LOP}_LOADING`,
    data,
})

export const getMyLopsSuccess = data => ({
    type: `${FETCH_MY_LOP}_COMPLETE`,
    data,
})

export const getMyLopsFailed = data => ({
    type: `${FETCH_MY_LOP}_FAILURE`,
    data,
})

export const fetchLopDeatils = (id, retainState = false) => ({
    type: `${FETCH_MY_LOP_DETAILS}_PENDING`,
    id,
    retainState,
})

export const fetchLopDeatilsSuccess = (data, retainState) => ({
    type: `${FETCH_MY_LOP_DETAILS}_SUCCESS`,
    data,
    retainState,
})

export const fetchLopDeatilsFailed = data => ({
    type: `${FETCH_MY_LOP_DETAILS}_FAILED`,
    data,
})

export const deteleLop = id => ({
    type: `${DELETE_LOP}_PENDING`,
    id,
})

export const deteleLopSuccess = data => ({
    type: `${DELETE_LOP}_SUCCESS`,
    data,
})

export const deteleLopFailed = data => ({
    type: `${DELETE_LOP}_FAILED`,
    data,
})

export const addLopSection = (data, dataToSave) => ({
    type: `${ADD_LOP_SECTION}_PENDING`,
    data,
    dataToSave,
})

export const addLopSectionSuccess = data => ({
    type: `${ADD_LOP_SECTION}_SUCCESS`,
    data,
})

export const toggleSectionUpdate = (email = '') => ({
    type: TOGGLE_LOP_SECTION,
    email,
})

export const addLopSectionFailed = data => ({
    type: `${ADD_LOP_SECTION}_FAILED`,
    data,
})

export const updateLopSections = (index, dataKey, data) => ({
    type: UPDATE_LOP_SECTION,
    index,
    dataKey,
    data,
})

export const updateLopSectionDetails = (dataKey, data) => ({
    type: UPDATE_LOP_SECTION_DETAILS,
    dataKey,
    data,
})

export const addTemplate2Details = () => ({
    type: ADD_TEMPLATE2_DETAILS,
    value: {
        id: 0,
        heading: '',
        subHeading: '',
        description: '',
        isDelete: 'false',
    },
})

export const deleteInTemplate2 = (index, item) => ({
    type: DELETE_SECTION_IN_TEMPLATE2,
    index,
    item,
})

export const deleteSection = (index, item) => ({
    type: DELETE_SECTION,
    index,
    item,
})

export const sortSection = (updatedList, ids) => ({
    type: SORT_SECTIONS,
    updatedList,
    ids,
})

export const sortTemplate2 = updatedList => ({
    type: SORT_IN_TEMPLATE2,
    updatedList,
})

export const updateTemplate2Details = (index, key, value) => ({
    type: UPDATE_LOP_TEMPLATE2_DETAILS,
    index,
    key,
    value,
})

export const addTemplate4Details = data => ({
    type: ADD_TEMPLATE4_DETAILS,
    value: {
        id: 0,
        heading: '',
        subHeading: '',
        description: '',
        isDelete: 'false',
        ...data,
    },
})

export const deleteInTemplate4 = (index, item) => ({
    type: DELETE_SECTION_IN_TEMPLATE4,
    index,
    item,
})

export const restoreInTemplate4 = (index, item) => ({
    type: RESTORE_SECTION_IN_TEMPLATE4,
    index,
    item,
})

export const sortTemplate4 = updatedList => ({
    type: SORT_IN_TEMPLATE4,
    updatedList,
})

export const updateTemplate4Details = (index, key, value) => ({
    type: UPDATE_LOP_TEMPLATE4_DETAILS,
    index,
    key,
    value,
})

export const saveLopDetails = data => ({
    type: `${SAVE_MY_LOP_DETAILS}_PENDING`,
    data,
})

export const saveLopDetailsSuccess = data => ({
    type: `${SAVE_MY_LOP_DETAILS}_SUCCESS`,
    data,
})

export const saveLopDetailsFailed = data => ({
    type: `${SAVE_MY_LOP_DETAILS}_FAILED`,
    data,
})

export const fetchLopAlbum = id => ({
    type: `${FETCH_LOP_ALBUM}_PENDING`,
    id,
})

export const fetchLopAlbumSuccess = data => ({
    type: `${FETCH_LOP_ALBUM}_SUCCESS`,
    data,
})

export const fetchLopAlbumFailed = data => ({
    type: `${FETCH_LOP_ALBUM}_FAILED`,
    data,
})

export const editSectionImage = data => ({
    type: `${EDIT_SECTION_IMAGE}_PENDING`,
    data,
})

export const editSectionImageSuccess = data => ({
    type: `${EDIT_SECTION_IMAGE}_SUCCESS`,
    data,
})

export const editSectionImageFailed = data => ({
    type: `${EDIT_SECTION_IMAGE}_FAILED`,
    data,
})

export const addPartner = (data, curBlockIndex) => ({
    type: `${ADD_PARTNER}_PENDING`,
    data,
    curBlockIndex,
})

export const addPartnerSuccess = data => ({
    type: `${ADD_PARTNER}_SUCCESS`,
    data,
})

export const addPartnerFailed = data => ({
    type: `${ADD_PARTNER}_FAILED`,
    data,
})

export const editPartner = (data, newUpadtedData, removeIndex, index, dumIndex) => ({
    type: `${EDIT_PARTNER}_PENDING`,
    data,
    newUpadtedData,
    removeIndex,
    index,
    dumIndex,
})

export const editPartnerSuccess = data => ({
    type: `${EDIT_PARTNER}_SUCCESS`,
    data,
})

export const editPartnerFailed = data => ({
    type: `${EDIT_PARTNER}_FAILED`,
    data,
})

export const deletePartner = data => ({
    type: `${DELETE_PARTNER}_PENDING`,
    data,
})

export const deletePartnerSuccess = data => ({
    type: `${DELETE_PARTNER}_SUCCESS`,
    data,
})

export const deletePartnerFailed = data => ({
    type: `${DELETE_PARTNER}_FAILED`,
    data,
})

export const fetchCurrentTemplate = data => ({
    type: `${FETCH_CURRENT_TEMPLATE_DETAILS}_PENDING`,
    data,
})

export const fetchCurrentTemplateSuccess = data => ({
    type: `${FETCH_CURRENT_TEMPLATE_DETAILS}_SUCCESS`,
    data,
})

export const fetchCurrentTemplateFailed = data => ({
    type: `${FETCH_CURRENT_TEMPLATE_DETAILS}_FAILED`,
    data,
})

export const fetchAdditionalDetails = data => ({
    type: `${FETCH_ADDITIONAL_DETAILS}_PENDING`,
    data,
})

export const fetchAdditionalDetailsSuccess = data => ({
    type: `${FETCH_ADDITIONAL_DETAILS}_SUCCESS`,
    data,
})

export const fetchAdditionalDetailsFailed = () => ({
    type: `${FETCH_ADDITIONAL_DETAILS}_FAILED`,
})

export const editCustomContent = data => ({
    type: EDIT_CUSTOM_CONTENT,
    data,
})

export const editCollageContent = (data, sectionIndex, addLoader = false, replaceContent = true) => ({
    type: `${EDIT_COLLAGE_CONTENT}_PENDING`,
    data,
    sectionIndex,
    addLoader,
    replaceContent,
})

export const editCollageContentSuccess = (sectionIndex, content) => ({
    type: `${EDIT_COLLAGE_CONTENT}_SUCCESS`,
    data: {},
    sectionIndex,
    content,
})

export const editFileContentSuccess = () => ({
    type: `${EDIT_FILE_CONTENT}_SUCCESS`,
    data: {},
})

export const addCustomContent = (data, sectionIndex, replaceContent = false, fileName = '') => ({
    type: `${ADD_CUSTOM_CONTENT}_PENDING`,
    data,
    sectionIndex,
    replaceContent,
    fileName,
})

export const addCustomContentSuccess = (sectionIndex, id, content, fileName, replaceContent) => ({
    type: `${ADD_CUSTOM_CONTENT}_SUCCESS`,
    data: {},
    sectionIndex,
    id,
    content,
    fileName,
    replaceContent,
})

export const addCustomContentFailed = sectionIndex => ({
    type: `${ADD_CUSTOM_CONTENT}_FAILED`,
    data: {},
    sectionIndex,
})

export const setSortedArray = data => ({
    type: `${SET_SORTED_ARRAY}`,
    data,
})

export const setOrderOfItem = data => ({
    type: `${SET_ORDER_ITEM}`,
    data,
})

export const dummyLoader = () => ({
    type: `${ADD_DUMMY_LOADER}_PENDING`,
})

export const dummyLoaderClose = () => ({
    type: `${ADD_DUMMY_LOADER}_SUCCESS`,
    data: {},
})

export const addArticle = (data, curBlockIndex) => ({
    type: `${ADD_ARTICLE}_PENDING`,
    data,
    curBlockIndex,
})

export const addArticleSuccess = data => ({
    type: `${ADD_ARTICLE}_SUCCESS`,
    data,
})

export const addArticleFailed = data => ({
    type: `${ADD_ARTICLE}_FAILED`,
    data,
})

export const editArticle = (data, newUpadtedData, removeIndex, index, dumIndex) => ({
    type: `${EDIT_ARTICLE}_PENDING`,
    data,
    newUpadtedData,
    removeIndex,
    index,
    dumIndex,
})

export const editArticleSuccess = data => ({
    type: `${EDIT_ARTICLE}_SUCCESS`,
    data,
})

export const addMultipleVideoData = data => ({
    type: ADD_MULTIPLE_VIEO_DATA,
    value: {
        id: 0,
        heading: '',
        subHeading: '',
        description: '',
        isMulti: true,
        isDelete: 'false',
        ...data,
    },
})

export const editArticleFailed = data => ({
    type: `${EDIT_ARTICLE}_FAILED`,
    data,
})

export const deleteArticle = (data, mainItem, curBlockIndex, curItemIndex) => ({
    type: `${DELETE_ARTICLE}_PENDING`,
    data,
    mainItem,
    curBlockIndex,
    curItemIndex,
})

export const deleteArticleSuccess = (data, mainItem, curBlockIndex, curItemIndex) => ({
    type: `${DELETE_ARTICLE}_SUCCESS`,
    data,
    mainItem,
    curBlockIndex,
    curItemIndex,
})

export const deleteArticleFailed = data => ({
    type: `${DELETE_ARTICLE}_FAILED`,
    data,
})

export const downloadLOPDocs = data => ({
    type: `${DOWNLOAD_LOP_DOCS}_PENDING`,
    data,
})

export const downloadLOPDocsSuccess = data => ({
    type: `${DOWNLOAD_LOP_DOCS}_SUCCESS`,
    data,
})

export const downloadLOPDocsFailed = data => ({
    type: `${DOWNLOAD_LOP_DOCS}_FAILED`,
    data,
})

export const previewLOPDocs = data => ({
    type: `${PREVIEW_LOP_DOCS}_PENDING`,
    data,
})

export const previewLOPDocsSuccess = data => ({
    type: `${PREVIEW_LOP_DOCS}_SUCCESS`,
    data,
})

export const previewLOPDocsFailed = data => ({
    type: `${PREVIEW_LOP_DOCS}_FAILED`,
    data,
})
export const clearPreviewedDoc = data => ({
    type: `${CLEAR_PREVIEWED_DOC}`,
    data,
})

export const getCollaborator = data => ({
    type: `${GET_COLLABORATOR_LIST}_PENDING`,
    data,
})

export const getCollaboratorSuccess = data => ({
    type: `${GET_COLLABORATOR_LIST}_SUCCESS`,
    data,
})

export const getCollaboratorFailed = data => ({
    type: `${GET_COLLABORATOR_LIST}_FAILED`,
    data,
})

export const searchCollaborator = data => ({
    type: `${SEARCH_COLLABORATOR}_PENDING`,
    data,
})

export const searchCollaboratorSuccess = data => ({
    type: `${SEARCH_COLLABORATOR}_SUCCESS`,
    data,
})

export const searchCollaboratorFailed = data => ({
    type: `${SEARCH_COLLABORATOR}_FAILED`,
    data,
})

export const editCollaborator = data => ({
    type: `${EDIT_COLLABORATORS}_PENDING`,
    data,
})

export const editCollaboratorsSuccess = data => ({
    type: `${EDIT_COLLABORATORS}_SUCCESS`,
    data,
})

export const editCollaboratorsFailed = data => ({
    type: `${EDIT_COLLABORATORS}_FAILED`,
    data,
})

export const addCollaborators = data => ({
    type: `${ADD_COLLABORATORS}`,
    data,
})
export const updateCollaborators = data => ({
    type: `${UPDATE_COLLABORATORS}`,
    data,
})

export const deleteCollaborators = (data, index) => ({
    type: `${DELETE_COLLABORATORS}`,
    data,
    index,
})

export const clearSearchCollaborators = data => ({
    type: `${CLEAR_SEARCH_COLLABORATOR}`,
    data,
})

export const contactWinRoomState = data => ({
    type: `${CONTACT_WIN_ROOM}`,
    data,
})

export const lockMyLop = data => ({
    type: `${LOCK_MY_LOP}_PENDING`,
    data,
})

export const lockMyLopSuccess = data => ({
    type: `${LOCK_MY_LOP}_SUCCESS`,
    data,
})

export const lockMyLopFailed = data => ({
    type: `${LOCK_MY_LOP}_FAILED`,
    data,
})

export const publishLinkForClient = (data = false) => ({
    type: `${PUBLISH_LINK_FOR_CLIENT}`,
    data,
})

export const contactWinRoom = data => ({
    type: `${CONTACT_WIN_ROOM_MAIL}_PENDING`,
    data,
})

export const contactWinRoomSuccess = data => ({
    type: `${CONTACT_WIN_ROOM_MAIL}_SUCCESS`,
    data,
})

export const contactWinRoomFailed = data => ({
    type: `${CONTACT_WIN_ROOM_MAIL}_FAILED`,
    data,
})

export const addExternalUser = data => ({
    type: `${ADD_EXTERNAL_USER}_PENDING`,
    data,
})

export const addExternalUserSuccess = data => ({
    type: `${ADD_EXTERNAL_USER}_SUCCESS`,
    data,
})
export const addExternalUserFailed = data => ({
    type: `${ADD_EXTERNAL_USER}_FAILED`,
    data,
})

export const resetStateToOriginal = () => ({
    type: RESET_TO_ORIGINAL,
})

export const updateBlock = data => ({
    type: UPDATE_BLOCK,
    data,
    isListUpdated: false,
})

export const updateArticleInSepeateSection = (data, isListUpdated = false) => ({
    type: `${UPDATE_ARTICLE_IN_SEPERATE_SECTION}_SUCCESS`,
    data,
    isListUpdated,
})

export const addBlock = data => ({
    type: ADD_BLOCK,
    data,
})

export const addBlockSuccess = data => ({
    type: `${ADD_BLOCK}_SUCCESS`,
    data,
})
export const addBlockFailed = data => ({
    type: `${ADD_BLOCK}_FAILED`,
    data,
})

export const deleteBlock = (data, index, articleslist = []) => ({
    type: DELETE_BLOCK,
    data,
    index,
    articleslist,
})

export const updateBlockHeader = (data, index) => ({
    type: UPDATE_BLOCK_HEADER,
    data,
    index,
})

export const resetListUpdate = () => ({
    type: RESET_LIST_UPDATED,
})

export const addArticleFromList = data => ({
    type: ADD_ARTICLE_FROM_LIST,
    data,
})

export const setElementToScroll = (elementIdToScroll = '') => ({
    type: SET_ELEMENT_TO_SCROLL,
    elementIdToScroll,
})

export const copyMoveSection = (data, contentToUse) => ({
    type: `${COPY_LOP_SECTION}_PENDING`,
    data,
    contentToUse,
})

export const copySectionSuccess = data => ({
    type: `${COPY_LOP_SECTION}_SUCCESS`,
    data,
})

export const moveSectionSuccess = data => ({
    type: `${MOVE_LOP_SECTION}_SUCCESS`,
    data,
})

export const copyMoveSectionFailed = data => ({
    type: `${COPY_LOP_SECTION}_FAILED`,
    data,
})

export const restoreSection = data => ({
    type: `${RESTORE_SECTION}_PENDING`,
    data,
})

export const restoreSectionSuccess = data => ({
    type: `${RESTORE_SECTION}_SUCCESS`,
    data,
})
export const restoreSectionFailed = data => ({
    type: `${RESTORE_SECTION}_FAILED`,
    data,
})

export const editMultipleVideos = (index = null, data) => ({
    type: `${EDIT_MULTIPLE_VIDEOS}_PENDING`,
    data,
    index,
})

export const editMultipleVideosSuccess = data => ({
    type: `${EDIT_MULTIPLE_VIDEOS}_SUCCESS`,
    data,
})
export const editMultipleVideosFailed = data => ({
    type: `${EDIT_MULTIPLE_VIDEOS}_FAILED`,
    data,
})

export const updateMultipleVideos = (index, key, value) => ({
    type: `${UPDATE_MULTIPLE_VIDEO}`,
    index,
    key,
    value,
})

export const getLopWelcome = data => ({
    type: `GET_${LOP_WELCOME}_PENDING`,
    data,
})

export const getLopWelcomeSuccess = data => ({
    type: `GET_${LOP_WELCOME}_SUCCESS`,
    data,
})

export const getLopWelcomeFailed = data => ({
    type: `GET_${LOP_WELCOME}_FAILED`,
    data,
})

export const setLopWelcome = data => ({
    type: `SET_${LOP_WELCOME}_PENDING`,
    data,
})

export const setLopWelcomeSuccess = data => ({
    type: `SET_${LOP_WELCOME}_SUCCESS`,
    data,
})

export const setLopWelcomeFailed = data => ({
    type: `SET_${LOP_WELCOME}_FAILED`,
    data,
})

export const updateLopWelcome = data => ({
    type: `PUT_${LOP_WELCOME}_PENDING`,
    data,
})

export const updateLopWelcomeSuccess = data => ({
    type: `PUT_${LOP_WELCOME}_SUCCESS`,
    data,
})

export const updateLopWelcomeFailed = data => ({
    type: `PUT_${LOP_WELCOME}_FAILED`,
    data,
})
