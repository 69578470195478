import { fromJS } from 'immutable'
import {
    FETCH_MY_LOP,
    FETCH_MY_LOP_DETAILS,
    DELETE_LOP,
    UPDATE_LOP_SECTION,
    UPDATE_LOP_SECTION_DETAILS,
    SAVE_MY_LOP_DETAILS,
    FETCH_LOP_ALBUM,
    EDIT_SECTION_IMAGE,
    ADD_PARTNER,
    EDIT_PARTNER,
    DELETE_PARTNER,
    FETCH_CURRENT_TEMPLATE_DETAILS,
    SET_SORTED_ARRAY,
    SORT_IN_TEMPLATE2,
    ADD_TEMPLATE2_DETAILS,
    UPDATE_LOP_TEMPLATE2_DETAILS,
    DELETE_SECTION_IN_TEMPLATE2,
    SET_ORDER_ITEM,
    ADD_TEMPLATE4_DETAILS,
    UPDATE_LOP_TEMPLATE4_DETAILS,
    DELETE_SECTION_IN_TEMPLATE4,
    RESTORE_SECTION_IN_TEMPLATE4,
    SORT_IN_TEMPLATE4,
    ADD_CUSTOM_CONTENT,
    EDIT_COLLAGE_CONTENT,
    ADD_LOP_SECTION,
    TOGGLE_LOP_SECTION,
    ADD_ARTICLE,
    EDIT_ARTICLE,
    DELETE_ARTICLE,
    DOWNLOAD_LOP_DOCS,
    GET_COLLABORATOR_LIST,
    SEARCH_COLLABORATOR,
    EDIT_COLLABORATORS,
    ADD_COLLABORATORS,
    DELETE_COLLABORATORS,
    CLEAR_SEARCH_COLLABORATOR,
    DELETE_SECTION,
    SORT_SECTIONS,
    CONTACT_WIN_ROOM,
    LOCK_MY_LOP,
    UPDATE_COLLABORATORS,
    PUBLISH_LINK_FOR_CLIENT,
    CONTACT_WIN_ROOM_MAIL,
    ADD_EXTERNAL_USER,
    RESET_TO_ORIGINAL,
    PREVIEW_LOP_DOCS,
    CLEAR_PREVIEWED_DOC,
    UPDATE_BLOCK,
    ADD_BLOCK,
    DELETE_BLOCK,
    UPDATE_BLOCK_HEADER,
    FETCH_ADDITIONAL_DETAILS,
    UPDATE_ARTICLE_IN_SEPERATE_SECTION,
    RESET_LIST_UPDATED,
    ADD_ARTICLE_FROM_LIST,
    SET_ELEMENT_TO_SCROLL,
    COPY_LOP_SECTION,
    RESTORE_SECTION,
    EDIT_MULTIPLE_VIDEOS,
    ADD_MULTIPLE_VIEO_DATA,
    UPDATE_MULTIPLE_VIDEO,
    LOP_WELCOME,
} from './constant'
import { STATUS_FAILED, SITE_TXT } from '../../Utils/constants'
import { removeDuplicates } from '../../Utils/functions'

const INITIAL_STATE = fromJS({
    isLoading: false,
    lopRemoved: false,
    error: '',
    lopListContent: {
        totalRecords: 0,
        data: [],
    },
    lopDetails: {},
    album: [],
    isListUpdated: false,
    updateCurrentSlide: false,
    sortedArray: [],
    removedContent: [],
    downloadDocs: [],
    collaboratorList: [],
    searchcollaboratorList: [],
    contactWinRoomFlag: false,
    lopLockFlag: false,
    publishLink: false,
    mailSentStatus: false,
    retainState: false,
    docUrl: [],
    reqData: null,
    additionalArticles: [],
    elementIdToScroll: '',
    restoreLopStatus: false,
    welcomeVideo: [],
    isWelcomeLoading: false,
})

function lopContentReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case `${FETCH_MY_LOP}_LOADING`:
            return state.set('error', '').set('isLoading', true)
        case `${FETCH_MY_LOP}_COMPLETE`:
            return state.set('lopListContent', action.data.result).set('isLoading', false)
        case `${FETCH_MY_LOP}_FAILURE`:
            return state
                .set('error', STATUS_FAILED)
                .set('isLoading', false)
                .set('lopListContent', { totalRecords: 0, data: [] })
        case `${FETCH_MY_LOP_DETAILS}_PENDING`:
            return state
                .set('error', '')
                .set('isLoading', true)
                .set('docUrl', [])
        case `${FETCH_MY_LOP_DETAILS}_SUCCESS`:
            return state
                .set('lopDetails', fromJS(action.data.result.data[0]))
                .set('isLoading', false)
                .set('retainState', action.retainState)
        case `${FETCH_MY_LOP_DETAILS}_FAILED`:
            return state
                .set('error', SITE_TXT.LOP_IS_LOCKED)
                .set('lopDetails', {})
                .set('isLoading', false)
                .set('retainState', false)
        case `${DELETE_LOP}_SUCCESS`:
            return state.set('lopRemoved', true)
        case `${COPY_LOP_SECTION}_SUCCESS`:
            return state
                .updateIn(['lopDetails', 'sections'], arr => arr.push(fromJS(action.data.newSection)))
                .set('updateCurrentSlide', true)
        case `${ADD_LOP_SECTION}_SUCCESS`:
            return state
                .updateIn(['lopDetails', 'sections'], arr => arr.push(fromJS(action.data)))
                .set('updateCurrentSlide', true)
        case TOGGLE_LOP_SECTION:
            return state
                .set('updateCurrentSlide', false)
                .updateIn(['lopDetails', 'sectionDetail', 0], () => fromJS({ sectionContent: [], email: action.email }))
        case UPDATE_LOP_SECTION:
            return state.updateIn(['lopDetails', 'sections', action.index, action.dataKey], x => action.data)
        case UPDATE_LOP_SECTION_DETAILS:
            return state.updateIn(['lopDetails', 'sectionDetail', 0, action.dataKey], x => action.data)
        case `${SAVE_MY_LOP_DETAILS}_PENDING`:
            return state
                .set('error', '')
                .set('isLoading', true)
                .set('lopLockFlag', !action.data.dataToSend.isUnLock)
        case `${SAVE_MY_LOP_DETAILS}_SUCCESS`:
            return state.set('isLoading', false)
        case `${SAVE_MY_LOP_DETAILS}_FAILED`:
            return state.set('error', STATUS_FAILED).set('isLoading', false)
        case `${FETCH_LOP_ALBUM}_PENDING`:
            return state
                .set('error', '')
                .set('isLoading', true)
                .set('album', [])
        case `${FETCH_LOP_ALBUM}_SUCCESS`:
            return state.set('isLoading', false).set('album', action.data.result.data)
        case `${FETCH_LOP_ALBUM}_FAILED`:
            return state
                .set('error', STATUS_FAILED)
                .set('isLoading', false)
                .set('album', [])
        case `${EDIT_SECTION_IMAGE}_PENDING`:
            return state.set('error', '').set('isLoading', true)
        case `${EDIT_SECTION_IMAGE}_SUCCESS`:
            return state
                .set('isLoading', false)
                .updateIn(['lopDetails', 'sections', action.data.currentSlide, 'image'], x => action.data.image)
                .updateIn(
                    ['lopDetails', 'sections', action.data.currentSlide, 'uploadedImage'],
                    x => action.data.uploadedImage
                )
        case `${EDIT_SECTION_IMAGE}_FAILED`:
            return state.set('error', STATUS_FAILED).set('isLoading', false)

        case `${ADD_PARTNER}_PENDING`:
            return state
                .set('status', null)
                .set('isLoading', true)
                .set('isListUpdated', false)

        case `${ADD_PARTNER}_SUCCESS`: {
            return state.set('status', action.data.responseData.status).set('isListUpdated', true)
        }

        case `${ADD_PARTNER}_FAILED`:
            return state
                .set('error', action.data.responseData.status)
                .set('isLoading', false)
                .set('isListUpdated', false)

        case `${EDIT_PARTNER}_PENDING`:
            return state
                .set('status', null)
                .set('isLoading', true)
                .set('isListUpdated', false)

        case `${EDIT_PARTNER}_SUCCESS`:
            return state
                .set('status', action.data.responseData.status)
                .set('isListUpdated', true)
                .updateIn(
                    [
                        'lopDetails',
                        'sectionDetail',
                        0,
                        'sectionContent',
                        action.data.removeIndex,
                        'partners',
                        action.data.dumIndex,
                    ],
                    () => fromJS(action.data.dataToMove)
                )
        case `${EDIT_PARTNER}_FAILED`:
            return state
                .set('error', action.data.responseData.status)
                .set('isLoading', false)
                .set('isListUpdated', false)

        case `${DELETE_PARTNER}_PENDING`:
            return state
                .set('status', null)
                .set('isLoading', true)
                .set('isListUpdated', false)

        case `${DELETE_PARTNER}_SUCCESS`:
            return state
                .set('status', action.data.responseData.status)
                .set('isLoading', false)
                .set('isListUpdated', true)
                .removeIn([
                    'lopDetails',
                    'sectionDetail',
                    0,
                    'sectionContent',
                    action.data.requestData.blockIndex,
                    'partners',
                    action.data.requestData.itemIndex,
                ])

        case `${DELETE_PARTNER}_FAILED`:
            return state
                .set('error', action.data.responseData.status)
                .set('isLoading', false)
                .set('isListUpdated', false)

        case `${FETCH_CURRENT_TEMPLATE_DETAILS}_PENDING`:
            return state
                .set('error', '')
                .set('isLoading', true)
                .set('isListUpdated', false)
                .updateIn(['removedContent'], () => fromJS([]))
                .updateIn(['lopDetails', 'sectionDetail', 0], () => fromJS([]))
        case `${FETCH_CURRENT_TEMPLATE_DETAILS}_SUCCESS`:
            return state.set('isLoading', false).updateIn(['lopDetails', 'sectionDetail', 0], () => fromJS(action.data))
        case `${FETCH_CURRENT_TEMPLATE_DETAILS}_FAILED`:
            return state.set('error', SITE_TXT.LOP_IS_DELETED).set('isLoading', false)
        case UPDATE_LOP_TEMPLATE2_DETAILS:
        case UPDATE_LOP_TEMPLATE4_DETAILS:
            return state.updateIn(
                ['lopDetails', 'sectionDetail', 0, 'sectionContent', action.index, action.key],
                x => action.value
            )
        case ADD_TEMPLATE2_DETAILS:
        case ADD_TEMPLATE4_DETAILS:
            return state.updateIn(['lopDetails', 'sectionDetail', 0, 'sectionContent'], arr =>
                arr.push(fromJS(action.value))
            )
        case SORT_IN_TEMPLATE2:
        case SORT_IN_TEMPLATE4:
            return state.updateIn(['lopDetails', 'sectionDetail', 0, 'sectionContent'], () =>
                fromJS(action.updatedList)
            )
        case DELETE_SECTION_IN_TEMPLATE2:
        case DELETE_SECTION_IN_TEMPLATE4:
            return state
                .updateIn(['removedContent'], arr => arr.push(action.item))
                .removeIn(['lopDetails', 'sectionDetail', 0, 'sectionContent', action.index])
        case RESTORE_SECTION_IN_TEMPLATE4:
            return state
                .removeIn(['removedContent', action.index])
                .updateIn(['lopDetails', 'sectionDetail', 0, 'sectionContent'], arr => arr.unshift(fromJS(action.item)))
        case DELETE_SECTION:
            return state.removeIn(['lopDetails', 'sections', action.index])
        case SORT_SECTIONS:
            return state.updateIn(['lopDetails', 'sections'], () => fromJS(action.updatedList))
        case `${SET_SORTED_ARRAY}`:
            return state.updateIn(['lopDetails', 'sectionDetail', 0, 'sectionContent'], () =>
                fromJS(action.data).set('sortedArray', action.data)
            )
        case `${SET_ORDER_ITEM}`:
            return state.set('status', null).set('isLoading', false)
        case `${ADD_CUSTOM_CONTENT}_SUCCESS`:
            return state
                .updateIn(
                    ['lopDetails', 'sectionDetail', 0, 'sectionContent', action.sectionIndex, 'id'],
                    x => action.id
                )
                .updateIn(
                    ['lopDetails', 'sectionDetail', 0, 'sectionContent', action.sectionIndex, 'fileName'],
                    x => action.fileName
                )
                .updateIn(
                    ['lopDetails', 'sectionDetail', 0, 'sectionContent', action.sectionIndex, 'images'],
                    images => {
                        if (action.replaceContent) {
                            return action.content
                        }
                        return images.map((value, index) => ({
                            ...value,
                            id: action.content[index].id,
                            imageFile: null,
                        }))
                    }
                )
        case `${ADD_CUSTOM_CONTENT}_FAILED`:
            return state.updateIn(
                ['lopDetails', 'sectionDetail', 0, 'sectionContent', action.sectionIndex, 'images'],
                images => images.map((value, index) => ({}))
            )
        case `${EDIT_COLLAGE_CONTENT}_SUCCESS`:
            return state.updateIn(
                ['lopDetails', 'sectionDetail', 0, 'sectionContent', action.sectionIndex, 'images'],
                images => action.content
            )
        case `${ADD_ARTICLE}_PENDING`:
            return state.set('error', '').set('isLoading', true)
        case `${ADD_ARTICLE}_SUCCESS`:
            return state
                .set('status', null)
                .updateIn(
                    ['lopDetails', 'sectionDetail', 0, 'sectionContent', action.data.curBlockIndex, 'partners'],
                    arr =>
                        arr.push(
                            fromJS({
                                block_id: action.data.requestData.get('block_id'),
                                name: action.data.requestData.get('name'),
                                id: action.data.responseData.result.id,
                                image: action.data.responseData.result.content[0].image,
                                isDelete: 'false',
                            })
                        )
                )
        case `${ADD_ARTICLE}_FAILED`:
            return state.set('error', STATUS_FAILED).set('isLoading', false)
        case `${EDIT_ARTICLE}_PENDING`:
            return state.set('error', '').set('isLoading', true)
        case `${EDIT_ARTICLE}_SUCCESS`:
            return state
                .set('isLoading', false)
                .updateIn(
                    [
                        'lopDetails',
                        'sectionDetail',
                        0,
                        'sectionContent',
                        action.data.removeIndex,
                        'partners',
                        action.data.dumIndex,
                    ],
                    () => fromJS(action.data.dataToMove)
                )
        case `${EDIT_ARTICLE}_FAILED`:
            return state.set('error', STATUS_FAILED).set('isLoading', false)
        case `${DELETE_ARTICLE}_PENDING`:
            return state.set('error', '').set('isLoading', true)
        case `${DELETE_ARTICLE}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .updateIn(['additionalArticles'], arr => arr.unshift(fromJS(action.mainItem)))
                .removeIn([
                    'lopDetails',
                    'sectionDetail',
                    0,
                    'sectionContent',
                    action.curBlockIndex,
                    'partners',
                    action.curItemIndex,
                ])
        case `${DELETE_ARTICLE}_FAILED`:
            return state
                .set('error', STATUS_FAILED)
                .set('isLoading', false)
                .set('status', action.data.responseData.status)

        case `${DOWNLOAD_LOP_DOCS}_PENDING`:
            return state
                .set('error', '')
                .set('isLoading', true)
                .set('status', null)
                .set('downloadDocs', [])
        case `${DOWNLOAD_LOP_DOCS}_SUCCESS`:
            return state
                .set('isLoading', false)
                .updateIn(['downloadDocs'], arr => arr.push(action.data.responseData))
                .set('status', fromJS(action.data.responseData.status))

        case `${DOWNLOAD_LOP_DOCS}_FAILED`:
            return state
                .set('error', STATUS_FAILED)
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('downloadDocs', [])

        case `${PREVIEW_LOP_DOCS}_PENDING`:
            return state
                .set('error', '')
                .set('isLoading', true)
                .set('status', null)
                .setIn(['docUrl'], [])
                .set('reqData', null)
        case `${PREVIEW_LOP_DOCS}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('status', fromJS(action.data.responseData.status))
                .updateIn(['docUrl'], arr => {
                    if (arr.length === 0) {
                        arr.push(action.data.responseData.result.fileURL)
                    }
                    return arr
                })
                .set('reqData', action.data.requestData)
        case `${PREVIEW_LOP_DOCS}_FAILED`:
            return state
                .set('error', STATUS_FAILED)
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('docUrl', [])
                .set('reqData', null)
        case `${CLEAR_PREVIEWED_DOC}`:
            return state.set('docUrl', [])

        case `${GET_COLLABORATOR_LIST}_PENDING`:
            return state
                .set('error', '')
                .set('isLoading', true)
                .set('status', null)
                .set('isListUpdated', false)
        case `${GET_COLLABORATOR_LIST}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('collaboratorList', fromJS(action.data.responseData.result.data))
                .set('status', fromJS(action.data.responseData.status))
        case `${GET_COLLABORATOR_LIST}_FAILED`:
            return state
                .set('error', STATUS_FAILED)
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('collaboratorList', fromJS([]))
        case `${SEARCH_COLLABORATOR}_PENDING`:
            return state
                .set('error', '')
                .set('status', null)
                .set('searchcollaboratorList', fromJS([]))
                .set('collaboratorList', fromJS(action.data.existingList))
        case `${SEARCH_COLLABORATOR}_SUCCESS`:
            removeDuplicates(action.data.existingList, action.data.responseData.result.data)
            return state
                .set('searchcollaboratorList', fromJS(action.data.responseData.result.data))
                .set('status', fromJS(action.data.responseData.status))
        case `${SEARCH_COLLABORATOR}_FAILED`:
            return state
                .set('error', STATUS_FAILED)
                .set('status', action.data.responseData.status)
                .set('searchcollaboratorList', fromJS([]))
        case `${EDIT_COLLABORATORS}_PENDING`:
            return state
                .set('error', '')
                .set('status', null)
                .set('isListUpdated', false)
                .set('collaboratorList', fromJS(action.data.collaborators))
        case `${EDIT_COLLABORATORS}_SUCCESS`:
            return state.set('isListUpdated', true).set('status', fromJS(action.data.responseData.status))
        case `${EDIT_COLLABORATORS}_FAILED`:
            return state
                .set('isListUpdated', false)
                .set('error', STATUS_FAILED)
                .set('status', action.data.responseData.status)

        case `${ADD_COLLABORATORS}`:
            return state.updateIn(['collaboratorList'], arr => arr.push(action.data))
        case `${UPDATE_COLLABORATORS}`:
            return state.set('collaboratorList', fromJS(action.data))

        case `${DELETE_COLLABORATORS}`: {
            if (!action.data.id) {
                return state.removeIn(['collaboratorList', action.index])
            }
            return state.updateIn(['collaboratorList', action.index], () => action.data)
        }
        case `${CLEAR_SEARCH_COLLABORATOR}`:
            return state.updateIn(['searchcollaboratorList'], () => [])

        case `${CONTACT_WIN_ROOM}`:
            return state.set('contactWinRoomFlag', action.data)

        case `${LOCK_MY_LOP}_PENDING`:
            return state
                .set('error', '')
                .set('status', null)
                .set('lopLockFlag', false)

        case `${LOCK_MY_LOP}_SUCCESS`:
            const { requestType } = action.data.requestData
            return state
                .set(
                    'lopLockFlag',
                    action.data.responseData.status === 'success' && (requestType === 1 || requestType === 3)
                )
                .set('status', fromJS(action.data.responseData.status))
                .set('isLoading', false)
        case `${LOCK_MY_LOP}_FAILED`:
            return state
                .set('lopLockFlag', false)
                .set('error', STATUS_FAILED)
                .set('status', action.data.responseData.status)
                .set('isLoading', false)

        case `${PUBLISH_LINK_FOR_CLIENT}`:
            return state.set('publishLink', action.data)

        case `${CONTACT_WIN_ROOM_MAIL}_PENDING`:
            return state
                .set('error', '')
                .set('status', null)
                .set('isLoading', false)
                .set('mailSentStatus', false)
        case `${CONTACT_WIN_ROOM_MAIL}_SUCCESS`:
            return state
                .set('status', fromJS(action.data.responseData.status))
                .set('isLoading', false)
                .set('mailSentStatus', true)
        case `${CONTACT_WIN_ROOM_MAIL}_FAILED`:
            return state
                .set('error', STATUS_FAILED)
                .set('status', action.data.responseData.status)
                .set('isLoading', false)
                .set('mailSentStatus', false)

        case `${ADD_EXTERNAL_USER}_PENDING`:
            return state
                .set('error', '')
                .set('status', null)
                .set('isLoading', false)
                .set('isListUpdated', false)
        case `${ADD_EXTERNAL_USER}_SUCCESS`:
            return state
                .set('status', fromJS(action.data.responseData.status))
                .set('isLoading', true)
                .set('isListUpdated', true)
        case `${ADD_EXTERNAL_USER}_FAILED`:
            return state
                .set('error', STATUS_FAILED)
                .set('status', action.data.responseData.status)
                .set('isLoading', false)
                .set('isListUpdated', false)
        case RESET_TO_ORIGINAL:
            return state.set('retainState', false)
        case UPDATE_BLOCK:
        case `${UPDATE_ARTICLE_IN_SEPERATE_SECTION}_SUCCESS`:
            return state
                .updateIn(['lopDetails', 'sectionDetail', 0, 'sectionContent', action.data.index, 'partners'], x =>
                    x.push(action.data.dataToMove)
                )
                .removeIn([
                    'lopDetails',
                    'sectionDetail',
                    0,
                    'sectionContent',
                    action.data.removeIndex,
                    'partners',
                    action.data.dumIndex,
                ])
                .set('isListUpdated', action.isListUpdated)
        case `${ADD_BLOCK}_SUCCESS`:
            return state
                .updateIn(['lopDetails', 'sectionDetail', 0, 'sectionContent'], arr =>
                    arr.push(
                        fromJS({
                            block_id: action.data.responseData.result.id,
                            block_name: action.data.requestData.header,
                            isDelete: 'false',
                            partners: [],
                        })
                    )
                )
                .set('status', action.data.responseData.result.message)
        case `${ADD_BLOCK}_FAILED`:
            return state.set('error', STATUS_FAILED).set('status', action.data.responseData.status)
        case `${DELETE_BLOCK}`:
            return state
                .updateIn(['lopDetails', 'sectionDetail', 0, 'sectionContent', action.index], () => action.data)
                .updateIn(['additionalArticles'], () => fromJS(action.articleslist))
        case `${UPDATE_BLOCK_HEADER}`:
            return state.updateIn(
                ['lopDetails', 'sectionDetail', 0, 'sectionContent', action.index, 'block_name'],
                x => action.data
            )
        case `${FETCH_ADDITIONAL_DETAILS}_SUCCESS`:
            return state.updateIn(['additionalArticles'], () => fromJS(action.data.articleslist || []))
        case `${FETCH_ADDITIONAL_DETAILS}_FAILED`:
            return state.set('additionalArticles', [])
        case RESET_LIST_UPDATED:
            return state.set('isListUpdated', false)
        case ADD_ARTICLE_FROM_LIST:
            return state
                .updateIn(['lopDetails', 'sectionDetail', 0, 'sectionContent', action.data.index, 'partners'], x =>
                    x.push(fromJS(action.data.dataToMove))
                )
                .removeIn(['additionalArticles', action.data.dumIndex])
        case SET_ELEMENT_TO_SCROLL:
            return state.set('elementIdToScroll', action.elementIdToScroll)

        case `${RESTORE_SECTION}_PENDING`:
            return state
                .set('error', '')
                .set('status', null)
                .set('isLoading', false)
                .set('isListUpdated', false)
                .set('restoreLopStatus', false)
        case `${RESTORE_SECTION}_SUCCESS`:
            return state
                .set('status', fromJS(action.data.responseData.status))
                .set('isLoading', true)
                .set('isListUpdated', true)
                .set('restoreLopStatus', fromJS(action.data.responseData.status) === 'success' && true)
        case `${RESTORE_SECTION}_FAILED`:
            return state
                .set('error', STATUS_FAILED)
                .set('status', action.data.responseData.status)
                .set('isLoading', false)
                .set('isListUpdated', false)
                .set('restoreLopStatus', false)

        case `${EDIT_MULTIPLE_VIDEOS}_SUCCESS`:
            const {
                requestData,
                responseData: {
                    result: { id },
                },
                index,
            } = action.data
            const { isText, sectionContent } = requestData
            sectionContent.map((item, index) => {
                item.displayOrder = index + 1
            })
            const obj = {
                id: id,
                contentTypeId: 1,
                description: '',
                heading: '',
                subHeading: '',
                isDelete: 'false',
                isText,
                layoutId: 0,
                videoUrl: '',
                url: '',
                videos: sectionContent,
            }
            if (index !== null) {
                return state.updateIn(
                    ['lopDetails', 'sectionDetail', 0, 'sectionContent', index, 'videos'],
                    x => sectionContent
                )
            } else {
                return state.updateIn(['lopDetails', 'sectionDetail', 0, 'sectionContent'], arr =>
                    arr.push(fromJS(obj))
                )
            }

        case `${EDIT_MULTIPLE_VIDEOS}_PENDING`:
            return state
                .set('error', '')
                .set('status', null)
                .set('isLoading', false)
                .set('isListUpdated', false)
                .set('restoreLopStatus', false)
        case `${EDIT_MULTIPLE_VIDEOS}_FAILED`:
            return state
                .set('error', STATUS_FAILED)
                .set('status', action.data.responseData.status)
                .set('isLoading', false)
                .set('isListUpdated', false)
                .set('restoreLopStatus', false)

        case ADD_MULTIPLE_VIEO_DATA:
            return state.updateIn(['lopDetails', 'sectionDetail', 0, 'sectionContent'], arr =>
                arr.push(fromJS(action.value))
            )

        case UPDATE_MULTIPLE_VIDEO:
            return state.updateIn(
                ['lopDetails', 'sectionDetail', 0, 'sectionContent', action.index, action.key],
                x => action.value
            )

        case `GET_${LOP_WELCOME}_PENDING`:
            return state.set('status', null).set('isWelcomeLoading', true)

        case `GET_${LOP_WELCOME}_SUCCESS`:
            return state.set('isWelcomeLoading', false).set('welcomeVideo', action.data.responseData.result.data)

        case `GET_${LOP_WELCOME}_FAILED`:
            return state.set('error', action.data.responseData).set('isWelcomeLoading', false)

        case `SET_${LOP_WELCOME}_PENDING`:
            return state
                .set('status', null)
                .set('isWelcomeLoading', true)
                .set('isListUpdated', false)

        case `SET_${LOP_WELCOME}_SUCCESS`:
            return state.set('isWelcomeLoading', false).set('isListUpdated', true)

        case `SET_${LOP_WELCOME}_FAILED`:
            return state
                .set('error', action.data.responseData)
                .set('isWelcomeLoading', false)
                .set('isListUpdated', false)

        case `PUT_${LOP_WELCOME}_PENDING`:
            return state
                .set('status', null)
                .set('isWelcomeLoading', true)
                .set('isListUpdated', false)

        case `PUT_${LOP_WELCOME}_SUCCESS`:
            return state
                .set('status', false)
                .set('isWelcomeLoading', false)
                .set('isListUpdated', true)
                .set('welcomeVideo', action.data.responseData.result.data)

        case `PUT_${LOP_WELCOME}_FAILED`:
            return state
                .set('error', action.data.responseData)
                .set('status', false)
                .set('isListUpdated', false)

        default:
            return state
    }
}

export default lopContentReducer
