import { connect } from 'react-redux'
import React, { Component } from 'react'
import { createStructuredSelector } from 'reselect'

import {
    selectStatus,
    selectData,
    loginStatus,
    getLopHeaderStatus,
    isSideBarDisabled,
    isSideBarHidden,
    isAuthenticated,
} from './selector'
import { showMenu, showWidth, setLogoutUser, setAuthenticatedFlag } from './actions'
import { getMyLops } from '../LopContent/actions'
import { selectMyLopList, selectLoading } from '../LopContent/selector'
import Header from '../../Components/Header'
import Sidebar from '../../Components/Sidebar'
import { Router, useHistory } from 'react-router-dom'
import Routes from '../../routes'
import Loader from '../../Components/Loader'
import Auth from '../../Auth/Auth'

import { Security, withOktaAuth } from '@okta/okta-react'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import history from '../../history'
import Messages from '../Messages'
import { WARNING_TIME, SESSION_TIMEOUT_TIME, ENV_VARS } from '../../Utils/constants'
import { selectUserStatus } from '../GetRole/selector'
const authO = new Auth()

class App extends Component {
    state = {
        isLoading: this.props.isLoading,
        warningTime: 1000 * 60 * WARNING_TIME,
        signoutTime: 1000 * 60 * SESSION_TIMEOUT_TIME,
        timer: null
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { isLoading } = this.state

        if (isLoading !== nextProps.isLoading) {
            this.setState({ isLoading: nextProps.isLoading })
        }
        if (nextProps.loginStatus === 'success') {
            this.destroy()
        }
    }

    componentWillUnmount(){
        if(this.state.timer!=null){
            window.clearInterval(this.state.timer);
        }
    }
    componentDidMount() {
        // if (authO.isAuthenticated()) {
        if (this.props.isAuthenticated) {
            this.events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress']

            for (var i in this.events) {
                window.addEventListener(this.events[i], this.resetTimeout)
            }

            this.setTimeout()
        }
        if(this.state.timer==null && window.location.pathname.indexOf('callback')>-1){
            this.state.timer = window.setInterval(()=>{
                if(document.getElementsByClassName('main-section-class') != null && document.getElementsByClassName('main-section-class').length>0 && document.getElementsByClassName('main-section-class')[0].textContent.indexOf("AuthSdkError")>-1){
                    history.push('/library');
                }
            }, 500);
        }
    }

    resetTimeout = () => {
        this.clearTimeoutFunc()
        this.setTimeout()
    }

    clearTimeoutFunc = () => {
        if (this.warnTimeout) clearTimeout(this.warnTimeout)
        if (this.logoutTimeout) clearTimeout(this.logoutTimeout)
    }

    setTimeout = () => {
        this.warnTimeout = setTimeout(this.warn, this.state.warningTime)
        this.logoutTimeout = setTimeout(this.logout, this.state.signoutTime)
    }

    /*  warn = () => {
          window.alert(SITE_TXT.TXT_SESSION_TIMEOUT_WARNING)
    }
 */
    logout = () => {
        this.props.setLogoutUser()
        //this.destroy()
    }

    destroy = () => {
        authO.logout()
    }

    validateUser = () => {
        this.props.setAuthenticatedFlag(true)
    }

    render() {
        const { isLoading } = this.state
        const {
            showLopHeader,
            showMenu,
            showWidth,
            setLogoutUser,
            data,
            getMyLops,
            myLopData,
            isLopListLoading,
            disableSideBar,
            hideSideBar,
            userRoleStatus,
            // mid,
        } = this.props
        // const isAuthenticated = authO.isAuthenticated()
        const isAuthenticated = this.props.isAuthenticated

        const config = {
            clientId: ENV_VARS.REACT_APP_OKTA_CLIENT_ID,
            issuer: ENV_VARS.REACT_APP_OKTA_ISSUER,
            redirectUri: ENV_VARS.REACT_APP_OKTA_REDIRECT_URI,
            scopes: ['openid', 'profile', 'email'],
            token: {
                storageTypes: [
                    'localStorage',
                    'sessionStorage',
                    'cookie'
                ],
                useMultipleCookies: true // puts each token in its own cookie
            },
            cache: {
                storageTypes: [
                    'localStorage',
                    'sessionStorage',
                    'cookie'
                ]
            },
            transaction: {
                storageTypes: [
                    'cookie'
                ]
            }
            // pkce: false,
            // responseType: 'code',
            // disableHttpsCheck: false,
        }

        console.log('ENV_VARS > ', ENV_VARS)

        const etxternal_user_config = {
            clientId: ENV_VARS.REACT_APP_OKTA_EXT_CLIENT_ID,
            issuer: ENV_VARS.REACT_APP_OKTA_EXT_ISSUER,
            redirectUri: ENV_VARS.REACT_APP_OKTA_EXT_REDIRECT_URI,
            scopes: ['openid', 'profile', 'email'],
            token: {
                storageTypes: [
                    'localStorage',
                    'sessionStorage',
                    'cookie'
                ],
                useMultipleCookies: true // puts each token in its own cookie
            },
            cache: {
                storageTypes: [
                    'localStorage',
                    'sessionStorage',
                    'cookie'
                ]
            },
            transaction: {
                storageTypes: [
                    'cookie'
                ]
            }
        }

        const oktaAuth = new OktaAuth(config)
        const oktaAuthExternalUser = new OktaAuth(etxternal_user_config)
        //   const _history = useHistory();
        const restoreOriginalUri = async (_oktaAuth, originalUri) => {
            history.replace(toRelativeUrl(originalUri || '/', window.location.origin))
        }

        const getAuth = () => {
            if (sessionStorage.getItem('loginMethod') == 'EXTERNAL') {
                return oktaAuthExternalUser
            }
            return oktaAuth
        }

        return (
            <React.Fragment>
                <Security restoreOriginalUri={restoreOriginalUri} oktaAuth={getAuth()}>
                    {isLoading && <Loader />}
                    <div style={{ position: 'relative' }}>
                        <Router history={history}>
                            <div>
                                <Header
                                    authO={authO}
                                    showLopHeader={showLopHeader}
                                    showMenu={showMenu}
                                    showWidth={showWidth}
                                    setLogoutUser={setLogoutUser}
                                    getMyLops={getMyLops}
                                    myLopData={myLopData}
                                    isLopListLoading={isLopListLoading}
                                    hideSideBar={hideSideBar}
                                    userRoleStatus={userRoleStatus}
                                />
                                <Messages />
                                <section className="main-section-class">
                                    {isAuthenticated && !hideSideBar && (
                                        <Sidebar showMenudata={data} disableSideBar={disableSideBar} />
                                    )}
                                    {/* <Routes authO={authO} /> */}
                                    <Routes authO={isAuthenticated} validateUser={this.validateUser} />
                                </section>
                            </div>
                        </Router>
                    </div>
                </Security>
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => {
    return createStructuredSelector({
        status: selectStatus(),
        data: selectData(),
        loginStatus: loginStatus(),
        showLopHeader: getLopHeaderStatus(),
        disableSideBar: isSideBarDisabled(),
        hideSideBar: isSideBarHidden(),
        isLoading: state => !!state.get('loader').get('count'),
        myLopData: selectMyLopList(),
        isLopListLoading: selectLoading(),
        userRoleStatus: selectUserStatus(),
        isAuthenticated: isAuthenticated(),
    })
}

const mapDispatchToProps = {
    showMenu,
    showWidth,
    setLogoutUser,
    getMyLops,
    setAuthenticatedFlag,
}
export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(App))
