import { createSelector } from 'reselect'

const selectApp = state => state.get('app')

const selectData = () => createSelector(selectApp, app => app.toJS()['data'])

const selectLoading = () => createSelector(selectApp, app => app.toJS()['isLoading'])

const selectStatus = () => createSelector(selectApp, app => app.toJS()['status'])

const selectCategoryData = () => createSelector(selectApp, app => app.toJS()['categoryData'])

const selectWidth = () => createSelector(selectApp, app => app.toJS()['width'])

const loginStatus = () => createSelector(selectApp, app => app.toJS()['loginStatus'])

const getLopHeaderStatus = () => createSelector(selectApp, app => app.toJS()['showLopHeader'])

const isSideBarDisabled = () => createSelector(selectApp, app => app.toJS()['disableSideBar'])

const isSideBarHidden = () => createSelector(selectApp, app => app.toJS()['hideSideBar'])

const isAuthenticated = () => createSelector(selectApp, app => app.toJS()['isAuthenticated'])

export {
    selectStatus,
    selectLoading,
    selectData,
    selectCategoryData,
    selectWidth,
    loginStatus,
    getLopHeaderStatus,
    isSideBarDisabled,
    isSideBarHidden,
    isAuthenticated,
}
