import React, { Component } from 'react'
import Graph from './Graph'
let view = []
class ActiveUserTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeUsers: this.props.activeUsers,
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.activeUsers !== nextProps.activeUsers) {
            this.setState({ activeUsers: nextProps.activeUsers })
        }
    }

    render() {
        const { activeUsers } = this.state
        const { selectedTime, selectedTab, selectedTrend } = this.props
        const tableData = activeUsers && activeUsers.data

        if (selectedTab === 1) {
            view = tableData && tableData.userView
        } else if (selectedTab === 2) {
            view = tableData && tableData.pageView
        } else if (selectedTab === 3) {
            view = tableData && tableData.trends && tableData.trends[selectedTrend]
        }
        return selectedTab !== 3 ? (
            <div className="active-user-list">
                {selectedTab === 1 ? (
                    <table className="table custom-table user-table">
                        <thead>
                            <tr>
                                <th>USERS</th>
                                <th>TOTAL</th>
                            </tr>
                        </thead>
                        <tbody>
                            {view &&
                                view.map((v, k) => {
                                    return (
                                        <tr key={k}>
                                            <td>{v.user}</td>
                                            <td>{selectedTime === 2 ? v.ytdCount : v.monthlyCount}</td>
                                        </tr>
                                    )
                                })}
                        </tbody>
                    </table>
                ) : (
                    <table className="table custom-table">
                        <thead>
                            <tr>
                                <th>PAGES</th>
                                <th>MM USERS</th>
                                <th>ADMIN</th>
                                <th>CST</th>
                                <th>CST WITH LOP</th>
                                <th>EXTERNAL CLIENT</th>
                                <th>TOTAL</th>
                            </tr>
                        </thead>
                        <tbody>
                            {view &&
                                view.map((v, k) => {
                                    return selectedTime === 2 ? (
                                        <tr key={k}>
                                            <td>{v.page === -1 ? 'NA' : v.page}</td>
                                            <td>{v.mmCore === -1 ? 'NA' : v.mmCore}</td>
                                            <td>{v.admin === -1 ? 'NA' : v.admin}</td>
                                            <td>{v.cst === -1 ? 'NA' : v.cst}</td>
                                            <td>{v.cstWithLop === -1 ? 'NA' : v.cstWithLop}</td>
                                            <td>{v.externalClient === -1 ? 'NA' : v.externalClient}</td>
                                            <td>{v.ytdCount === -1 ? 'NA' : v.ytdCount}</td>
                                        </tr>
                                    ) : (
                                        <tr key={k}>
                                            <td>{v.page === -1 ? 'NA' : v.page}</td>
                                            <td>{v.mmCoreMonthly === -1 ? 'NA' : v.mmCoreMonthly}</td>
                                            <td>{v.adminMonthly === -1 ? 'NA' : v.adminMonthly}</td>
                                            <td>{v.cstMonthly === -1 ? 'NA' : v.cstMonthly}</td>
                                            <td>{v.cstWithLopMonthly === -1 ? 'NA' : v.cstWithLopMonthly}</td>
                                            <td>{v.externalClientMonthly === -1 ? 'NA' : v.externalClientMonthly}</td>
                                            <td>{v.monthlyCount === -1 ? 'NA' : v.monthlyCount}</td>
                                        </tr>
                                    )
                                })}
                        </tbody>
                    </table>
                )}
            </div>
        ) : (
            <Graph graph={view} />
        )
    }
}

export default ActiveUserTable
