import React, { Component } from 'react'
import { Card } from 'antd'
import Modal from 'react-responsive-modal'
import c from 'classnames'
import { SITE_TXT } from '../../Utils/constants'
class EmailModal extends Component {
    state = {
        ModalText: 'Content of the modal',
        confirmLoading: false,
        showCopy: false,
        loading: false,
        iconLoading: false,
    }

    createSharableLInk = () => {
        const { emails } = this.props
        const validEmail = emails.filter(email => email.emailId.length > 0).map((item, index) => item.emailId)
        const textField = document.createElement('textarea')
        textField.innerText = validEmail
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        this.setState({ showCopy: true, loading: true })
        clearTimeout(this.delayCopyTimer)
        this.delayCopyTimer = setTimeout(() => {
            this.setState({ showCopy: false, loading: true })
        }, 1200)
    }

    render() {
        const styleClasses = {
            modal: 'emailmodal',
            overlay: 'overlayAlbumModal',
            closeButton: 'close-btn-modal',
        }
        const { showCopy } = this.state
        return (
            <Modal open onClose={this.props.hideModal} center classNames={styleClasses}>
                <Card title="Active Users" bordered={false}>
                    {this.props.emails
                        .filter(email => email.emailId.length > 0)
                        .map((item, index) => (
                            <div key={index}>{item.emailId}</div>
                        ))}
                </Card>
                <button className="btn btn-primary" onClick={this.createSharableLInk}>
                    Copy to clipboard{' '}
                </button>
                <div className={c('copiedClip', { showCopy })}>{SITE_TXT.TXT_COPIED}</div>
            </Modal>
        )
    }
}

export default EmailModal
