import React, { Component } from 'react'
import './login.css'
import history from '../../history'
import { Redirect } from 'react-router'
import { SITE_TXT } from '../../Utils/constants'
import loginbg from '../../Assets/loginbg.svg'
import { withOktaAuth } from '@okta/okta-react'
import { OktaAuth } from '@okta/okta-auth-js'
import { isAuthenticated } from '../../Containers/App/selector'
import { setAuthenticatedFlag } from '../../Containers/App/actions'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { parseJwt } from '../../Auth'
import { ENV_VARS } from '../../Utils/constants'

const { TXT_MCK_MM_HUB, TXT_SIGN_IN, TXT_SIGN_IN_SSO, TXT_WELCOME_TO } = SITE_TXT
const GET_ROLE = '/getRole'

class Login extends Component {
    state = {
        username: '',
        password: '',
        submitted: false,
    }

    handleChange(e) {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }

    makeUserLogin() {
        this.props.mid.isAuthed().then(isAuthed => {
            if (isAuthed) history.replace('/auth/callback')
            // updateState({ isAuthed, isLoading: false });
        })
    }

    login(e) {
        e.preventDefault()
        // this.props.auth.login()
        this.props.mid.login().finally(() => {
            this.makeUserLogin()
        })
    }

    loginWithSSO(loginMethod, e) {
        localStorage.clear()
        sessionStorage.clear()
        e.preventDefault()
        sessionStorage.setItem('loginMethid', loginMethod)
        if (loginMethod == 'EXTERNAL') {
            const etxternal_user_config = {
                clientId: ENV_VARS.REACT_APP_OKTA_EXT_CLIENT_ID,
                issuer: ENV_VARS.REACT_APP_OKTA_EXT_ISSUER,
                redirectUri: ENV_VARS.REACT_APP_OKTA_EXT_REDIRECT_URI,
                scopes: ['openid', 'profile', 'email'],
            }
            const oktaAuthExternalUser = new OktaAuth(etxternal_user_config)
            oktaAuthExternalUser.signInWithRedirect()
        } else {
            this.props.oktaAuth.signInWithRedirect()
        }
        // this.props.auth.loginWithSSO()
    }

    render() {
        const isAuthenticated = this.props.authState?.isAuthenticated

        if (isAuthenticated) {
            this.props.setAuthenticatedFlag(true)
            localStorage.setItem('isAuthenticated', true)
            localStorage.setItem('userInfo', JSON.stringify(parseJwt(this.props.authState.accessToken.accessToken)))
        }

        return (
            <div className="login-container-new">
                {this.props.isAuthenticated === true ? (
                    <Redirect to={GET_ROLE} />
                ) : (
                    <div className="login-ctrl">
                        <div className="loginbg" style={{ backgroundImage: `url(${loginbg})` }}></div>
                        <div className="login-text-ctr">
                            <h1>
                                {TXT_WELCOME_TO} <br />
                                {TXT_MCK_MM_HUB}
                            </h1>
                            <div className="clearfix">
                                {/* <div className="btn btn-primary btn-lg" onClick={this.login.bind(this)}> */}
                                <div
                                    className="btn btn-primary btn-lg"
                                    onClick={this.loginWithSSO.bind(this, 'EXTERNAL')}
                                >
                                    {TXT_SIGN_IN}
                                </div>
                            </div>
                            <div style={{ marginTop: 20 }}>
                                <div className="btn btn-primary btn-lg" onClick={this.loginWithSSO.bind(this, 'SSO')}>
                                    {TXT_SIGN_IN_SSO}
                                </div>
                            </div>
                            {/* <div className="forgotpass">
                                <NavLink to="/forgot-pass" style={{ color: '#007bff' }}>
                                    {TXT_FORGOT_PASSWORD_REDIRECT}
                                </NavLink>{' '}
                            </div> */}
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return createStructuredSelector({
        isAuthenticated: isAuthenticated(),
    })
}

const mapDispatchToProps = {
    setAuthenticatedFlag,
}

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(Login))
