import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import {
    selectStatus,
    selectLoading,
    selectError,
    selectAddedData,
    selectEditData,
    selectfetchData,
    selectData,
    selectDeleteData,
} from './selector'
import { DeliverForm } from '../../Components'
import { deliverEditSubSection, deliverAddSubSection, fetchDeliverItem, deliverDeleteSubSection } from './actions'

const mapStateToProps = createStructuredSelector({
    isLoading: selectLoading(),
    status: selectStatus(),
    error: selectError(),
    selectAddedData: selectAddedData(),
    selectEditData: selectEditData(),
    selectfetchData: selectfetchData(),
    data: selectData(),
    selectDeleteData: selectDeleteData(),
})
const mapDispatchToProps = {
    deliverAddSubSection: deliverAddSubSection,
    deliverEditSubSection: deliverEditSubSection,
    fetchDeliverItem: fetchDeliverItem,
    deliverDeleteSubSection: deliverDeleteSubSection,
}
export default connect(mapStateToProps, mapDispatchToProps)(DeliverForm)
