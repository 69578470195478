import React, { Component } from 'react'
import PDFViewer from './PDFViewer/PDFViewer'
import PDFJSBackend from './backend/pdfjs'
import { getPrefixedUrl } from '../../Utils/apis'
import Modal from 'react-responsive-modal'

import './index.scss'
// import WebviewerBackend from './backends/webviewer'

class index extends Component {
    constructor(props) {
        super(props)
        this.myViewer = React.createRef()
        this.state = {
            docId: this.props.docId,
        }
    }

    onButtonClick = () => {
        this.myViewer.current.rotate('clockwise')
    }

    onCloseModal = () => {
        this.props.closePreviewModal(this.state.docId)
    }

    render() {
        const styleClasses = {
            modal: 'pdfModal',
            overlay: 'pdfPreviewModal',
            closeButton: 'close-btn-modal',
        }
        return (
            <div className="App">
                <Modal open onClose={this.onCloseModal} center classNames={styleClasses}>
                    <PDFViewer ref={this.myViewer} backend={PDFJSBackend} src={getPrefixedUrl(this.props.pdfUrl)} />
                </Modal>
            </div>
        )
    }
}

export default index
