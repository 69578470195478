import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-responsive-modal'
import { Button } from 'reactstrap'
import c from 'classnames'

import { SITE_TXT } from '../../../Utils/constants'
import { CircleCheck, CircleClose } from '../../../Utils/svg'
import './index.scss'

const ConfirmModal = ({ onClose, takeAction, isDelete }) => (
    <Modal
        open
        onClose={() => onClose(false)}
        center
        classNames={{
            modal: 'albumModal copySectionModal confirmModal',
            overlay: 'overlayAlbumModal',
            closeButton: 'closeIcon',
        }}
        closeOnOverlayClick={false}
    >
        <div className="topImage">{isDelete ? <CircleClose /> : <CircleCheck />}</div>
        <h2>{isDelete ? SITE_TXT.DELETE_PERMANENT : SITE_TXT.RESTORE_PERMANENT}</h2>
        <p>
            {isDelete ? (
                'Your section will be deleted forever and there will be no way of accessing it'
            ) : (
                <span>
                    Are you sure you want to restore this section.
                    <br />
                    If you restore this section it will be added to your LOP section.
                </span>
            )}
        </p>

        <div className="buttonContent">
            <span className="closeButton" onClick={() => onClose(false)}>
                {SITE_TXT.TXT_CANCEL_ADMIN}
            </span>
            <Button className={c('doneButton', { isDelete })} onClick={takeAction}>
                {isDelete ? SITE_TXT.TXT_DELETE_NEW : SITE_TXT.TXT_CONFIRM}
            </Button>
        </div>
    </Modal>
)

ConfirmModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    takeAction: PropTypes.func.isRequired,
    isDelete: PropTypes.bool.isRequired,
}

export default ConfirmModal
