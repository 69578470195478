import { createSelector } from 'reselect'

const selectMessages = state => state.get('messages')
const selectType = () => createSelector(selectMessages, messages => messages.toJS()['type'])

const selectStatus = () => createSelector(selectMessages, messages => messages.toJS()['status'])

const selectMessage = () => createSelector(selectMessages, messages => messages.toJS()['message'])

export { selectStatus, selectType, selectMessage }
