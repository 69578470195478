import React, { Component, Fragment } from 'react'
import Modal from 'react-responsive-modal'
import PropTypes from 'prop-types'
import ReactDropzone from 'react-dropzone'
import { connect } from 'react-redux'

import Inputbox from '../../../../Inputbox'
import ThumbnailEditorModal from '../../../../ThumbnailEditorModal'
import { isValidPattern, isValidFile, getFileExtType } from '../../../../../Utils/functions'
import { SITE_TXT, IMAGE_CROP_OBJ_RECT, DOCUMENT_NAME_PATTERN } from '../../../../../Utils/constants'
import { UploadIcon, AddPartnerProfile } from '../../../../../Utils/svg'
import { getPrefixedUrl } from '../../../../../Utils/apis'
import Dropdown from '../../../../Dropdown'
import './index.scss'

class ArticleModal extends Component {
    constructor(props) {
        super(props)
        const { curBlockId: block_id } = props
        this.state = {
            formData: {
                articleName: '',
                docFile: '',
                image1: '',
                block_id,
            },
            deliverImg1Src: '',
            isModalOpen: false,
            base64path: null,
            imageId: '',
            imageSrc: '',
            isEditMode: false,
            id: null,
            imgId: null,
            addEdit: false,
            acceptedFileType: SITE_TXT.PDF_FILE_TYPE_ACCEPTED,
            fileBrowsed: false,
            docFile: '',
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.isEditMode !== prevState.isEditMode && prevState.addEdit === false) {
            return {
                isEditMode: nextProps.isEditMode,
                imgId: nextProps.articleData.imageId,
                id: nextProps.articleData.id,
                addEdit: true,
                formData: {
                    articleName: nextProps.articleData.name,
                    docFile: nextProps.articleData.docFile,
                    image1: nextProps.articleData.image,
                    block_id: nextProps.articleData.block_id,
                },
            }
        }
        return null
    }

    handleDrop = files => {
        if (this.state.fileBrowsed) {
            if (!isValidFile(files.target.files[0].name, this.state.acceptedFileType)) {
                alert(SITE_TXT.TXT_INVALID_FILE_EXT)
                return
            }
            this.setState({
                docFile: files.target.files[0],
                formData: {
                    ...this.state.formData,
                    docFile: files.target.files[0],
                },
            })
        } else {
            if (files.length === 0) {
                alert(SITE_TXT.TXT_INVALID_FILE_EXT)
                return
            }
            this.setState({
                docFile: files[0],
                formData: { ...this.state.formData, docFile: files[0] },
                fileBrowsed: true,
            })
        }
    }

    getSrcFunction = reader => {
        if (this.state.imageId === 'image1') {
            this.setState({
                deliverImg1Src: reader.result,
                imageSrc: reader.result,
                base64path: reader.result,
            })
        }
    }

    getBase64(file) {
        const reader = new FileReader()
        reader.addEventListener(
            'load',
            () => {
                this.getSrcFunction(reader)
            },
            false
        )
        if (file) {
            reader.readAsDataURL(file)
        }
    }

    closeModal = () => {
        this.props.closeModal()
    }

    openUploadModal = field => {
        this.setState({
            isModalOpen: true,
            imageId: field,
        })
    }

    replaceFile = () => {
        this.browseInp.click()
    }

    closeUploadModal = () => {
        this.setState({
            isModalOpen: false,
        })
    }

    handleChange = (field, value) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: value,
            },
        })
    }

    handleSaveImage = (thumbnailId, formTosave, imageTosave) => {
        if (this.state.imageId === 'image1') {
            this.setState({
                formData: { ...this.state.formData, image1: imageTosave },
            })
            this.getBase64(imageTosave)
        }
        this.closeUploadModal()
    }

    isFormValid = () => {
        const { articleName } = this.state.formData
        if (!isValidPattern(articleName, DOCUMENT_NAME_PATTERN)) {
            alert(SITE_TXT.TXT_DOCNAME_VALID_PATTERN)
            return false
        }
        return true
    }

    createFormData = () => {
        const { id = '', formData, isEditMode } = this.state
        const { sectionDetails, articleData } = this.props
        const { articleName = '', image1 = '', docFile = '', block_id } = formData
        const { sectionId = '' } = sectionDetails

        let data = new FormData()
        let newUpadtedData = {}
        data.append('sectionId', sectionId)
        data.append('name', articleName)
        data.append('image', image1)
        data.append('docFile', docFile)
        data.append('block_id', block_id)

        if (isEditMode) {
            data.append('id', id)
            newUpadtedData = {
                id,
                name: articleName,
                block_id,
                isDelete: 'false',
                image: articleData.image,
            }
        }

        return { data, newUpadtedData }
    }

    handleSave = e => {
        e.preventDefault()
        if (!this.isFormValid()) {
            return
        }
        const {
            editArticle,
            addArticle,
            closeModal,
            curBlockIndex,
            curBlockId: elementIndex,
            blockCategory,
        } = this.props
        this.setState({ addEdit: true }, () => {
            const { data, newUpadtedData } = this.createFormData()
            if (this.state.isEditMode) {
                const newBlockIndex = blockCategory.findIndex(cat => cat.key === newUpadtedData.block_id)
                editArticle(data, newUpadtedData, curBlockIndex, newBlockIndex, parseInt(elementIndex))
            } else {
                addArticle(data, curBlockIndex)
            }
            closeModal()
        })
    }

    render() {
        const { closeModal, isEditMode, showImageMsg, blockCategory } = this.props
        const styleClasses = {
            modal: 'article',
            overlay: 'overlayArticleModal',
            closeButton: 'close-btn-modal',
        }
        const { isModalOpen, imageId, base64path, fileBrowsed, docFile, deliverImg1Src } = this.state
        const thumbImages = {
            deliverImg1Src,
        }
        const { articleName, image1, block_id } = this.state.formData
        const noBlockId = !parseInt(block_id)
        const { TXT_LOP_ARTICLES_ADD, TXT_LOP_ARTICLES_EDIT } = SITE_TXT

        return (
            <Modal open center onClose={closeModal} classNames={styleClasses}>
                <form id="addArticle" className="form-horizontal" encType="multipart/form-data" method="post">
                    {!isEditMode ? (
                        <span className="modal-heading txt-upper">{TXT_LOP_ARTICLES_ADD}</span>
                    ) : (
                        <span className="modal-heading txt-upper">{TXT_LOP_ARTICLES_EDIT}</span>
                    )}

                    <Inputbox
                        placeholder={'Enter Additional Material Name'}
                        type="text"
                        fieldFor={'articleName'}
                        label={'Additional Material Name'}
                        onChange={this.handleChange}
                        value={articleName}
                        max="100"
                    />

                    <Dropdown
                        label="Category"
                        fieldFor={'block_id'}
                        options={blockCategory}
                        value={block_id}
                        onChange={this.handleChange}
                    />

                    <div>
                        {!fileBrowsed && (
                            <ReactDropzone
                                onDrop={this.handleDrop}
                                className="dropzone"
                                accept={this.state.acceptedFileType}
                                ref={node => {
                                    this.dropzoneRef = node
                                }}
                            >
                                {docFile ? (
                                    <span>{docFile.name}</span>
                                ) : (
                                    <span>
                                        <UploadIcon width="20" height="20" />
                                        {SITE_TXT.TXT_DROPZONE + ' '}
                                        <span className="txt-blue action-icon">{SITE_TXT.TXT_BROWSE}</span>
                                    </span>
                                )}
                            </ReactDropzone>
                        )}

                        {fileBrowsed && (
                            <div className="file-info-container">
                                <input
                                    type="file"
                                    accept={this.state.acceptedFileType}
                                    ref={input => (this.browseInp = input)}
                                    onChange={this.handleDrop}
                                    className="browse-lop-inp"
                                />
                                {docFile && (
                                    <div className="file-info">
                                        <span className={'txt-upper extBox ' + getFileExtType(docFile.name)}>
                                            {getFileExtType(docFile.name)}
                                        </span>
                                        <span className="file-name">{docFile.name}</span>
                                    </div>
                                )}
                                <div className="action-icon">
                                    <span className="txt-blue" onClick={() => this.replaceFile()}>
                                        {SITE_TXT.TXT_REPLACE_FILE}
                                    </span>
                                </div>
                            </div>
                        )}
                        <hr />
                        <span className="allowedExt">
                            {SITE_TXT.TXT_ALLOWED_FILES_TEXT}
                            {'   '}
                            <b>{this.state.acceptedFileType}</b>
                        </span>
                    </div>
                    <div className="article-poto">
                        <label>Additional Material Image</label>
                        <div className={!image1 ? 'article-image' : 'article-image with-image'}>
                            <div>
                                {image1 && (
                                    <img
                                        alt="img"
                                        src={deliverImg1Src || getPrefixedUrl(`${image1}`)}
                                        className="article-image"
                                    />
                                )}
                                <div className="browse-icon" onClick={() => this.openUploadModal('image1')}>
                                    <AddPartnerProfile />
                                </div>
                            </div>
                        </div>
                    </div>

                    {showImageMsg && <span className="allowedExt">Additional Material Image is Mandatory</span>}

                    <div className="btn-section">
                        <button className="btn btn-outline-secondary btn-lg" onClick={closeModal}>
                            {SITE_TXT.TXT_CANCEL}
                        </button>
                        <button
                            disabled={
                                !this.state.isEditMode
                                    ? !articleName || !docFile || !image1 || noBlockId
                                    : !articleName || !image1 || noBlockId
                            }
                            className="btn btn-primary btn-lg"
                            onClick={e => this.handleSave(e)}
                        >
                            {SITE_TXT.TXT_DONE}
                        </button>
                    </div>
                </form>

                <Fragment>
                    {isModalOpen && (
                        <ThumbnailEditorModal
                            isAdminPage
                            onlyImage
                            isModalOpen={isModalOpen}
                            imageId={imageId}
                            thumbnailImg={image1 || null}
                            thumbImages={thumbImages}
                            deliverImgageSrc={base64path}
                            editCancel={() => this.closeUploadModal()}
                            handleSave={(id, form, test) => this.handleSaveImage(id, form, test)}
                            imgCropObj={IMAGE_CROP_OBJ_RECT}
                            overlayClass="uploadGalleryImageOverlay autoadusjtmdl"
                        />
                    )}
                </Fragment>
            </Modal>
        )
    }
}

ArticleModal.defaultProps = {
    showImageMsg: false,
}

ArticleModal.propTypes = {
    showImageMsg: PropTypes.bool,
    articleData: PropTypes.instanceOf(Object).isRequired,
    blockCategory: PropTypes.instanceOf(Array).isRequired,
    curBlockId: PropTypes.string.isRequired,
    curBlockIndex: PropTypes.number.isRequired,
    addArticle: PropTypes.func.isRequired,
    editArticle: PropTypes.func.isRequired,
}

export default connect()(ArticleModal)
