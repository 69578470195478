import { fromJS } from 'immutable'
import { SEARCH_FETCH, SEARCH, SEARCH_LOAD_MORE, SEARCH_RECENTLY_SEARCHED_DOCS } from './constant'
import { STATUS_FAILED } from '../../Utils/constants'

const INITIAL_STATE = fromJS({
    status: null,
    isLoading: false,
    error: null,
    metaData: null,
    data: null,
    recentlySearchedData: null,
})

function searchReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case `${SEARCH_FETCH}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)
        case `${SEARCH_FETCH}_SUCCESS`:
            return state
                .set('status', action.data.status)
                .set('metaData', { [action.data.module]: action.data.result })
                .set('isLoading', false)
        case `${SEARCH_FETCH}_FAILED`:
            return state.set('error', STATUS_FAILED).set('isLoading', false)
        case `${SEARCH}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)
        case `${SEARCH}_SUCCESS`:
            return state
                .set('status', action.data.status)
                .set('data', action.data.result)
                .set('isLoading', false)
        case `${SEARCH}_FAILED`:
            return state.set('error', STATUS_FAILED).set('isLoading', false)
        case `${SEARCH_LOAD_MORE}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)
        case `${SEARCH_LOAD_MORE}_SUCCESS`: {
            const data = state.get('data')
            data.list.push(...action.data.result.list)
            return state
                .set('status', action.data.status)
                .set('data', data)
                .set('isLoading', false)
        }

        case `${SEARCH_LOAD_MORE}_FAILED`:
            return state.set('error', STATUS_FAILED).set('isLoading', false)

        case `${SEARCH_RECENTLY_SEARCHED_DOCS}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)
        case `${SEARCH_RECENTLY_SEARCHED_DOCS}_SUCCESS`: {
            return state
                .set('status', action.data.status)
                .set('recentlySearchedData', action.data.result)
                .set('isLoading', false)
        }
        case `${SEARCH_RECENTLY_SEARCHED_DOCS}_FAILED`:
            return state.set('error', STATUS_FAILED).set('isLoading', false)

        default:
            return state
    }
}

export default searchReducer
