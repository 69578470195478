import React, { useEffect, useState } from 'react'
import limitCharacters from 'limit-characters'
import { convertToEmbedLinkYouTube, createMarkup } from '../../../../../Utils/functions'
import './index.scss'
import { isIphoneX, isMobile, SITE_TXT } from '../../../../../Utils/constants'
import MultiVideoUpload from '../MultiVideoModal'
import { PencilIcon } from '../../../../../Utils/svg'

const MultiVideoItem = props => {
    const { item, editMode, sectionDetails, indexItem, updateMultipleVideos } = props
    const { id, videos } = item
    const { sectionId, templateId } = sectionDetails
    const [isText, setIsText] = useState('False')
    const [openVidEditModal, toggleVidEditModal] = useState(false)
    const [videoUrl, setVideoUrls] = useState({
        mainUrl: '',
        sideUrls: [],
        mainUrlObject: {},
        heading: '',
        subHeading: '',
    })
    useEffect(() => {
        const { videos, isText, heading, subHeading } = item
        const sideUrl = []
        videos.forEach((item, index) => {
            sideUrl.push({ key: index, isActive: index === 0, read_more: false, ...item })
        })

        setVideoUrls({
            ...videoUrl,
            mainUrl: videos[0].videoUrl,
            mainUrlObject: videos[0],
            sideUrls: sideUrl,
            heading,
            subHeading,
        })
        setIsText(isText)
        // eslint-disable-next-line
    }, [videos])

    const videoUrlFrame = ({ url }) => {
        if (url) {
            const vidUrl = convertToEmbedLinkYouTube(url)
            const iframe = `<iframe class="videoIframe" src=${vidUrl} allowfullscreen />`
            if (url.includes('mm-digital-hub.com') || url.includes('/mm/')) {
                return (
                    <div>
                        <video controls key={vidUrl}>
                            <source src={url} />
                        </video>
                    </div>
                )
            } else {
                return <div dangerouslySetInnerHTML={createMarkup(iframe)} />
            }
        }
    }

    const handleVideoClick = (item, index) => {
        const videos = [...videoUrl.sideUrls]
        videos.map(item => (item.isActive = false))
        videos[index] = {
            ...videos[index],
            isActive: true,
        }

        setVideoUrls({ ...videoUrl, mainUrl: item.videoUrl, mainUrlObject: item, sideUrls: videos })
    }

    const handleInputChange = (type, e) => {
        setVideoUrls({ ...videoUrl, [e.target.name]: e.target.value })
        updateMultipleVideos(indexItem, type, e.target.value)
    }

    const handleOpenModal = () => {
        toggleVidEditModal(true)
    }
    const closeVidEditModal = () => {
        toggleVidEditModal(false)
    }

    const handleReadMore = (item, index) => {
        const videos = [...videoUrl.sideUrls]
        videos.map(item => (item.read_more = false))
        videos[index] = {
            ...videos[index],
            read_more: true,
        }

        setVideoUrls({ ...videoUrl, mainUrl: item.videoUrl, mainUrlObject: item, sideUrls: videos })
    }

    const handleReadLess = (item, index) => {
        const videos = [...videoUrl.sideUrls]
        videos[index] = {
            ...videos[index],
            read_more: false,
        }

        setVideoUrls({ ...videoUrl, mainUrl: item.videoUrl, mainUrlObject: item, sideUrls: videos })
    }
    return (
        <div className="multipleVideo">
            {editMode ? (
                <div className="multiple-video-edit">
                    <div className="vid-heading">
                        <input
                            type="text"
                            className="contentTitleText"
                            name="heading"
                            onChange={e => handleInputChange('heading', e)}
                            placeholder={SITE_TXT.TXT_HEADER_PLACEHOLDER}
                            value={videoUrl.heading}
                        />
                        <input
                            type="text"
                            className="contentSubTitleText"
                            name="subHeading"
                            onChange={e => handleInputChange('subHeading', e)}
                            placeholder={SITE_TXT.TXT_SUB_PLACEHOLDER}
                            value={videoUrl.subHeading}
                        />
                    </div>
                    <div className="vid-container">
                        <div className="main-vid-container">
                            <span className="edit-icon" onClick={handleOpenModal}>
                                <PencilIcon />
                            </span>
                            {videoUrlFrame({ url: videoUrl.mainUrlObject.videoUrl, play: true })}
                            {isText === 'True' && (
                                <div className="headings">
                                    <div className="heading-non-edit">{videoUrl.mainUrlObject.heading}</div>
                                    <div className="subheading-non-edit">{videoUrl.mainUrlObject.subHeading}</div>
                                </div>
                            )}
                        </div>
                        <div className="sidebar-vid-container">
                            {videoUrl.sideUrls &&
                                videoUrl.sideUrls.map((item, index) => (
                                    <div className="sidebar-vid-item" key={index}>
                                        <div className={`sidebar-item ${item.isActive ? 'active' : 'inactive'}`}>
                                            <div className="video-item">
                                                <div
                                                    className="sidebar-overlay"
                                                    onClick={() => handleVideoClick(item, index)}
                                                />
                                                {videoUrlFrame({ url: item.videoUrl, play: false })}
                                            </div>
                                            {isText === 'True' && (
                                                <div className="head-info">
                                                    <div className="heading-non-edit">{item.heading}</div>
                                                    {item.read_more ? (
                                                        <div className="subheading-non-edit">
                                                            {item.subHeading}
                                                            <span
                                                                className="read-more"
                                                                onClick={() => handleReadLess(item, index)}
                                                            >
                                                                <br />
                                                                Read Less
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <div className="subheading-non-edit">
                                                            {limitCharacters({
                                                                text: item.subHeading,
                                                                length: isIphoneX || isMobile ? 40 : 80,
                                                                breakWord: true,
                                                            })}
                                                            <span
                                                                className="read-more"
                                                                onClick={() => handleReadMore(item, index)}
                                                            >
                                                                ...Read More
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="multiple-video-edit">
                    <div className="vid-heading">
                        <div className="contentTitleText">{videoUrl.heading}</div>
                        <div className="contentSubTitleText">{videoUrl.subHeading}</div>
                    </div>
                    <div className="vid-container">
                        <div className="main-vid-container">
                            {videoUrlFrame({ url: videoUrl.mainUrlObject.videoUrl, play: true })}
                            {isText === 'True' && (
                                <div>
                                    <h2 className="heading-non-edit">{videoUrl.mainUrlObject.heading}</h2>
                                    <div className="subheading-non-edit">{videoUrl.mainUrlObject.subHeading}</div>
                                </div>
                            )}
                        </div>
                        <div className="sidebar-vid-container">
                            {videoUrl.sideUrls &&
                                videoUrl.sideUrls.map((item, index) => (
                                    <div className="sidebar-vid-item" key={index}>
                                        <div className={`sidebar-item ${item.isActive ? 'active' : 'inactive'}`}>
                                            <div className="video-item">
                                                <div
                                                    className="sidebar-overlay"
                                                    onClick={() => handleVideoClick(item, index)}
                                                />
                                                {videoUrlFrame({ url: item.videoUrl, play: false })}
                                            </div>
                                            {isText === 'True' && (
                                                <div className="head-info">
                                                    <div className="heading">{item.heading}</div>
                                                    {item.read_more ? (
                                                        <div className="subheading-non-edit">
                                                            {item.subHeading}
                                                            <span
                                                                className="read-more"
                                                                onClick={() => handleReadLess(item, index)}
                                                            >
                                                                <br />
                                                                Read Less
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <div className="subheading">
                                                            {limitCharacters({
                                                                text: item.subHeading,
                                                                length: isIphoneX || isMobile ? 40 : 80,
                                                                breakWord: true,
                                                            })}
                                                            <span
                                                                className="read-more"
                                                                onClick={() => handleReadMore(item, index)}
                                                            >
                                                                ...Read More
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            )}

            {openVidEditModal && (
                <MultiVideoUpload
                    videoEditData={videoUrl.sideUrls}
                    onClose={closeVidEditModal}
                    sectionId={sectionId}
                    templateId={templateId}
                    isText={isText}
                    elementId={id}
                    indexItem={indexItem}
                />
            )}
        </div>
    )
}

export default MultiVideoItem
