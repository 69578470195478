import React, { Component } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { isIphoneX, isMobile, SITE_TXT } from '../../Utils/constants'
import { PencilIcon } from '../../Utils/svg'

class CstTable extends Component {
    showSortIcon(colid) {
        return this.props.sortColumn === colid ? this.props.sortType.toLowerCase() : ''
    }

    render() {
        const { cstData, lengthTable, totalRecords, toggleCreate } = this.props
        const rows = () =>
            cstData &&
            cstData.map(item => (
                <div className={'data-row'} key={item.id}>
                    <div className={'name'}>{item.name}</div>
                    <div className={'status'}>{item.status}</div>
                    <div className={'contact'}>{item.memberContact}</div>
                    <div className={'charge-code'}>{item.chargeCode}</div>
                    <div className={'login-validity'}>{item.loginValidity}</div>
                    {!isMobile && !isIphoneX && (
                        <div className={'edit-icon'}>
                            <span
                                onClick={() => toggleCreate({ cstData: item, editMode: true })}
                                className="addEditIcon"
                            >
                                <PencilIcon />
                            </span>
                        </div>
                    )}
                </div>
            ))
        const mobileCard = () =>
            cstData &&
            cstData.map(item => (
                <div className={'card-data'}>
                    <div className={'name-header'}>
                        <div>{SITE_TXT.MMCST_NAME}</div>
                        <div>{item.name}</div>
                    </div>
                    <div className={'contact-header'}>
                        <div>{SITE_TXT.MMCST_CONTACT}</div>
                        <div>{item.memberContact}</div>
                    </div>
                    <div className={'chargeCode-header'}>
                        <div>{SITE_TXT.MMCST_CHARGE_CODE}</div>
                        <div>{item.chargeCode}</div>
                    </div>
                    <div className={'duration-header'}>
                        <div>{SITE_TXT.MMCST_DURATION}</div>
                        <div>{item.loginValidity}</div>
                    </div>
                </div>
            ))
        return (
            <div>
                <InfiniteScroll
                    pageStart={0}
                    loadMore={() => this.props.loadMoreCst()}
                    hasMore={lengthTable < totalRecords}
                    loader={
                        <div className="loader" key={0}>
                            {SITE_TXT.TXT_LOADING}
                        </div>
                    }
                    useWindow={true}
                >
                    {!isMobile && !isIphoneX ? (
                        <div className={'container-table'}>
                            <div className={'table-header'}>
                                <div
                                    className={this.showSortIcon('name')}
                                    onClick={() => this.props.sortRecord('name')}
                                >
                                    {SITE_TXT.MMCST_NAME}
                                </div>
                                <div
                                    className={this.showSortIcon('status')}
                                    onClick={() => this.props.sortRecord('status')}
                                >
                                    {SITE_TXT.MMCST_STATUS}
                                </div>
                                <div
                                    className={this.showSortIcon('memberContact')}
                                    onClick={() => this.props.sortRecord('memberContact')}
                                >
                                    {SITE_TXT.MMCST_CONTACT}
                                </div>
                                <div
                                    className={this.showSortIcon('chargeCode')}
                                    onClick={() => this.props.sortRecord('chargeCode')}
                                >
                                    {SITE_TXT.MMCST_CHARGE_CODE}
                                </div>
                                <div
                                    className={this.showSortIcon('duration')}
                                    onClick={() => this.props.sortRecord('duration')}
                                >
                                    {SITE_TXT.MMCST_DURATION}
                                </div>
                                <div>&nbsp;</div>
                            </div>
                            <div className={'table-body'}>{rows()}</div>
                        </div>
                    ) : (
                        <div>{mobileCard()}</div>
                    )}
                </InfiniteScroll>
            </div>
        )
    }
}

export default CstTable
