import { call, put, takeLatest } from 'redux-saga/effects'
import { callTestGetApi } from '../../Utils/apis'
import { INDUSTRY_GET } from './constant'
import { industrySuccessAction, industryFailedAction } from './actions'

export function* industry() {
    const response = yield call(callTestGetApi)
    const responseData = response.data
    if (responseData.status) {
        yield put(industrySuccessAction({ responseData }))
    } else {
        yield put(industryFailedAction({ responseData }))
    }
}

export function* industrySaga() {
    yield takeLatest(`${INDUSTRY_GET}_PENDING`, industry)
}

export default [industrySaga]
