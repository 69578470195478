import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import loadImage from 'blueimp-load-image'
import { createStructuredSelector } from 'reselect'

import ImageModal from '../ImageModal'
import Loader from '../../../../Loader'
import { selectWidth } from '../../../../../Containers/App/selector'
import { getPrefixedUrl } from '../../../../../Utils/apis'
import { SITE_TXT } from '../../../../../Utils/constants'
import {
    updateTemplate4Details,
    addCustomContent,
    editCustomContent,
} from '../../../../../Containers/LopContent/actions'
import { AddPartnerProfile, PencilIcon } from '../../../../../Utils/svg'
import './index.scss'

class ImageCollage extends Component {
    constructor(props) {
        super(props)
        const { item } = props
        const { images } = item
        this.state = {
            currenIndex: null,
            dummyImg: null,
            showLoader: false,
            showEditIcon: images.filter(x => isEmpty(x)).length,
        }
    }

    openUploadModal = currenIndex => {
        this.setState({ currenIndex })
    }

    handleFile = (e, currenIndex) => {
        if (e.target.files && e.target.files.length > 0) {
            const { appWidth } = this.props
            if (appWidth <= 768) {
                this.setState({ showLoader: true })
                loadImage(
                    e.target.files[0],
                    canvas => {
                        this.setState({
                            dummyImg: canvas.toDataURL('image/png'),
                            currenIndex,
                            showLoader: false,
                        })
                    },
                    { orientation: true }
                )
            } else {
                const reader = new FileReader()
                reader.addEventListener(
                    'loadend',
                    () =>
                        this.setState({
                            dummyImg: reader.result,
                            currenIndex,
                        }),
                    false
                )
                reader.readAsDataURL(e.target.files[0])
            }
        }
        e.target.value = null
    }

    getSrcFunction = ({ result }, imageFile) => {
        const { currenIndex } = this.state
        const {
            item,
            indexItem,
            updateTemplate4Details,
            sectionDetails,
            addCustomContent,
            editCustomContent,
        } = this.props
        const { images, isText, contentTypeId, layoutId, id } = item
        const updateImages = [
            ...images.slice(0, currenIndex),
            {
                imageFile,
                base64path: result,
            },
            ...images.slice(currenIndex + 1),
        ]
        updateTemplate4Details(indexItem, 'images', updateImages)

        if (!updateImages.filter(x => isEmpty(x)).length && !id) {
            const { sectionId, templateId } = sectionDetails
            const data = new FormData()
            data.append('sectionId', sectionId)
            data.append('templateId', templateId)
            data.append('contentTypeId', contentTypeId)
            data.append('isText', isText)
            data.append('layoutId', layoutId)
            updateImages.forEach(({ imageFile }) => {
                imageFile && data.append('images', imageFile)
            })
            addCustomContent(data, indexItem)
        }

        if (id) {
            const data = new FormData()
            data.append('id', images[currenIndex].id)
            data.append('image', imageFile)
            editCustomContent(data)
        }
        this.resetImage()
    }

    resetImage = () => {
        this.setState({
            currenIndex: null,
            dummyImg: null,
        })
    }

    getBase64 = file => {
        let reader = new FileReader()
        reader.addEventListener(
            'load',
            () => {
                this.getSrcFunction(reader, file)
            },
            false
        )
        reader.readAsDataURL(file)
    }

    render() {
        const { dummyImg, showEditIcon, showLoader } = this.state
        const { item, editMode } = this.props
        const { isText, layoutId, images } = item
        return (
            <div
                className={c('imageContainer', {
                    fullImage: isText === 'False',
                })}
            >
                <div className={`template${layoutId}`}>
                    {images.map((content, i) => (
                        <div className={`addImageContent image${i + 1}`} key={i}>
                            {content.base64path || content.image ? (
                                <Fragment>
                                    {editMode || showEditIcon ? (
                                        <span className="editImageIcon">
                                            <PencilIcon />
                                            <input
                                                type="file"
                                                onChange={e => this.handleFile(e, i)}
                                                accept={SITE_TXT.TXT_CREATE_USER_FILES_ACCEPTED}
                                            />
                                        </span>
                                    ) : null}
                                    <img
                                        alt=""
                                        src={content.base64path || getPrefixedUrl(content.image)}
                                        key={
                                            content.base64path
                                                ? content.base64path.substring(0, 4)
                                                : getPrefixedUrl(content.image)
                                        }
                                    />
                                </Fragment>
                            ) : (
                                <div className="uploadContent">
                                    {layoutId < 7 && (
                                        <span className="topContent">Image {('0' + (i + 1)).slice(-2)}</span>
                                    )}
                                    <AddPartnerProfile width="36" height="36" />
                                    <input
                                        type="file"
                                        onChange={e => this.handleFile(e, i)}
                                        accept={SITE_TXT.TXT_CREATE_USER_FILES_ACCEPTED}
                                    />
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                {dummyImg && (
                    <ImageModal dummyImg={dummyImg} handleSave={this.getBase64} closeModal={this.resetImage} />
                )}
                {showLoader && <Loader />}
            </div>
        )
    }
}

ImageCollage.propTypes = {
    indexItem: PropTypes.number.isRequired,
    editMode: PropTypes.bool.isRequired,
    item: PropTypes.instanceOf(Object).isRequired,
    sectionDetails: PropTypes.instanceOf(Object).isRequired,
    addCustomContent: PropTypes.func.isRequired,
    editCustomContent: PropTypes.func.isRequired,
    updateTemplate4Details: PropTypes.func.isRequired,
    appWidth: PropTypes.number.isRequired,
}

const stateToProps = createStructuredSelector({
    appWidth: selectWidth(),
})

const dispatchProps = {
    updateTemplate4Details,
    addCustomContent,
    editCustomContent,
}
export default connect(stateToProps, dispatchProps)(ImageCollage)
