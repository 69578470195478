import React, { Component } from 'react'
import PropTypes from 'prop-types'
import PersonBox from './PersonBox'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { isAdmin, isIphoneX, isMobile, SITE_TXT } from '../../Utils/constants'
import {
    getLibraryOwner,
    getOwnerLocations,
    addLibraryOwner,
    toggleListUpdatedFlag,
    updateOwner,
    removeOwner,
} from '../../Containers/Library/actions'
import { libraryOwnerList, ownerLocation, isListUpdated } from '../../Containers/Library/selector'
import { AddIcon } from '../../Utils/svg'
import PersonModal from './PersonModal'

class LibrarySidebar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isEditMode: false,
            open: false,
            person: 'OWNER',
            editData: {},
            phaseId: null,
            isListUpdated: false,
            editExpert: false,
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.isListUpdated) {
            this.setState(
                {
                    isListUpdated: nextProps.isListUpdated,
                    open: false,
                },
                () => {
                    const {
                        isRendercheck,
                        getLibraryOwner,
                        phaseId,
                        toggleListUpdatedFlag,
                        getOwnerLocations,
                    } = this.props
                    if (isRendercheck) {
                        getOwnerLocations()
                        toggleListUpdatedFlag()
                        getLibraryOwner(phaseId)
                    }
                }
            )
        }
    }
    componentDidMount = () => {
        const { getLibraryOwner, getOwnerLocations, phaseId } = this.props
        this.setState(
            {
                phaseId,
            },
            () => {
                getOwnerLocations()
                getLibraryOwner(phaseId)
            }
        )
    }

    onCloseModal = () => {
        this.setState({
            open: false,
        })
    }

    handleAddPerson = type => {
        // const { getOwnerLocations } = this.props
        // getOwnerLocations()
        this.setState({
            open: true,
            isEditMode: false,
            editData: {},
            person: type,
        })
    }

    handleEditExpert = () => {
        this.props.updateEditExpert(!this.state.editExpert)
        this.setState({
            editExpert: !this.state.editExpert,
        })
    }

    editPersonDetils = data => {
        this.setState({
            isEditMode: true,
            open: true,
            editData: data,
            person: data.usertype,
        })
    }

    render() {
        const { libraryOwnerList, ownerLocation, addLibraryOwner, updateOwner, removeOwner } = this.props
        const { open, editData, isEditMode, editExpert } = this.state

        return (
            <div className={`sidebar-list ${editExpert ? 'editing-mode' : ''}`}>
                {!isMobile && !isIphoneX && isAdmin && !editExpert ? (
                    <span className="addEditExpert" onClick={this.handleEditExpert}>
                        <AddIcon width="16" height="16" />
                        {!editExpert ? SITE_TXT.TXT_PLAYBOOK_ADD : SITE_TXT.TXT_PLAYBOOK_DONE}
                    </span>
                ) : null}
                {editExpert ? (
                    <div class="editingmode">
                        You are now Editing <strong>Owners/Experts</strong> on this page{' '}
                        <span onClick={this.handleEditExpert}>X</span>
                    </div>
                ) : null}
                <div className="list-owner">
                    <div className="title">{SITE_TXT.LOP_OWNER}</div>
                    <div className={'personBox'}>
                        {libraryOwnerList &&
                            libraryOwnerList.length > 0 &&
                            libraryOwnerList
                                .filter(item => item.usertype === 'OWNER')
                                .map(item => {
                                    return (
                                        <PersonBox
                                            key={item.id}
                                            {...item}
                                            location={item.location}
                                            editPersonDetils={() => this.editPersonDetils(item)}
                                            person={'OWNER'}
                                            editExpert={editExpert}
                                        />
                                    )
                                })}
                    </div>

                    {isAdmin &&
                    ((libraryOwnerList && libraryOwnerList.length === 0) ||
                        (libraryOwnerList.length > 0 &&
                            libraryOwnerList.filter(item => item.usertype === 'OWNER').length === 0)) ? (
                        <div className="btn-another">
                            {isAdmin && editExpert && (
                                <span className="addEditIcon" onClick={() => this.handleAddPerson('OWNER')}>
                                    <AddIcon width="16" height="16" />
                                    {SITE_TXT.TXT_PLAYBOOK_OWNER1}
                                </span>
                            )}
                        </div>
                    ) : (
                        libraryOwnerList &&
                        libraryOwnerList.length > 0 &&
                        libraryOwnerList.filter(item => item.usertype === 'OWNER').length < 2 && (
                            <div className="btn-another">
                                {isAdmin && editExpert && (
                                    <span onClick={() => this.handleAddPerson('OWNER')} className="addEditIcon">
                                        <AddIcon width="16" height="16" />
                                        {SITE_TXT.TXT_PLAYBOOK_OWNER2}
                                    </span>
                                )}
                            </div>
                        )
                    )}
                </div>
                <hr />
                <div className="list-experts">
                    <div className="title">{SITE_TXT.LOP_EXPERT}</div>
                    <div className={'expertBox'}>
                        {libraryOwnerList &&
                            libraryOwnerList.length > 0 &&
                            libraryOwnerList
                                .filter(item => item.usertype === 'EXPERT')
                                .map((item, index) => (
                                    <PersonBox
                                        key={item.id}
                                        {...item}
                                        location={item.location}
                                        editPersonDetils={() => this.editPersonDetils(item)}
                                        person={'EXPERT'}
                                        editExpert={editExpert}
                                    />
                                ))}
                    </div>

                    {isAdmin &&
                    ((libraryOwnerList && libraryOwnerList.length === 0) ||
                        (libraryOwnerList.length > 0 &&
                            libraryOwnerList &&
                            libraryOwnerList.filter(item => item.usertype === 'EXPERT').length === 0)) ? (
                        <div className="btn-another">
                            {isAdmin && editExpert && (
                                <span className="addEditIcon" onClick={() => this.handleAddPerson('EXPERT')}>
                                    <AddIcon width="16" height="16" />
                                    {SITE_TXT.TXT_PLAYBOOK_OTHER1}
                                </span>
                            )}
                        </div>
                    ) : (
                        libraryOwnerList &&
                        libraryOwnerList.length > 0 &&
                        libraryOwnerList.filter(item => item.usertype === 'EXPERT').length < 6 && (
                            <div className="btn-another">
                                {isAdmin && editExpert && (
                                    <span className="addEditIcon" onClick={() => this.handleAddPerson('EXPERT')}>
                                        <AddIcon width="16" height="16" />
                                        {SITE_TXT.TXT_PLAYBOOK_OTHER2}
                                    </span>
                                )}
                            </div>
                        )
                    )}
                </div>
                {open && (
                    <PersonModal
                        open={open}
                        onCloseModal={this.onCloseModal}
                        ownerLocation={ownerLocation}
                        editData={editData}
                        isEditMode={isEditMode}
                        person={this.state.person}
                        addPerson={addLibraryOwner}
                        deletePerson={removeOwner}
                        editPerson={updateOwner}
                        categoryId={this.state.phaseId}
                    />
                )}
            </div>
        )
    }
}

LibrarySidebar.propTypes = {
    ownerLocation: PropTypes.instanceOf(Object),
    libraryOwnerList: PropTypes.instanceOf(Object),
    getLibraryOwner: PropTypes.func.isRequired,
    getOwnerLocations: PropTypes.func.isRequired,
    addLibraryOwner: PropTypes.func.isRequired,
    updateOwner: PropTypes.func.isRequired,
    removeOwner: PropTypes.func.isRequired,
    editExpert: PropTypes.bool.isRequired,
}

const mapStateToProps = createStructuredSelector({
    libraryOwnerList: libraryOwnerList(),
    ownerLocation: ownerLocation(),
    isListUpdated: isListUpdated(),
})

const dispatchProps = {
    getLibraryOwner,
    getOwnerLocations,
    addLibraryOwner,
    toggleListUpdatedFlag,
    updateOwner,
    removeOwner,
}

export default connect(mapStateToProps, dispatchProps)(LibrarySidebar)
