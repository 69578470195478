import { call, put, takeLatest } from 'redux-saga/effects'
import { callTestGetApi } from '../../Utils/apis'
import { LEARN_GET } from './constant'
import { learnSuccessAction, learnFailedAction } from './actions'

export function* learn() {
    const response = yield call(callTestGetApi)
    const responseData = response.data
    if (responseData.status) {
        yield put(learnSuccessAction({ responseData }))
    } else {
        yield put(learnFailedAction({ responseData }))
    }
}

export function* learnSaga() {
    yield takeLatest(`${LEARN_GET}_PENDING`, learn)
}

export default [learnSaga]
