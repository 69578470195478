import React, { Component } from 'react'
import './admin.css'
import ActiveUserTable from './ActiveUserTable'
import ExportTrendsFull from './ExportTrendsFull'
import Dropdown from '../Dropdown'
import { SITE_TXT } from '../../Utils/constants'
import LOPDataTable from './LopDataTable'
import DocumnetTab from './documentsTab'
import SharedDouments from './sharedDocuments'

class Statistic extends Component {
    state = {
        selectedTab: 1,
        selectedTime: 1,
        selectedTrend: 'users',
        userType: '',
    }
    componentDidMount() {
        this.props.getActiveUsers('')
    }

    selectTab = tab => {
        this.setState({ selectedTab: tab, selectedTime: 1 })
    }
    selectTime = time => {
        this.setState({ selectedTime: time })
    }
    selectTrend = trend => {
        const _this = this
        this.setState({ selectedTrend: trend }, () => {
            if (_this.state.selectedTrend === 'pages' && !this.props.userTypeData) {
                this.props.fetchUserType()
            }
        })
    }

    handleChange = (field, userType) => {
        const data = { userType }
        this.setState({ userType })
        this.props.getActiveUsers(data)
    }
    render() {
        const { selectedTab, selectedTime, selectedTrend, userType } = this.state
        const { TXT_USER_TYPE, TXT_SELECT_USER_TYPE } = SITE_TXT
        const userOptions = this.props.userTypeData

        return (
            <div className="active-user-modal full-page">
                <div className="top-heading-admin">Statistics</div>
                <div className="lower-heading-admin">Active Users</div>
                <div className="tab-name d-flex">
                    <div className="cursor" onClick={() => this.selectTab(1)}>
                        <div className={`${selectedTab === 1 ? 'selected' : ''}`}>User View</div>
                        {selectedTab === 1 && <div className="bottom-line"></div>}
                    </div>
                    <div className="cursor" onClick={() => this.selectTab(2)}>
                        <div className={`${selectedTab === 2 ? 'selected' : ''}`}>Page View</div>
                        {selectedTab === 2 && <div className="bottom-line"></div>}
                    </div>
                    <div className="cursor" onClick={() => this.selectTab(3)}>
                        <div className={`${selectedTab === 3 ? 'selected' : ''}`}>Trends</div>
                        {selectedTab === 3 && <div className="bottom-line"></div>}
                    </div>
                    <div className="cursor" onClick={() => this.selectTab(4)}>
                        <div className={`${selectedTab === 4 ? 'selected' : ''}`}>LOP Data</div>
                        {selectedTab === 4 && <div className="bottom-line"></div>}
                    </div>
                    <div className="cursor" onClick={() => this.selectTab(5)}>
                        <div className={`${selectedTab === 5 ? 'selected' : ''}`}>Documents</div>
                        {selectedTab === 5 && <div className="bottom-line"></div>}
                    </div>
                    <div className="cursor" onClick={() => this.selectTab(6)}>
                        <div className={`${selectedTab === 6 ? 'selected' : ''}`}>Shared Documents</div>
                        {selectedTab === 6 && <div className="bottom-line"></div>}
                    </div>
                </div>
                {parseInt(selectedTab) === 1 ||
                    (parseInt(selectedTab) === 2 && (
                        <div className="d-flex" style={{ width: '100%' }}>
                            <div className="toggle">
                                <button
                                    type="button"
                                    className={`btn btn-left ${selectedTime === 1 ? 'selected-time' : ''}`}
                                    onClick={() => this.selectTime(1)}
                                >
                                    Monthly
                                </button>
                                <button
                                    type="button"
                                    className={`btn btn-right ${selectedTime === 2 ? 'selected-time' : ''}`}
                                    onClick={() => this.selectTime(2)}
                                >
                                    YTD
                                </button>
                            </div>
                        </div>
                    ))}

                {parseInt(selectedTab) === 3 && (
                    <div className="d-flex flex-col-sm" style={{ width: '100%' }}>
                        <div className="toggle">
                            <button
                                type="button"
                                className={`btn btn-left ${selectedTrend === 'users' ? 'selected-time' : ''}`}
                                onClick={() => this.selectTrend('users')}
                            >
                                User
                            </button>
                            <button
                                type="button"
                                className={`btn btn-center ${selectedTrend === 'pages' ? 'selected-time' : ''}`}
                                onClick={() => this.selectTrend('pages')}
                            >
                                Pages
                            </button>
                            <button
                                type="button"
                                className={`btn btn-right ${selectedTrend === 'downloads' ? 'selected-time' : ''}`}
                                onClick={() => this.selectTrend('downloads')}
                            >
                                Download
                            </button>
                        </div>
                        <div className="dd-user">
                            {selectedTrend === 'pages' && (
                                <Dropdown
                                    label={TXT_USER_TYPE}
                                    value={userType || ''}
                                    fieldFor="userType"
                                    onChange={(field, value) => this.handleChange(field, value)}
                                    customClass="select-validation"
                                    defaultVal={TXT_SELECT_USER_TYPE}
                                    options={userOptions}
                                />
                            )}
                        </div>
                        {selectedTab === 3 && <ExportTrendsFull activeUsers={this.props.activeUsers} />}
                    </div>
                )}

                {parseInt(selectedTab) === 1 || parseInt(selectedTab) === 2 || parseInt(selectedTab) === 3 ? (
                    <ActiveUserTable
                        activeUsers={this.props.activeUsers}
                        selectedTab={selectedTab}
                        selectedTime={selectedTime}
                        selectedTrend={selectedTrend}
                    />
                ) : (
                    ''
                )}
                {parseInt(selectedTab) === 4 && <LOPDataTable />}

                {parseInt(selectedTab) === 5 && <DocumnetTab />}

                {parseInt(selectedTab) === 6 && <SharedDouments />}
            </div>
        )
    }
}

export default Statistic
