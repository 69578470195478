import React, { Component } from 'react'
import { isEmpty } from 'lodash'
import InfiniteScroll from 'react-infinite-scroller'
import Inputbox from '../Inputbox'
import './index.css'
import { HandIcon } from '../../Utils/svg'
import DatePicker from 'react-date-picker'
import 'react-date-picker/dist/DatePicker.css'
import Modal from 'react-responsive-modal'
import Moment from 'moment'
import { SITE_TXT, STATUS_SUCCESS, STATUS_FAILURE } from '../../Utils/constants'
import Checkbox from '../Checkbox'
import Dropdown from '../Dropdown'
import { optionsRequestType } from '../../Containers/Admin/constant'
class DocCommonTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selected: {},
            selectAll: 0,
            date: new Date(),
            data: this.props.tableData ? this.props.tableData : [],
            isLoading: this.props.isLoading ? this.props.isLoading : this.props.isLoading,
            adminDocumentLoadMore: this.props.adminDocumentLoadMore ? this.props.adminDocumentLoadMore : '',
            dataRequest: this.props.data ? this.props.data : {},
            searchInput: '',
            expSearch: this.props.expSearch ? this.props.expSearch : '',
            open: false,
            showDate: false,
            selectedMonth: null,
            defaultDate: '',
            extendDoc: this.props.extendDoc,
            extendDocState: this.props.extendDocState,
            status: this.props.status,
            isSubmitted: false,
            fetchDocuments: this.props.fetchDocuments,
            modalheading: '',
            dataAction: this.props.dataAction,
            extendAction: this.props.extendAction,
            sortColumn: 'contentName',
            sortType: 'ASC',
            pageNumber: this.props.pageNumber,
            requestType: 'library',
            options: JSON.parse(JSON.stringify(optionsRequestType)),
        }
        this.handleChange = this.handleChange.bind(this)
        this.toggleRow = this.toggleRow.bind(this)

        this.dateChange = this.dateChange.bind(this)
    }

    dateChange = date => {
        this.setState({
            date: date,
        })
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState(
            {
                data: nextProps.tableData,
                isLoading: nextProps.isLoading,
                adminDocumentLoadMore: nextProps.adminDocumentLoadMore,
                dataRequest: nextProps.data,
                extendDoc: nextProps.extendDoc,
                extendDocState: nextProps.extendDocState,
                status: nextProps.status,
                fetchDocuments: nextProps.fetchDocuments,
                modalheading: nextProps.modalheading,
                dataAction: nextProps.dataAction,
                extendAction: nextProps.extendAction,
                expSearch: nextProps.expSearch,
            },
            () => {
                if (this.state.isSubmitted === true) {
                    if (this.state.status === STATUS_SUCCESS) {
                        this.setState({ isSubmitted: false, selected: {}, pageNumber: 1 }, () => {
                            const data = {
                                action: this.state.dataAction,
                                pageSize: 20,
                                pageNumber: this.state.pageNumber,
                                search: '',
                                sortColumn: this.state.sortColumn,
                                sortType: this.state.sortType,
                                requestType: this.state.requestType,
                            }

                            this.state.fetchDocuments(data)
                        })

                        this.onCloseModal()
                    } else if (this.state.status === STATUS_FAILURE) {
                        this.onCloseModal()
                    }
                }
            }
        )
    }

    extensionSubmit = () => {
        let defaultD = ''
        if (this.state.selectedMonth === 0) {
            if (
                Moment(this.state.date).format(SITE_TXT.TXT_DATE_PATTERN) ===
                Moment(new Date()).format(SITE_TXT.TXT_DATE_PATTERN)
            ) {
                let currentDate = new Date()
                currentDate.setDate(currentDate.getDate())
                defaultD = Moment(currentDate).format(SITE_TXT.TXT_DATE_PATTERN)
            } else {
                defaultD = Moment(this.state.date).format(SITE_TXT.TXT_DATE_PATTERN)
            }
        } else {
            defaultD = this.state.defaultDate
        }
        if (this.state.selectedMonth === null) {
            alert(SITE_TXT.TXT_ENTER_TIME_DURATION)
        } else {
            const selVals = Object.keys(this.state.selected)
            const ids = selVals.join(', ')
            const data = {
                ids: ids,
                customdate: defaultD,
                month: this.state.selectedMonth,
                action: this.state.extendAction,
                requestType: this.state.requestType,
            }
            this.state.extendDoc(data)
            this.setState({
                isSubmitted: true,
            })
        }
    }
    toggleRow(id) {
        const newSelected = Object.assign({}, this.state.selected)
        newSelected[id] = !this.state.selected[id]
        if (newSelected[id] === false) {
            delete newSelected[id]
        }
        this.setState({
            selected: newSelected,
            selectAll: 2,
        })
    }
    loadMoreSearch() {
        const data = this.state.dataRequest
        if (!this.state.isLoading) {
            data.pageNumber = this.state.pageNumber + 1
            data.sortColumn = this.state.sortColumn
            data.sortType = this.state.sortType
            data.requestType = this.state.requestType
            this.state.adminDocumentLoadMore(data)
            this.setState({ pageNumber: data.pageNumber })
        }
    }
    toggleSelectAll() {
        let newSelected = {}
        if (this.state.selectAll === 0) {
            this.state.data.list.forEach(x => {
                newSelected[x.id] = true
            })
        }

        this.setState({
            selected: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0,
        })
    }

    handleChange = (field, value) => {
        clearTimeout(this.delayTimer)
        this.delayTimer = setTimeout(() => {
            this.setState(
                {
                    pageNumber: 1,
                    searchInput: value.trim(),
                },
                () => {
                    const data = {
                        action: this.state.dataAction,
                        pageSize: 20,
                        pageNumber: this.state.pageNumber,
                        search: this.state.searchInput,
                        sortColumn: this.state.sortColumn,
                        sortType: this.state.sortType === 'ASC' ? 'DESC' : 'ASC',
                        requestType: this.state.requestType,
                    }

                    this.state.expSearch(data)
                }
            )
        }, 1000)
    }
    /*  subButton = () => {
        const data = this.state.dataRequest
        if (!this.state.isLoading) {
            data.search = this.state.searchInput
            data.pageNumber = 1
            this.state.expSearch(data)
        }
    } */

    onCloseModal = () => {
        this.setState({ open: false, selectedMonth: null, showDate: false })
    }
    onOpenModal = () => {
        this.setState({ open: true })
    }
    monthBtns = e => {
        this.setState({ showDate: false, selectedMonth: e })
    }
    customBtn = e => {
        this.setState({ showDate: true, selectedMonth: e })
    }

    sortDocuments = col => {
        this.setState(
            {
                pageNumber: 1,
            },
            () => {
                const data = {
                    action: this.state.dataAction,
                    pageSize: 20,
                    pageNumber: this.state.pageNumber,
                    search: this.state.searchInput,
                    sortColumn: col,
                    sortType: this.state.sortType === 'ASC' ? 'DESC' : 'ASC',
                    requestType: this.state.requestType,
                }
                this.setState({ sortColumn: data.sortColumn, sortType: data.sortType }, () => {
                    this.props.fetchDocuments(data)
                })
            }
        )
    }

    handleChangeType = (field, value) => {
        this.setState(
            {
                pageNumber: 1,
                [field]: value,
                search: '',
                searchInput: '',
                selected: {},
            },
            () => {
                const data = {
                    action: this.state.dataAction,
                    pageSize: 20,
                    pageNumber: this.state.pageNumber,
                    search: this.state.searchInput,
                    sortColumn: this.state.sortColumn,
                    sortType: this.state.sortType,
                    requestType: this.state.requestType,
                }
                this.setState({ sortColumn: data.sortColumn, sortType: data.sortType }, () => {
                    this.props.fetchDocuments(data)
                })
            }
        )

        document.getElementById('searchData').value = ''
    }
    showSortIcon = columnId => {
        if (this.state.sortColumn !== columnId) {
            return ''
        } else {
            return this.state.sortType === 'ASC' ? 'desc' : 'asc'
        }
    }

    render() {
        const { open } = this.state
        let lengthTable = this.state.data.list ? this.state.data.list.length : 0
        let totalrecords = this.state.data ? this.state.data.totalRecords : 0
        const {
            TXT_EXTEND,
            TXT_MONTHS,
            TXT_CUSTOM,
            TXT_CANCEL,
            TXT_SUBMIT,
            TXT_SEARCH_PLACEHOLDER,
            TXT_SHOWING,
            TXT_LOADING,
            TXT_CONTENT,
            TXT_EXPIRATION_DATE,
            TXT_PATH,
            TXT_YEARS,
            TXT_YEAR,
            TXT_EDIT_CHECK,
        } = SITE_TXT
        const selectAllCheckboxComp = label => (
            <div className="checkbox-wrapper selectall-chk" onClick={() => this.toggleSelectAll()}>
                <Checkbox
                    checked={this.state.selectAll === 1}
                    isIndeterminate={this.state.selectAll === 2}
                    label={label}
                />
            </div>
        )

        return (
            <div>
                <div className="table-up-heading">
                    <div className="table-first-div">
                        <div className="first-half-heading">
                            <Dropdown
                                defaultVal={TXT_EDIT_CHECK}
                                value={this.state.requestType}
                                fieldFor="requestType"
                                onChange={(field, value) => this.handleChangeType(field, value)}
                                options={this.state.options}
                            />
                        </div>
                        {totalrecords > 0 && !isEmpty(this.state.selected) ? (
                            <div className="sec-half-heading">
                                <span className="hand-icon action-icon" onClick={this.onOpenModal.bind(this)}>
                                    <HandIcon />
                                    {TXT_EXTEND}
                                </span>
                                {open && (
                                    <Modal
                                        open={open}
                                        onClose={this.onCloseModal}
                                        center
                                        classNames={{
                                            modal: 'comp-modal',
                                        }}
                                    >
                                        <div className="headingModal">
                                            <h2>{this.state.modalheading}</h2>
                                        </div>
                                        <form id="extendExpForm">
                                            <div className="btn-group">
                                                <button type="button" onClick={this.monthBtns.bind(this, 6)}>
                                                    6 {' ' + TXT_MONTHS}
                                                </button>
                                                <button type="button" onClick={this.monthBtns.bind(this, 12)}>
                                                    1 {' ' + TXT_YEAR}
                                                </button>
                                                <button type="button" onClick={this.monthBtns.bind(this, 18)}>
                                                    18 {' ' + TXT_MONTHS}
                                                </button>
                                                <button type="button" onClick={this.monthBtns.bind(this, 24)}>
                                                    2 {' ' + TXT_YEARS}
                                                </button>
                                                <button type="button" onClick={this.customBtn.bind(this, 0)}>
                                                    {TXT_CUSTOM}
                                                </button>
                                            </div>
                                            {this.state.showDate ? (
                                                <div className="dateClass">
                                                    <DatePicker
                                                        minDate={new Date()}
                                                        onChange={this.dateChange}
                                                        value={this.state.date}
                                                    />
                                                </div>
                                            ) : null}

                                            <div className="butClass ">
                                                <button
                                                    type="button"
                                                    className="btn btn-default cancel-Btn"
                                                    onClick={this.onCloseModal}
                                                >
                                                    {TXT_CANCEL}
                                                </button>
                                                <button
                                                    onClick={this.extensionSubmit.bind(this)}
                                                    type="button"
                                                    className="btn btn-primary"
                                                >
                                                    {TXT_SUBMIT}
                                                </button>
                                            </div>
                                        </form>
                                    </Modal>
                                )}
                            </div>
                        ) : (
                            ''
                        )}
                    </div>

                    <div className="tableSecDiv">
                        <div className="searchBoxContainer">
                            <div className="searchInputBox">
                                <Inputbox
                                    placeholder={TXT_SEARCH_PLACEHOLDER}
                                    type="text"
                                    fieldFor="search"
                                    onChange={this.handleChange}
                                    id="searchData"
                                />
                            </div>
                            {/* <div
                                    className="searchButton"
                                    onClick={this.subButton.bind(this)}
                                >
                                    <SearchIcon />
                        </div> */}
                        </div>
                        <div className="showing-records">
                            {TXT_SHOWING}
                            <span className="total-rec">
                                {' '}
                                {lengthTable}/{totalrecords}
                            </span>
                        </div>
                    </div>
                </div>
                <InfiniteScroll
                    pageStart={0}
                    loadMore={() => this.loadMoreSearch()}
                    loader={
                        <div className="loader" key={0}>
                            {TXT_LOADING}
                        </div>
                    }
                    hasMore={lengthTable < totalrecords}
                    useWindow={true}
                >
                    <table className="table custom-table">
                        <thead>
                            <tr>
                                <th width="60px">{selectAllCheckboxComp()}</th>
                                <th
                                    onClick={() => this.sortDocuments('contentName')}
                                    id="contentName"
                                    className={this.showSortIcon('contentName')}
                                >
                                    {this.state.requestType === 'mylop' ? 'MY LOP' : TXT_CONTENT}
                                </th>
                                <th
                                    onClick={() => this.sortDocuments('expiryDate')}
                                    id="expiryDate"
                                    className={this.showSortIcon('expiryDate')}
                                >
                                    {TXT_EXPIRATION_DATE}
                                </th>
                                <th
                                    onClick={() => this.sortDocuments('displayPath')}
                                    id="displayPath"
                                    className={this.showSortIcon('displayPath')}
                                >
                                    {this.state.requestType === 'mylop' ? 'CURRENT USERS' : TXT_PATH}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.tableData.list &&
                                this.props.tableData.list.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            <div className="checkbox-wrapper" onClick={() => this.toggleRow(item.id)}>
                                                <Checkbox
                                                    itemId={item.id}
                                                    checked={this.state.selected[item.id] === true}
                                                />
                                            </div>
                                        </td>
                                        <td>{item.contentName}</td>
                                        <td>{item.expiryDate}</td>
                                        <td>
                                            {this.state.requestType === 'mylop'
                                                ? item && item.user_email && item.user_email.join(', ')
                                                : item.displayPath}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </InfiniteScroll>
            </div>
        )
    }
}

export default DocCommonTable
