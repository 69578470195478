import { createSelector } from 'reselect'

const selectIndustry = state => state.get('industry')

const selectLoading = () => createSelector(selectIndustry, industry => industry.toJS()['isLoading'])

const selectStatus = () => createSelector(selectIndustry, industry => industry.toJS()['status'])

const selectError = () => createSelector(selectIndustry, industry => industry.toJS()['error'])

export { selectStatus, selectLoading, selectError }
