import React, { useEffect, useCallback, useState } from 'react'

import { connect } from 'react-redux'
import XLSX from 'xlsx'
import moment from 'moment'

import { createStructuredSelector } from 'reselect'
import { Table, Input } from 'antd'
import { docsStats } from '../../Containers/Admin/selector'

import { getDocsStats } from '../../Containers/Admin/actions'

import { docsDataColumns } from '../../Containers/Admin/constant'
import { DownloadIcon, LopHeaderLink } from '../../Utils/svg'

const { Search } = Input
let lastCopiedFile = null

const DocumnetTab = ({ getDocsStats, docsStats }) => {
    const [docsData, setDocsData] = useState({
        table: [],
        cst_user: 0,
        doc_created: 0,
        external_views: 0,
    })
    const [search, setSearch] = useState('')

    const getDocsStatsCall = useCallback(() => getDocsStats(), [getDocsStats])
    useEffect(() => {
        getDocsStatsCall()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getDocsStatsCall])

    useEffect(() => {
        if (docsStats) {
            if (docsStats) {
                const today = moment()
                const lastMonthDate = moment().subtract(1, 'months')
                const lastOneMonthRecord = []

                docsStats.map((item, index) => {
                    item.key = index
                    item.created_date = moment(item.created_date).format('L')
                    item.dateShow = moment(item.UploadedAt).format('DD/MM/YYYY')
                    const recordDate = moment(item.created_date, 'DD/MM/YYYY')
                    if (recordDate.isBetween(lastMonthDate, today, 'days', '[]')) {
                        lastOneMonthRecord.push(item)
                    }

                    item.boxUrlLink = (
                        <span className="tooltip-copy" onClick={() => copyLink(item, index)}>
                            <LopHeaderLink style={{ cursor: 'pointer' }} />
                            <span className="tooltiptext" id={`myTooltip-${index}`}>
                                Copy Link
                            </span>
                        </span>
                    )

                    return item
                })
                let externalViews = 0
                lastOneMonthRecord.forEach(item => {
                    externalViews += item.total_external_viewer
                })

                setDocsData({
                    ...docsData,
                    table: docsStats,
                    docs_created: lastOneMonthRecord.length,
                    external_views: externalViews,
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docsStats])

    const onChange = (pagination, filters, sorter, extra) => {
        // console.log('params', pagination, filters, sorter, extra)
    }
    const handleInputChange = value => {
        setSearch(value)
    }
    useEffect(() => {
        if (search) {
            const tableData = docsData.table
            const newTable = tableData.filter(
                item => item.Name.toLowerCase().includes(search) || item.UploadedAt.toLowerCase().includes(search)
            )
            setDocsData({ ...docsData, table: newTable })
        } else {
            if (docsStats) {
                setDocsData({ ...docsData, table: docsStats })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])

    useEffect(() => {
        const dom = document.querySelector('.ant-pagination-options')
        if (dom && dom.childNodes.length < 2) {
            const node = document.createElement('DIV')
            const textNode = document.createTextNode('Rows per page')
            node.appendChild(textNode)
            dom.append(node)
        }
    })

    const handleDataDownload = () => {
        if (docsStats) {
            if (docsStats) {
                const tempTableData = docsStats.map(item => {
                    delete item.key
                    return item
                })
                const animalWS = XLSX.utils.json_to_sheet(tempTableData)
                const wb = XLSX.utils.book_new() // make Workbook of Excel
                XLSX.utils.book_append_sheet(wb, animalWS, 'Docs Data') // sheetAName is name of Worksheet
                XLSX.writeFile(wb, 'Docs_Data.xlsx') // name of the file is 'book.xlsx'
            }
        }
    }

    const copyLink = (data, index) => {
        if (lastCopiedFile !== null) {
            let tooltipN = document.getElementById(`myTooltip-${lastCopiedFile}`)
            tooltipN.innerHTML = 'Copy Link'
        }
        lastCopiedFile = index
        const el = document.createElement('textarea')
        el.value = data.boxPerviewURL
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        let tooltip = document.getElementById(`myTooltip-${index}`)
        tooltip.innerHTML = 'Copied'
        document.body.removeChild(el)
    }

    return (
        <>
            <div className="lop-data-table">
                <div>
                    <div className="search-input">
                        <Search size="large" placeholder="Search" allowClear onSearch={handleInputChange} />
                    </div>
                    <div className="downloadExcel" onClick={handleDataDownload}>
                        <DownloadIcon fill="white" /> Download excel
                    </div>
                    {docsData.table.length ? (
                        <Table
                            columns={docsDataColumns}
                            dataSource={docsData.table}
                            pagination={{
                                defaultPageSize: 5,
                                defaultCurrent: 1,
                                showSizeChanger: true,
                                pageSizeOptions: ['5', '10', '15', '20'],
                                locale: { items_per_page: '' },
                            }}
                            onChange={onChange}
                        />
                    ) : null}
                </div>
            </div>
        </>
    )
}
const mapStateToProps = createStructuredSelector({
    docsStats: docsStats(),
})
const mapDispatchToProps = {
    getDocsStats,
}
export default connect(mapStateToProps, mapDispatchToProps)(DocumnetTab)
