import {
    LIBRARY_ITEMS_GET,
    LIBRARY_ITEM,
    LIBRARY_DOCUMENTS,
    LIBRARY_DOCUMENTS_MORE,
    LIBRARY_ITEM_ADD,
    LIBRARY_ITEM_EDIT,
    LIBRARY_ITEM_TRASH,
    LIBRARY_PHASE_ADD,
    LIBRARY_PHASE_EDIT,
    LIBRARY_PHASE_TRASH,
    LIBRARY_ITEM_REORDER,
    LIBRARY_DOWNLOAD_DOCUMENTS,
    SAVE_LIBRARY_DOWNLOAD_DOCUMENTS,
    LIBRARY_EMAIL_DOCUMENTS,
    LIBRARY_OWNER_GET,
    OWNER_LOCATION_GET,
    LIBRARY_OWNER_ADD,
    TOGGLE_LIST_UPDATED_FLAG,
    UPDATE_OWNER,
    REMOVE_OWNER,
    SHARE_DOCUMENTS_POST,
    SHARE_DOCUMENTS_GET,
    SHARE_DOCUMENT_POST,
    MULTIPLE_SHARE_DOCS,
} from './constant'

export const library = data => ({
    type: `${LIBRARY_ITEMS_GET}_PENDING`,
    data,
})

export const librarySuccessAction = data => ({
    type: `${LIBRARY_ITEMS_GET}_SUCCESS`,
    data,
})

export const libraryFailedAction = data => ({
    type: `${LIBRARY_ITEMS_GET}_FAILED`,
    data,
})

export const libraryCategory = data => ({
    type: `${LIBRARY_ITEM}_PENDING`,
    data,
})

export const libraryCategorySuccess = data => ({
    type: `${LIBRARY_ITEM}_SUCCESS`,
    data,
})

export const libraryCategoryFailure = data => ({
    type: `${LIBRARY_ITEM}_FAILED`,
    data,
})

export const libraryDocuments = data => ({
    type: `${LIBRARY_DOCUMENTS}_PENDING`,
    data,
})

export const libraryDocumentsSuccess = data => ({
    type: `${LIBRARY_DOCUMENTS}_SUCCESS`,
    data,
})

export const libraryDocumentsFailure = data => ({
    type: `${LIBRARY_DOCUMENTS}_FAILED`,
    data,
})

export const libraryDocumentsMore = data => ({
    type: `${LIBRARY_DOCUMENTS_MORE}_PENDING`,
    data,
})

export const libraryDocumentsMoreSuccess = data => ({
    type: `${LIBRARY_DOCUMENTS_MORE}_SUCCESS`,
    data,
})

export const libraryDocumentsMoreFailure = data => ({
    type: `${LIBRARY_DOCUMENTS_MORE}_FAILED`,
    data,
})

export const add = data => ({
    type: `${LIBRARY_ITEM_ADD}_PENDING`,
    data,
})

export const addSuccess = data => ({
    type: `${LIBRARY_ITEM_ADD}_SUCCESS`,
    data,
})

export const addFailure = data => ({
    type: `${LIBRARY_ITEM_ADD}_FAILED`,
    data,
})

export const edit = data => ({
    type: `${LIBRARY_ITEM_EDIT}_PENDING`,
    data,
})

export const editSuccess = data => ({
    type: `${LIBRARY_ITEM_EDIT}_SUCCESS`,
    data,
})

export const editFailure = data => ({
    type: `${LIBRARY_ITEM_EDIT}_FAILED`,
    data,
})

export const trash = (id, categoryId) => ({
    type: `${LIBRARY_ITEM_TRASH}_PENDING`,
    data: { id, categoryId },
})

export const trashSuccess = data => ({
    type: `${LIBRARY_ITEM_TRASH}_SUCCESS`,
    data,
})

export const trashFailure = data => ({
    type: `${LIBRARY_ITEM_TRASH}_FAILED`,
    data,
})

export const addPhase = data => ({
    type: `${LIBRARY_PHASE_ADD}_PENDING`,
    data,
})

export const addPhaseSuccess = data => ({
    type: `${LIBRARY_PHASE_ADD}_SUCCESS`,
    data,
})

export const addPhaseFailure = data => ({
    type: `${LIBRARY_PHASE_ADD}_FAILED`,
    data,
})

export const editPhase = data => ({
    type: `${LIBRARY_PHASE_EDIT}_PENDING`,
    data,
})

export const editPhaseSuccess = data => ({
    type: `${LIBRARY_PHASE_EDIT}_SUCCESS`,
    data,
})

export const editPhaseFailure = data => ({
    type: `${LIBRARY_PHASE_EDIT}_FAILED`,
    data,
})

export const trashPhase = data => ({
    type: `${LIBRARY_PHASE_TRASH}_PENDING`,
    data,
})

export const trashPhaseSuccess = data => ({
    type: `${LIBRARY_PHASE_TRASH}_SUCCESS`,
    data,
})

export const trashPhaseFailure = data => ({
    type: `${LIBRARY_PHASE_TRASH}_FAILED`,
    data,
})

export const reorder = data => ({
    type: `${LIBRARY_ITEM_REORDER}_PENDING`,
    data,
})

export const reorderSuccess = data => ({
    type: `${LIBRARY_ITEM_REORDER}_SUCCESS`,
    data,
})

export const reorderFailure = data => ({
    type: `${LIBRARY_ITEM_REORDER}_FAILED`,
    data,
})

export const downloadDocuments = data => ({
    type: `${LIBRARY_DOWNLOAD_DOCUMENTS}_PENDING`,
    data,
})

export const downloadDocumentsSuccess = data => ({
    type: `${LIBRARY_DOWNLOAD_DOCUMENTS}_SUCCESS`,
    data,
})

export const downloadDocumentsFailure = data => ({
    type: `${LIBRARY_DOWNLOAD_DOCUMENTS}_FAILED`,
    data,
})

export const saveDocuments = url => ({
    type: `${SAVE_LIBRARY_DOWNLOAD_DOCUMENTS}_PENDING`,
    url,
})

export const saveDocumentsSuccess = data => ({
    type: `${SAVE_LIBRARY_DOWNLOAD_DOCUMENTS}_SUCCESS`,
    data,
})

export const saveDocumentsFailure = data => ({
    type: `${SAVE_LIBRARY_DOWNLOAD_DOCUMENTS}_FAILED`,
    data,
})

export const emailDocuments = data => ({
    type: `${LIBRARY_EMAIL_DOCUMENTS}_PENDING`,
    data,
})

export const emailDocumentsSuccess = data => ({
    type: `${LIBRARY_EMAIL_DOCUMENTS}_SUCCESS`,
    data,
})

export const emailDocumentsFailure = data => ({
    type: `${LIBRARY_EMAIL_DOCUMENTS}_FAILED`,
    data,
})

export const getLibraryOwner = data => ({
    type: `${LIBRARY_OWNER_GET}_PENDING`,
    data,
})

export const getLibraryOwnerSuccess = data => ({
    type: `${LIBRARY_OWNER_GET}_SUCCESS`,
    data,
})

export const getLibraryOwnerFailure = data => ({
    type: `${LIBRARY_OWNER_GET}_FAILED`,
    data,
})

export const getOwnerLocations = () => ({
    type: `${OWNER_LOCATION_GET}_PENDING`,
})

export const getOwnerLocationsSuccess = data => ({
    type: `${OWNER_LOCATION_GET}_SUCCESS`,
    data,
})

export const getOwnerLocationsFailure = data => ({
    type: `${OWNER_LOCATION_GET}_FAILED`,
    data,
})

export const addLibraryOwner = data => ({
    type: `${LIBRARY_OWNER_ADD}_PENDING`,
    data,
})

export const addLibraryOwnerSuccess = data => ({
    type: `${LIBRARY_OWNER_ADD}_SUCCESS`,
    data,
})

export const addLibraryOwnerFailure = data => ({
    type: `${LIBRARY_OWNER_ADD}_FAILED`,
    data,
})
export const toggleListUpdatedFlag = data => ({
    type: `${TOGGLE_LIST_UPDATED_FLAG}`,
    data,
})
export const updateOwner = data => ({
    type: `${UPDATE_OWNER}_PENDING`,
    data,
})

export const updateOwnerSuccess = data => ({
    type: `${UPDATE_OWNER}_SUCCESS`,
    data,
})

export const updateOwnerFailure = data => ({
    type: `${UPDATE_OWNER}_FAILED`,
    data,
})
export const removeOwner = data => ({
    type: `${REMOVE_OWNER}_PENDING`,
    data,
})

export const removeOwnerSuccess = data => ({
    type: `${REMOVE_OWNER}_SUCCESS`,
    data,
})

export const removeOwnerFailure = data => ({
    type: `${REMOVE_OWNER}_FAILED`,
    data,
})

export const postShareDocuments = data => ({
    type: `${SHARE_DOCUMENTS_POST}_PENDING`,
    data,
})

export const postShareDocumentsSuccess = data => ({
    type: `${SHARE_DOCUMENTS_POST}_SUCCESS`,
    data,
})

export const shareDocumentsFailure = data => ({
    type: `${SHARE_DOCUMENTS_POST}_FAILED`,
    data,
})

export const copyShareDocuments = data => ({
    type: `${SHARE_DOCUMENTS_GET}_PENDING`,
    data,
})

export const copyShareDocumentsSuccess = data => ({
    type: `${SHARE_DOCUMENTS_GET}_SUCCESS`,
    data,
})

export const copyDocumentsFailure = data => ({
    type: `${SHARE_DOCUMENTS_GET}_FAILED`,
    data,
})

export const postCopyShareDocuments = data => ({
    type: `${SHARE_DOCUMENT_POST}_PENDING`,
    data,
})

export const postCopyShareDocumentsSuccess = data => ({
    type: `${SHARE_DOCUMENT_POST}_SUCCESS`,
    data,
})

export const postCopyDocumentsFailure = data => ({
    type: `${SHARE_DOCUMENT_POST}_FAILED`,
    data,
})

export const multipleShareDocs = data => {
    console.log('action call multiple share docs')
    return {
        type: `${MULTIPLE_SHARE_DOCS}_PENDING`,
        data,
    }
}

export const multipleShareDocsSuccess = data => {
    console.log('action call multiple share docs')
    return {
        type: `${MULTIPLE_SHARE_DOCS}_SUCCESS`,
        data,
    }
}

export const multipleShareDocsFailure = data => {
    console.log('action call multiple share docs')
    return {
        type: `${MULTIPLE_SHARE_DOCS}_FAILED`,
        data,
    }
}
