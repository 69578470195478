import { fromJS } from 'immutable'
import {
    DELIVER_GET,
    DELIVER_ADD_SECTION,
    DELIVER_EDIT_SECTION,
    DELIVER_TRASH_SECTION,
    DELIVER_EDIT_SUB_SECTION,
    DELIVER_ADD_SUB_SECTION,
    DELIVER_FETCH_ITEM,
    DELIVER_DELETE_SUB_SECTION,
    DELIVER_ITEM_REORDER,
    DELIVER_DOWNLOAD_BROCHURE,
} from './constant'

const INITIAL_STATE = fromJS({
    status: null,
    isLoading: false,
    error: null,
    selectAddedData: null,
    selectEditData: null,
    selectfetchData: null,
    data: null,
    selectDeleteData: null,
})

function deliverReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case `${DELIVER_GET}_PENDING`:
            return state
                .set('status', null)
                .set('isLoading', true)
                .set('data', true)
        // .set('error', null)
        case `${DELIVER_GET}_SUCCESS`:
            return state
                .set('status', action.data.responseData.status)
                .set('isLoading', false)
                .set('data', action.data.responseData)
        // .set('error', null)
        case `${DELIVER_GET}_FAILED`:
            return state
                .set('status', action.data.responseData.status)
                .set('isLoading', false)
                .set('data', action.data.responseData)
        // .set('error', action.data.responseData.result.message)

        case `${DELIVER_ADD_SECTION}_PENDING`:
            return state.set('status', null).set('isLoading', true)
        // .set('error', null)
        case `${DELIVER_ADD_SECTION}_SUCCESS`: {
            const { reqData, responseData } = action.data
            const data = state.get('data')
            const deliverId = reqData.deliveryId
            const topicName = reqData.name
            const deliverData = data.result.data.filter(sectionObj => sectionObj.id === deliverId)
            deliverData[0].list.push({
                deliverId,
                name: topicName,
                id: responseData.result.id,
                list: [],
            })
            return state
                .set('isLoading', false)
                .set('status', responseData.status)
                .set('data', data)
            // .set('error', null)
        }
        case `${DELIVER_ADD_SECTION}_FAILED`:
            return state
                .set('status', action.data.responseData.status)
                .set('isLoading', false)
                .set('error', action.data.responseData.result.message)

        case `${DELIVER_EDIT_SECTION}_PENDING`:
            return state.set('status', null).set('isLoading', true)
        // .set('error', null)
        case `${DELIVER_EDIT_SECTION}_SUCCESS`: {
            const { reqData, responseData } = action.data
            const data = state.get('data')
            const deliverId = reqData.deliveryId
            const topicName = reqData.name
            const topicId = reqData.id
            const deliverData = data.result.data.filter(sectionObj => sectionObj.id === deliverId)
            const topicToEditInd = deliverData[0].list.findIndex(topic => topic.id === topicId)
            deliverData[0].list[topicToEditInd].name = topicName
            return state
                .set('status', responseData.status)
                .set('isLoading', false)
                .set('data', data)
            // .set('error', null)
        }
        case `${DELIVER_EDIT_SECTION}_FAILED`:
            return state
                .set('status', action.data.responseData.status)
                .set('isLoading', false)
                .set('error', action.data.responseData.result.message)
        case `${DELIVER_TRASH_SECTION}_PENDING`:
            return state.set('status', null).set('isLoading', true)
        // .set('error', null)
        case `${DELIVER_TRASH_SECTION}_SUCCESS`: {
            const { reqData, responseData } = action.data
            const data = state.get('data')
            const deliverId = reqData.deliveryId
            const topicId = reqData.id
            const deliverData = data.result.data.filter(sectionObj => sectionObj.id === deliverId)
            const topicToTrashInd = deliverData[0].list.findIndex(topic => topic.id === topicId)
            deliverData[0].list.splice(topicToTrashInd, 1)
            return state
                .set('status', responseData.status)
                .set('isLoading', false)
                .set('data', data)
            // .set('error', null)
        }

        case `${DELIVER_TRASH_SECTION}_FAILED`:
            return state
                .set('status', action.data.responseData.status)
                .set('isLoading', false)
                .set('error', action.data.responseData.result.message)
        case `${DELIVER_ADD_SUB_SECTION}_PENDING`:
            return (
                state
                    .set('status', null)
                    // .set('error', null)
                    .set('isLoading', true)
                    .set('selectAddedData', null)
            )
        case `${DELIVER_ADD_SUB_SECTION}_SUCCESS`:
            return state
                .set('status', action.data.responseData)
                .set('isLoading', false)
                .set('selectAddedData', action.data.responseData)
        case `${DELIVER_ADD_SUB_SECTION}_FAILED`:
            return state
                .set('error', action.data.responseData)
                .set('isLoading', false)
                .set('selectAddedData', action.data.responseData)
        case `${DELIVER_EDIT_SUB_SECTION}_PENDING`:
            return (
                state
                    .set('status', null)
                    // .set('error', null)
                    .set('isLoading', true)
                    .set('selectEditData', null)
            )
        case `${DELIVER_EDIT_SUB_SECTION}_SUCCESS`:
            return state
                .set('status', action.data.responseData)
                .set('isLoading', false)
                .set('selectEditData', action.data.responseData)
        case `${DELIVER_EDIT_SUB_SECTION}_FAILED`:
            return state
                .set('error', action.data.responseData)
                .set('isLoading', false)
                .set('selectEditData', action.data.responseData)

        case `${DELIVER_DELETE_SUB_SECTION}_PENDING`:
            return (
                state
                    .set('status', null)
                    // .set('error', null)
                    .set('isLoading', true)
                    .set('selectDeleteData', null)
            )
        case `${DELIVER_DELETE_SUB_SECTION}_SUCCESS`:
            return state
                .set('status', action.data.responseData)
                .set('isLoading', false)
                .set('selectDeleteData', action.data.responseData)
        case `${DELIVER_DELETE_SUB_SECTION}_FAILED`:
            return state
                .set('error', action.data.responseData)
                .set('isLoading', false)
                .set('selectDeleteData', action.data.responseData)

        case `${DELIVER_FETCH_ITEM}_PENDING`:
            return (
                state
                    .set('status', null)
                    .set('isLoading', true)
                    // .set('error', null)
                    .set('selectfetchData', null)
            )
        case `${DELIVER_FETCH_ITEM}_SUCCESS`:
            return state
                .set('status', action.data.responseData.status)
                .set('isLoading', false)
                .set('selectfetchData', action.data.responseData.result)
        case `${DELIVER_FETCH_ITEM}_FAILED`:
            return state
                .set('status', action.data.responseData.status)
                .set('isLoading', false)
                .set('selectfetchData', action.data.responseData.result)
        case `${DELIVER_ITEM_REORDER}_PENDING`:
            return state.set('isLoading', true).set('status', null)
        case `${DELIVER_ITEM_REORDER}_SUCCESS`: {
            const stateData = state.get('data')
            const { data } = stateData.result
            const { reqData } = action.data
            const { libraryId, list, sectionId } = reqData
            const sectionData = data.filter(obj => obj.id === sectionId)
            const subSectionData = sectionData[0].list.filter(obj => obj.id === libraryId)
            subSectionData[0].list = list
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('data', stateData)
        }
        case `${DELIVER_ITEM_REORDER}_FAILED`:
            return state.set('isLoading', false).set('status', null)

        case `${DELIVER_DOWNLOAD_BROCHURE}_PENDING`:
            return state
                .set('status', null)
                .set('isLoading', true)
                .set('downloadBrochureData', null)
        case `${DELIVER_DOWNLOAD_BROCHURE}_SUCCESS`: {
            return state
                .set('status', action.data.responseData.status)
                .set('isLoading', false)
                .set('downloadBrochureData', action.data.responseData)
        }
        case `${DELIVER_DOWNLOAD_BROCHURE}_FAILED`:
            return state
                .set('status', null)
                .set('isLoading', false)
                .set('downloadBrochureData', null)
        default:
            return state
    }
}

export default deliverReducer
