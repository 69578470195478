import React, { Component, Fragment } from 'react'
import { withAlert } from 'react-alert'
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap'
import c from 'classnames'
import Calendar from 'react-calendar'
import moment from 'moment'
import Autocomplete from 'react-autocomplete'
import ModuleHeader from '../ModuleHeader'
import { USER_INITAL_STATE } from '../../Containers/Admin/constant'
import Inputbox from '../Inputbox'
import DropdownCustom from '../Dropdown'
import Tags from '../Tags'
import { getPrefixedUrl } from '../../Utils/apis'
import './admin.css'
import { BackIcon, UploadIcon } from '../../Utils/svg'
import { isValidPattern, getUniqueElements } from '../../Utils/functions'
import ThumbnailEditorModal from '../ThumbnailEditorModal'

import {
    SITE_TXT,
    NAME_PATTERN,
    EMAIL_PATTERN,
    MAX_TAGS_ALLOWED_DESTINATION,
    MAX_TAGS_ALLOWED_EXPERT,
    IMAGE_CROP_USER_PROFILE,
    STATUS_SUCCESS,
    userInfo,
    userAuthorization,
    COMMA_KEY_CODE,
} from '../../Utils/constants'

class CreateUser extends Component {
    state = {
        spinnerState: false,
        user: JSON.parse(JSON.stringify(USER_INITAL_STATE)),
        options: [],
        value: [],
        lastTagTyped: '',
        lastDesTagTyped: '',
        userType: [],
        created: false,
        createUserData: this.props.createUserData,
        ticketCreated: false,
        userDetails: '',
        secondlastParam: '',
        userdetails: this.props.userData,
        DesTagArray: [],
        setExpVal: false,
        setDesVal: false,
        setNameVal: false,
        userName: '',
        setTypeVal: false,
        usertype: 0,
        setEmailVal: false,
        isModalOpen: false,
        emailId: '',
        parentEmail: '',
        userImg1Src: null,
        imageId: null,
        profileUpdated: false,
        showCalendar: false,
    }

    showCalendar = () => {
        const { showCalendar } = this.state
        this.setState({ showCalendar: !showCalendar })
    }

    handleParentEmail = parentEmail => {
        this.setState({
            parentEmail,
            user: { ...this.state.user, parentEmail },
        })
        clearTimeout(this.delayTimer)
        this.delayTimer = setTimeout(() => {
            this.props.getUserEmail({
                pageSize: 1000,
                pageNumber: 1,
                search: parentEmail,
            })
        }, 200)
    }

    updateParentEmail = parentEmail => {
        this.setState({
            parentEmail,
            user: { ...this.state.user, parentEmail },
        })
    }

    handleChange = (field, value) => {
        this.setState({
            user: { ...this.state.user, [field]: value },
            showCalendar: false,
        })
    }

    handleDrop = files => {
        this.setState({
            user: { ...this.state.user, userFile: files[0] },
        })
    }
    openUploadModal = field => {
        this.setState({
            isModalOpen: true,
            imageId: field,
        })
    }

    closeUploadModal = () => {
        this.setState({
            isModalOpen: false,
        })
    }

    getSrcFunction = reader => {
        if (this.state.imageId === 'image1') {
            this.setState({
                userImg1Src: reader.result,
            })
        }
    }

    getBase64 = file => {
        let reader = new FileReader()
        reader.addEventListener(
            'load',
            () => {
                this.getSrcFunction(reader)
            },
            false
        )
        if (file) {
            reader.readAsDataURL(file)
        }
    }

    handleSave = (thumbnailId, formTosave, imageTosave) => {
        if (this.state.imageId === 'image1') {
            this.setState({
                user: { ...this.state.user, userFile: imageTosave },
            })
            this.getBase64(imageTosave)
        }
        this.closeUploadModal()
    }

    componentDidMount = () => {
        const { TXT_UPDATE_PROFILE_CHECK, TXT_EDIT_CHECK } = SITE_TXT
        let currentLocation = window.location.href
        let userId = currentLocation.slice(currentLocation.lastIndexOf('/') + 1)
        let secondlastParam = currentLocation.split('/').slice(-2)[0]
        if (secondlastParam !== TXT_UPDATE_PROFILE_CHECK) {
            this.props.fetchUserType()
        }

        this.setState(
            {
                secondlastParam: secondlastParam,
                user: {
                    ...this.state.user,
                    userId: userId,
                },
            },
            () => {
                if (this.state.secondlastParam === TXT_EDIT_CHECK) {
                    this.props.fetchUserData(userId)
                } else if (this.state.secondlastParam === TXT_UPDATE_PROFILE_CHECK) {
                    this.props.getUserProfile()
                }
            }
        )
    }

    inputChangeHandler = (data, input) => {
        let inp = input.trim()
        if (inp === '') {
            return
        }
        if (data === 'expert') {
            clearTimeout(this.delayTimer)
            this.delayTimer = setTimeout(() => {
                this.setState({ lastTagTyped: inp })
                this.props.fetchExpertTags(inp)
            }, 1000)
        } else if (data === 'des') {
            clearTimeout(this.delayTimer)
            this.delayTimer = setTimeout(() => {
                this.setState({ lastDesTagTyped: inp })
                this.props.fetchDesignationTags(inp)
            }, 1000)
        }
    }

    onReactSelectChange = value => {
        if (value.length > MAX_TAGS_ALLOWED_EXPERT) {
            alert(SITE_TXT.TXT_MAX_TAGS_ALLOWED_EXPERT)
            value.pop()
            this.expertSelect.select.setState({ selectValue: value })
            return
        }

        this.setState({
            user: { ...this.state.user, expertTags: value, lastTagTyped: '' },
        })
    }

    keyDownHandler = (data, e) => {
        if (e.which === COMMA_KEY_CODE) {
            this.addTypedTag(data, e.target.value)
            e.preventDefault()
        }
    }

    onAddBtnClick = (data, e) => {
        e.preventDefault()
        if (data === 'expert') {
            if (this.state.lastTagTyped !== '') {
                this.addTypedTag(data, this.state.lastTagTyped)
            }
        }
        if (data === 'des') {
            if (this.state.lastDesTagTyped !== '') {
                this.addTypedTag(data, this.state.lastDesTagTyped)
            }
        }
    }

    addTypedTag = (data, val) => {
        if (val.trim() === '') {
            return
        }
        if (data === 'expert') {
            if (!this.expertSelect.select.state.selectValue) this.expertSelect.select.state.selectValue = []
            this.expertSelect.select.setState(
                {
                    selectValue: [...this.expertSelect.select.state.selectValue, { label: val, value: val }],
                },
                () => {
                    this.setState({ lastTagTyped: '' })
                    this.expertSelect.blur()
                    this.expertSelect.focus()
                    this.expertSelect.onChange(this.expertSelect.select.state.selectValue)
                }
            )
        } else if (data === 'des') {
            if (!this.DesSelect.select.state.selectValue) this.DesSelect.select.state.selectValue = []
            this.DesSelect.select.setState(
                {
                    selectValue: [...this.DesSelect.select.state.selectValue, { label: val, value: val }],
                },
                () => {
                    this.setState({ lastDesTagTyped: '' })
                    this.DesSelect.blur()
                    this.DesSelect.focus()
                    this.DesSelect.onChange(this.DesSelect.select.state.selectValue)
                }
            )
        }
    }

    onReactDesSelectChange = value => {
        if (value.length > MAX_TAGS_ALLOWED_DESTINATION) {
            alert(SITE_TXT.TXT_MAX_TAGS_ALLOWED_DESTINATION)
            value.pop()
            this.DesSelect.select.setState({ selectValue: value })
            return
        }

        this.setState({ user: { ...this.state.user, designationTags: value } })
    }

    validateFormHere = () => {
        const { alert } = this.props
        const { error } = alert
        const { user } = this.state
        const { name, userType, email, parentEmail } = user
        const {
            TXT_USERNAME_MANDATORY,
            TXT_USERNAME_INVALID_PATTERN,
            TXT_SELECT_USER_TYPE,
            TXT_USERTYPE_MANDATORY,
            TXT_EMAILID_MANDATORY,
            TXT_EMAILID_INVALID_PATTERN,
            TXT_EXTERNAL_TYPE,
        } = SITE_TXT
        if (name.trim() === '') {
            error(TXT_USERNAME_MANDATORY)
            return false
        } else if (!isValidPattern(name, NAME_PATTERN)) {
            error(TXT_USERNAME_INVALID_PATTERN)
            return false
        } else if (userType === '' || userType === TXT_SELECT_USER_TYPE) {
            error(TXT_USERTYPE_MANDATORY)
            return false
        } else if (email === '') {
            error(TXT_EMAILID_MANDATORY)
            return false
        } else if (!isValidPattern(email, EMAIL_PATTERN)) {
            error(TXT_EMAILID_INVALID_PATTERN)
            return false
        } else if (userType === TXT_EXTERNAL_TYPE && !isValidPattern(parentEmail, EMAIL_PATTERN)) {
            error(TXT_EMAILID_INVALID_PATTERN)
            return false
        }
        return true
    }

    handleCreateUser(e) {
        e.preventDefault()
        if (this.validateFormHere()) {
            const { TXT_EDIT_CHECK, TXT_UPDATE_PROFILE_CHECK } = SITE_TXT
            const data = this.createFormData()
            this.setState({ userDetails: data })
            if (this.state.secondlastParam === TXT_EDIT_CHECK) {
                this.props.editUserData(data)
                this.setState({ updated: true })
            } else if (this.state.secondlastParam === TXT_UPDATE_PROFILE_CHECK) {
                this.props.setUserProfile(data)
                this.setState({ profileUpdated: true })
            } else {
                this.props.addUserBackend(data)
                this.setState({ created: true })
            }
        }
    }

    getUniqueTags = tags => {
        return getUniqueElements(tags.split(',')).toString()
    }

    createFormData() {
        let data = new FormData()
        let expTagsStr = ''
        let desTagsStr = ''
        const {
            name,
            userFile,
            email,
            userType,
            expertTags,
            designationTags,
            userId,
            loginValidity,
            parentEmail,
        } = this.state.user
        const { TXT_UPDATE_PROFILE_CHECK, TXT_EDIT_CHECK, TXT_CST_TYPE } = SITE_TXT
        let expL = expertTags.length
        for (let index = 0; index < expL; index++) {
            expTagsStr += expertTags[index].value + ','
        }
        if (expTagsStr.trim().length > 0) {
            expTagsStr = expTagsStr.substring(0, expTagsStr.length - 1)
        }
        let destL = designationTags.length
        for (let index = 0; index < destL; index++) {
            desTagsStr += designationTags[index].value + ','
        }
        if (desTagsStr.trim().length > 0) {
            desTagsStr = desTagsStr.substring(0, desTagsStr.length - 1)
        }
        if (this.state.secondlastParam === TXT_UPDATE_PROFILE_CHECK) {
            data.append('UserName', name)
            data.append('Expertise', this.state.user.userType !== TXT_CST_TYPE ? this.getUniqueTags(expTagsStr) : '')
            data.append('Designation', desTagsStr)
            data.append('userImage', userFile)
        } else {
            data.append('UserName', name)
            data.append('Expertise', this.state.user.userType !== TXT_CST_TYPE ? this.getUniqueTags(expTagsStr) : '')
            data.append('Designation', desTagsStr)
            data.append('LoginId', email)
            data.append('UserTypeId', userType)
            data.append('userImage', userFile)
            data.append('loginValidity', loginValidity)
            data.append('parentEmail', parentEmail)
            if (this.state.secondlastParam === TXT_EDIT_CHECK) {
                data.append('UserId', userId)
            }
        }
        return data
    }

    UNSAFE_componentWillReceiveProps = nextProps => {
        const { userTypeData, createUserData, status, userData, isEditUserData, setUserProfileSuccess } = nextProps
        this.setState(
            {
                userType: userTypeData || [],
                createUserData: createUserData,
            },
            () => {
                if (this.state.created === true) {
                    if (createUserData && status === 'successfulCreation') {
                        this.setState({ created: false }, () => {
                            let verificationEmailRequest = {
                                data: {
                                    user_id: createUserData.user_id,
                                },
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                                },
                            }

                            this.props.verificationEmail(verificationEmailRequest)
                            let changePasswordRequest = {
                                email: createUserData.email,
                                newPassword: 'secret@123',
                            }
                            this.props.authO.changePassword(changePasswordRequest, 'set')
                        })
                    }
                }
            }
        )

        if (
            this.state.secondlastParam === SITE_TXT.TXT_EDIT_CHECK ||
            this.state.secondlastParam === SITE_TXT.TXT_UPDATE_PROFILE_CHECK
        ) {
            let userUpdatedData = userData ? userData.data[0] : []
            this.setState({
                userdetails: userData,
            })
            if (this.state.updated === true) {
                if (isEditUserData && isEditUserData.status === STATUS_SUCCESS) {
                    this.setState({ updated: false }, () => {
                        this.historyBack()
                    })
                }
            }
            if (this.state.profileUpdated === true) {
                if (setUserProfileSuccess && setUserProfileSuccess.status === STATUS_SUCCESS) {
                    this.setState({ profileUpdated: false }, () => {
                        if (userAuthorization && userAuthorization['user_metadata']) {
                            userAuthorization['user_metadata']['name'] = this.state.user.name

                            if (setUserProfileSuccess.result.image) {
                                userAuthorization['user_metadata']['image'] = setUserProfileSuccess.result.image
                            }
                        }

                        localStorage.setItem('userInfo', JSON.stringify(userInfo))
                        this.historyBack()
                    })
                }
            }

            if (userData && this.state.setNameVal === false) {
                let expertTags = []
                let designationTags = []
                if (userUpdatedData.Expertise !== '') {
                    expertTags = userUpdatedData.Expertise.split(',').map(val => ({
                        label: val,
                        value: val,
                    }))
                    this.expertSelect && this.expertSelect.select.setValue(expertTags)
                }
                if (userUpdatedData.Designation !== '') {
                    designationTags = userUpdatedData.Designation.split(',').map(val => ({
                        label: val,
                        value: val,
                    }))
                    this.DesSelect && this.DesSelect.select.setValue(designationTags)
                }
                const user = {
                    ...this.state.user,
                    name: userUpdatedData.UserName,
                    email: userUpdatedData.EmailId,
                    userType: userUpdatedData.UserType,
                    userFile: userUpdatedData.UserImage,
                    parentEmail: userUpdatedData.parentEmail,
                    loginValidity: userUpdatedData.loginValidity,
                    designationTags,
                    expertTags,
                }
                this.setState({
                    user,
                    setNameVal: true,
                    parentEmail: userUpdatedData.parentEmail,
                })
            }
        }
    }
    historyBack() {
        window.history.back()
    }
    render() {
        const {
            user,
            userdetails,
            isModalOpen,
            userImg1Src,
            secondlastParam,
            userType,
            showCalendar,
            parentEmail,
        } = this.state
        let optionsExpert = this.props.data ? this.props.data.tags : []
        let optionsDesignation = this.props.designationData ? this.props.designationData.tags : []

        const {
            TXT_NAME,
            TXT_NAME_PLACEHOLDER,
            TXT_EXPERTISE,
            TXT_ROLE,
            TXT_CREATE_USER_ADMIN,
            TXT_ADD,
            TXT_USER_TYPE,
            TXT_SELECT_USER_TYPE,
            TXT_EMAILID_PLACEHOLDER,
            TXT_CREATE_ADMIN_AND_USERS,
            TXT_EDIT_CHECK,
            TXT_USER_IMAGE,
            TXT_UPDATE_USER_ADMIN,
            TXT_UPDATE_ADMIN_AND_USERS,
            TXT_BROWSE,
            TXT_YOUR_PROFILE,
            TXT_UPDATE_USER_PROFILE,
            TXT_UPDATE_PROFILE_CHECK,
            TXT_EMAILID,
            TXT_CST_TYPE,
            TXT_EXTERNAL_TYPE,
            TXT_REF_EMAIL,
        } = SITE_TXT
        const fullWidth = user && user.userType === TXT_EXTERNAL_TYPE

        const userOptions = fullWidth ? userType : userType.filter(({ key }) => key !== TXT_EXTERNAL_TYPE)

        return (
            <div className="uploadDoc-container">
                <span onClick={() => this.historyBack()} className="getBack">
                    <BackIcon />
                </span>
                <ModuleHeader
                    heading={
                        secondlastParam === TXT_EDIT_CHECK
                            ? TXT_UPDATE_ADMIN_AND_USERS
                            : secondlastParam === TXT_UPDATE_PROFILE_CHECK
                            ? TXT_YOUR_PROFILE
                            : TXT_CREATE_ADMIN_AND_USERS
                    }
                />
                <section className="createuser-ctrl">
                    <form
                        id="createForm"
                        className="form-horizontal fullFormWidth"
                        encType="multipart/form-data"
                        method="post"
                    >
                        <div className={c('firstDivCreateUser', { fullWidth })}>
                            <Inputbox
                                label={TXT_NAME}
                                placeholder={TXT_NAME_PLACEHOLDER}
                                type="text"
                                fieldFor="name"
                                onChange={(field, value) => this.handleChange(field, value)}
                                customClass="text"
                                autoFocus={true}
                                value={user.name}
                                disabled={user && user.userType === TXT_EXTERNAL_TYPE}
                            />
                            {secondlastParam === TXT_UPDATE_PROFILE_CHECK ? null : (
                                <DropdownCustom
                                    label={TXT_USER_TYPE}
                                    defaultVal={
                                        secondlastParam === TXT_EDIT_CHECK ? TXT_EDIT_CHECK : TXT_SELECT_USER_TYPE
                                    }
                                    value={userdetails ? user.userType : 0}
                                    fieldFor="userType"
                                    onChange={(field, value) => this.handleChange(field, value)}
                                    customClass="select-validation"
                                    selectedMenu={user.userType}
                                    options={userOptions}
                                    disabled={user && user.userType === TXT_EXTERNAL_TYPE}
                                />
                            )}
                            {user.userType !== TXT_EXTERNAL_TYPE && (
                                <Inputbox
                                    readonly={
                                        secondlastParam === TXT_EDIT_CHECK ||
                                        secondlastParam === SITE_TXT.TXT_UPDATE_PROFILE_CHECK
                                    }
                                    label={TXT_EMAILID}
                                    placeholder={TXT_EMAILID_PLACEHOLDER}
                                    type="email"
                                    fieldFor="email"
                                    onChange={(field, value) => this.handleChange(field, value)}
                                    customClass="email"
                                    value={user.email}
                                />
                            )}
                            {user && user.userType === TXT_EXTERNAL_TYPE && (
                                <Fragment>
                                    <div className="parentEmail form-group">
                                        <label>{TXT_REF_EMAIL}</label>
                                        <Autocomplete
                                            getItemValue={item => item}
                                            items={this.props.userEmails}
                                            renderItem={item => <div key={item}>{item}</div>}
                                            value={parentEmail}
                                            onChange={e => this.handleParentEmail(e.target.value)}
                                            onSelect={parentEmail => this.updateParentEmail(parentEmail)}
                                        />
                                    </div>

                                    <div className="calendar-container form-group">
                                        <label>Expiry Date</label>
                                        <Dropdown isOpen={showCalendar} toggle={this.showCalendar}>
                                            <DropdownToggle>
                                                {user.loginValidity}
                                                <img src={SITE_TXT.CALENDAR_IMG} alt="" />
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <Calendar
                                                    onChange={x =>
                                                        this.handleChange(
                                                            'loginValidity',
                                                            moment(x).format('MM/DD/YYYY')
                                                        )
                                                    }
                                                    value={new Date(user.loginValidity)}
                                                    minDate={moment().toDate()}
                                                />
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </Fragment>
                            )}

                            <label>{TXT_USER_IMAGE}</label>

                            <div id="uploadFileDivForUser" form="uploadDocForm">
                                <div onClick={() => this.openUploadModal('image1')} className="dropzone">
                                    {user.userFile ? (
                                        <img
                                            alt="img"
                                            src={userImg1Src || getPrefixedUrl(`${user.userFile}`)}
                                            className="user-images"
                                        />
                                    ) : (
                                        <div className="browse-icon">
                                            <UploadIcon width="38" height="34" /> <br />
                                            {TXT_BROWSE}
                                        </div>
                                    )}
                                </div>
                            </div>
                            {user && user.userType === TXT_EXTERNAL_TYPE && (
                                <div className="btnuser">
                                    <button
                                        type="submit"
                                        className="btn btn-primary createUserBtn"
                                        onClick={e => this.handleCreateUser(e)}
                                    >
                                        {TXT_UPDATE_USER_ADMIN}
                                    </button>
                                </div>
                            )}
                        </div>
                        {user && user.userType !== TXT_EXTERNAL_TYPE && (
                            <div className="secDivCreateUser">
                                <div id="uploadFileSecondDiv">
                                    <div>
                                        <label>{TXT_EXPERTISE}</label>
                                        <div className="addTag form-group">
                                            {user.userType === TXT_CST_TYPE ? (
                                                <input
                                                    type="text"
                                                    disabled
                                                    value="Enter Tags here"
                                                    className="cst-input"
                                                />
                                            ) : (
                                                <Tags
                                                    usertype={user.userType}
                                                    label={TXT_EXPERTISE}
                                                    options={optionsExpert}
                                                    onInputChange={input => this.inputChangeHandler('expert', input)}
                                                    onChange={value => this.onReactSelectChange(value)}
                                                    onKeyDown={e => this.keyDownHandler('expert', e)}
                                                    refValue={input => (this.expertSelect = input)}
                                                />
                                            )}
                                            <button
                                                disabled={user.userType === TXT_CST_TYPE}
                                                className="addTagBtnUser"
                                                onClick={e => this.onAddBtnClick('expert', e)}
                                            >
                                                +{TXT_ADD}
                                            </button>
                                        </div>
                                    </div>

                                    <label className="txt-upper">{TXT_ROLE}</label>
                                    <div className="addTag form-group">
                                        <Tags
                                            isMulti={false}
                                            label={TXT_ROLE}
                                            options={optionsDesignation}
                                            onInputChange={input => this.inputChangeHandler('des', input)}
                                            onChange={value => this.onReactDesSelectChange(value)}
                                            onKeyDown={e => this.keyDownHandler('des', e)}
                                            refValue={input => (this.DesSelect = input)}
                                        />
                                        <button className="addTagBtnUser" onClick={e => this.onAddBtnClick('des', e)}>
                                            +{TXT_ADD}
                                        </button>
                                    </div>
                                </div>
                                <div className="btnuser">
                                    <button
                                        type="submit"
                                        className="btn btn-primary createUserBtn"
                                        onClick={e => this.handleCreateUser(e)}
                                    >
                                        {secondlastParam === TXT_EDIT_CHECK
                                            ? TXT_UPDATE_USER_ADMIN
                                            : secondlastParam === TXT_UPDATE_PROFILE_CHECK
                                            ? TXT_UPDATE_USER_PROFILE
                                            : TXT_CREATE_USER_ADMIN}
                                    </button>
                                </div>
                            </div>
                        )}
                        <div>
                            {isModalOpen && (
                                <ThumbnailEditorModal
                                    isAdminPage={true}
                                    isModalOpen={isModalOpen}
                                    onlyImage={true}
                                    thumbnailImg={user.userFile}
                                    userImg1Src={userImg1Src}
                                    editCancel={() => this.closeUploadModal()}
                                    handleSave={(id, form, test) => this.handleSave(id, form, test)}
                                    imgCropObj={IMAGE_CROP_USER_PROFILE}
                                    modalClass="create-user-modal"
                                />
                            )}
                        </div>
                    </form>
                </section>
            </div>
        )
    }
}

export default withAlert(CreateUser)
