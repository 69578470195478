import { fromJS } from 'immutable'
import { LEARN_GET } from './constant'

const INITIAL_STATE = fromJS({
    status: null,
    isLoading: true,
    error: null,
})

function learnReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case `${LEARN_GET}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)
        case `${LEARN_GET}_SUCCESS`:
            return state.set('status', action.data.responseData).set('isLoading', false)
        case `${LEARN_GET}_FAILED`:
            return state.set('error', action.data.responseData).set('isLoading', false)
        default:
            return state
    }
}

export default learnReducer
