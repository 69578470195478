export const FETCH_MY_LOP = 'FETCH_MY_LOP'
export const FETCH_MY_LOP_DETAILS = 'FETCH_MY_LOP_DETAILS'
export const DELETE_LOP = 'DELETE_LOP'
export const SAVE_MY_LOP_DETAILS = 'SAVE_MY_LOP_DETAILS'
export const UPDATE_LOP_SECTION = 'UPDATE_LOP_SECTION'
export const UPDATE_LOP_SECTION_DETAILS = 'UPDATE_LOP_SECTION_DETAILS'
export const FETCH_LOP_ALBUM = 'FETCH_LOP_ALBUM'
export const EDIT_SECTION_IMAGE = 'EDIT_SECTION_IMAGE'
export const ADD_LOP_SECTION = 'ADD_LOP_SECTION'
export const TOGGLE_LOP_SECTION = 'TOGGLE_LOP_SECTION'
export const ADD_PARTNER = 'ADD_PARTNER'
export const EDIT_PARTNER = 'EDIT_PARTNER'
export const DELETE_PARTNER = 'DELETE_PARTNER'
export const FETCH_CURRENT_TEMPLATE_DETAILS = 'FETCH_CURRENT_TEMPLATE_DETAILS'
export const FETCH_ADDITIONAL_DETAILS = 'FETCH_ADDITIONAL_DETAILS'
export const SET_SORTED_ARRAY = 'SET_SORTED_ARRAY'
export const UPDATE_LOP_TEMPLATE2_DETAILS = 'UPDATE_LOP_TEMPLATE2_DETAILS'
export const DELETE_SECTION_IN_TEMPLATE2 = 'DELETE_SECTION_IN_TEMPLATE2'
export const SORT_IN_TEMPLATE2 = 'SORT_IN_TEMPLATE2'
export const ADD_TEMPLATE2_DETAILS = 'ADD_TEMPLATE2_DETAILS'
export const SET_ORDER_ITEM = 'SET_ORDER_ITEM'
export const ADD_TEMPLATE4_DETAILS = 'ADD_TEMPLATE4_DETAILS'
export const UPDATE_LOP_TEMPLATE4_DETAILS = 'UPDATE_LOP_TEMPLATE4_DETAILS'
export const DELETE_SECTION_IN_TEMPLATE4 = 'DELETE_SECTION_IN_TEMPLATE4'
export const RESTORE_SECTION_IN_TEMPLATE4 = 'RESTORE_SECTION_IN_TEMPLATE4'
export const SORT_IN_TEMPLATE4 = 'SORT_IN_TEMPLATE4'
export const ADD_CUSTOM_CONTENT = 'ADD_CUSTOM_CONTENT'
export const EDIT_CUSTOM_CONTENT = 'EDIT_CUSTOM_CONTENT'
export const EDIT_COLLAGE_CONTENT = 'EDIT_COLLAGE_CONTENT'
export const EDIT_FILE_CONTENT = 'EDIT_FILE_CONTENT'
export const ADD_DUMMY_LOADER = 'ADD_DUMMY_LOADER'
export const ADD_ARTICLE = 'ADD_ARTICLE'
export const EDIT_ARTICLE = 'EDIT_ARTICLE'
export const UPDATE_ARTICLE_IN_SEPERATE_SECTION = 'UPDATE_ARTICLE_IN_SEPERATE_SECTION'
export const DELETE_ARTICLE = 'DELETE_ARTICLE'
export const DOWNLOAD_LOP_DOCS = 'DOWNLOAD_LOP_DOCS'
export const PREVIEW_LOP_DOCS = 'PREVIEW_LOP_DOCS'
export const CLEAR_PREVIEWED_DOC = 'CLEAR_PREVIEWED_DOC'
export const GET_COLLABORATOR_LIST = 'GET_COLLABORATOR_LIST'
export const SEARCH_COLLABORATOR = 'SEARCH_COLLABORATOR'
export const CLEAR_SEARCH_COLLABORATOR = 'CLEAR_SEARCH_COLLABORATOR'
export const EDIT_COLLABORATORS = 'EDIT_COLLABORATORS'
export const ADD_COLLABORATORS = 'ADD_COLLABORATORS'
export const UPDATE_COLLABORATORS = 'UPDATE_COLLABORATORS'
export const DELETE_COLLABORATORS = 'DELETE_COLLABORATORS'
export const DELETE_SECTION = 'DELETE_SECTION'
export const SORT_SECTIONS = 'SORT_SECTIONS'
export const CONTACT_WIN_ROOM = 'CONTACT_WIN_ROOM'
export const LOCK_MY_LOP = 'LOCK_MY_LOP'
export const PUBLISH_LINK_FOR_CLIENT = 'PUBLISH_LINK_FOR_CLIENT'
export const CONTACT_WIN_ROOM_MAIL = 'CONTACT_WIN_ROOM_MAIL'
export const ADD_EXTERNAL_USER = 'ADD_EXTERNAL_USER'
export const RESET_TO_ORIGINAL = 'RESET_TO_ORIGINAL'
export const UPDATE_BLOCK = 'UPDATE_BLOCK'
export const ADD_BLOCK = 'ADD_BLOCK'
export const DELETE_BLOCK = 'DELETE_BLOCK'
export const UPDATE_BLOCK_HEADER = 'UPDATE_BLOCK_HEADER'
export const RESET_LIST_UPDATED = 'RESET_LIST_UPDATED'
export const ADD_ARTICLE_FROM_LIST = 'ADD_ARTICLE_FROM_LIST'
export const SET_ELEMENT_TO_SCROLL = 'SET_ELEMENT_TO_SCROLL'
export const COPY_LOP_SECTION = 'COPY_LOP_SECTION'
export const MOVE_LOP_SECTION = 'MOVE_LOP_SECTION'
export const RESTORE_SECTION = 'RESTORE_SECTION'
export const EDIT_MULTIPLE_VIDEOS = 'EDIT_MULTIPLE_VIDEOS'
export const ADD_MULTIPLE_VIEO_DATA = 'ADD_MULTIPLE_VIEO_DATA'
export const UPDATE_MULTIPLE_VIDEO = 'UPDATE_MULTIPLE_VIDEO'
export const LOP_WELCOME = 'LOP_WELCOME'
