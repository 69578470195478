import React, { Component } from 'react'
import { getPrefixedUrl } from '../../Utils/apis'
import './index.scss'
import { PencilIcon } from '../../Utils/svg'

export default class PersonBox extends Component {
    openEditModal = () => {
        const data = {
            id: this.props.id,
            name: this.props.username,
            location: this.props.userlocation,
            image1: this.props.userimage,
            person: this.props.person,
        }
        this.props.editPersonDetils(data)
    }
    render() {
        const { editExpert } = this.props
        return (
            <div className="person-box">
                <div className="person-image">
                    <img src={getPrefixedUrl(this.props.userimage)} alt={this.props.userimage} />
                </div>
                <div className="profile">
                    <div className="v-center">
                        <div className="person-name">{this.props.username}</div>
                        <div className="person-location">{this.props.location}</div>
                    </div>
                </div>
                {editExpert && (
                    <div className="edit-btn" onClick={this.openEditModal}>
                        <PencilIcon height="14" />
                    </div>
                )}
            </div>
        )
    }
}
