import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-responsive-modal'

import Inputbox from '../../../../Inputbox'
import './index.scss'
import { SITE_TXT } from '../../../../../Utils/constants'

class HeadingModal extends Component {
    constructor(props) {
        super(props)
        const { header, curItemIndex } = props
        this.state = {
            header,
            curItemIndex,
        }
    }

    handleChange = (field, value) => this.setState({ [field]: value })

    createFormData = () => {
        const { header } = this.state
        const { templateId, sectionId } = this.props
        const lopId = localStorage.getItem('myLopData') ? JSON.parse(localStorage.getItem('myLopData')).id : null
        return {
            header,
            templateId,
            sectionId,
            lopId,
        }
    }

    handleSave = () => {
        const { addBlock, updateBlockHeader, header: oldHeader, onCloseModal } = this.props
        const { header, curItemIndex } = this.state
        const validData = this.createFormData()
        if (oldHeader) {
            updateBlockHeader(header, curItemIndex)
        } else {
            addBlock(validData)
        }
        onCloseModal()
    }
    render() {
        const styleClasses = {
            modal: 'headerModal',
            overlay: 'overlayHeader',
            closeButton: 'close-btn-modal',
        }
        const { header } = this.state
        const { onCloseModal, header: oldHeader } = this.props
        return (
            <Modal open onClose={onCloseModal} center classNames={styleClasses}>
                <div className="header-content">
                    <div className="header-text">{oldHeader ? <span>Edit Header</span> : <span>Add Header</span>}</div>
                    <div className="body">
                        <div>
                            <Inputbox
                                label={'Header text'}
                                type="text"
                                fieldFor={'header'}
                                placeholder={'Please enter header text here.'}
                                onChange={this.handleChange}
                                onKeyPress={e => e.which === 13 && this.handleSave()}
                                value={header}
                                max={100}
                            />
                        </div>
                        <div className="btn-section">
                            <button className="btn btn-outline-secondary btn-lg" onClick={onCloseModal}>
                                {SITE_TXT.TXT_CANCEL}
                            </button>
                            <button className="btn btn-primary btn-lg" onClick={this.handleSave} disabled={!header}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

HeadingModal.defaultProps = {
    curItemIndex: null,
}

HeadingModal.propTypes = {
    curItemIndex: PropTypes.number,
    editMode: PropTypes.bool.isRequired,
    sectionId: PropTypes.string.isRequired,
    templateId: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    addBlock: PropTypes.func.isRequired,
    updateBlockHeader: PropTypes.func.isRequired,
    onCloseModal: PropTypes.func.isRequired,
}

export default HeadingModal
