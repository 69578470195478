import { SEARCH_FETCH, SEARCH, SEARCH_LOAD_MORE, SEARCH_RECENTLY_SEARCHED_DOCS } from './constant'

export const searchFetchAction = data => ({
    type: `${SEARCH_FETCH}_PENDING`,
    data,
})

export const searchFetchSuccessAction = data => ({
    type: `${SEARCH_FETCH}_SUCCESS`,
    data,
})

export const searchFetchFailedAction = data => ({
    type: `${SEARCH_FETCH}_FAILED`,
    data,
})

export const searchAction = data => ({
    type: `${SEARCH}_PENDING`,
    data,
})

export const searchSuccessAction = data => ({
    type: `${SEARCH}_SUCCESS`,
    data,
})

export const searchFailedAction = data => ({
    type: `${SEARCH}_FAILED`,
    data,
})

export const searchLoadMoreAction = data => ({
    type: `${SEARCH_LOAD_MORE}_PENDING`,
    data,
})

export const searchLoadMoreSuccessAction = data => ({
    type: `${SEARCH_LOAD_MORE}_SUCCESS`,
    data,
})

export const searchLoadMoreFailedAction = data => ({
    type: `${SEARCH_LOAD_MORE}_FAILED`,
    data,
})

export const recentlySearched = data => ({
    type: `${SEARCH_RECENTLY_SEARCHED_DOCS}_PENDING`,
    data,
})

export const recentlySearchedSuccess = data => ({
    type: `${SEARCH_RECENTLY_SEARCHED_DOCS}_SUCCESS`,
    data,
})

export const recentlySearchedFailed = data => ({
    type: `${SEARCH_RECENTLY_SEARCHED_DOCS}_FAILED`,
    data,
})
