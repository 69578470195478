import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import Template1 from './Template1'
import Template2 from './Template2'
import Template3 from './Template3'
import Template4 from './Template4'
import Template5 from './Template5'

class SectionContent extends Component {
    loadCurrentTemplate = () => {
        const {
            content,
            editMode,
            appWidth,
            sectionDetails,
            disableReorder,
            currentSlide,
            sliderSettings,
            sections,
            updateCurrentSlide,
        } = this.props
        if (isEmpty(content) || isEmpty(sectionDetails)) return null
        const { templateId } = sectionDetails

        switch (parseInt(templateId)) {
            case 5:
                return (
                    <Template5
                        content={content}
                        editMode={editMode}
                        disableReorder={disableReorder}
                        sectionDetails={sectionDetails}
                        sliderSettings={sliderSettings}
                    />
                )
            case 4:
                return (
                    <Template4
                        content={content}
                        editMode={editMode}
                        sections={sections}
                        updateCurrentSlide={updateCurrentSlide}
                        currentSlide={currentSlide}
                        disableReorder={disableReorder}
                        sectionDetails={sectionDetails}
                        appWidth={appWidth}
                    />
                )
            case 3:
                return (
                    <Template3
                        content={content}
                        editMode={editMode}
                        sections={sections}
                        updateCurrentSlide={updateCurrentSlide}
                        disableReorder={disableReorder}
                        sectionDetails={sectionDetails}
                    />
                )
            case 2:
                return (
                    <Template2
                        content={content}
                        sections={sections}
                        updateCurrentSlide={updateCurrentSlide}
                        editMode={editMode}
                        disableReorder={disableReorder}
                    />
                )
            case 1:
                return (
                    <Template1
                        content={content}
                        editMode={editMode}
                        sections={sections}
                        updateCurrentSlide={updateCurrentSlide}
                        disableReorder={disableReorder}
                        sectionDetails={sectionDetails}
                    />
                )
            default:
                return null
        }
    }

    render() {
        return <Fragment>{this.loadCurrentTemplate()}</Fragment>
    }
}

SectionContent.propTypes = {
    editMode: PropTypes.bool.isRequired,
    currentSlide: PropTypes.number.isRequired,
    disableReorder: PropTypes.bool.isRequired,
    appWidth: PropTypes.number.isRequired,
    sections: PropTypes.instanceOf(Array).isRequired,
    content: PropTypes.instanceOf(Object).isRequired,
    sectionDetails: PropTypes.instanceOf(Object).isRequired,
    sliderSettings: PropTypes.instanceOf(Object).isRequired,
    updateCurrentSlide: PropTypes.func.isRequired,
}

export default SectionContent
