import { fromJS } from 'immutable'
import { STATUS_FAILED } from '../../Utils/constants'
import {
    GET_CST_GROUP,
    ADD_CST_GROUP,
    EDIT_CST_GROUP,
    RESET_STATUS,
    LOAD_MORE_CST_GROUP,
    GET_SINGLE_CST,
    RESET_VALUE,
    VALIDATE_CHARGE_CODE,
} from './constant'

const INITIAL_STATE = fromJS({
    isLoading: false,
    mmCstContent: {},
    singleRecord: {},
})

function mmCstReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case `${GET_CST_GROUP}_PENDING"`:
            return state
                .set('status', '')
                .set('isLoading', true)
                .setIn(['mmCstContent'], fromJS({}))
        case `${GET_CST_GROUP}_SUCCESS`:
            return state
                .set('status', action.data.responseData.result.status)
                .setIn(['mmCstContent'], fromJS(action.data.responseData.result))
                .set('isLoading', false)
        case `${GET_CST_GROUP}_FAILURE`:
            return state
                .set('error', STATUS_FAILED)
                .set('isLoading', false)
                .setIn(['mmCstContent'], fromJS({}))
        case `${GET_SINGLE_CST}_PENDING"`:
            return state
                .set('status', '')
                .set('isLoading', true)
                .setIn(['singleRecord'], fromJS({}))
        case `${GET_SINGLE_CST}_SUCCESS`:
            return state
                .set('status', action.data.responseData.result.status)
                .setIn(['singleRecord'], fromJS(action.data.responseData.result.data))
                .set('isLoading', false)
        case `${GET_SINGLE_CST}_FAILURE`:
            return state
                .set('error', STATUS_FAILED)
                .set('isLoading', false)
                .setIn(['singleRecord'], fromJS({}))
        case `${LOAD_MORE_CST_GROUP}_PENDING"`:
            return state
                .set('status', '')
                .set('isLoading', true)
                .set('isListUpdated', false)
        case `${LOAD_MORE_CST_GROUP}_SUCCESS`:
            return state
                .updateIn(['mmCstContent', 'data'], arr => arr.push(...action.data.responseData.result.data))
                .set('isListUpdated', true)
        case `${LOAD_MORE_CST_GROUP}_FAILURE`:
            return state
                .set('error', STATUS_FAILED)
                .set('isLoading', false)
                .setIn(['mmCstContent'], fromJS({}))
                .set('isListUpdated', false)
        case `${ADD_CST_GROUP}_PENDING"`:
            return state
                .set('status', false)
                .set('isLoading', true)
                .set('isListUpdated', false)
                .setIn(['mmCstContent'], fromJS({}))
        case `${ADD_CST_GROUP}_SUCCESS`:
            return state
                .set('status', action.data.responseData.result.status)
                .set('isListUpdated', true)
                .set('mmCstContent', action.data.responseData.result)
                .set('isLoading', false)
        case `${ADD_CST_GROUP}_FAILURE`:
            return state
                .set('error', STATUS_FAILED)
                .set('isListUpdated', false)
                .set('isLoading', false)
                .setIn(['mmCstContent'], fromJS({}))
        case `${EDIT_CST_GROUP}_PENDING"`:
            return state
                .set('status', false)
                .set('isLoading', true)
                .set('isListUpdated', false)
        //.setIn(['mmCstContent'], fromJS({}))
        case `${EDIT_CST_GROUP}_SUCCESS`:
            return (
                state
                    .set('status', action.data.responseData.result.status)
                    //.set('mmCstContent', action.responseData.data.result)
                    .set('isLoading', false)
                    .set('isListUpdated', true)
            )
        case `${EDIT_CST_GROUP}_FAILURE`:
            return state
                .set('error', STATUS_FAILED)
                .set('isLoading', false)
                .set('isListUpdated', false)
        //.setIn(['mmCstContent'], fromJS({}))
        case `${RESET_STATUS}`:
            return state.set('isListUpdated', false)
        case `${RESET_VALUE}`:
            return state.set('singleRecord', {})
        case `${VALIDATE_CHARGE_CODE}_PENDING"`:
            return state
        case `${VALIDATE_CHARGE_CODE}_SUCCESS`:
            return state
        case `${VALIDATE_CHARGE_CODE}_FAILURE`:
            return state
        default:
            return state
    }
}

export default mmCstReducer
