import { SITE_TXT } from '../../Utils/constants'
export const LOP_GETSTARTED_ITEMS_GET = 'LOP_GETSTARTED_ITEMS_GET'
export const LOP_GETSTARTED_ITEMS_GET_MORE = 'LOP_GETSTARTED_ITEMS_GET_MORE'
export const LOP_GOLD_ITEMS_GET = 'LOP_GOLD_ITEMS_GET'
export const LOP_ADD_ITEM = 'LOP_ADD_ITEM'
export const LOP_GOLD_ITEMS_MORE = 'LOP_GOLD_ITEMS_MORE'
export const LOP_ADD_FOLDER = 'LOP_ADD_FOLDER'
export const LOP_EDIT_FOLDER = 'LOP_EDIT_FOLDER'
export const LOP_FETCH_FOLDER_LIST = 'LOP_FETCH_FOLDER_LIST'

export const LOP_GETSTARTED_ITEM_DELETE = 'LOP_GETSTARTED_ITEM_DELETE'
export const LOP_EDIT_ITEM = 'LOP_EDIT_ITEM'
export const LOP_GOLD_DELETE_ITEM = 'LOP_GOLD_DELETE_ITEM'
export const LOP_GOLD_DELETE_FOLDER = 'LOP_GOLD_DELETE_FOLDER'
export const LOP_GOLD_EMAIL_ITEMS = 'LOP_GOLD_EMAIL_ITEMS'
export const LOP_GOLD_DOWNLOAD_ITEMS = 'LOP_GOLD_DOWNLOAD_ITEMS'
export const LOP_FETCH_INDUSTRY_TAGS = 'LOP_FETCH_INDUSTRY_TAGS'
export const LOP_FETCH_TOPICS_KEYWORDS_TAGS = 'LOP_FETCH_TOPICS_KEYWORDS_TAGS'
export const LOP_GEOGRAPHY = 'LOP_GEOGRAPHY'
export const LOP_DEALTYPE = 'LOP_DEALTYPE'
export const LOP_DEALSIZE = 'LOP_DEALSIZE'
export const LOP_CASE_STUDIES_ADD = 'LOP_CASE_STUDIES_ADD'
export const LOP_CASE_STUDIES_ITEMS = 'LOP_CASE_STUDIES_ITEMS'
export const LOP_CASE_STUDIES_ITEMS_MORE = 'LOP_CASE_STUDIES_ITEMS_MORE'

export const LOP_CASE_STUDIES_EDIT_GET = 'LOP_CASE_STUDIES_EDIT_GET'
export const LOP_CASE_STUDIES_EDIT_PUT = 'LOP_CASE_STUDIES_EDIT_PUT'
export const LOP_EXPERT_ITEMS_GET = 'LOP_EXPERT_ITEMS_GET'
export const LOP_EXPERT_ITEMS_MORE = 'LOP_EXPERT_ITEMS_MORE'

export const LOP_CLIENTREF_ITEMS_GET = 'LOP_CLIENTREF_ITEMS_GET'
export const LOP_CLIENTREF_ITEMS_MORE = 'LOP_CLIENTREF_ITEMS_MORE'
export const LOP_EXPERT_CV_ADD = 'LOP_EXPERT_CV_ADD'
export const LOP_EXPERT_CV_EDIT = 'LOP_EXPERT_CV_EDIT'
export const LOP_EXPERT_CV_FETCH = 'LOP_EXPERT_CV_FETCH'
export const LOP_REGION_BASE = 'LOP_REGION_BASE'
export const LOP_FETCH_EXPERT_INDUSTRY_TAGS = 'LOP_FETCH_EXPERT_INDUSTRY_TAGS'
export const LOP_FETCH_EXPERT_ROLE_TAGS = 'LOP_FETCH_EXPERT_ROLE_TAGS'
export const LOP_FETCH_EXPERT_TOPICS_TAGS = 'LOP_FETCH_EXPERT_TOPICS_TAGS'
export const LOP_CLEAR_FETCHED_DATA = 'LOP_CLEAR_FETCHED_DATA'
export const LOP_FETCH_COUNTRY_CODE = 'LOP_FETCH_COUNTRY_CODE'
export const LOP_ADD_CLIENT = 'LOP_ADD_CLIENT'
export const LOP_EDIT_CLIENT_FETCH = 'LOP_EDIT_CLIENT_FETCH'
export const LOP_EDIT_CLIENT_PUT = 'LOP_EDIT_CLIENT_PUT'
export const LOP_EMPTY_CLIENT_DATA = 'LOP_EMPTY_CLIENT_DATA'
export const CREATE_LOP = 'CREATE_LOP'

export const LOP_SECTIONS = [
    { id: 'getting-started', name: SITE_TXT.TXT_LOP_GETTING_STARTED },
    { id: 'articles', name: SITE_TXT.TXT_LOP_ARTICLES },
    {
        id: 'gold-standard-lop-materials',
        name: SITE_TXT.TXT_LOP_GOLD_STANDARD,
    },
    /*{ id: 'case-studies', name: SITE_TXT.TXT_LOP_CASE_STUDIES },
    {
        id: 'client-references',
        name: SITE_TXT.TXT_LOP_CLIENT_REFERENCES,
    },
    { id: 'expert-cvs', name: SITE_TXT.TXT_LOP_EXPERT_CVS },
    { id: 'delivery', name: SITE_TXT.TXT_LOP_DELIVERY },*/
]

export const LOP_DOC_INITIAL_STATE = {
    name: '',
    docFile: null,
}

export const LOP_CS_DEALSIZE_INITIAL_STATE = {
    dealSizeMin: '0',
    dealSizeMax: '0',
}

export const LOP_CS_YEAR_INITIAL_STATE = {
    monthFrom: '0',
    monthTo: '0',
    yearFrom: '0',
    yearTo: '0',
}

export const LOP_CS_FILTER_RESET_STATE = {
    industry: '',
    geographyId: '',
    dealTypeId: '',
    keywords: '',
    fromDate: '',
    toDate: '',
    dealSizeMin: '',
    dealSizeMax: '',
}

export const LOP_EXPERT_LIST_INITIAL_STATE = {
    expertName: '',
    regionBaseIds: '',
    expertRole: [],
    industry: [],
    topics: [],
    docFile: '',
    id: null,
    fileName: '',
}

export const LOP_CLIENT_REFERENCES_INITIAL_STATE = {
    companyName: '',
    year: 0,
    dealTypeId: '',
    dealSizeId: '',
    primaryCSTContact: '',
    secondaryCSTContact: '',
    primaryMMContact: '',
    secondaryMMContact: '',
    primaryClientRefName: '',
    primaryClientRoleList: [],
    primaryClientRefEmail: '',
    primaryClientRefPhone: '',
    pClientRefCountryCodeId: '',
    secondaryClientRefName: '',
    secondaryClientRoleList: [],
    secondaryClientRefEmail: '',
    secondaryClientRefPhone: '',
    sClientRefCountryCodeId: '',
    industry: [],
    notes: '',
    month: 0,
    submissionDate: 0,
}
export const LOP_GETTING_STARTED = 'gettingStarted'
export const LOP_ARTICLES = 'articles'
export const LOP_GOLD_STANDARD = 'goldStandard'
export const LOP_CASE_STUDIES = 'caseStudies'
export const LOP_DELIVERY = 'delivery'
export const LOP_EXPERT_CV = 'expertCV'
export const LOP_CLIENT_REFERENCES = 'clientReferences'
export const TOOLBAR_CONFIG = {
    display: ['INLINE_STYLE_BUTTONS', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
        { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
        { label: 'Italic', style: 'ITALIC' },
        { label: 'Underline', style: 'UNDERLINE' },
    ],
}
