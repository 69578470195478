import { createSelector } from 'reselect'

const selectDeliver = state => state.get('deliver')

const selectLoading = () => createSelector(selectDeliver, deliver => deliver.toJS()['isLoading'])

const selectStatus = () => createSelector(selectDeliver, deliver => deliver.toJS()['status'])

const selectError = () => createSelector(selectDeliver, deliver => deliver.toJS()['error'])

const selectAddedData = () => createSelector(selectDeliver, deliver => deliver.toJS()['selectAddedData'])

const selectEditData = () => createSelector(selectDeliver, deliver => deliver.toJS()['selectEditData'])

const selectfetchData = () => createSelector(selectDeliver, deliver => deliver.toJS()['selectfetchData'])

const selectDeleteData = () => createSelector(selectDeliver, deliver => deliver.toJS()['selectDeleteData'])

const selectData = () => createSelector(selectDeliver, deliver => deliver.toJS()['data'])

const downloadBrochureData = () => createSelector(selectDeliver, deliver => deliver.toJS()['downloadBrochureData'])
export {
    selectStatus,
    selectLoading,
    selectError,
    selectData,
    selectAddedData,
    selectEditData,
    selectfetchData,
    selectDeleteData,
    downloadBrochureData,
}
