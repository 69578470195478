import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-responsive-modal'
import { Button } from 'reactstrap'
import { connect } from 'react-redux'

import { addLopSection } from '../../../Containers/LopContent/actions'
import Inputbox from '../../Inputbox'
import { SITE_TXT } from '../../../Utils/constants'
import './index.scss'

class AddSection extends Component {
    state = {
        sectionName: '',
    }

    addSectionInfo = () => {
        const { sectionName } = this.state
        const { myLopId, addLopSection, onClose } = this.props
        let mainData = new FormData()
        mainData.append('myLopId', myLopId)
        mainData.append('name', sectionName)
        addLopSection(mainData, {
            templateId: '4',
            sectionName,
            sectionHeader: '[placeholder]',
        })
        onClose()
    }

    handleChange = (a, sectionName) => {
        this.setState({ sectionName })
    }

    render() {
        const { onClose } = this.props
        const { sectionName } = this.state
        const styleClasses = {
            modal: 'albumModal addSection',
            overlay: 'overlayAlbumModal',
            closeButton: 'closeIcon',
        }
        return (
            <Modal open onClose={onClose} center classNames={styleClasses} closeOnOverlayClick={false}>
                <h2>{SITE_TXT.TXT_ADD_NEW_SECTION}</h2>
                <Inputbox
                    autoFocus
                    label={SITE_TXT.TXT_SECTION_NAME}
                    placeholder={SITE_TXT.TXT_SECTION_NAME_PLACEHOLDER}
                    value={sectionName}
                    onChange={this.handleChange}
                    max="200"
                />
                <div className="buttonContent">
                    <div className="btn btn-outline-secondary btn-lg">
                        <span onClick={onClose}>{SITE_TXT.TXT_CANCEL}</span>
                    </div>
                    <Button disabled={!sectionName} className="btn btn-primary btn-lg" onClick={this.addSectionInfo}>
                        {SITE_TXT.TXT_DONE}
                    </Button>
                </div>
            </Modal>
        )
    }
}

AddSection.propTypes = {
    myLopId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    addLopSection: PropTypes.func.isRequired,
}

const dispatchProps = { addLopSection }

export default connect(null, dispatchProps)(AddSection)
