import React, { Component } from 'react'
import ThumbnailListLibrary from '../ThumbnailListLibrary'
import ThumbnailListActions from '../ThumbnailListActions'
import './index.css'
import { SITE_TXT } from '../../Utils/constants'
class ThumbnailSectionLibrary extends Component {
    state = {
        data: this.props.data,
        isEditMode: false,
        isModalOpen: false,
        isReorderMode: false,
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({ data: nextProps.data })
    }

    toggleEditMode = () => {
        this.setState({ isEditMode: !this.state.isEditMode })
        //const sectionIndex = this.props.sectionIndex
        this.props.handleFadeSection(!this.state.isEditMode, this.props.sectionIndex)
    }

    openThumbnailModal = () => {
        this.setState({ isModalOpen: true })
    }

    closeThumbnailModal = () => {
        this.setState({ isModalOpen: false })
    }
    toggleReorderMode() {
        this.setState({ isReorderMode: !this.state.isReorderMode })
    }

    setReorderMode(mode) {
        this.setState({ data: this.state.data, isReorderMode: mode })
    }

    render() {
        const { data, isEditMode, isModalOpen, isReorderMode } = this.state
        const { id, name, list } = data
        const { TXT_NO_DATA } = SITE_TXT
        const isfade = this.props.isFade
        return (
            <section className={'top-category-container ' + isfade}>
                <section className="section-header">
                    {isEditMode ? (
                        <div className="editingmode">
                            You are now Editing <strong>{name}</strong> section{' '}
                            <span onClick={() => this.toggleEditMode()}>X</span>
                        </div>
                    ) : null}
                    <span>{name}</span>
                    {list && (
                        <ThumbnailListActions
                            clickAdd={() => this.openThumbnailModal()}
                            clickToggleEdit={() => this.toggleEditMode()}
                            isModalOpen={isModalOpen}
                            isEditMode={isEditMode}
                            isReorderMode={isReorderMode}
                            toggleReorderMode={() => this.toggleReorderMode()}
                        />
                    )}
                </section>
                {list ? (
                    <ThumbnailListLibrary
                        topCategoryId={id}
                        topCategoryName={name}
                        listData={list}
                        isEditMode={isEditMode}
                        add={this.props.add}
                        edit={this.props.edit}
                        trash={this.props.trash}
                        isModalOpen={isModalOpen}
                        closeThumbnailModal={() => this.closeThumbnailModal()}
                        isReorderMode={isReorderMode}
                        setReorderMode={mode => this.setReorderMode(mode)}
                        showSlider={this.props.showSlider}
                        onReorder={this.props.onReorder}
                    />
                ) : (
                    <span>{TXT_NO_DATA}</span>
                )}
            </section>
        )
    }
}

ThumbnailSectionLibrary.defaultProps = {
    showSlider: false,
}

export default ThumbnailSectionLibrary
