import React, { Component } from 'react'
import './index.css'
import { isValidPattern, convertToEmbedLinkYouTube } from '../../Utils/functions'
import Inputbox from '../Inputbox'
import ModuleHeader from '../ModuleHeader'
import { ConfirmAlertBox } from '../../Containers/Library/Alerts'
import ThumbnailEditorModal from '../ThumbnailEditorModal'
import {
    SITE_TXT,
    MAX_LENGTH_TOOL_DESCRIPTION,
    LINK_PATTERN,
    IMAGE_CROP_OBJ_RECT,
    TOOL_NAME_LENGTH_ALLOWED,
    STATUS_SUCCESS,
    STATUS_FAILURE,
} from '../../Utils/constants'
import ReactDropzone from 'react-dropzone'
import { RemoveIcon, BackIcon, UploadIcon } from '../../Utils/svg'
import * as Constant from '../../Containers/Deliver/constant'
import { isEmpty } from '../../Utils/functions'
import RichTextEditor from 'react-rte'
import { getPrefixedUrl } from '../../Utils/apis'
class DeliverForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tool: JSON.parse(JSON.stringify(Constant.DELIVER_INITAL_STATE)),
            value: RichTextEditor.createEmptyValue(),
            submitted: false,
            enteredVal: [],
            fetchedData: {},
            isModalOpen: false,
            newlyFetchesData: this.props.selectfetchData,
            addProduct: false,
            editProduct: false,
            parentId: this.props.location.state ? this.props.location.state.listId : null,
            deletedProduct: false,
            deliverImg1Src: null,
            deliverImg2Src: null,
            deliverImg3Src: null,
            deliverImgIconSrc: null,
            deliverImgageSrc: this.props.deliverImgageSrc || null,
        }

        this.handleChange = this.handleChange.bind(this)
    }
    componentDidMount() {
        let currentLocation = window.location.href
        let toolid = currentLocation.slice(currentLocation.lastIndexOf('/') + 1)
        let secondlastParam = currentLocation.split('/').slice(-2)[0]

        this.setState(
            {
                secondlastParam: secondlastParam,
                tool: {
                    ...this.state.tool,
                    toolId: toolid,
                },
            },
            () => {
                if (this.state.secondlastParam === 'edit') {
                    this.props.fetchDeliverItem(toolid)
                }
            }
        )
    }
    openUploadModal = field => {
        this.setState({
            isModalOpen: true,
            imageId: field,
        })
    }

    closeUploadModal = () => {
        this.setState({
            isModalOpen: false,
        })
    }
    deleteDetails = () => {
        const title = SITE_TXT.TXT_CONFIRM_DELETE
        const message = SITE_TXT.TXT_SURE_DELETE_TOOL
        const onYesTrash = () => this.trashFinally()
        ConfirmAlertBox(title, message, onYesTrash)
    }

    trashFinally = () => {
        this.setState({ deletedProduct: true }, () => {
            this.props.deliverDeleteSubSection(this.state.tool.toolId)
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.state.secondlastParam === 'edit') {
            if (nextProps.selectfetchData && isEmpty(this.state.fetchedData)) {
                const inputVal = nextProps.selectfetchData.description.replace(/(<([^>]+)>)/gi, '')
                const enteredValue = inputVal.split(' ')

                this.setState({
                    fetchedData: nextProps.selectfetchData,
                    tool: {
                        ...this.state.tool,
                        image1: nextProps.selectfetchData.image1,
                        image2: nextProps.selectfetchData.image2,
                        image3: nextProps.selectfetchData.image3,
                        toolName: nextProps.selectfetchData.name,
                        toolIcon: nextProps.selectfetchData.icon,
                        toolLink: nextProps.selectfetchData.toolLink,
                        toolDescription: nextProps.selectfetchData.description,
                        videoLink: nextProps.selectfetchData.videoLink,
                    },
                    value: RichTextEditor.createValueFromString(nextProps.selectfetchData.description, 'html'),
                    enteredVal: enteredValue,
                })
            }
        }
        if (nextProps.selectDeleteData) {
            if (this.state.deletedProduct === true && nextProps.selectDeleteData.status === STATUS_SUCCESS) {
                this.setState({ deletedProduct: false })
                this.historyBack()
            } else if (
                this.state.deletedProduct === true &&
                nextProps.selectDeleteData.response.data.status === STATUS_FAILURE
            ) {
                this.setState({ deletedProduct: false })
                alert(nextProps.selectDeleteData.response.data.result.message)
            }
        }

        if (nextProps.selectAddedData) {
            if (this.state.addProduct === true && nextProps.selectAddedData.status === STATUS_SUCCESS) {
                this.setState({ addProduct: false })
                this.historyBack()
            } else if (
                this.state.addProduct === true &&
                nextProps.selectAddedData.response.data.status === STATUS_FAILURE
            ) {
                this.setState({ addProduct: false })
                alert(nextProps.selectAddedData.response.data.result.message)
            }
        }
        if (nextProps.selectEditData) {
            if (this.state.editProduct === true && nextProps.selectEditData.status === STATUS_SUCCESS) {
                this.setState({ editProduct: false })
                this.historyBack()
            } else if (this.state.editProduct === true && nextProps.selectEditData.status === STATUS_FAILURE) {
                this.setState({ editProduct: false })
                //alert(nextProps.selectEditData.result.message)
            }
        }
        /* if (this.state.addProduct === true) {
            this.setState({ addProduct: false })
            this.historyBack()
        } */
    }
    removeFile() {
        this.setState({ tool: { ...this.state.tool, brochure: '' } })
    }
    removeIconFile() {
        this.setState({ tool: { ...this.state.tool, toolIcon: '' } })
    }
    handleChange = (field, value) => {
        this.setState({ tool: { ...this.state.tool, [field]: value } }, () => {})
    }
    getSrcFunction(reader) {
        if (this.state.imageId === 'image1') {
            this.setState({
                deliverImg1Src: reader.result,
                deliverImgageSrc: reader.result,
            })
        } else if (this.state.imageId === 'image2') {
            this.setState({
                deliverImg2Src: reader.result,
                deliverImgageSrc: reader.result,
            })
        } else if (this.state.imageId === 'image3') {
            this.setState({
                deliverImg3Src: reader.result,
                deliverImgageSrc: reader.result,
            })
        } else if (this.state.imageId === 'toolIcon') {
            this.setState({
                deliverImgIconSrc: reader.result,
                deliverImgageSrc: reader.result,
            })
        }
    }
    getBase64(file) {
        var reader = new FileReader()
        reader.addEventListener(
            'load',
            () => {
                this.getSrcFunction(reader)
            },
            false
        )
        if (file) {
            reader.readAsDataURL(file)
        }
    }

    handleSave = (thumbnailId, formTosave, imageTosave) => {
        if (this.state.imageId === 'image1') {
            this.setState({
                tool: { ...this.state.tool, image1: imageTosave },
            })
            this.getBase64(imageTosave)
        } else if (this.state.imageId === 'image2') {
            this.setState({
                tool: { ...this.state.tool, image2: imageTosave },
            })
            this.getBase64(imageTosave)
        } else if (this.state.imageId === 'image3') {
            this.setState({
                tool: { ...this.state.tool, image3: imageTosave },
            })
            this.getBase64(imageTosave)
        } else if (this.state.imageId === 'toolIcon') {
            this.setState({
                tool: { ...this.state.tool, toolIcon: imageTosave },
            })
            this.getBase64(imageTosave)
        }

        this.closeUploadModal()
    }
    createFormData() {
        let tool = this.state.tool
        let data = new FormData()
        let currentLocation = window.location.href
        let editToolId = currentLocation.slice(currentLocation.lastIndexOf('/') + 1)
        data.append('name', tool.toolName)
        data.append('image1', tool.image1)
        data.append('image2', tool.image2)
        data.append('image3', tool.image3)
        data.append('docFile', tool.brochure)
        data.append('videoLink', convertToEmbedLinkYouTube(tool.videoLink))
        data.append('link', tool.toolLink)
        data.append('detail', tool.toolDescription.toString('html'))
        data.append('imageIcon', tool.toolIcon)
        if (this.state.secondlastParam === 'edit') {
            data.append('id', editToolId)
        }
        if (this.state.secondlastParam !== 'edit') {
            data.append('deliveryTopicId', this.state.parentId.listId)
        }

        return data
    }
    onChange = value => {
        this.setState({
            value,
        })
        const inputVal = value._editorState
            .getCurrentContent()
            .getPlainText('')
            .trim()
        const enteredValue = inputVal.split(' ')
        this.setState({ enteredVal: enteredValue })
        if (enteredValue.length <= 70) {
            this.setState({
                tool: {
                    ...this.state.tool,
                    toolDescription: value,
                },
            })
        }
    }

    addDetails = e => {
        e.preventDefault()
        if (this.state.secondlastParam === 'edit') {
            this.setState(
                this.setState({ submitted: true }, () => {
                    const { tool, enteredVal } = this.state
                    if (
                        tool.toolName &&
                        tool.image1 &&
                        tool.image2 &&
                        tool.image3 &&
                        tool.toolLink &&
                        enteredVal !== [] &&
                        enteredVal.length <= 70 &&
                        enteredVal.length !== 0 &&
                        enteredVal[0] !== '' &&
                        isValidPattern(tool.videoLink, LINK_PATTERN) &&
                        isValidPattern(tool.toolLink, LINK_PATTERN) &&
                        tool.toolName.length <= TOOL_NAME_LENGTH_ALLOWED
                    ) {
                        this.setState({ editProduct: true }, () => {
                            this.props.deliverEditSubSection(this.createFormData())
                        })
                    }
                })
            )
        } else {
            this.setState({ submitted: true }, () => {
                const { tool, enteredVal } = this.state
                if (
                    tool.toolIcon &&
                    tool.toolName &&
                    tool.toolLink &&
                    tool.image1 &&
                    tool.image2 &&
                    tool.image3 &&
                    enteredVal !== [] &&
                    enteredVal.length <= MAX_LENGTH_TOOL_DESCRIPTION &&
                    enteredVal.length !== 0 &&
                    enteredVal[0] !== '' &&
                    tool.videoLink &&
                    isValidPattern(tool.videoLink, LINK_PATTERN) &&
                    isValidPattern(tool.toolLink, LINK_PATTERN) &&
                    tool.toolName.length <= TOOL_NAME_LENGTH_ALLOWED
                ) {
                    this.setState({ addProduct: true }, () => {
                        this.props.deliverAddSubSection(this.createFormData())
                    })
                }
            })
        }
    }
    handleDrop = files => {
        if (files.length === 0) {
            alert(SITE_TXT.TXT_INVALID_FILE_EXT)
            return
        }
        this.setState({
            tool: { ...this.state.tool, brochure: files[0] },
        })
    }
    handleIconDrop = files => {
        this.setState({
            tool: { ...this.state.tool, toolIcon: files[0] },
        })
    }
    historyBack() {
        window.history.back()
    }

    render() {
        const toolbarConfig = {
            display: ['INLINE_STYLE_BUTTONS', 'HISTORY_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                { label: 'Italic', style: 'ITALIC' },
                { label: 'Underline', style: 'UNDERLINE' },
            ],
        }

        const {
            tool,
            submitted,
            enteredVal,
            isModalOpen,
            deliverImg1Src,
            deliverImg2Src,
            deliverImg3Src,
            deliverImgIconSrc,
            deliverImgageSrc,
            imageId,
        } = this.state
        const thumbImages = {
            deliverImg2Src,
            deliverImg1Src,
            deliverImg3Src,
            deliverImgIconSrc,
        }
        const {
            TXT_TOOL_ICON_NAME,
            TXT_TOOL_LINK,
            TXT_TOOL_NAME_PLACEHOLDER,
            TXT_TOOL_LINK_PLACEHOLDER,
            TXT_DELIVER_FILE_TYPE_ACCEPTED,
            TXT_ALLOWED_FILES_TEXT,
            TXT_DELIVER_DROPZONE_TEXT,
            TXT_TOOL_IMAGES,
            TXT_DELIVER,
            TXT_PLACEHOLDER_DELIVER_TOOL_DESCIRPTION,
            TXT_TOOL_DESCIRPTION,
            TXT_UPLOAD_BROCHURE,
            TXT_VIDEO_LINK,
            TXT_UNDER_VIDEO_LINK_LABEL,
            TXT_TOOL_NAME_REQUIRED,
            TXT_TOOL_DESCRIPTION_REQUIRED,
            TXT_TOOL_LINK_REQUIRED,
            TXT_TOOL_ICON_REQUIRED,
            TXT_VIDEO_LINK_PLACEHOLDER,
            TXT_TOOL_LINK_INVALID,
            TXT_VIDEO_LINK_INVALID,
            TXT_DELIVER_BROCHURE_FILE_TYPE_ACCEPTED,
            TXT_TOOL_DESCRIPTION_INVALID,
            TXT_DELIVER_EDIT_DROPZONE_TEXT,
            TXT_VIDEO_LINK_REQUIRED,
            TXT_ADD_TOOL_DETAILS,
            TXT_EDIT_TOOL_DETAILS,
            TXT_ADD_TOOL,
            TXT_EDIT_TOOL,
            TXT_DELETE_TOOL,
            TXT_TOOL_NAME_INVALID,
            TXT_TOP_RIGHT_IMAGE,
            TXT_LEFT_IMAGE,
            TXT_BOTTOM_RIGHT_IMAGE,
            TXT_BROWSE,
            TXT_TOOL_IMAGE_REQUIRED,
        } = SITE_TXT

        const dropZoneText =
            this.state.secondlastParam === 'edit' ? TXT_DELIVER_EDIT_DROPZONE_TEXT : TXT_DELIVER_DROPZONE_TEXT

        const deliverImg = tool[`${this.state.imageId}`]

        return (
            <main className="deliver-container">
                <span onClick={() => this.historyBack()} className="getBack">
                    <BackIcon />
                </span>
                <div className="headingModule">
                    {this.state.secondlastParam === 'edit' ? (
                        <ModuleHeader heading={TXT_EDIT_TOOL_DETAILS} />
                    ) : (
                        <ModuleHeader heading={TXT_ADD_TOOL_DETAILS} />
                    )}
                </div>
                <section>
                    <form
                        id="uploadProductForm"
                        encType="multipart/form-data"
                        className="form-horizontal drop-shadow"
                        method="post"
                    >
                        <div className="deliverForm">
                            <div className="first-div-deliver">
                                <label htmlFor="tool-icon-name" className=" delivery-form-label">
                                    {TXT_TOOL_ICON_NAME}*
                                </label>
                                <div className="div-tool-icon">
                                    <div className="first-div-deliver-upload">
                                        <div id="uploadFileDivForDeliver">
                                            <div
                                                className={
                                                    ' upload-validation tool-icon form-group' + submitted &&
                                                    !tool.toolIcon
                                                        ? ' has-error  upload-validation tool-icon form-group'
                                                        : ' upload-validation tool-icon form-group'
                                                }
                                                onClick={() => this.openUploadModal('toolIcon')}
                                            >
                                                {tool.toolIcon ? (
                                                    <img
                                                        className="image-tool-icon"
                                                        alt="img"
                                                        src={deliverImgIconSrc || getPrefixedUrl(`${tool.toolIcon}`)}
                                                    />
                                                ) : (
                                                    <div className="browse-icon">
                                                        <UploadIcon width="38" height="34" /> <br />
                                                        {TXT_BROWSE}
                                                    </div>
                                                )}
                                            </div>

                                            {/*{' '}
                                            <ReactDropzone
                                                onDrop={this.handleIconDrop}
                                                className={
                                                    'dropzone upload-validation tool-icon form-group' +
                                                        submitted &&
                                                    !tool.toolIcon
                                                        ? ' has-error dropzone upload-validation tool-icon form-group'
                                                        : 'dropzone upload-validation tool-icon form-group'
                                                }
                                                accept={
                                                    TXT_DELIVER_FILE_TYPE_ACCEPTED
                                                }
                                            >
                                                {this.state.secondlastParam ===
                                                'edit'
                                                    ? 'Upload \n'
                                                    : ''}

                                                {tool.toolIcon === ''
                                                    ? TXT_DELIVER_DROPZONE_TEXT
                                                    : tool.toolIcon.name}
                                            </ReactDropzone>{' '}



                                        <div className="removeIconFile">
                                            <span
                                                onClick={this.removeIconFile.bind(
                                                    this
                                                )}
                                            >
                                                <RemoveIcon />
                                            </span>
                                        </div>*/}
                                        </div>
                                        {this.state.secondlastParam === 'edit'
                                            ? ''
                                            : submitted &&
                                              !tool.toolIcon && (
                                                  <div className="help-block">{TXT_TOOL_ICON_REQUIRED}</div>
                                              )}
                                    </div>
                                    <div className="sec-div-deliver-inputText">
                                        <label>Tool Name</label>
                                        <Inputbox
                                            value={tool.toolName}
                                            onChange={this.handleChange}
                                            autoFocus={true}
                                            type="text"
                                            fieldFor="toolName"
                                            customClass={'form-group' + submitted && !tool.toolName ? ' has-error' : ''}
                                            placeholder={TXT_TOOL_NAME_PLACEHOLDER}
                                        />{' '}
                                        {submitted && !tool.toolName && (
                                            <div className="help-block">{TXT_TOOL_NAME_REQUIRED}</div>
                                        )}
                                        {submitted && tool.toolName.length > TOOL_NAME_LENGTH_ALLOWED && (
                                            <div className="help-block">{TXT_TOOL_NAME_INVALID}</div>
                                        )}
                                    </div>
                                </div>
                                <label htmlFor="tool-icon-name" className=" delivery-form-label">
                                    {TXT_TOOL_IMAGES}*
                                </label>
                                <span className="allowedExt title">
                                    {TXT_ALLOWED_FILES_TEXT} <b>{TXT_DELIVER_FILE_TYPE_ACCEPTED}</b>{' '}
                                </span>

                                <div id="uploadFileDivForDeliver" className="images" form="uploadProductForm">
                                    {' '}
                                    <div className="image-drop-container">
                                        <span className="allowedExt">{TXT_TOP_RIGHT_IMAGE}</span>
                                        <div onClick={() => this.openUploadModal('image1')} className="dropzone">
                                            {tool.image1 ? (
                                                <img
                                                    alt="img"
                                                    src={deliverImg1Src || getPrefixedUrl(`${tool.image1}`)}
                                                    className="deliver-images"
                                                />
                                            ) : (
                                                <div className="browse-icon">
                                                    <UploadIcon width="38" height="34" /> <br />
                                                    {TXT_BROWSE}
                                                </div>
                                            )}
                                            {submitted && !tool.image1 && (
                                                <div className="help-block">{TXT_TOOL_IMAGE_REQUIRED}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="image-drop-container">
                                        <span className="allowedExt">{TXT_BOTTOM_RIGHT_IMAGE}</span>
                                        <div onClick={() => this.openUploadModal('image3')} className="dropzone">
                                            {tool.image3 ? (
                                                <img
                                                    className="deliver-images"
                                                    alt="img"
                                                    src={deliverImg3Src || getPrefixedUrl(`${tool.image3}`)}
                                                />
                                            ) : (
                                                <div className="browse-icon">
                                                    <UploadIcon width="38" height="34" /> <br />
                                                    {TXT_BROWSE}
                                                </div>
                                            )}
                                            {submitted && !tool.image3 && (
                                                <div className="help-block">{TXT_TOOL_IMAGE_REQUIRED}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="image-drop-container">
                                        <span className="allowedExt">{TXT_LEFT_IMAGE}</span>
                                        <div onClick={() => this.openUploadModal('image2')} className="dropzone">
                                            {tool.image2 ? (
                                                <img
                                                    className="deliver-images"
                                                    alt="img"
                                                    src={deliverImg2Src || getPrefixedUrl(`${tool.image2}`)}
                                                />
                                            ) : (
                                                <div className="browse-icon">
                                                    <UploadIcon width="38" height="34" /> <br />
                                                    {TXT_BROWSE}
                                                </div>
                                            )}
                                            {submitted && !tool.image2 && (
                                                <div className="help-block">{TXT_TOOL_IMAGE_REQUIRED}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className="form-group mobile-absolute" style={{ paddingTop: 15 }}>
                                    <label htmlFor="tool-icon-name" className="delivery-form-label">
                                        {TXT_TOOL_DESCIRPTION}*
                                    </label>
                                    <RichTextEditor
                                        className={
                                            submitted && (enteredVal.length === 0 || enteredVal[0] === '')
                                                ? ' has-error deliver-tool-desc form-group'
                                                : 'deliver-tool-desc form-group'
                                        }
                                        toolbarConfig={toolbarConfig}
                                        value={this.state.value}
                                        onChange={this.onChange}
                                        placeholder={TXT_PLACEHOLDER_DELIVER_TOOL_DESCIRPTION}
                                    />
                                    {submitted && (enteredVal.length === 0 || enteredVal[0] === '') && (
                                        <div className="help-block">{TXT_TOOL_DESCRIPTION_REQUIRED}</div>
                                    )}
                                    {submitted &&
                                        enteredVal !== [] &&
                                        enteredVal.length > MAX_LENGTH_TOOL_DESCRIPTION && (
                                            <div className="help-block">{TXT_TOOL_DESCRIPTION_INVALID}</div>
                                        )}
                                </div>
                            </div>

                            <div className="sec-div-deliver">
                                <label htmlFor="tool-icon-name" className=" delivery-form-label">
                                    {TXT_TOOL_LINK}*
                                </label>
                                <Inputbox
                                    type="text"
                                    fieldFor="toolLink"
                                    customClass={'form-group' + submitted && !tool.toolLink ? ' has-error' : ''}
                                    value={tool.toolLink}
                                    autoFocus={true}
                                    placeholder={TXT_TOOL_LINK_PLACEHOLDER}
                                    onChange={this.handleChange}
                                />
                                {submitted && !tool.toolLink && (
                                    <div className="help-block">{TXT_TOOL_LINK_REQUIRED}</div>
                                )}
                                {submitted && tool.toolLink && !isValidPattern(tool.toolLink, LINK_PATTERN) && (
                                    <div className="help-block">{TXT_TOOL_LINK_INVALID}</div>
                                )}
                                <label htmlFor="tool-icon-name" className=" delivery-form-label pad-top-20">
                                    {TXT_UPLOAD_BROCHURE}
                                </label>
                                <span className="allowedExt">
                                    {TXT_ALLOWED_FILES_TEXT} <b>{TXT_DELIVER_BROCHURE_FILE_TYPE_ACCEPTED}</b>{' '}
                                </span>

                                <div id="uploadFileDivForDeliver" className="" form="uploadProductForm">
                                    <ReactDropzone
                                        onDrop={this.handleDrop}
                                        className="dropzone upload-validation upload-brochure"
                                        accept={TXT_DELIVER_BROCHURE_FILE_TYPE_ACCEPTED}
                                    >
                                        {tool.brochure ? tool.brochure.name : dropZoneText}
                                    </ReactDropzone>

                                    <div className="removeFile">
                                        <span onClick={this.removeFile.bind(this)}>
                                            <RemoveIcon />
                                        </span>
                                    </div>
                                </div>
                                <label
                                    htmlFor="tool-icon-name"
                                    style={{ paddingTop: 10 }}
                                    className=" delivery-form-label"
                                >
                                    {TXT_VIDEO_LINK}
                                </label>
                                <div className="under-label-text">{TXT_UNDER_VIDEO_LINK_LABEL}</div>
                                <br />
                                <Inputbox
                                    type="text"
                                    fieldFor="videoLink"
                                    customClass={'form-group' + submitted && !tool.videoLink ? ' has-error' : ''}
                                    value={tool.videoLink}
                                    autoFocus={true}
                                    placeholder={TXT_VIDEO_LINK_PLACEHOLDER}
                                    onChange={this.handleChange}
                                />
                                {submitted && !tool.videoLink && (
                                    <div className="help-block">{TXT_VIDEO_LINK_REQUIRED}</div>
                                )}
                                {submitted && tool.videoLink && !isValidPattern(tool.videoLink, LINK_PATTERN) && (
                                    <div className="help-block">{TXT_VIDEO_LINK_INVALID}</div>
                                )}
                            </div>
                        </div>
                        <div className="button-class btn-section">
                            {this.state.secondlastParam === 'edit' ? (
                                <button
                                    type="button"
                                    className="btn btn-danger btn-lg "
                                    onClick={this.deleteDetails.bind(this)}
                                >
                                    {TXT_DELETE_TOOL}
                                </button>
                            ) : null}
                            {'   '}
                            <button
                                type="submit"
                                className="btn btn-primary btn-lg"
                                onClick={this.addDetails.bind(this)}
                            >
                                {this.state.secondlastParam === 'edit' ? TXT_EDIT_TOOL : TXT_ADD_TOOL}
                            </button>
                        </div>

                        <div>
                            {isModalOpen && (
                                <ThumbnailEditorModal
                                    isModalOpen={isModalOpen}
                                    formType={TXT_DELIVER}
                                    clickToBrowse={this.clickToBrowse}
                                    thumbnailImg={deliverImg}
                                    deliverImgageSrc={deliverImgageSrc}
                                    thumbImages={thumbImages}
                                    imageId={imageId}
                                    editCancel={() => this.closeUploadModal()}
                                    handleSave={(id, form, test) => this.handleSave(id, form, test)}
                                    imgCropObj={IMAGE_CROP_OBJ_RECT}
                                    onlyImage={true}
                                    modalClass="deliver-modal create-user-modal"
                                />
                            )}
                        </div>
                    </form>
                </section>
            </main>
        )
    }
}

export default DeliverForm
