import React, { Component } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
// import ReactSelect from 'react-select'
import './index.css'
import { isEmpty } from '../../Utils/functions'
import { SITE_TXT } from '../../Utils/constants'
import { Link } from 'react-router-dom'
let showRecord = null
class Search extends Component {
    state = {
        module: this.props.match.params.type,
        metaData: { [this.props.match.params.type]: {} },
        data: {},
        pageNumber: 1,
        pageSize: 20,
        isLoading: this.props.isLoading,
        defaultTopic: null,
        selTabName: sessionStorage.getItem('selTabName') ? sessionStorage.getItem('selTabName') : '',
        selectedTab: sessionStorage.getItem('selectedTab') ? parseInt(sessionStorage.getItem('selectedTab'), 10) : 0,
        clickSearch: false,
    }

    componentDidMount() {
        const { searchFetchOptions, match } = this.props
        const defaultTopic = sessionStorage.getItem('lastTopicPage')
        sessionStorage.removeItem('lastTopicPage')
        this.setState({
            defaultTopic,
        })
        searchFetchOptions(match.params.type)
        this.props.recentlySearched()
    }
    handleSearch(e) {
        e.preventDefault()
        this.setState(
            {
                pageNumber: 1,
                clickSearch: true,
            },
            () => {
                const data = this.getFormData(this.searchForm)
                this.props.search(data)
            }
        )
    }

    getFormData(formElem) {
        const { keywordsElem, typeElem, topicElem } = formElem
        const { pageNumber, pageSize } = this.state
        return {
            keyword: keywordsElem.value,
            type: typeElem.value,
            topic: topicElem.value,
            pageNumber,
            pageSize,
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { metaData, match, data, isLoading } = nextProps
        if (isEmpty(this.state.metaData[match.params.type]) && !isEmpty(metaData)) {
            this.setState({ metaData })
        }
        if (!isEmpty(data)) {
            this.setState({ data })
        }
        if (isLoading !== this.state.isLoading) {
            this.setState({ isLoading })
        }
        if (this.state.clickSearch === true) {
            if (data && data.totalRecords === 0) {
                showRecord = <div className="no-result">{SITE_TXT.TXT_NO_RECORDS_FOUND}</div>
                this.setState({ clickSearch: false })
            } else {
                showRecord = null
            }
        }
    }

    loadMoreSearch() {
        this.setState({ pageNumber: this.state.pageNumber + 1, isLoading: true }, () => {
            const data = this.getFormData(this.searchForm)
            this.props.searchLoadMore(data)
        })
    }
    resetForm(e) {
        e.preventDefault()

        this.setState({ defaultTopic: null }, () => {
            this.searchForm.reset()
        })
    }

    render() {
        const { module, metaData, data, defaultTopic } = this.state
        const moduleMetaData = metaData[module]
        const { topic, type } = moduleMetaData
        const { recentlySearchedData } = this.props

        const {
            // TXT_NO_RECORDS_FOUND,
            TXT_LOADING,
            TXT_SEARCH_RESULT,
            TXT_RESET_SEARCH,
            // TXT_KEYWORDS,
            // TXT_TYPE,
            // TXT_FUNCTION_OR_TOPICS,
            TXT_RECENTLY_SEARCHED,
            TXT_ALL,
            TXT_SEARCH_BTN,
            TXT_GO_TO_DOCUMENT,
        } = SITE_TXT
        return (
            <main className="search-container">
                <div className="search-labels">
                    {/*  <div className="search-label-group">
                        <div className="search-label keyword">
                            {TXT_KEYWORDS}
                        </div>
                        <div className="search-label type">{TXT_TYPE}</div>
                        <div className="search-label topic">
                            {TXT_FUNCTION_OR_TOPICS}
                        </div>
        </div> */}
                </div>
                <form
                    onSubmit={e => this.handleSearch(e)}
                    className="form-inline"
                    ref={elem => (this.searchForm = elem)}
                >
                    <div className="search-inputs">
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Keywords"
                                name="keywordsElem"
                                autoFocus
                            />
                            <div className="input-separator" />
                        </div>
                        <div className="form-group">
                            <select className="form-control" name="typeElem">
                                <option value="">{TXT_ALL}</option>
                                {type &&
                                    type.map(({ key, value }) => (
                                        <option key={key} value={key}>
                                            {value}
                                        </option>
                                    ))}
                            </select>
                            {/* type && (
                                <ReactSelect
                                    name="typeElem"
                                    options={[
                                        { label: 'All', value: '' },
                                        ...type.map(item => ({
                                            label: item.value,
                                            value: item.key,
                                        })),
                                    ]}
                                    placeholder="Type"
                                    className="form-control custom-select"
                                    classNamePrefix="react-select"
                                />
                            ) */}
                            <div className="input-separator" />
                        </div>
                        <div className="form-group">
                            {topic && (
                                <select
                                    className="form-control"
                                    name="topicElem"
                                    value={defaultTopic}
                                    onChange={e =>
                                        this.setState({
                                            defaultTopic: e.target.value,
                                        })
                                    }
                                >
                                    <option value="">{TXT_ALL}</option>
                                    {topic.map(({ id, name, list }) => (
                                        <optgroup label={name} key={id}>
                                            {list.map(({ id, name }) => (
                                                <option key={id} value={id}>
                                                    {name}
                                                </option>
                                            ))}
                                        </optgroup>
                                    ))}
                                </select>
                            )}
                        </div>
                    </div>
                    <div className="search-actions-new">
                        <div className="btn-section">
                            <button type="submit" className="btn btn-primary btn-lg">
                                {TXT_SEARCH_BTN}
                            </button>
                            <button
                                type="button"
                                className="btn btn-outline-secondary btn-lg"
                                onClick={e => this.resetForm(e)}
                            >
                                {TXT_RESET_SEARCH}
                            </button>
                        </div>
                    </div>
                </form>
                {/*  <div className="search-labels">
                    <div className="search-label-group">
                        <div
                            className="search-label"
                            onClick={() => this.searchForm.reset()}
                        >
                            {TXT_RESET_SEARCH}
                        </div>
                    </div>
                                            </div> */}
                <div className="result-container">
                    {data && data.totalRecords > 0 ? (
                        <section className="section-header">
                            <span className="recently-searched">
                                {TXT_SEARCH_RESULT}{' '}
                                <span className="countTotal">
                                    {' '}
                                    {data.totalRecords ? `  ${data.totalRecords} ` : `0`}
                                </span>
                            </span>
                        </section>
                    ) : null}
                    {data && data.totalRecords > 0 ? (
                        <div>
                            <InfiniteScroll
                                pageStart={0}
                                loadMore={() => this.loadMoreSearch()}
                                hasMore={data.list.length < data.totalRecords && !this.state.isLoading}
                                loader={
                                    <div className="loader" key={0}>
                                        {TXT_LOADING}
                                    </div>
                                }
                                useWindow={true}
                            >
                                <ul className="searchListItems">
                                    {!isEmpty(data) &&
                                        !isEmpty(data.list) &&
                                        data.list.map(item => (
                                            <li key={item.id}>
                                                <div>
                                                    <Link
                                                        // href={item.box_previewURL}
                                                        to={`/${SITE_TXT.TXT_LIBRARY}/${item.libraryName}/${item.topicId}`}
                                                    >
                                                        <span>
                                                            <img alt="img" src={'/icons/' + item.contentFormatIcon} />
                                                        </span>
                                                        <div>
                                                            <span>{item.topicName}</span>
                                                            <span>{item.contentName}</span>
                                                        </div>
                                                        <span>
                                                            <Link
                                                                to={{
                                                                    pathname: `/${SITE_TXT.TXT_LIBRARY}/${item.libraryName}/${item.topicId}`,
                                                                    state: {
                                                                        item: item,
                                                                    },
                                                                }}
                                                            >
                                                                <span
                                                                    className="redirectToDocIcon"
                                                                    title={TXT_GO_TO_DOCUMENT}
                                                                />
                                                            </Link>
                                                        </span>
                                                    </Link>
                                                </div>
                                            </li>
                                        ))}
                                </ul>
                            </InfiniteScroll>
                        </div>
                    ) : (
                        showRecord
                    )}
                    <div>
                        <section className="section-header">
                            <div className="recently-searched">
                                {TXT_RECENTLY_SEARCHED}{' '}
                                <span className="countTotal">
                                    {!isEmpty(recentlySearchedData)
                                        ? recentlySearchedData.totalRecords
                                            ? `  ${recentlySearchedData.totalRecords} `
                                            : `0`
                                        : 0}
                                </span>
                            </div>
                        </section>
                        <ul className="searchListItems">
                            {!isEmpty(recentlySearchedData) &&
                                !isEmpty(recentlySearchedData.list) &&
                                recentlySearchedData.list.map(item => (
                                    <li key={item.id}>
                                        <div>
                                            <Link
                                                to={`/library/${item.libraryName}/${item.topicId}`}
                                                rel="noopener noreferrer"
                                            >
                                                <span>
                                                    <img alt="img" src={'/icons/' + item.contentFormatIcon} />
                                                </span>
                                                <div>
                                                    <span>{item.topicName}</span>
                                                    <span>{item.contentName}</span>
                                                </div>
                                                <span>
                                                    <Link
                                                        to={{
                                                            pathname: `/library/${item.libraryName}/${item.topicId}`,
                                                            state: {
                                                                item: item,
                                                            },
                                                        }}
                                                    >
                                                        <span
                                                            className="redirectToDocIcon"
                                                            title={TXT_GO_TO_DOCUMENT}
                                                        />
                                                    </Link>
                                                </span>
                                            </Link>
                                        </div>
                                    </li>
                                ))}
                        </ul>
                    </div>
                </div>
            </main>
        )
    }
}

export default Search
