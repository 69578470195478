import { createSelector } from 'reselect'

const selectMMPractice = state => state.get('mmPractice')

const selectLoading = () => createSelector(selectMMPractice, mmPractice => mmPractice.toJS()['isLoading'])

const selectStatus = () => createSelector(selectMMPractice, mmPractice => mmPractice.toJS()['status'])

const selectError = () => createSelector(selectMMPractice, mmPractice => mmPractice.toJS()['error'])

export { selectStatus, selectLoading, selectError }
