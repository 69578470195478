import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import c from 'classnames'
import arrayMove from 'array-move'
import { createStructuredSelector } from 'reselect'

import ItemList from './ItemList'
import { ConfirmAlertBox } from '../../../../Containers/Library/Alerts'
import { LOP_CUSTOM_SECTIONS, SITE_TXT } from '../../../../Utils/constants'
import VideoModal from './VideoModal'
import NotesModal from '../../../Lop/ClientReferences/NotesModal'
import {
    sortTemplate4,
    deleteInTemplate4,
    addTemplate4Details,
    updateTemplate4Details,
    setElementToScroll,
    editMultipleVideos,
    updateMultipleVideos,
} from '../../../../Containers/LopContent/actions'
import { getElementIdToScroll } from '../../../../Containers/LopContent/selector'
import './index.scss'
import MultiVideoUpload from './MultiVideoModal'

class Template4 extends Component {
    constructor() {
        super()
        this.state = {
            type: null,
            selectedIndex: null,
            layoutId: null,
            contentTypeId: null,
            isText: 'False',
            modalOpen: false,
            multiModalOpen: false,
            openNotes: false,
            videoEditData: {},
            videoDetails: '',
            anchors: null,
            isHeaer: false,
            isTextArea: false,
            isMulti: false,
        }
        this.delayCopyTimer = null
    }

    componentDidMount() {
        const { elementIdToScroll, setElementToScroll } = this.props
        const elementId = document.getElementById(elementIdToScroll)
        if (elementIdToScroll && elementId) {
            elementId.scrollIntoView({ behavior: 'smooth' })
            setElementToScroll()
        }

        const anchors = document.querySelectorAll('a')
        this.setState({ anchors })
        if (anchors && anchors.length) {
            for (let i = 0; i < anchors.length; i++) {
                anchors[i].addEventListener('click', this.eventObj)
                anchors[i].addEventListener('touchstart', this.eventObj)
            }
        }
    }

    componentWillUnmount() {
        const { anchors } = this.state
        if (anchors && anchors.length) {
            for (let i = 0; i < anchors.length; i++) {
                anchors[i].removeEventListener('click', this.eventObj)
                anchors[i].removeEventListener('touchstart', this.eventObj)
            }
        }
    }
    eventObj = e => {
        const locationToSend = e.target.href
        if (locationToSend && locationToSend.includes('noredirect')) {
            e.preventDefault()
            const { sections, updateCurrentSlide, setElementToScroll } = this.props
            const locationArray = locationToSend.split('/')
            const sectionId = locationArray[locationArray.length - 2]
            const elementId = locationArray[locationArray.length - 1]
            const sectionToMove = sections.findIndex(x => x.sectionId === sectionId)
            if (sectionToMove) {
                updateCurrentSlide(sectionToMove)
                setElementToScroll(elementId)
            }
        }
    }

    addNewContent = type =>
        this.setState({
            type,
            selectedIndex: 0,
            isText: LOP_CUSTOM_SECTIONS[type][0].isText,
            contentTypeId: LOP_CUSTOM_SECTIONS[type][0].contentTypeId,
            layoutId: LOP_CUSTOM_SECTIONS[type][0].layoutId || null,
        })

    updateSelectedIndex = (selectedIndex, isText, layoutId, contentTypeId, isHeader, isTextArea, isMulti) =>
        this.setState({ selectedIndex, isText, layoutId, contentTypeId, isHeader, isTextArea, isMulti })

    deleteContent = (index, item) => {
        const { deleteInTemplate4 } = this.props
        const { TXT_CONFIRM_DELETE, TXT_CUSTOM_CONTENT_MESSAGE } = SITE_TXT
        ConfirmAlertBox(TXT_CONFIRM_DELETE, TXT_CUSTOM_CONTENT_MESSAGE, () => deleteInTemplate4(index, item))
    }

    createSharableLInk = urlLink => {
        const textField = document.createElement('textarea')
        textField.innerText = urlLink
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        this.setState({ showCopy: true })
        clearTimeout(this.delayCopyTimer)
        this.delayCopyTimer = setTimeout(() => {
            this.setState({ showCopy: false })
        }, 1200)
    }

    toggleModal = (modalOpen, videoEditData = {}, editSectionContentType = null) => {
        const { isText, layoutId, contentTypeId, isMulti } = this.state
        const { addTemplate4Details } = this.props
        if (contentTypeId === 1 || editSectionContentType === 1) {
            if (!modalOpen) {
                this.setState({
                    modalOpen,
                    type: null,
                    selectedIndex: null,
                    layoutId: null,
                    contentTypeId: null,
                    videoEditData,
                })
            } else if (isMulti) {
                this.setState({ multiModalOpen: modalOpen })
            } else {
                this.setState({ modalOpen, videoEditData })
            }
        } else {
            const images = []
            let imagesToAdd = parseInt(layoutId)
            if (imagesToAdd > 2 && imagesToAdd < 7) {
                imagesToAdd = imagesToAdd < 6 ? imagesToAdd : 2
                for (let i = 0; i <= imagesToAdd; i++) {
                    images.push({})
                }
            }

            if (imagesToAdd > 6) {
                images.push({})
            }
            const mainObject = layoutId
                ? { isText, contentTypeId, layoutId, images }
                : { isText, contentTypeId, images }
            addTemplate4Details(mainObject)
            this.setState({
                type: null,
                selectedIndex: null,
                layoutId: null,
                contentTypeId: null,
            })
        }
    }

    closeMultiModal = () => {
        this.setState({ multiModalOpen: false })
    }

    toggleNotesModal = (openNotes, videoDetails = '') => {
        this.setState({ openNotes, videoDetails })
    }

    render() {
        const {
            type,
            selectedIndex,
            modalOpen,
            isText,
            videoEditData,
            openNotes,
            videoDetails,
            contentTypeId,
            showCopy,
            multiModalOpen,
        } = this.state
        const {
            content,
            appWidth,
            sectionDetails,
            sortTemplate4,
            editMode,
            currentSlide,
            disableReorder,
            updateTemplate4Details,
            editMultipleVideos,
            updateMultipleVideos,
        } = this.props
        const { sectionContent = [] } = content
        const { TXT_COPIED, TXT_ADD_ELE, TXT_LAYOUT, TXT_NEXT } = SITE_TXT
        return (
            <div className="lopCustomSection">
                <div className={c('copiedClip', { showCopy })}>{TXT_COPIED}</div>
                <ItemList
                    data={sectionContent}
                    onSortEnd={({ oldIndex, newIndex }) => sortTemplate4(arrayMove(sectionContent, oldIndex, newIndex))}
                    axis="xy"
                    distance={1}
                    lockToContainerEdges
                    appWidth={appWidth}
                    editMode={editMode}
                    currentSlide={currentSlide}
                    disableReorder={disableReorder}
                    sectionDetails={sectionDetails}
                    deleteInTemplate={this.deleteContent}
                    createSharableLInk={this.createSharableLInk}
                    toggleNotesModal={this.toggleNotesModal}
                    updateTemplate={updateTemplate4Details}
                    toggleModal={this.toggleModal}
                    helperClass="movingIcon"
                    updateMultipleVideos={updateMultipleVideos}
                />
                {editMode && (
                    <div className="addCustomContent">
                        <h3>{TXT_ADD_ELE}</h3>
                        <section className="contentHeader">
                            {Object.keys(LOP_CUSTOM_SECTIONS).map(item => (
                                <div
                                    className={c({
                                        highlighted: type === item,
                                    })}
                                    onClick={() => this.addNewContent(item)}
                                    key={item}
                                >
                                    {item}
                                </div>
                            ))}
                        </section>

                        {type && (
                            <Fragment>
                                <h3>{TXT_LAYOUT}</h3>
                                <section className="contentItems">
                                    {LOP_CUSTOM_SECTIONS[type].map((item, i) => {
                                        const {
                                            Icon,
                                            DefaultIcon,
                                            content,
                                            isText,
                                            contentTypeId,
                                            isHeader,
                                            isTextArea,
                                            layoutId = null,
                                            isMulti = false,
                                        } = item
                                        return (
                                            <div
                                                className={c(`selectorType${contentTypeId}`, {
                                                    highlighted: selectedIndex === i,
                                                })}
                                                onClick={() =>
                                                    this.updateSelectedIndex(
                                                        i,
                                                        isText,
                                                        layoutId,
                                                        contentTypeId,
                                                        isHeader,
                                                        isTextArea,
                                                        isMulti
                                                    )
                                                }
                                                key={i}
                                            >
                                                {selectedIndex === i ? <Icon /> : <DefaultIcon />}
                                                <span className="label">{content}</span>
                                            </div>
                                        )
                                    })}
                                </section>
                                <div className="buttonContent">
                                    <span className="btn btn-outline-primary" onClick={() => this.toggleModal(true)}>
                                        {TXT_NEXT}
                                    </span>
                                </div>
                            </Fragment>
                        )}
                    </div>
                )}
                {modalOpen && (
                    <VideoModal
                        onClose={() => this.toggleModal(false, {}, 1)}
                        isText={isText}
                        contentTypeId={contentTypeId}
                        videoEditData={videoEditData}
                    />
                )}
                {openNotes && (
                    <NotesModal
                        openNotes={openNotes}
                        onNotesCloseModal={() => this.toggleNotesModal(false)}
                        notes={videoDetails}
                        heading="Details"
                    />
                )}

                {multiModalOpen && (
                    <MultiVideoUpload
                        onClose={() => this.closeMultiModal()}
                        isText={isText}
                        contentTypeId={contentTypeId}
                        videoEditData={videoEditData}
                        sectionDetails={sectionDetails}
                        editMultipleVideos={editMultipleVideos}
                        sectionId={sectionDetails.sectionId}
                        templateId={sectionDetails.templateId}
                    />
                )}
            </div>
        )
    }
}

Template4.propTypes = {
    content: PropTypes.instanceOf(Object).isRequired,
    sectionDetails: PropTypes.instanceOf(Object).isRequired,
    currentSlide: PropTypes.number.isRequired,
    appWidth: PropTypes.number.isRequired,
    elementIdToScroll: PropTypes.string.isRequired,
    editMode: PropTypes.bool.isRequired,
    disableReorder: PropTypes.bool.isRequired,
    sortTemplate4: PropTypes.func.isRequired,
    deleteInTemplate4: PropTypes.func.isRequired,
    updateTemplate4Details: PropTypes.func.isRequired,
    setElementToScroll: PropTypes.func.isRequired,
}

const stateToProps = createStructuredSelector({
    elementIdToScroll: getElementIdToScroll(),
})

const dispatchProps = {
    sortTemplate4,
    deleteInTemplate4,
    addTemplate4Details,
    updateTemplate4Details,
    setElementToScroll,
    editMultipleVideos,
    updateMultipleVideos,
}

export default connect(stateToProps, dispatchProps)(Template4)
