export const LIBRARY_ITEMS_GET = 'LIBRARY_ITEMS_GET'

export const LIBRARY_ITEM = 'LIBRARY_ITEM'

export const LIBRARY_DOCUMENTS = 'LIBRARY_DOCUMENTS'

export const LIBRARY_DOCUMENTS_MORE = 'LIBRARY_DOCUMENTS_MORE'

export const LIBRARY_ITEM_ADD = 'LIBRARY_ITEM_ADD'

export const LIBRARY_ITEM_EDIT = 'LIBRARY_ITEM_EDIT'

export const LIBRARY_ITEM_TRASH = 'LIBRARY_ITEM_TRASH'

export const LIBRARY_PHASE_ADD = 'LIBRARY_PHASE_ADD'

export const LIBRARY_PHASE_EDIT = 'LIBRARY_PHASE_EDIT'

export const LIBRARY_PHASE_TRASH = 'LIBRARY_PHASE_TRASH'

export const LIBRARY_ITEM_REORDER = 'LIBRARY_ITEM_REORDER'

export const LIBRARY_DOWNLOAD_DOCUMENTS = 'LIBRARY_DOWNLOAD_DOCUMENTS'
export const SAVE_LIBRARY_DOWNLOAD_DOCUMENTS = 'SAVE_LIBRARY_DOWNLOAD_DOCUMENTS'

export const LIBRARY_EMAIL_DOCUMENTS = 'LIBRARY_EMAIL_DOCUMENTS'

export const LIBRARY_OWNER_GET = 'LIBRARY_OWNER_GET'
export const OWNER_LOCATION_GET = 'OWNER_LOCATION_GET'
export const LIBRARY_OWNER_ADD = 'LIBRARY_OWNER_ADD'
export const TOGGLE_LIST_UPDATED_FLAG = 'TOGGLE_LIST_UPDATED_FLAG'
export const UPDATE_OWNER = 'UPDATE_OWNER'
export const REMOVE_OWNER = 'REMOVE_OWNER'

export const SHARE_DOCUMENTS_POST = 'SHARE_DOCUMENTS_POST'
export const SHARE_DOCUMENTS_GET = 'SHARE_DOCUMENTS_GET'
export const SHARE_DOCUMENT_POST = 'SHARE_DOCUMENT_POST'

export const MULTIPLE_SHARE_DOCS = 'MULTIPLE_SHARE_DOCS'
