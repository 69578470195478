import React, { Component } from 'react'
import './admin.css'
import { SITE_TXT } from '../../Utils/constants'
import ActiveUsersModal from './ActiveUsersModal'
class AdminDashboardCards extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showModal: false,
        }
    }

    openActiveUserModal = () => {
        this.props.getActiveUsers('')
        this.setState({ showModal: true })
    }

    closeActiveUserModal = () => {
        this.setState({ showModal: false })
    }

    render() {
        const { showModal } = this.state
        const {
            TXT_TODAYS_ACTIVITY,
            TXT_TOTAL_NO_OF_USERS,
            TXT_NUMBER_OF_ADMIN_USERS,
            TXT_NEW_USERS,
            TXT_THIS_MONTH,
            TXT_THIS_YEAR,
            TXT_NUMBER_OF_ACTIVE_USERS,
        } = SITE_TXT
        return (
            <div>
                <div className="lower-heading-admin"> {TXT_TODAYS_ACTIVITY}</div>
                <div className="blue-bar-admin" />
                <div className="card-container">
                    <div className="admin-dashboardcard">
                        <div className="card-headings">{TXT_TOTAL_NO_OF_USERS}</div>
                        <div className="total-user-count total">{this.props.totalUsers}</div>
                    </div>
                    <div className="admin-dashboardcard">
                        <div className="card-headings">{TXT_NEW_USERS}</div>
                        <div className="total-user-count new">
                            {this.props.newUsers} <span>{TXT_THIS_MONTH}</span>
                        </div>
                    </div>
                    <div className="admin-dashboardcard">
                        <div className="card-headings">{TXT_NUMBER_OF_ADMIN_USERS}</div>
                        <div className="total-user-count total">
                            {this.props.adminUsers} <span>{TXT_THIS_YEAR}</span>
                        </div>
                    </div>
                    <div className="admin-dashboardcard">
                        <div className="card-headings d-flex">
                            <div className="visitors">{TXT_NUMBER_OF_ACTIVE_USERS}</div>
                            {/* <div className="cursor" onClick={this.openActiveUserModal}>
                                <ForwardArrow />
                            </div> */}
                        </div>
                        <div className="total-user-count total">{this.props.adminUsers}</div>
                    </div>
                </div>

                {showModal && (
                    <ActiveUsersModal
                        onClose={this.closeActiveUserModal}
                        showModal={showModal}
                        activeUsers={this.props.activeUsers}
                        userTypeData={this.props.userTypeData}
                        fetchUserType={this.props.fetchUserType}
                        getActiveUsers={this.props.getActiveUsers}
                    />
                )}
            </div>
        )
    }
}

export default AdminDashboardCards
