import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { GetRole } from '../../Components'
import { selectUserStatus, selectNewRole } from './selector'
import { role } from './actions'

const mapStateToProps = createStructuredSelector({
    status: selectUserStatus(),
    newRole: selectNewRole(),
})

const mapDispatchToProps = {
    role,
}
export default connect(mapStateToProps, mapDispatchToProps)(GetRole)
