import React, { Component } from 'react'
import Modal from 'react-responsive-modal'
import ActiveUserTable from './ActiveUserTable'
import ExportTrends from './ExportTrends'
import Dropdown from '../Dropdown'
import { SITE_TXT } from '../../Utils/constants'
class ActiveUsersModal extends Component {
    state = {
        open: this.props.showModal,
        selectedTab: 1,
        selectedTime: 1,
        selectedTrend: 'users',
        userType: '',
    }
    onCloseModal = () => {
        this.props.onClose()
    }

    selectTab = tab => {
        this.setState({ selectedTab: tab, selectedTime: 1 })
    }
    selectTime = time => {
        this.setState({ selectedTime: time })
    }
    selectTrend = trend => {
        const _this = this
        this.setState({ selectedTrend: trend }, () => {
            if (_this.state.selectedTrend === 'pages' && !this.props.userTypeData) {
                this.props.fetchUserType()
            }
        })
    }

    handleChange = (field, value) => {
        const data = { userType: value }
        this.setState({ userType: value })
        this.props.getActiveUsers(data)
    }
    render() {
        const { open, selectedTab, selectedTime, selectedTrend, userType } = this.state
        const { TXT_USER_TYPE, TXT_SELECT_USER_TYPE } = SITE_TXT
        const userOptions = this.props.userTypeData
        return (
            <div>
                <Modal
                    classNames={{
                        modal: 'active-user-modal',
                    }}
                    open={open}
                    onClose={this.onCloseModal}
                    center
                >
                    <div className="active-user-heading">Active Users</div>
                    <div className="tab-name d-flex">
                        <div className="cursor" onClick={() => this.selectTab(1)}>
                            <div className={`${selectedTab === 1 ? 'selected' : ''}`}>User View</div>
                            {selectedTab === 1 && <div className="bottom-line" />}
                        </div>
                        <div className="cursor" onClick={() => this.selectTab(2)}>
                            <div className={`${selectedTab === 2 ? 'selected' : ''}`}>Page View</div>
                            {selectedTab === 2 && <div className="bottom-line" />}
                        </div>
                        <div className="cursor" onClick={() => this.selectTab(3)}>
                            <div className={`${selectedTab === 3 ? 'selected' : ''}`}>Trends</div>
                            {selectedTab === 3 && <div className="bottom-line" />}
                        </div>
                    </div>
                    {selectedTab !== 3 && (
                        <div className="d-flex" style={{ width: '100%' }}>
                            <div className="toggle">
                                <button
                                    type="button"
                                    className={`btn btn-left ${selectedTime === 1 ? 'selected-time' : ''}`}
                                    onClick={() => this.selectTime(1)}
                                >
                                    Monthly
                                </button>
                                <button
                                    type="button"
                                    className={`btn btn-right ${selectedTime === 2 ? 'selected-time' : ''}`}
                                    onClick={() => this.selectTime(2)}
                                >
                                    YTD
                                </button>
                            </div>
                        </div>
                    )}

                    {selectedTab === 3 && (
                        <div className="d-flex" style={{ width: '100%' }}>
                            <div className="toggle">
                                <button
                                    type="button"
                                    className={`btn btn-left ${selectedTrend === 'users' ? 'selected-time' : ''}`}
                                    onClick={() => this.selectTrend('users')}
                                >
                                    User
                                </button>
                                <button
                                    type="button"
                                    className={`btn btn-center ${selectedTrend === 'pages' ? 'selected-time' : ''}`}
                                    onClick={() => this.selectTrend('pages')}
                                >
                                    Pages
                                </button>
                                <button
                                    type="button"
                                    className={`btn btn-right ${selectedTrend === 'downloads' ? 'selected-time' : ''}`}
                                    onClick={() => this.selectTrend('downloads')}
                                >
                                    Download
                                </button>
                            </div>
                            <div className="dd-user">
                                {selectedTrend === 'pages' && (
                                    <Dropdown
                                        label={TXT_USER_TYPE}
                                        value={userType || ''}
                                        fieldFor="userType"
                                        onChange={(field, value) => this.handleChange(field, value)}
                                        customClass="select-validation"
                                        defaultVal={TXT_SELECT_USER_TYPE}
                                        options={userOptions}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                    <ActiveUserTable
                        activeUsers={this.props.activeUsers}
                        selectedTab={selectedTab}
                        selectedTime={selectedTime}
                        selectedTrend={selectedTrend}
                    />

                    {selectedTab === 3 && <ExportTrends activeUsers={this.props.activeUsers} />}
                </Modal>
            </div>
        )
    }
}

export default ActiveUsersModal
