import { fromJS } from 'immutable'
import { USER_ROLE_GET } from './constant'

export const INITIAL_STATE = fromJS({
    status: null,
    isLoading: true,
    newRole: null,
})

function roleReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case `${USER_ROLE_GET}_PENDING`:
            return state
                .set('isLoading', true)
                .set('status', null)
                .set('newRole', null)
        case `${USER_ROLE_GET}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('newRole', action.data.responseData.data)
        case `${USER_ROLE_GET}_FAILED`:
            return state
                .set('isLoading', false)
                .set('status', 'failure')
                .set('newRole', null)

        default:
            return state
    }
}

export default roleReducer
