import { call, put, takeLatest, all } from 'redux-saga/effects'
import { getLibrarySearch, getLibrarySearchResult, getLibraryRecentSearchResult } from '../../Utils/apis'
import { SEARCH, SEARCH_FETCH, SEARCH_LOAD_MORE, SEARCH_RECENTLY_SEARCHED_DOCS } from './constant'
import {
    searchSuccessAction,
    searchFailedAction,
    searchFetchFailedAction,
    searchFetchSuccessAction,
    searchLoadMoreSuccessAction,
    searchLoadMoreFailedAction,
    recentlySearchedSuccess,
    recentlySearchedFailed,
} from './actions'

export function* searchFetch({ data }) {
    const response = yield call(getLibrarySearch, data)
    const responseData = response.data
    if (responseData.status) {
        yield put(searchFetchSuccessAction({ ...responseData, module: data }))
    } else {
        yield put(searchFetchFailedAction({ responseData }))
    }
}

export function* search({ data }) {
    const response = yield call(getLibrarySearchResult, data)
    const responseData = response.data
    if (responseData.status) {
        yield put(searchSuccessAction({ ...responseData }))
    } else {
        yield put(searchFailedAction({ responseData }))
    }
}

export function* searchLoadMore({ data }) {
    const response = yield call(getLibrarySearchResult, data)
    const responseData = response.data
    if (responseData.status) {
        yield put(searchLoadMoreSuccessAction({ ...responseData }))
    } else {
        yield put(searchLoadMoreFailedAction({ responseData }))
    }
}

export function* recentlySearchedDocs() {
    const response = yield call(getLibraryRecentSearchResult)
    const responseData = response.data
    if (responseData.status) {
        yield put(recentlySearchedSuccess({ ...responseData }))
    } else {
        yield put(recentlySearchedFailed({ responseData }))
    }
}
export function* searchSaga() {
    yield all([
        yield takeLatest(`${SEARCH}_PENDING`, search),
        yield takeLatest(`${SEARCH_FETCH}_PENDING`, searchFetch),
        yield takeLatest(`${SEARCH_LOAD_MORE}_PENDING`, searchLoadMore),
        yield takeLatest(`${SEARCH_RECENTLY_SEARCHED_DOCS}_PENDING`, recentlySearchedDocs),
    ])
}

export default [searchSaga]
