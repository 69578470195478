import { fromJS } from 'immutable'
import {
    LOP_GETSTARTED_ITEMS_GET,
    LOP_GETSTARTED_ITEMS_GET_MORE,
    LOP_GETSTARTED_ITEM_DELETE,
    LOP_ADD_ITEM,
    LOP_EDIT_ITEM,
    LOP_ADD_FOLDER,
    LOP_EDIT_FOLDER,
    LOP_FETCH_FOLDER_LIST,
    LOP_GOLD_ITEMS_GET,
    LOP_GOLD_ITEMS_MORE,
    LOP_GOLD_DELETE_ITEM,
    LOP_GOLD_DELETE_FOLDER,
    LOP_GOLD_EMAIL_ITEMS,
    LOP_GOLD_DOWNLOAD_ITEMS,
    LOP_FETCH_INDUSTRY_TAGS,
    LOP_FETCH_TOPICS_KEYWORDS_TAGS,
    LOP_GEOGRAPHY,
    LOP_DEALTYPE,
    LOP_DEALSIZE,
    LOP_CASE_STUDIES_ITEMS,
    LOP_CASE_STUDIES_ITEMS_MORE,
    LOP_CASE_STUDIES_ADD,
    LOP_CASE_STUDIES_EDIT_GET,
    LOP_CASE_STUDIES_EDIT_PUT,
    LOP_EXPERT_ITEMS_GET,
    LOP_EXPERT_ITEMS_MORE,
    LOP_CLIENTREF_ITEMS_GET,
    LOP_CLIENTREF_ITEMS_MORE,
    LOP_EXPERT_CV_ADD,
    LOP_EXPERT_CV_EDIT,
    LOP_EXPERT_CV_FETCH,
    LOP_REGION_BASE,
    LOP_FETCH_EXPERT_INDUSTRY_TAGS,
    LOP_FETCH_EXPERT_ROLE_TAGS,
    LOP_FETCH_EXPERT_TOPICS_TAGS,
    LOP_CLEAR_FETCHED_DATA,
    LOP_FETCH_COUNTRY_CODE,
    LOP_ADD_CLIENT,
    LOP_EDIT_CLIENT_FETCH,
    LOP_EDIT_CLIENT_PUT,
    LOP_EMPTY_CLIENT_DATA,
    CREATE_LOP,
} from './constant'
import { STATUS_FAILED } from '../../Utils/constants'

const INITIAL_STATE = fromJS({
    status: null,
    isLoading: false,
    documentsData: null,
    isGoldLoading: false,
    data: null,
    error: null,
    isListUpdated: false,
    addEditStatus: null,
    foldersList: null,
    industryTags: null,
    topicsKeywordsTags: null,
    geographyData: null,
    dealSizeData: null,
    dealTypeData: null,
    editItemData: null,
    expertData: null,
    fetchedCv: null,
    selectLopRegionBase: null,
    selectExpIndustryTags: null,
    selectExpRoleTags: null,
    selectExpTopicsTags: null,
    isLoadingFetch: false,
    countryCode: null,
    uploadLopStatus: null,
    isLopAdded: null,
})

function lopReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case `${LOP_GETSTARTED_ITEMS_GET}_PENDING`:
            return state
                .set('isLoading', true)
                .set('status', null)
                .set('data', null)
                .set('isListUpdated', false)
        case `${LOP_GETSTARTED_ITEMS_GET}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('data', action.data.responseData.result)
                .set('status', action.data.responseData.status)
                .set('isListUpdated', false)
        case `${LOP_GETSTARTED_ITEMS_GET}_FAILED`:
            return state
                .set('isLoading', false)
                .set('status', null)
                .set('error', null)
                .set('isListUpdated', false)
        case `${LOP_GETSTARTED_ITEMS_GET_MORE}_PENDING`:
            return state.set('isLoading', true).set('status', null)

        case `${LOP_GETSTARTED_ITEMS_GET_MORE}_SUCCESS`:
            const arr = state.get('data')
            arr.data.push(...action.data.responseData.result.data)
            return state
                .set('isLoading', false)
                .set('documentsData', arr)
                .set('status', action.data.responseData.status)
                .set('isListUpdated', false)
        case `${LOP_GETSTARTED_ITEMS_GET_MORE}_FAILED`:
            return state
                .set('isLoading', false)
                .set('status', null)
                .set('error', null)
                .set('isListUpdated', false)

        case `${LOP_GETSTARTED_ITEM_DELETE}_PENDING`:
            return state
                .set('isLoading', true)
                .set('status', null)
                .set('isListUpdated', false)

        case `${LOP_GETSTARTED_ITEM_DELETE}_SUCCESS`:
            const { responseData } = action.data
            const { status } = responseData
            return state
                .set('isLoading', false)
                .set('status', status)
                .set('documentsData', {})
                .set('isListUpdated', true)
        case `${LOP_GETSTARTED_ITEM_DELETE}_FAILED`:
            return state
                .set('isLoading', false)
                .set('error', STATUS_FAILED)
                .set('isListUpdated', false)

        case `${LOP_ADD_ITEM}_PENDING`:
            return state
                .set('status', null)
                .set('addEditStatus', null)
                .set('isListUpdated', false)
        case `${LOP_ADD_ITEM}_SUCCESS`: {
            return state
                .set('isLoading', false)
                .set('isListUpdated', true)
                .set('addEditStatus', action.data.responseData)
        }
        case `${LOP_ADD_ITEM}_FAILED`:
            return state.set('error', action.data.responseData).set('isListUpdated', false)
        case `${LOP_EDIT_ITEM}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)
                .set('isListUpdated', false)
                .set('addEditStatus', null)

        case `${LOP_EDIT_ITEM}_SUCCESS`:
            return state
                .set('status', action.data.responseData.status)
                .set('isLoading', false)
                .set('addEditStatus', action.data.responseData)
                .set('isListUpdated', true)

        case `${LOP_EDIT_ITEM}_FAILED`:
            return state
                .set('error', action.data.responseData.status)
                .set('isLoading', false)
                .set('addEditStatus', null)
                .set('isListUpdated', false)
        case `${LOP_ADD_FOLDER}_PENDING`:
            return state
                .set('status', null)
                .set('isLoading', true)
                .set('addEditStatus', null)
                .set('isListUpdated', false)

        case `${LOP_ADD_FOLDER}_SUCCESS`: {
            return state
                .set('status', action.data.responseData.status)
                .set('isLoading', false)
                .set('addEditStatus', action.data.responseData)
                .set('isListUpdated', true)
        }

        case `${LOP_ADD_FOLDER}_FAILED`:
            return state
                .set('error', action.data.responseData.status)
                .set('isLoading', false)
                .set('addEditStatus', null)
                .set('isListUpdated', false)
        case `${LOP_EDIT_FOLDER}_PENDING`:
            return state
                .set('status', null)
                .set('isLoading', true)
                .set('addEditStatus', null)
                .set('isListUpdated', false)
        case `${LOP_EDIT_FOLDER}_SUCCESS`:
            return state
                .set('status', action.data.responseData.status)
                .set('isLoading', false)
                .set('addEditStatus', action.data.responseData)
                .set('isListUpdated', true)

        case `${LOP_EDIT_FOLDER}_FAILED`:
            return state
                .set('error', action.data.responseData.status)
                .set('isLoading', false)
                .set('addEditStatus', null)
                .set('isListUpdated', false)
        case `${LOP_FETCH_FOLDER_LIST}_PENDING`:
            return state
                .set('status', null)
                .set('isLoading', true)
                .set('foldersList', null)
                .set('isListUpdated', false)

        case `${LOP_FETCH_FOLDER_LIST}_SUCCESS`:
            return state
                .set('status', action.data.responseData.status)
                .set('isLoading', false)
                .set('foldersList', action.data.responseData.result.data)
                .set('isListUpdated', false)
        case `${LOP_FETCH_FOLDER_LIST}_FAILED`:
            return state
                .set('error', action.data.responseData.status)
                .set('isLoading', false)
                .set('foldersList', null)
                .set('isListUpdated', false)
        case `${LOP_GOLD_ITEMS_GET}_PENDING`:
            return state
                .set('isLoading', true)
                .set('status', null)
                .set('documentsData', null)
                .set('isListUpdated', false)
        case `${LOP_GOLD_ITEMS_GET}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('documentsData', action.data.responseData.result)
                .set('isListUpdated', false)

        case `${LOP_GOLD_ITEMS_GET}_FAILED`:
            return state
                .set('isLoading', false)
                .set('error', STATUS_FAILED)
                .set('isListUpdated', false)

        case `${LOP_GOLD_ITEMS_MORE}_PENDING`:
            return state
                .set('isLoading', true)
                .set('status', null)
                .set('isListUpdated', false)
        case `${LOP_GOLD_ITEMS_MORE}_SUCCESS`: {
            const docsData = state.get('documentsData')
            docsData.data.push(...action.data.responseData.result.data)
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('documentsData', docsData)
                .set('isListUpdated', false)
        }
        case `${LOP_GOLD_ITEMS_MORE}_FAILED`:
            return state
                .set('isLoading', false)
                .set('error', STATUS_FAILED)
                .set('isListUpdated', false)

        case `${LOP_GOLD_DELETE_ITEM}_PENDING`:
            return state
                .set('isLoading', true)
                .set('status', null)
                .set('isListUpdated', false)

        case `${LOP_GOLD_DELETE_ITEM}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('status', status)
                .set('isListUpdated', true)

        case `${LOP_GOLD_DELETE_ITEM}_FAILED`:
            return state
                .set('isLoading', false)
                .set('error', STATUS_FAILED)
                .set('isListUpdated', false)

        case `${LOP_GOLD_DELETE_FOLDER}_PENDING`:
            return state
                .set('isLoading', true)
                .set('status', null)
                .set('isListUpdated', false)

        case `${LOP_GOLD_DELETE_FOLDER}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('status', status)
                .set('isListUpdated', true)
        case `${LOP_GOLD_DELETE_FOLDER}_FAILED`:
            return state
                .set('isLoading', false)
                .set('error', STATUS_FAILED)
                .set('isListUpdated', false)

        case `${LOP_GOLD_EMAIL_ITEMS}_PENDING`:
            return state
                .set('isLoading', true)
                .set('status', null)
                .set('emailDocumentsData', false)

        case `${LOP_GOLD_EMAIL_ITEMS}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('status', status)
                .set('emailDocumentsData', action.data.responseData.status)
        case `${LOP_GOLD_EMAIL_ITEMS}_FAILED`:
            return state
                .set('isLoading', false)
                .set('error', STATUS_FAILED)
                .set('emailDocumentsData', false)

        case `${LOP_GOLD_DOWNLOAD_ITEMS}_PENDING`:
            return state
                .set('isLoading', true)
                .set('status', null)
                .set('downloadDocumentsData', false)

        case `${LOP_GOLD_DOWNLOAD_ITEMS}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('downloadDocumentsData', action.data.responseData)
        case `${LOP_GOLD_DOWNLOAD_ITEMS}_FAILED`:
            return state
                .set('isLoading', false)
                .set('error', STATUS_FAILED)
                .set('downloadDocumentsData', false)

        case `${LOP_GEOGRAPHY}_PENDING`:
            return state
                .set('isLoading', true)
                .set('status', null)
                .set('geographyData', null)

        case `${LOP_GEOGRAPHY}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('geographyData', action.data.responseData.result.data)

        case `${LOP_GEOGRAPHY}_FAILED`:
            return state
                .set('isLoading', false)
                .set('error', STATUS_FAILED)
                .set('geographyData', null)

        case `${LOP_DEALTYPE}_PENDING`:
            return state.set('isLoading', true).set('status', null)

        case `${LOP_DEALTYPE}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('dealTypeData', action.data.responseData.result.data)

        case `${LOP_DEALTYPE}_FAILED`:
            return state.set('isLoading', false).set('error', STATUS_FAILED)

        case `${LOP_DEALSIZE}_PENDING`:
            return state.set('isLoading', true).set('status', null)

        case `${LOP_DEALSIZE}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('dealSizeData', action.data.responseData.result.data)

        case `${LOP_DEALSIZE}_FAILED`:
            return state.set('isLoading', false).set('error', STATUS_FAILED)
        case `${LOP_FETCH_INDUSTRY_TAGS}_PENDING`:
            return state.set('isLoading', true).set('status', null)

        case `${LOP_FETCH_INDUSTRY_TAGS}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('industryTags', action.data.responseData.result.data)
        case `${LOP_FETCH_INDUSTRY_TAGS}_FAILED`:
            return state.set('isLoading', false).set('error', STATUS_FAILED)
        case `${LOP_FETCH_TOPICS_KEYWORDS_TAGS}_PENDING`:
            return state.set('isLoading', true).set('status', null)

        case `${LOP_FETCH_TOPICS_KEYWORDS_TAGS}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('topicsKeywordsTags', action.data.responseData.result.data)
        case `${LOP_FETCH_TOPICS_KEYWORDS_TAGS}_FAILED`:
            return state.set('isLoading', false).set('error', STATUS_FAILED)

        case `${LOP_EXPERT_ITEMS_GET}_PENDING`:
            return state
                .set('isLoading', true)
                .set('status', null)
                .set('expertData', null)
                .set('isListUpdated', false)
        case `${LOP_EXPERT_ITEMS_GET}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('expertData', action.data.responseData.result)
                .set('isListUpdated', false)

        case `${LOP_EXPERT_ITEMS_GET}_FAILED`:
            return state
                .set('isLoading', true)
                .set('status', null)
                .set('expertData', null)
                .set('isListUpdated', false)

        case `${LOP_CASE_STUDIES_ITEMS}_PENDING`:
            return state
                .set('isLoading', true)
                .set('status', null)
                .set('data', null)

                .set('isListUpdated', false)

        case `${LOP_CASE_STUDIES_ITEMS}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('data', action.data.responseData.result)
                .set('isListUpdated', false)
        case `${LOP_CASE_STUDIES_ITEMS}_FAILED`:
            return state
                .set('isLoading', false)
                .set('error', STATUS_FAILED)
                .set('data', null)
                .set('isListUpdated', false)

        case `${LOP_CASE_STUDIES_ITEMS_MORE}_PENDING`:
            return state
                .set('isLoading', true)
                .set('status', null)
                .set('isListUpdated', false)

        case `${LOP_CASE_STUDIES_ITEMS_MORE}_SUCCESS`:
            const data = state.get('data')
            data.data.push(...action.data.responseData.result.data)
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('data', data)
                .set('isListUpdated', false)

        case `${LOP_CASE_STUDIES_ITEMS_MORE}_FAILED`:
            return state
                .set('isLoading', false)
                .set('error', STATUS_FAILED)
                .set('isListUpdated', false)

        case `${LOP_CASE_STUDIES_ADD}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('addEditStatus', null)
                .set('isListUpdated', false)
        case `${LOP_CASE_STUDIES_ADD}_SUCCESS`:
            return state
                .set('data', data)
                .set('isLoading', false)
                .set('isListUpdated', true)
                .set('addEditStatus', action.data.responseData)

        case `${LOP_CASE_STUDIES_ADD}_FAILED`:
            return state
                .set('status', null)
                .set('error', action.data.responseData)
                .set('addEditStatus', null)
                .set('isListUpdated', false)

        case `${LOP_CASE_STUDIES_EDIT_GET}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)
                .set('addEditStatus', null)
                .set('editItemData', null)

        case `${LOP_CASE_STUDIES_EDIT_GET}_SUCCESS`:
            return state
                .set('status', action.data.responseData.status)
                .set('isLoading', false)
                .set('addEditStatus', action.data.responseData)
                .set('editItemData', action.data.responseData.result.data)

        case `${LOP_CASE_STUDIES_EDIT_GET}_FAILED`:
            return state
                .set('error', action.data.responseData.status)
                .set('isLoading', false)
                .set('addEditStatus', null)
                .set('editItemData', null)

        case `${LOP_CASE_STUDIES_EDIT_PUT}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)
                .set('isListUpdated', false)
                .set('addEditStatus', null)

        case `${LOP_CASE_STUDIES_EDIT_PUT}_SUCCESS`:
            return state
                .set('status', action.data.responseData.status)
                .set('isLoading', false)
                .set('addEditStatus', action.data.responseData)
                .set('isListUpdated', true)

        case `${LOP_CASE_STUDIES_EDIT_PUT}_FAILED`:
            return state
                .set('error', action.data.responseData.status)
                .set('isLoading', false)
                .set('addEditStatus', null)
                .set('isListUpdated', false)

        case `${LOP_EXPERT_ITEMS_MORE}_PENDING`:
            return state
                .set('isLoading', true)
                .set('status', null)
                .set('isListUpdated', false)

        case `${LOP_EXPERT_ITEMS_MORE}_SUCCESS`: {
            const expData = state.get('expertData')
            expData.data.push(...action.data.responseData.result.data)
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('expertData', expData)
                .set('isListUpdated', false)
        }
        case `${LOP_EXPERT_ITEMS_MORE}_FAILED`:
            return state
                .set('isLoading', false)
                .set('error', STATUS_FAILED)
                .set('isListUpdated', false)

        case `${LOP_CLIENTREF_ITEMS_GET}_PENDING`:
            return state
                .set('isLoading', true)
                .set('status', null)
                .set('data', null)

                .set('isListUpdated', false)

        case `${LOP_CLIENTREF_ITEMS_GET}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('data', action.data.responseData.result)
                .set('isListUpdated', false)
        case `${LOP_CLIENTREF_ITEMS_GET}_FAILED`:
            return state
                .set('isLoading', false)
                .set('error', STATUS_FAILED)
                .set('data', null)
                .set('isListUpdated', false)

        case `${LOP_CLIENTREF_ITEMS_MORE}_PENDING`:
            return state
                .set('isLoading', true)
                .set('status', null)
                .set('isListUpdated', false)

        case `${LOP_CLIENTREF_ITEMS_MORE}_SUCCESS`:
            const clientRefData = state.get('data')
            clientRefData.data.push(...action.data.responseData.result.data)
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('data', clientRefData)
                .set('isListUpdated', false)

        case `${LOP_CLIENTREF_ITEMS_MORE}_FAILED`:
            return state
                .set('isLoading', false)
                .set('error', STATUS_FAILED)
                .set('isListUpdated', false)

        case `${LOP_EXPERT_CV_ADD}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('addEditStatus', null)
                .set('isListUpdated', false)

        case `${LOP_EXPERT_CV_ADD}_SUCCESS`:
            return state
                .set('data', data)
                .set('isLoading', false)
                .set('isListUpdated', true)
                .set('addEditStatus', action.data.responseData)

        case `${LOP_EXPERT_CV_ADD}_FAILED`:
            return state
                .set('status', null)
                .set('error', action.data.responseData)
                .set('addEditStatus', null)
                .set('isListUpdated', false)

        case `${LOP_EXPERT_CV_EDIT}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)
                .set('isListUpdated', false)
                .set('addEditStatus', null)

        case `${LOP_EXPERT_CV_EDIT}_SUCCESS`:
            return state
                .set('status', action.data.responseData.status)
                .set('isLoading', false)
                .set('addEditStatus', action.data.responseData)
                .set('isListUpdated', true)

        case `${LOP_EXPERT_CV_EDIT}_FAILED`:
            return state
                .set('error', action.data.responseData.status)
                .set('isLoading', false)
                .set('addEditStatus', null)
                .set('isListUpdated', false)

        case `${LOP_EXPERT_CV_FETCH}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('isLoading', true)
                .set('addEditStatus', null)
                .set('fetchedCv', null)

        case `${LOP_EXPERT_CV_FETCH}_SUCCESS`:
            return state
                .set('status', action.data.responseData.status)
                .set('isLoading', false)
                .set('addEditStatus', action.data.responseData)
                .set('fetchedCv', action.data.responseData.result.data)

        case `${LOP_EXPERT_CV_FETCH}_FAILED`:
            return state
                .set('error', action.data.responseData.status)
                .set('isLoading', false)
                .set('addEditStatus', null)
                .set('fetchedCv', null)

        case `${LOP_REGION_BASE}_PENDING`:
            return state.set('isLoading', true).set('status', null)

        case `${LOP_REGION_BASE}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('selectLopRegionBase', action.data.responseData.result.data)

        case `${LOP_REGION_BASE}_FAILED`:
            return state.set('isLoading', false).set('error', STATUS_FAILED)

        case `${LOP_FETCH_EXPERT_INDUSTRY_TAGS}_PENDING`:
            return state
                .set('isLoading', true)
                .set('status', null)
                .set('isLoadingFetch', true)

        case `${LOP_FETCH_EXPERT_INDUSTRY_TAGS}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('isLoadingFetch', false)
                .set('status', action.data.responseData.status)
                .set('selectExpIndustryTags', action.data.responseData.result.data)
        case `${LOP_FETCH_EXPERT_INDUSTRY_TAGS}_FAILED`:
            return state
                .set('isLoading', false)
                .set('error', STATUS_FAILED)
                .set('isLoadingFetch', false)

        case `${LOP_FETCH_EXPERT_ROLE_TAGS}_PENDING`:
            return state.set('isLoading', true).set('status', null)

        case `${LOP_FETCH_EXPERT_ROLE_TAGS}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('selectExpRoleTags', action.data.responseData.result.data)
        case `${LOP_FETCH_EXPERT_ROLE_TAGS}_FAILED`:
            return state.set('isLoading', false).set('error', STATUS_FAILED)

        case `${LOP_FETCH_EXPERT_TOPICS_TAGS}_PENDING`:
            return state.set('isLoading', true).set('status', null)

        case `${LOP_FETCH_EXPERT_TOPICS_TAGS}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('selectExpTopicsTags', action.data.responseData.result.data)
        case `${LOP_FETCH_EXPERT_TOPICS_TAGS}_FAILED`:
            return state.set('isLoading', false).set('error', STATUS_FAILED)

        case `${LOP_CLEAR_FETCHED_DATA}_PENDING`:
            return state.set('isLoading', true).set('status', null)

        case `${LOP_CLEAR_FETCHED_DATA}_SUCCESS`:
            return state
                .set('status', 'success')
                .set('isLoading', false)
                .set('fetchedCv', null)

        case `${LOP_CLEAR_FETCHED_DATA}_FAILED`:
            return state.set('isLoading', false).set('error', STATUS_FAILED)

        case `${LOP_FETCH_COUNTRY_CODE}_PENDING`:
            return state.set('isLoading', true).set('status', null)

        case `${LOP_FETCH_COUNTRY_CODE}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('status', action.data.responseData.status)
                .set('countryCode', action.data.responseData.result.data)
        case `${LOP_FETCH_COUNTRY_CODE}_FAILED`:
            return state.set('isLoading', false).set('error', STATUS_FAILED)

        case `${LOP_ADD_CLIENT}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('addEditStatus', null)
                .set('isListUpdated', false)

        case `${LOP_ADD_CLIENT}_SUCCESS`:
            return state
                .set('data', data)
                .set('isLoading', false)
                .set('isListUpdated', true)
                .set('addEditStatus', action.data.responseData)

        case `${LOP_ADD_CLIENT}_FAILED`:
            return state
                .set('status', null)
                .set('error', action.data.responseData)
                .set('addEditStatus', null)
                .set('isListUpdated', false)

        case `${LOP_EDIT_CLIENT_FETCH}_PENDING`:
            return state
                .set('status', null)
                .set('error', null)
                .set('addEditStatus', null)

        case `${LOP_EDIT_CLIENT_FETCH}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('addEditStatus', action.data.responseData)
                .set('status', action.data.responseData.status)

        case `${LOP_EDIT_CLIENT_FETCH}_FAILED`:
            return state
                .set('status', null)
                .set('error', action.data.responseData)
                .set('addEditStatus', null)

        case `${LOP_EDIT_CLIENT_PUT}_PENDING`:
            return state
                .set('status', null)
                .set('isListUpdated', false)
                .set('addEditStatus', null)

        case `${LOP_EDIT_CLIENT_PUT}_SUCCESS`:
            return state
                .set('isLoading', false)
                .set('isListUpdated', true)
                .set('status', action.data.responseData.status)
                .set('addEditStatus', action.data.responseData)

        case `${LOP_EDIT_CLIENT_PUT}_FAILED`:
            return state
                .set('status', null)
                .set('error', action.data.responseData)
                .set('isListUpdated', false)
                .set('addEditStatus', null)

        case `${LOP_EMPTY_CLIENT_DATA}`:
            return state.set('addEditStatus', null).set('isLoading', false)

        case `${CREATE_LOP}_PENDING`:
            return state
                .set('status', null)
                .set('uploadLopStatus', null)
                .set('isLopAdded', false)
        case `${CREATE_LOP}_SUCCESS`: {
            return state
                .set('isLoading', false)
                .set('isLopAdded', true)
                .set('uploadLopStatus', action.data.responseData)
        }
        case `${CREATE_LOP}_FAILED`:
            return state.set('error', action.data.responseData).set('isLopAdded', false)

        default:
            return state
    }
}

export default lopReducer
