import React from 'react'
import ImageCollage from './ImageCollage'
import ImageCarousel from './ImageCarousel'

const ImageItem = props => {
    const { item } = props
    const { layoutId } = item
    if (layoutId > 2) return <ImageCollage {...props} />
    return <ImageCarousel {...props} />
}

export default ImageItem
