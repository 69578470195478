import React, { Component } from 'react'
import './index.css'
class Error extends Component {
    render() {
        return (
            <div className="error-container">
                <p>{this.props.message}</p>
            </div>
        )
    }
}

export default Error
