import React, { Component } from 'react'
import ModuleHeader from '../ModuleHeader'
import { SITE_TXT } from '../../Utils/constants'

class PageNotFound extends Component {
    render() {
        return (
            <main className="page-not-found-container">
                <ModuleHeader heading={SITE_TXT.TXT_PAGE_NOT_EXIST} />
            </main>
        )
    }
}

export default PageNotFound
