import { fromJS } from 'immutable'

const ignoreActions = ['DEMO_ACTION_IGNORED', 'DEMO_ACTION_IGNORED_1', 'ADD_BLOCK_SUCCESS']

const actionIgnored = actionType => {
    let isIgnored = false
    ignoreActions.forEach(ignoreAction => {
        if (~actionType.indexOf(ignoreAction)) isIgnored = true
    })
    return isIgnored
}

const INITIAL_STATE = fromJS({
    count: 0,
})
const loaderReducer = (state = INITIAL_STATE, { type }) => {
    if (actionIgnored(type)) return state
    const requestStatus = type.split('_').reverse()[0]
    switch (requestStatus) {
        case 'PENDING':
            return state.update('count', count => count + 1)
        case 'SUCCESS':
        case 'FAILED':
            return state.update('count', count => count - 1)
        default:
            return state
    }
}

export default loaderReducer
