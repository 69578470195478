import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-responsive-modal'
import { Button } from 'reactstrap'
import { connect } from 'react-redux'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import arrayMove from 'array-move'

import { sortSection } from '../../../Containers/LopContent/actions'
import { SITE_TXT } from '../../../Utils/constants'
import { getPrefixedUrl } from '../../../Utils/apis'
import './index.scss'

const SortableItem = SortableElement(props => {
    const { item } = props
    return (
        <figure className="sectionIcon">
            <img alt="" src={item.uploadedImage || getPrefixedUrl(item.image)} />
            <figcaption className="ellipsisPro" title={item.sectionName}>
                {item.templateId === '2' ? item.sectionName.substr(0, 12) : item.sectionName.substr(0, 14)}
            </figcaption>
        </figure>
    )
})

const SortableList = SortableContainer(props => {
    return (
        <div className="sectionContainer clearfix">
            {props.data.map((item, index) => {
                return <SortableItem key={item.sectionId} index={index} item={item} />
            })}
        </div>
    )
})

class ReorderSection extends Component {
    constructor(props) {
        super(props)
        const { sections } = props
        this.state = { sections }
    }

    updateSections = sections => this.setState({ sections })

    setNewSections = () => {
        const { sections } = this.state
        const { sortSection, onClose, reloadContent, currentSlide, content } = this.props
        const { sectionId: oldSectionId } = content
        sortSection(sections, sections.map(({ sectionId }) => sectionId).join())
        onClose()
        const newSlide = sections.findIndex(({ sectionId }) => sectionId === oldSectionId)
        setTimeout(() => {
            reloadContent(newSlide === -1 ? currentSlide : newSlide, false, true)
        }, 1)
    }

    render() {
        const { onClose } = this.props
        const { sections } = this.state
        const styleClasses = {
            modal: 'albumModal reorderSection',
            overlay: 'overlayAlbumModal',
            closeButton: 'closeIcon',
        }
        return (
            <Modal open onClose={onClose} center classNames={styleClasses} closeOnOverlayClick={false}>
                <h2>{SITE_TXT.TXT_REORDER_SECTION}</h2>
                <SortableList
                    data={sections}
                    onSortEnd={({ oldIndex, newIndex }) => this.updateSections(arrayMove(sections, oldIndex, newIndex))}
                    axis="xy"
                    distance={1}
                    lockToContainerEdges={true}
                    helperClass="movingSectionIcon"
                />
                <div className="buttonContent">
                    <div className="btn btn-outline-secondary btn-lg">
                        <span onClick={onClose}>{SITE_TXT.TXT_CANCEL}</span>
                    </div>
                    <Button className="btn btn-primary btn-lg" onClick={this.setNewSections}>
                        {SITE_TXT.TXT_DONE}
                    </Button>
                </div>
            </Modal>
        )
    }
}

ReorderSection.propTypes = {
    currentSlide: PropTypes.number.isRequired,
    sections: PropTypes.instanceOf(Array).isRequired,
    onClose: PropTypes.func.isRequired,
    reloadContent: PropTypes.func.isRequired,
    sortSection: PropTypes.func.isRequired,
}

const dispatchProps = { sortSection }

export default connect(null, dispatchProps)(ReorderSection)
