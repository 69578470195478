import React, { useState, useEffect } from 'react'
import Modal from 'react-responsive-modal'
import { isEmpty } from 'lodash'
import arrayMove from 'array-move'
import { LINK_PATTERN, SITE_TXT } from '../../../../Utils/constants'
import { connect } from 'react-redux'
import { Icon } from 'antd'
import { Button, Input } from 'reactstrap'
import { convertToEmbedLinkYouTube, createMarkup, isValidPattern } from '../../../../Utils/functions'
import { Arrow, DeleteGreyIcon } from '../../../../Utils/svg'
import { editMultipleVideos } from '../../../../Containers/LopContent/actions'
import './index.css'

const MultiVideoUpload = ({
    videoEditData,
    onClose,
    isText,
    editMultipleVideos,
    sectionId,
    templateId,
    elementId = '',
    indexItem,
}) => {
    const [videoObject] = useState({
        heading: '',
        subHeading: '',
        description: '',
        videoUrl: '',
        isDelete: 'false',
        contentTypeId: 1,
        id: 0,
    })
    const [videInput, setVideoInput] = useState([])
    const [noData, setNoData] = useState(false)

    useEffect(() => {
        setVideoInput([videoObject])
    }, [videoObject])

    useEffect(() => {
        if (videoEditData.length > 0) {
            const tempVid = []
            videoEditData.forEach(item => {
                tempVid.push(item)
            })
            setVideoInput(tempVid)
        }
    }, [videoEditData])

    useEffect(() => {
        if (videInput.length) {
            const newVidArray = videInput.filter(item => item.isDelete === 'false' || item.isDelete === '0')
            console.log('newVidArray', newVidArray)
            if (newVidArray.length === 0) {
                setNoData(true)
            } else {
                setNoData(false)
            }
        } else {
            console.log('videInput', videInput)
            setNoData(true)
        }
    }, [videInput])

    const handleModalClose = () => {
        onClose()
    }

    const sendData = () => {
        const data = {}
        data.sectionId = sectionId
        data.templateId = templateId
        data.elementId = elementId
        data.isText = isText
        data.sectionContent = []
        videInput.forEach((item, index) => {
            const obj = {}
            obj.id = item.id || 0
            obj.heading = item.heading
            obj.subHeading = item.subHeading
            obj.description = item.description
            obj.isDelete = item.isDelete === 'true' ? 'true' : 'false'
            obj.videoUrl = item.videoUrl
            obj.contentTypeId = 1
            // data.push(obj)
            data.sectionContent.push(obj)
        })
        editMultipleVideos(indexItem, data)
        handleModalClose()
    }

    const handleAddVideoClick = () => {
        setVideoInput([...videInput, videoObject])
    }

    const handleRemoveVidItem = index => {
        const videoInputs = [...videInput]
        if (videoInputs[index].id !== 0) {
            videoInputs[index] = {
                ...videInput[index],
                isDelete: 'true',
            }
            setVideoInput(videoInputs)
        } else {
            const tempVideObject = videInput
            tempVideObject.splice(index, 1)
            setVideoInput([...tempVideObject])
        }
    }

    const handleChange = (e, index, type) => {
        const videoInputs = [...videInput]
        videoInputs[index] = {
            ...videInput[index],
            [type]: e.target.value || '',
        }
        setVideoInput(videoInputs)
    }

    const videoUrlFrame = ({ url }) => {
        if (url) {
            const vidUrl = convertToEmbedLinkYouTube(url)
            const iframe = `<iframe class="videoIframe" src=${vidUrl} allowfullscreen />`
            if (url.includes('mm-digital-hub.com') || url.includes('/mm/')) {
                return (
                    <video controls key={vidUrl}>
                        <source src={url} />
                    </video>
                )
            } else {
                return <div dangerouslySetInnerHTML={createMarkup(iframe)} />
            }
        }
    }

    const handleHeaderShift = (e, moveType, item, index) => {
        if (moveType === 'up') {
            if (index !== 0) {
                const newIndex = index - 1
                const newVideoInput = arrayMove(videInput, index, newIndex)
                newVideoInput.map((item, index) => {
                    item.displayOrder = `${index + 1}`
                    return item
                })
                setVideoInput(newVideoInput)
            }
        } else {
            if (index < videInput.length) {
                const newIndex = index + 1
                const newVideoInput = arrayMove(videInput, index, newIndex)
                newVideoInput.map((item, index) => {
                    item.displayOrder = `${index + 1}`
                    return item
                })
                setVideoInput(newVideoInput)
            }
        }
    }
    return (
        <Modal
            open
            onClose={handleModalClose}
            classNames={{ modal: 'multiVideoLinkModal' }}
            closeOnOverlayClick={false}
        >
            <h2>{isEmpty(videoEditData) ? SITE_TXT.TXT_ADD_VIDEO : SITE_TXT.TXT_EDIT_VIDEO}</h2>
            <div className="instruction">{SITE_TXT.TXT_VIDEO_ADD_INST}</div>
            <div className="videoContent clearfix">
                {noData && (
                    <div className="message">
                        It seems you do not have any video in the list. Please add a new video to continue.
                    </div>
                )}
                {videInput.map((item, index) => {
                    if (item.isDelete === 'false' || item.isDelete === '0') {
                        return (
                            <div className="video-input-container" key={index}>
                                {/*controls*/}
                                <div className="vid-header">
                                    <div className="serial"> {index + 1}</div>
                                    <div className="controls">
                                        <span onClick={e => handleHeaderShift(e, 'up', item, index)}>
                                            <Icon component={Arrow} className={'vidUpArrow'} />
                                            Move Up
                                        </span>
                                        <span onClick={e => handleHeaderShift(e, 'down', item, index)}>
                                            <Icon component={Arrow} className={'vidDownArrow'} />
                                            Move Down
                                        </span>
                                        <span className="bin-icon" onClick={() => handleRemoveVidItem(index)}>
                                            <DeleteGreyIcon />
                                            Delete
                                        </span>
                                    </div>
                                </div>
                                <div className="video-box">
                                    {/*video preview*/}
                                    <div className="previewBox"> {videoUrlFrame({ url: item.videoUrl })}</div>
                                    <div className="video-url">
                                        <Input
                                            placeholder={SITE_TXT.TXT_ENTER_LINK}
                                            onChange={e => handleChange(e, index, 'videoUrl')}
                                            value={item.videoUrl}
                                            className={
                                                item.videoUrl.length > 0 && isValidPattern(item.videoUrl, LINK_PATTERN)
                                                    ? 'valid'
                                                    : item.videoUrl.length > 0 &&
                                                      !isValidPattern(item.videoUrl, LINK_PATTERN)
                                                    ? 'invalid'
                                                    : ''
                                            }
                                        />
                                    </div>
                                    {isText === 'True' && (
                                        <div className="row2">
                                            <Input
                                                type="text"
                                                placeholder="Video heading"
                                                onChange={e => handleChange(e, index, 'heading')}
                                                className="heading"
                                                value={item.heading}
                                            />
                                            <textarea
                                                onChange={e => handleChange(e, index, 'subHeading')}
                                                className="form-control subHeading"
                                                value={item.subHeading}
                                                placeholder="Video Description"
                                                rows={3}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )
                    }
                })}
                <div className="add-input" onClick={handleAddVideoClick}>
                    + ADD
                </div>
            </div>

            <div className="btn-section">
                <div className="btn btn-outline-secondary">
                    <span onClick={onClose}>{SITE_TXT.TXT_CANCEL}</span>
                </div>
                <Button onClick={sendData} color="primary" disabled={noData}>
                    {SITE_TXT.TXT_DONE}
                </Button>
            </div>
        </Modal>
    )
}

MultiVideoUpload.defaultProps = {
    contentTypeId: 1,
}

const dispatchProps = {
    editMultipleVideos,
}
export default connect(null, dispatchProps)(MultiVideoUpload)
