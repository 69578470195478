import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-responsive-modal'
import loadImage from 'blueimp-load-image'
import 'react-image-crop/dist/ReactCrop.css'
import { Input, Button } from 'reactstrap'
import c from 'classnames'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import arrayMove from 'array-move'

import Loader from '../../../../Loader'
import { IMAGE_CROP_NO_WIDTH, SITE_TXT } from '../../../../../Utils/constants'
import { getPrefixedUrl } from '../../../../../Utils/apis'
import { EditLopImageIcon } from '../../../../../Utils/svg'
import ImageResizer from '../../../../ImageResizer'
import './index.scss'

const SortableItem = SortableElement(props => {
    const { item, indexItem, indexMain, updateCurrentIndex, itemWidth } = props
    return (
        <div
            className={c('MovableThumbnail', {
                activeImage: indexItem === indexMain,
            })}
            style={{ width: `${itemWidth}%` }}
            onClick={() => updateCurrentIndex(indexItem)}
        >
            <img key={indexItem} alt="" src={item.dummyImg || getPrefixedUrl(item.image)} />
        </div>
    )
})

const SortableList = SortableContainer(props => {
    const { data, indexMain, updateCurrentIndex, handleFile, SITE_TXT } = props
    const widthToUse = data.length < 30 ? 88 : 100

    return (
        <div className="thumbnailImages">
            {data.map((item, index) => {
                return (
                    <SortableItem
                        key={item.id || index}
                        indexItem={index}
                        index={index}
                        item={item}
                        indexMain={indexMain}
                        updateCurrentIndex={updateCurrentIndex}
                        itemWidth={(widthToUse / data.length).toFixed(2)}
                    />
                )
            })}
            {data.length < 30 && (
                <div className="addImage">
                    <span>ADD IMAGE</span>
                    <input
                        type="file"
                        onChange={e => handleFile(e, data.length)}
                        accept={SITE_TXT.TXT_CREATE_USER_FILES_ACCEPTED}
                    />
                </div>
            )}
        </div>
    )
})

class ImageCropper extends Component {
    constructor(props) {
        super(props)
        const { addedImages } = props
        this.state = {
            images: [...addedImages],
            index: 0,
            savedImages: [],
            showLoader: false,
        }
    }

    handleFile = (e, index) => {
        if (e.target.files && e.target.files.length > 0) {
            const imageFile = e.target.files[0]
            const { images } = this.state
            const { appWidth } = this.props
            if (appWidth <= 768) {
                this.setState({ showLoader: true })
                loadImage(
                    e.target.files[0],
                    canvas => {
                        const item = images[index] || { imageText: '' }
                        const updateImages = [
                            ...images.slice(0, index),
                            {
                                ...item,
                                dummyImg: canvas.toDataURL('image/png'),
                                imageFile,
                                id: images[index] ? images[index].id : 0,
                            },
                            ...images.slice(index + 1),
                        ]
                        this.setState({
                            images: updateImages,
                            index,
                            showLoader: false,
                        })
                    },
                    { orientation: true }
                )
            } else {
                const reader = new FileReader()
                reader.addEventListener(
                    'loadend',
                    () => {
                        const item = images[index] || { imageText: '' }
                        const updateImages = [
                            ...images.slice(0, index),
                            {
                                ...item,
                                dummyImg: reader.result,
                                imageFile,
                                id: images[index] ? images[index].id : 0,
                            },
                            ...images.slice(index + 1),
                        ]
                        this.setState({ images: updateImages, index })
                    },
                    false
                )
                reader.readAsDataURL(e.target.files[0])
            }
        }
        e.target.value = null
    }

    getResizedImg = imageFile => {
        const { index, images } = this.state
        const updateImages = [
            ...images.slice(0, index),
            {
                id: 0,
                ...images[index],
                imageFile,
            },
            ...images.slice(index + 1),
        ]
        this.setState({ images: updateImages })
    }

    updateImageText = imageText => {
        const { index, images } = this.state
        const updateImages = [
            ...images.slice(0, index),
            {
                ...images[index],
                imageText,
            },
            ...images.slice(index + 1),
        ]
        this.setState({ images: updateImages })
    }

    updateCurrentIndex = index => this.setState({ index })

    saveCarousel = () => {
        const {
            sectionItem,
            sectionIndex,
            sectionDetails,
            addCustomContent,
            editCollageContent,
            editCarousel,
            closeModal,
        } = this.props
        const { isText, contentTypeId, layoutId, id: sectionMainId } = sectionItem
        const { images } = this.state
        const { sectionId, templateId } = sectionDetails

        const data = new FormData()
        if (editCarousel) {
            const rawImageData = []
            data.append('elementId', sectionMainId)
            images.forEach((croppedImageData, index) => {
                const { imageFile, imageText, id } = croppedImageData
                let isImage = false
                if (imageFile) {
                    data.append('images', imageFile)
                    isImage = true
                }
                rawImageData.push({
                    id,
                    imageText,
                    isImage,
                    displayOrder: index + 1,
                })
            })
            data.append('imageData', JSON.stringify(rawImageData))
            editCollageContent(data, sectionIndex, true)
        } else {
            data.append('sectionId', sectionId)
            data.append('templateId', templateId)
            data.append('contentTypeId', contentTypeId)
            data.append('isText', isText)
            data.append('layoutId', layoutId)
            images.forEach(({ imageFile, imageText }) => {
                imageFile && data.append('images', imageFile)
                data.append('imageDescription', imageText)
            })
            addCustomContent(data, sectionIndex, true)
        }
        closeModal()
    }

    sortArray = images => this.setState({ images })

    render() {
        const { closeModal } = this.props
        const { index, images, showLoader } = this.state
        const styleClasses = {
            modal: 'imageCropperModal',
            overlay: 'overlayAlbumModal',
            closeButton: 'closeBtnImageModal',
        }

        const content = images[index]
        const disableSubmit = images.filter(x => x.imageFile || x.image).length !== images.length

        return (
            <Modal open center onClose={() => closeModal()} classNames={styleClasses} closeOnOverlayClick={false}>
                <h3>{SITE_TXT.TXT_UPLOAD_IMAGES}</h3>
                <div className="cropperModal">
                    {content.dummyImg ? (
                        <ImageResizer
                            crop={IMAGE_CROP_NO_WIDTH}
                            quality={0.5}
                            origImg={content.dummyImg}
                            imgDataUrl={content.dummyImg}
                            getResizedImg={x => this.getResizedImg(x)}
                        />
                    ) : (
                        <div className="imageContent">
                            <span className="editImageIcon">
                                <EditLopImageIcon />
                                <input
                                    type="file"
                                    onChange={e => this.handleFile(e, index)}
                                    accept={SITE_TXT.TXT_CREATE_USER_FILES_ACCEPTED}
                                />
                            </span>
                            <img alt="" src={getPrefixedUrl(content.image)} key={getPrefixedUrl(content.image)} />
                        </div>
                    )}
                    <div className="nextImageContent">
                        <Input
                            value={content.imageText}
                            placeholder="Image Description here"
                            maxLength="100"
                            onChange={e => this.updateImageText(e.target.value)}
                        />
                        {index !== images.length - 1 && (
                            <span onClick={() => this.updateCurrentIndex(index + 1)}>NEXT IMAGE</span>
                        )}
                    </div>
                    <SortableList
                        data={images}
                        onSortEnd={({ oldIndex, newIndex }) => this.sortArray(arrayMove(images, oldIndex, newIndex))}
                        axis="xy"
                        distance={1}
                        lockToContainerEdges={true}
                        indexMain={index}
                        helperClass="movingIcon"
                        updateCurrentIndex={this.updateCurrentIndex}
                        SITE_TXT={SITE_TXT}
                        handleFile={this.handleFile}
                    />
                    <div className="btn-section">
                        <div className="btn btn-outline-secondary btn-lg">
                            <span onClick={closeModal}>{SITE_TXT.TXT_CANCEL}</span>
                        </div>
                        <Button disabled={disableSubmit} color="primary" size="lg" onClick={this.saveCarousel}>
                            {SITE_TXT.TXT_DONE}
                        </Button>
                    </div>
                </div>
                {showLoader && <Loader />}
            </Modal>
        )
    }
}

ImageCropper.propTypes = {
    closeModal: PropTypes.func.isRequired,
    sectionIndex: PropTypes.number.isRequired,
    editCarousel: PropTypes.bool.isRequired,
    addedImages: PropTypes.instanceOf(Array).isRequired,
    sectionItem: PropTypes.instanceOf(Object).isRequired,
    sectionDetails: PropTypes.instanceOf(Object).isRequired,
    addCustomContent: PropTypes.func.isRequired,
    editCollageContent: PropTypes.func.isRequired,
    appWidth: PropTypes.number.isRequired,
}

export default ImageCropper
