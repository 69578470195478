import { MM_PRACTICE_GET } from './constant'

export const mmPracticeAction = data => ({
    type: `${MM_PRACTICE_GET}_PENDING`,
    data,
})

export const mmPracticeSuccessAction = data => ({
    type: `${MM_PRACTICE_GET}_SUCCESS`,
    data,
})

export const mmPracticeFailedAction = data => ({
    type: `${MM_PRACTICE_GET}_FAILED`,
    data,
})
