import { call, put, takeLatest, takeEvery, all } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import {
    fetchMyLops,
    fetchMyLopDetails,
    deleteMyLop,
    saveLopSection,
    fetchSectionGallery,
    editLopSectionImage,
    addPartner,
    editPartner,
    deletePartner,
    addLopSection,
    addCustomSectionContent,
    editCustomSectionContent,
    addArticle,
    editArticle,
    deleteArticle,
    downloadMyLOPDocs,
    fetchCollaborator,
    searchCollaborator,
    editCollaborators,
    deleteLopSection,
    reOrderLopSection,
    lockMyLOP,
    contactWinRoom,
    addExternalUser,
    copyMoveLopSection,
    addBlock,
    fetchAdditionalContent,
    fetchLopCurrentTemplateDetails,
    restoreSectionApi,
    editMultipleVideosApi,
    setLopWelcome,
    getLopWelcome,
    updateLopWelcome,
} from '../../Utils/apis'
import {
    FETCH_MY_LOP,
    FETCH_MY_LOP_DETAILS,
    DELETE_LOP,
    SAVE_MY_LOP_DETAILS,
    FETCH_LOP_ALBUM,
    EDIT_SECTION_IMAGE,
    ADD_PARTNER,
    EDIT_PARTNER,
    DELETE_PARTNER,
    SET_ORDER_ITEM,
    ADD_CUSTOM_CONTENT,
    EDIT_CUSTOM_CONTENT,
    EDIT_COLLAGE_CONTENT,
    ADD_LOP_SECTION,
    EDIT_ARTICLE,
    ADD_ARTICLE,
    DELETE_ARTICLE,
    DOWNLOAD_LOP_DOCS,
    GET_COLLABORATOR_LIST,
    SEARCH_COLLABORATOR,
    EDIT_COLLABORATORS,
    DELETE_SECTION,
    SORT_SECTIONS,
    LOCK_MY_LOP,
    CONTACT_WIN_ROOM_MAIL,
    ADD_EXTERNAL_USER,
    PREVIEW_LOP_DOCS,
    ADD_BLOCK,
    FETCH_ADDITIONAL_DETAILS,
    FETCH_CURRENT_TEMPLATE_DETAILS,
    COPY_LOP_SECTION,
    RESTORE_SECTION,
    EDIT_MULTIPLE_VIDEOS,
    LOP_WELCOME,
} from './constant'
import {
    getMyLopsSuccess,
    getMyLopsFailed,
    fetchLopDeatilsSuccess,
    fetchLopDeatilsFailed,
    saveLopDetailsSuccess,
    saveLopDetailsFailed,
    fetchLopAlbumSuccess,
    fetchLopAlbumFailed,
    editSectionImageSuccess,
    editSectionImageFailed,
    addPartnerSuccess,
    addPartnerFailed,
    editPartnerSuccess,
    editPartnerFailed,
    deletePartnerSuccess,
    deletePartnerFailed,
    fetchCurrentTemplate,
    fetchCurrentTemplateSuccess,
    fetchCurrentTemplateFailed,
    addCustomContentSuccess,
    addCustomContentFailed,
    editCollageContentSuccess,
    addArticleSuccess,
    addArticleFailed,
    editArticleSuccess,
    editArticleFailed,
    editFileContentSuccess,
    addLopSectionSuccess,
    addLopSectionFailed,
    dummyLoader,
    dummyLoaderClose,
    deleteArticleSuccess,
    deleteArticleFailed,
    downloadLOPDocsSuccess,
    downloadLOPDocsFailed,
    getCollaboratorSuccess,
    getCollaboratorFailed,
    searchCollaboratorSuccess,
    searchCollaboratorFailed,
    editCollaboratorsSuccess,
    editCollaboratorsFailed,
    deteleLopSuccess,
    deteleLopFailed,
    lockMyLopSuccess,
    lockMyLopFailed,
    contactWinRoomSuccess,
    contactWinRoomFailed,
    addExternalUserSuccess,
    addExternalUserFailed,
    previewLOPDocsSuccess,
    previewLOPDocsFailed,
    addBlockSuccess,
    addBlockFailed,
    fetchAdditionalDetailsSuccess,
    fetchAdditionalDetailsFailed,
    updateArticleInSepeateSection,
    copySectionSuccess,
    moveSectionSuccess,
    copyMoveSectionFailed,
    restoreSectionFailed,
    restoreSectionSuccess,
    editMultipleVideosFailed,
    editMultipleVideosSuccess,
    getLopWelcomeSuccess,
    getLopWelcomeFailed,
    setLopWelcomeSuccess,
    setLopWelcomeFailed,
    updateLopWelcomeSuccess,
    updateLopWelcomeFailed,
} from './actions'
import { STATUS_SUCCESS, SITE_TXT } from '../../Utils/constants'

export function* fetchMyLopSaga({ data }) {
    const response = yield call(fetchMyLops, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(getMyLopsSuccess(responseData))
    } else {
        yield put(getMyLopsFailed({ responseData }))
    }
}

export function* fetchLopDetailsSaga({ id, retainState }) {
    const response = yield call(fetchMyLopDetails, id)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(fetchLopDeatilsSuccess(responseData, retainState))
    } else {
        yield put(fetchLopDeatilsFailed({ responseData }))
    }
}

export function* saveLopDetailsSaga({ data }) {
    const { dataToSend, saveButtonPressed } = data
    const response = yield call(saveLopSection, dataToSend)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        const dataSendFromSaga = saveButtonPressed ? { responseData } : responseData
        yield put(saveLopDetailsSuccess(dataSendFromSaga))
        if (saveButtonPressed) {
            yield put(fetchCurrentTemplate(dataToSend))
        }
    } else {
        yield put(saveLopDetailsFailed({ responseData }))
    }
}

export function* setSortedArraySaga({ data }) {
    yield call(saveLopSection, data)
}

export function* fetchLopAlbumSaga({ id }) {
    const response = yield call(fetchSectionGallery, id)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(fetchLopAlbumSuccess(responseData))
    } else {
        yield put(fetchLopAlbumFailed({ responseData }))
    }
}

export function* updateLopSectionImageSaga({ data }) {
    const { currentSlide, image, uploadedImage, mainData } = data
    const response = yield call(editLopSectionImage, mainData)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(
            editSectionImageSuccess({
                responseData,
                currentSlide,
                uploadedImage,
                image,
            })
        )
    } else {
        yield put(editSectionImageFailed({ responseData }))
    }
}

export function* addPartnerSaga({ data, curBlockIndex }) {
    const response = yield call(addPartner, data)
    const res = response.data
    const { data: apiData, ...result } = res
    const responseData = { data: apiData, result }
    if (res.status === STATUS_SUCCESS) {
        yield put(addPartnerSuccess({ responseData, requestData: data, curBlockIndex }))
    } else {
        yield put(addPartnerFailed({ responseData }))
    }
}

export function* editPartnerSaga({ data, newUpadtedData, removeIndex, index, dumIndex }) {
    const response = yield call(editPartner, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        const dataToMove = {
            ...newUpadtedData,
            image: responseData.data[0].image,
        }
        if (removeIndex === index) {
            yield put(editPartnerSuccess({ responseData, removeIndex, dumIndex, dataToMove }))
        } else {
            yield put(updateArticleInSepeateSection({ responseData, dataToMove, removeIndex, index, dumIndex }, true))
        }
    } else {
        yield put(editPartnerFailed({ responseData }))
    }
}

export function* deletePartnerSaga({ data }) {
    const response = yield call(deletePartner, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(deletePartnerSuccess({ responseData, requestData: data }))
    } else {
        yield put(deletePartnerFailed({ responseData }))
    }
}

export function* fetchCurrentTemplateSaga({ data }) {
    const response = yield call(fetchLopCurrentTemplateDetails, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(fetchCurrentTemplateSuccess(responseData.result.data[0]))
    } else {
        const newData = {
            ...responseData,
            result: {
                ...responseData.result,
                message: SITE_TXT.LOP_IS_DELETED,
            },
        }
        yield put(fetchCurrentTemplateFailed({ responseData: newData }))
    }
}

export function* fetchAdditionalReadingSaga({ data }) {
    const response = yield call(fetchAdditionalContent, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(fetchAdditionalDetailsSuccess(responseData.result.data[0]))
    } else {
        yield put(fetchAdditionalDetailsFailed())
    }
}

export function* addCustomContentSaga({ data, sectionIndex, replaceContent, fileName }) {
    const response = yield call(addCustomSectionContent, data)
    const responseData = response.data
    const { id, content } = responseData.result
    if (responseData.status === STATUS_SUCCESS) {
        yield put(addCustomContentSuccess(sectionIndex, id, content, fileName, replaceContent))
    } else {
        yield put(addCustomContentFailed(sectionIndex))
    }
}

export function* addLopSectionSaga({ data, dataToSave }) {
    const response = yield call(addLopSection, data)
    const responseData = response.data
    const { id: sectionId, image } = responseData.result
    if (responseData.status === STATUS_SUCCESS) {
        yield put(addLopSectionSuccess({ ...dataToSave, sectionId, image }))
    } else {
        yield put(addLopSectionFailed())
    }
}

export function* editCustomContentSaga({ data }) {
    yield call(editCustomSectionContent, data)
}

export function* editCollageContentSaga({ data, sectionIndex, addLoader, replaceContent }) {
    const response = yield call(editCustomSectionContent, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        if (replaceContent) {
            const { data: content } = responseData.result
            yield put(editCollageContentSuccess(sectionIndex, content))
        } else {
            yield put(editFileContentSuccess())
        }
        if (addLoader) {
            yield put(dummyLoader())
            yield call(delay, 3000)
            yield put(dummyLoaderClose())
        }
    } else {
        yield put(editCollageContentSuccess(sectionIndex, []))
    }
}

export function* addArticleSaga({ data, curBlockIndex }) {
    const response = yield call(addArticle, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(addArticleSuccess({ responseData, requestData: data, curBlockIndex }))
    } else {
        yield put(addArticleFailed({ responseData }))
    }
}

export function* editArticleSaga({ data, newUpadtedData, removeIndex, index, dumIndex }) {
    const response = yield call(editArticle, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        const dataToMove = {
            ...newUpadtedData,
            image: responseData.result.content[0].image,
        }
        if (removeIndex === index) {
            yield put(editArticleSuccess({ responseData, removeIndex, dumIndex, dataToMove }))
        } else {
            yield put(updateArticleInSepeateSection({ responseData, dataToMove, removeIndex, index, dumIndex }))
        }
    } else {
        yield put(editArticleFailed({ responseData }))
    }
}

export function* deleteArticleSaga({ data, mainItem, curBlockIndex, curItemIndex }) {
    const response = yield call(deleteArticle, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(deleteArticleSuccess({ responseData }, mainItem, curBlockIndex, curItemIndex))
    } else {
        yield put(deleteArticleFailed({ responseData }))
    }
}

export function* downloadMyLOPDocsSaga({ data }) {
    const response = yield call(downloadMyLOPDocs, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(downloadLOPDocsSuccess({ responseData }))
    } else {
        yield put(downloadLOPDocsFailed({ responseData }))
    }
}

export function* previewMyLOPDocsSaga({ data }) {
    const response = yield call(downloadMyLOPDocs, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(previewLOPDocsSuccess({ responseData, requestData: data }))
    } else {
        yield put(previewLOPDocsFailed({ responseData }))
    }
}

export function* getCollaboratorSaga({ data }) {
    const response = yield call(fetchCollaborator, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(getCollaboratorSuccess({ responseData }))
    } else {
        yield put(getCollaboratorFailed({ responseData }))
    }
}

export function* searchCollaboratorSaga({ data }) {
    const { existingList } = data
    const response = yield call(searchCollaborator, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(searchCollaboratorSuccess({ responseData, existingList }))
    } else {
        yield put(searchCollaboratorFailed({ responseData }))
    }
}

export function* editCollaboratorsSaga({ data }) {
    const response = yield call(editCollaborators, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(editCollaboratorsSuccess({ responseData }))
    } else {
        yield put(editCollaboratorsFailed({ responseData }))
    }
}

export function* deleteSectionSaga({ item }) {
    yield call(deleteLopSection, item)
}

export function* reorderSectionSaga({ ids }) {
    yield call(reOrderLopSection, { ids })
}

export function* deleteLopDetailsSaga({ id }) {
    const response = yield call(deleteMyLop, id)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(deteleLopSuccess({ responseData }))
    } else {
        yield put(deteleLopFailed({ responseData }))
    }
}

export function* lockMyLopSaga({ data }) {
    const response = yield call(lockMyLOP, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(lockMyLopSuccess({ responseData, requestData: data }))
    } else {
        yield put(lockMyLopFailed({ responseData }))
    }
}

export function* contactWinRoomSaga({ data }) {
    const response = yield call(contactWinRoom, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(contactWinRoomSuccess({ responseData }))
    } else {
        yield put(contactWinRoomFailed({ responseData }))
    }
}

export function* addExternalUserSaga({ data }) {
    const response = yield call(addExternalUser, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(addExternalUserSuccess({ responseData }))
    } else {
        yield put(addExternalUserFailed({ responseData }))
    }
}

export function* addBlockSaga({ data }) {
    const response = yield call(addBlock, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(addBlockSuccess({ responseData, requestData: data }))
    } else {
        yield put(addBlockFailed({ responseData }))
    }
}

export function* copyMoveSectionSaga({ data, contentToUse }) {
    const response = yield call(copyMoveLopSection, data)
    const responseData = response.data
    const { result = {} } = responseData
    const { id: sectionId = '' } = result
    let newSection = null
    if (contentToUse) {
        newSection = { ...contentToUse, sectionId }
    }
    if (responseData.status === STATUS_SUCCESS) {
        if (newSection) {
            yield put(copySectionSuccess({ responseData, newSection }))
        } else {
            yield put(moveSectionSuccess({ responseData }))
        }
    } else {
        yield put(copyMoveSectionFailed({ responseData }))
    }
}

export function* restoreLopSaga({ data }) {
    const response = yield call(restoreSectionApi, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(restoreSectionSuccess({ responseData, requestData: data }))
    } else {
        yield put(restoreSectionFailed({ responseData }))
    }
}

export function* editMultipleVideoSaga({ index, data }) {
    const response = yield call(editMultipleVideosApi, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(editMultipleVideosSuccess({ responseData, requestData: data, index }))
    } else {
        yield put(editMultipleVideosFailed({ responseData }))
    }
}

export function* getLopWelcomeSaga({ data }) {
    const response = yield call(getLopWelcome, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(getLopWelcomeSuccess({ responseData }))
    } else {
        yield put(getLopWelcomeFailed({ responseData }))
    }
}

export function* setLopWelcomeSaga({ data }) {
    const response = yield call(setLopWelcome, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(setLopWelcomeSuccess({ responseData }))
    } else {
        yield put(setLopWelcomeFailed({ responseData }))
    }
}

export function* putLopWelcomeSaga({ data }) {
    const response = yield call(updateLopWelcome, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(updateLopWelcomeSuccess({ responseData }))
    } else {
        yield put(updateLopWelcomeFailed({ responseData }))
    }
}

export function* lopContentSaga() {
    yield all([
        takeLatest(`${FETCH_MY_LOP}_LOADING`, fetchMyLopSaga),
        takeLatest(`${FETCH_MY_LOP_DETAILS}_PENDING`, fetchLopDetailsSaga),
        takeLatest(`${DELETE_LOP}_PENDING`, deleteLopDetailsSaga),
        takeLatest(`${SAVE_MY_LOP_DETAILS}_PENDING`, saveLopDetailsSaga),
        takeLatest(`${SET_ORDER_ITEM}`, setSortedArraySaga),
        takeLatest(`${FETCH_LOP_ALBUM}_PENDING`, fetchLopAlbumSaga),
        takeLatest(`${EDIT_SECTION_IMAGE}_PENDING`, updateLopSectionImageSaga),
        takeLatest(`${ADD_PARTNER}_PENDING`, addPartnerSaga),
        takeLatest(`${EDIT_PARTNER}_PENDING`, editPartnerSaga),
        takeLatest(`${DELETE_PARTNER}_PENDING`, deletePartnerSaga),
        takeLatest(`${ADD_CUSTOM_CONTENT}_PENDING`, addCustomContentSaga),
        takeLatest(`${ADD_LOP_SECTION}_PENDING`, addLopSectionSaga),
        takeLatest(`${EDIT_COLLAGE_CONTENT}_PENDING`, editCollageContentSaga),
        takeLatest(EDIT_CUSTOM_CONTENT, editCustomContentSaga),
        takeLatest(`${FETCH_CURRENT_TEMPLATE_DETAILS}_PENDING`, fetchCurrentTemplateSaga),
        takeEvery(`${FETCH_ADDITIONAL_DETAILS}_PENDING`, fetchAdditionalReadingSaga),
        takeLatest(`${ADD_ARTICLE}_PENDING`, addArticleSaga),
        takeLatest(`${EDIT_ARTICLE}_PENDING`, editArticleSaga),
        takeLatest(`${DELETE_ARTICLE}_PENDING`, deleteArticleSaga),
        takeLatest(`${DOWNLOAD_LOP_DOCS}_PENDING`, downloadMyLOPDocsSaga),
        takeLatest(`${PREVIEW_LOP_DOCS}_PENDING`, previewMyLOPDocsSaga),
        takeLatest(`${GET_COLLABORATOR_LIST}_PENDING`, getCollaboratorSaga),
        takeLatest(`${SEARCH_COLLABORATOR}_PENDING`, searchCollaboratorSaga),
        takeLatest(`${EDIT_COLLABORATORS}_PENDING`, editCollaboratorsSaga),
        takeLatest(`${LOCK_MY_LOP}_PENDING`, lockMyLopSaga),
        takeLatest(`${CONTACT_WIN_ROOM_MAIL}_PENDING`, contactWinRoomSaga),
        takeLatest(`${ADD_EXTERNAL_USER}_PENDING`, addExternalUserSaga),
        takeLatest(`${COPY_LOP_SECTION}_PENDING`, copyMoveSectionSaga),
        takeLatest(DELETE_SECTION, deleteSectionSaga),
        takeLatest(SORT_SECTIONS, reorderSectionSaga),
        takeLatest(`${ADD_BLOCK}`, addBlockSaga),
        takeLatest(`${RESTORE_SECTION}_PENDING`, restoreLopSaga),
        takeLatest(`${EDIT_MULTIPLE_VIDEOS}_PENDING`, editMultipleVideoSaga),
        takeLatest(`GET_${LOP_WELCOME}_PENDING`, getLopWelcomeSaga),
        takeLatest(`SET_${LOP_WELCOME}_PENDING`, setLopWelcomeSaga),
        takeLatest(`PUT_${LOP_WELCOME}_PENDING`, putLopWelcomeSaga),
    ])
}

export default [lopContentSaga]
