import React, { Component } from 'react'

import PhaseModal from '../PhaseModal'
import { EditMainIcon } from '../../Utils/svg'

import { SITE_TXT, isMobile, isAdmin } from '../../Utils/constants'
import DeliverSubSection from './DeliverSubSection'
class DeliverSection extends Component {
    constructor(props) {
        super(props)
        sessionStorage.removeItem('currentCategoryTab')
        sessionStorage.removeItem('selTabName')
        sessionStorage.removeItem('selectedTab')
        sessionStorage.removeItem('phaseId')
        sessionStorage.removeItem('selTabId')
    }
    state = {
        categoryId: this.props.categoryId,
        categoryName: this.props.categoryName,
        data: this.props.data,
        isEditMode: this.props.isEditMode,
        section: null,
        showEditMode: true,
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { categoryId, data, categoryName, isEditMode, section } = nextProps
        this.setState({
            categoryId,
            isEditMode,
            data,
            categoryName,
            section,
        })
    }

    setSection = section => {
        this.setState({ section: section, phase: section, open: true })
    }

    editSectionHandler = section => {
        this.setState({ section: section })
    }

    clickToggleEdit = () => {
        //this.setState({ isEditMode: !this.state.isEditMode })
        this.setSection(null)
        this.setState({ open: false })
        this.props.handleFade(!this.props.isEditMode, this.props.categoryId, this.props.categoryName)
        //console.log(!this.state.isEditMode)
    }

    toggleShowEditMode() {
        this.setState({ showEditMode: !this.state.showEditMode })
    }

    onCloseModal() {
        this.setState({ section: null })
    }
    afterTrash() {
        this.onCloseModal()
    }

    afterEdit() {
        this.onCloseModal()
    }

    afterAdd() {
        this.onCloseModal()
    }

    render() {
        const { categoryId, categoryName, data, isEditMode, section, isReorderMode, showEditMode } = this.state

        const { TXT_EDIT_MODE, TXT_ADD_SECTION, TXT_EDIT_DELETE_TOPIC, TXT_SECTION_NAME_PLACEHOLDER } = SITE_TXT
        const showActions = isAdmin && !isMobile
        return (
            <div className="deliver-list-section">
                <section className="section-header">
                    <span>{categoryName}</span>
                    {showActions && showEditMode && (
                        <div className="addEdit">
                            {!isEditMode && (
                                <span className="  addEditIcon" onClick={() => this.clickToggleEdit()}>
                                    <EditMainIcon />
                                    <span>{TXT_EDIT_MODE}</span>
                                </span>
                            )}
                            {/* {isEditMode && (
                               <span className="  addEditIcon" onClick={() => this.clickToggleEdit()}>
                                    <ExitEditModeIcon />
                                    <span>{TXT_EDIT_EXIT_MODE}</span>
                                </span>
                            )} */}
                        </div>
                    )}
                </section>
                <div className="blue-bar" />
                {data.map((deliverSubSection, index) => (
                    <DeliverSubSection
                        key={index}
                        data={deliverSubSection}
                        isEditMode={isEditMode}
                        addSubSection={this.props.addSubSection}
                        editSubSection={this.props.editSubSection}
                        fetchDeliverItem={this.props.fetchDeliverItem}
                        selectfetchData={this.props.selectfetchData}
                        searchInp={this.props.searchInp}
                        isReorderMode={isReorderMode}
                        onReorder={this.props.onReorder}
                        sectionId={categoryId}
                        toggleShowEditMode={() => this.toggleShowEditMode()}
                        editSectionHandler={e => this.editSectionHandler(e)}
                    />
                ))}
                {showActions &&
                    isEditMode &&
                    (section ? (
                        <PhaseModal
                            label={TXT_EDIT_DELETE_TOPIC}
                            placeholder={TXT_SECTION_NAME_PLACEHOLDER}
                            editPhase={this.props.editSection}
                            trashPhase={this.props.trashSection}
                            categoryName={categoryName}
                            categoryId={categoryId}
                            phaseId={section.id}
                            phase={section}
                            open={true}
                            setSection={sec => this.setSection(sec)}
                            afterTrash={() => this.afterTrash()}
                            afterEdit={() => this.afterEdit()}
                        />
                    ) : (
                        <PhaseModal
                            label={TXT_ADD_SECTION}
                            placeholder={TXT_SECTION_NAME_PLACEHOLDER}
                            addPhaseHandler={this.props.addSection}
                            categoryName={categoryName}
                            categoryId={categoryId}
                            afterAdd={() => this.afterAdd()}
                            setSection={sec => this.setSection(null)}
                            open={this.state.open}
                        />
                    ))}
            </div>
        )
    }
}

export default DeliverSection
