import React, { Component } from 'react'
import Modal from 'react-responsive-modal'
import { SITE_TXT, DOCUMENT_NAME_PATTERN, STATUS_SUCCESS, MAX_NAME_LENGTH } from '../../Utils/constants'
import Inputbox from '../Inputbox'
import { isValidPattern } from '../../Utils/functions'
import { LOP_GOLD_STANDARD } from '../../Containers/Lop/constant'
class FolderModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: this.props.open,
            name: this.props.name,
            isEditMode: this.props.isEditMode,
            folderId: this.props.folderId,
            requestType: this.props.requestType,
            addEditStatus: this.props.addEditStatus,
            addEdit: false,
            deleteStatus: false,
        }
    }

    UNSAFE_componentWillReceiveProps = nextProps => {
        const { addEditStatus } = nextProps
        this.setState({ deleteStatus: nextProps.onGoldDeleteSuccess })
        if (this.state.deleteStatus === true) {
            this.setState({ deleteStatus: false }, () => {
                this.resetModal()
            })
        }
        if (addEditStatus && addEditStatus.status === STATUS_SUCCESS && this.state.addEdit === true) {
            this.setState(
                {
                    addEdit: false,
                },
                () => {
                    this.resetModal()
                }
            )
        }
    }

    handleChange = (field, value) => {
        this.setState({ [field]: value })
    }

    getValidatedData = () => {
        const { name, requestType, isEditMode, folderId } = this.state
        let data = { name, requestType }

        if (isEditMode) {
            data.id = folderId
        }
        return data
    }

    isFormValid = () => {
        const { name } = this.state
        if (!isValidPattern(name, DOCUMENT_NAME_PATTERN)) {
            alert(SITE_TXT.TXT_FOLDERNAME_VALID_PATTERN)
            return false
        }
        if (name.trim().length > MAX_NAME_LENGTH) {
            alert(SITE_TXT.TXT_FOLDER_NAME_MAX_LENGTH)
            return false
        }
        return true
    }

    handleSave = e => {
        e.preventDefault()
        if (!this.isFormValid()) {
            return
        }
        this.setState({ isLoading: true, addEdit: true }, () => {
            const validData = this.getValidatedData()
            if (this.state.isEditMode) {
                this.props.lopEditFolder(validData)
            } else {
                this.props.lopAddFolder(validData)
            }
        })
    }

    resetModal = () => {
        this.setState(
            {
                name: '',
                isEditMode: false,
            },
            () => {
                this.props.onCloseModal()
            }
        )
    }

    replaceFile = () => {
        this.browseInp.click()
    }

    handleDelete = () => {
        if (this.state.requestType === LOP_GOLD_STANDARD) {
            this.props.onGoldDeleteFolder(this.state.folderId)
        }
    }

    render = () => {
        const { open, name, isEditMode } = this.state
        const {
            TXT_ADD_NEW_FOLDER,
            TXT_SAVE,
            TXT_DELETE,
            TXT_EDIT_FOLDER,
            TXT_FOLDERNAME,
            TXT_FOLDERNAME_PLACEHOLDER,
        } = SITE_TXT
        return (
            open && (
                <Modal
                    open={open}
                    classNames={{
                        modal: 'comp-modal lop-modal',
                        overlay: 'lop-modal-overlay',
                        closeButton: 'close-btn-modal',
                    }}
                    onClose={() => this.resetModal()}
                    center
                >
                    <form id="addFolderLop" className="form-horizontal" encType="multipart/form-data" method="post">
                        {isEditMode ? (
                            <span className="modal-heading txt-upper">{TXT_EDIT_FOLDER}</span>
                        ) : (
                            <span className="modal-heading txt-upper">{TXT_ADD_NEW_FOLDER}</span>
                        )}
                        <div id="files-container">
                            <div className="file-detail">
                                <Inputbox
                                    label={TXT_FOLDERNAME}
                                    placeholder={TXT_FOLDERNAME_PLACEHOLDER}
                                    type="text"
                                    fieldFor={'name'}
                                    onChange={this.handleChange}
                                    autoFocus={true}
                                    value={name}
                                />
                            </div>
                        </div>

                        <div className="btn-container">
                            {isEditMode && (
                                <span className="txt-upper" disabled={name === ''} onClick={() => this.handleDelete()}>
                                    {TXT_DELETE}
                                </span>
                            )}
                            <button
                                disabled={this.state.isEditMode && name.trim() === ''}
                                className="btn btn-primary"
                                onClick={e => this.handleSave(e)}
                            >
                                {TXT_SAVE}
                            </button>
                        </div>
                    </form>
                </Modal>
            )
        )
    }
}

export default FolderModal
