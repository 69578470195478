import React, { Component } from 'react'
import './index.css'

class ModuleHeader extends Component {
    render() {
        return (
            <section className="module-header">
                <span>{this.props.heading}</span>
            </section>
        )
    }
}

export default ModuleHeader
