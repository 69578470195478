import {
    ADD_CST_GROUP,
    EDIT_CST_GROUP,
    GET_CST_GROUP,
    GET_SINGLE_CST,
    LOAD_MORE_CST_GROUP,
    RESET_STATUS,
    RESET_VALUE,
    VALIDATE_CHARGE_CODE,
} from './constant'

export const getCstGroup = data => ({
    type: `${GET_CST_GROUP}_PENDING`,
    data,
})
export const getCstGroupSuccess = data => ({
    type: `${GET_CST_GROUP}_SUCCESS`,
    data,
})
export const getCstGroupFailed = data => ({
    type: `${GET_CST_GROUP}_FAILED`,
    data,
})
export const loadMoreCstGroup = data => ({
    type: `${LOAD_MORE_CST_GROUP}_PENDING`,
    data,
})
export const loadMoreCstGroupSuccess = data => ({
    type: `${LOAD_MORE_CST_GROUP}_SUCCESS`,
    data,
})
export const loadMoreCstGroupFailed = data => ({
    type: `${LOAD_MORE_CST_GROUP}_FAILED`,
    data,
})

export const addCstGroup = data => ({
    type: `${ADD_CST_GROUP}_PENDING`,
    data,
})
export const addCstGroupSuccess = data => ({
    type: `${ADD_CST_GROUP}_SUCCESS`,
    data,
})
export const addCstGroupFailed = data => ({
    type: `${ADD_CST_GROUP}_FAILED`,
    data,
})
export const getSingleCstGroup = data => ({
    type: `${GET_SINGLE_CST}_PENDING`,
    data,
})
export const getSingleCstGroupSuccess = data => ({
    type: `${GET_SINGLE_CST}_SUCCESS`,
    data,
})
export const getSingleCstGroupFailed = data => ({
    type: `${GET_SINGLE_CST}_FAILED`,
    data,
})

export const editCstGroup = data => ({
    type: `${EDIT_CST_GROUP}_PENDING`,
    data,
})
export const editCstGroupSuccess = data => ({
    type: `${EDIT_CST_GROUP}_SUCCESS`,
    data,
})
export const editCstGroupFailed = data => ({
    type: `${EDIT_CST_GROUP}_FAILED`,
    data,
})
export const resetStatus = () => ({
    type: `${RESET_STATUS}`,
})
export const resetValue = () => ({
    type: `${RESET_VALUE}`,
})

export const validateCC = data => ({
    type: `${VALIDATE_CHARGE_CODE}_PENDING`,
    data,
})
export const validateCCSuccess = data => ({
    type: `${VALIDATE_CHARGE_CODE}_SUCCESS`,
    data,
})
export const validateCCFailed = data => ({
    type: `${VALIDATE_CHARGE_CODE}_FAILED`,
    data,
})
