import React, { Component } from 'react'
//import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Tabs } from 'antd'
import { NavLink } from 'react-router-dom'
import Modal from 'react-responsive-modal'
import ReactSliderLibrary from '../ReactSliderLibrary'
import Inputbox from '../Inputbox'
import PhaseModal from '../PhaseModal'
import InfiniteScroll from 'react-infinite-scroller'
import { withAlert } from 'react-alert'
import c from 'classnames'
import {
    SITE_TXT,
    isMobile,
    isAdmin,
    EMAIL_PATTERN_DOMAIN,
    STATUS_SUCCESS,
    isIphoneX,
    STATUS_FAILURE,
    isTablet,
} from '../../Utils/constants'
import { getFileExt, isValidPattern, isEmpty } from '../../Utils/functions'
import { DownloadIcon, MailtoIcon, AddIcon, PencilIcon, ShareIcon, CopyDocs } from '../../Utils/svg'
import { getPrefixedUrl } from '../../Utils/apis'
import './index.css'
import Loader from '../Loader'
import Checkbox from '../Checkbox'
import LibrarySidebar from '../LibrarySidebar'
const { TabPane } = Tabs

function ValidateEmail(mail) {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
        return true
    }
    return false
}
class LibraryCategory extends Component {
    constructor(props) {
        super(props)
        this.updateEditExpert = this.updateEditExpert.bind(this)
        this.state = {
            selected: {},
            selectAll: 0,
            isEditPhaseMode: false,
            selTabName: sessionStorage.getItem('selTabName') ? sessionStorage.getItem('selTabName') : '',
            selectedTab: sessionStorage.getItem('selectedTab')
                ? parseInt(sessionStorage.getItem('selectedTab'), 10)
                : 0,
            itemId: null,
            open: false,
            phaseId: sessionStorage.getItem('selTabId') ? sessionStorage.getItem('selTabId') : '',
            pageNumber: 1,
            pageSize: 20,
            sortColumn: 'contentName',
            sortType: 'ASC',
            categoryData: null,
            documentsData: {},
            search: '',
            downloadId: '',
            selectOnBasisOfTabId: false,
            redirectData: this.props.location.state ? this.props.location.state.item : null,
            downloadDoc: false,
            selectContentName: '',
            selectedContentExt: '',
            email: '',
            submitted: false,
            isLoading: false,
            emailSuccess: false,
            downloadLoader: false,
            editStatus: this.props.editStatus,
            editExpert: false,
            showSidebar: false,
            isEditExpert: false,
            shareModalopen: false,
            emailTags: [],
            regenerateLink: true,
            postShareData: '',
            isChecked: true,
            isShareSubmitted: false,
            shareMultipleDocs: '',
            multipleDocs: [],
            isSendMultipleDocs: false,
            disabledSendBtn: true,
            isCheckedMultiple: true,
            invalidMail: false,
            isSingleShare: false,
        }
        this.onSubmitShareDocs = this.onSubmitShareDocs.bind(this)
        this.copyURLLink = this.copyURLLink.bind(this)
        this.regenerateShareLink = this.regenerateShareLink.bind(this)
        this.outMouseOnCopy = this.outMouseOnCopy.bind(this)
    }
    updateEditExpert(isEditExpert) {
        this.setState({
            isEditExpert: isEditExpert,
        })
    }
    handleSelect = (index, last, event) => {
        sessionStorage.setItem('selectedTab', index)
        this.setState({
            selectedTab: index,
            selected: {},
            search: '',
            selTabName: sessionStorage.getItem('selTabName'),
            phaseId: sessionStorage.getItem('selTabId'),
        })
        /* if (isMobile || isIphoneX) {
            document.getElementsByClassName('react-tabs__tab--selected')[0]
            .classList.remove('react-tabs__tab--selected')
            event.target.classList.add('react-tabs__tab--selected')
            document.querySelector('.search-inp').value = ''
        } */
    }

    onCloseModal = () => {
        this.setState({ open: false })
    }

    onOpenModal = () => {
        this.setState({ open: true })
    }
    setTagNameHandler = index => {
        this.handleSelect(index)
        //return
        const phase = this.state.categoryData.data[index]
        sessionStorage.setItem('selTabName', phase.name)
        sessionStorage.setItem('selTabId', phase.id)
        this.setState(
            {
                selTabName: phase.name,
                phaseId: phase.id,
                pageNumber: 1,
                selected: {},
                selectAll: 0,
            },
            () => {
                let data = {
                    phaseId: this.state.phaseId,
                    search: this.state.search,
                    pageSize: isIphoneX || window.innerWidth <= 767 ? 100000 : this.state.pageSize,
                    sortType: this.state.sortType,
                    sortColumn: this.state.sortColumn,
                    pageNumber: 1,
                }

                this.props.libraryDocuments(data)
            }
        )
    }

    editClickhandler = () => {
        this.setState({ isEditPhaseMode: true })
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { categoryData, documentsData, editStatus } = nextProps
        this.setState(
            {
                editStatus,
            },
            () => {
                if (editStatus === STATUS_SUCCESS) {
                    this.setState(
                        {
                            phase: this.state.phaseToEdit,
                            selTabName: this.state.phaseToEdit.name,
                        },
                        () => {
                            sessionStorage.setItem('selTabName', this.state.phaseToEdit.name)
                            this.onCloseModal()
                        }
                    )
                } else if (editStatus === STATUS_FAILURE) {
                    this.setState(
                        {
                            phase: sessionStorage.getItem('selTabName'),
                            selTabName: sessionStorage.getItem('selTabName'),
                        },
                        () => {
                            sessionStorage.setItem('selTabName', this.state.phase)
                        }
                    )
                }
            }
        )
        if (categoryData !== this.state.categoryData) {
            this.setState({ categoryData }, () => {
                if (categoryData.data.length > 0) {
                    this.setState(
                        {
                            phaseId:
                                this.state.redirectData !== null
                                    ? this.state.redirectData.phaseId
                                    : this.state.phaseId
                                    ? this.state.phaseId
                                    : categoryData.data[0].id,
                            search:
                                this.state.redirectData !== null
                                    ? this.state.redirectData.contentName
                                    : this.state.search,
                        },
                        () => {
                            let data = {
                                phaseId: this.state.phaseId,
                                search: this.state.search,
                                pageSize: isIphoneX || window.innerWidth <= 767 ? 100000 : this.state.pageSize,
                                sortType: this.state.sortType,
                                sortColumn: this.state.sortColumn,
                                pageNumber: 1,
                            }
                            this.props.libraryDocuments(data)
                        }
                    )
                }
            })
        }
        if (documentsData !== this.state.documentsData) {
            this.setState({ documentsData }, () => {
                this.setState({ isLoading: false })
            })
        }

        if (this.state.redirectData) {
            if (nextProps.categoryData && this.state.selectOnBasisOfTabId === false) {
                nextProps.categoryData.data.forEach((val, key) => {
                    if (val.id === this.state.redirectData.phaseId) {
                        this.setState(
                            {
                                selectedTab: key,
                                selectOnBasisOfTabId: true,
                            },
                            () => {
                                sessionStorage.setItem('selTabName', val.name)
                            }
                        )
                    }
                })
            }
        }

        if (this.state.emailSuccess === true) {
            if (nextProps.emailDocumentsData === STATUS_SUCCESS) {
                this.setState({ emailSuccess: false })
                this.onCloseModal()
            }
        }

        if (this.state.downloadDoc === true) {
            if (nextProps.downloadDocumentsData !== null) {
                const { downloadDocumentsData } = nextProps
                if (downloadDocumentsData.result && downloadDocumentsData.result.fileURL) {
                    this.props.saveDocuments(getPrefixedUrl(downloadDocumentsData.result.fileURL))
                    this.setState({ downloadDoc: false, downloadId: '' })
                }
            }
        }
    }

    downloadLibDocs() {
        const selVals = Object.keys(this.state.selected)
        const ids = selVals.join(',')
        this.setState({ downloadDoc: true, downloadId: ids }, () =>
            this.props.downloadDocuments({ ids, tempId: Math.random() })
        )
    }

    emailLibDocs(e) {
        e.preventDefault()
        const selVals = Object.keys(this.state.selected)
        const ids = selVals.join(',')

        this.setState({ submitted: true }, () => {
            const { email } = this.state
            if (email && isValidPattern(email['email'], EMAIL_PATTERN_DOMAIN)) {
                const data = {
                    emailId: email['email'].toLowerCase(),
                    ids: ids,
                }
                this.props.emailDocuments(data)
                this.setState({ open: false })
            }
        })
    }
    componentDidMount() {
        const { id } = this.props.match.params
        this.setState({ itemId: +id }, () => sessionStorage.setItem('lastTopicPage', this.state.itemId))
        this.props.libraryCategory()
    }

    afterTrash() {
        setTimeout(() => {
            sessionStorage.setItem('selTabId', this.state.categoryData.data[0].id)
            sessionStorage.setItem('selectedTab', 0)
            sessionStorage.setItem('selTabName', this.state.categoryData.data[0].name)
            this.setState(
                {
                    selectedTab: sessionStorage.getItem('selectedTab'),
                    phaseId: sessionStorage.getItem('selTabId'),
                    selTabName: sessionStorage.getItem('selTabName'),
                },
                () => {
                    if (this.state.categoryData.data.length > 0) {
                        let data = {
                            phaseId: this.state.phaseId,
                            search: this.state.search,
                            pageSize: isIphoneX || window.innerWidth <= 767 ? 100000 : this.state.pageSize,
                            sortType: this.state.sortType,
                            sortColumn: this.state.sortColumn,
                            pageNumber: 1,
                        }
                        this.handleSelect(parseInt(this.state.selectedTab, 10))
                        this.props.libraryDocuments(data)
                    }
                }
            )
        }, 2000)
    }

    afterAdd(selTab) {
        sessionStorage.setItem('selectedTab', selTab)
        this.setState(
            {
                selectedTab: selTab,
                phaseId: sessionStorage.getItem('selTabId'),
                selTabName: sessionStorage.getItem('selTabName'),
            },
            () => {
                this.handleSelect(this.state.selectedTab)
            }
        )
    }

    afterEdit() {
        this.setState({
            selTabName: sessionStorage.getItem('selTabName'),
        })
    }

    sortDocuments = col => {
        this.setState(
            {
                pageNumber: 1,
                sortType: this.state.sortType === 'ASC' ? 'DESC' : 'ASC',
                sortColumn: col,
                isLoading: true,
            },
            () => {
                let data = {
                    phaseId: this.state.phaseId,
                    search: this.state.search,
                    pageSize: isIphoneX || window.innerWidth <= 767 ? 100000 : this.state.pageSize,
                    sortType: this.state.sortType,
                    sortColumn: this.state.sortColumn,
                    pageNumber: this.state.pageNumber,
                }
                this.props.libraryDocuments(data)
            }
        )
    }

    loadMoreDocuments() {
        if (!this.state.isLoading) {
            this.setState({ pageNumber: this.state.pageNumber + 1, isLoading: true }, () => {
                const data = {
                    phaseId: this.state.phaseId,
                    pageNumber: this.state.pageNumber,
                    search: this.state.search,
                    pageSize: this.state.pageSize,
                    sortType: this.state.sortType,
                    sortColumn: this.state.sortColumn,
                }
                this.props.libraryDocumentsMore(data)
            })
        }
    }

    searchDocuments = (field, value) => {
        clearTimeout(this.delayTimer)
        this.delayTimer = setTimeout(() => {
            this.setState({ search: value }, () => {
                let data = {
                    phaseId: this.state.phaseId,
                    search: this.state.search,
                    pageSize: isIphoneX || window.innerWidth <= 767 ? 100000 : this.state.pageSize,
                    sortType: this.state.sortType,
                    sortColumn: this.state.sortColumn,
                    pageNumber: 1,
                }
                this.props.libraryDocuments(data)
            })
        }, 1000)
    }

    showSortIcon(colid) {
        return this.state.sortColumn === colid ? this.state.sortType.toLowerCase() : ''
    }

    editPhaseHandler = phase => {
        this.setState({ phaseToEdit: phase }, () => {
            this.props.editPhase(phase)
        })
    }
    toggleRow(id, shareMultipleDocs, e) {
        const newSelected = Object.assign({}, this.state.selected)
        newSelected[id] = !this.state.selected[id]
        if (newSelected[id] === false) {
            delete newSelected[id]
        }

        let tempList = [...this.state.multipleDocs]
        if (e.target.checked) {
            tempList.push(shareMultipleDocs)
        } else {
            const id = tempList.findIndex(item => item.id === shareMultipleDocs.id)
            tempList.splice(id, 1)
        }

        this.setState({
            multipleDocs: tempList,
        })
        // this.state.multipleDocs.push(shareMultipleDocs)
        let shareDocsObj = {
            documents: tempList,
            // documents: this.state.multipleDocs,
        }

        this.setState({ shareMultipleDocs: shareDocsObj, isSendMultipleDocs: true })
        this.state.documentsData.data.forEach(x => {
            const selVals = Object.keys(newSelected)
            const newids = selVals.join(',')

            if (newids === x.id) {
                this.setState({
                    selectContentName: x.contentName,
                    selectedContentExt: x.contentExtension,
                })
            }
            //const newSelectedDocs = newids ? newids.split(',') : []
        })
        this.setState({
            selected: newSelected,
            selectAll: 2,
            isSingleShare: false,
            //selectContentName: contentName,
            // selectedContentExt: contentExtension,
        })
    }
    handleChange = (field, value) => {
        this.setState({ email: { ...this.state.email, [field]: value } }, () => {})
    }

    handleSidebar = () => {
        this.setState({
            showSidebar: !this.state.showSidebar,
        })
    }

    toggleSelectAll() {
        let newSelected = {}
        let tempMultipleDocs = []
        if (this.state.selectAll === 0) {
            this.state.documentsData.data.forEach(x => {
                newSelected[x.id] = true
                tempMultipleDocs.push({
                    box_file_id: x.box_file_id,
                    file_name: `${x.contentName}.${x.contentExtension}`,
                    id: x.id,
                })
            })
        }

        this.setState({
            selected: newSelected,
            multipleDocs: tempMultipleDocs,
            selectAll: this.state.selectAll === 0 ? 1 : 0,
            isSingleShare: false,
        })
    }

    componentDidUpdate() {
        if (this.props.closePopUpBox && this.state.shareModalopen) this.onCloseShareDocsModal()
        // if (this.props.isCopyDoc && this.props.isCopyDoc && this.props.isCopyDoc.length < 1) {
        //     this.props.postCopyShareDocuments(this.state.postShareData)
        // }
    }

    onOpenModalShareDocs = (id, shareDoc) => {
        const data = { id: id, doc: { documents: [shareDoc] } }
        this.props.copyShareDocuments(data)
        this.setState({
            shareModalopen: true,
            postShareData: shareDoc,
            isChecked: false,
            isCheckedMultiple: true,
            emailTags: [],
            disabledSendBtn: true,
            isSingleShare: true,
        })
    }

    onCloseShareDocsModal = () => {
        this.setState({ shareModalopen: false })
        this.setState({ tag: '' })
    }

    removeTag = i => {
        const newEmailTags = [...this.state.emailTags]
        newEmailTags.splice(i, 1)
        this.setState({ emailTags: newEmailTags })
    }

    emailInputKeyDown = (e, field) => {
        const val = e.target.value
        this.setState({ invalidMail: false })
        if (this.state.emailTags.length < 5) {
            if (this.state.emailTags.length > 0) {
                this.setState({ disabledSendBtn: false })
            } else {
                this.setState({ disabledSendBtn: true })
            }
            if ((e.key === 'Enter' && val) || (e.key === ',' && val)) {
                if (val.includes('mckinsey') && ValidateEmail(val)) {
                    if (this.state.emailTags.find(tag => tag.toLowerCase() === val.toLowerCase())) {
                        return
                    }
                    this.setState({ emailTags: [...this.state.emailTags, val] })
                    this.tagInput.value = null
                    this.setState({ disabledSendBtn: false })
                } else {
                    this.setState({ invalidMail: true })
                }
            } else if (e.key === 'Backspace' && !val) {
                this.setState({ disabledSendBtn: true })
                this.removeTag(this.state.emailTags.length - 1)
            }
        } else {
            return
        }
    }

    onSubmitShareDocs(e) {
        e.preventDefault()

        const regenLink = this.props.reGenLink && this.props.reGenLink.batch_id

        const sharedFileId = this.props.isCopyDoc && this.props.isCopyDoc.batch_id

        const data = {
            recipients: this.state.emailTags,
            batch_id: sharedFileId ? sharedFileId : regenLink,
        }

        if (this.state.isSingleShare)
            data.shared_file_id = this.props.isCopyDoc ? this.props.isCopyDoc.shared_file_id : ''

        this.props.postShareDocuments(data)
    }

    copyURLLink() {
        let copyText = document.getElementById('copy-clip')
        copyText.select()
        copyText.blur()
        copyText.setSelectionRange(0, 99999)
        document.execCommand('copy')
        let tooltip = document.getElementById('myTooltip')
        tooltip.innerHTML = 'Copied'
    }

    outMouseOnCopy() {
        let tooltip = document.getElementById('myTooltip')
        tooltip.innerHTML = 'Copy'
    }

    regenerateShareLink() {
        this.props.postCopyShareDocuments({ documents: [this.state.postShareData] })
    }

    onOpenModalMultipleShareDocs() {
        this.props.postCopyShareDocuments({ documents: this.state.multipleDocs })
        this.setState({ shareModalopen: true, isCheckedMultiple: false, emailTags: [], disabledSendBtn: true })
    }
    render() {
        const tabId = this.state.itemId

        const { open, isEditExpert, selectedTab, shareModalopen, isCheckedMultiple } = this.state
        let docFileShareId =
            this.props.reGenLink && this.props.reGenLink && this.props.reGenLink && this.props.reGenLink.document
        let lengthTable =
            this.state.documentsData && this.state.documentsData.data ? this.state.documentsData.data.length : 0
        let totalRecords = this.state.documentsData ? this.state.documentsData.totalRecords : 0

        let shareFileId =
            this.props.isCopyDoc && this.props.isCopyDoc.shared_file_id
                ? this.props.isCopyDoc.shared_file_id
                : this.props.isCopyDoc && this.props.isCopyDoc.box_file_id

        const {
            TXT_ADD,
            TXT_LOADING,
            TXT_LIBRARY,
            TXT_CONTENT,
            TXT_FILE_FORMAT,
            TXT_FILE_TYPE,
            TXT_WILL_COME_SOON,
            TXT_ADD_PHASE,
            TXT_EDIT_DELETE_PHASE,
            TXT_NO_PHASE_AVAILABLE,
            TXT_NO_DOCUMENT_AVAILABLE,
            TXT_DOCUMENTS,
            TXT_SEARCHBY_DOCNAME,
            TXT_SHOWING,
            TXT_EDIT_DOCUMENT,
            TXT_CANCEL,
            TXT_SUBMIT,
            TXT_EMAIL_DOCUMENTS,
            TXT_EMAIL,
            TXT_EMAIL_REQUIRED,
            TXT_DOWNLOAD,
            TXT_EMAILADDRESS,
            TXT_EMAIL_INVALID,
            TXT_DOWNLOAD_COUNT,
            ACTION_DOCS_LIBRARY,
            TXT_SEND,
            SHARE_CANCEL,
        } = SITE_TXT
        const {
            categoryData,
            documentsData,
            submitted,
            email,
            downloadLoader,
            showSidebar,
            isShareSubmitted,
            emailTags,
            disabledSendBtn,
        } = this.state

        const rows = documents =>
            documents.map(item => {
                const shareDocsData = {
                    id: item.id,
                    box_file_id: item.box_file_id,
                    file_name: `${item.contentName}.${item.contentExtension}`,
                }
                return (
                    <tr
                        className={
                            this.props.location.state
                                ? this.props.location.state.item.id === item.id
                                    ? 'active'
                                    : ''
                                : ''
                        }
                        key={item.id}
                    >
                        <td>
                            <div className="checkbox-wrapper" onClick={e => this.toggleRow(item.id, shareDocsData, e)}>
                                <Checkbox itemId={item.id} checked={this.state.selected[item.id] === true} />
                            </div>
                        </td>
                        <td>{item.contentName}</td>
                        <td>{item.contentType}</td>
                        <td>{item.downloadCount}</td>
                        <td className="text-center">
                            <span className={'extBox ' + getFileExt(item.contentFormatIcon)}>
                                {getFileExt(item.contentFormatIcon).toUpperCase()}
                            </span>
                        </td>
                        <td style={{ display: 'flex', justifyContent: 'center' }}>
                            <span
                                title="Click & Share Document"
                                onClick={() => this.onOpenModalShareDocs(item.box_file_id, shareDocsData)}
                                className="share-icon"
                                style={{ marginRight: 10 }}
                            >
                                <ShareIcon fontColorCode="#333333" />
                            </span>
                            {!isMobile && !isIphoneX && isAdmin && (
                                <NavLink
                                    title={TXT_EDIT_DOCUMENT}
                                    to={'/admin/edit/' + item.id}
                                    className="addEditIcon"
                                    style={{ marginRight: 0, marginLeft: 10 }}
                                >
                                    <PencilIcon />
                                </NavLink>
                            )}
                        </td>
                    </tr>
                )
            })

        const dotSlider = {
            dots: true,
            arrows: false,
            slidesToShow: 1,
            rows: 3,
            slidesToScroll: 1,
            infinite: false,
            speed: 500,
            className: 'custom-slider',
        }
        const selectAllCheckboxComp = label => (
            <div className="checkbox-wrapper selectall-chk" onClick={() => this.toggleSelectAll()}>
                <Checkbox
                    checked={this.state.selectAll === 1}
                    isIndeterminate={this.state.selectAll === 2}
                    label={label}
                />
            </div>
        )
        return (
            <span className="loader-span">
                {downloadLoader ? <Loader /> : null}
                <main className={`library-category-container ${isEditExpert ? 'editing-mode' : ''}`}>
                    {categoryData && (
                        <div className="lib-header">
                            <section className="lib-header-content">
                                <NavLink to="/library">{TXT_LIBRARY} </NavLink>
                                <label className="breadcrumb-separator"> / </label>
                                <label>{categoryData.header}</label>
                            </section>
                            {/* {!isMobile && !isIphoneX ? (
                                <section className="lib-header-content">
                                    <NavLink to="/library">{TXT_LIBRARY} </NavLink>
                                    <label className="breadcrumb-separator"> / </label>
                                    <label>{categoryData.header}</label>
                                </section>
                            ) : (
                                <section className="lib-header-content">
                                    <NavLink to="/library">{'<-- ' + TXT_LIBRARY}</NavLink>

                                    <label>{' > ' + categoryData.header}</label>
                                </section>
                            )} */}
                            <section className="addPhase-section">
                                {!isMobile && !isIphoneX && isAdmin ? (
                                    <PhaseModal
                                        label={TXT_ADD_PHASE}
                                        addPhaseHandler={this.props.addPhase}
                                        categoryName={categoryData.header}
                                        categoryId={tabId}
                                        afterAdd={() => this.afterAdd(categoryData.data.length)}
                                    />
                                ) : null}
                                {!isMobile && !isIphoneX && isAdmin && categoryData.data.length > 0 && (
                                    <PhaseModal
                                        label={TXT_EDIT_DELETE_PHASE}
                                        categoryName={categoryData.header}
                                        categoryId={tabId}
                                        phase={
                                            this.state.selTabName === ''
                                                ? categoryData.data[0]
                                                    ? categoryData.data[0].name
                                                    : ''
                                                : this.state.selTabName
                                        }
                                        phaseId={
                                            categoryData.data[this.state.selectedTab]
                                                ? categoryData.data[this.state.selectedTab].id
                                                : categoryData.data[0]
                                        }
                                        editPhase={phasetoedit => this.editPhaseHandler(phasetoedit)}
                                        trashPhase={this.props.trashPhase}
                                        afterTrash={() => this.afterTrash()}
                                        afterEdit={() => this.afterEdit()}
                                    />
                                )}
                            </section>
                        </div>
                    )}

                    <div className="library-category-content">
                        {isMobile && isIphoneX && (
                            <aside className="aside-right">
                                <span>{TXT_WILL_COME_SOON}</span>
                            </aside>
                        )}
                        <div className="main-content">
                            {categoryData && categoryData.data && categoryData.data.length > 0 ? (
                                <Tabs
                                    defaultActiveKey={`${selectedTab}`}
                                    onChange={this.setTagNameHandler}
                                    animated={false}
                                >
                                    {categoryData.data.map((item, index) => (
                                        <TabPane key={index} tab={item.name}>
                                            <div className="above-table-section">
                                                <div className="search-list-section">
                                                    <Inputbox
                                                        placeholder={TXT_SEARCHBY_DOCNAME}
                                                        type="text"
                                                        fieldFor="search"
                                                        onChange={this.searchDocuments}
                                                        customClass="search-inp"
                                                    />
                                                    {documentsData && documentsData.data && (
                                                        <div className="showing-records">
                                                            {TXT_SHOWING}
                                                            <span className="total-rec">
                                                                {' '}
                                                                {documentsData.data.length}/{documentsData.totalRecords}{' '}
                                                                {TXT_DOCUMENTS}
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>

                                                {!isMobile && !isIphoneX && isAdmin && (
                                                    <NavLink
                                                        onClick={() => this.setTagNameHandler(item)}
                                                        to={'/admin/' + item.id}
                                                        className="addEditIcon"
                                                    >
                                                        <AddIcon />
                                                        {TXT_ADD}
                                                    </NavLink>
                                                )}
                                            </div>

                                            {documentsData &&
                                            documentsData.data &&
                                            documentsData.totalRecords > 0 &&
                                            !isEmpty(this.state.selected) ? (
                                                <div className="actionBtns">
                                                    <button className="btn btn-primary" onClick={this.onOpenModal}>
                                                        <span>
                                                            <MailtoIcon width="16" height="16" />
                                                            {TXT_EMAIL}
                                                        </span>
                                                    </button>
                                                    <button
                                                        className="btn btn-primary"
                                                        onClick={() => this.downloadLibDocs()}
                                                    >
                                                        <span>
                                                            <DownloadIcon width="16" height="16" />
                                                            {TXT_DOWNLOAD}
                                                        </span>
                                                    </button>
                                                    <button className="btn btn-primary">
                                                        <span
                                                            title="Click & Share Document"
                                                            onClick={() => this.onOpenModalMultipleShareDocs()}
                                                            className="share-icon"
                                                        >
                                                            <ShareIcon width="16" height="16" fontColorCode="#fff" />
                                                            Share
                                                        </span>
                                                    </button>

                                                    <Modal
                                                        open={open}
                                                        onClose={this.onCloseModal}
                                                        center
                                                        classNames={{
                                                            modal: 'comp-modal library-modal',
                                                        }}
                                                    >
                                                        <h2 className="heading-class">{TXT_EMAIL_DOCUMENTS}</h2>
                                                        <form>
                                                            <div
                                                                className={
                                                                    'form-group' +
                                                                    (submitted && !email ? ' has-error' : '')
                                                                }
                                                            >
                                                                <label htmlFor="email" className="inputlabels">
                                                                    {TXT_EMAILADDRESS}
                                                                </label>
                                                                <Inputbox
                                                                    type="email"
                                                                    fieldFor="email"
                                                                    placeholder="Enter email address here..."
                                                                    onChange={(field, value) =>
                                                                        this.handleChange(field, value)
                                                                    }
                                                                    customClass="email"
                                                                />
                                                                {submitted && !email && (
                                                                    <div className="help-block">
                                                                        {TXT_EMAIL_REQUIRED}
                                                                    </div>
                                                                )}
                                                                {submitted &&
                                                                    email &&
                                                                    !isValidPattern(
                                                                        email['email'],
                                                                        EMAIL_PATTERN_DOMAIN
                                                                    ) && (
                                                                        <div
                                                                            className="help-block "
                                                                            style={{
                                                                                color: '#d84315',
                                                                            }}
                                                                        >
                                                                            {TXT_EMAIL_INVALID}
                                                                        </div>
                                                                    )}
                                                            </div>

                                                            <div className="butClass ">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-default cancel-Btn"
                                                                    onClick={this.onCloseModal}
                                                                >
                                                                    {TXT_CANCEL}
                                                                </button>
                                                                <button
                                                                    onClick={this.emailLibDocs.bind(this)}
                                                                    type="button"
                                                                    className="btn btn-primary"
                                                                >
                                                                    {TXT_SUBMIT}
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </Modal>
                                                </div>
                                            ) : null}
                                            {documentsData && documentsData.totalRecords > 0 ? (
                                                !isMobile && !isIphoneX ? (
                                                    <InfiniteScroll
                                                        pageStart={0}
                                                        loadMore={() => this.loadMoreDocuments()}
                                                        hasMore={lengthTable < totalRecords}
                                                        loader={
                                                            <div className="loader" key={0}>
                                                                {TXT_LOADING}
                                                            </div>
                                                        }
                                                        useWindow={true}
                                                    >
                                                        <table className="table custom-table">
                                                            <thead>
                                                                <tr key={item.id}>
                                                                    <th id="doc_check">{selectAllCheckboxComp()}</th>
                                                                    <th
                                                                        id="contentName"
                                                                        className={this.showSortIcon('contentName')}
                                                                        onClick={() =>
                                                                            this.sortDocuments('contentName')
                                                                        }
                                                                    >
                                                                        {TXT_CONTENT}
                                                                    </th>
                                                                    <th
                                                                        id="contentType"
                                                                        className={this.showSortIcon('contentType')}
                                                                        onClick={() =>
                                                                            this.sortDocuments('contentType')
                                                                        }
                                                                    >
                                                                        {TXT_FILE_TYPE}
                                                                    </th>
                                                                    <th
                                                                        id="downloadCount"
                                                                        className={this.showSortIcon('downloadCount')}
                                                                        onClick={() =>
                                                                            this.sortDocuments('downloadCount')
                                                                        }
                                                                    >
                                                                        {TXT_DOWNLOAD_COUNT}
                                                                    </th>
                                                                    <th
                                                                        id="contentFormat"
                                                                        className={this.showSortIcon('contentFormat')}
                                                                        onClick={() =>
                                                                            this.sortDocuments('contentFormat')
                                                                        }
                                                                        style={{ textAlign: 'center' }}
                                                                    >
                                                                        {TXT_FILE_FORMAT}
                                                                    </th>
                                                                    <th style={{ textAlign: 'center' }}>
                                                                        {ACTION_DOCS_LIBRARY}
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>{rows(documentsData.data)}</tbody>
                                                        </table>
                                                    </InfiniteScroll>
                                                ) : (
                                                    <div className="tab-list">
                                                        {selectAllCheckboxComp(SITE_TXT.TXT_SELECTALL)}
                                                        <ReactSliderLibrary
                                                            listId={item.id}
                                                            topCategoryName={item.name}
                                                            listData={documentsData.data}
                                                            settings={dotSlider}
                                                            noImage={true}
                                                            isPhaseList={false}
                                                            toggleRow={id => this.toggleRow(id)}
                                                            selected={this.state.selected}
                                                        />
                                                        <div className={'bottom-panel'}>
                                                            <LibrarySidebar
                                                                phaseId={this.state.itemId}
                                                                editExpert={this.state.editExpert}
                                                                updateEditExpert={this.updateEditExpert}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            ) : (
                                                <div className="no-result">{TXT_NO_DOCUMENT_AVAILABLE}</div>
                                            )}
                                        </TabPane>
                                    ))}
                                </Tabs>
                            ) : (
                                <div className="no-result">{TXT_NO_PHASE_AVAILABLE}</div>
                            )}
                            {/* {categoryData && categoryData.data && categoryData.data.length > 0 ? (
                                <Tabs onSelect={this.handleSelect} selectedIndex={parseInt(this.state.selectedTab, 10)}>
                                    <TabList>
                                        {!isMobile &&
                                            !isIphoneX &&
                                            categoryData.data.map(item => (
                                                <Tab key={item.id} onClick={() => this.setTagNameHandler(item)}>
                                                    {item.name}
                                                </Tab>
                                            ))}
                                        {(isMobile || isIphoneX) && (
                                            <Slider {...settings}>
                                                {categoryData.data.map(item => (
                                                    <div key={item.id}>
                                                        <Tab key={item.id} onClick={() => this.setTagNameHandler(item)}>
                                                            {item.name}
                                                        </Tab>
                                                        <div className="blue-bar" />
                                                    </div>
                                                ))}
                                            </Slider>
                                        )}
                                    </TabList>

                                    
                                </Tabs>
                            ) : (
                                <div className="no-result">{TXT_NO_PHASE_AVAILABLE}</div>
                            )} */}
                        </div>

                        <aside className="aside-right">
                            <span>{TXT_WILL_COME_SOON}</span>
                        </aside>
                    </div>
                </main>
                <aside
                    className={c('aside-right-desktop', {
                        active: showSidebar,
                    })}
                >
                    {isTablet && (
                        <div className={c('floatingIcon')} onClick={this.handleSidebar}>
                            {'Library Owners'}
                        </div>
                    )}

                    {this.state.itemId && (
                        <LibrarySidebar
                            phaseId={this.state.itemId}
                            editExpert={this.state.editExpert}
                            updateEditExpert={this.updateEditExpert}
                            check={this.state.isChecked}
                        />
                    )}
                </aside>
                {/* ========================================== Open Document Share modal ================================================== */}
                <Modal
                    open={shareModalopen}
                    onClose={this.onCloseShareDocsModal}
                    center
                    classNames={{ modal: 'share-docs-modal' }}
                >
                    <h4 className="Share-Box-link-via-e">Share box link via email</h4>

                    <span>Send to</span>
                    {isShareSubmitted && !emailTags && <div className="help-block">{TXT_EMAIL_REQUIRED}</div>}
                    {isShareSubmitted && emailTags && !isValidPattern(emailTags['emailTags'], EMAIL_PATTERN_DOMAIN) && (
                        <div
                            className="help-block "
                            style={{
                                color: '#d84315',
                            }}
                        >
                            {TXT_EMAIL_INVALID}
                        </div>
                    )}
                    <div className="input-tag">
                        <ul className="input-tag__tags">
                            {this.state.emailTags &&
                                this.state.emailTags.map((tag, i) => (
                                    <li key={tag}>
                                        {tag}
                                        <button
                                            className="close-btn"
                                            type="button"
                                            onClick={() => {
                                                this.removeTag(i)
                                            }}
                                        >
                                            {' '}
                                            +
                                        </button>
                                    </li>
                                ))}
                            <li className="input-tag__tags__input">
                                <input
                                    type="email"
                                    id="email-tag"
                                    placeholder="Enter Email Address..."
                                    onKeyDown={this.emailInputKeyDown}
                                    ref={c => {
                                        this.tagInput = c
                                    }}
                                    field-for="email"
                                />
                            </li>
                        </ul>
                        {this.state.invalidMail ? (
                            <div
                                className="help-block "
                                style={{
                                    color: '#d84315',
                                }}
                            >
                                {TXT_EMAIL_INVALID}
                            </div>
                        ) : null}
                    </div>
                    {isCheckedMultiple ? <hr className="Line-bar" /> : ''}
                    {isCheckedMultiple ? (
                        <div>
                            <input
                                type="text"
                                id="copy-clip"
                                readOnly
                                value={`https://mckinsey.ent.box.com/file/${
                                    shareFileId === undefined ? docFileShareId : shareFileId
                                }`}
                            />
                            <span
                                className="copy-docs-icon tooltip-copy"
                                onClick={this.copyURLLink}
                                onMouseOut={this.outMouseOnCopy}
                            >
                                <CopyDocs />
                                <span className="tooltiptext" id="myTooltip">
                                    Copy to Link
                                </span>
                            </span>
                        </div>
                    ) : (
                        ''
                    )}
                    {isCheckedMultiple ? (
                        <div className="regen-link" onClick={this.regenerateShareLink}>
                            Regenerate link
                        </div>
                    ) : (
                        ''
                    )}
                    <div className="modal-button-class">
                        <button
                            type="button"
                            className="btn btn-default modal-cancel-btn"
                            onClick={this.onCloseShareDocsModal}
                        >
                            {SHARE_CANCEL}
                        </button>
                        <button
                            disabled={disabledSendBtn}
                            onClick={this.onSubmitShareDocs}
                            type="button"
                            className="btn btn-primary modal-btn"
                        >
                            {TXT_SEND}
                        </button>
                    </div>
                </Modal>
                {/* ====================================== End Document Share modal ====================================================== */}
            </span>
        )
    }
}

export default withAlert(LibraryCategory)
