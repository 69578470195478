import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { LopContent } from '../../Components'
import { setLopHeader } from '../App/actions'
import {
    fetchLopDeatils,
    saveLopDetails,
    toggleSectionUpdate,
    fetchCurrentTemplate,
    deleteSection,
    deteleLop,
    resetStateToOriginal,
    restoreInTemplate4,
    contactWinRoomState,
    lockMyLop,
    publishLinkForClient,
    addExternalUser,
    updateLopSectionDetails,
    getMyLops,
    restoreSection,
    editMultipleVideos,
    addMultipleVideoData,
    updateMultipleVideos,
    getLopWelcome,
    setLopWelcome,
    updateLopWelcome,
} from './actions'
import {
    selectLopDetails,
    partnerDetails,
    selectStatus,
    sortedArray,
    removedContent,
    currentSlideContent,
    isListUpdated,
    isLopRemoved,
    lopError,
    retainState,
    additionalArticles,
    contactWinRoomFlag,
    lopLockFlag,
    publishLink,
    restoreLopStatus,
    welcomeVideo,
    isWelcomeLoading,
} from './selector'

import { selectWidth } from '../App/selector'

const mapStateToProps = createStructuredSelector({
    lopDetails: selectLopDetails(),
    partnerDetails: partnerDetails(),
    status: selectStatus(),
    sortedArray: sortedArray(),
    removedContent: removedContent(),
    updateCurrentSlideNumber: currentSlideContent(),
    isListUpdated: isListUpdated(),
    lopRemoved: isLopRemoved(),
    lopError: lopError(),
    appWidth: selectWidth(),
    retainState: retainState(),
    additionalArticles: additionalArticles(),
    contactWinRoomFlag: contactWinRoomFlag(),
    lopLockFlag: lopLockFlag(),
    publishLink: publishLink(),
    restoreLopStatus: restoreLopStatus(),
    welcomeVideo: welcomeVideo(),
    isWelcomeLoading: isWelcomeLoading(),
})
const mapDispatchToProps = {
    setLopHeader,
    deleteSection,
    fetchLopDeatils,
    deteleLop,
    saveLopDetails,
    toggleSectionUpdate,
    fetchCurrentTemplate,
    resetStateToOriginal,
    restoreInTemplate4,
    contactWinRoomState,
    lockMyLop,
    publishLinkForClient,
    addExternalUser,
    updateLopSectionDetails,
    getMyLops,
    restoreSection,
    editMultipleVideos,
    addMultipleVideoData,
    updateMultipleVideos,
    getLopWelcome,
    setLopWelcome,
    updateLopWelcome,
}
export default connect(mapStateToProps, mapDispatchToProps)(LopContent)
