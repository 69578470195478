import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withAlert } from 'react-alert'
import c from 'classnames'
import { isEmpty } from 'lodash'
import { Link, Redirect } from 'react-router-dom'
import { Inputbox } from '../../Components'
import ThumbnailEditorModal from '../../Components/ThumbnailEditorModal'
import { SITE_TXT, IMAGE_CROP_LOP_LOGO, NAME_PATTERN } from '../../Utils/constants'
import { isValidPattern } from '../../Utils/functions'
import './index.scss'
import { contactWinRoomState } from '../../Containers/LopContent/actions'
import { contactWinRoomFlag } from '../../Containers/LopContent/selector'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import ContactWinRoom from '../../Components/ContactWinRoom'

class CreateLop extends Component {
    constructor(props) {
        super(props)
        const lopInfo = localStorage.getItem('myLopData') ? JSON.parse(localStorage.getItem('myLopData')) : {}
        const { name = '', id = null, images = [] } = lopInfo
        const lop_id = localStorage.getItem('lop_id')
        const logos = images.length ? images.map(userFile => ({ userFile })) : [{}, {}]
        this.state = {
            lopInfo,
            name,
            id,
            isModalOpen: false,
            hideAddLogo: false,
            redirectToSections: false,
            imageId: '',
            logos,
            lop_id,
            flagSwitch: true,
        }
    }

    componentDidMount = () => {
        const { id } = this.state
        this.props.setLopHeader(true, !id)
        localStorage.setItem('editMode', false)
        localStorage.setItem('currentSlide', 0)
        localStorage.setItem('sectionDetails', '')
    }

    componentDidUpdate(nextProps) {
        const { uploadLopStatus } = nextProps
        const { name } = this.state
        if (uploadLopStatus && uploadLopStatus.result) {
            localStorage.setItem(
                'myLopData',
                JSON.stringify({
                    id: uploadLopStatus.result.id,
                    permissionId: 4,
                    name,
                    images: [],
                })
            )
            setTimeout(() => this.setState({ redirectToSections: true }), 1000)
        }
    }

    componentWillUnmount() {
        this.props.setLopHeader()
    }

    handleChange = (a, name) => {
        this.setState({ name })
    }

    removeLogo = imageId => {
        const { logos } = this.state
        const updateLogos = [...logos.slice(0, imageId), ...logos.slice(imageId + 1)]
        this.setState({ logos: updateLogos, hideAddLogo: false })
    }

    addLogo = () => {
        this.setState({
            logos: [...this.state.logos, {}],
            hideAddLogo: true,
        })
    }

    openUploadModal = imageId => {
        this.setState({
            isModalOpen: true,
            imageId,
        })
    }

    closeUploadModal = () => {
        this.setState({
            isModalOpen: false,
        })
    }

    getBase64 = file => {
        let reader = new FileReader()
        reader.addEventListener(
            'load',
            () => {
                this.getSrcFunction(reader, file)
            },
            false
        )
        if (file) {
            reader.readAsDataURL(file)
        }
        this.closeUploadModal()
    }

    getSrcFunction = ({ result }, imageSrc) => {
        const { imageId, logos } = this.state
        const updateLogos = [
            ...logos.slice(0, imageId),
            {
                imageSrc,
                base64path: result,
            },
            ...logos.slice(imageId + 1),
        ]
        this.setState({ logos: updateLogos })
    }

    validateFormHere = (name, logos) => {
        const { alert } = this.props
        const { error } = alert
        const { TXT_LOP_MANDATORY, TXT_LOP_INVALID_PATTERN } = SITE_TXT
        if (name.trim() === '') {
            error(TXT_LOP_MANDATORY)
            return false
        } else if (!isValidPattern(name, NAME_PATTERN)) {
            error(TXT_LOP_INVALID_PATTERN)
            return false
        }
        // else if (logos.filter(x => !isEmpty(x)).length < 2) {
        //     error(LOP_LOGOS_MANDATORY)
        //     return false
        // }
        return true
    }

    uploadLopData = () => {
        const { name, logos, id, lop_id, flagSwitch } = this.state
        if (!id) {
            if (this.validateFormHere(name, logos)) {
                const data = new FormData()
                data.append('name', name)
                data.append('lop_id', lop_id)
                data.append('is_live', flagSwitch ? 1 : 0)
                logos.forEach(({ imageSrc }) => {
                    imageSrc && data.append('companyLogo', imageSrc)
                })
                this.props.uploadLop(data)
            }
        } else {
            this.setState({ redirectToSections: true })
        }
    }

    handleCloseModal = () => {
        this.props.contactWinRoomState(false)
    }

    handleSwitchChange = e => {
        console.log('handle switch change', e)
        const { flagSwitch } = this.state
        this.setState({ flagSwitch: !flagSwitch })
    }

    render() {
        const { contactWinRoomFlag } = this.props
        const { name, isModalOpen, logos, hideAddLogo, id, redirectToSections, imageId, lopInfo } = this.state

        return (
            <main className="createLopContainer clearfix">
                {redirectToSections && <Redirect to="/lopSections" />}
                <div
                    className={c('innerContent', {
                        maxSizedLimit: hideAddLogo,
                        emptyInnerContent: isEmpty(lopInfo),
                    })}
                >
                    {id ? (
                        <h3>{name}</h3>
                    ) : (
                        <Inputbox
                            autoFocus
                            label={SITE_TXT.TXT_LOP_CREATE}
                            placeholder={SITE_TXT.TXT_LOP_PLACEHOLDER}
                            value={name}
                            onChange={this.handleChange}
                            max="200"
                        />
                    )}
                </div>
                <div
                    className={c('buttonContainer', {
                        maxSizedLimit: hideAddLogo,
                    })}
                >
                    <div className="btn btn-primary btn-lg" onClick={this.uploadLopData}>
                        {SITE_TXT.TXT_NEXT}
                    </div>

                    {!id && (
                        <Link className="btn btn-outline-secondary btn-lg" to="/lop">
                            {SITE_TXT.TXT_CANCEL}
                        </Link>
                    )}
                </div>
                <div>
                    {isModalOpen && (
                        <ThumbnailEditorModal
                            isAdminPage
                            isModalOpen
                            onlyImage
                            thumbnailImg={logos[imageId].imageSrc || null}
                            userImg1Src={logos[imageId].base64path || null}
                            editCancel={() => this.closeUploadModal()}
                            handleSave={(a, b, img) => this.getBase64(img)}
                            imgCropObj={IMAGE_CROP_LOP_LOGO}
                            modalClass="albumUploadImage galleryImageModal createLopModal"
                        />
                    )}
                </div>

                {contactWinRoomFlag && (
                    <ContactWinRoom onCloseModal={this.handleCloseModal} contactWinRoomFlag={contactWinRoomFlag} />
                )}
            </main>
        )
    }
}

CreateLop.propTypes = {
    uploadLop: PropTypes.func.isRequired,
    setLopHeader: PropTypes.func.isRequired,
    contactWinRoomState: PropTypes.func.isRequired,
    contactWinRoomFlag: PropTypes.bool.isRequired,
}

const stateToProps = createStructuredSelector({
    contactWinRoomFlag: contactWinRoomFlag(),
})

const dispatchProps = {
    contactWinRoomState,
}

export default connect(stateToProps, dispatchProps)(withAlert(CreateLop))

// export default withAlert(CreateLop)
