import React, { Component } from 'react'
import ModuleHeader from '../ModuleHeader'
import { MMPRACTICE_HEADING } from '../../Utils/constants'

class MMPractice extends Component {
    render() {
        return (
            <main className="mmPractice-container">
                <ModuleHeader heading={MMPRACTICE_HEADING} />
            </main>
        )
    }
}

export default MMPractice
