import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import c from 'classnames'
import ReactTooltip from 'react-tooltip'

import DeleteLopModal from './DeleteLopModal'
import AddSection from './AddSection'
import ReorderSection from './ReorderSection'
import { isExternalClinet } from '../../../Utils/constants'
import { ReorderSectionsLop, EditIcon, TrashIcon, CollaboratorsIcon, DeletedSections } from '../../../Utils/svg'
import './index.scss'

class SliderSections extends Component {
    constructor(props) {
        super(props)
        const lopInfo = JSON.parse(localStorage.getItem('myLopData'))
        this.state = {
            lopInfo,
            openAddSection: false,
            openReorderSection: false,
            openDeleteLop: false,
        }
    }

    componentDidMount() {
        const { currentSlide } = this.props
        this.sliderWrapper && this.sliderWrapper.slickGoTo(currentSlide)
    }

    componentDidUpdate() {
        ReactTooltip.rebuild()
    }

    toggleSectionModal = () => {
        const { openAddSection } = this.state
        this.setState({
            openAddSection: !openAddSection,
        })
    }

    toggleReorderModal = () => {
        const { openReorderSection } = this.state
        this.setState({
            openReorderSection: !openReorderSection,
        })
    }

    toggleLopDeleteModal = () => {
        const { openDeleteLop } = this.state
        this.setState({
            openDeleteLop: !openDeleteLop,
        })
    }

    updateSlideNumber = currentSlide => {
        this.sliderWrapper && this.sliderWrapper.slickGoTo(currentSlide, true)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.updateSectionSlider) {
            this.updateSlideNumber(nextProps.currentSlide)
        }
    }

    render() {
        const {
            sections,
            currentSlide,
            updateCurrentSlide,
            editMode,
            reloadContent,
            lockMyLop,
            sliderSettings,
            content,
            duplicateLop,
            isExternal,
            handleCollaborator,
            toggleDeletedSections,
            collaboratorCount,
            deleteLop,
        } = this.props

        const { lopInfo, openAddSection, openReorderSection, openDeleteLop } = this.state
        const permissionId = parseInt(lopInfo.permissionId)
        const { sectionId } = content
        const colText = collaboratorCount > 1 ? 'Collaborators' : 'Collaborator'
        const colContent = collaboratorCount ? `${collaboratorCount} ${colText}` : null
        return (
            <div className="mainSectionContent">
                {!isExternalClinet && (
                    <div className="upperHeader">
                        {permissionId !== 1 && <span>{lopInfo.name}</span>}
                        <div className="headerIcons">
                            {!editMode && (permissionId === 2 || permissionId > 3) && (
                                <div className="duplicateBtn" onClick={duplicateLop}>
                                    Duplicate LOP
                                </div>
                            )}
                            {!isExternal && (
                                <span
                                    className="collabIcon"
                                    onClick={() => handleCollaborator(sectionId)}
                                    data-tip={colContent}
                                >
                                    <CollaboratorsIcon />
                                </span>
                            )}
                            {!editMode && (permissionId === 2 || permissionId > 3) && (
                                <span onClick={lockMyLop} data-tip="Edit LOP">
                                    <EditIcon />
                                </span>
                            )}
                            {editMode && (
                                <span
                                    className="reorderContent"
                                    onClick={this.toggleReorderModal}
                                    data-tip="Reorder Sections"
                                >
                                    <ReorderSectionsLop />
                                </span>
                            )}
                            {(permissionId === 2 || permissionId > 3) && editMode && (
                                <span className="addSection" onClick={this.toggleSectionModal} data-tip="Add Section" />
                            )}
                            {permissionId === 4 && editMode && (
                                <span
                                    className="reorderContent"
                                    onClick={toggleDeletedSections}
                                    data-tip="View Deleted"
                                >
                                    <DeletedSections />
                                </span>
                            )}
                            {permissionId === 4 && editMode && (
                                <span onClick={this.toggleLopDeleteModal} data-tip="Delete LOP">
                                    <TrashIcon />
                                </span>
                            )}
                        </div>
                    </div>
                )}
                {!isExternal && (
                    <div className="collabIconMobile" onClick={() => handleCollaborator(sectionId)}>
                        <CollaboratorsIcon />
                    </div>
                )}
                <div className="sectionsSlider">
                    {sections.length ? (
                        <Slider {...sliderSettings} ref={sliderWrapper => (this.sliderWrapper = sliderWrapper)}>
                            {sections.map((item, i) => (
                                <div
                                    key={item.sectionId}
                                    title={item.sectionName}
                                    className={c('ellipsisPro mainSlide', {
                                        active: i === currentSlide,
                                    })}
                                    onClick={() => {
                                        i !== currentSlide && updateCurrentSlide(i)
                                    }}
                                >
                                    {item.templateId === '2' ? item.sectionName.substr(0, 12) : item.sectionName}
                                </div>
                            ))}
                        </Slider>
                    ) : null}

                    {openAddSection && <AddSection myLopId={lopInfo.id} onClose={this.toggleSectionModal} />}
                    {openReorderSection && (
                        <ReorderSection
                            currentSlide={currentSlide}
                            sections={sections}
                            reloadContent={reloadContent}
                            onClose={this.toggleReorderModal}
                            content={content}
                        />
                    )}
                    {openDeleteLop && <DeleteLopModal onClose={this.toggleLopDeleteModal} deleteLop={deleteLop} />}
                    <ReactTooltip place="bottom" />
                </div>
            </div>
        )
    }
}

SliderSections.defaultProps = {
    collaboratorCount: 0,
}

SliderSections.propTypes = {
    isExternal: PropTypes.bool.isRequired,
    appWidth: PropTypes.number.isRequired,
    content: PropTypes.instanceOf(Object).isRequired,
    editMode: PropTypes.bool.isRequired,
    updateSectionSlider: PropTypes.bool.isRequired,
    currentSlide: PropTypes.number.isRequired,
    sliderSettings: PropTypes.instanceOf(Object).isRequired,
    sections: PropTypes.instanceOf(Array).isRequired,
    updateCurrentSlide: PropTypes.func.isRequired,
    deleteLop: PropTypes.func.isRequired,
    reloadContent: PropTypes.func.isRequired,
    duplicateLop: PropTypes.func.isRequired,
    handleCollaborator: PropTypes.func.isRequired,
    toggleDeletedSections: PropTypes.func.isRequired,
    collaboratorCount: PropTypes.number,
}

export default SliderSections
