import React, { Component } from 'react'
import Modal from 'react-responsive-modal'
import {
    SITE_TXT,
    IMAGE_CROP_OBJ_SQUARE,
    DOCUMENT_NAME_PATTERN,
    MAX_TAGS_ALLOWED_OFFICE,
    COMMA_KEY_CODE,
} from '../../../Utils/constants'
import ThumbnailEditorModal from '../../ThumbnailEditorModal'
import { getPrefixedUrl } from '../../../Utils/apis'

import Inputbox from '../../Inputbox'
import { isValidPattern } from '../../../Utils/functions'
import './index.scss'
import { PencilIcon } from '../../../Utils/svg'
import Tags from '../../Tags'

export default class PersonModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: this.props.open,
            addEdit: false,
            isModalOpen: false,
            imgId: null,
            base64path: null,
            imageId: '',
            imageSrc: '',
            deliverImg1Src: '',
            isEditMode: false,
            formData: {
                name: '',
                office: '',
                image1: '',
            },
            person: '',
            id: null,
            office: '',
            lastOfficeTag: '',
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.isEditMode !== prevState.isEditMode) {
            return {
                isEditMode: nextProps.isEditMode,
                formData: {
                    name: nextProps.editData.username,
                    office: nextProps.editData.location,
                    image1: nextProps.editData.userimage,
                },
                person: nextProps.person,
                id: nextProps.editData.id,
            }
        }
        if (!prevState.isEditMode && nextProps.person !== prevState.person) {
            return {
                person: nextProps.person,
            }
        }
        return null
    }

    componentDidMount() {
        if (this.props.isEditMode) {
            const tempArr = [
                {
                    label: this.props.editData.location,
                    value: this.props.editData.userlocation,
                },
            ]
            this.officeSelect.select.setValue(tempArr)
        }
    }

    getSrcFunction = reader => {
        if (this.state.imageId === 'image1') {
            this.setState({
                deliverImg1Src: reader.result,
                imageSrc: reader.result,
                base64path: reader.result,
            })
        }
    }

    getBase64(file) {
        const reader = new FileReader()
        reader.addEventListener(
            'load',
            () => {
                this.getSrcFunction(reader)
            },
            false
        )
        if (file) {
            reader.readAsDataURL(file)
        }
    }

    closeUploadModal = () => {
        this.setState({
            isModalOpen: false,
        })
    }
    handleSaveImage = (thumbnailId, formTosave, imageTosave) => {
        if (this.state.imageId === 'image1') {
            this.setState({
                formData: { ...this.state.formData, image1: imageTosave },
            })
            this.getBase64(imageTosave)
        }
        this.closeUploadModal()
    }

    handleChange = (field, value) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: value,
            },
        })
    }

    openUploadModal = field => {
        this.setState({
            isModalOpen: true,
            imageId: field,
        })
    }

    onCloseModal = () => {
        this.props.onCloseModal()
    }

    isFormValid = () => {
        const { name } = this.state.formData
        if (!isValidPattern(name, DOCUMENT_NAME_PATTERN)) {
            alert(SITE_TXT.TXT_DOCNAME_VALID_PATTERN)
            return false
        }
        return true
    }

    handleDelete = id => {
        const { deletePerson } = this.props
        deletePerson(id)
    }

    createFormData = () => {
        const { name = '', image1 = '' } = this.state.formData
        const { person, office } = this.state
        const { value } = office && office[0]
        const { categoryId } = this.props
        const UserType = person && person !== null && person === 'OWNER' ? 'ONE' : 'TWO'
        let data = new FormData()
        data.append('username', name)
        data.append('userimage', image1)
        data.append('userlocationid', value)
        if (!this.state.isEditMode) {
            data.append('categoryid', categoryId)
        }
        data.append('usertype', UserType)

        return data
    }

    keyDownHandler = (data, e, ref) => {
        if (e.keyCode === COMMA_KEY_CODE) {
            e.preventDefault()
            return
        }
        if (e.keyCode === 13) {
            if (data === 'office') {
                if (this.state.lastOfficeTag !== '') {
                    this.addTypedTag(data, this.state.lastOfficeTag, ref)
                }
            }
        }
    }

    onAddBtnClick = (data, currentRef, e) => {
        e.preventDefault()
        if (data === 'office') {
            if (this.state.lastOfficeTag !== '') {
                this.addTypedTag(data, this.state.lastOfficeTag, currentRef)
            }
        }
    }

    inputChangeHandler = (data, input) => {
        let inp = input.trim()
        if (inp === '') {
            return
        }
        if (data === 'office') {
            clearTimeout(this.delayTimer)
            this.delayTimer = setTimeout(() => {
                this.setState({
                    lastOfficeTag: inp,
                })
            }, 1000)
        }
    }

    addTypedTag = (data, val, currentRef) => {
        if (val === undefined) {
            return
        }
        if (val.trim() === '') {
            return
        }
        let obj = { label: val, value: val }
        let arrobj = []
        arrobj.push(obj)

        this.onReactSelectChange(data, arrobj)

        if (data === 'office') {
            if (!currentRef.state.value) currentRef.state.value = []
            currentRef.setState(
                {
                    value: [...currentRef.state.value, { label: val, value: val }],
                    office: this.state.value,
                    formData: {
                        ...this.state.formData,
                        office: val,
                    },
                },
                () => {
                    this.setState({ lastOfficeTag: '' })
                    currentRef.blur()
                    currentRef.focus()
                    currentRef.onChange(currentRef.state.value)
                }
            )
        }
    }

    onReactSelectChange = (data, value, currentRef) => {
        if (data === 'office' && value.length > MAX_TAGS_ALLOWED_OFFICE) {
            value.pop()
            currentRef && currentRef.select.setState({ selectValue: value })
            alert(SITE_TXT.TXT_MAX_TAGS_ALLOWED_OFFICE)
            return
        }

        if (data === 'office') {
            this.setState({
                office: value,
                lastOfficeTag: '',
                formData: {
                    ...this.state.formData,
                    office: value.length && value[0].label,
                },
            })
        }
    }

    getTagsStr = tagsArr => {
        let tagsStr = ''
        let tagsArrL = tagsArr.length
        for (let index = 0; index < tagsArrL; index++) {
            tagsStr += tagsArr[index].value + ','
        }
        if (tagsStr.trim().length > 0) {
            tagsStr = tagsStr.substring(0, tagsStr.length - 1)
        }

        return tagsStr
    }

    handleSave = e => {
        e.preventDefault()
        if (!this.isFormValid()) {
            return
        }
        const { editPerson, addPerson, onCloseModal } = this.props
        const { id = '' } = this.state
        this.setState({ addEdit: true }, () => {
            const validData = this.createFormData()
            if (this.state.isEditMode) {
                editPerson({ id, validData })
            } else {
                addPerson(validData)
            }
            onCloseModal()
        })
    }

    render() {
        const { isModalOpen, imageId, base64path, deliverImg1Src, person, isEditMode } = this.state
        const { ownerLocation } = this.props
        const { image1, name, office } = this.state.formData
        const thumbImages = {
            deliverImg1Src,
        }
        const styleClasses = {
            modal: 'personModal',
            overlay: 'overlayPersonModal',
            closeButton: 'closeIcon',
        }
        const addEditText =
            !isEditMode && person === 'OWNER'
                ? SITE_TXT.LOP_ADD_PLAYBOOK_OWNER
                : !isEditMode && person === 'EXPERT'
                ? SITE_TXT.LOP_ADD_PLAYBOOK_EXPERT
                : isEditMode && person === 'OWNER'
                ? SITE_TXT.LOP_EDIT_PLAYBOOK_OWNER
                : isEditMode && person === 'EXPERT'
                ? SITE_TXT.LOP_EDIT_PLAYBOOK_EXPERT
                : ''
        return (
            <div>
                <Modal open onClose={this.onCloseModal} center classNames={styleClasses} closeOnOverlayClick={false}>
                    <div className="modalHeader">{addEditText}</div>
                    <div className="modalBody">
                        <div>
                            <div className="image-text">User Image</div>
                            <div className={!image1 ? 'person-image no-image' : 'person-image with-image'}>
                                <div>
                                    {isEditMode && (
                                        <div className="editIcon" onClick={() => this.openUploadModal('image1')}>
                                            <PencilIcon width="15" height="15" />
                                        </div>
                                    )}
                                    {image1 ? (
                                        <img
                                            alt="img"
                                            src={deliverImg1Src || getPrefixedUrl(`${image1}`)}
                                            className="person-image"
                                        />
                                    ) : (
                                        <div className="browse-icon" onClick={() => this.openUploadModal('image1')}>
                                            <svg width="32px" height="22px" viewBox="0 0 32 22" version="1.1">
                                                <g
                                                    id="Merger-Management-v11"
                                                    stroke="none"
                                                    stroke-width="1"
                                                    fill="none"
                                                    fill-rule="evenodd"
                                                >
                                                    <g
                                                        id="08.3_MM_Admin_Playbook-Owners_Edit-playbook-owner"
                                                        transform="translate(-523.000000, -420.000000)"
                                                        fill="#2251FF"
                                                    >
                                                        <g id="Group-4" transform="translate(430.000000, 264.000000)">
                                                            <g id="Group-3" transform="translate(40.000000, 96.000000)">
                                                                <g
                                                                    id="Icons-/-Outline-/-32-/-Actions-/-Cloud-Upload-Copy"
                                                                    transform="translate(53.000000, 60.000000)"
                                                                >
                                                                    <path
                                                                        d="M16,0.5 C21.3114475,0.5 25.7486333,4.38519591 26.4516893,9.55838559 C29.3469438,10.0566001 31.5,12.5184484 31.5,15.5 C31.5,18.6167903 29.1471952,21.1656622 26,21.5 L26,21.5 L19.5,21.5 L19.5,20.5 L20,20.5 L25.9467334,20.5028454 C28.5381707,20.2251914 30.5,18.0998763 30.5,15.5 C30.5,12.9001237 28.5381707,10.7748086 25.9467334,10.4971546 L25.9467334,10.4971546 L25.542093,10.4538002 L25.5023857,10.0487857 C25.0264118,5.19385165 20.931677,1.5 16,1.5 C11.8109132,1.5 8.13988278,4.26697072 6.87661532,8.25112193 L6.87661532,8.25112193 L6.78410696,8.54287905 L6.48219949,8.59319696 C3.672092,9.06154821 1.5,11.5956555 1.5,14.5 C1.5,17.6340023 3.8951436,20.2240995 7,20.5 L7,20.5 L12.5,20.5 L12.5,21.5 L12,21.5 L6.95605309,21.4980649 C3.29314841,21.1748675 0.5,18.1543698 0.5,14.5 C0.5,11.2081927 2.8756519,8.33592653 6.01814779,7.66379073 C7.49344024,3.42299988 11.4701014,0.5 16,0.5 Z M16,7.29289322 L21.7071068,13 L21,13.7071068 L16.5,9.207 L16.5,19 L15.5,19 L15.5,9.207 L11,13.7071068 L10.2928932,13 L16,7.29289322 Z"
                                                                        id="Combined-Shape"
                                                                    ></path>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                            Browse
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div>
                            <Inputbox
                                placeholder={'Enter name'}
                                type="text"
                                fieldFor={'name'}
                                label={'Name'}
                                onChange={this.handleChange}
                                value={name}
                                max="100"
                            />

                            <div>
                                <label>{'Office'}</label>
                                <div className={'office-tags'}>
                                    <Tags
                                        label={'Office'}
                                        placeholder={'Add/Select Office'}
                                        onInputChange={input => this.inputChangeHandler('office', input)}
                                        options={ownerLocation.map(item => ({ label: item.location, value: item.id }))}
                                        onChange={value => this.onReactSelectChange('office', value, this.officeSelect)}
                                        onKeyDown={e => this.keyDownHandler('office', e, this.officeSelect)}
                                        refValue={input => (this.officeSelect = input)}
                                    />
                                    <button
                                        className="add-tag-btn"
                                        onClick={e => this.onAddBtnClick('office', this.officeSelect, e)}
                                    >
                                        +{SITE_TXT.TXT_ADD}
                                    </button>
                                </div>
                            </div>
                            {/*<div>*/}
                            {/*    <label htmlFor="Office">Office</label>*/}
                            {/*    <br />*/}
                            {/*    <select*/}
                            {/*        name="office"*/}
                            {/*        className="office"*/}
                            {/*        defaultValue={office}*/}
                            {/*        onChange={e => this.handleChange('office', e.target.value)}*/}
                            {/*    >*/}
                            {/*        <option value={0}>Select office</option>*/}
                            {/*        {ownerLocation &&*/}
                            {/*            ownerLocation.length > 0 &&*/}
                            {/*            ownerLocation.map(item => (*/}
                            {/*                <option key={item.id} value={item.id}>*/}
                            {/*                    {item.location}*/}
                            {/*                </option>*/}
                            {/*            ))}*/}
                            {/*    </select>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div className="buttonContent">
                        {isEditMode ? (
                            <button
                                className="btn btn-danger btn-lg btn-margin size-136"
                                onClick={() => this.handleDelete(this.state.id)}
                            >
                                {SITE_TXT.TXT_DELETE}
                            </button>
                        ) : (
                            <button
                                className="btn btn-outline-dark btn-lg btn-margin size-136"
                                onClick={this.onCloseModal}
                            >
                                {SITE_TXT.TXT_CANCEL}
                            </button>
                        )}

                        <button
                            disabled={!name || !office || parseInt(office) === 0}
                            className="btn btn-primary btn-lg size-136"
                            onClick={e => this.handleSave(e)}
                        >
                            {SITE_TXT.TXT_DONE}
                        </button>
                    </div>
                </Modal>

                {isModalOpen && (
                    <ThumbnailEditorModal
                        isAdminPage
                        onlyImage
                        isModalOpen={isModalOpen}
                        imageId={imageId}
                        thumbnailImg={image1 || null}
                        thumbImages={thumbImages}
                        deliverImgageSrc={base64path}
                        editCancel={() => this.closeUploadModal()}
                        handleSave={(id, form, test) => this.handleSaveImage(id, form, test)}
                        imgCropObj={IMAGE_CROP_OBJ_SQUARE}
                        overlayClass="uploadPlaybookOwnerOverlay full-img-ctrl"
                    />
                )}
            </div>
        )
    }
}
