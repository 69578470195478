import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectStatus, selectLoading, selectError, selectData } from './selector'
import { Deliver } from '../../Components'
import { deliver, deliverAddSection, deliverEditSection, deliverTrashSection, reorder } from './actions'

const mapStateToProps = createStructuredSelector({
    isLoading: selectLoading(),
    status: selectStatus(),
    error: selectError(),
    data: selectData(),
})
const mapDispatchToProps = {
    deliver,
    deliverAddSection,
    deliverEditSection,
    deliverTrashSection,
    reorder,
}
export default connect(mapStateToProps, mapDispatchToProps)(Deliver)
