import React, { Component } from 'react'
import history from '../../history'
import { STATUS_FAILURE } from '../../Utils/constants'

class GetRole extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showErrorMessage: false,
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { newRole, status } = nextProps
        if (newRole) {
            let updateUserInfo = { ...JSON.parse(localStorage.getItem('userInfo')) }
            updateUserInfo.newRole = newRole.role
            updateUserInfo.loginValidity = newRole.loginValidity
            localStorage.setItem('userInfo', JSON.stringify(updateUserInfo))
        }
        if (status) {
            if (status === STATUS_FAILURE) {
                // localStorage.removeItem('access_token')
                // localStorage.removeItem('id_token')
                // localStorage.removeItem('expires_at')
                // localStorage.removeItem('role')
                // localStorage.removeItem('userInfo')
                localStorage.clear()
                this.setState({ showErrorMessage: true })
            } else history.replace('/library')
        }
    }

    componentDidMount() {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'))
        this.props.role({ email: userInfo.email ? userInfo.email : userInfo.name, id: userInfo.sub })
    }

    render() {
        const style = {
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            height: '100vh',
            width: '100vw',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'white',
        }

        if (this.state.showErrorMessage) {
            return (
                <div style={{ padding: 20, textAlign: 'center' }}>
                    You don’t have access to this page. Try accessing{' '}
                    <a
                        href="javascript:void(null);"
                        onClick={() => {
                            this.props.authO.logout()
                        }}
                    >{` ${window.location.origin} `}</a>{' '}
                    again. Otherwise, please reach out to{' '}
                    <a href={`mailto:Katie_Ammon@mckinsey.com`}>Katie_Ammon@mckinsey.com</a>
                    {' or '}
                    <a href={`mailto:rui_silva@mckinsey.com`}>rui_silva@mckinsey.com</a> to request access. We’re sorry
                    for the inconvenience.
                </div>
            )
        } else {
            return <div style={style}></div>
        }
    }
}

export default GetRole
