import React, { Component } from 'react'
import ModuleHeader from '../ModuleHeader'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import DocExpTable from '../Table/docExpTable'
import DocToBeExpTable from '../Table/docToBeExpTable'
import { BackIcon } from '../../Utils/svg'
import './admin.css'
import { SITE_TXT } from '../../Utils/constants'
let dataRequest,
    toBeExpDataRequest = {}
class Documents extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selTabName: sessionStorage.getItem('selTabName') ? sessionStorage.getItem('selTabName') : '',
            selectedTab: sessionStorage.getItem('selectedTab')
                ? parseInt(sessionStorage.getItem('selectedTab'), 10)
                : 0,
            extDocdata: [],
            pageNumberExp: 1,
            pageSizeExp: 20,
            pageSizeToBeExp: 20,
            pageNumberToBeExp: 1,
            isLoading: this.props.isLoading,
            isLoadingToBeExp: this.props.isLoading,
            data: {},
            toBeExpiredReqData: {},
            selectedTabIndex: 0,
            requestType: 'library',
            pageNumber: 1,
            inAction: 'expired',
        }
    }

    handleSelect = (index, last, event) => {
        this.setState({ selectedTab: index })
        sessionStorage.setItem('selectedTab', index)
    }

    setTagNameHandler = name => {
        sessionStorage.setItem('selTabName', name)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { isLoading } = nextProps
        if (isLoading !== this.state.isLoading) {
            this.setState({ isLoading })
            this.setState({ isLoadingToBeExp: isLoading })
        }
    }

    componentDidMount() {
        const data = {
            action: 'expired',
            pageSize: this.state.pageSizeExp,
            pageNumber: this.state.pageNumberExp,
            search: '',
            sortColumn: 'contentName',
            sortType: 'ASC',
            requestType: this.state.requestType,
        }

        this.setState({ data: data, selectedTabIndex: 0, inAction: 'ToBeExpired' }, () => {
            dataRequest = data
        })
        this.props.fetchExtDocuments(data)
    }

    historyBack() {
        window.history.back()
    }

    clickExpired(action) {
        this.setState(
            {
                pageNumberExp: 1,
                inAction: 'ToBeExpired',
            },
            () => {
                const data = {
                    action: action,
                    pageSize: this.state.pageSizeExp,
                    pageNumber: this.state.pageNumberExp,
                    search: '',
                    sortColumn: 'contentName',
                    sortType: 'ASC',
                    requestType: this.state.requestType,
                }

                this.setState({ data: data, selectedTabIndex: 0, requestType: 'library' }, () => {
                    dataRequest = data
                })
                this.props.fetchExtDocuments(data)
            }
        )
    }

    clickToBeExpired(action) {
        this.setState(
            {
                pageNumberToBeExp: 1,
                inAction: 'expired',
            },
            () => {
                const data = {
                    action: action,
                    pageSize: this.state.pageSizeExp,
                    pageNumber: this.state.pageNumberExp,
                    search: '',
                    sortColumn: 'contentName',
                    sortType: 'ASC',
                    requestType: this.state.requestType,
                }

                this.setState(
                    {
                        toBeExpiredReqData: data,
                        selectedTabIndex: 1,
                        requestType: 'library',
                    },
                    () => {
                        toBeExpDataRequest = data
                    }
                )
                this.props.fetchExtDocuments(data)
            }
        )
    }
    render() {
        let extDoc = this.props.extDocData ? this.props.extDocData : []

        const { TXT_EXPIRED, TXT_TO_EXPIRE, TXT_EXTENSION_OF_DPOCUMENTS } = SITE_TXT

        return (
            <div className="uploadDoc-container">
                <div>
                    <span onClick={() => this.historyBack()} className="getBack">
                        <BackIcon />
                    </span>
                    <ModuleHeader heading={TXT_EXTENSION_OF_DPOCUMENTS} />
                    <br />

                    <Tabs selectedIndex={this.state.selectedTabIndex} onSelect={this.handleSelect}>
                        <TabList>
                            <Tab
                                onClick={
                                    this.state.inAction === 'expired' ? this.clickExpired.bind(this, 'expired') : ''
                                }
                            >
                                {TXT_EXPIRED}
                            </Tab>
                            <Tab
                                onClick={
                                    this.state.inAction === 'ToBeExpired'
                                        ? this.clickToBeExpired.bind(this, 'ToBeExpired')
                                        : ''
                                }
                            >
                                {TXT_TO_EXPIRE}
                            </Tab>
                        </TabList>

                        <TabPanel>
                            <DocExpTable
                                tableData={extDoc}
                                adminDocumentLoadMore={this.props.adminDocumentLoadMore}
                                isLoading={this.state.isLoading}
                                data={dataRequest}
                                expSearch={this.props.expSearch}
                                extendDoc={this.props.extendDoc}
                                extendDocState={this.props.extendDocState}
                                status={this.props.status}
                                fetchDocuments={this.props.fetchExtDocuments}
                                pageNumber={this.state.pageNumber}
                            />
                        </TabPanel>
                        <TabPanel>
                            <DocToBeExpTable
                                tableData={extDoc}
                                adminDocumentLoadMore={this.props.adminDocumentLoadMore}
                                isLoading={this.state.isLoading}
                                data={toBeExpDataRequest}
                                expSearch={this.props.expSearch}
                                extendDoc={this.props.extendDoc}
                                extendDocState={this.props.extendDocState}
                                status={this.props.status}
                                fetchDocuments={this.props.fetchExtDocuments}
                                pageNumber={this.state.pageNumber}
                            />
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        )
    }
}

export default Documents
