import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Input } from 'reactstrap'
import { isEmpty } from 'lodash'

import AlbumModal from '../AlbumModal'
import { updateLopSections, fetchLopAlbum, editSectionImage } from '../../../Containers/LopContent/actions'
import { selectLopAlbum } from '../../../Containers/LopContent/selector'
import { selectWidth } from '../../../Containers/App/selector'
import { getPrefixedUrl } from '../../../Utils/apis'
import { EditIcon } from '../../../Utils/svg'
import './index.scss'

class ImageGallery extends Component {
    state = {
        openGallery: false,
    }

    componentDidMount() {
        const { content, fetchLopAlbum } = this.props
        if (!isEmpty(content)) {
            const { sectionId } = content
            fetchLopAlbum(sectionId)
        }
    }

    toggleGallery = () => {
        this.setState(prevState => ({
            openGallery: !prevState.openGallery,
        }))
    }

    updateSectionImage = data => {
        this.props.editSectionImage(data)
        this.toggleGallery()
    }

    render() {
        const { openGallery } = this.state
        const { content, editMode, updateLopSections, currentSlide, album, windowWidth } = this.props
        if (isEmpty(content)) return null
        const { image, sectionName, sectionId, uploadedImage, sectionHeader } = content
        const height = Math.ceil(windowWidth / 6)
        return (
            <div className="imageGallery" style={{ height }}>
                <img
                    alt=""
                    src={uploadedImage || getPrefixedUrl(image)}
                    key={image ? image.substring(0, 4) : uploadedImage}
                />
                <div className="sectionHeader">
                    {editMode ? (
                        <Fragment>
                            <Input
                                value={sectionName}
                                className="imageTitleInput"
                                maxLength="50"
                                onChange={e => updateLopSections(currentSlide, 'sectionName', e.target.value)}
                            />
                            <Input
                                value={sectionHeader}
                                type="textarea"
                                className="imageDescInput"
                                maxLength="220"
                                onChange={e => updateLopSections(currentSlide, 'sectionHeader', e.target.value)}
                                onKeyPress={e => e.which === 13 && e.preventDefault()}
                                placeholder="[placeholder]"
                            />
                        </Fragment>
                    ) : (
                        <Fragment>
                            <h2>{sectionName}</h2>
                            <div className="sectionHeaderText">{sectionHeader}</div>
                        </Fragment>
                    )}
                </div>

                <div className="bottomIcons">
                    {editMode && (
                        <span className="editLopGalleryIcon" onClick={this.toggleGallery}>
                            <EditIcon />
                        </span>
                    )}
                </div>

                {openGallery && album.length ? (
                    <AlbumModal
                        album={album}
                        onClose={this.toggleGallery}
                        currentSlide={currentSlide}
                        sectionId={sectionId}
                        updateSectionData={this.updateSectionImage}
                    />
                ) : null}
            </div>
        )
    }
}

ImageGallery.propTypes = {
    windowWidth: PropTypes.number.isRequired,
    currentSlide: PropTypes.number.isRequired,
    content: PropTypes.instanceOf(Object).isRequired,
    album: PropTypes.instanceOf(Array).isRequired,
    editMode: PropTypes.bool.isRequired,
    updateLopSections: PropTypes.func.isRequired,
    fetchLopAlbum: PropTypes.func.isRequired,
    editSectionImage: PropTypes.func.isRequired,
}

const stateToProps = createStructuredSelector({
    album: selectLopAlbum(),
    windowWidth: selectWidth(),
})

const dispatchProps = {
    updateLopSections,
    fetchLopAlbum,
    editSectionImage,
}
export default connect(stateToProps, dispatchProps)(ImageGallery)
