import React, { Component } from 'react'
import { Input } from 'reactstrap'
import PropTypes from 'prop-types'
import VideoModal from './VideoModal'
import InfiniteScroll from 'react-infinite-scroller'
import c from 'classnames'
import NotesModal from '../../Lop/ClientReferences/NotesModal'
import { ConfirmAlertBox } from '../../../Containers/Library/Alerts'
import { SITE_TXT } from '../../../Utils/constants'
import { AddIcon, BinDelete, EditMainIcon } from '../../../Utils/svg'
import './index.scss'

class VideoData extends Component {
    constructor(props) {
        const { isLoading } = props
        super(props)
        this.state = {
            search: '',
            videoDetails: '',
            showCopy: false,
            openNotes: false,
            modalOpen: false,
            pageNumber: 1,
            isLoading,
            videoData: {},
        }
        this.delayTimer = null
        this.delayCopyTimer = null
    }

    componentDidMount() {
        this.fetchVideos()
    }

    componentDidUpdate() {
        const { reloadVideoContent, resetAdminVideo } = this.props
        if (reloadVideoContent) {
            this.setState({ search: '' }, () => this.fetchVideos())
            resetAdminVideo()
        }
    }

    static getDerivedStateFromProps(nextProps, state) {
        const { isLoading } = nextProps
        return { ...state, isLoading }
    }

    fetchVideos = () => {
        const { search } = this.state
        const { getAdminVideo } = this.props
        this.setState(
            {
                pageNumber: 1,
            },
            () => {
                getAdminVideo({
                    search,
                    pageNumber: 1,
                    pageSize: 5,
                    resetContent: true,
                })
            }
        )
    }

    loadNextVideos = () => {
        const { search, isLoading, pageNumber: oldPage } = this.state
        const { getAdminVideo } = this.props
        if (!isLoading) {
            const pageNumber = oldPage + 1
            getAdminVideo({
                search,
                pageNumber,
                pageSize: 5,
                resetContent: false,
            })
            this.setState({
                pageNumber,
                isLoading: true,
            })
        }
    }

    handleInputChange = ({ target }) => {
        const { isLoading } = this.state
        const { value } = target
        this.setState({ search: value.trim() })
        clearTimeout(this.delayTimer)
        if (!isLoading) {
            this.delayTimer = setTimeout(() => this.fetchVideos(), 1000)
        }
    }

    toggleVideoModal = (videoData = {}, modalOpen = false, reloadContent = false) => {
        const { pageNumber: oldPageNumber, search: oldSearch } = this.state
        this.setState(
            {
                modalOpen,
                videoData,
                pageNumber: reloadContent ? 1 : oldPageNumber,
                search: reloadContent ? '' : oldSearch,
            },
            () => {
                reloadContent && this.fetchVideos()
            }
        )
    }

    copyLinkAction = publicVideoUrl => {
        const textField = document.createElement('textarea')
        textField.innerText = publicVideoUrl
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        this.setState({ showCopy: true })
        clearTimeout(this.delayCopyTimer)
        this.delayCopyTimer = setTimeout(() => {
            this.setState({ showCopy: false })
        }, 1200)
    }

    checkToDeleteVideo = selectedId => {
        const { TXT_CONFIRM_DELETE, TXT_VIDEO_DELETE_MESSAGE } = SITE_TXT
        const { deleteAdminVideo } = this.props
        ConfirmAlertBox(TXT_CONFIRM_DELETE, TXT_VIDEO_DELETE_MESSAGE, () => deleteAdminVideo(selectedId))
    }

    toggleNotesModal = (openNotes, videoDetails = '') => {
        this.setState({ openNotes, videoDetails })
    }

    render() {
        const {
            TXT_VIDEOS,
            TXT_ADD_NEW_VIDEO,
            TXT_SEARCH_BTN,
            TXT_VIDNAME_LOP,
            TXT_VID_DESC,
            TXT_VID_DATE,
            TXT_VID_LINK_COPY,
            TXT_EDIT_CHECK,
            TXT_DELETE,
            TXT_VID_LINK_META,
            TXT_READ_MORE,
            TXT_COPIED,
        } = SITE_TXT
        const { search, modalOpen, videoData, showCopy, openNotes, videoDetails } = this.state
        const { uploadVideo, userVideos, closeVideoUpload } = this.props
        const { totalCount, data } = userVideos
        return (
            <div className="videoContent clearfix">
                <div className={c('copiedClip', { showCopy })}>{TXT_COPIED}</div>
                <h2>{TXT_VIDEOS}</h2>
                <div className="videoSearchContent">
                    <Input placeholder={TXT_SEARCH_BTN} value={search} onChange={e => this.handleInputChange(e)} />
                    {totalCount ? (
                        <span className="totalVideos">
                            Showing: {data.length}/{totalCount} Videos
                        </span>
                    ) : null}
                    <div className="addButton" onClick={() => this.toggleVideoModal({}, true)}>
                        <AddIcon /> {TXT_ADD_NEW_VIDEO}
                    </div>
                </div>

                <InfiniteScroll
                    pageStart={0}
                    loadMore={this.loadNextVideos}
                    hasMore={data.length < totalCount}
                    useWindow={true}
                >
                    <div className="videoSection clearfix">
                        {data.map(videoItem => {
                            const { id, video_url, video_name, video_description, presigned_url: preUrl } = videoItem
                            const originalDate = video_url.substring(
                                video_url.lastIndexOf('m/') + 2,
                                video_url.lastIndexOf('_')
                            )
                            const dateVideo = `${originalDate.substr(0, 3)} ${originalDate.substr(
                                3,
                                2
                            )}, ${originalDate.substr(5, 4)}`
                            return (
                                <div className="videoRepeater clearfix" key={id}>
                                    <video controls key={preUrl}>
                                        <source src={preUrl} />
                                    </video>
                                    <div className="videoData">
                                        <div className="info">
                                            <label>{TXT_VIDNAME_LOP}</label>
                                            {video_name}
                                            <div className="editButtons text-center">
                                                {/* <span onClick={() => this.toggleVideoModal(videoItem, true)}>
                                                    {TXT_EDIT_CHECK}
                                                </span> */}
                                                <span
                                                    title={TXT_EDIT_CHECK}
                                                    onClick={() => this.toggleVideoModal(videoItem, true)}
                                                >
                                                    <EditMainIcon />
                                                </span>
                                                <span title={TXT_DELETE} onClick={() => this.checkToDeleteVideo(id)}>
                                                    <BinDelete />
                                                </span>
                                                {/* <span onClick={() => this.checkToDeleteVideo(id)}>{TXT_DELETE}</span> */}
                                            </div>
                                        </div>
                                        <div className="info">
                                            <label>{TXT_VID_DATE}</label>
                                            {dateVideo}
                                        </div>
                                        <div className="info">
                                            <label>{TXT_VID_DESC}</label>
                                            <div
                                                className="desc"
                                                dangerouslySetInnerHTML={{
                                                    __html: video_description,
                                                }}
                                            />
                                            {video_description.length > 490 ? (
                                                <div
                                                    className="readMore"
                                                    onClick={() => this.toggleNotesModal(true, video_description)}
                                                >
                                                    {TXT_READ_MORE}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="info">
                                            <label className="embedLabel">{TXT_VID_LINK_COPY}</label>
                                            <div className="linkText">
                                                {video_url}
                                                <span onClick={() => this.copyLinkAction(video_url)}>
                                                    {TXT_VID_LINK_META}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </InfiniteScroll>
                {modalOpen && (
                    <VideoModal
                        closeModal={this.toggleVideoModal}
                        uploadVideo={uploadVideo}
                        videoData={videoData}
                        closeVideoUpload={closeVideoUpload}
                    />
                )}
                {openNotes && (
                    <NotesModal
                        openNotes={openNotes}
                        onNotesCloseModal={() => this.toggleNotesModal(false)}
                        notes={videoDetails}
                        heading="Details"
                    />
                )}
            </div>
        )
    }
}

VideoData.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    closeVideoUpload: PropTypes.bool.isRequired,
    reloadVideoContent: PropTypes.bool.isRequired,
    userVideos: PropTypes.instanceOf(Object).isRequired,
    uploadVideo: PropTypes.func.isRequired,
    getAdminVideo: PropTypes.func.isRequired,
    deleteAdminVideo: PropTypes.func.isRequired,
    resetAdminVideo: PropTypes.func.isRequired,
}

export default VideoData
