import React, { Component } from 'react'
import ModuleHeader from '../ModuleHeader'
import { isEmpty } from '../../Utils/functions'
import ThumbnailSectionLibrary from '../ThumbnailSectionLibrary'
import './index.css'
import { LIBRARY_HEADING } from '../../Utils/constants'
class Library extends Component {
    constructor(props) {
        super(props)
        sessionStorage.removeItem('currentCategoryTab')
        sessionStorage.removeItem('selTabName')
        sessionStorage.setItem('topLevel', 'Library')
        sessionStorage.removeItem('selectedTab')
        sessionStorage.removeItem('phaseId')
        sessionStorage.removeItem('selTabId')
        this.handleFadeSection = this.handleFadeSection.bind(this)
        this.state = {
            listData: [],
            editModeSection: 0,
            isEditMode: false,
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { data } = nextProps
        if (isEmpty(this.state.listData) && data && !isEmpty(data.data)) {
            this.setState({ listData: data.data })
        }
    }
    componentDidMount() {
        if(localStorage.getItem('access_token')==null && localStorage.getItem('okta-token-storage')!=null){
            // This Block Added by Suman. Because before Okta Integration access_token variable used every where while making any api call.
            const ACCESS_TOKEN = JSON.parse(localStorage.getItem('okta-token-storage'))?.accessToken?.accessToken;
            localStorage.setItem('access_token', ACCESS_TOKEN)
        }
        this.props.library()
    }
    handleFadeSection(isEditMode, editModeSection) {
        this.setState({
            editModeSection: editModeSection,
            isEditMode: isEditMode,
        })
    }

    render() {
        const { listData, isEditMode, editModeSection } = this.state
        return (
            !isEmpty(listData) && (
                <main className="library-container">
                    {this.props.data && (
                        <div>
                            <ModuleHeader heading={LIBRARY_HEADING} />
                            {listData &&
                                listData.map((list, index) => (
                                    <ThumbnailSectionLibrary
                                        key={index}
                                        data={list}
                                        add={this.props.add}
                                        edit={this.props.edit}
                                        trash={this.props.trash}
                                        onReorder={this.props.reorder}
                                        showSlider={true}
                                        handleFadeSection={this.handleFadeSection}
                                        sectionIndex={index}
                                        isFade={
                                            isEditMode && parseInt(editModeSection) !== index
                                                ? 'fadesection'
                                                : 'notfade'
                                        }
                                    />
                                ))}
                        </div>
                    )}
                </main>
            )
        )
    }
}

export default Library
