import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'
import { cstGroupDetail } from '../../Containers/Admin/selector'
import {
    fetchActiveCST,
    fetchNewCST,
    fetchExpiredCST,
    loadMoreActiveCST,
    loadMoreNewCST,
    loadMoreExpiredCST,
} from '../../Containers/Admin/actions'
import { Collapse, Icon } from 'antd'
import { SITE_TXT } from '../../Utils/constants'
import c from 'classnames'
import './antd.scss'
import './index.scss'
import CstList from './CSTList'

const { Panel } = Collapse

const data = {
    pageNumber: 1,
    pageSize: 5,
    sortColumn: '',
    sortType: 'ASC',
    search: '',
    requestType: '2',
}

class CSTPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            defaultActiveKey: ['1', '2', '3'],
            activeKey: ['1', '2', '3'],
            readMoreNewCST: false,
            readMoreActiveCST: false,
            readMoreExpiredCST: false,
            activeCSTPageNumber: 1,
            newCSTPageNumber: 1,
            expiredCSTPageNumber: 1,
        }
    }

    componentDidMount() {
        const { fetchActiveCST, fetchNewCST, fetchExpiredCST } = this.props
        fetchActiveCST({
            ...data,
            sortColumn: '',
            requestType: '2',
        })
        fetchNewCST({
            ...data,
            requestType: '1',
        })
        fetchExpiredCST({
            ...data,
            requestType: '4',
        })
    }

    handleSearch = (searchText, requestType) => {
        const { fetchActiveCST, fetchNewCST, fetchExpiredCST } = this.props
        if (requestType === 2) {
            fetchActiveCST({
                ...data,
                sortColumn: '',
                search: searchText,
                requestType: '2',
            })
        } else if (requestType === 1) {
            fetchNewCST({
                ...data,
                search: searchText,
                requestType: '1',
            })
        } else if (requestType === 4) {
            fetchExpiredCST({
                ...data,
                search: searchText,
                requestType: '4',
            })
        }
    }

    handleReadMore = ({ field, value }) => {
        const { loadMoreActiveCST, loadMoreNewCST, loadMoreExpiredCST } = this.props
        this.setState(
            {
                readMoreNewCST: false,
                readMoreActiveCST: false,
                readMoreExpiredCST: false,
                [field]: value,
                pageSize: 5,
            },
            () => {
                if (this.state.readMoreActiveCST) {
                    this.setState(
                        {
                            activeKey: ['1'],
                            activeCSTPageNumber: this.state.activeCSTPageNumber + 1,
                        },
                        () => {
                            const { activeCSTPageNumber } = this.state
                            loadMoreActiveCST({
                                ...data,
                                sortColumn: '',
                                pageNumber: activeCSTPageNumber,
                                requestType: '2',
                            })
                        }
                    )
                }
                if (this.state.readMoreNewCST) {
                    this.setState(
                        {
                            activeKey: ['2'],
                            newCSTPageNumber: this.state.newCSTPageNumber + 1,
                        },
                        () => {
                            const { newCSTPageNumber } = this.state
                            loadMoreNewCST({
                                ...data,
                                sortColumn: '',
                                pageNumber: newCSTPageNumber,
                                requestType: '1',
                            })
                        }
                    )
                }
                if (this.state.readMoreExpiredCST) {
                    this.setState(
                        {
                            activeKey: ['3'],
                            expiredCSTPageNumber: this.state.expiredCSTPageNumber + 1,
                        },
                        () => {
                            const { expiredCSTPageNumber } = this.state
                            loadMoreExpiredCST({
                                ...data,
                                sortColumn: '',
                                pageNumber: expiredCSTPageNumber,
                                requestType: '4',
                            })
                        }
                    )
                }
            }
        )
    }

    handleReadLess = requestType => {
        const { fetchActiveCST, fetchNewCST, fetchExpiredCST } = this.props
        if (requestType === 2) {
            fetchActiveCST({
                ...data,
                sortColumn: '',
                requestType: '2',
            })
        } else if (requestType === 1) {
            fetchNewCST({
                ...data,
                requestType: '1',
            })
        } else if (requestType === 4) {
            fetchExpiredCST({
                ...data,
                requestType: '4',
            })
        }
        this.handleCallback()
    }
    handleCallback = activeKey => {
        this.setState({
            activeKey,
            activeCSTPageNumber: 1,
            newCSTPageNumber: 1,
            expiredCSTPageNumber: 1,
        })
    }
    render() {
        const { cstGroupDetail, toggleCSTDetails } = this.props
        const { defaultActiveKey, activeKey } = this.state

        return (
            <div className={'cst-panel'}>
                <Collapse
                    bordered={false}
                    defaultActiveKey={defaultActiveKey}
                    activeKey={activeKey}
                    expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
                    onChange={this.handleCallback}
                >
                    <Panel
                        header={SITE_TXT.TXT_ACTIVE_CST}
                        key="1"
                        className={c('panel panel1', {
                            noData: cstGroupDetail.activeCST && cstGroupDetail.activeCST.length === 0,
                            readMore: this.state.readMoreActiveCST === true,
                        })}
                    >
                        <CstList
                            list={cstGroupDetail.activeCST}
                            toggleCSTDetails={toggleCSTDetails}
                            currentPanel={'readMoreActiveCST'}
                            handleReadMore={this.handleReadMore}
                            handleReadLess={this.handleReadLess}
                            handleSearch={this.handleSearch}
                            requestType={2}
                            isActiveCst
                        />
                    </Panel>
                    <Panel
                        header={SITE_TXT.TXT_NEW_CST}
                        key="2"
                        className={c('panel panel2', {
                            noData: cstGroupDetail.newCST && cstGroupDetail.newCST.length === 0,
                            readMore: this.state.readMoreNewCST === true,
                        })}
                    >
                        <CstList
                            list={cstGroupDetail.newCST}
                            toggleCSTDetails={toggleCSTDetails}
                            currentPanel={'readMoreNewCST'}
                            handleReadMore={this.handleReadMore}
                            handleReadLess={this.handleReadLess}
                            handleSearch={this.handleSearch}
                            requestType={1}
                            isNewCst
                        />
                    </Panel>
                    <Panel
                        header={SITE_TXT.TXT_EXPIRED_CST}
                        key="3"
                        className={c('panel panel3', {
                            noData: cstGroupDetail.expiredCST && cstGroupDetail.expiredCST.length === 0,
                            readMore: this.state.readMoreExpiredCST === true,
                        })}
                    >
                        <CstList
                            list={cstGroupDetail.expiredCST}
                            toggleCSTDetails={toggleCSTDetails}
                            currentPanel={'readMoreExpiredCST'}
                            handleReadMore={this.handleReadMore}
                            handleReadLess={this.handleReadLess}
                            handleSearch={this.handleSearch}
                            requestType={4}
                            isExpiredCst
                        />
                    </Panel>
                </Collapse>
            </div>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    cstGroupDetail: cstGroupDetail(),
})
const dispatchProps = {
    fetchActiveCST,
    fetchNewCST,
    fetchExpiredCST,
    loadMoreActiveCST,
    loadMoreNewCST,
    loadMoreExpiredCST,
}

CSTPanel.propTypes = {
    toggleCSTDetails: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, dispatchProps)(CSTPanel)
