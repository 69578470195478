import React, { Component } from 'react'
import { Button, FormGroup, Label, Input, Form } from 'reactstrap'
import './ForgotPass.css'
import { isValidPattern } from '../../Utils/functions'
import { EMAIL_PATTERN, SITE_TXT } from '../../Utils/constants'
import { Link } from 'react-router-dom'

export default class ForgotPass extends Component {
    state = {
        email: '',
        submitted: false,
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        })
    }

    handleSubmit = e => {
        e.preventDefault()
        this.setState({ submitted: true })
        const { email } = this.state
        let changePasswordRequest = {
            email: email,
            newPassword: 'secret@123',
        }
        if (isValidPattern(email, EMAIL_PATTERN)) {
            this.props.authO.changePassword(changePasswordRequest, 'reset')
        }
    }

    render() {
        const { submitted, email } = this.state
        const {
            TXT_EMAIL,
            TXT_SEND,
            TXT_FORGOT_PASSWORD,
            TXT_ENTER_EMAILID_SIGNEDUP,
            TXT_EMAIL_INVALID,
            TXT_EMAIL_WILLBE_SENT_MESSAGE,
            TXT_EMAIL_REQUIRED,
            TXT_CANCEL,
        } = SITE_TXT
        return (
            <div className="forgot-pass">
                <span className="heading">{TXT_FORGOT_PASSWORD}</span>
                <div className="emptyDiv" />
                <div className="sub-heading">
                    <span>{TXT_ENTER_EMAILID_SIGNEDUP}</span>
                    <br />
                    <span>{TXT_EMAIL_WILLBE_SENT_MESSAGE}</span>
                </div>
                <Form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
                    <FormGroup className={submitted && !email ? ' has-error' : ''}>
                        <Label for="email">{TXT_EMAIL}</Label>
                        <div className="inlining">
                            <Input
                                type="email"
                                name="email"
                                id="email"
                                placeholder="Enter Email ID"
                                onChange={this.handleChange('email')}
                            />
                            <Button type="submit" color="primary">
                                {TXT_SEND}
                            </Button>{' '}
                        </div>
                        {submitted && !email && <div className="help-block">{TXT_EMAIL_REQUIRED}</div>}
                        {submitted && email && !isValidPattern(email, EMAIL_PATTERN) && (
                            <div className="help-block " style={{ color: '#d84315' }}>
                                {TXT_EMAIL_INVALID}
                            </div>
                        )}
                    </FormGroup>

                    <Button outline color="info" type="button" className="btn-div">
                        <Link to="/">{TXT_CANCEL}</Link>
                    </Button>
                </Form>
            </div>
        )
    }
}
