import React, { Component } from 'react'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { getFileExt } from '../../Utils/functions'
import './index.css'
import { isMobile, SITE_TXT, isIphoneX } from '../../Utils/constants'
import Checkbox from '../Checkbox'
import RowExpansion from '../Lop/RowExpansion'
import { FoldereIcon, FileIcon } from '../../Utils/svg'

// import { VideoLink } from '../../Utils/svg'

class TabComp extends Component {
    constructor(props) {
        super(props)
        if (!sessionStorage.getItem('currentCategoryTab')) {
            sessionStorage.setItem('currentCategoryTab', this.props.topCategoryName)
        }
    }
    state = {
        tab: this.props.tab,
        expandedRows: false,
        rowId: null,
        // openPlayModal: this.props.openPlayModal || false,
    }

    /*handleChange(event) {
        this.props.toggleRow(event.target.id)
        this.setState({ value: event.target.value })
    }*/
    handleRowClick(rowId) {
        if (this.state.rowId === null) {
            this.setState({
                expandedRows: !this.state.expandedRows,
                rowId: rowId,
            })
        } else if (rowId === this.state.rowId) {
            this.setState({
                expandedRows: !this.state.expandedRows,
            })
        } else {
            this.setState({
                rowId: rowId,
                expandedRows: true,
            })
        }
    }

    render() {
        const { tab } = this.state
        const clickCallback = () => this.handleRowClick(tab.id)
        const { id, contentType, contentFormatIcon } = tab
        const nestedComponent =
            tab.files && tab.files.length > 0 && this.state.expandedRows === true ? ' nested-component' : ''

        const selectedrow =
            tab.files && this.state.expandedRows === true && this.state.rowId === tab.id ? ' selected-row' : ''
        let wordInString = ''
        return (
            <div>
                <div key={id} className={`tab-comp ${nestedComponent} ${selectedrow}`}>
                    {(isMobile || isIphoneX) && (
                        <div
                            className={` ${!tab.files ? 'checkbox-wrapper' : 'no-checkbox '}`}
                            onChange={() => !tab.files && this.props.toggleRow(tab.id)}
                        >
                            {' '}
                            {!tab.files && <Checkbox itemId={tab.id} checked={this.props.selected} />}
                            {tab.files &&
                                tab.files.forEach(file => {
                                    if (
                                        file.contentName.includes(this.props.search.trim()) &&
                                        this.props.search !== ''
                                    ) {
                                        wordInString = (
                                            <div title={SITE_TXT.TXT_SEARCHED_FOLDER} className="search-dot-slider" />
                                        )
                                    }
                                })}
                            {wordInString}
                        </div>
                    )}
                    <div className="tab-title">
                        <div className="tab-name">
                            {this.props.fileIcon && (!tab.files ? <FileIcon /> : <FoldereIcon />)}
                            {'  ' + tab.contentName}
                            {/* {tab.videoUrl ? (
                                <span
                                    onClick={() =>
                                        this.props.onOpenVideoModal(tab)
                                    }
                                    onKeyUp={e =>
                                        this.props.enterKeyHandler(
                                            e,
                                            'openVideo'
                                        )
                                    }
                                    tabIndex="0"
                                >
                                    <VideoLink width="14" height="14" />
                                    <span className="lower-div-text action-icon">
                                        {' '}
                                        {tab.contentName}
                                    </span>
                                </span>
                            ) : (
                                tab.contentName || name
                            )} */}
                        </div>
                        <div className="tab-type">{contentType}</div>
                    </div>

                    <span
                        className={` ${
                            !tab.contentFormatIcon ? '' : 'tab-format extBox ' + getFileExt(contentFormatIcon)
                        }`}
                    >
                        {contentFormatIcon ? getFileExt(contentFormatIcon).toUpperCase() : ''}
                    </span>

                    {tab.files && (
                        <RowExpansion
                            clickCallback={clickCallback}
                            item={tab}
                            expandedRows={this.state.expandedRows}
                            rowId={this.state.rowId}
                        />
                    )}
                </div>
                {tab.files &&
                    this.state.expandedRows &&
                    this.state.rowId === tab.id &&
                    tab.files.length > 0 &&
                    tab.files.map(i => (
                        <div key={i.id} className="nested-tab-comp tab-comp">
                            {(isMobile || isIphoneX) && (
                                <div className="checkbox-wrapper" onChange={() => this.props.toggleNestedRow(i)}>
                                    <Checkbox itemId={i.id} checked={this.props.selectedNested[i.id] === true} />
                                </div>
                            )}
                            <div className="tab-title">
                                <div className="tab-name">
                                    {this.props.fileIcon && <FileIcon />}
                                    {'  ' + i.contentName}
                                    {/* {i.videoUrl ? (
                                        <span
                                            onClick={() =>
                                                this.props.onOpenVideoModal(i)
                                            }
                                            onKeyUp={e =>
                                                this.props.enterKeyHandler(
                                                    e,
                                                    'openVideo'
                                                )
                                            }
                                            tabIndex="0"
                                        >
                                            <VideoLink width="14" height="14" />
                                            <span className="lower-div-text action-icon">
                                                {' '}
                                                {i.contentName}
                                            </span>
                                        </span>
                                    ) : (
                                        i.contentName
                                    )} */}
                                </div>
                            </div>
                            <span className={'tab-format extBox ' + getFileExt(i.contentFormatIcon)}>
                                {getFileExt(i.contentFormatIcon).toUpperCase()}
                            </span>
                        </div>
                    ))}
                <br />
                &nbsp;
            </div>
        )
    }
}

export default TabComp
