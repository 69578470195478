import {
    DELIVER_ADD_SUB_SECTION,
    DELIVER_EDIT_SUB_SECTION,
    DELIVER_FETCH_ITEM,
    DELIVER_GET,
    DELIVER_ADD_SECTION,
    DELIVER_EDIT_SECTION,
    DELIVER_TRASH_SECTION,
    DELIVER_DELETE_SUB_SECTION,
    DELIVER_DOWNLOAD_BROCHURE,
    DELIVER_ITEM_REORDER,
} from './constant'

export const deliver = data => ({ type: `${DELIVER_GET}_PENDING`, data })

export const deliverSuccess = data => ({ type: `${DELIVER_GET}_SUCCESS`, data })

export const deliverFailed = data => ({ type: `${DELIVER_GET}_FAILED`, data })

export const deliverAddSection = data => ({
    type: `${DELIVER_ADD_SECTION}_PENDING`,
    data,
})

export const deliverAddSectionSuccess = data => ({
    type: `${DELIVER_ADD_SECTION}_SUCCESS`,
    data,
})

export const deliverAddSectionFailed = data => ({
    type: `${DELIVER_ADD_SECTION}_FAILED`,
    data,
})

export const deliverEditSection = data => ({
    type: `${DELIVER_EDIT_SECTION}_PENDING`,
    data,
})

export const deliverEditSectionSuccess = data => ({
    type: `${DELIVER_EDIT_SECTION}_SUCCESS`,
    data,
})

export const deliverEditSectionFailed = data => ({
    type: `${DELIVER_EDIT_SECTION}_FAILED`,
    data,
})

export const deliverTrashSection = data => ({
    type: `${DELIVER_TRASH_SECTION}_PENDING`,
    data,
})

export const deliverTrashSectionSuccess = data => ({
    type: `${DELIVER_TRASH_SECTION}_SUCCESS`,
    data,
})

export const deliverTrashSectionFailed = data => ({
    type: `${DELIVER_TRASH_SECTION}_FAILED`,
    data,
})

export const deliverAddSubSection = data => ({
    type: `${DELIVER_ADD_SUB_SECTION}_PENDING`,
    data,
})

export const deliverAddSubSectionSuccess = data => ({
    type: `${DELIVER_ADD_SUB_SECTION}_SUCCESS`,
    data,
})

export const deliverAddSubSectionFailed = data => ({
    type: `${DELIVER_ADD_SUB_SECTION}_FAILED`,
    data,
})

export const deliverEditSubSection = data => ({
    type: `${DELIVER_EDIT_SUB_SECTION}_PENDING`,
    data,
})

export const deliverEditSubSectionSuccess = data => ({
    type: `${DELIVER_EDIT_SUB_SECTION}_SUCCESS`,
    data,
})

export const deliverEditSubSectionFailed = data => ({
    type: `${DELIVER_EDIT_SUB_SECTION}_FAILED`,
    data,
})

export const deliverDeleteSubSection = data => ({
    type: `${DELIVER_DELETE_SUB_SECTION}_PENDING`,
    data,
})

export const deliverDeleteSubSectionSuccess = data => ({
    type: `${DELIVER_DELETE_SUB_SECTION}_SUCCESS`,
    data,
})

export const deliverDeleteSubSectionFailed = data => ({
    type: `${DELIVER_DELETE_SUB_SECTION}_FAILED`,
    data,
})

export const fetchDeliverItem = data => ({
    type: `${DELIVER_FETCH_ITEM}_PENDING`,
    data,
})

export const fetchDeliverItemSuccess = data => ({
    type: `${DELIVER_FETCH_ITEM}_SUCCESS`,
    data,
})

export const fetchDeliverItemFailed = data => ({
    type: `${DELIVER_FETCH_ITEM}_FAILED`,
    data,
})

export const reorder = data => ({
    type: `${DELIVER_ITEM_REORDER}_PENDING`,
    data,
})

export const reorderSuccess = data => ({
    type: `${DELIVER_ITEM_REORDER}_SUCCESS`,
    data,
})

export const reorderFailure = data => ({
    type: `${DELIVER_ITEM_REORDER}_FAILED`,
    data,
})

export const downloadDeliverBrochure = data => ({
    type: `${DELIVER_DOWNLOAD_BROCHURE}_PENDING`,
    data,
})

export const downloadDeliverBrochureSuccess = data => ({
    type: `${DELIVER_DOWNLOAD_BROCHURE}_SUCCESS`,
    data,
})

export const downloadDeliverBrochureFailed = data => ({
    type: `${DELIVER_DOWNLOAD_BROCHURE}_FAILED`,
    data,
})
