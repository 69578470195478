import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { selectLoading, getUserVideos, getUserVideoModalStatus, getUserVideoStatus } from './selector'
import { selectWidth } from '../App/selector'
import CstWithLopVideoComp from './cstWithLopVideoComp'
import { uploadVideo, getAdminVideo, deleteAdminVideo, resetAdminVideo } from './actions'

const mapStateToProps = createStructuredSelector({
    userVideos: getUserVideos(),
    isLoading: selectLoading(),
    reloadVideoContent: getUserVideoStatus(),
    closeVideoUpload: getUserVideoModalStatus(),
    appWidth: selectWidth(),
})
const mapDispatchToProps = {
    uploadVideo,
    getAdminVideo,
    deleteAdminVideo,
    resetAdminVideo,
}
export default connect(mapStateToProps, mapDispatchToProps)(CstWithLopVideoComp)
