import React, { Component } from 'react'
import history from '../history'
import { parseJwt } from './Auth'

const GET_ROLE = '/getRole'

class MckCallback extends Component {
    componentDidMount = () => {
        const { mid } = this.props

        function setTokenData(access_token) {
            const token_payload = parseJwt(access_token)
            if (token_payload) {
                let expiresAt = JSON.stringify(token_payload.exp * 1000 + new Date().getTime())
                localStorage.setItem('access_token', access_token)
                localStorage.setItem('id_token', '')
                localStorage.setItem('expires_at', expiresAt)
                localStorage.setItem('myLopData', '')
                localStorage.setItem(
                    'userInfo',
                    JSON.stringify({ ...token_payload, newRole: 'Admin', loginValidity: '2099-12-31' })
                ) //TODO
                localStorage.setItem('editMode', false)
                localStorage.setItem('currentSlide', 0)
                localStorage.setItem('sectionDetails', '')
                localStorage.setItem('lop_id', null)
                // if (authResult.idTokenPayload.name.includes('mckinsey') || authResult.idTokenPayload.email.includes('mckinsey'))
                //
                // else history.replace(ROUTE_LIBRARY)
                history.replace(GET_ROLE)
            }
        }

        const access_token = mid.accessToken()
        if (access_token) {
            setTokenData(access_token)
        } else {
            mid.isAuthed().then(isAuthed => {
                const access_token = mid.accessToken()
                setTokenData(access_token)
            })
        }
    }

    render() {
        const style = {
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            height: '100vh',
            width: '100vw',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'white',
        }

        return <div style={style}></div>
    }
}

export default MckCallback
