import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import {
    selectStatus,
    selectLoading,
    selectError,
    selectData,
    userTypeData,
    designationData,
    userData,
    getUserProfileData,
    setUserProfileSuccess,
    activeUsers,
} from './selector'
import Profile from '../../Components/Profile'

import {
    fetchExpertTags,
    fetchUserType,
    fetchExtDocuments,
    fetchDesignationTags,
    getUserProfile,
    setUserProfile,
    getActiveUsers,
} from './actions'

const mapStateToProps = createStructuredSelector({
    isLoading: selectLoading(),
    status: selectStatus(),
    error: selectError(),
    data: selectData(),
    designationData: designationData(),
    userTypeData: userTypeData(),
    userData: userData(),
    getUserProfileData: getUserProfileData(),
    setUserProfileSuccess: setUserProfileSuccess(),
    activeUsers: activeUsers(),
})
const mapDispatchToProps = {
    fetchExpertTags: fetchExpertTags,
    fetchUserType: fetchUserType,
    fetchExtDocuments: fetchExtDocuments,
    fetchDesignationTags: fetchDesignationTags,
    getUserProfile: getUserProfile,
    setUserProfile: setUserProfile,
    getActiveUsers: getActiveUsers,
}
export default connect(mapStateToProps, mapDispatchToProps)(Profile)
