import { call, put, takeLatest, all } from 'redux-saga/effects'
import { STATUS_SUCCESS } from '../../Utils/constants'

import {
    ADD_CST_GROUP,
    EDIT_CST_GROUP,
    GET_CST_GROUP,
    GET_SINGLE_CST,
    LOAD_MORE_CST_GROUP,
    VALIDATE_CHARGE_CODE,
} from './constant'
import {
    addCstGroupFailed,
    addCstGroupSuccess,
    editCstGroupFailed,
    editCstGroupSuccess,
    getCstGroupFailed,
    getCstGroupSuccess,
    getSingleCstGroupFailed,
    getSingleCstGroupSuccess,
    loadMoreCstGroupFailed,
    loadMoreCstGroupSuccess,
    validateCCFailed,
    validateCCSuccess,
} from './action'
import { fetchCstGroup, addCstGroup, editCstGroup, fetchSingleCST, validateCC } from '../../Utils/apis'

export function* fetchCstGroupSaga({ data }) {
    const response = yield call(fetchCstGroup, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(getCstGroupSuccess({ responseData }))
    } else {
        yield put(getCstGroupFailed({ responseData }))
    }
}

export function* fetchSingleCstSaga({ data }) {
    const response = yield call(fetchSingleCST, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(getSingleCstGroupSuccess({ responseData }))
    } else {
        yield put(getSingleCstGroupFailed({ responseData }))
    }
}

export function* loadMoreCstGroupSaga({ data }) {
    const response = yield call(fetchCstGroup, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(loadMoreCstGroupSuccess({ responseData }))
    } else {
        yield put(loadMoreCstGroupFailed({ responseData }))
    }
}

export function* addCstGroupSaga({ data }) {
    const response = yield call(addCstGroup, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(addCstGroupSuccess({ responseData }))
    } else {
        yield put(addCstGroupFailed({ responseData }))
    }
}

export function* editCstGroupSaga({ data }) {
    const response = yield call(editCstGroup, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(editCstGroupSuccess({ responseData }))
    } else {
        yield put(editCstGroupFailed({ responseData }))
    }
}

export function* validateChargeCodeSaga({ data }) {
    const response = yield call(validateCC, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(validateCCSuccess({ responseData }))
    } else {
        yield put(validateCCFailed({ responseData }))
    }
}

export function* mmCstSaga() {
    yield all([takeLatest(`${GET_CST_GROUP}_PENDING`, fetchCstGroupSaga)])
    yield all([takeLatest(`${GET_SINGLE_CST}_PENDING`, fetchSingleCstSaga)])
    yield all([takeLatest(`${LOAD_MORE_CST_GROUP}_PENDING`, loadMoreCstGroupSaga)])
    yield all([takeLatest(`${ADD_CST_GROUP}_PENDING`, addCstGroupSaga)])
    yield all([takeLatest(`${EDIT_CST_GROUP}_PENDING`, editCstGroupSaga)])
    yield all([takeLatest(`${VALIDATE_CHARGE_CODE}_PENDING`, validateChargeCodeSaga)])
}

export default [mmCstSaga]
