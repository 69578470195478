import auth0 from 'auth0-js'
import history from '../history'
import { ENV_VARS, SSO_SIGN_IN_LINK } from '../Utils/constants'
import { removeLopData } from '../Utils/functions'
import { getTokenForSSO } from '../Utils/apis'

const ROUTE_LIBRARY = '/library'
const GET_ROLE = '/getRole'
const ROUTE_ROOT = '/'
export default class Auth {
    userProfile
    requestedScopes = ENV_VARS.REACT_APP_AUTH_0_SCOPE

    auth0 = new auth0.WebAuth({
        domain: ENV_VARS.REACT_APP_AUTH_0_DOMAIN,
        clientID: ENV_VARS.REACT_APP_AUTH_0_CLIENT_ID,
        redirectUri: ENV_VARS.REACT_APP_AUTH_0_REDIRECT_URI,
        audience: ENV_VARS.REACT_APP_AUTH_0_AUDIENCE,
        responseType: ENV_VARS.REACT_APP_AUTH_0_RESPONSE_TYPE,
        connection: ENV_VARS.REACT_APP_AUTH_0_DB_CONNECTION_NAME,
        scope: this.requestedScopes,
    })

    getAccessToken = () => {
        const accessToken = localStorage.getItem('access_token')
        if (!accessToken) {
            throw new Error('No access token found')
        }
        return accessToken
    }

    handleSSOAuthentication = () => {
        try {
            let url = new URL(window.location.href)
            let searchParams = new URLSearchParams(url.search)
            const callbackCode = searchParams.get('code')
            getTokenForSSO({ code: callbackCode, redirect_uri: ENV_VARS.REACT_APP_AUTH_0_REDIRECT_URI })
                .then(res => {
                    if (res.data && res.data.id_token) {
                        this.setSession({
                            expiresIn: res.data.expires_in,
                            accessToken: res.data.id_token,
                            idToken: res.data.id_token,
                            idTokenPayload: parseJwt(res.data.id_token),
                        })
                    } else {
                        history.replace(ROUTE_LIBRARY)
                        console.error('Auth.js handleSSOAuthentication getTokenForSSO', res)
                        alert(`Your account has been deactivated, please contact your administrator.`)
                    }
                })
                .catch(err => {
                    history.replace(ROUTE_LIBRARY)
                    console.error('Auth.js handleSSOAuthentication getTokenForSSO catch', err)
                    alert(`Your account has been deactivated, please contact your administrator.`)
                })
        } catch (err) {
            history.replace(ROUTE_LIBRARY)
            console.error('Auth.js handleSSOAuthentication catch', err)
            alert(`Your account has been deactivated, please contact your administrator.`)
        }
    }

    handleAuthentication = () => {
        this.auth0.parseHash((err, authResult) => {
            if (authResult && authResult.accessToken && authResult.idToken) {
                this.setSession(authResult)
                // history.replace(ROUTE_LIBRARY)
            } else if (err) {
                history.replace(ROUTE_LIBRARY)
                console.error('Auth.js handleAuthentication', err)
                alert(`Your account has been deactivated, please contact your administrator.`)
                // alert(`Error: ${err.error}. Check the console for further details.`)
            }
        })
    }

    setSession = authResult => {
        // Set the time that the access token will expire at
        let expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime())
        localStorage.setItem('access_token', authResult.accessToken)
        localStorage.setItem('id_token', authResult.idToken)
        localStorage.setItem('expires_at', expiresAt)
        localStorage.setItem('myLopData', '')
        localStorage.setItem('userInfo', JSON.stringify(authResult.idTokenPayload))
        localStorage.setItem('editMode', false)
        localStorage.setItem('currentSlide', 0)
        localStorage.setItem('sectionDetails', '')
        localStorage.setItem('lop_id', null)
        // if (authResult.idTokenPayload.name.includes('mckinsey') || authResult.idTokenPayload.email.includes('mckinsey'))
        //
        // else history.replace(ROUTE_LIBRARY)
        history.replace(GET_ROLE)
    }

    logout = () => {
        // Clear access token and ID token from local storage
        // localStorage.removeItem('access_token')
        // localStorage.removeItem('id_token')
        // localStorage.removeItem('expires_at')
        // localStorage.removeItem('role')
        // localStorage.removeItem('userInfo')
        localStorage.clear()
        removeLopData()
        history.replace(ROUTE_ROOT)
    }

    isAuthenticated = () => {
        // Check whether the current time is past the
        // access token's expiry time
        let expiresAt = JSON.parse(localStorage.getItem('expires_at'))
        return new Date().getTime() < expiresAt
    }

    changePassword = (data, passType) => {
        this.auth0.changePassword(
            {
                email: data.email,
                connection: ENV_VARS.REACT_APP_AUTH_0_DB_CONNECTION_NAME,
                callBackUrl: ENV_VARS.REACT_APP_AUTH_0_CALLBACK_URL,
            },
            (err, authResult) => {
                if (err) {
                    alert(err.description)
                    return
                } else if (authResult) {
                    if (passType === 'set') {
                        alert('A link has been sent to your email to set a new password!')
                    } else if (passType === 'reset') {
                        alert('A link has been sent to  your email to reset your password!')
                    }
                }
            }
        )
    }

    login() {
        this.auth0.authorize({
            prompt: 'login',
        })
    }

    loginWithSSO() {
        window.location = SSO_SIGN_IN_LINK
    }
}

export const parseJwt = token => {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
            .join('')
    )
    return JSON.parse(jsonPayload)
}
