import React, { Component } from 'react'
import { Input } from 'reactstrap'
import PropTypes from 'prop-types'
import { isIphoneX, isMobile, SITE_TXT } from '../../Utils/constants'
import { AddPartnerProfile } from '../../Utils/svg'
import './index.scss'
import CSTTable from './CSTTable'
import { createStructuredSelector } from 'reselect'
import { mmCstContent, isListUpdated } from './selector'
import { getCstGroup, loadMoreCstGroup, resetStatus } from './action'
import { connect } from 'react-redux'

class CSTlist extends Component {
    state = {
        lengthTable: 0,
        totalRecords: null,
        pageNumber: 1,
        pageSize: !isMobile && !isIphoneX ? 20 : 10,
        sortColumn: '',
        sortType: 'ASC',
        search: '',
        cstData: [],
        loadCounter: 0,
    }

    static getDerivedStateFromProps(nextProps) {
        if (nextProps.mmCstContent) {
            return {
                totalRecords: nextProps.mmCstContent.totalRecords,
                lengthTable: nextProps.mmCstContent.data && nextProps.mmCstContent.data.length,
                cstData: nextProps.mmCstContent.data,
            }
        } else return null
    }

    componentDidMount() {
        const { getCstGroup } = this.props
        const { pageNumber, pageSize, sortColumn, sortType, search } = this.state
        const data = {
            pageNumber,
            pageSize,
            sortColumn,
            sortType,
            search,
        }
        getCstGroup(data)
    }
    componentDidUpdate() {
        if (this.props.isListUpdated) {
            this.setState(
                {
                    loadCounter: 0,
                },
                () => {
                    this.props.resetStatus()
                }
            )
        }
    }

    loadMoreCst = () => {
        if (this.state.loadCounter === 0) {
            this.setState(
                {
                    pageNumber: this.state.pageNumber + 1,
                    loadCounter: 1,
                },
                () => {
                    const { pageNumber, pageSize, sortColumn, sortType, search } = this.state
                    const data = {
                        pageNumber,
                        pageSize,
                        sortColumn,
                        sortType,
                        search,
                    }
                    this.props.loadMoreCstGroup(data)
                }
            )
        }
    }

    sortRecord = sortProps => {
        this.setState(
            {
                sortColumn: sortProps,
                pageNumber: 1,
                sortType: this.state.sortType === 'ASC' ? 'DESC' : 'ASC',
            },
            () => {
                const { pageNumber, pageSize, sortColumn, sortType, search } = this.state
                const { getCstGroup } = this.props
                const data = {
                    pageNumber,
                    pageSize,
                    sortColumn,
                    sortType,
                    search,
                }
                getCstGroup(data)
            }
        )
    }
    search = value => {
        clearTimeout(this.delayTimer)
        this.delayTimer = setTimeout(() => {
            this.setState({ search: value, pageNumber: 1 }, () => {
                const { pageNumber, pageSize, sortColumn, sortType, search } = this.state
                const { getCstGroup } = this.props
                const data = {
                    pageNumber,
                    pageSize,
                    sortColumn,
                    sortType,
                    search,
                }
                getCstGroup(data)
            })
        }, 1000)
    }

    handleInputChange = search => this.setState({ search })

    render() {
        const { toggleCreate, isListUpdated } = this.props
        const { cstData, totalRecords, lengthTable, sortColumn, sortType } = this.state
        const { CST_TEXT, TXT_SEARCH_BTN, ADD_CST } = SITE_TXT
        return (
            <div className="cstList">
                <h3>{CST_TEXT}</h3>
                <div className="upperHeader clearfix">
                    <div className={'header-text'}>
                        <Input placeholder={TXT_SEARCH_BTN} onChange={e => this.search(e.target.value)} />

                        <span className="cstCount">
                            Showing: {lengthTable}/{totalRecords} CSTs
                        </span>
                    </div>

                    {!isMobile && !isIphoneX && (
                        <div onClick={() => toggleCreate({ editMode: false })} className="addCst">
                            <AddPartnerProfile width="22" height="22" /> {ADD_CST}
                        </div>
                    )}

                    <div className={'cstTable'}>
                        <CSTTable
                            cstData={cstData}
                            totalRecords={totalRecords}
                            lengthTable={lengthTable}
                            sortColumn={sortColumn}
                            sortType={sortType}
                            toggleCreate={toggleCreate}
                            loadMoreCst={this.loadMoreCst}
                            status={isListUpdated}
                            sortRecord={this.sortRecord}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

CSTlist.propTypes = {
    toggleCreate: PropTypes.func.isRequired,
    getCstGroup: PropTypes.func.isRequired,
    loadMoreCstGroup: PropTypes.func.isRequired,
    resetStatus: PropTypes.func.isRequired,
    isListUpdated: PropTypes.bool,
    mmCstContent: PropTypes.object,
}

const stateToProps = createStructuredSelector({
    mmCstContent: mmCstContent(),
    isListUpdated: isListUpdated(),
})
const dispatchProps = {
    getCstGroup,
    loadMoreCstGroup,
    resetStatus,
}

export default connect(stateToProps, dispatchProps)(CSTlist)
