import { createSelector } from 'reselect'

const selectLibrary = state => state.get('library')

const selectData = () => createSelector(selectLibrary, library => library.toJS()['data'])

const selectLoading = () => createSelector(selectLibrary, library => library.toJS()['isLoading'])

const selectStatus = () => createSelector(selectLibrary, library => library.toJS()['status'])

const selectEditStatus = () => createSelector(selectLibrary, library => library.toJS()['editStatus'])
const selectCategoryData = () => createSelector(selectLibrary, library => library.toJS()['categoryData'])

const selectDocumentsData = () => createSelector(selectLibrary, library => library.toJS()['documentsData'])

const downloadDocumentsData = () => createSelector(selectLibrary, library => library.toJS()['downloadDocumentsData'])
const emailDocumentsData = () => createSelector(selectLibrary, library => library.toJS()['emailDocumentsData'])
const libraryOwnerList = () => createSelector(selectLibrary, library => library.toJS()['libraryOwnerList'])
const ownerLocation = () => createSelector(selectLibrary, library => library.toJS()['ownerLocation'])
const isListUpdated = () => createSelector(selectLibrary, library => library.toJS()['isListUpdated'])
const isCopyDoc = () => createSelector(selectLibrary, library => library.toJS()['isCopyDoc'])
const reGenLink = () => createSelector(selectLibrary, library => library.toJS()['reGenLink'])
const shareDoc = () => createSelector(selectLibrary, library => library.toJS()['shareDoc'])
const multiShareDoc = () => createSelector(selectLibrary, library => library.toJS()['multiShareDoc'])

const selectClosePopUpBox = () => createSelector(selectLibrary, library => library.toJS()['closePopUpBox'])

export {
    selectStatus,
    selectEditStatus,
    selectLoading,
    selectData,
    selectCategoryData,
    selectDocumentsData,
    downloadDocumentsData,
    emailDocumentsData,
    libraryOwnerList,
    ownerLocation,
    isListUpdated,
    isCopyDoc,
    reGenLink,
    shareDoc,
    multiShareDoc,
    selectClosePopUpBox,
}
