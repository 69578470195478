import { createSelector } from 'reselect'

const selectSearch = state => state.get('search')

const selectLoading = () => createSelector(selectSearch, search => search.toJS()['isLoading'])

const selectStatus = () => createSelector(selectSearch, search => search.toJS()['status'])

const selectError = () => createSelector(selectSearch, search => search.toJS()['error'])

const selectMetaData = () => createSelector(selectSearch, search => search.toJS()['metaData'])

const selectData = () => createSelector(selectSearch, search => search.toJS()['data'])

const recentlySearchedData = () => createSelector(selectSearch, search => search.toJS()['recentlySearchedData'])

export { selectStatus, selectLoading, selectError, selectData, selectMetaData, recentlySearchedData }
