import callApi, { callAuth0, makeAPICall } from './request'
import { ENV_VARS } from './constants'

const POST = 'POST'
const PUT = 'PUT'
const GET = 'GET'
const DELETE = 'DELETE'

const API_URL_PREFIX = '/backendapi'
// const SERVER_URL = `${ENV_VARS.REACT_APP_SERVER_URL}:${ENV_VARS.REACT_APP_SERVER_PORT}`
const SERVER_URL = `${ENV_VARS.REACT_APP_SERVER_URL}`
const getPrefixedUrl = (url, full = false) => {
    const fullUrl = `${SERVER_URL}${API_URL_PREFIX}${url}`
    const onlyPrefixUrl = `${API_URL_PREFIX}${url}`
    const finalUrl = full ? fullUrl : ENV_VARS.NODE_ENV === 'production' ? fullUrl : onlyPrefixUrl
    return finalUrl
}

const urls = {
    // Test
    'test-api-post': '/rest/test-post',
    'test-api-get': '/library-category',

    'exchange-token': '/exchange-token',
    'get-user-profile': '/get-user-profile',

    // Library Items
    'get-library-items': '/get-library-items',
    'add-library-item': '/add-library-item',
    'edit-library-item': '/edit-library-item',
    'delete-library-item': '/delete-library-item',
    'get-library-item': '/get-library-item',
    'get-library-item-docs': '/get-library-item-docs',
    'set-library-item-order': '/set-library-item-order',

    // Library Search
    'get-library-search': '/get-library-search',
    'get-library-search-result': '/get-library-search-result',
    'get-library-recent-search-result': '/get-library-recent-search-result',

    // Library Phase
    'add-library-phase': '/add-library-phase',
    'edit-library-phase': '/edit-library-phase',
    'delete-library-phase': '/delete-library-phase',
    'add-library-owner': '/add-library-owner',
    'get-library-owner': '/get-library-owner',
    'get-owner-locations': '/get-owner-locations',
    'set-library-owner': '/set-library-owner',
    'remove-library-owner': '/remove-library-owner',

    // Deliver
    'get-deliver-items': '/get-deliver-items',
    'add-deliver-topic': '/add-deliver-topic',
    'edit-deliver-topic': '/edit-deliver-topic',
    'delete-deliver-topic': '/delete-deliver-topic',
    'add-deliver-product': '/add-deliver-product',
    'edit-deliver-product': '/edit-deliver-product',
    'get-deliver-product': '/get-deliver-product',
    'delete-deliver-product': '/delete-deliver-product',
    'download-deliver-product-brochure': '/download-deliver-product-brochure', // Need to fix later
    'set-deliver-item-order': '/set-deliver-item-order',

    // Lop
    'get-lop-items': '/get-lop-items',
    'delete-lop-items': '/delete-lop-items',
    'delete-lop-folder': '/delete-lop-folder',
    'get-lop-tags': '/get-lop-tags',
    'get-dropdownlist': '/get-dropdownlist',
    'get-lop-getstarted-items': '/get-lop-getstarted-items',
    'add-lop-folder': '/add-lop-folder',
    'edit-lop-folder': '/edit-lop-folder',
    'get-lop-folder-list': '/get-lop-folder-list',
    'add-lop-items': '/add-lop-items',
    'edit-lop-items': '/edit-lop-item',
    'add-lop-casestudy': '/add-lop-casestudy',
    'get-lop-casestudy': '/get-lop-casestudy',
    'edit-lop-casestudy': '/edit-lop-casestudy',
    'get-lop-client-ref': '/get-lop-client-ref',
    'edit-lop-client-ref': '/edit-lop-client-ref',
    'add-lop-expertcv': '/add-lop-expertcv',
    'get-lop-expertcv': '/get-lop-expertcv',
    'edit-lop-expertcv': '/edit-lop-expertcv',
    'get-countrylist': '/get-countrylist',
    'add-lop-client-ref': '/add-lop-client-ref',
    'add-partner': '/add-partner',
    'edit-partner': '/edit-partner',
    'delete-partner': '/delete-partner',
    'add-mylop-section': '/add-mylop-section',
    'delete-mylop-section': '/delete-mylop-section',
    'reorder-mylop-section': '/reorder-mylop-section',
    'delete-mylop': '/delete-mylop',
    'add-content': '/add-content',
    'edit-content': '/edit-content',
    'add-article': '/add-article',
    'edit-article': '/edit-article',
    'delete-article': '/delete-article',
    'download-mylop-documents': '/download-mylop-documents',
    'get-collaborators': '/get-collaborators',
    'search-collaborators': '/search-collaborators',
    'edit-collaborators': '/edit-collaborators',
    'lock-mylop': '/lock-mylop',
    'send-email': '/send-email',
    'add-external-user': '/add-external-user',
    'copy-mylop-section': '/copy-mylop-section',
    'get-mylop-section-detail': '/get-mylop-section-detail',
    'get-mylop-block-list': '/get-mylop-block-list',
    'update-block': '/update-block',
    'add-block': '/add-block',
    'add-cst-group': '/add-cst-group',
    'edit-cst-group': '/edit-cst-group',
    'get-cst-groups': '/get-cst-groups',
    'get-cst-group': '/get-cst-group',
    'update-cst-group': '/update-cst-group',
    'restore-section': '/restore-section',
    'edit-multiple-videos': '/edit-multiple-videos',
    'lop-welcome': '/lop-welcome',
    'get-all-documents': '/get-all-documents',
    'get-shared-documents': '/library/shared/document',

    // Admin
    'get-admin-user-roles': '/get-admin-user-roles',
    'get-admin-user-expertises': '/get-admin-user-expertises',
    'get-admin-user-designation': '/get-admin-user-designation',
    'get-admin-documents': '/get-admin-documents',
    'add-user': '/add-user',
    'increase-user-download-limit': '/increase-user-download-limit',
    'fetch-user': '/fetch-user',
    'update-user': '/update-user',
    'get-document-download-limit': '/get-library-download-limit',
    'set-document-download-limit': '/set-library-download-limit',
    'call-logout': '/logout',
    'lop-stats': '/lop-stats',
    'user-block-unblock': '/user-block-unblock',
    // Admin Library Documents
    'get-document-tags': '/get-document-tags/1',
    'add-library-document': '/add-library-document',
    'get-library-document': '/get-library-document',
    'edit-library-document': '/edit-library-document',
    'delete-library-document': '/delete-library-document',
    'download-documents': '/download-documents',
    'email-documents': '/email-documents',
    'user-count-history': '/user-count-history',
    'user-activity-history': '/user-activity-history',
    'user-download-history': '/user-download-history',
    'user-count': '/user-count',
    'user-profile': '/user-profile',
    'add-mylop': '/add-mylop',
    'get-mylop-items': '/get-mylop-items',
    'get-mylop-landing-page': '/get-mylop-landing-page',
    'get-section-gallery': '/get-section-gallery',
    'edit-mylop-section-detail': '/edit-mylop-section-detail',
    'edit-mylop-section-image': '/edit-mylop-section-image',
    'add-video': '/add-video',
    'remove-video': '/remove-video',
    'get-video': '/get-video',
    'library/document/share': '/library/document/share',
}

const getEndpoint = endpoint => {
    if (urls[endpoint]) {
        return getPrefixedUrl(urls[endpoint])
    }
    return null
}

/**
 *  test api call, type: post
 */
const callTestPostApi = testData => {
    return callApi(getEndpoint('test-api-post'), {
        method: POST,
        data: testData,
    })
}

/**
 *  test api call, type: get
 */
const callTestGetApi = () => {
    return callApi(getEndpoint('test-api-get'), {
        method: GET,
    })
}

const getTokenForSSO = data => {
    return callApi(getEndpoint('exchange-token'), {
        method: POST,
        data: data,
    })
}

const getUserRole = data => {
    if (data['email'] == undefined || data['email'] == null) {
        data['email'] = data['id']
        // this if block added by suman
    }

    const { email, id } = data
    return callApi(getEndpoint('get-user-profile'), {
        method: GET,
        query: { email, id },
    })
}

const addLibraryItem = data => {
    return callApi(getEndpoint('add-library-item'), {
        method: POST,
        data: data,
    })
}

const setLibraryItemOrder = ({ list }) => {
    return callApi(getEndpoint('set-library-item-order'), {
        method: POST,
        data: { ids: list.map(item => +item.id).join() },
    })
}

const setDeliverItemOrder = ({ list }) => {
    return callApi(getEndpoint('set-deliver-item-order'), {
        method: POST,
        data: { ids: list.map(item => +item.id).join() },
    })
}

const editLibraryItem = data => {
    return callApi(getEndpoint('edit-library-item'), {
        method: PUT,
        data: data,
    })
}

const deleteLibraryItem = itemId => {
    return callApi(getEndpoint('delete-library-item') + '/' + itemId, {
        method: DELETE,
    })
}

const addLibraryPhase = data => {
    return callApi(getEndpoint('add-library-phase'), {
        method: POST,
        data: data,
    })
}

const callEditPhase = data => {
    return callApi(getEndpoint('edit-library-phase'), {
        method: PUT,
        data: data,
    })
}

const callTrashPhase = itemId => {
    return callApi(getEndpoint('delete-library-phase') + '/' + itemId, {
        method: DELETE,
    })
}

const getLibaryItems = () => {
    return callApi(getEndpoint('get-library-items'), {
        method: GET,
    })
}

const getLibraryItem = () => {
    let currentLocation = window.location.href
    let tabid = currentLocation.slice(currentLocation.lastIndexOf('/') + 1)
    return callApi(getEndpoint('get-library-item') + '/' + tabid, {
        method: GET,
    })
}

const getLibraryItemDocuments = data => {
    const { search, pageNumber, pageSize, sortColumn, sortType, phaseId } = data
    return callApi(getEndpoint('get-library-item-docs') + '/' + phaseId, {
        method: GET,
        query: { search, pageNumber, pageSize, sortColumn, sortType },
    })
}

const addLibraryDocument = data => {
    return callApi(getEndpoint('add-library-document'), {
        method: POST,
        data: data,
    })
}

const shareDocument = data => {
    return callApi(getEndpoint('library/document/share'), {
        method: PUT,
        data: data,
    })
}

const getShareCopyDocument = data => {
    return callApi(getEndpoint('library/document/share') + '?box_file_id=' + data.id, {
        method: GET,
    })
}
const postShareCopyDocument = data => {
    return callApi(getEndpoint('library/document/share', data), {
        method: POST,
        data: data,
    })
}

const multipleShareDocs = data => {
    return callApi(getEndpoint('library/document/share'), {
        method: POST,
        data: data,
    })
}

const editMultipleVideosApi = data => {
    return callApi(getEndpoint('edit-multiple-videos'), {
        method: PUT,
        data: data,
    })
}

// --------- LOP  -----------------

const getLopItems = data => {
    const {
        requestType,
        pageSize,
        sortType,
        sortColumn,
        pageNumber,
        industry,
        geographyId,
        dealTypeId,
        keywords,
        dealSizeMin,
        dealSizeMax,
        fromDate,
        toDate,
        search,
        notes,
        expertName,
        regionBaseIds,
        expertRole,
        topics,
    } = data
    let query = {}
    if (
        requestType === 'gettingStarted' ||
        requestType === 'articles' ||
        requestType === 'goldStandard' ||
        requestType === 'delivery'
    ) {
        query = {
            requestType,
            search,
            pageNumber,
            pageSize,
            sortColumn,
            sortType,
        }
    } else if (requestType === 'caseStudies') {
        query = {
            requestType,
            pageSize,
            sortType,
            sortColumn,
            pageNumber,
            industry,
            geographyId,
            dealTypeId,
            keywords,
            dealSizeMin,
            dealSizeMax,
            fromDate,
            toDate,
        }
    } else if (requestType === 'clientReferences') {
        query = {
            requestType,
            pageSize,
            sortType,
            sortColumn,
            pageNumber,
            industry,
            fromDate,
            toDate,
            dealTypeId,
            dealSizeMin,
            dealSizeMax,
            notes,
        }
    } else if (requestType === 'expertCV') {
        query = {
            pageNumber,
            pageSize,
            sortColumn,
            sortType,
            requestType,
            expertName,
            regionBaseIds,
            expertRole,
            industry,
            topics,
        }
    }

    return callApi(getEndpoint('get-lop-items'), {
        method: GET,
        query: {
            ...query,
        },
    })
}

const fetchMyLops = query => {
    return callApi(getEndpoint('get-mylop-items'), {
        method: GET,
        query,
    })
}

const fetchMyLopDetails = id =>
    callApi(`${getEndpoint('get-mylop-landing-page')}?id=${id}`, {
        method: GET,
    })

const fetchLopCurrentTemplateDetails = ({ sectionId, templateId }) =>
    callApi(`${getEndpoint('get-mylop-section-detail')}?sectionId=${sectionId}&templateId=${templateId}`, {
        method: GET,
    })

const fetchAdditionalContent = ({ sectionId, templateId }) =>
    callApi(`${getEndpoint('get-mylop-block-list')}?sectionId=${sectionId}&templateId=${templateId}`, {
        method: GET,
    })

const fetchSectionGallery = id =>
    callApi(`${getEndpoint('get-section-gallery')}?sectionId=${id}`, {
        method: GET,
    })

const saveLopSection = data => {
    return callApi(getEndpoint('edit-mylop-section-detail'), {
        method: PUT,
        data,
    })
}
const editLopSectionImage = data => {
    return callApi(getEndpoint('edit-mylop-section-image'), {
        method: PUT,
        data,
    })
}

const calldeleteLopGetStartedItems = data => {
    return callApi(getEndpoint('delete-lop-items') + '?requestType=' + data.requestType + '&ids=' + data.items, {
        method: DELETE,
    })
}

const calldeleteGoldStandardFolder = data => {
    return callApi(getEndpoint('delete-lop-folder') + '?requestType=' + data.requestType + '&ids=' + data.items, {
        method: DELETE,
    })
}

const calldeleteGoldStandardItems = data => {
    return callApi(getEndpoint('delete-lop-items') + '?requestType=' + data.requestType + '&ids=' + data.items, {
        method: DELETE,
    })
}

const fetchLopTags = tagObj => {
    const { listType, name } = tagObj
    return callApi(getEndpoint('get-lop-tags') + '/' + listType, {
        method: GET,
        query: { name },
    })
}
const fetchLopDropdownlist = listType => {
    return callApi(getEndpoint('get-dropdownlist'), {
        method: GET,
        query: { listType: listType },
    })
}

const addPartner = data => {
    return callApi(getEndpoint('add-partner'), {
        method: POST,
        data: data,
    })
}
const editPartner = data => {
    return callApi(getEndpoint('edit-partner'), {
        method: PUT,
        data: data,
    })
}

const deletePartner = data => {
    return callApi(getEndpoint('delete-partner'), {
        method: DELETE,
        data,
    })
}

const addLopSection = data => {
    return callApi(getEndpoint('add-mylop-section'), {
        method: POST,
        data: data,
    })
}

const deleteMyLop = id => {
    return callApi(`${getEndpoint('delete-mylop')}?id=${id}`, {
        method: DELETE,
    })
}

const deleteLopSection = data => {
    const { sectionId, templateId } = data
    return callApi(`${getEndpoint('delete-mylop-section')}?id=${sectionId}&templateId=${templateId}`, {
        method: DELETE,
    })
}

const reOrderLopSection = data => {
    return callApi(getEndpoint('reorder-mylop-section'), {
        method: PUT,
        data,
    })
}

const addCustomSectionContent = data => {
    return callApi(getEndpoint('add-content'), {
        method: POST,
        data: data,
    })
}

const editCustomSectionContent = data => {
    return callApi(getEndpoint('edit-content'), {
        method: PUT,
        data: data,
    })
}

//--------------End Lop  -----------------
const callGetGoldItems = data => {
    const { search, pageNumber, pageSize, sortColumn, sortType } = data
    return callApi(getEndpoint('get-library-item-docs') + '/' + 19, {
        method: GET,
        query: { search, pageNumber, pageSize, sortColumn, sortType },
    })
}

const getDocumentTags = name => {
    return callApi(getEndpoint('get-document-tags'), {
        method: GET,
        query: { name },
    })
}

const getAdminUserExpertises = name => {
    return callApi(getEndpoint('get-admin-user-expertises'), {
        method: GET,
        query: { name },
    })
}

const getAdminUserDesignation = name => {
    return callApi(getEndpoint('get-admin-user-designation'), {
        method: GET,
        query: { name },
    })
}

const getLibrarySearch = data => {
    return callApi(getEndpoint('get-library-search'), {
        data: data,
    })
}

const getAdminUserRoles = () => {
    return callApi(getEndpoint('get-admin-user-roles'), {
        method: GET,
    })
}
const getAdminLibraryDocuments = query => {
    return callApi(getEndpoint('get-admin-documents'), {
        query,
    })
}

const callCreateUser = data => {
    return callAuth0('https://' + ENV_VARS.REACT_APP_AUTH_0_DOMAIN + '/api/v2/users', {
        data: data.data,
        headers: data.headers,
        method: POST,
    })
}
const callCreateTicket = data => {
    return callAuth0('https://' + ENV_VARS.REACT_APP_AUTH_0_DOMAIN + '/api/v2/tickets/password-change', {
        data: data.data,
        headers: data.headers,
        method: POST,
    })
}

const callVerification = data => {
    return callAuth0('https://' + ENV_VARS.REACT_APP_AUTH_0_DOMAIN + '/api/v2/tickets/email-verification', {
        data: data.data,
        headers: data.headers,
        method: POST,
    })
}

const getLibrarySearchResult = query => {
    return callApi(getEndpoint('get-library-search-result'), {
        query,
    })
}

const extendDocuments = query => {
    return callApi(getEndpoint('get-admin-documents'), {
        method: POST,
        query,
    })
}
const addUser = data => {
    return callApi(getEndpoint('add-user'), {
        method: POST,
        data: data,
    })
}

const getLibraryDocument = data => {
    return callApi(getEndpoint('get-library-document') + '/' + data, {})
}

const editLibraryDocument = data => {
    return callApi(getEndpoint('edit-library-document'), {
        method: PUT,
        data: data,
    })
}
const deleteLibraryDocument = data => {
    return callApi(getEndpoint('delete-library-document') + '/' + data, {
        method: DELETE,
    })
}

const getLibraryRecentSearchResult = () => {
    return callApi(getEndpoint('get-library-recent-search-result'), {
        method: GET,
    })
}
function callAdminUserCount() {
    return callApi(getEndpoint('user-count-history'), {
        method: GET,
    })
}

function callAdminDetails(query) {
    return callApi(getEndpoint('user-download-history'), {
        query,
    })
}
function getUserCount(query) {
    return callApi(getEndpoint('user-count'), {
        query,
    })
}
const addLopFolder = data => {
    return callApi(getEndpoint('add-lop-folder'), {
        method: POST,
        data: data,
    })
}
const editLopFolder = data => {
    return callApi(getEndpoint('edit-lop-folder'), {
        method: PUT,
        data: data,
    })
}

const fetchLopFolderList = query => {
    const { requestType, id } = query
    return callApi(getEndpoint('get-lop-folder-list'), {
        method: GET,
        query: { requestType, id },
    })
}

const addLopItems = data => {
    return callApi(getEndpoint('add-lop-items'), {
        method: POST,
        data: data,
    })
}
const editLopItems = data => {
    return callApi(getEndpoint('edit-lop-items'), {
        method: PUT,
        data: data,
    })
}
const callAdminBlockUnblock = query => {
    return callApi(getEndpoint('increase-user-download-limit'), {
        method: POST,
        query,
    })
}
const downloadLibraryDocument = query => {
    return callApi(getEndpoint('download-documents'), {
        method: GET,
        query,
    })
}
const saveLibraryDocument = url => {
    const headers = {}
    const ACCESS_TOKEN = localStorage.getItem('access_token')
    if (ACCESS_TOKEN) {
        headers.Authorization = `Bearer ${ACCESS_TOKEN}`
    }
    const originalConfig = {
        method: POST,
        url,
        headers,
        responseType: 'blob',
    }
    return makeAPICall(originalConfig)
}

const emailLibraryDocument = query => {
    return callApi(getEndpoint('email-documents'), {
        method: GET,
        query,
    })
}

const downloadDocument = data => {
    return callApi(getEndpoint('download-documents') + '?requestType=' + data.requestType + '&ids=' + data.ids, {
        method: GET,
    })
}

const emailDocument = data => {
    return callApi(
        getEndpoint('email-documents') +
            '?requestType=' +
            data.requestType +
            '&ids=' +
            data.ids +
            '&emailId=' +
            data.emailId,
        {
            method: GET,
        }
    )
}

function addDeliverProduct(data) {
    return callApi(getEndpoint('add-deliver-product'), {
        method: POST,
        data: data,
    })
}

const editDeliverProduct = data => {
    return callApi(getEndpoint('edit-deliver-product'), {
        method: PUT,
        data: data,
    })
}
const addDeliverTopic = data => {
    return callApi(getEndpoint('add-deliver-topic'), {
        method: POST,
        data: data,
    })
}
const getDeliverItems = () => {
    return callApi(getEndpoint('get-deliver-items'), {
        method: GET,
    })
}

const getDeliverProduct = Inpvalue => {
    return callApi(getEndpoint('get-deliver-product') + '/' + Inpvalue, {
        method: GET,
    })
}
const editDeliverTopic = data => {
    return callApi(getEndpoint('edit-deliver-topic'), {
        method: PUT,
        data: data,
    })
}

const deleteDeliverTopic = data => {
    return callApi(getEndpoint('delete-deliver-topic') + '/' + data.id, {
        method: DELETE,
    })
}
const deleteDeliverProduct = data => {
    return callApi(getEndpoint('delete-deliver-product') + '/' + data, {
        method: DELETE,
    })
}

const downloadDeliverProductBrochure = data => {
    return callApi(getEndpoint('download-deliver-product-brochure') + '/' + data, {
        method: GET,
    })
}

const callFetchUser = Inpvalue => {
    return callApi(getEndpoint('fetch-user') + '/' + Inpvalue, {
        method: GET,
    })
}

const callEditUser = data => {
    return callApi(getEndpoint('update-user'), {
        method: PUT,
        data: data,
    })
}

const deleteUser = data => {
    return callApi(getEndpoint('update-user') + '?UserId=' + data, {
        method: DELETE,
    })
}

const callGetDocumentLimit = () => {
    return callApi(getEndpoint('get-document-download-limit'), {
        method: GET,
    })
}

const callEditDocumentLimit = data => {
    return callApi(getEndpoint('set-document-download-limit'), {
        method: POST,
        data: data,
    })
}

const logoutUser = () => {
    return callApi(getEndpoint('call-logout'), {
        method: GET,
    })
}

const callGetUserProfile = () => {
    return callApi(getEndpoint('user-profile'), {
        method: GET,
    })
}

const callEditUserProfile = data => {
    return callApi(getEndpoint('user-profile'), {
        method: POST,
        data: data,
    })
}

const addCaseStudyItem = data => {
    return callApi(getEndpoint('add-lop-casestudy'), {
        method: POST,
        data: data,
    })
}

const fetchEditItemCaseStudy = data => {
    const { requestType, id } = data
    return callApi(getEndpoint('get-lop-casestudy'), {
        method: GET,
        query: {
            requestType,
            id,
        },
    })
}

const putEditItemCaseStudy = data => {
    return callApi(getEndpoint('edit-lop-casestudy'), {
        method: PUT,
        data: data,
    })
}

const addExpertItem = data => {
    return callApi(getEndpoint('add-lop-expertcv'), {
        method: POST,
        data: data,
    })
}

const fetchExpertItem = data => {
    const { id } = data
    return callApi(getEndpoint('get-lop-expertcv'), {
        method: GET,
        query: {
            id,
        },
    })
}

const editExpertItem = data => {
    return callApi(getEndpoint('edit-lop-expertcv'), {
        method: PUT,
        data: data,
    })
}

const callGetCountryCode = () => {
    return callApi(getEndpoint('get-countrylist'), {
        method: GET,
    })
}

const addclientItem = data => {
    return callApi(getEndpoint('add-lop-client-ref'), {
        method: POST,
        data: data,
    })
}

const fetchEditItemClientRef = data => {
    const { requestType, id } = data
    return callApi(getEndpoint('get-lop-client-ref'), {
        method: GET,
        query: {
            requestType,
            id,
        },
    })
}

const putEditItemClientRef = data => {
    return callApi(getEndpoint('edit-lop-client-ref'), {
        method: PUT,
        data: data,
    })
}

const addLopData = data => callApi(getEndpoint('add-mylop'), { method: POST, data: data })

const addArticle = data => {
    return callApi(getEndpoint('add-article'), {
        method: POST,
        data: data,
    })
}

const editArticle = data => {
    return callApi(getEndpoint('edit-article'), {
        method: PUT,
        data,
    })
}

const deleteArticle = data => {
    return callApi(getEndpoint('delete-article'), {
        method: DELETE,
        data,
    })
}

const downloadMyLOPDocs = data => {
    return callApi(getEndpoint('download-mylop-documents'), {
        method: GET,
        query: {
            ...data,
        },
    })
}

const fetchCollaborator = data => {
    const { id } = data
    return callApi(getEndpoint('get-collaborators'), {
        method: GET,
        query: {
            id,
        },
    })
}

const searchCollaborator = data => {
    const { name } = data
    return callApi(getEndpoint('search-collaborators'), {
        method: GET,
        query: {
            name,
        },
    })
}

const editCollaborators = data => {
    return callApi(getEndpoint('edit-collaborators'), {
        method: PUT,
        data: data,
    })
}

const lockMyLOP = data => {
    const { id, requestType } = data
    return callApi(getEndpoint('lock-mylop'), {
        method: GET,
        query: {
            id,
            requestType,
        },
    })
}

const contactWinRoom = data => {
    const { recipient, subject, body } = data
    return callApi(getEndpoint('send-email'), {
        method: GET,
        query: {
            recipient,
            subject,
            body,
        },
    })
}

const addExternalUser = data => {
    return callApi(getEndpoint('add-external-user'), {
        method: POST,
        data: data,
    })
}

const copyMoveLopSection = data => {
    return callApi(getEndpoint('copy-mylop-section'), {
        method: POST,
        data,
    })
}

const getLibraryOwner = data => {
    return callApi(getEndpoint('get-library-owner') + '/' + data, {
        method: GET,
    })
}
const getOwnerLocations = data => {
    return callApi(getEndpoint('get-owner-locations'), {
        method: GET,
    })
}

const addLibraryOwner = data => {
    return callApi(getEndpoint('add-library-owner'), {
        method: POST,
        data: data,
    })
}
const setLibraryOwner = data => {
    const { id, validData } = data
    return callApi(getEndpoint('set-library-owner') + '/' + id, {
        method: PUT,
        data: validData,
    })
}

const removeLibraryOwner = itemId => {
    return callApi(getEndpoint('remove-library-owner') + '/' + itemId, {
        method: DELETE,
    })
}

const uploadVideoData = data => {
    const { dataToSend, id } = data
    return callApi(`${getEndpoint('add-video')}/${id}`, {
        method: POST,
        data: dataToSend,
    })
}

const fetchVideoData = data => {
    const { search, pageNumber, pageSize } = data
    return callApi(getEndpoint('get-video'), {
        method: GET,
        query: { search, pageNumber, pageSize },
    })
}

const updateBlock = data => {
    const { sectionId, id, block_id } = data
    return callApi(getEndpoint('update-block'), {
        method: PUT,
        query: { sectionId, id, block_id },
    })
}

const addBlock = data => {
    return callApi(getEndpoint('add-block'), {
        method: POST,
        data,
    })
}

const fetchCstGroup = data => {
    const { pageNumber, pageSize, sortColumn, sortType, search, requestType = 0 } = data
    return callApi(getEndpoint('get-cst-groups'), {
        method: GET,
        query: {
            pageNumber,
            pageSize,
            sortColumn,
            sortType,
            search,
            requestType,
        },
    })
}

const fetchCstGroupDetail = data => {
    const { id } = data
    return callApi(getEndpoint('get-cst-group'), {
        method: GET,
        query: { id },
    })
}

const fetchSingleCST = id => {
    return callApi(`${getEndpoint('get-cst-group')}?id=${id}`, {
        method: GET,
    })
}

const addCstGroup = data => {
    return callApi(getEndpoint('add-cst-group'), {
        method: POST,
        data,
    })
}

const editCstGroup = data => {
    return callApi(getEndpoint('edit-cst-group'), {
        method: PUT,
        data,
    })
}

const removeVideoData = id => {
    return callApi(`${getEndpoint('remove-video')}/${id}`, { method: DELETE })
}

const editCSTGroup = data => {
    return callApi(getEndpoint('update-cst-group'), {
        method: PUT,
        data: data,
    })
}

const validateCC = data => {
    const token = localStorage.getItem('ccToken')
    return callApi(`${getEndpoint('validate-chargeCode')}`, {
        method: POST,
        headers: `Bearer ${token}`,
    })
}

const callActiveUsers = userType => {
    return callApi(`${getEndpoint('user-activity-history')}?userType=${userType}`, {
        method: GET,
    })
}

const restoreSectionApi = data => {
    return callApi(getEndpoint('restore-section'), {
        method: PUT,
        data: data,
    })
}

const callLOPStats = userType => {
    return callApi(`${getEndpoint('lop-stats')}`, {
        method: GET,
    })
}

const updateLopWelcome = data => {
    return callApi(getEndpoint('lop-welcome'), {
        method: PUT,
        data,
    })
}

const setLopWelcome = data => {
    return callApi(getEndpoint('lop-welcome'), {
        method: POST,
        data: data,
    })
}

const getLopWelcome = data => {
    const { section_id } = data
    return callApi(`${getEndpoint('lop-welcome')}?section_id=${section_id}`, {
        method: GET,
    })
}

const blockUnblock = data => {
    return callApi(getEndpoint('user-block-unblock'), {
        method: PUT,
        data,
    })
}

const callDocsStats = () => {
    return callApi(`${getEndpoint('get-all-documents')}`, {
        method: GET,
    })
}

const callSharedDocsList = () => {
    return callApi(`${getEndpoint('get-shared-documents')}`, {
        method: GET,
    })
}

const callRecipientsList = data => {
    return callApi(`${getEndpoint('get-shared-documents')}?head_id=${data}`, {
        method: GET,
    })
}

const callDeleteSharedDoc = data => {
    return callApi(`${getEndpoint('get-shared-documents')}`, {
        method: DELETE,
        data,
    })
}

export {
    callTestGetApi,
    callTestPostApi,
    getLibaryItems,
    getDeliverItems,
    addDeliverTopic,
    editDeliverTopic,
    deleteDeliverTopic,
    getTokenForSSO,
    getUserRole,
    addLibraryItem,
    editLibraryItem,
    deleteLibraryItem,
    addLibraryPhase,
    callEditPhase,
    callTrashPhase,
    getLibraryItem,
    getLibraryItemDocuments,
    getDocumentTags,
    addLibraryDocument,
    logoutUser,
    getLibraryOwner,
    getOwnerLocations,
    addLibraryOwner,
    setLibraryOwner,
    removeLibraryOwner,
    //--- LOP ---
    getLopItems,
    calldeleteLopGetStartedItems,
    editLopItems,
    calldeleteGoldStandardFolder,
    calldeleteGoldStandardItems,
    fetchLopTags,
    fetchLopDropdownlist,
    addCaseStudyItem,
    fetchEditItemCaseStudy,
    putEditItemCaseStudy,
    addExpertItem,
    fetchExpertItem,
    editExpertItem,
    callGetCountryCode,
    addclientItem,
    fetchEditItemClientRef,
    putEditItemClientRef,
    addArticle,
    editArticle,
    deleteArticle,
    downloadMyLOPDocs,
    fetchCollaborator,
    searchCollaborator,
    editCollaborators,
    lockMyLOP,
    contactWinRoom,
    addExternalUser,
    updateBlock,
    addBlock,
    fetchCstGroup,
    addCstGroup,
    editCstGroup,
    fetchSingleCST,
    //--- end LOP ---
    getAdminUserExpertises,
    getAdminUserRoles,
    getAdminLibraryDocuments,
    getAdminUserDesignation,
    callCreateUser,
    getLibrarySearch,
    getLibrarySearchResult,
    // callDocToBeExp,
    extendDocuments,
    addLopFolder,
    editLopFolder,
    fetchLopFolderList,
    addLopItems,
    callCreateTicket,
    callVerification,
    addUser,
    getLibraryDocument,
    editLibraryDocument,
    deleteLibraryDocument,
    callAdminUserCount,
    callAdminDetails,
    callAdminBlockUnblock,
    setLibraryItemOrder,
    getLibraryRecentSearchResult,
    getPrefixedUrl,
    downloadLibraryDocument,
    saveLibraryDocument,
    emailLibraryDocument,
    emailDocument,
    downloadDocument,
    addDeliverProduct,
    editDeliverProduct,
    getDeliverProduct,
    deleteDeliverProduct,
    downloadDeliverProductBrochure,
    callFetchUser,
    callEditUser,
    setDeliverItemOrder,
    callGetDocumentLimit,
    callEditDocumentLimit,
    callGetUserProfile,
    callEditUserProfile,
    callGetGoldItems,
    addLopData,
    fetchMyLops,
    fetchMyLopDetails,
    saveLopSection,
    fetchSectionGallery,
    editLopSectionImage,
    addPartner,
    editPartner,
    deletePartner,
    fetchLopCurrentTemplateDetails,
    fetchAdditionalContent,
    addCustomSectionContent,
    editCustomSectionContent,
    addLopSection,
    deleteLopSection,
    reOrderLopSection,
    deleteMyLop,
    uploadVideoData,
    fetchVideoData,
    removeVideoData,
    fetchCstGroupDetail,
    editCSTGroup,
    validateCC,
    getUserCount,
    deleteUser,
    callActiveUsers,
    copyMoveLopSection,
    restoreSectionApi,
    callLOPStats,
    editMultipleVideosApi,
    updateLopWelcome,
    setLopWelcome,
    getLopWelcome,
    blockUnblock,
    shareDocument,
    getShareCopyDocument,
    postShareCopyDocument,
    callDocsStats,
    multipleShareDocs,
    callSharedDocsList,
    callRecipientsList,
    callDeleteSharedDoc,
}
