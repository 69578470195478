import React from 'react'
import { withAlert } from 'react-alert'

import './index.scss'
import Inputbox from '../Inputbox'
import { BackIcon } from '../../Utils/svg'
import Dropdown from '../Dropdown'
import { MonthListCode, SITE_TXT } from '../../Utils/constants'
import { futureYearList } from '../../Utils/functions'
import { FormGroup } from 'reactstrap'
import { createStructuredSelector } from 'reselect'
import { cstGroupDetail, isListUpdated } from '../../Containers/Admin/selector'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { blkUnBlkCST, fetchCSTById, resetStatus, updateCSTGroupDetail } from '../../Containers/Admin/actions'
import c from 'classnames'
import { Checkbox } from 'antd'
import { ConfirmAlertBox } from '../../Containers/Library/Alerts'

class CSTDetail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            chargeCode: '',
            selected: [],
            month: '',
            year: '',
            cstDataById: null,
            status: false,
            open: false,
            isBlock: false,
            isDataSubmitted: false,
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { isDataSubmitted = false } = this.state
        if (nextProps.cstGroupDetail && nextProps.cstGroupDetail.cstById && !isDataSubmitted) {
            this.setState({
                selected: nextProps.cstGroupDetail.cstById[0].cstUsers,
                cstDataById: nextProps.cstGroupDetail.cstById[0],
                chargeCode: nextProps.cstGroupDetail.cstById[0].chargeCode,
                month: parseInt(nextProps.cstGroupDetail.cstById[0].loginValidity.split('/')[0]),
                year: nextProps.cstGroupDetail.cstById[0].loginValidity.split('/')[2],
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.isListUpdated === true) {
            this.setState(
                {
                    status: true,
                },
                () => {
                    this.props.resetStatus()
                    this.props.toggleCSTDetails({})
                }
            )
        }
    }

    componentDidMount() {
        const { cstId, fetchCSTById } = this.props
        if (cstId)
            fetchCSTById({
                id: this.props.cstId,
            })
    }

    toggleSelected = state => {
        if (state === 'approve') {
        } else if (state === 'deny') {
        }
    }

    handleSubmit = e => {
        e.preventDefault()
        const { id } = this.state.cstDataById
        const { chargeCode } = this.state
        const data = {
            chargeCode,
            id,
            isDenied: false,
            loginValidity:
                this.state.month && this.state.year
                    ? `${this.state.month}/01/${this.state.year}`
                    : this.state.cstDataById.loginValidity,
        }

        if (this.state.cstDataById.status === 'Pending') {
            let selectedUsers = [...this.state.selected]
            selectedUsers.map(user => {
                if (user.checked) {
                    user.isApproved = true
                }
                return user
            })
            data.cstUsers = selectedUsers
        }

        this.setState({ isDataSubmitted: true })

        this.props.updateCSTGroupDetail(data)
    }

    denyRequest = () => {
        const { chargeCode, id } = this.state.cstDataById
        const data = {
            chargeCode,
            id,
            loginValidity:
                this.state.month && this.state.year
                    ? `${this.state.month}/01/${this.state.year}`
                    : this.state.cstDataById.loginValidity,
        }

        if (this.state.cstDataById.status === 'Pending') {
            let selectedUsers = [...this.state.selected]
            selectedUsers.map(user => {
                if (user.checked) {
                    user.isDenied = true
                } else {
                    user.isApproved = true
                }
                return user
            })
            data.cstUsers = selectedUsers
        }

        if (this.state.cstDataById.cstUsers.length === data.cstUsers.filter(user => user.isDenied).length) {
            data.isDenied = true
        } else {
            data.isDenied = false
        }
        this.props.updateCSTGroupDetail(data)
    }

    toggleRow(id) {
        let newSelected = [...this.state.selected]
        const isExists = this.state.selected.findIndex(user => user.id === id)
        if (isExists > -1) {
            newSelected = newSelected.map(user => {
                if (user.id === id) {
                    user.checked = !user.checked
                }
                return user
            })
        }

        this.setState({
            selected: newSelected,
        })
    }

    handleChange = (field, value) => {
        this.setState({
            [field]: value,
        })
    }
    validateForm = () => {
        return !this.state.selected.length
    }

    blockUser = () => {
        const { alert } = this.props
        const { error } = alert
        const usersSelected = this.state.selected.filter(item => item.checked === true)

        if (usersSelected.length === 0) {
            error('Please select some users to perform this action.')
            return
        }
        const expUser = usersSelected.filter(item => item.Status === 'Expired')
        const activeUser = usersSelected.filter(item => item.Status === 'Approved')
        if (expUser && expUser.length > 0) {
            error('Please do not select Blocked users.')
            return
        }

        if (activeUser && activeUser.length > 0) {
            this.setState({ isBlock: true }, () => {
                const title = SITE_TXT.TXT_CST_DELETE_HEADER
                const message = this.state.isBlock ? SITE_TXT.TXT_CST_BLOCK_MSG : SITE_TXT.TXT_CST_UNBLOCK_MSG
                ConfirmAlertBox(title, message, () => this.handleYes())
            })
        }
    }

    unblockUser = () => {
        const { alert } = this.props
        const { error } = alert
        const usersSelected = this.state.selected.filter(item => item.checked === true)

        if (usersSelected.length === 0) {
            error('Please select some users to perform this action.')
            return
        }
        const expUser = usersSelected.filter(item => item.Status === 'Expired')
        const activeUser = usersSelected.filter(item => item.Status === 'Approved')

        if (activeUser && activeUser.length > 0) {
            error('Please do not select active users')
        }

        if (expUser && expUser.length > 0) {
            this.setState({ isBlock: false }, () => {
                const title = SITE_TXT.TXT_CST_DELETE_HEADER
                const message = this.state.isBlock ? SITE_TXT.TXT_CST_BLOCK_MSG : SITE_TXT.TXT_CST_UNBLOCK_MSG
                ConfirmAlertBox(title, message, () => this.handleYes())
            })
        }
    }

    handleCloseModal = () => {
        this.setState({ open: false })
    }

    handleYes = () => {
        const { blkUnBlkCST } = this.props
        const { id } = this.state.cstDataById
        const { isBlock } = this.state
        const usersSelected = this.state.selected.filter(item => item.checked)
        let data = {
            is_blocked: isBlock,
            cst_group_id: id,
        }
        if (isBlock) {
            const activeUser = usersSelected.filter(item => item.Status === 'Approved')
            data.emails = activeUser.map(item => item.email)
            if (data.emails.length > 0) {
                blkUnBlkCST(data)
            }
        } else {
            const expiredUser = usersSelected.filter(item => item.Status === 'Expired')
            data.emails = expiredUser.map(item => item.email)
            if (data.emails.length > 0) {
                blkUnBlkCST(data)
            }
        }
        this.handleCloseModal()
    }

    handleNo = () => {
        this.handleCloseModal()
    }

    render() {
        const { month, year, chargeCode, cstDataById } = this.state
        const { toggleCSTDetails, isNewCst, isExpiredCst, isActiveCst } = this.props
        const YearsData = futureYearList()
        const {
            TXT_SAVE_ADMIN,
            TXT_DENY_BTN,
            TXT_CREATE_USER_BTN,
            CST_NAME,
            TXT_MM_CONTECT_ADMIN,
            MMCST_CHARGE_CODE,
            MMCST_DURATION,
            CST_ACCESS,
        } = SITE_TXT
        return (
            <div className="admin-cst">
                <h3>
                    <span onClick={toggleCSTDetails}>
                        <BackIcon />
                    </span>
                    {isNewCst
                        ? SITE_TXT.TXT_NEW_CST_ADMIN
                        : isExpiredCst
                        ? SITE_TXT.TXT_EXPIRED_CST_ADMIN
                        : isActiveCst
                        ? SITE_TXT.TXT_ACTIVE_CST_ADMIN
                        : ''}
                </h3>
                {cstDataById && (
                    <section className="cstWrapper">
                        <div className={'leftSection'}>
                            <FormGroup>
                                <div className={'label'}>{CST_NAME}</div>
                                <div className={'name'}>{cstDataById.name}</div>
                            </FormGroup>
                            <FormGroup>
                                <div className={'label'}>{TXT_MM_CONTECT_ADMIN}</div>
                                <div className="membername">{cstDataById.memberContact}</div>
                            </FormGroup>

                            <FormGroup className="cstname">
                                <Inputbox
                                    type={'text'}
                                    value={chargeCode}
                                    label={MMCST_CHARGE_CODE}
                                    fieldFor={'chargeCode'}
                                    onChange={this.handleChange}
                                />
                            </FormGroup>
                            <FormGroup className="dropOuter">
                                <div className={'label'}>{MMCST_DURATION}</div>
                                {isExpiredCst ? (
                                    <div className={'dropdown'}>
                                        <Dropdown
                                            value={month}
                                            options={MonthListCode}
                                            fieldFor={'month'}
                                            onChange={this.handleChange}
                                        />
                                        <Dropdown
                                            value={year}
                                            options={YearsData}
                                            fieldFor={'year'}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                ) : (
                                    <div className={'duration'}>
                                        {cstDataById.loginValidity.split('/')[0] +
                                            '/' +
                                            cstDataById.loginValidity.split('/')[2]}
                                    </div>
                                )}
                            </FormGroup>
                        </div>
                        <div className={'rightSection'}>
                            <div className={'label'}>{CST_ACCESS}</div>
                            <div
                                className={c('emailGroup cst-emails', {
                                    even:
                                        cstDataById.cstUsers &&
                                        // eslint-disable-next-line array-callback-return
                                        cstDataById.cstUsers.filter(i => {
                                            if (i.isApproved && !isNewCst) {
                                                return i
                                            } else if (isNewCst) {
                                                return i
                                            }
                                        }).length %
                                            2 ===
                                            0,
                                    odd:
                                        cstDataById.cstUsers &&
                                        // eslint-disable-next-line array-callback-return
                                        cstDataById.cstUsers.filter(i => {
                                            if (i.isApproved && !isNewCst) {
                                                return i
                                            } else if (isNewCst) {
                                                return i
                                            }
                                        }).length %
                                            2 ===
                                            1,
                                })}
                            >
                                {cstDataById.cstUsers
                                    // eslint-disable-next-line array-callback-return
                                    .filter(i => {
                                        if (!isNewCst) {
                                            return i
                                        } else if (isNewCst) {
                                            return i
                                        }
                                    })
                                    .map(item => {
                                        return (
                                            <div
                                                key={item.id}
                                                className={c('email', {
                                                    hide1: !isNewCst && !item.isApproved,
                                                })}
                                            >
                                                <label htmlFor={item.id}>
                                                    {isNewCst && (
                                                        <Checkbox
                                                            checked={
                                                                this.state.selected.find(
                                                                    user => user.id === item.id && user.checked
                                                                ) !== undefined
                                                            }
                                                            onChange={() => this.toggleRow(item.id)}
                                                            className="checkBox"
                                                            disabled={item.isApproved}
                                                        >
                                                            {item.email}
                                                        </Checkbox>
                                                    )}
                                                    {!isNewCst && (
                                                        <Checkbox
                                                            checked={
                                                                this.state.selected.find(
                                                                    user => user.id === item.id && user.checked
                                                                ) !== undefined
                                                            }
                                                            onChange={() => this.toggleRow(item.id)}
                                                            className={`checkBox ${
                                                                item.Status.toLowerCase() === 'approved'
                                                                    ? 'active'
                                                                    : 'inactive'
                                                            }`}
                                                        >
                                                            {item.email}
                                                        </Checkbox>
                                                    )}
                                                </label>
                                            </div>
                                        )
                                    })}
                            </div>
                        </div>
                        <div className={'buttonContent'}>
                            {isNewCst ? (
                                <div className={'button-group'}>
                                    <button
                                        disabled={this.validateForm()}
                                        onClick={this.denyRequest}
                                        className={c('deny', {
                                            disabled: this.validateForm(),
                                        })}
                                    >
                                        {TXT_DENY_BTN}
                                    </button>
                                    <button
                                        disabled={this.validateForm()}
                                        onClick={this.handleSubmit}
                                        className={c('create', {
                                            disabled: this.validateForm(),
                                        })}
                                    >
                                        {TXT_CREATE_USER_BTN}
                                    </button>
                                </div>
                            ) : (
                                <div className="user-access-btns">
                                    <button
                                        disabled={this.validateForm()}
                                        onClick={this.blockUser}
                                        className={c('btn btn-outline-secondary btn-lg danger', {
                                            disabled: this.validateForm(),
                                        })}
                                    >
                                        Block user
                                    </button>
                                    <button
                                        disabled={this.validateForm()}
                                        onClick={this.unblockUser}
                                        className={c('btn btn-outline-secondary btn-lg success', {
                                            disabled: this.validateForm(),
                                        })}
                                    >
                                        Unblock User
                                    </button>
                                    <button className="btn btn-primary btn-lg" onClick={this.handleSubmit}>
                                        {TXT_SAVE_ADMIN}
                                    </button>
                                </div>
                            )}
                        </div>
                    </section>
                )}
            </div>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    cstGroupDetail: cstGroupDetail(),
    isListUpdated: isListUpdated(),
})
const dispatchProps = {
    fetchCSTById,
    updateCSTGroupDetail,
    resetStatus,
    blkUnBlkCST,
}

CSTDetail.propTypes = {
    toggleCSTDetails: PropTypes.func.isRequired,
    resetStatus: PropTypes.func.isRequired,
    updateCSTGroupDetail: PropTypes.func.isRequired,
    fetchCSTById: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, dispatchProps)(withAlert(CSTDetail))
