import React, { Component } from 'react'
import Slider from 'react-slick'
import ThumbnailComp from '../ThumbnailComp'
import { Tab } from 'react-tabs'
import './index.css'
import TabComp from '../TabComp'

class ReactSlider extends Component {
    state = {
        listData: this.props.listData,
        listId: this.props.listId,
        topCategoryName: this.props.topCategoryName,
        isEditMode: this.props.isEditMode,
        settings: this.props.settings,
        noImage: this.props.noImage,
        isPhaseList: this.props.isPhaseList,
        search: this.props.search,
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            listData: nextProps.listData,
            noImage: nextProps.noImage,
            isPhaseList: nextProps.isPhaseList,
            settings: nextProps.settings,
            search: nextProps.search,
        })
    }

    render() {
        const {
            listData,
            listId,
            topCategoryName,
            // isEditMode,
            settings,
            noImage,
            isPhaseList,
        } = this.state
        return (
            <Slider {...settings}>
                {noImage
                    ? !isPhaseList
                        ? listData.map(tab => (
                              <TabComp
                                  key={tab.id}
                                  categoryId={listId}
                                  topCategoryName={topCategoryName}
                                  tab={tab}
                                  checked={this.checked}
                                  toggleRow={() => this.props.toggleRow(tab.id)}
                                  selected={this.props.selected[tab.id] === true}
                                  selectedNested={this.props.selectedNested}
                                  search={this.props.search}
                                  toggleNestedRow={this.props.toggleNestedRow}
                              />
                          ))
                        : listData.map(item => <Tab key={item.id}>{item.name}</Tab>)
                    : listData.map(thumbnail => (
                          <ThumbnailComp
                              key={thumbnail.id}
                              categoryId={listId}
                              topCategoryName={topCategoryName}
                              isEditMode={this.props.isEditMode}
                              thumbnail={thumbnail}
                              edit={this.props.edit}
                              trash={this.props.trash}
                              imageAlignment={listId === '1' ? 'img-left child-pad-25' : 'img-top'}
                          />
                      ))}
            </Slider>
        )
    }
}

ReactSlider.defaultProps = {
    settings: {
        dots: false,
        arrows: true,
        slidesToShow: 3,
        rows: 3,
        slidesToScroll: 3,
        infinite: false,
        speed: 500,
        className: 'custom-slider',
    },
}

export default ReactSlider
