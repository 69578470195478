import { call, put, takeLatest } from 'redux-saga/effects'
import { callTestGetApi } from '../../Utils/apis'
import { MM_PRACTICE_GET } from './constant'
import { mmPracticeSuccessAction, mmPracticeFailedAction } from './actions'

export function* mmPractice() {
    const response = yield call(callTestGetApi)
    const responseData = response.data
    if (responseData.status) {
        yield put(mmPracticeSuccessAction({ responseData }))
    } else {
        yield put(mmPracticeFailedAction({ responseData }))
    }
}

export function* mmPracticeSaga() {
    yield takeLatest(`${MM_PRACTICE_GET}_PENDING`, mmPractice)
}

export default [mmPracticeSaga]
