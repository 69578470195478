import React, { Component } from 'react'
import ModuleHeader from '../ModuleHeader'
import { INDUSTRY_HEADING } from '../../Utils/constants'

class Industry extends Component {
    render() {
        return (
            <main className="industry-container">
                <ModuleHeader heading={INDUSTRY_HEADING} />
            </main>
        )
    }
}

export default Industry
