import { LEARN_GET } from './constant'

export const learnAction = data => ({ type: `${LEARN_GET}_PENDING`, data })

export const learnSuccessAction = data => ({
    type: `${LEARN_GET}_SUCCESS`,
    data,
})

export const learnFailedAction = data => ({ type: `${LEARN_GET}_FAILED`, data })
