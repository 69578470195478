import { fromJS } from 'immutable'
import { STATUS_SUCCESS, STATUS_ERROR, STATUS_FAILED } from '../../Utils/constants'

const ignoreActions = ['DEMO_ACTION_IGNORED', 'DEMO_ACTION_IGNORED_1']

const actionIgnored = actionType => {
    let isIgnored = false
    ignoreActions.forEach(ignoreAction => {
        if (~actionType.indexOf(ignoreAction)) isIgnored = true
    })
    return isIgnored
}

const INITIAL_STATE = fromJS({
    message: null,
    type: null,
    status: null,
})
const messageReducer = (state = INITIAL_STATE, action) => {
    const { type, data } = action
    if (actionIgnored(type)) return state
    const requestStatus = type.split('_').reverse()[0]
    switch (requestStatus) {
        case 'PENDING': {
            return state.set('type', null).set('status', 'pending')
        }
        case 'SUCCESS': {
            const { responseData } = data
            let message = (responseData && responseData.result && responseData.result.message) || ''
            return state
                .set('type', STATUS_SUCCESS)
                .set('status', STATUS_SUCCESS)
                .set('message', message)
        }
        case 'FAILED': {
            const { responseData } = data
            let message = (responseData && responseData.result && responseData.result.message) || ''
            return state
                .set('type', STATUS_ERROR)
                .set('status', STATUS_FAILED)
                .set('message', message)
        }
        default:
            return state
    }
}

export default messageReducer
