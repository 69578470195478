import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'

import { EditLopImageIcon } from '../../../../../Utils/svg'
import { createMarkup } from '../../../../../Utils/functions'

import './index.scss'

const VideoItem = props => {
    const { item, editMode, indexItem, toggleModal } = props

    const { videoUrl, isText } = item
    //eslint-disable-next-line
    const videoSrc = videoUrl.replace(/^https?\:\/\//i, '//')
    const iframe = `<iframe class="videoIframe" src=${videoSrc} allowfullscreen />`
    return (
        <div
            className={c('videoContainer', {
                fullVideo: isText === 'False',
            })}
        >
            {editMode && (
                <span className="editVideoIcon" onClick={() => toggleModal(true, { indexItem, videoUrl }, 1)}>
                    <EditLopImageIcon />
                </span>
            )}
            {videoSrc.includes('mm-digital-hub.com') || videoSrc.includes('/mm/') ? (
                <video controls key={videoSrc}>
                    <source src={videoSrc} />
                </video>
            ) : (
                <div dangerouslySetInnerHTML={createMarkup(iframe)} />
            )}
        </div>
    )
}

VideoItem.propTypes = {
    item: PropTypes.instanceOf(Object).isRequired,
    indexItem: PropTypes.number.isRequired,
    editMode: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    updateTemplate: PropTypes.func.isRequired,
    deleteInTemplate: PropTypes.func.isRequired,
}

export default VideoItem
