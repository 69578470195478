import { APP_SHOW_MENU, APP_SHOW_WIDTH, ADMIN_LOGOUT_USER, SET_LOP_HEADER, SET_IS_AUTHENTICATED } from './constant'

export const showMenu = toggleData => {
    let data = { toggleData: toggleData }
    return {
        type: APP_SHOW_MENU,
        data,
    }
}

export const showWidth = data => ({
    type: APP_SHOW_WIDTH,
    data,
})

export const setLogoutUser = () => ({
    type: `${ADMIN_LOGOUT_USER}_PENDING`,
})
export const setLogoutUserSuccess = data => ({
    type: `${ADMIN_LOGOUT_USER}_SUCCESS`,
    data,
})

export const setLogoutUserFailure = data => ({
    type: `${ADMIN_LOGOUT_USER}_FAILED`,
    data,
})

export const setLopHeader = (showLopHeader = false, disableSideBar = false, hideSideBar = false) => ({
    type: SET_LOP_HEADER,
    showLopHeader,
    disableSideBar,
    hideSideBar,
})

export const setAuthenticatedFlag = flag => ({
    type: SET_IS_AUTHENTICATED,
    data: flag,
})
