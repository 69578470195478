import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { createStructuredSelector } from 'reselect'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import { connect } from 'react-redux'
import c from 'classnames'
import { Collapse, Dropdown, Icon, Menu } from 'antd'
import Slider from 'react-slick'

import { downloadDocs, additionalArticles } from '../../../../Containers/LopContent/selector'
import ArticleModal from './ArticleModal'
import HeadingModal from '../Template3/HeadingModal'
import SafeImage from '../../../SafeImage'
import { ConfirmAlertBox } from '../../../../Containers/Library/Alerts'
import { DownloadFileIcon, PencilIcon, BinDelete, Arrow } from '../../../../Utils/svg'
import { getPrefixedUrl } from '../../../../Utils/apis'
import { SITE_TXT } from '../../../../Utils/constants'
import {
    fetchCurrentTemplate,
    addArticle,
    editArticle,
    deleteArticle,
    setOrderOfItem,
    downloadLOPDocs,
    setSortedArray,
    addBlock,
    updateBlock,
    deleteBlock,
    updateBlockHeader,
    fetchAdditionalDetails,
    addArticleFromList,
} from '../../../../Containers/LopContent/actions'
import { saveDocuments } from '../../../../Containers/Library/actions'
import './index.scss'

const SortableItem = SortableElement(props => {
    return (
        <div className="article-box">
            <div className="button-group">
                {props.editMode && (
                    <Fragment>
                        <Dropdown
                            overlay={props.menuItem}
                            onVisibleChange={() => props.getItemId(props.value, props.itemIndex, props.dumIndex)}
                            trigger={['click', 'hover']}
                        >
                            <span className="ant-dropdown-link">
                                Move To <Icon type="down" />
                            </span>
                        </Dropdown>
                        <span
                            title="Edit"
                            onClick={() => props.handleEdit(props.value, props.itemIndex, `${props.dumIndex}`)}
                        >
                            <PencilIcon width="15px" height="15px" />
                        </span>
                        <span
                            className="trash-black"
                            title="Delete"
                            onClick={() =>
                                props.handleDelete(props.value.id, props.value, props.itemIndex, props.dumIndex)
                            }
                        >
                            <BinDelete width="13px" height="13px" />
                        </span>
                    </Fragment>
                )}
                <span title="Download" onClick={() => props.handleDownload(props.value.id)}>
                    <DownloadFileIcon />
                </span>
            </div>
            <div className="article-image">
                <SafeImage imageUrl={getPrefixedUrl(props.value.image)} supportClass="articleDefaultImage" />
            </div>
            <div className="name">{props.value.name}</div>
        </div>
    )
})

const SortableList = SortableContainer(props => {
    const {
        addNewArticle,
        editMode,
        block_id,
        data,
        disableReorder,
        handleDelete,
        handleEdit,
        handleDownload,
        menuItem,
        getItemId,
        itemIndex,
    } = props
    return (
        <div
            className={c('team-container', {
                editmode: editMode,
            })}
        >
            {data &&
                data.length > 0 &&
                data.map((value, index) => {
                    return (
                        <SortableItem
                            key={`item-${index}`}
                            dumIndex={index}
                            index={index}
                            value={value}
                            disabled={!editMode || disableReorder}
                            handleDelete={handleDelete}
                            handleEdit={handleEdit}
                            editMode={editMode}
                            handleDownload={handleDownload}
                            menuItem={menuItem}
                            getItemId={getItemId}
                            itemIndex={itemIndex}
                        />
                    )
                })}
            {editMode && (
                <div onClick={() => addNewArticle(block_id)} className="add-team">
                    <span className="btn-add-team">Add Additional Materials</span>
                </div>
            )}
        </div>
    )
})

class Template5 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            sectionId: props.sectionId,
            articleData: {},
            openHeadingModal: false,
            header: '',
            curItemIndex: null,
            dumIndex: null,
            oldIndex: null,
            curBlockId: null,
            curBlockIndex: null,
        }
    }

    componentDidMount() {
        const { sectionDetails, fetchAdditionalDetails, additionalArticles } = this.props
        const { sectionId, templateId } = sectionDetails
        if (additionalArticles.length === 0) {
            fetchAdditionalDetails({ sectionId, templateId })
        }
    }

    componentDidUpdate(nextProps) {
        if (nextProps.downloadDocs.length > 0) {
            const { downloadDocs } = nextProps
            if (downloadDocs[0].result && downloadDocs[0].result.fileURL) {
                this.props.saveDocuments(getPrefixedUrl(downloadDocs[0].result.fileURL))
            }
        }
    }

    handleCloseModal = () => {
        this.setState({
            open: false,
            articleData: {},
            curBlockId: null,
            curBlockIndex: null,
        })
    }

    handleAddArticle = (curBlockId, curBlockIndex) => {
        this.setState({
            open: true,
            curBlockId,
            curBlockIndex,
        })
    }

    handleEdit = (item, curBlockIndex, curBlockId) => {
        this.setState({
            open: true,
            articleData: item,
            curBlockId,
            curBlockIndex,
        })
    }

    handleDelete = (id, mainItem, curBlockIndex, curItemIndex) => {
        const title = SITE_TXT.TXT_CONFIRM_DELETE
        const message = SITE_TXT.TXT_ARTICLE_DELETE_MESSAGE
        ConfirmAlertBox(title, message, () => this.trashFinally(id, mainItem, curBlockIndex, curItemIndex))
    }

    trashFinally = (id, mainItem, curBlockIndex, curItemIndex) => {
        const { deleteArticle, sectionDetails } = this.props
        const { sectionId, templateId } = sectionDetails
        const data = {
            sectionId,
            templateId,
            id,
        }
        deleteArticle(data, mainItem, curBlockIndex, curItemIndex)
    }

    onSortEnd = ({ oldIndex, newIndex }, array, index) => {
        const { content } = this.props
        const { sectionContent = [] } = content
        sectionContent[index].partners = arrayMove(array, oldIndex, newIndex)
        this.props.setSortedArray(sectionContent)
    }

    handleDownload = id => {
        const { downloadLOPDocs, sectionDetails } = this.props
        const { templateId } = sectionDetails
        const data = {
            ids: id,
            templateId,
        }
        downloadLOPDocs(data)
    }

    getItemId = (item, index, x) => {
        this.setState({
            currentVisibleItem: item,
            oldIndex: index,
            dumIndex: x,
        })
    }

    moveTo = (item, index) => {
        const { content, updateBlock, addArticleFromList } = this.props
        const { currentVisibleItem, dumIndex, oldIndex: removeIndex } = this.state
        const { sectionContent = [] } = content
        const { block_id } = sectionContent[index]
        const data = {
            dataToMove: { ...currentVisibleItem, block_id },
            index,
            removeIndex,
            dumIndex,
        }

        if (removeIndex === null) {
            addArticleFromList(data)
        } else {
            updateBlock(data)
        }
    }

    handleHeaderDelete = (e, item, index) => {
        e.stopPropagation()
        const title = SITE_TXT.TXT_CONFIRM_DELETE
        const message = SITE_TXT.TXT_BLOCK_DELETE_MESSAGE
        ConfirmAlertBox(title, message, () => this.addArticlesInList(item, index))
    }

    addArticlesInList = (item, index) => {
        const { deleteBlock, additionalArticles } = this.props
        const partners = item.partners.map(article => ({ ...article, isDelete: 'true' }))
        deleteBlock({ ...item, isDelete: 'true', partners }, index, [...partners, ...additionalArticles])
    }

    handleHeaderShift = (e, moveType, item, index) => {
        e.stopPropagation()
        const { content } = this.props
        const { sectionContent = [] } = content
        if (moveType === 'up') {
            if (index !== 0) {
                const newIndex = index - 1
                this.props.setSortedArray(arrayMove(sectionContent, index, newIndex))
            }
        } else {
            if (index < sectionContent.length) {
                const newIndex = index + 1
                this.props.setSortedArray(arrayMove(sectionContent, index, newIndex))
            }
        }
    }

    handleHeadingModal = () => {
        this.setState({
            openHeadingModal: true,
        })
    }

    handleHeaderEdit = (e, item, index) => {
        e.stopPropagation()
        this.setState({
            openHeadingModal: true,
            header: item.block_name,
            curItemIndex: index,
        })
    }

    handleHeadingCloseModal = () => {
        this.setState({
            openHeadingModal: false,
            header: '',
        })
    }

    render() {
        const {
            editMode,
            addBlock,
            disableReorder,
            content,
            sectionDetails,
            addArticle,
            editArticle,
            updateBlockHeader,
            additionalArticles,
            sliderSettings,
        } = this.props
        const { sectionContent = [] } = content
        const { block_id = '' } = sectionContent[0] || {}
        const { open, articleData, openHeadingModal, header, curItemIndex, curBlockId, curBlockIndex } = this.state
        const { templateId, sectionId } = sectionDetails

        const menuItem = sectionContent.map((item, index) => (
            <Menu.Item key={index} onClick={() => this.moveTo(item, index)}>
                {item.block_name}
            </Menu.Item>
        ))
        const menu = <Menu>{menuItem}</Menu>
        const genExtra = (item, index, editMode) => {
            if (editMode) {
                return (
                    <div>
                        <Icon
                            component={Arrow}
                            onClick={e => this.handleHeaderShift(e, 'up', item, index)}
                            style={{ marginRight: '10px' }}
                            className={'upArrow'}
                        />
                        <Icon
                            component={Arrow}
                            onClick={e => this.handleHeaderShift(e, 'down', item, index)}
                            style={{ marginRight: '50px' }}
                            className={'downArrow'}
                        />
                        <Icon
                            component={PencilIcon}
                            onClick={e => this.handleHeaderEdit(e, item, index)}
                            style={{ marginRight: '40px' }}
                        />
                        {sectionContent.filter(item => item.isDelete === 'false').length > 1 ? (
                            <Icon
                                className="trash-black"
                                component={BinDelete}
                                onClick={e => this.handleHeaderDelete(e, item, index)}
                                style={{ marginRight: '38px' }}
                            />
                        ) : null}
                    </div>
                )
            }
            return null
        }
        const { Panel } = Collapse

        return (
            <div className={`sectionContent5 ${editMode ? ' ineditmode' : ''}`}>
                {editMode && (
                    <div className="add-panel">
                        <div onClick={this.handleHeadingModal}>
                            <div className="plus">
                                <div className="cross">&nbsp;</div>
                            </div>
                            {SITE_TXT.TXT_ADD_HEADING}
                        </div>
                    </div>
                )}
                <Collapse defaultActiveKey={block_id} expandIconPosition="right" accordion bordered={false}>
                    {sectionContent.map((item, index) => {
                        if (item.isDelete === 'false') {
                            return (
                                <Panel
                                    header={item && item.block_name}
                                    key={item.block_id}
                                    extra={genExtra(item, index, editMode)}
                                >
                                    <SortableList
                                        axis="xy"
                                        distance={1}
                                        data={item.partners}
                                        onSortEnd={dataOld => this.onSortEnd(dataOld, item.partners, index)}
                                        block_id={item.block_id}
                                        lockToContainerEdges={true}
                                        editMode={editMode}
                                        disableReorder={disableReorder}
                                        handleDelete={this.handleDelete}
                                        handleEdit={this.handleEdit}
                                        helperClass="movingIcon"
                                        handleDownload={this.handleDownload}
                                        menuItem={menu}
                                        getItemId={this.getItemId}
                                        itemIndex={index}
                                        addNewArticle={block_id => this.handleAddArticle(block_id, index)}
                                    />
                                </Panel>
                            )
                        }
                        return null
                    })}
                </Collapse>
                {open && (
                    <ArticleModal
                        closeModal={this.handleCloseModal}
                        addArticle={addArticle}
                        editArticle={editArticle}
                        sectionDetails={sectionDetails}
                        articleData={articleData}
                        isEditMode={!isEmpty(articleData)}
                        showImageMsg
                        curBlockId={curBlockId}
                        curBlockIndex={curBlockIndex}
                        blockCategory={sectionContent.map(item => ({
                            key: item.block_id,
                            value: item.block_name,
                        }))}
                    />
                )}
                {openHeadingModal && (
                    <HeadingModal
                        editMode={editMode}
                        sectionId={sectionId}
                        templateId={templateId}
                        header={header}
                        curItemIndex={curItemIndex}
                        addBlock={addBlock}
                        updateBlockHeader={updateBlockHeader}
                        onCloseModal={this.handleHeadingCloseModal}
                    />
                )}
                {additionalArticles.length && editMode ? (
                    <div className="removedContent">
                        <h3>
                            <span>{SITE_TXT.TXT_ADDITONAL_CONTENT}</span>
                        </h3>
                        <Slider {...sliderSettings}>
                            {additionalArticles.map((item, deletedIndex) => {
                                const { name, id, image } = item
                                return (
                                    <div key={id} className="deletedItemMain">
                                        {image && <img alt="" src={getPrefixedUrl(image)} />}
                                        <Dropdown
                                            overlay={menu}
                                            onVisibleChange={() => this.getItemId(item, null, deletedIndex)}
                                            trigger={['click', 'hover']}
                                            placement="topRight"
                                        >
                                            <span className="ant-dropdown-link">
                                                Move To <Icon type="down" />
                                            </span>
                                        </Dropdown>
                                        <div className="articleContent ellipsisPro">{name}</div>
                                    </div>
                                )
                            })}
                        </Slider>
                    </div>
                ) : null}
            </div>
        )
    }
}

Template5.propTypes = {
    editMode: PropTypes.bool.isRequired,
    disableReorder: PropTypes.bool.isRequired,
    content: PropTypes.instanceOf(Object).isRequired,
    sectionDetails: PropTypes.instanceOf(Object).isRequired,
    sliderSettings: PropTypes.instanceOf(Object).isRequired,
    additionalArticles: PropTypes.instanceOf(Array).isRequired,
    fetchCurrentTemplate: PropTypes.func.isRequired,
    addArticle: PropTypes.func.isRequired,
    editArticle: PropTypes.func.isRequired,
    deleteArticle: PropTypes.func.isRequired,
    downloadLOPDocs: PropTypes.func.isRequired,
    setOrderOfItem: PropTypes.func.isRequired,
    setSortedArray: PropTypes.func.isRequired,
    addBlock: PropTypes.func.isRequired,
    updateBlock: PropTypes.func.isRequired,
    addArticleFromList: PropTypes.func.isRequired,
    deleteBlock: PropTypes.func.isRequired,
    updateBlockHeader: PropTypes.func.isRequired,
    fetchAdditionalDetails: PropTypes.func.isRequired,
    saveDocuments: PropTypes.func.isRequired,
}

const stateToProps = createStructuredSelector({
    downloadDocs: downloadDocs(),
    additionalArticles: additionalArticles(),
})

const dispatchProps = {
    fetchCurrentTemplate,
    addArticle,
    editArticle,
    deleteArticle,
    setOrderOfItem,
    setSortedArray,
    downloadLOPDocs,
    addBlock,
    updateBlock,
    addArticleFromList,
    deleteBlock,
    updateBlockHeader,
    saveDocuments,
    fetchAdditionalDetails,
}

export default connect(stateToProps, dispatchProps)(Template5)
