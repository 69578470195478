import {
    LOP_GETSTARTED_ITEMS_GET,
    LOP_GETSTARTED_ITEMS_GET_MORE,
    LOP_GETSTARTED_ITEM_DELETE,
    LOP_GOLD_ITEMS_GET,
    LOP_ADD_ITEM,
    LOP_GOLD_ITEMS_MORE,
    LOP_EDIT_ITEM,
    LOP_GOLD_DELETE_ITEM,
    LOP_GOLD_DELETE_FOLDER,
    LOP_ADD_FOLDER,
    LOP_EDIT_FOLDER,
    LOP_FETCH_FOLDER_LIST,
    LOP_GOLD_EMAIL_ITEMS,
    LOP_GOLD_DOWNLOAD_ITEMS,
    LOP_FETCH_INDUSTRY_TAGS,
    LOP_FETCH_TOPICS_KEYWORDS_TAGS,
    LOP_GEOGRAPHY,
    LOP_DEALTYPE,
    LOP_DEALSIZE,
    LOP_CASE_STUDIES_ITEMS,
    LOP_CASE_STUDIES_ITEMS_MORE,
    LOP_CASE_STUDIES_ADD,
    LOP_CASE_STUDIES_EDIT_GET,
    LOP_CASE_STUDIES_EDIT_PUT,
    LOP_EXPERT_ITEMS_GET,
    LOP_EXPERT_ITEMS_MORE,
    LOP_CLIENTREF_ITEMS_GET,
    LOP_CLIENTREF_ITEMS_MORE,
    LOP_EXPERT_CV_ADD,
    LOP_EXPERT_CV_EDIT,
    LOP_EXPERT_CV_FETCH,
    LOP_REGION_BASE,
    LOP_FETCH_EXPERT_INDUSTRY_TAGS,
    LOP_FETCH_EXPERT_ROLE_TAGS,
    LOP_FETCH_EXPERT_TOPICS_TAGS,
    LOP_CLEAR_FETCHED_DATA,
    LOP_FETCH_COUNTRY_CODE,
    LOP_ADD_CLIENT,
    LOP_EDIT_CLIENT_FETCH,
    LOP_EDIT_CLIENT_PUT,
    LOP_EMPTY_CLIENT_DATA,
    CREATE_LOP,
} from './constant'

export const lopGetStartedItems = data => ({
    type: `${LOP_GETSTARTED_ITEMS_GET}_PENDING`,
    data,
})

export const lopGetStartedItemsSuccess = data => ({
    type: `${LOP_GETSTARTED_ITEMS_GET}_SUCCESS`,
    data,
})

export const lopGetStartedItemsFailure = data => ({
    type: `${LOP_GETSTARTED_ITEMS_GET}_FAILED`,
    data,
})

export const lopGetStartedItemsMore = data => ({
    type: `${LOP_GETSTARTED_ITEMS_GET_MORE}_PENDING`,
    data,
})

export const lopGetStartedItemsMoreSuccess = data => ({
    type: `${LOP_GETSTARTED_ITEMS_GET_MORE}_SUCCESS`,
    data,
})

export const lopGetStartedItemsMoreFailure = data => ({
    type: `${LOP_GETSTARTED_ITEMS_GET_MORE}_FAILED`,
    data,
})

export const lopGetStartedItemsDelete = data => ({
    type: `${LOP_GETSTARTED_ITEM_DELETE}_PENDING`,
    data,
})

export const lopGetStartedItemsDeleteSuccess = data => ({
    type: `${LOP_GETSTARTED_ITEM_DELETE}_SUCCESS`,
    data,
})

export const lopGetStartedItemsDeleteFailure = data => ({
    type: `${LOP_GETSTARTED_ITEM_DELETE}_FAILED`,
    data,
})

export const lopGoldItems = data => ({
    type: `${LOP_GOLD_ITEMS_GET}_PENDING`,
    data,
})

export const lopGoldItemsSuccess = data => ({
    type: `${LOP_GOLD_ITEMS_GET}_SUCCESS`,
    data,
})

export const lopGoldItemsFailure = data => ({
    type: `${LOP_GOLD_ITEMS_GET}_FAILED`,
    data,
})
export const lopAddItem = data => ({
    type: `${LOP_ADD_ITEM}_PENDING`,
    data,
})

export const lopAddItemSuccess = data => ({
    type: `${LOP_ADD_ITEM}_SUCCESS`,
    data,
})

export const lopAddItemFailure = data => ({
    type: `${LOP_ADD_ITEM}_FAILED`,
    data,
})

export const lopEditItem = data => ({
    type: `${LOP_EDIT_ITEM}_PENDING`,
    data,
})

export const lopEditItemSuccess = data => ({
    type: `${LOP_EDIT_ITEM}_SUCCESS`,
    data,
})

export const lopEditItemFailure = data => ({
    type: `${LOP_EDIT_ITEM}_FAILED`,
    data,
})
export const lopGoldItemsMore = data => ({
    type: `${LOP_GOLD_ITEMS_MORE}_PENDING`,
    data,
})

export const lopGoldItemsMoreSuccess = data => ({
    type: `${LOP_GOLD_ITEMS_MORE}_SUCCESS`,
    data,
})

export const lopGoldItemsMoreFailure = data => ({
    type: `${LOP_GOLD_ITEMS_MORE}_FAILED`,
    data,
})

export const lopGoldDeleteItems = data => ({
    type: `${LOP_GOLD_DELETE_ITEM}_PENDING`,
    data,
})

export const lopGoldDeleteItemsSuccess = data => ({
    type: `${LOP_GOLD_DELETE_ITEM}_SUCCESS`,
    data,
})

export const lopGoldDeleteItemsFailure = data => ({
    type: `${LOP_GOLD_DELETE_ITEM}_FAILED`,
    data,
})

export const lopGoldDeleteFolder = data => ({
    type: `${LOP_GOLD_DELETE_FOLDER}_PENDING`,
    data,
})

export const lopGoldDeleteFolderSuccess = data => ({
    type: `${LOP_GOLD_DELETE_FOLDER}_SUCCESS`,
    data,
})

export const lopGoldDeleteFolderFailure = data => ({
    type: `${LOP_GOLD_DELETE_FOLDER}_FAILED`,
    data,
})

export const lopAddFolder = data => ({
    type: `${LOP_ADD_FOLDER}_PENDING`,
    data,
})

export const lopAddFolderSuccess = data => ({
    type: `${LOP_ADD_FOLDER}_SUCCESS`,
    data,
})

export const lopAddFolderFailure = data => ({
    type: `${LOP_ADD_FOLDER}_FAILED`,
    data,
})

export const lopEditFolder = data => ({
    type: `${LOP_EDIT_FOLDER}_PENDING`,
    data,
})

export const lopEditFolderSuccess = data => ({
    type: `${LOP_EDIT_FOLDER}_SUCCESS`,
    data,
})

export const lopEditFolderFailure = data => ({
    type: `${LOP_EDIT_FOLDER}_FAILED`,
    data,
})

export const lopFetchFolderList = data => ({
    type: `${LOP_FETCH_FOLDER_LIST}_PENDING`,
    data,
})

export const lopFetchFolderListSuccess = data => ({
    type: `${LOP_FETCH_FOLDER_LIST}_SUCCESS`,
    data,
})

export const lopFetchFolderListFailure = data => ({
    type: `${LOP_FETCH_FOLDER_LIST}_FAILED`,
    data,
})

export const lopGoldEmailItems = data => ({
    type: `${LOP_GOLD_EMAIL_ITEMS}_PENDING`,
    data,
})

export const lopGoldEmailItemsSuccess = data => ({
    type: `${LOP_GOLD_EMAIL_ITEMS}_SUCCESS`,
    data,
})

export const lopGoldEmailItemsFailure = data => ({
    type: `${LOP_GOLD_EMAIL_ITEMS}_FAILED`,
    data,
})

export const lopGoldDownloadItems = data => ({
    type: `${LOP_GOLD_DOWNLOAD_ITEMS}_PENDING`,
    data,
})

export const lopGoldDownloadItemsSuccess = data => ({
    type: `${LOP_GOLD_DOWNLOAD_ITEMS}_SUCCESS`,
    data,
})

export const lopGoldDownloadItemsFailure = data => ({
    type: `${LOP_GOLD_DOWNLOAD_ITEMS}_FAILED`,
    data,
})

export const lopExpertItems = data => ({
    type: `${LOP_EXPERT_ITEMS_GET}_PENDING`,
    data,
})

export const lopExpertItemsSuccess = data => ({
    type: `${LOP_EXPERT_ITEMS_GET}_SUCCESS`,
    data,
})

export const lopExpertItemsFailure = data => ({
    type: `${LOP_EXPERT_ITEMS_GET}_FAILED`,
    data,
})

export const lopExpertItemsMore = data => ({
    type: `${LOP_EXPERT_ITEMS_MORE}_PENDING`,
    data,
})

export const lopExpertItemsMoreSuccess = data => ({
    type: `${LOP_EXPERT_ITEMS_MORE}_SUCCESS`,
    data,
})

export const lopExpertItemsMoreFailure = data => ({
    type: `${LOP_EXPERT_ITEMS_MORE}_FAILED`,
    data,
})

export const lopIndustryTags = data => ({
    type: `${LOP_FETCH_INDUSTRY_TAGS}_PENDING`,
    data,
})

export const lopIndustryTagsSuccess = data => ({
    type: `${LOP_FETCH_INDUSTRY_TAGS}_SUCCESS`,
    data,
})

export const lopIndustryTagsFailure = data => ({
    type: `${LOP_FETCH_INDUSTRY_TAGS}_FAILED`,
    data,
})

export const lopTopicsKeywordsTags = data => ({
    type: `${LOP_FETCH_TOPICS_KEYWORDS_TAGS}_PENDING`,
    data,
})

export const lopTopicsKeywordsTagsSuccess = data => ({
    type: `${LOP_FETCH_TOPICS_KEYWORDS_TAGS}_SUCCESS`,
    data,
})

export const lopTopicskeywordsTagsFailure = data => ({
    type: `${LOP_FETCH_TOPICS_KEYWORDS_TAGS}_FAILED`,
    data,
})

export const lopGeography = data => ({
    type: `${LOP_GEOGRAPHY}_PENDING`,
    data,
})

export const lopGeographySuccess = data => ({
    type: `${LOP_GEOGRAPHY}_SUCCESS`,
    data,
})

export const lopGeographyFailure = data => ({
    type: `${LOP_GEOGRAPHY}_FAILED`,
    data,
})

export const lopDealType = data => ({
    type: `${LOP_DEALTYPE}_PENDING`,
    data,
})

export const lopDealTypeSuccess = data => ({
    type: `${LOP_DEALTYPE}_SUCCESS`,
    data,
})

export const lopDealTypeFailure = data => ({
    type: `${LOP_DEALTYPE}_FAILED`,
    data,
})

export const lopDealSize = data => ({
    type: `${LOP_DEALSIZE}_PENDING`,
    data,
})

export const lopDealSizeSuccess = data => ({
    type: `${LOP_DEALSIZE}_SUCCESS`,
    data,
})

export const lopDealSizeFailure = data => ({
    type: `${LOP_DEALSIZE}_FAILED`,
    data,
})

export const lopCaseStudiesItems = data => ({
    type: `${LOP_CASE_STUDIES_ITEMS}_PENDING`,
    data,
})

export const lopCaseStudiesItemsSuccess = data => ({
    type: `${LOP_CASE_STUDIES_ITEMS}_SUCCESS`,
    data,
})

export const lopCaseStudiesItemsFailure = data => ({
    type: `${LOP_CASE_STUDIES_ITEMS}_FAILED`,
    data,
})
export const lopCaseStudiesItemsMore = data => ({
    type: `${LOP_CASE_STUDIES_ITEMS_MORE}_PENDING`,
    data,
})

export const lopCaseStudiesItemsMoreSuccess = data => ({
    type: `${LOP_CASE_STUDIES_ITEMS_MORE}_SUCCESS`,
    data,
})

export const lopCaseStudiesItemsMoreFailure = data => ({
    type: `${LOP_CASE_STUDIES_ITEMS_MORE}_FAILED`,
    data,
})

export const lopCaseStudiesAddItem = data => ({
    type: `${LOP_CASE_STUDIES_ADD}_PENDING`,
    data,
})

export const lopCaseStudiesAddItemSuccess = data => ({
    type: `${LOP_CASE_STUDIES_ADD}_SUCCESS`,
    data,
})

export const lopCaseStudiesAddItemFailure = data => ({
    type: `${LOP_CASE_STUDIES_ADD}_FAILED`,
    data,
})

export const caseStudEditItemGet = data => ({
    type: `${LOP_CASE_STUDIES_EDIT_GET}_PENDING`,
    data,
})

export const caseStudEditItemGetSuccess = data => ({
    type: `${LOP_CASE_STUDIES_EDIT_GET}_SUCCESS`,
    data,
})

export const caseStudEditItemGetFailure = data => ({
    type: `${LOP_CASE_STUDIES_EDIT_GET}_FAILED`,
    data,
})

export const caseStudEditItemPut = data => ({
    type: `${LOP_CASE_STUDIES_EDIT_PUT}_PENDING`,
    data,
})

export const caseStudEditItemPutSuccess = data => ({
    type: `${LOP_CASE_STUDIES_EDIT_PUT}_SUCCESS`,
    data,
})

export const caseStudEditItemPutFailure = data => ({
    type: `${LOP_CASE_STUDIES_EDIT_PUT}_FAILED`,
    data,
})

export const lopClientRefItems = data => ({
    type: `${LOP_CLIENTREF_ITEMS_GET}_PENDING`,
    data,
})

export const lopClientRefItemsSuccess = data => ({
    type: `${LOP_CLIENTREF_ITEMS_GET}_SUCCESS`,
    data,
})

export const lopClientRefItemsFailure = data => ({
    type: `${LOP_CLIENTREF_ITEMS_GET}_FAILED`,
    data,
})
export const lopClientRefItemsMore = data => ({
    type: `${LOP_CLIENTREF_ITEMS_MORE}_PENDING`,
    data,
})

export const lopClientRefItemsMoreSuccess = data => ({
    type: `${LOP_CLIENTREF_ITEMS_MORE}_SUCCESS`,
    data,
})

export const lopClientRefItemsMoreFailure = data => ({
    type: `${LOP_CLIENTREF_ITEMS_MORE}_FAILED`,
    data,
})

export const lopExpertCvAddItem = data => ({
    type: `${LOP_EXPERT_CV_ADD}_PENDING`,
    data,
})

export const lopExpertCvAddItemSuccess = data => ({
    type: `${LOP_EXPERT_CV_ADD}_SUCCESS`,
    data,
})

export const lopExpertCvAddItemFailure = data => ({
    type: `${LOP_EXPERT_CV_ADD}_FAILED`,
    data,
})

export const lopExpertCvEditItem = data => ({
    type: `${LOP_EXPERT_CV_EDIT}_PENDING`,
    data,
})

export const lopExpertCvEditItemSuccess = data => ({
    type: `${LOP_EXPERT_CV_EDIT}_SUCCESS`,
    data,
})

export const lopExpertCvEditItemFailure = data => ({
    type: `${LOP_EXPERT_CV_EDIT}_FAILED`,
    data,
})

export const lopExpertCvFetchItem = data => ({
    type: `${LOP_EXPERT_CV_FETCH}_PENDING`,
    data,
})

export const lopExpertCvFetchItemSuccess = data => ({
    type: `${LOP_EXPERT_CV_FETCH}_SUCCESS`,
    data,
})

export const lopExpertCvFetchItemFailure = data => ({
    type: `${LOP_EXPERT_CV_FETCH}_FAILED`,
    data,
})

export const lopRegionBase = data => ({
    type: `${LOP_REGION_BASE}_PENDING`,
    data,
})

export const lopRegionBaseSuccess = data => ({
    type: `${LOP_REGION_BASE}_SUCCESS`,
    data,
})

export const lopRegionBaseFailure = data => ({
    type: `${LOP_REGION_BASE}_FAILED`,
    data,
})

export const lopExpertIndustryTags = data => ({
    type: `${LOP_FETCH_EXPERT_INDUSTRY_TAGS}_PENDING`,
    data,
})

export const lopExpertIndustryTagsSuccess = data => ({
    type: `${LOP_FETCH_EXPERT_INDUSTRY_TAGS}_SUCCESS`,
    data,
})

export const lopExpertIndustryTagsFailure = data => ({
    type: `${LOP_FETCH_EXPERT_INDUSTRY_TAGS}_FAILED`,
    data,
})

export const lopExpertRoleTags = data => ({
    type: `${LOP_FETCH_EXPERT_ROLE_TAGS}_PENDING`,
    data,
})

export const lopExpertRoleTagsSuccess = data => ({
    type: `${LOP_FETCH_EXPERT_ROLE_TAGS}_SUCCESS`,
    data,
})

export const lopExpertRoleTagsFailure = data => ({
    type: `${LOP_FETCH_EXPERT_ROLE_TAGS}_FAILED`,
    data,
})

export const lopExpertTopicsTags = data => ({
    type: `${LOP_FETCH_EXPERT_TOPICS_TAGS}_PENDING`,
    data,
})

export const lopExpertTopicsTagsSuccess = data => ({
    type: `${LOP_FETCH_EXPERT_TOPICS_TAGS}_SUCCESS`,
    data,
})

export const lopExpertTopicsTagsFailure = data => ({
    type: `${LOP_FETCH_EXPERT_TOPICS_TAGS}_FAILED`,
    data,
})

export const lopClearFetchedEditData = data => ({
    type: `${LOP_CLEAR_FETCHED_DATA}_PENDING`,
    data,
})

export const lopClearFetchedEditDataSuccess = data => ({
    type: `${LOP_CLEAR_FETCHED_DATA}_SUCCESS`,
    data,
})

export const lopClearFetchedEditDataFailure = data => ({
    type: `${LOP_CLEAR_FETCHED_DATA}_FAILED`,
    data,
})

export const lopFetchedCountryCode = data => ({
    type: `${LOP_FETCH_COUNTRY_CODE}_PENDING`,
    data,
})

export const lopFetchedCountryCodeSuccess = data => ({
    type: `${LOP_FETCH_COUNTRY_CODE}_SUCCESS`,
    data,
})

export const lopFetchedCountryCodeFailure = data => ({
    type: `${LOP_FETCH_COUNTRY_CODE}_FAILED`,
    data,
})

export const lopAddClient = data => ({
    type: `${LOP_ADD_CLIENT}_PENDING`,
    data,
})

export const lopAddClientSuccess = data => ({
    type: `${LOP_ADD_CLIENT}_SUCCESS`,
    data,
})

export const lopAddClientFailure = data => ({
    type: `${LOP_ADD_CLIENT}_FAILED`,
    data,
})

export const lopEditClientFetch = data => ({
    type: `${LOP_EDIT_CLIENT_FETCH}_PENDING`,
    data,
})

export const lopEditClientFetchSuccess = data => ({
    type: `${LOP_EDIT_CLIENT_FETCH}_SUCCESS`,
    data,
})

export const lopEditClientFetchFailure = data => ({
    type: `${LOP_EDIT_CLIENT_FETCH}_FAILED`,
    data,
})

export const lopEditClientPut = data => ({
    type: `${LOP_EDIT_CLIENT_PUT}_PENDING`,
    data,
})

export const lopEditClientPutSuccess = data => ({
    type: `${LOP_EDIT_CLIENT_PUT}_SUCCESS`,
    data,
})

export const lopEditClientPutFailure = data => ({
    type: `${LOP_EDIT_CLIENT_PUT}_FAILED`,
    data,
})

export const lopEmptyClientRef = data => ({
    type: `${LOP_EMPTY_CLIENT_DATA}`,
    data,
})

export const uploadLop = data => ({
    type: `${CREATE_LOP}_PENDING`,
    data,
})

export const uploadLopSuccess = data => ({
    type: `${CREATE_LOP}_SUCCESS`,
    data,
})

export const uploadLopFailed = data => ({
    type: `${CREATE_LOP}_FAILED`,
    data,
})
