import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CreateCst from './CreateCst'
import CSTlist from './CSTlist'
import { createStructuredSelector } from 'reselect'
import { mmCstContent, isListUpdated, singleRecord } from './selector'
import { addCstGroup, editCstGroup, resetStatus, getSingleCstGroup, resetValue, validateCC } from './action'
import { connect } from 'react-redux'

class MMCst extends Component {
    state = {
        createCST: false,
        cstData: null,
        editMode: false,
        singleRecord: {},
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.singleRecord.length) {
            this.setState({
                createCST: !prevState.createCST,
            })
        }
        if (this.props.singleRecord.length) {
            this.props.resetValue()
        }
    }

    toggleCreate = item => {
        const { getSingleCstGroup } = this.props
        this.setState(
            prevState => ({
                createCST: !prevState.createCST,
                editMode: item.editMode,
            }),
            () => {
                if (item.cstData) {
                    getSingleCstGroup(item.cstData.id)
                }
            }
        )
    }

    render() {
        const { createCST, editMode } = this.state
        const {
            addCstGroup,
            editCstGroup,
            isListUpdated,
            resetStatus,
            resetValue,
            singleRecord,
            validateCC,
        } = this.props
        return (
            <main>
                {createCST ? (
                    <CreateCst
                        toggleCreate={this.toggleCreate}
                        addCstGroup={addCstGroup}
                        editCstGroup={editCstGroup}
                        editMode={editMode}
                        status={isListUpdated}
                        resetStatus={resetStatus}
                        singleRecord={singleRecord}
                        resetValue={resetValue}
                        validateCC={validateCC}
                    />
                ) : (
                    <CSTlist toggleCreate={this.toggleCreate} />
                )}
            </main>
        )
    }
}

MMCst.propTypes = {
    setLopHeader: PropTypes.func.isRequired,
    validateCC: PropTypes.func.isRequired,
    addCstGroup: PropTypes.func.isRequired,
    editCstGroup: PropTypes.func.isRequired,
    resetStatus: PropTypes.func.isRequired,
    getSingleCstGroup: PropTypes.func.isRequired,
    isLopListLoading: PropTypes.bool.isRequired,
    resetValue: PropTypes.func.isRequired,
    isListUpdated: PropTypes.bool,
    singleRecord: PropTypes.array,
    mmCstContent: PropTypes.object,
}

const stateToProps = createStructuredSelector({
    mmCstContent: mmCstContent(),
    isListUpdated: isListUpdated(),
    singleRecord: singleRecord(),
})
const dispatchProps = {
    addCstGroup,
    editCstGroup,
    resetStatus,
    getSingleCstGroup,
    resetValue,
    validateCC,
}

export default connect(stateToProps, dispatchProps)(MMCst)
