import React, { Component } from 'react'
import './admin.css'
import { SITE_TXT, NUMBER_PATTERN, STATUS_SUCCESS, userInfo } from '../../Utils/constants'
import * as Constant from '../../Containers/Admin/constant'
import '../Table/index.css'
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
import Inputbox from '../Inputbox'
import InfiniteScroll from 'react-infinite-scroller'
import { NavLink } from 'react-router-dom'
import Modal from 'react-responsive-modal'
import Loader from '../Loader'
import { OpenLockIcon, PencilIcon, CloseLockIcon, BinDelete } from '../../Utils/svg'
import { isEmpty, isValidPattern } from '../../Utils/functions'
import { getPrefixedUrl } from '../../Utils/apis'
import { ConfirmAlertBox } from '../../Containers/Library/Alerts'
import EmailModal from './EmailModal'

class AdminDashboardTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            roleDownloadLimit: JSON.parse(JSON.stringify(Constant.DOWNLOAD_INITAL_STATE)),
            isBlockedUser: false,
            dataLoadMore: this.props.getAdminTableDataLoadMore,
            getAdminTableData: this.props.getAdminTableData,
            adminTableData: this.props.adminTableData ? this.props.adminTableData : [],
            dataRequest: this.props.dataRequest,
            sortColumn: 'remaining',
            sortType: 'ASC',
            pageNumber: 1,
            isAdminDataLoading: this.props.isAdminDataLoading,
            isLoading: this.props.isLoading,
            searchInput: '',
            successBlockUnblock: false,
            open: false,
            downloadLimitData: this.props.downloadLimitData,
            submitted: false,
            submitSuccess: false,
            isError: false,
            deleteModal: false,
            visible: false,
            emails: '',
            showCopy: false,
        }
        this.handleChange = this.handleChange.bind(this)
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            dataLoadMore: nextProps.getAdminTableDataLoadMore,
            getAdminTableData: nextProps.getAdminTableData,
            adminTableData: nextProps.adminTableData ? nextProps.adminTableData : [],
            dataRequest: nextProps.dataRequest,
            isAdminDataLoading: nextProps.isAdminDataLoading,
            isLoading: nextProps.isLoading,
            downloadLimitData: nextProps.downloadLimitData,
        })
        if (nextProps.blockUnblockData) {
            if (nextProps.blockUnblockData.status === 'success' && this.state.successBlockUnblock === true) {
                const data = {
                    pageSize: 20,
                    pageNumber: 1,
                    search: this.state.searchInput,
                    sortColumn: this.state.sortColumn,
                    sortType: this.state.sortType,
                }
                this.props.getAdminTableData(data)
                this.setState({ successBlockUnblock: false, pageNumber: 1 })
            } else if (this.state.successBlockUnblock === true) {
                alert(nextProps.blockUnblockData)
                this.setState({ successBlockUnblock: false })
            }
        }

        if (nextProps.downloadLimitData && this.state.submitted === false) {
            const roleDownloadLimit = {}
            nextProps.downloadLimitData.data.forEach((value, key) => {
                roleDownloadLimit[value.KeyValue] = value.downloadLimit
            })
            this.setState({
                roleDownloadLimit: roleDownloadLimit,
                submitted: true,
            })
        }

        if (this.state.submitSuccess === true && nextProps.downloadLimitStatus === STATUS_SUCCESS) {
            const data = {
                pageSize: 20,
                pageNumber: 1,
                search: this.state.searchInput,
                sortColumn: this.state.sortColumn,
                sortType: this.state.sortType,
            }
            this.props.getAdminTableData(data)
            this.setState({ submitSuccess: false, pageNumber: 1 })
        }
        if (nextProps.adminUser && nextProps.adminUser.length > 0 && this.state.emails.length < 1) {
            this.setState(
                {
                    emails: nextProps.adminUser,
                },
                () => {
                    this.setState({
                        visible: true,
                    })
                }
            )
        }
    }
    loadMoreSearch = () => {
        const data = this.state.dataRequest
        if (!this.state.isLoading) {
            data.pageNumber = this.state.pageNumber + 1
            data.sortColumn = this.state.sortColumn
            data.sortType = this.state.sortType
            this.state.dataLoadMore(data)
            this.setState({ pageNumber: data.pageNumber })
        }
    }
    blockUnblockClick = id => {
        const data = {}
        data['UserGuidId'] = id
        this.props.blockUnblockUser(data)
        this.setState({ successBlockUnblock: true })
    }
    handleChange = (field, value) => {
        clearTimeout(this.delayTimer)
        this.delayTimer = setTimeout(() => {
            this.setState({ searchInput: value.trim() }, () => {
                const data = this.state.dataRequest
                if (!this.state.isLoading) {
                    data.pageNumber = 1
                    data.search = this.state.searchInput
                    this.state.getAdminTableData(data)
                    this.setState({ pageNumber: 1 })
                }
            })
        }, 1000)
    }
    handleSubmit = e => {
        e.preventDefault()
        let obj = this.state.roleDownloadLimit
        let newArray = Object.values(obj)
        let checkBlankValues = function(element) {
            return element == null || element === ''
        }
        let returnedVal = newArray.some(checkBlankValues)
        if (returnedVal) {
            this.setState({ isError: true })
        } else {
            this.props.setDownloadLimit(this.state.roleDownloadLimit)
            this.setState({
                submitSuccess: true,
                isError: false,
                submitted: false,
            })
            this.onCloseModal()
        }
    }
    handleCountChange = (field, value) => {
        if (!isValidPattern(value, NUMBER_PATTERN) && value !== '') {
            return false
        } else {
            this.setState(
                {
                    roleDownloadLimit: {
                        ...this.state.roleDownloadLimit,
                        [field]: value,
                    },
                },
                () => {}
            )
        }
    }
    sortDocuments = col => {
        this.setState(
            {
                pageNumber: 1,
            },
            () => {
                const data = {
                    action: this.state.dataAction,
                    pageSize: 20,
                    pageNumber: this.state.pageNumber,
                    search: this.state.searchInput,
                    sortColumn: col,
                    sortType: this.state.sortType === 'ASC' ? 'DESC' : 'ASC',
                }
                this.setState({ sortColumn: data.sortColumn, sortType: data.sortType }, () => {
                    this.state.getAdminTableData(data)
                })
            }
        )
    }

    showSortIcon = columnId => {
        if (this.state.sortColumn !== columnId) {
            return ''
        } else {
            return this.state.sortType === 'ASC' ? 'desc' : 'asc'
        }
    }
    onOpenModal = () => {
        this.props.getDownloadLimit()
        this.setState({ open: true, submitted: false })
    }

    onCloseModal = () => {
        this.setState({ open: false, submitted: false, isError: false, deleteModal: false })
    }
    onDownloadsKeyPress = event => {
        return this.isNumberKey(event)
    }

    emailToActiveUser = () => {
        this.setState({
            emails: '',
        })
        const data = {
            pageSize: '',
            pageNumber: 1,
            search: '',
            sortColumn: '',
            sortType: 'ASC',
        }
        this.props.getAdminUserCount(data)
    }

    doMail = email => {
        window.location.href = 'mailto:' + email
    }

    handleDeleteUser = (id, index) => {
        this.setState({ userIdToDelete: false })
        const title = SITE_TXT.TXT_CONFIRM_DELETE
        const message = SITE_TXT.TXT_PARTNER_DELETE_MESSAGE
        ConfirmAlertBox(title, message, () => this.trashFinally(id, index))
        // this.props.deleteUser(item.id)
    }
    trashFinally = (id, index) => {
        const { deleteUser } = this.props
        const data = {
            id,
            index,
        }
        deleteUser(data)
        this.setState({ ondeleteSuccessStatus: true })
    }
    showModal = () => {
        this.setState({
            visible: true,
        })
    }
    hideModal = () => {
        this.setState({
            visible: false,
        })
    }

    render() {
        const { open, roleDownloadLimit, visible, emails } = this.state
        const isSameEmail = userInfo['name']
        const {
            TXT_USER_HISTORY,
            TXT_NAME,
            TXT_USER_TYPE,
            TXT_DOWNLOAD_HISTORY,
            TXT_TAKE_ACTION,
            TXT_SEARCH_PLACEHOLDER,
            TXT_SHOWING,
            TXT_EDIT_DOCUMENT,
            TXT_LOADING,
            TXT_BLOCK_USER,
            TXT_UNBLOCK_USER,
            TXT_TOTAL_DOWNLOADS,
            TXT_SET_DOWNLOAD_LIMIT,
            TXT_SET_WEEKLY_DOWNLOAD_LIMIT,
            TXT_UPPER_DOWNLOAD_LIMIT,
            TXT_CANCEL_ADMIN,
            TXT_SAVE,
            TXT_REQUIRED_DOWNLOAD_VALUE,
        } = SITE_TXT

        let lengthTable = !isEmpty(this.state.adminTableData) ? this.state.adminTableData.data.length : 0
        let totalrecords = this.state.adminTableData ? this.state.adminTableData.totalCount : 0
        let adminTableDataList = !isEmpty(this.state.adminTableData) ? this.state.adminTableData.data : []

        return (
            <div className="lower-section">
                <div className="admin-settings-heading">
                    <div className="user-history-heading">
                        <div className="lower-heading-admin">{TXT_USER_HISTORY}</div>
                        <div className="blue-bar-admin" />
                    </div>
                    <div className="set-limit-popup">
                        <button className="btn btn-primary send-mail" onClick={this.emailToActiveUser}>
                            {'Email To Active Users'}
                        </button>
                        <button className="btn btn-primary download-btn" onClick={this.onOpenModal}>
                            {TXT_SET_DOWNLOAD_LIMIT}
                        </button>
                        <Modal
                            open={open}
                            onClose={this.onCloseModal}
                            center
                            classNames={{ modal: 'comp-modal set-limit-modal' }}
                        >
                            <div className="set-limit-header">
                                {TXT_SET_WEEKLY_DOWNLOAD_LIMIT}{' '}
                                <span className="small-heading-limit">({TXT_UPPER_DOWNLOAD_LIMIT})</span>
                            </div>
                            <br />
                            <div className="row">
                                {!isEmpty(this.state.downloadLimitData) ? (
                                    this.state.downloadLimitData.data.map((item, i) => (
                                        <div className="col-sm-6" key={i}>
                                            <label htmlFor={item.KeyValue} className="input-lable">
                                                {item.value}
                                            </label>
                                            <Inputbox
                                                type="text"
                                                max="3"
                                                fieldFor={item.KeyValue}
                                                onChange={(field, value) => this.handleCountChange(field, value)}
                                                value={roleDownloadLimit[item.KeyValue]}
                                            />
                                        </div>
                                    ))
                                ) : (
                                    <div className="loader-alignment">
                                        <Loader />
                                    </div>
                                )}
                            </div>
                            <div className="button-section">
                                <button className="btn btn-outline-secondary btn-lg" onClick={this.onCloseModal}>
                                    {TXT_CANCEL_ADMIN}
                                </button>
                                <button className="btn btn-primary btn-lg" onClick={this.handleSubmit.bind(this)}>
                                    {TXT_SAVE}
                                </button>
                                {this.state.isError ? (
                                    <div className="help-block " style={{ color: '#d84315' }}>
                                        {TXT_REQUIRED_DOWNLOAD_VALUE}
                                    </div>
                                ) : null}
                            </div>
                        </Modal>
                    </div>
                </div>

                <div className="tableSecDivDashboard">
                    <div className="searchBoxContainerDashboard">
                        <div className="searchInputBoxDashboard">
                            <div className="searchInputBox">
                                <Inputbox
                                    placeholder={TXT_SEARCH_PLACEHOLDER}
                                    type="text"
                                    fieldFor="search"
                                    onChange={this.handleChange}
                                    autoFocus={true}
                                />
                            </div>
                        </div>
                        <div className="showingRecordsDashboard">
                            {TXT_SHOWING}

                            <span className="total-rec"> {' ' + adminTableDataList.length + '/' + totalrecords} </span>
                        </div>
                        {/* <div className="searchButton">
                                <SearchIcon />
        </div> */}
                    </div>
                </div>
                <div className="scrollauto">
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={() => this.loadMoreSearch()}
                        loader={
                            <div className="loader" key={0}>
                                {TXT_LOADING}
                            </div>
                        }
                        hasMore={lengthTable < totalrecords}
                        useWindow={true}
                    >
                        <table className="table customAdminSessionTable">
                            <thead>
                                <tr>
                                    <th
                                        onClick={() => this.sortDocuments('userName')}
                                        id="userName"
                                        className={this.showSortIcon('userName')}
                                    >
                                        {TXT_NAME}
                                    </th>
                                    <th
                                        onClick={() => this.sortDocuments('userType')}
                                        id="userType"
                                        className={this.showSortIcon('userType')}
                                    >
                                        {TXT_USER_TYPE}
                                    </th>
                                    <th
                                        onClick={() => this.sortDocuments('downloadCount')}
                                        id="downloadCount"
                                        className={this.showSortIcon('downloadCount')}
                                    >
                                        {TXT_DOWNLOAD_HISTORY}
                                    </th>
                                    <th
                                        onClick={() => this.sortDocuments('totalDownloadCount')}
                                        id="totalDownloadCount"
                                        className={this.showSortIcon('totalDownloadCount')}
                                    >
                                        {TXT_TOTAL_DOWNLOADS}
                                    </th>
                                    <th
                                        onClick={() => this.sortDocuments('remaining')}
                                        id="remaining"
                                        className={this.showSortIcon('remaining')}
                                    >
                                        {TXT_TAKE_ACTION}
                                    </th>
                                    <th>DELETE</th>
                                </tr>
                            </thead>
                            <tbody>
                                {adminTableDataList.map((item, index) => (
                                    <tr key={item.id} className="tabletxt">
                                        <td>
                                            <div className="name-section">
                                                <div className="pencil-format">
                                                    {isSameEmail !== item.emailId && (
                                                        <NavLink
                                                            className="action-icon"
                                                            title={TXT_EDIT_DOCUMENT}
                                                            to={'/admin/createUser/edit/' + item.id}
                                                        >
                                                            <PencilIcon width="15" height="15" />
                                                        </NavLink>
                                                    )}
                                                </div>

                                                <div className="img-svg-noImg">
                                                    {item.userImage ? (
                                                        <img
                                                            src={getPrefixedUrl(item.userImage)}
                                                            alt="usericon"
                                                            className="userImage round-shape"
                                                            height="40"
                                                            width="40"
                                                        />
                                                    ) : (
                                                        <img
                                                            src="../../images/userIcon.png"
                                                            alt="usericon"
                                                            className="userImage round-shape"
                                                            height="40"
                                                            width="40"
                                                        />
                                                        /* <NoImageIcon
                                                            width="40"
                                                            height="40"
                                                        /> */
                                                    )}
                                                </div>
                                                <div className="user-name v-center-parent">
                                                    <div className="v-center-relative">
                                                        {item.userName}
                                                        <div className="emailCss">{item.emailId}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{item.userType}</td>
                                        <td>
                                            <Slider
                                                min={0}
                                                max={parseInt(item.todayLimit)}
                                                value={parseInt(item.downloadCount)}
                                                labels={{
                                                    0: 0,
                                                    1000: parseInt(item.todayLimit),
                                                }}
                                                handleLabel={item.downloadCount}
                                            />
                                        </td>
                                        <td className="text-center">{item.totalDownloadCount}</td>
                                        <td className="admin-action-td">
                                            {item.remaining > 0 ? (
                                                <span>
                                                    <span>
                                                        <CloseLockIcon />
                                                    </span>
                                                    <span className="block-unblock-text">{TXT_BLOCK_USER}</span>
                                                </span>
                                            ) : (
                                                <span>
                                                    <span>
                                                        <OpenLockIcon />
                                                    </span>
                                                    <span
                                                        className="block-unblock-text"
                                                        onClick={this.blockUnblockClick.bind(this, item.id)}
                                                    >
                                                        {TXT_UNBLOCK_USER}
                                                    </span>
                                                </span>
                                            )}
                                        </td>
                                        <td>
                                            <span
                                                className="deleteUser"
                                                onClick={() => this.handleDeleteUser(item.id, index)}
                                            >
                                                <BinDelete />
                                            </span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </InfiniteScroll>
                </div>
                {visible && <EmailModal visible={visible} hideModal={this.hideModal} emails={emails} />}
            </div>
        )
    }
}

export default AdminDashboardTable
