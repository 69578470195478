import { put, takeLatest, all, call } from 'redux-saga/effects'
import {
    LOP_GETSTARTED_ITEMS_GET,
    LOP_GETSTARTED_ITEMS_GET_MORE,
    LOP_GETSTARTED_ITEM_DELETE,
    LOP_ADD_ITEM,
    LOP_GOLD_ITEMS_GET,
    LOP_GOLD_ITEMS_MORE,
    LOP_EDIT_ITEM,
    LOP_GOLD_DELETE_ITEM,
    LOP_GOLD_DELETE_FOLDER,
    LOP_ADD_FOLDER,
    LOP_EDIT_FOLDER,
    LOP_FETCH_FOLDER_LIST,
    LOP_GOLD_EMAIL_ITEMS,
    LOP_GOLD_DOWNLOAD_ITEMS,
    LOP_FETCH_INDUSTRY_TAGS,
    LOP_FETCH_TOPICS_KEYWORDS_TAGS,
    LOP_GEOGRAPHY,
    LOP_DEALTYPE,
    LOP_DEALSIZE,
    LOP_CASE_STUDIES_ITEMS,
    LOP_CASE_STUDIES_ITEMS_MORE,
    LOP_CASE_STUDIES_ADD,
    LOP_CASE_STUDIES_EDIT_GET,
    LOP_CASE_STUDIES_EDIT_PUT,
    LOP_EXPERT_ITEMS_GET,
    LOP_EXPERT_ITEMS_MORE,
    LOP_CLIENTREF_ITEMS_GET,
    LOP_CLIENTREF_ITEMS_MORE,
    LOP_EXPERT_CV_ADD,
    LOP_EXPERT_CV_EDIT,
    LOP_EXPERT_CV_FETCH,
    LOP_REGION_BASE,
    LOP_FETCH_EXPERT_INDUSTRY_TAGS,
    LOP_FETCH_EXPERT_ROLE_TAGS,
    LOP_FETCH_EXPERT_TOPICS_TAGS,
    LOP_CLEAR_FETCHED_DATA,
    LOP_FETCH_COUNTRY_CODE,
    LOP_ADD_CLIENT,
    LOP_EDIT_CLIENT_FETCH,
    LOP_EDIT_CLIENT_PUT,
    CREATE_LOP,
} from './constant'
import { STATUS_SUCCESS } from '../../Utils/constants'

import {
    getLopItems,
    calldeleteLopGetStartedItems,
    addLopItems,
    editLopItems,
    addLopFolder,
    editLopFolder,
    fetchLopFolderList,
    calldeleteGoldStandardItems,
    calldeleteGoldStandardFolder,
    emailDocument,
    downloadDocument,
    fetchLopTags,
    fetchLopDropdownlist,
    addCaseStudyItem,
    fetchEditItemCaseStudy,
    putEditItemCaseStudy,
    addExpertItem,
    fetchExpertItem,
    editExpertItem,
    callGetCountryCode,
    addclientItem,
    fetchEditItemClientRef,
    putEditItemClientRef,
    addLopData,
} from '../../Utils/apis'

import {
    lopGetStartedItemsSuccess,
    lopGetStartedItemsFailure,
    lopAddItemSuccess,
    lopAddItemFailure,
    lopGoldItemsSuccess,
    lopGoldItemsFailure,
    lopGoldItemsMoreSuccess,
    lopGoldItemsMoreFailure,
    lopGetStartedItemsMoreSuccess,
    lopGetStartedItemsMoreFailure,
    lopGetStartedItemsDeleteSuccess,
    lopGetStartedItemsDeleteFailure,
    lopEditItemSuccess,
    lopEditItemFailure,
    lopGoldDeleteItemsSuccess,
    lopGoldDeleteItemsFailure,
    lopGoldDeleteFolderSuccess,
    lopGoldDeleteFolderFailure,
    lopAddFolderSuccess,
    lopAddFolderFailure,
    lopEditFolderSuccess,
    lopEditFolderFailure,
    lopFetchFolderListSuccess,
    lopFetchFolderListFailure,
    lopGoldEmailItemsSuccess,
    lopGoldEmailItemsFailure,
    lopGoldDownloadItemsSuccess,
    lopGoldDownloadItemsFailure,
    lopExpertItemsSuccess,
    lopExpertItemsFailure,
    lopExpertItemsMoreSuccess,
    lopExpertItemsMoreFailure,
    lopCaseStudiesItemsSuccess,
    lopCaseStudiesItemsFailure,
    lopCaseStudiesItemsMoreSuccess,
    lopCaseStudiesItemsMoreFailure,
    lopGeographySuccess,
    lopGeographyFailure,
    lopDealTypeSuccess,
    lopDealTypeFailure,
    lopDealSizeSuccess,
    lopDealSizeFailure,
    lopIndustryTagsSuccess,
    lopIndustryTagsFailure,
    lopTopicsKeywordsTagsSuccess,
    lopTopicskeywordsTagsFailure,
    lopCaseStudiesAddItemSuccess,
    lopCaseStudiesAddItemFailure,
    caseStudEditItemGetSuccess,
    caseStudEditItemGetFailure,
    caseStudEditItemPutSuccess,
    caseStudEditItemPutFailure,
    lopClientRefItemsSuccess,
    lopClientRefItemsFailure,
    lopClientRefItemsMoreSuccess,
    lopClientRefItemsMoreFailure,
    lopExpertCvAddItemSuccess,
    lopExpertCvAddItemFailure,
    lopExpertCvEditItemSuccess,
    lopExpertCvEditItemFailure,
    lopExpertCvFetchItemSuccess,
    lopExpertCvFetchItemFailure,
    lopRegionBaseSuccess,
    lopRegionBaseFailure,
    lopExpertIndustryTagsSuccess,
    lopExpertIndustryTagsFailure,
    lopExpertRoleTagsSuccess,
    lopExpertRoleTagsFailure,
    lopExpertTopicsTagsSuccess,
    lopExpertTopicsTagsFailure,
    lopClearFetchedEditDataSuccess,
    lopFetchedCountryCodeSuccess,
    lopFetchedCountryCodeFailure,
    lopAddClientSuccess,
    lopAddClientFailure,
    lopEditClientFetchSuccess,
    lopEditClientFetchFailure,
    lopEditClientPutSuccess,
    lopEditClientPutFailure,
    uploadLopSuccess,
    uploadLopFailed,
} from './actions'

export function* lopGetStartedItems({ data }) {
    const response = yield call(getLopItems, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(lopGetStartedItemsSuccess({ responseData }))
    } else {
        yield put(
            lopGetStartedItemsFailure({
                responseData,
            })
        )
    }
}

export function* lopGetStartedItemsMore({ data }) {
    const response = yield call(getLopItems, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(lopGetStartedItemsMoreSuccess({ responseData }))
    } else {
        yield put(
            lopGetStartedItemsMoreFailure({
                responseData,
            })
        )
    }
}

export function* lopAddItem({ data }) {
    const response = yield call(addLopItems, data)
    const responseData = response.data

    if (responseData.status === STATUS_SUCCESS) {
        yield put(lopAddItemSuccess({ responseData, reqData: data }))
    } else {
        yield put(
            lopAddItemFailure({
                responseData,
            })
        )
    }
}

export function* lopEditItem({ data }) {
    const response = yield call(editLopItems, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(lopEditItemSuccess({ responseData, reqData: data }))
    } else {
        yield put(
            lopEditItemFailure({
                responseData,
            })
        )
    }
}

export function* lopAddFolder({ data }) {
    const response = yield call(addLopFolder, data)
    const responseData = response.data

    if (responseData.status === STATUS_SUCCESS) {
        yield put(lopAddFolderSuccess({ responseData, reqData: data }))
    } else {
        yield put(
            lopAddFolderFailure({
                responseData,
            })
        )
    }
}

export function* lopEditFolder({ data }) {
    const response = yield call(editLopFolder, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(lopEditFolderSuccess({ responseData, reqData: data }))
    } else {
        yield put(
            lopEditFolderFailure({
                responseData,
            })
        )
    }
}

export function* lopFetchFolderList({ data }) {
    const response = yield call(fetchLopFolderList, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(lopFetchFolderListSuccess({ responseData }))
    } else {
        yield put(
            lopFetchFolderListFailure({
                responseData,
            })
        )
    }
}

export function* lopGoldItems({ data }) {
    const response = yield call(getLopItems, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(lopGoldItemsSuccess({ responseData }))
    } else {
        yield put(
            lopGoldItemsFailure({
                responseData,
            })
        )
    }
}
export function* lopGoldItemsMore({ data }) {
    const response = yield call(getLopItems, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(lopGoldItemsMoreSuccess({ responseData }))
    } else {
        yield put(
            lopGoldItemsMoreFailure({
                responseData,
            })
        )
    }
}

export function* deleteLopGetStartedItems({ data }) {
    const response = yield call(calldeleteLopGetStartedItems, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(lopGetStartedItemsDeleteSuccess({ responseData, reqData: data }))
    } else {
        yield put(lopGetStartedItemsDeleteFailure({ responseData }))
    }
}

export function* deleteLopGoldItems({ data }) {
    const response = yield call(calldeleteGoldStandardItems, data)
    //Change the CALL from the API when API details are received.
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(lopGoldDeleteItemsSuccess({ responseData, reqData: data }))
    } else {
        yield put(lopGoldDeleteItemsFailure({ responseData }))
    }
}

export function* deleteLopGoldFolder({ data }) {
    const response = yield call(calldeleteGoldStandardFolder, data)
    //Change the CALL from the API when API details are received.
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(lopGoldDeleteFolderSuccess({ responseData, reqData: data }))
    } else {
        yield put(lopGoldDeleteFolderFailure({ responseData }))
    }
}

export function* downloadLOPDocuments({ data }) {
    const response = yield call(downloadDocument, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(lopGoldDownloadItemsSuccess({ responseData }))
    } else {
        yield put(lopGoldDownloadItemsFailure({ responseData }))
    }
}

export function* emailLOPDocuments({ data }) {
    const response = yield call(emailDocument, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(lopGoldEmailItemsSuccess({ responseData }))
    } else {
        yield put(lopGoldEmailItemsFailure({ responseData }))
    }
}

export function* lopIndustryTags({ data }) {
    const response = yield call(fetchLopTags, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(lopIndustryTagsSuccess({ responseData, reqData: data }))
    } else {
        yield put(
            lopIndustryTagsFailure({
                responseData,
            })
        )
    }
}
export function* lopExpertItems({ data }) {
    const response = yield call(getLopItems, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(lopExpertItemsSuccess({ responseData }))
    } else {
        yield put(
            lopExpertItemsFailure({
                responseData,
            })
        )
    }
}

export function* lopTopicsKeywordsTags({ data }) {
    const response = yield call(fetchLopTags, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(lopTopicsKeywordsTagsSuccess({ responseData, reqData: data }))
    } else {
        yield put(
            lopTopicskeywordsTagsFailure({
                responseData,
            })
        )
    }
}

export function* lopGeography({ data }) {
    const response = yield call(fetchLopDropdownlist, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(lopGeographySuccess({ responseData, reqData: data }))
    } else {
        yield put(
            lopGeographyFailure({
                responseData,
            })
        )
    }
}

export function* lopExpertItemsMore({ data }) {
    const response = yield call(getLopItems, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(lopExpertItemsMoreSuccess({ responseData }))
    } else {
        yield put(
            lopExpertItemsMoreFailure({
                responseData,
            })
        )
    }
}
export function* lopDealType({ data }) {
    const response = yield call(fetchLopDropdownlist, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(lopDealTypeSuccess({ responseData, reqData: data }))
    } else {
        yield put(
            lopDealTypeFailure({
                responseData,
            })
        )
    }
}

export function* lopDealsize({ data }) {
    const response = yield call(fetchLopDropdownlist, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(lopDealSizeSuccess({ responseData, reqData: data }))
    } else {
        yield put(
            lopDealSizeFailure({
                responseData,
            })
        )
    }
}

export function* lopCaseStudiesItems({ data }) {
    const response = yield call(getLopItems, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(lopCaseStudiesItemsSuccess({ responseData }))
    } else {
        yield put(
            lopCaseStudiesItemsFailure({
                responseData,
            })
        )
    }
}

export function* lopCaseStudiesItemsMore({ data }) {
    const response = yield call(getLopItems, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(lopCaseStudiesItemsMoreSuccess({ responseData }))
    } else {
        yield put(
            lopCaseStudiesItemsMoreFailure({
                responseData,
            })
        )
    }
}

export function* addLopCaseStudiesItem({ data }) {
    const response = yield call(addCaseStudyItem, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(lopCaseStudiesAddItemSuccess({ responseData }))
    } else {
        yield put(
            lopCaseStudiesAddItemFailure({
                responseData,
            })
        )
    }
}

export function* caseStudEditItemGet({ data }) {
    const response = yield call(fetchEditItemCaseStudy, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(caseStudEditItemGetSuccess({ responseData }))
    } else {
        yield put(
            caseStudEditItemGetFailure({
                responseData,
            })
        )
    }
}

export function* caseStudEditItemPut({ data }) {
    const response = yield call(putEditItemCaseStudy, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(caseStudEditItemPutSuccess({ responseData }))
    } else {
        yield put(
            caseStudEditItemPutFailure({
                responseData,
            })
        )
    }
}

export function* lopClientRefItems({ data }) {
    const response = yield call(getLopItems, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(lopClientRefItemsSuccess({ responseData }))
    } else {
        yield put(
            lopClientRefItemsFailure({
                responseData,
            })
        )
    }
}

export function* addLopExpertItem({ data }) {
    const response = yield call(addExpertItem, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(lopExpertCvAddItemSuccess({ responseData }))
    } else {
        yield put(
            lopExpertCvAddItemFailure({
                responseData,
            })
        )
    }
}

export function* lopClientRefItemsMore({ data }) {
    const response = yield call(getLopItems, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(lopClientRefItemsMoreSuccess({ responseData }))
    } else {
        yield put(
            lopClientRefItemsMoreFailure({
                responseData,
            })
        )
    }
}

export function* expertFetchItem({ data }) {
    const response = yield call(fetchExpertItem, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(lopExpertCvFetchItemSuccess({ responseData }))
    } else {
        yield put(
            lopExpertCvFetchItemFailure({
                responseData,
            })
        )
    }
}

export function* expertEditItem({ data }) {
    const response = yield call(editExpertItem, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(lopExpertCvEditItemSuccess({ responseData }))
    } else {
        yield put(
            lopExpertCvEditItemFailure({
                responseData,
            })
        )
    }
}

export function* lopRegionBase({ data }) {
    const response = yield call(fetchLopDropdownlist, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(lopRegionBaseSuccess({ responseData, reqData: data }))
    } else {
        yield put(
            lopRegionBaseFailure({
                responseData,
            })
        )
    }
}

export function* lopExpIndustryTags({ data }) {
    const response = yield call(fetchLopTags, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(lopExpertIndustryTagsSuccess({ responseData, reqData: data }))
    } else {
        yield put(
            lopExpertIndustryTagsFailure({
                responseData,
            })
        )
    }
}

export function* lopExpRoleTags({ data }) {
    const response = yield call(fetchLopTags, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(lopExpertRoleTagsSuccess({ responseData, reqData: data }))
    } else {
        yield put(
            lopExpertRoleTagsFailure({
                responseData,
            })
        )
    }
}

export function* lopExpTopicsTags({ data }) {
    const response = yield call(fetchLopTags, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(lopExpertTopicsTagsSuccess({ responseData, reqData: data }))
    } else {
        yield put(
            lopExpertTopicsTagsFailure({
                responseData,
            })
        )
    }
}

export function* lopClearFetchedData({ data }) {
    const responseData = null
    yield put(
        lopClearFetchedEditDataSuccess({
            responseData,
        })
    )
}

export function* lopCountryCode({ data }) {
    const response = yield call(callGetCountryCode, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(lopFetchedCountryCodeSuccess({ responseData, reqData: data }))
    } else {
        yield put(
            lopFetchedCountryCodeFailure({
                responseData,
            })
        )
    }
}

export function* lopAddClientItem({ data }) {
    const response = yield call(addclientItem, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(lopAddClientSuccess({ responseData, reqData: data }))
    } else {
        yield put(
            lopAddClientFailure({
                responseData,
            })
        )
    }
}

export function* lopFetchClientRef({ data }) {
    const response = yield call(fetchEditItemClientRef, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(lopEditClientFetchSuccess({ responseData, reqData: data }))
    } else {
        yield put(
            lopEditClientFetchFailure({
                responseData,
            })
        )
    }
}

export function* lopPutClientRef({ data }) {
    const response = yield call(putEditItemClientRef, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(lopEditClientPutSuccess({ responseData, reqData: data }))
    } else {
        yield put(
            lopEditClientPutFailure({
                responseData,
            })
        )
    }
}

export function* uploadLop({ data }) {
    const response = yield call(addLopData, data)
    const responseData = response.data
    if (responseData.status === STATUS_SUCCESS) {
        yield put(uploadLopSuccess({ responseData }))
    } else {
        yield put(uploadLopFailed({ responseData }))
    }
}

export function* lopSaga() {
    yield all([
        takeLatest(`${LOP_GETSTARTED_ITEMS_GET}_PENDING`, lopGetStartedItems),
        takeLatest(`${LOP_GETSTARTED_ITEMS_GET_MORE}_PENDING`, lopGetStartedItemsMore),
        takeLatest(`${LOP_GETSTARTED_ITEM_DELETE}_PENDING`, deleteLopGetStartedItems),
        takeLatest(`${LOP_GOLD_ITEMS_GET}_PENDING`, lopGoldItems),
        takeLatest(`${LOP_GOLD_ITEMS_MORE}_PENDING`, lopGoldItemsMore),
        takeLatest(`${LOP_ADD_ITEM}_PENDING`, lopAddItem),
        takeLatest(`${LOP_EDIT_ITEM}_PENDING`, lopEditItem),
        takeLatest(`${LOP_GOLD_DELETE_ITEM}_PENDING`, deleteLopGoldItems),
        takeLatest(`${LOP_GOLD_DELETE_FOLDER}_PENDING`, deleteLopGoldFolder),
        takeLatest(`${LOP_ADD_FOLDER}_PENDING`, lopAddFolder),
        takeLatest(`${LOP_EDIT_FOLDER}_PENDING`, lopEditFolder),
        takeLatest(`${LOP_FETCH_FOLDER_LIST}_PENDING`, lopFetchFolderList),
        takeLatest(`${LOP_GOLD_EMAIL_ITEMS}_PENDING`, emailLOPDocuments),
        takeLatest(`${LOP_GOLD_DOWNLOAD_ITEMS}_PENDING`, downloadLOPDocuments),
        takeLatest(`${LOP_EXPERT_ITEMS_GET}_PENDING`, lopExpertItems),
        takeLatest(`${LOP_EXPERT_ITEMS_MORE}_PENDING`, lopExpertItemsMore),

        takeLatest(`${LOP_FETCH_INDUSTRY_TAGS}_PENDING`, lopIndustryTags),
        takeLatest(`${LOP_FETCH_TOPICS_KEYWORDS_TAGS}_PENDING`, lopTopicsKeywordsTags),
        takeLatest(`${LOP_CASE_STUDIES_ITEMS}_PENDING`, lopCaseStudiesItems),
        takeLatest(`${LOP_CASE_STUDIES_ITEMS_MORE}_PENDING`, lopCaseStudiesItemsMore),
        takeLatest(`${LOP_GEOGRAPHY}_PENDING`, lopGeography),
        takeLatest(`${LOP_DEALTYPE}_PENDING`, lopDealType),
        takeLatest(`${LOP_DEALSIZE}_PENDING`, lopDealsize),
        takeLatest(`${LOP_CASE_STUDIES_ADD}_PENDING`, addLopCaseStudiesItem),

        takeLatest(`${LOP_CASE_STUDIES_EDIT_GET}_PENDING`, caseStudEditItemGet),
        takeLatest(`${LOP_CASE_STUDIES_EDIT_PUT}_PENDING`, caseStudEditItemPut),

        takeLatest(`${LOP_CLIENTREF_ITEMS_GET}_PENDING`, lopClientRefItems),
        takeLatest(`${LOP_CLIENTREF_ITEMS_MORE}_PENDING`, lopClientRefItemsMore),
        takeLatest(`${LOP_EXPERT_CV_ADD}_PENDING`, addLopExpertItem),
        takeLatest(`${LOP_EXPERT_CV_FETCH}_PENDING`, expertFetchItem),
        takeLatest(`${LOP_EXPERT_CV_EDIT}_PENDING`, expertEditItem),
        takeLatest(`${LOP_REGION_BASE}_PENDING`, lopRegionBase),
        takeLatest(`${LOP_FETCH_EXPERT_INDUSTRY_TAGS}_PENDING`, lopExpIndustryTags),

        takeLatest(`${LOP_FETCH_EXPERT_ROLE_TAGS}_PENDING`, lopExpRoleTags),

        takeLatest(`${LOP_FETCH_EXPERT_TOPICS_TAGS}_PENDING`, lopExpTopicsTags),
        takeLatest(`${LOP_CLEAR_FETCHED_DATA}_PENDING`, lopClearFetchedData),
        takeLatest(`${LOP_FETCH_COUNTRY_CODE}_PENDING`, lopCountryCode),
        takeLatest(`${LOP_ADD_CLIENT}_PENDING`, lopAddClientItem),
        takeLatest(`${LOP_EDIT_CLIENT_FETCH}_PENDING`, lopFetchClientRef),
        takeLatest(`${LOP_EDIT_CLIENT_PUT}_PENDING`, lopPutClientRef),
        takeLatest(`${CREATE_LOP}_PENDING`, uploadLop),
    ])
}

export default [lopSaga]
